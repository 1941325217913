import BaseService from "../BaseService";
import { API_KEY } from "../../config";
// import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";

const HEADER_CLOCK_SERVICE = {
    "API-Key": API_KEY(),
    "Device-Platform": "web",
};

class ClockService {
    postGetAgentDetail(orgID, callback) { 
        HEADER_CLOCK_SERVICE["Organization-ID"] = orgID;
        // HEADER_CLOCK_SERVICE["Authorization"] = "Bearer " + HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).accessToken;

        BaseService.doPostAccessToken(API.GET_AGENT_DETAIL_AND_STATUS, {}, HEADER_CLOCK_SERVICE, callback);
    }

    postAgentClockIn(orgID, startAsAway, callback) { 
        HEADER_CLOCK_SERVICE["Organization-ID"] = orgID;
        // HEADER_CLOCK_SERVICE["Authorization"] = "Bearer " + HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).accessToken;

        BaseService.doPostAccessToken(API.AGENT_CLOCK_IN, {startAsAway: startAsAway}, HEADER_CLOCK_SERVICE, callback);
    }

    postAgentClockOut(orgID, callback) { 
        HEADER_CLOCK_SERVICE["Organization-ID"] = orgID;
        // HEADER_CLOCK_SERVICE["Authorization"] = "Bearer " + HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).accessToken;

        BaseService.doPostAccessToken(API.AGENT_CLOCK_OUT, {}, HEADER_CLOCK_SERVICE, callback);
    }

    postAgentClockPause(orgID, callback) { 
        HEADER_CLOCK_SERVICE["Organization-ID"] = orgID;
        // HEADER_CLOCK_SERVICE["Authorization"] = "Bearer " + HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).accessToken;

        BaseService.doPostAccessToken(API.AGENT_CLOCK_PAUSE, {}, HEADER_CLOCK_SERVICE, callback);
    }

    postAgentClockResume(orgID, callback) { 
        HEADER_CLOCK_SERVICE["Organization-ID"] = orgID;
        // HEADER_CLOCK_SERVICE["Authorization"] = "Bearer " + HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).accessToken;

        BaseService.doPostAccessToken(API.AGENT_CLOCK_RESUME, {}, HEADER_CLOCK_SERVICE, callback);
    }
    
}

const API = {
    GET_AGENT_DETAIL_AND_STATUS: "/organization/agent/get_details_and_status",
    AGENT_CLOCK_IN: "/organization/agent_clock/in",
    AGENT_CLOCK_OUT: "/organization/agent_clock/out",
    AGENT_CLOCK_PAUSE: "/organization/agent_clock/pause",
    AGENT_CLOCK_RESUME: "/organization/agent_clock/resume"
}

export default new ClockService()