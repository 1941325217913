import React, { useEffect, useState } from "react";
import "../../auth/Auth.css";
import "./MemberInvite.scss";
import iconInvitationSuccess from "../../../assets/img/icon-invitation-sent.svg";
import { FiInfo } from "react-icons/fi";
import { Modal, ModalBody } from "reactstrap";
import { checkID, doToast, scrollToClass } from "../../../helper/HelperGeneral";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SelectTopic from "../../reuseableComponent/selectTopic/SelectTopic";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import MemberService from "../../../services/newServices/MemberServices";
import TopicService from "../../../services/newServices/TopicServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import HelperInput from "../../../helper/HelperInput";
// import Loading from "../reuseableComponent/loading/Loading";
import mixpanel from "mixpanel-browser";

const MEMBER_ROLE = {
  admin: "admin",
  agent: "member"
}

const MemberInvite = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [isOpenModalSendInvitation, setIsOpenModalSendInvitation] = useState(false);
  let [memberEmail, setMemberEmail] = useState("");
  let [memberRole, setMemberRole] = useState("");
  let [memberEmailOrigin, setMemberEmailOrigin] = useState("");
  let [memberRoleOrigin, setMemberRoleOrigin] = useState("");
  let [isWaitLoadingTopicList, setIsWaitLoadingTopicList] = useState(false);
  let [isWaitResponseInviteMember, setIsWaitResponseInviteMember] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [isCheckAll, setIsCheckAll] = useState(false);
  let [selectedTopic, setSelectedTopic] = useState([]);
  let [topicListOrigin, setTopicListOrigin] = useState([]);
  let [topicList, setTopicList] = useState([]);
  let [searchTopic, setSearchTopic] = useState("");
  let [errorMessageData, setErrorMessageData] = useState({
    email: "",
    role: "",
    topic: ""
  }) 

  const back = () => history.push(`/o/${orgID}/organization/members`);

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (orgID) {
      getTopic();
    }
  }, [orgID])

  let getTopic = () => {
    setIsWaitLoadingTopicList(true);
    TopicService.getTopic(orgID, "list", null, (response) => {
      if (response.dataResult.error.message === "") {
        setTopicListOrigin(response.dataResult.data.topics);
        setTopicList(response.dataResult.data.topics);
      } else {
        let _retriveDataError = { ...retriveDataError };
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
      setIsWaitLoadingTopicList(false);
    });
  }

  let toggleModalSendInvitation = () => {
    setIsOpenModalSendInvitation(!isOpenModalSendInvitation);
  }

  const onChangeEmailMember = (e) => {
    if(HelperInput.noSpace(e.target.value)) {
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.email = "";
      setErrorMessageData(_errorMessageData);
      setMemberEmail(e.target.value);
    }  
  };

  const onChangeRoleMember = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.role = "";
    setErrorMessageData(_errorMessageData);
    setMemberRole(e.target.value);
  }

  const onSubmitInviteMember = (e) => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    e.preventDefault();

    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(memberEmail === "") {
        _errorMessageData.email = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-email" }
    }

    if(memberEmail.length > 250) {
      _errorMessageData.email = "Characters exceed limit";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-email" }
    }

    if(memberRole === "") {
        _errorMessageData.role = "Please select role";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-role" }
    }

    if(selectedTopic.length === 0) {
      _errorMessageData.topic = "Please select topic";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-topic" }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      setIsWaitResponseInviteMember(true);

      const _selectedTopic = [...selectedTopic]
      const listTopicID = _selectedTopic.map(item => item.id)

      let dataMemberID = {
        email: memberEmail,
        roleCode: memberRole,
        topicIDs: listTopicID
      };

      MemberService.addMember(orgID, dataMemberID, (response) => {
        if (response.dataResult.status === 200) {
          // setMemberDetail(data.data.member);
          if (response.dataResult.data.success) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
              mixpanel.track(
                "[Action] Invite Member",
                {
                  userFullName: myAgentData.account.fullName,
                  userID: myAgentData.account.id,
                  organizationID: orgID.split("-")[0]
                }
              );
            }
            // history.push({
            //   pathname: `/o/${orgID}/organization/members`,
            //   state: {
            //     inviteMessage: response.dataResult.data.message,
            //     success: response.dataResult.data.success
            //   }
            // });
            setMemberEmailOrigin(memberEmail);
            setMemberRoleOrigin(memberRole); 
            setTopicListOrigin(topicList);
            toggleModalSendInvitation();
          } else {
            doToast(response.dataResult.data.message, 'fail');
          }

          setIsWaitResponseInviteMember(false);
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setIsWaitResponseInviteMember(false);
        }
      });
    }
  }

  // const isButtonSubmitDisabled = () => {
  //   return (
  //     (memberEmail.length === 0 || memberRole.length === 0 || !HelperInput.emailFormatValid(memberEmail)) ? true : false
  //   )
  // }

  const modalInvitationSuccess = () => {
    return (
      <Modal isOpen={isOpenModalSendInvitation} className="modal-invitation-sent" toggle={back}>
        <ModalBody>
          <img src={iconInvitationSuccess} alt="" />

          <br />
          <b>
            Invitation Sent!
            </b>
          <p>
            Once the invited member has created an account, don’t forget to assign them onto available topics, so they can start receiving and replying to messages.
            </p>

          <ButtonWithLoadingOrIcon
            onClickAction={back}
            className="orange-button main-button-40"
            text="Got it, Thanks!"
          />
        </ModalBody>
      </Modal>
    )
  }

  let onCheckAll = (e) => {
    if(!isWaitResponseInviteMember) {
      if (e.target.checked) {
        setSelectedTopic(topicList);
      } else {
        setSelectedTopic([]);
      }
      
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
      setIsCheckAll(e.target.checked);
    }
  }

  const onClearAll = () => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.topic = "";
    setErrorMessageData(_errorMessageData);
    setIsCheckAll(false);
    setSelectedTopic([]);
  }

  let isChecked = (id) => {
    const _data = [...selectedTopic]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let onChangeSingleSelect = (e, val) => {
    if(!isWaitResponseInviteMember) {
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
      let _selectedTopic = [...selectedTopic]
  
      if (e.target.checked) {
        _selectedTopic = [..._selectedTopic, val]
      } else {
        const _dataSelectedTopic = [..._selectedTopic]
        _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
        setIsCheckAll(false);
      }
  
      setSelectedTopic(_selectedTopic);
    }
  }

  let removeSingleTopic = (val) => {
    setIsCheckAll(false);
    let _selectedTopic = [...selectedTopic];
    const _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    setSelectedTopic(_selectedTopic);
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
      for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
          if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
          }
        }
      }
    }

    setTopicList(result);
  }

  return (
    <>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
        dataOrigin={Object.assign({email: memberEmailOrigin}, {role: memberRoleOrigin}, {topic: topicListOrigin})}
        dataAfterChange={Object.assign({email: memberEmail}, {role: memberRole}, {topic: topicList})}
      >
        <BackButton text="Back to members" onClick={back} />
        {modalInvitationSuccess()}

        <div className="sectionWrap invite-member-wrapper">
          {isWaitLoadingTopicList || retriveDataError.code ?
            <RetrieveDataLoading
              noPaddingTop={true}
              isLoading={isWaitLoadingTopicList}
              errorMessage={retriveDataError.message}
            />
            :
            <>
              <div className="form-title-wrapper">
                <b>Invite Member</b>
              </div>

              <div className="form-content-wrapper form-member-invite">
                <form onSubmit={(e) => {
                  onSubmitInviteMember(e);
                }}>
                  <label className="pos-relative">
                    <b>Email Address</b>
                    <span className={errorMessageData.email === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{memberEmail.length}/250</span>
                  </label>
                  <input disabled={isWaitResponseInviteMember} className={`input-email ${errorMessageData.email !== "" ? "border-red" : ""}`} type="text" placeholder="your@email.com" value={memberEmail} onChange={(e) => {
                    onChangeEmailMember(e);
                  }} />

                  {errorMessageData.email !== "" &&
                      <ErrorOrInfoComp
                          text={errorMessageData.email}
                          _className={"font-red"}
                          icon={<FiInfo />}
                      />
                  }

                  <label className="input-role"><b>Organization Role</b></label>
                  <div className="custom-radio">
                    <input disabled={isWaitResponseInviteMember} type="radio" id="role-admin" name="role-radio" value={MEMBER_ROLE.admin} onChange={(e) => onChangeRoleMember(e)} />
                    <label htmlFor="role-admin">
                      <div className="role-radio-label">
                        <b>Admin</b>
                        <span>Access to organization including billing, technical, integrations, inbox and quick reply</span>
                      </div>
                    </label>

                    <input disabled={isWaitResponseInviteMember} type="radio" id="role-agent" name="role-radio" value={MEMBER_ROLE.agent} onChange={(e) => onChangeRoleMember(e)} />
                    <label htmlFor="role-agent">
                      <div className="role-radio-label">
                        <b>Agent</b>
                        <span>Access to inbox and Personal Quick Replies</span>
                      </div>
                    </label>
                  </div>

                  {errorMessageData.role !== "" &&
                    <ErrorOrInfoComp
                        text={errorMessageData.role}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                  }

                  <SelectTopic 
                    onChangeSearchTopic={onChangeSearchTopic}
                    onCheckAll={onCheckAll}
                    onClearAll={onClearAll}
                    topicList={topicList}
                    onChangeSingleSelect={onChangeSingleSelect}
                    isChecked={isChecked}
                    isCheckAll={isCheckAll}
                    searchTopic={searchTopic}
                    title={"Assign Topics"}
                    isTitleOptional
                    selectedTopic={selectedTopic}
                    removeSingleTopic={removeSingleTopic}
                    _className={"input-topic"}
                  />

                  {errorMessageData.topic !== "" &&
                      <ErrorOrInfoComp
                          text={errorMessageData.topic}
                          _className={"font-red"}
                          icon={<FiInfo />}
                      />
                  }
                  
                  {!isWaitResponseInviteMember &&
                    <ButtonWithLoadingOrIcon
                      // isDisabled={isButtonSubmitDisabled()}
                      className="orange-button main-button-40"
                      text="Send Invitation"
                    />
                  }
                </form>

                {isWaitResponseInviteMember &&
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    position="left"
                    className="orange-button main-button-40"
                    text="Send Invitation"
                  />
                }
              </div>
            </>
          }
        </div>
      </SectionWrap>
    </>
  );
};

export default MemberInvite;