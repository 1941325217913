import React, { useState, useEffect, useCallback, useRef } from 'react';
import './OpenCaseTab.scss';
import HelperChat from '../../../../helper/HelperChat';
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import { doToast, passInsertCase, getItemFromArray, generateElipsis } from '../../../../helper/HelperGeneral';
import RoomListNoChat from '../../roomListNoChat/RoomListNoChat';
import { connect } from 'react-redux';
import { FiRefreshCw, FiRotateCcw, FiEdit3, FiList } from 'react-icons/fi';
// import { MdPlayArrow } from "react-icons/md";
// import { Scrollbars } from 'react-custom-scrollbars';
import { taptalk } from '@taptalk.io/web-sdk';
import CaseService from "../../../../services/chat/caseServices";
import ClockService from "../../../../services/chat/clockServices";
import ServiceQuickReply from "../../../../services/newServices/QuickReplyService";
// import HelperDate from "../../../../helper/HelperDate";
import { VariableSizeList as VariableList } from 'react-window';
import fiedit from "../../../../assets/img/fiedit.svg";
import iconFilterWhite from "../../../../assets/img/icon-filter-inbox-white.svg";
import iconFilterGrey from "../../../../assets/img/icon-filter-inbox-grey.svg";
import soundNewCase from "../../../../assets/sound/new-unassigned.mp3";
import soundNewMessage from "../../../../assets/sound/new-message.mp3";
import CaseListSortBy from '../../../reuseableComponent/caseListSortBy/CaseListSortBy';
import PopupConfirmation from '../../../reuseableComponent/popupConfirmation/PopupConfirmation';
import ButtonWithLoadingOrIcon from '../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
import FilterBy from "../modalFilter/filterBy/FilterBy";
import { setQuickReplyList } from "../../../../redux/actions/reduxActionQuickReplyList";
import { clearIsWaitMarkAsJunk } from '../../../../redux/actions/reduxActionIsWaitMarkAsJunk';
import { clearChangeContactInfo } from "../../../../redux/actions/reduxActionChangeUserAlias";
import { clearUpdateCaseLabel } from "../../../../redux/actions/reduxActionCaseLabel";
import { setCaseSortBy } from "../../../../redux/actions/reduxActionCaseSortBy";
import { clearStartConversation } from '../../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../../redux/actions/reduxActionStartConversationBa';
import { setToggleMineCase } from "../../../../redux/actions/reduxActionToggleMineCase";
import { setClockState } from "../../../../redux/actions/reduxActionClockState";
import { setCaseListChecked, clearCaseListChecked } from "../../../../redux/actions/reduxActionCaseListChecked";
import CaseListComponent from "../caseListComponent/CaseListComponent";
import CaseListAction from "../../roomListTab/caseListAction/CaseListAction";
import { setReconnectRoomList } from "../../../../redux/actions/reduxActionReconnectRoomList";
import { clearCaseFilter } from "../../../../redux/actions/reduxActionCaseFilter";
import StartConversation from "../startConversation/StartConversation";
import mixpanel from "mixpanel-browser";
import { CASE_TYPES, ASSIGNEE_TYPE_AGENT } from '../../../../constants/inbox';

const DEFAULT_CELL_HEIGHT = 68;

const CLOCK_STATE = {
    isOff: "off",
    isStarted: "started",
    isPaused: "paused"
}

const DEFAULT_FILTER_TOPIC_AGENT = {
    value: 0,
    label: ""
}

const FILTER_MESSAGE = {
    date: "There are no cases for the selected dates. Please select different dates.",
    filter: "There are no cases for the selected filters. Please select different filter options.",
    all: "There are no cases for the selected filters and dates. Please select different filter options and dates."
}

const CONNECTING_STATUS = {
    disconnected: 1,
    loading: 2,
    connected: 3
};

const INITIAL_OPEN_CASE_PARAM_MINE = (reconnect = false) => ({
    topicID: 0,
    agentAccountID: 0,
    caseType: "self",
    search: '',
    lastID: 0,
    isSearchOrFilter: false,
    isReconnect: reconnect,
    isLoadMoreMine: false,
    label: [],
    junkStatus: ""
});


const INITIAL_OPEN_CASE_PARAM_UNASSIGNED = (reconnect = false) => ({
    topicID: 0,
    agentAccountID: 0,
    caseType: "unassigned",
    search: '',
    lastID: 0,
    isSearchOrFilter: false,
    isReconnect: reconnect,
    isLoadMoreUnassigned: false,
    label: [],
    junkStatus: ""
});

// var style = {
//     scrollStyle: {
//       position: "relative",
//       backgroundColor: "#ff7d00",
//       right: "-5px",
//       width: "3px",
//       borderRadius: "8px"
//     }
// };

const SORT_MESSAGE_BY = {
    last_message: "last_message",
    created_time: "created_time"
}

const LIST_TYPE = {
    MINE: 1, //chatDataFilteredMine
    UNASSIGNED: 2, // chatDataFilteredUnassigned
    LAST_ASSIGNED_MINE: 3, // lastAssignedMineCase
    WITH_LAST_ASSIGNED_MINE: 4, // chatDataFilteredWithLastAssigned
}

const MESSAGE_TYPE = {
    CASE_CREATED: 3005,
    CASE_CLOSED: 3001,
    CASE_REOPEN: 3002,
    CASE_RATING_BUTTON: 3003,
    CASE_RATING_BUTTON_DISABLED: 3004,
    CASE_UPDATE_AGENT_CHANGE: 3006,
    CASE_UPDATE_DETAIL_UPDATE: 3007
}

// const PREFIX_CASE = 'case#';

let ListCaseComponent = (props) => {
    let { 
        caseLabel, dataLength, containerHeight, row, mainProps,
        fetchingUnassignedCaseIsErrorProps, fetchingUnassignedCaseProps,
        fetchingMineCaseIsErrorProps, fetchingMineCaseProps,
        onClickRetryMoreCaseUnassignedProps, onScrollRoomListListenerProps,
        listNameProps, needReplyLengthProps,
        lastAssignedMineCaseProps, isChatDataFilteredWithLastAssignedMine,
        isLastCaseHavingMarginBottomProps, refProps, needReplyData 
    } = props;

    let arrayOfRowHeight = [];

    let generateRowHeight = () => {
        arrayOfRowHeight = [];

        Object.keys(props.case).map((value, index) => {
            if (props.case[value].isLastAssigned) {
                if (Object.keys(lastAssignedMineCaseProps).length === 1) {
                    if (props.case[value].labelIDs && props.case[value].labelIDs.length > 0 && caseLabel.length > 0) {
                        arrayOfRowHeight.push(127 + 28 + 28);
                    } else {
                        arrayOfRowHeight.push(100 + 28 + 28);
                    }
                } else {
                    if ((index === 0) || (index === Object.keys(lastAssignedMineCaseProps).length - 1)) {
                        if (props.case[value].labelIDs && props.case[value].labelIDs.length > 0 && caseLabel.length > 0) {
                            arrayOfRowHeight.push(127 + 28);
                        } else {
                            arrayOfRowHeight.push(100 + 28);
                        }
                    } else {
                        if (props.case[value].labelIDs && props.case[value].labelIDs.length > 0 && caseLabel.length > 0) {
                            arrayOfRowHeight.push(127);
                        } else {
                            arrayOfRowHeight.push(100);
                        }
                    }
                }
            } else {
                if (props.case[value].labelIDs && props.case[value].labelIDs.length > 0 && caseLabel.length > 0) {
                    arrayOfRowHeight.push(listNameProps === "list-mine-case" ? 127 : 101);
                } else {
                    arrayOfRowHeight.push(listNameProps === "list-mine-case" ? 100 : 74);
                }
            }

            return null;
        })
    }

    generateRowHeight();

    const getItemSize = (index) => {
        return arrayOfRowHeight[index];
    }

    let arrayOfLabel = []
    const generateLabelCase = () => {
        Object.keys(props.case).map((value, index) => {
            if (props.case[value].labelIDs && caseLabel) {
                if (props.case[value].labelIDs.length > 0) {
                    if (caseLabel.length > 0) {
                        const labels = caseLabel.filter((el) => {
                            return props.case[value].labelIDs.find(val => val === el.id)
                        });

                        const labelCase = [...labels]
                        // console.log('labelCase', labelCase)
                        arrayOfLabel.push([...Array(labelCase.length).fill(false)])
                        // arrayOfLabel.push(labelCase)
                        // setHiddenUnassigned(labelCase)
                    }
                }
            }
            return null
        })
    }
    generateLabelCase()

    return (
        <VariableList
            className={`List all-case-list
                        ${listNameProps}
                        ${needReplyLengthProps > 0 ? "need-reply-show" : ""}
                        ${listNameProps === 'list-unassigned-case' ? 'last-case-no-margin-bottom' : ''}
                        ${!isLastCaseHavingMarginBottomProps ? "last-case-no-margin-bottom" : ""}
            `}
            height={containerHeight}
            itemCount={dataLength}
            itemSize={getItemSize}
            width={"100%"}
            onScroll={onScrollRoomListListenerProps}
            ref={refProps}
            itemData={{
                whichCase: listNameProps,
                caseData: props.case,
                mainProps: mainProps,
                caseLabel: caseLabel,
                labelHidden: arrayOfLabel,
                _fetchingUnassignedCaseIsErrorProps: fetchingUnassignedCaseIsErrorProps,
                _fetchingUnassignedCaseProps: fetchingUnassignedCaseProps,
                _fetchingMineCaseIsErrorProps: fetchingMineCaseIsErrorProps,
                _fetchingMineCaseProps: fetchingMineCaseProps,
                _onClickRetryMoreCaseUnassignedProps: onClickRetryMoreCaseUnassignedProps,
                _lastAssignedMineCaseProps: lastAssignedMineCaseProps,
                _isChatDataFilteredWithLastAssignedMine: isChatDataFilteredWithLastAssignedMine,
                _refProps: refProps,
                _needReplyData: needReplyData,
                _dataLength: dataLength
            }}
        >
            {row}
        </VariableList>
    )
};

const getLabelCase = (labelIDs, labelList) => {
    if (labelIDs && labelList) {
        if (labelIDs.length > 0) {
            if (labelList.length > 0) {
                let newLabel = [];

                labelIDs.map((v) => {
                    if(getItemFromArray(labelList, "id", v)) {
                        newLabel.push(getItemFromArray(labelList, "id", v))
                    }else {
                        newLabel.push({name: "", id: ""})
                    }
                    return null;
                })
                
                const labelCase = [...newLabel]
                return labelCase
            }
        }
    }
    return []
}

const LabelItem = ({ label, container, index, isItemHidden, setIsItemHidden }) => {
    const itemRef = useRef();

    // useEffect(() => {
    //     if (!container.current) return;

    //     const { offsetLeft, offsetWidth } = itemRef.current;
    //     const copyIsItemHidden = [...isItemHidden];

    //     if (offsetLeft + offsetWidth > container.current.offsetWidth) {
    //         copyIsItemHidden[index] = true;
    //         setIsItemHidden(copyIsItemHidden);
    //     }
    // }, [index, container, isItemHidden, setIsItemHidden]);

    return (
        <div key={`label-${label.id}`} className="case-tab-label-content" style={{ background: label.backgroundColor, display: isItemHidden[index] ? "none" : "inline-block" }} ref={itemRef} >
            <b>{generateElipsis(label.name, 13)}</b>
        </div>
    );
};

let printRowCaseList = ({ index, style, data }) => {
    let myAgentAccountID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
    let caseDetail = data.caseData[Object.keys(data.caseData)[index]];
    let message = data.caseData[Object.keys(data.caseData)[index]].tapTalkRoom;
    let _props = data.mainProps;
    let _needReplyData = data._needReplyData;
    
    const containerRef = useRef()

    const labelCase = getLabelCase(caseDetail.labelIDs, data.caseLabel)

    const hidden = data.labelHidden[index] ? data.labelHidden[index] : []

    const [isItemHidden, setIsItemHidden] = useState([...hidden])
    
    // useEffect(() => {
    //     console.log("data", data.labelHidden)
    //     setIsItemHidden(hidden)
    // }, [data.labelHidden])

    // const totalHidden = () => {
    //     let totalHiddenItem = 0;
    //     if (isItemHidden) {
    //         for (let hideItem of isItemHidden) {
    //             if (hideItem === true) totalHiddenItem++;
    //         }
    //     }
    //     return totalHiddenItem;
    // };

    let onClickOpenCaseRoom = (room, caseData) => {
        _props.clearStartConversation();
        _props.clearStartConversationBa();
        _props.onClickRoomListProps(room, caseData)
    }

    return (
        <div style={style}>
            {(index === 0 && caseDetail.isLastAssigned) &&
                <div className="case-list-separator">
                    <b>Sort by Last Assigned</b>
                </div>
            }

            <CaseListComponent 
                caseDetail={caseDetail}
                roomType={data.whichCase === "list-mine-case" ? "mine" : "not-mine"}
                message={message}
                myAgentAccountID={myAgentAccountID}
                labelCase={labelCase}
                // totalHidden={totalHidden()}
                _containerRef={containerRef}
                LabelItem={LabelItem}
                isItemHidden={isItemHidden}
                setIsItemHidden={setIsItemHidden}
                onClickCase={onClickOpenCaseRoom}
                checkedType={`${data.whichCase === "list-mine-case" ? "mine" : "unassigned"}`}
                allCase={data.caseData}
                needReplyData={_needReplyData}
            />

            {((index === Object.keys(data._lastAssignedMineCaseProps).length - 1) && caseDetail.isLastAssigned) &&
                <div className="case-list-separator with-border-top">
                    <b>Sort by Case Created</b>
                </div>
            }

            {(index === (data._dataLength - 1)) &&
                <React.Fragment>
                    <div className="fetch-solved-case">
                        {(data._fetchingUnassignedCaseProps || data._fetchingMineCaseProps) &&
                            <div className="fetch-solved-case-content">
                                <div className="lds-ring">
                                    <div /><div /><div /><div />
                                </div>

                                <b>More Cases</b>
                            </div>
                        }

                        {(data._fetchingUnassignedCaseIsErrorProps || data._fetchingMineCaseIsErrorProps) &&
                            <div className="fetch-solved-case-content no-fill-button retry-fetch"
                                onClick={() => data._onClickRetryMoreCaseProps()}
                            >
                                <FiRefreshCw />

                                <b>Retry Fetch</b>
                            </div>
                        }
                    </div>

                    {/* {(data._fetchingUnassignedCaseIsErrorProps || data._fetchingMineCaseIsErrorProps) &&
                        <div className="fetch-solved-case">
                            <div className="fetch-solved-case-content no-fill-button retry-fetch"
                                onClick={() => data._onClickRetryMoreCaseProps()}
                            >
                                <FiRefreshCw />

                                <b>Retry Fetch</b>
                            </div>
                        </div>
                    } */}
                </React.Fragment>
            }
        </div>
    )
};

var OpenCaseTab = (props) => {
    let [isRoomListUnassignedLoadingFinish, setIsRoomListUnassignedLoadingFinish] = useState(false);
    let [chatDataFilteredUnassigned, setChatDataFilteredUnassigned] = useState({});
    // let [totalUnassigned, setTotalUnassigned] = useState(0);

    // let [openCaseUnassignedList, setOpenCaseUnassignedList] = useState({});

    let [isRoomListMineLoadingFinish, setIsRoomListMineLoadingFinish] = useState(false);
    let [chatDataFilteredMine, setChatDataFilteredMine] = useState({});
    // let [totalMine, setTotalMine] = useState(0);

    // let [openCaseMineList, setOpenCaseMineList] = useState({});

    let [hashMapTypingTimeout, setHashMapTypingTimeout] = useState({});

    let [panelMineCaseHeight, setPanelMineCaseHeight] = useState(window.innerHeight - 251); // - 196 256
    let [panelUnassignedHeight, setPanelUnassignedHeight] = useState(window.innerHeight - 251); // -196 256

    let [openCaseSoundNotifEnable, setOpenCaseSoundNotifEnable] = useState(false);
    let [onDelaySound, setOnDelaySound] = useState(false);

    let [hasMoreUnassignedCase, setHasMoreUnassignedCase] = useState(false);
    let [hasMoreMineCase, setHasMoreMineCase] = useState(false);

    let [fetchingUnassignedCase, setFetchingUnassignedCase] = useState(false);
    let [fetchingUnassignedCaseIsError, setFetchingUnassigendCaseIsError] = useState(false);

    let [fetchingMineCase, setFetchingMineCase] = useState(false);
    let [fetchingMineCaseIsError, setFetchingMineCaseIsError] = useState(false);

    // let [isOpenCaseMineActive, setIsOpenCaseMineActive] = useState(false);

    let [needReplyDataUnassigned, setNeedReplyDataUnassigned] = useState({});
    // let [totalUnreadCountUnassigned, setTotalUnreadCountUnassigned] = useState(0);

    let [needReplyDataMine, setNeedReplyDataMine] = useState({});
    // let [totalUnreadCountMine, setTotalUnreadCountMine] = useState(0);

    // let [openCaseMineSortBy, setOpenCaseMineSortBy] = useState(props.caseSortBy.mine);
    // let [openCaseUnassignedSortBy, setOpenCaseUnassignedSortBy] = useState(props.caseSortBy.unassigned);
    let [isShowOpenCaseSortBy, setIsShowOpenCaseSortBy] = useState(false);

    let [lastAssignedMineCase, setLastAssignedMineCase] = useState({});

    let [isShowModalStartConversation, setIsShowModalStartConversation] = useState(false);

    let [filterSearchValueMine, setFilterSearchValueMine] = useState('');
    // let [onSearchCaseMine, setOnSearchCaseMine] = useState(false);
    let [isLoadingSearchOpenCaseFinishMine, setIsLoadingSearchOpenCaseFinishMine] = useState(true);
    let [filterSearchValueUnassigned, setFilterSearchValueUnassigned] = useState('');
    // let [onSearchCaseUnassigned, setOnSearchCaseUnassigned] = useState(false);
    let [isLoadingSearchOpenCaseFinishUnassigned, setIsLoadingSearchOpenCaseFinishUnassigned] = useState(true);
    let [isShowSearchBox, setIsShowSearchBox] = useState(false);

    //filter
    let [filterTopicValueMine, setFilterTopicValueMine] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValueMine, setFilterAgentValueMine] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValueMine, setFilterMediumValueMine] = useState([]);
    let [filterCaseTypeMine, setFilterCaseTypeMine] = useState(CASE_TYPES.allTypes.value);

    let [filterTopicValueMineBefore, setFilterTopicValueMineBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValueMineBefore, setFilterAgentValueMineBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValueMineBefore, setFilterMediumValueMineBefore] = useState([]);
    let [filterCaseTypeMineBefore, setFilterCaseTypeMineBefore] = useState(CASE_TYPES.allTypes.value);

    let [searchNotFoundMine, setSearchNotFoundMine] = useState(false);
    let [isSearchActiveMine, setIsSearchActiveMine] = useState(false);
    let [showModalFilterTopicAgentMine, setShowModalFilterTopicAgentMine] = useState(false);
    let [isResetFilterMine, setIsResetFilterMine] = useState(false);
    let [isFilterTopicAgentChannelActiveMine, setIsFilterTopicAgentChannelActiveMine] = useState(false);

    let [filterTopicValueUnassigned, setFilterTopicValueUnassigned] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValueUnassigned, setFilterAgentValueUnassigned] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValueUnassigned, setFilterMediumValueUnassigned] = useState([]);
    let [filterCaseTypeUnassigned, setFilterCaseTypeUnassigned] = useState(CASE_TYPES.allTypes.value);

    let [filterTopicValueUnassignedBefore, setFilterTopicValueUnassignedBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValueUnassignedBefore, setFilterAgentValueUnassignedBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValueUnassignedBefore, setFilterMediumValueUnassignedBefore] = useState([]);
    let [filterCaseTypeUnassignedBefore, setFilterCaseTypeUnassignedBefore] = useState(CASE_TYPES.allTypes.value);

    let [searchNotFoundUnassigned, setSearchNotFoundUnassigned] = useState(false);
    let [isSearchActiveUnassigned, setIsSearchActiveUnassigned] = useState(false);
    let [showModalFilterTopicAgentUnassigned, setShowModalFilterTopicAgentUnassigned] = useState(false);
    let [isResetFilterUnassigned, setIsResetFilterUnassigned] = useState(false);
    let [isFilterTopicAgentChannelActiveUnassigned, setIsFilterTopicAgentChannelActiveUnassigned] = useState(false);

    let [topicList, setTopicList] = useState([]);
    let [agentList, setAgentList] = useState(null);
    //filter

    // const [isLoadingGetLabelList, setIsLoadingGetLabelList] = useState(false)

    const [filterLabelMine, setFilterLabelMine] = useState([])
    const [filterLabelUnassigned, setFilterLabelUnassigned] = useState([])
    const [filterLabelMineBefore, setFilterLabelMineBefore] = useState([])
    const [filterLabelUnassignedBefore, setFilterLabelUnassignedBefore] = useState([])
    
    let [isShowModalStartClock, setIsShowModalStartClock] = useState(false);
    let [isWaitResponseStartClock, setIsWaitResponseStartClock] = useState(false);

    let [tabIDVal, setTabIDVal] = useState(false);
    let [lastBulkJunkMine, setLastBulkJunkMine] = useState({
        time: false,
        arrayOfRoomIDs: []
    })
    
    let [lastBulkSolveMine, setLastBulkSolveMine] = useState({
        time: false,
        arrayOfRoomIDs: []
    })
    let [lastBulkJunkUnassigned, setLastBulkJunkUnassigned] = useState({
        time: false,
        arrayOfRoomIDs: []
    })
    
    let [lastBulkSolveUnassigned, setLastBulkSolveUnassigned] = useState({
        time: false,
        arrayOfRoomIDs: []
    })

    //need reply unassigned count
    useEffect(() => {
        let _chatDataFilteredUnassigned = {...chatDataFilteredUnassigned};
        let _needReply = {};

        if(Object.keys(_chatDataFilteredUnassigned).length > 0) {
            Object.keys(_chatDataFilteredUnassigned).map((v, i) => {
                if (!_chatDataFilteredUnassigned[v].isJunk
                ) {
                    _needReply[_chatDataFilteredUnassigned[v].tapTalkRoom.lastMessage.room.roomID] = _chatDataFilteredUnassigned[v];
                }
                
                return null;
            })

            setNeedReplyDataUnassigned(_needReply);
        }
    }, [chatDataFilteredUnassigned])

    //bulk
    useEffect(() => {
        if(lastBulkJunkMine.time) {
            actionJunkBulkMine(lastBulkJunkMine.arrayOfRoomIDs.slice())
        }
    }, [lastBulkJunkMine])

    useEffect(() => {
        if(lastBulkSolveMine.time) {
            actionRemoveFromRoomListBulkMine(lastBulkSolveMine.arrayOfRoomIDs.slice())
        }
    }, [lastBulkSolveMine])

    useEffect(() => {
        if(lastBulkJunkUnassigned.time) {
            actionJunkBulkUnassigned(lastBulkJunkUnassigned.arrayOfRoomIDs.slice())
        }
    }, [lastBulkJunkUnassigned])

    useEffect(() => {
        if(lastBulkSolveUnassigned.time) {
            actionRemoveFromRoomListBulkUnassigned(lastBulkSolveUnassigned.arrayOfRoomIDs.slice())
        }
    }, [lastBulkSolveUnassigned])

    let removeFromNeedReplyBulkMine = (roomIDs) => {
        let _needReplyData = { ...needReplyDataMine };

        roomIDs.map((v) => {
            if (_needReplyData[v]) {
                delete _needReplyData[v];
            }

            return null;
        })
        setNeedReplyDataMine(_needReplyData);
    }

    let actionRemoveFromRoomListBulkMine = (roomIDs) => {
        let _data = { ...chatDataFilteredMine };

        removeFromNeedReplyBulkMine(roomIDs)

        roomIDs.map((v) => {
            if (_data[v]) {
                delete _data[v];
            }
          
            return null;
        })

        setChatDataFilteredMine(_data);
    }

     let actionJunkBulkMine = (roomIDs) => {
        let _data = { ...chatDataFilteredMine };

        roomIDs.map((v) => {
            if (_data[v]) {
                _data[v].isJunk = true;
            }
          
            return null;
        })

        setChatDataFilteredMine(_data);
        setLastBulkJunkMine({
            time: false,
            arrayOfRoomIDs: []
        });
    }

    let removeFromNeedReplyBulkUnassigned = (roomIDs) => {
        let _needReplyData = { ...needReplyDataUnassigned };

        roomIDs.map((v) => {
            if (_needReplyData[v]) {
                delete _needReplyData[v];
            }

            return null;
        })
        setNeedReplyDataUnassigned(_needReplyData);
    }

    let actionRemoveFromRoomListBulkUnassigned = (roomIDs) => {
        let _data = { ...chatDataFilteredUnassigned };

        removeFromNeedReplyBulkUnassigned(roomIDs)

        roomIDs.map((v) => {
            if (_data[v]) {
                delete _data[v];
            }
          
            return null;
        })

        setChatDataFilteredUnassigned(_data);
    }

     let actionJunkBulkUnassigned = (roomIDs) => {
        let _data = { ...chatDataFilteredUnassigned };

        roomIDs.map((v) => {
            if (_data[v]) {
                _data[v].isJunk = true;
            }
          
            return null;
        })

        setChatDataFilteredUnassigned(_data);
        setLastBulkJunkUnassigned({
            time: false,
            arrayOfRoomIDs: []
        });
    }
    //bulk

    //reconnect
    useEffect(() => {
        let _reconRoomList = props.reconnectRoomList;

        if(_reconRoomList && _reconRoomList > 0) {
            getOpenCaseList(INITIAL_OPEN_CASE_PARAM_MINE(true));
            getOpenCaseList(INITIAL_OPEN_CASE_PARAM_UNASSIGNED(true));

            props.setReconnectRoomList(0);
        }
    }, [props.reconnectRoomList])
    //reconnect
    
    //sound notif
    useEffect(() => {
        let _l = localStorage.getItem("OneTalk.tabKey");
        let rand =  Math.floor((Math.random())*(1000-1+1))+1;
        if(!_l) {
            localStorage.setItem("OneTalk.tabKey", rand);
            setTabIDVal(rand);
        }

        // if(localStorage.getItem("OneTalk.tabKey") === rand) {
        //     window.addEventListener("unload", function () {
        //         localStorage.removeItem("OneTalk.tabKey");
        //     });
        // }

        window.addEventListener("storage", function(evt) {
            if (evt.key === "OneTalk.tabKey" && evt.newValue === null) {
                if (localStorage.getItem("OneTalk.tabKey") === null) {
                    setTabIDVal(rand);
                    localStorage.setItem("OneTalk.tabKey", rand);
                }
            }
        });

        window.addEventListener("unload", function () {
            localStorage.removeItem("OneTalk.tabKey");
        });
    }, [])
    //sound notif

    useEffect(() => {
        let _defData = {
            isShow: false,
            data: {},
            checkedAll: false
        };

        let _caseListChecked = {...props.caseListChecked};

        _caseListChecked["mine"] = _defData;
        _caseListChecked["unassigned"] = _defData;

        getQuickReplyList();
        props.setCaseListChecked(_caseListChecked);
    }, [props.toggleMineCase]);

    useEffect(() => {
        let _changeContactInfo = props.changeContactInfo;
        let _chatDataFilteredMine = { ...chatDataFilteredMine };
        let _chatDataFilteredUnassigned = { ...chatDataFilteredUnassigned };
        let _lastAssignedMineCase = { ...lastAssignedMineCase };

        if (_changeContactInfo.state) {
            let changeChatFilteredMine = () => {
                Object.keys(_chatDataFilteredMine).map((value, index) => {
                    if (_chatDataFilteredMine[value].userID === _changeContactInfo.userID) {
                        _chatDataFilteredMine[value].userAlias = _changeContactInfo.alias;
                        _chatDataFilteredMine[value].userFullName = _changeContactInfo.fullname;
                        _chatDataFilteredMine[value].userPhone = _changeContactInfo.phone;
                        _chatDataFilteredMine[value].userEmail = _changeContactInfo.email;
                    }

                    return null;
                })

                setChatDataFilteredMine(_chatDataFilteredMine);
            }

            let changeChatFilteredUnassigned = () => {
                Object.keys(_chatDataFilteredUnassigned).map((value, index) => {
                    if (_chatDataFilteredUnassigned[value].userID === _changeContactInfo.userID) {
                        _chatDataFilteredUnassigned[value].userAlias = _changeContactInfo.alias;
                    }

                    return null;
                })

                setChatDataFilteredUnassigned(_chatDataFilteredUnassigned);
            }

            let changeChatFilteredLastAssign = () => {
                Object.keys(_lastAssignedMineCase).map((value, index) => {
                    if (_lastAssignedMineCase[value].userID === _changeContactInfo.userID) {
                        _lastAssignedMineCase[value].userAlias = _changeContactInfo.alias;
                    }

                    return null;
                })

                setLastAssignedMineCase(_lastAssignedMineCase);
            }

            changeChatFilteredMine();
            changeChatFilteredUnassigned();
            changeChatFilteredLastAssign();
            props.clearChangeContactInfo();
        }
    }, [props.changeContactInfo])

    useEffect(() => {
        let isRunUpdateCaseLabelProps = props.updateCaseLabel;

        let _chatDataFilteredMine = { ...chatDataFilteredMine };
        let _chatDataFilteredUnassigned = { ...chatDataFilteredUnassigned };
        let _lastAssignedMineCase = { ...lastAssignedMineCase };

        if (isRunUpdateCaseLabelProps.state) {
            const roomID = isRunUpdateCaseLabelProps.roomID.roomID;

            if (_chatDataFilteredUnassigned[roomID]) {
                if (_chatDataFilteredUnassigned[roomID].id === isRunUpdateCaseLabelProps.id) {
                    if (isRunUpdateCaseLabelProps.action === 'add') {
                        _chatDataFilteredUnassigned[roomID].labelIDs = [..._chatDataFilteredUnassigned[roomID].labelIDs, ...isRunUpdateCaseLabelProps.labelIDs];
                    } else if (isRunUpdateCaseLabelProps.action === 'remove') {
                        const removeLabelIDs = [...isRunUpdateCaseLabelProps.labelIDs]
                        const currentLabelIDs = [..._chatDataFilteredUnassigned[roomID].labelIDs]
                        const newLabelIDs = currentLabelIDs.filter(labelID => {
                            return !removeLabelIDs.find(val => val === labelID)
                        })
                        _chatDataFilteredUnassigned[roomID].labelIDs = [...newLabelIDs]
                    }
                }
                setChatDataFilteredUnassigned(_chatDataFilteredUnassigned);
            }

            if (_chatDataFilteredMine[roomID]) {
                if (_chatDataFilteredMine[roomID].id === isRunUpdateCaseLabelProps.id) {
                    if (isRunUpdateCaseLabelProps.action === 'add') {
                        _chatDataFilteredMine[roomID].labelIDs = [..._chatDataFilteredMine[roomID].labelIDs, ...isRunUpdateCaseLabelProps.labelIDs];
                    } else if (isRunUpdateCaseLabelProps.action === 'remove') {
                        const removeLabelIDs = [...isRunUpdateCaseLabelProps.labelIDs]
                        const currentLabelIDs = [..._chatDataFilteredMine[roomID].labelIDs]
                        const newLabelIDs = currentLabelIDs.filter(labelID => {
                            return !removeLabelIDs.find(val => val === labelID)
                        })
                        _chatDataFilteredMine[roomID].labelIDs = [...newLabelIDs]
                    }
                }
                setChatDataFilteredMine(_chatDataFilteredMine);
            }

            if (_lastAssignedMineCase[roomID]) {
                if (_lastAssignedMineCase[roomID].id === isRunUpdateCaseLabelProps.id) {
                    if (isRunUpdateCaseLabelProps.action === 'add') {
                        _lastAssignedMineCase[roomID].labelIDs = [..._lastAssignedMineCase[roomID].labelIDs, ...isRunUpdateCaseLabelProps.labelIDs];
                    } else if (isRunUpdateCaseLabelProps.action === 'remove') {
                        const removeLabelIDs = [...isRunUpdateCaseLabelProps.labelIDs]
                        const currentLabelIDs = [..._lastAssignedMineCase[roomID].labelIDs]
                        const newLabelIDs = currentLabelIDs.filter(labelID => {
                            return !removeLabelIDs.find(val => val === labelID)
                        })
                        _lastAssignedMineCase[roomID].labelIDs = [...newLabelIDs]
                    }
                }
                setLastAssignedMineCase(_lastAssignedMineCase);
            }
            props.clearUpdateCaseLabel();
        }

    }, [props.updateCaseLabel])
    
    let lastAssignedMineCaseReff = useCallback(node => {
        if (node !== null) {
            node.resetAfterIndex(0);
        }
    }, [chatDataFilteredMine]);

    let lastUnassignedMineCaseReff = useCallback(node => {
        if (node !== null) {
            node.resetAfterIndex(0);
        }
    }, [chatDataFilteredUnassigned]);

    let onScrollRoomListListener = (whichList) => {
        let el = document.getElementsByClassName(whichList)[0];
        if (el && ((!fetchingUnassignedCase && !fetchingUnassignedCaseIsError) || (!fetchingMineCase && !fetchingMineCaseIsError))) {
            if ((el.scrollHeight - el.scrollTop - DEFAULT_CELL_HEIGHT) <= el.clientHeight) {
                runMoreOpenCase(whichList === "list-mine-case" ? "self" : "unassigned");
            }
        }
    };

    let onClickRetryMoreCase = (whichList) => {
        runMoreOpenCase(whichList);
    };

    let runMoreOpenCase = (whichList) => {
        let _chatDataFiltered = whichList === "self" ? { ...chatDataFilteredMine } : { ...chatDataFilteredUnassigned };

        if (whichList === "self" && hasMoreMineCase && Object.keys(_chatDataFiltered).length > 0) {
            setFetchingMineCase(true);

            getOpenCaseList({
                topicID: filterTopicValueMine.value === 0 ? 0 : filterTopicValueMine.value,
                // agentAccountID: filterAgentValueMine.value === 0 ? 0 : filterAgentValueMine.value,
                mediums: filterMediumValueMine,
                search: filterSearchValueMine,
                lastID: _chatDataFiltered[Object.keys(_chatDataFiltered)[Object.keys(_chatDataFiltered).length - 1]].id,
                isSearchOrFilter: false,
                isReconnect: false,
                isLoadMoreMine: true,
                caseType: "self",
                label: filterLabelMine,
                junkStatus: filterCaseTypeMine
            });
        }

        if (whichList === "unassigned" && hasMoreUnassignedCase && Object.keys(_chatDataFiltered).length > 0) {
            setFetchingUnassignedCase(true);

            getOpenCaseList({
                topicID: filterTopicValueUnassigned.value === 0 ? 0 : filterTopicValueUnassigned.value,
                // agentAccountID: filterAgentValueUnassigned.value === 0 ? 0 : filterAgentValueUnassigned.value,
                mediums: filterMediumValueUnassigned,
                search: filterSearchValueUnassigned,
                lastID: _chatDataFiltered[Object.keys(_chatDataFiltered)[Object.keys(_chatDataFiltered).length - 1]].id,
                isSearchOrFilter: false,
                isReconnect: false,
                isLoadMoreUnassigned: true,
                caseType: "unassigned",
                label: filterLabelUnassigned,
                junkStatus: filterCaseTypeUnassigned
            });
        }
    }

    let playSoundNotification = async (sound) => {
        if(parseInt(tabIDVal) === parseInt(localStorage.getItem("OneTalk.tabKey"))) {
            let soundNewMessage = document.getElementById("sound-new-message");
            let soundNewCase = document.getElementById("sound-new-case");
    
            let doSound = async () => {
                if (openCaseSoundNotifEnable) {
                    if (sound === "message") {
                        // if(soundNewCase.paused) {
                        soundNewMessage.play();
                        // }
                    } else {
                        soundNewCase.play();
                    }
                } else {
                    return;
                }
            }
            
            if (!onDelaySound) {
                setOnDelaySound(true);
                doSound();
            } else {
                return;
            }
        }
    }

    let removeFromNeedReply = (whichList, roomID) => {
        let _needReplyData = whichList === LIST_TYPE.MINE ? { ...needReplyDataMine } : { ...needReplyDataUnassigned };

        if (_needReplyData[roomID]) {
            delete _needReplyData[roomID];
            whichList === LIST_TYPE.MINE ? setNeedReplyDataMine(_needReplyData) : setNeedReplyDataUnassigned(_needReplyData);
        }
    }

    let addNeedReplyCounter = (whichList, data) => {
        if(data !== "" && !data.isJunk) {
            let _needReplyData = whichList === LIST_TYPE.MINE ? { ...needReplyDataMine } : { ...needReplyDataUnassigned };
            
            if (data.tapTalkRoom.lastMessage.user.userRole.code !== "agent" &&
                data.tapTalkRoom.unreadCount === 0 &&
                data.tapTalkRoom.lastMessage.user.userID !== taptalk.getTaptalkActiveUser().userID
            ) {
                _needReplyData[data.tapTalkRoom.lastMessage.room.roomID] = data;
                
                if(props.needReplyColorIndicator) {
                    _needReplyData[data.tapTalkRoom.lastMessage.room.roomID] = HelperChat.needReplyLevelCount(props.needReplyColorIndicator, _needReplyData[data.tapTalkRoom.lastMessage.room.roomID]);
                }
    
                whichList === LIST_TYPE.MINE ? setNeedReplyDataMine(_needReplyData) : setNeedReplyDataUnassigned(_needReplyData);
            }
        }
    }

    let actionUpdateRoomList = (message, isUpdate = false) => {
        let myAgentAccountID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
        let _chatDataFilteredMine = { ...chatDataFilteredMine };
        let _chatDataFilteredUnassigned = { ...chatDataFilteredUnassigned };
        let _lastAssignedMineCase = { ...lastAssignedMineCase };

        let isEmitWasNotCaseDetailUpdate = (messageType) => {
            return (
                messageType !== MESSAGE_TYPE.CASE_CREATED &&
                messageType !== MESSAGE_TYPE.CASE_UPDATE_DETAIL_UPDATE &&
                messageType !== MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE &&
                messageType !== MESSAGE_TYPE.CASE_CLOSED &&
                messageType !== MESSAGE_TYPE.CASE_RATING_BUTTON &&
                messageType !== MESSAGE_TYPE.CASE_RATING_BUTTON_DISABLED &&
                messageType !== MESSAGE_TYPE.CASE_REOPEN
            )
        }

        let isMessageInCaseMine = (message) => {
            return _chatDataFilteredMine[message.room.roomID];
        }

        let isMessageInCaseUnassigned = (message) => {
            return _chatDataFilteredUnassigned[message.room.roomID];
        }

        // let isMessageInLastAssignedMineCase = (message) => {
        //     return _lastAssignedMineCase[message.room.roomID];
        // }

        let actionRemoveFromRoomList = (type) => {
            let _data;

            switch (type) {
                case LIST_TYPE.LAST_ASSIGNED_MINE:
                    _data = { ...lastAssignedMineCase };
                    break;
                case LIST_TYPE.MINE:
                    _data = { ...chatDataFilteredMine };
                    break;
                case LIST_TYPE.UNASSIGNED:
                    _data = { ...chatDataFilteredUnassigned };
                    break;
                default:
                    break;
                // case LIST_TYPE.WITH_LAST_ASSIGNED_MINE:
                //     _data = {...chatDataFilteredWithLastAssignedMine};
                //     break;
            }

            if (_data[message.room.roomID]) {
                delete _data[message.room.roomID];

                //set case data
                if (Object.keys(_data).length === 0) {
                    if (type === LIST_TYPE.LAST_ASSIGNED_MINE) {
                        setLastAssignedMineCase({});
                    }

                    if (type === LIST_TYPE.MINE) {
                        setChatDataFilteredMine({});
                    }

                    if (type === LIST_TYPE.UNASSIGNED) {
                        setChatDataFilteredUnassigned({});
                    }

                    // if(type === LIST_TYPE.WITH_LAST_ASSIGNED_MINE) {
                    //     setChatDataFilteredWithLastAssignedMine({});
                    // }
                } else {
                    if (type === LIST_TYPE.LAST_ASSIGNED_MINE) {
                        setLastAssignedMineCase(_data);
                    }

                    if (type === LIST_TYPE.MINE) {
                        setChatDataFilteredMine(_data);
                    }

                    if (type === LIST_TYPE.UNASSIGNED) {
                        setChatDataFilteredUnassigned(_data);
                    }

                    // if(type === LIST_TYPE.WITH_LAST_ASSIGNED_MINE) {
                    //     setChatDataFilteredWithLastAssignedMine(_data);
                    // }
                }
                //set case data

                //set total case
                // if (type === LIST_TYPE.MINE) {
                //     setTotalMine(totalMine => totalMine - 1);
                // }

                // if (type === LIST_TYPE.UNASSIGNED) {
                //     setTotalUnassigned(totalUnassigned => totalUnassigned - 1);
                // }
                //set total case

                //handle need reply

                //handle need reply
            }
        }

        if ((myAgentAccountID !== parseInt(message.user.xcUserID.split("agent:")[1])) && _chatDataFilteredMine[message.room.roomID] && isEmitWasNotCaseDetailUpdate(message.type) && !isUpdate) {
            playSoundNotification("message");
            if ('Notification' in window) {
                HelperChat.showNotificationMessage(message);
            }
        }

        //update last message and unread
        if (isEmitWasNotCaseDetailUpdate(message.type)) {
            if (isMessageInCaseUnassigned(message)) {
                HelperChat.setRoomListLastMessage(message, _chatDataFilteredUnassigned, props.caseSortBy.unassigned, isUpdate, (newHashmapOfMessages) => {
                    setChatDataFilteredUnassigned(newHashmapOfMessages);
                });
            }

            if (isMessageInCaseMine(message)) {
                HelperChat.setRoomListLastMessage(message, _chatDataFilteredMine, props.caseSortBy.mine, isUpdate, (newHashmapOfMessages) => {
                    setChatDataFilteredMine(newHashmapOfMessages);

                    //remove from need reply
                    if (!isUpdate) {
                        removeFromNeedReply(LIST_TYPE.MINE, message.room.roomID);
                    } else {
                        addNeedReplyCounter(LIST_TYPE.MINE, newHashmapOfMessages[message.room.roomID]);
                    }
                });
            }

            // if(isMessageInLastAssignedMineCase(message)) {
            //     HelperChat.setRoomListLastMessage(message, _lastAssignedMineCase, SORT_MESSAGE_BY.created_time, isUpdate, (newHashmapOfMessages) => {
            //         setLastAssignedMineCase(newHashmapOfMessages);
            //     });
            // }
        } else {
            //insert new case to room list
            if (
                (message.type === MESSAGE_TYPE.CASE_CREATED && !isUpdate) && 
                !_chatDataFilteredUnassigned[message.room.roomID] && 
                message.data.assigneeType === ASSIGNEE_TYPE_AGENT &&
                passInsertCase(props.caseFilter, "open", message.data)
            ) {
                if (props.topicListProps[message.data.topicID]) {
                    let generateNewOpenCase = () => {
                        let newOpenCase = {};
                        newOpenCase[message.room.roomID] = message.data;
                        newOpenCase[message.room.roomID].tapTalkRoom = {};
                        newOpenCase[message.room.roomID].tapTalkRoom.lastMessage = message;
                        newOpenCase[message.room.roomID].tapTalkRoom.unreadCount = message.isHidden ? 0 : 1;

                        return newOpenCase;
                    }

                    if (message.data.agentAccountID === myAgentAccountID) {
                        if (Object.keys(_chatDataFilteredMine).length === 0) {
                            setChatDataFilteredMine(generateNewOpenCase());
                        } else {
                            if (props.caseSortBy.mine === SORT_MESSAGE_BY.last_message) {
                                setChatDataFilteredMine(Object.assign(generateNewOpenCase(), _chatDataFilteredMine));
                            } else {
                                if (!hasMoreMineCase) {
                                    setChatDataFilteredMine(Object.assign(_chatDataFilteredMine, generateNewOpenCase()));
                                }
                            }
                        }

                        // setTotalMine(totalMine => totalMine + 1);
                        playSoundNotification("case");
                    } else {
                        if (message.data.agentAccountID === 0) {
                            if (Object.keys(_chatDataFilteredUnassigned).length === 0) {
                                setChatDataFilteredUnassigned(generateNewOpenCase());
                            } else {
                                if (props.caseSortBy.unassigned === SORT_MESSAGE_BY.last_message) {
                                    setChatDataFilteredUnassigned(Object.assign(generateNewOpenCase(), _chatDataFilteredUnassigned));
                                } else {
                                    if (!hasMoreUnassignedCase) {
                                        setChatDataFilteredUnassigned(Object.assign(_chatDataFilteredUnassigned, generateNewOpenCase()));
                                    }
                                }
                            }

                            // setTotalUnassigned(totalUnassigned => totalUnassigned + 1);
                            playSoundNotification("case");
                        }
                    }
                }
            }
            //insert new case to room list
        
            //case update take case case or taken by other agent
            if (
                (
                    (
                        message.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE || 
                        message.type === MESSAGE_TYPE.CASE_UPDATE_DETAIL_UPDATE
                    ) && 
                    !isUpdate &&
                    message.data.assigneeType === ASSIGNEE_TYPE_AGENT
                )
                // props.topicListProps[message.data.topicID]
            ) {
                let messageData = {...message.data};
                
                if (message.data.agentAccountID === myAgentAccountID) { //this case update was belong to me
                    if (_chatDataFilteredMine[message.room.roomID]) { //only update current case

                        let currentTaptalkRoom = {..._chatDataFilteredMine[message.room.roomID].tapTalkRoom};
                        _chatDataFilteredMine[message.room.roomID] = messageData;

                        if (!message.isHidden) {
                            _chatDataFilteredMine[message.room.roomID].tapTalkRoom = {};
                            _chatDataFilteredMine[message.room.roomID].tapTalkRoom.lastMessage = message;
                            _chatDataFilteredMine[message.room.roomID].tapTalkRoom.unreadCount = currentTaptalkRoom.unreadCount;
                        } else {
                            _chatDataFilteredMine[message.room.roomID].tapTalkRoom = currentTaptalkRoom;
                        }

                        //handle add need reply
                        if (
                            _chatDataFilteredMine[message.room.roomID].tapTalkRoom.lastMessage.user.userID !== taptalk.getTaptalkActiveUser().userID &&
                            _chatDataFilteredMine[message.room.roomID].tapTalkRoom.unreadCount === 0 &&
                            _chatDataFilteredMine[message.room.roomID].tapTalkRoom.lastMessage.user.userRole.code !== "agent"
                        ) {
                            if(!_chatDataFilteredMine[message.room.roomID].isJunk) {
                                addNeedReplyCounter(LIST_TYPE.MINE, _chatDataFilteredMine[message.room.roomID]);
                            }else {
                                removeFromNeedReply(LIST_TYPE.MINE, message.room.roomID);
                            }
                        }
                        //handle add need reply

                        setChatDataFilteredMine(_chatDataFilteredMine);
                    } else {
                        //if exist in unassigned
                        if (_chatDataFilteredUnassigned[message.room.roomID]) {
                            let currentTaptalkRoom = {..._chatDataFilteredUnassigned[message.room.roomID].tapTalkRoom};

                            let generateNewMineObject = (isLastAssignedMine = false) => {
                                let newMineObject = {};
                                let newMessageData = { ...messageData };
                                delete messageData.tapTalkRoom;

                                newMineObject[message.room.roomID] = newMessageData;

                                if (isLastAssignedMine) {
                                    newMineObject[message.room.roomID].isLastAssigned = true;
                                }

                                if (!message.isHidden) {
                                    newMineObject[message.room.roomID].tapTalkRoom = {};
                                    newMineObject[message.room.roomID].tapTalkRoom.lastMessage = message;
                                    newMineObject[message.room.roomID].tapTalkRoom.unreadCount = currentTaptalkRoom.unreadCount;
                                } else {
                                    newMineObject[message.room.roomID].tapTalkRoom = currentTaptalkRoom;
                                }

                                return newMineObject;
                            };
                            
                            if(!props.isWaitMarkAsJunk) {
                                //then add need reply counter
                                addNeedReplyCounter(LIST_TYPE.MINE, _chatDataFilteredUnassigned[message.room.roomID]);

                                //then add to mine
                                if (Object.keys(_chatDataFilteredMine).length === 0) {
                                    if (props.caseSortBy.mine === SORT_MESSAGE_BY.last_message) {
                                        setChatDataFilteredMine(generateNewMineObject())
                                    } else {
                                        setChatDataFilteredMine(generateNewMineObject(true))
                                    }
                                } else {
                                    if (props.caseSortBy.mine === SORT_MESSAGE_BY.last_message) {
                                        setChatDataFilteredMine(Object.assign(generateNewMineObject(), _chatDataFilteredMine));
                                    } else { // if sort by created time
                                        //then add to lastAssigned
                                        let newLastAssignedMineCaseObject = Object.assign(generateNewMineObject(true), _lastAssignedMineCase);
                                        setLastAssignedMineCase({ ...newLastAssignedMineCaseObject });

                                        //then remove if exist in current chatDataFilteredMine
                                        if (_chatDataFilteredMine[message.room.roomID]) { // remove if exist in current chatDataFilteredMine
                                            delete _chatDataFilteredMine[message.room.roomID];
                                        }

                                        //then add to mine case
                                        let newChatDataFilteredMine = Object.assign(generateNewMineObject(true), _chatDataFilteredMine);
                                        setChatDataFilteredMine(newChatDataFilteredMine);
                                    }
                                }

                                // setTotalMine(totalMine => totalMine + 1);
                                
                                props.setToggleMineCase(true);
                            }

                            props.clearIsWaitMarkAsJunk();

                            //then remove from unassigned
                            delete _chatDataFilteredUnassigned[message.room.roomID];
                            // setTotalUnassigned(totalUnassigned => totalUnassigned - 1);
                            setChatDataFilteredUnassigned(_chatDataFilteredUnassigned);
                        }
                        //if exist in unassigned

                        //if handover
                        else if(!_chatDataFilteredMine[message.room.roomID]) {
                            let generateNewMineObject = (isLastAssignedMine = false) => {
                                let newMineObject = {};
                                let newMessageData = { ...messageData };
                                delete messageData.tapTalkRoom;

                                newMineObject[message.room.roomID] = newMessageData;

                                if (isLastAssignedMine) {
                                    newMineObject[message.room.roomID].isLastAssigned = true;
                                }

                                newMineObject[message.room.roomID].tapTalkRoom = {};
                                newMineObject[message.room.roomID].tapTalkRoom.lastMessage = message;
                                newMineObject[message.room.roomID].tapTalkRoom.unreadCount = 0;
                                
                                return newMineObject;
                            };
                            
                            if(!props.isWaitMarkAsJunk) {
                                //then add need reply counter
                                // addNeedReplyCounter(LIST_TYPE.MINE, _chatDataFilteredUnassigned[message.room.roomID].tapTalkRoom);

                                //then add to mine
                                if (Object.keys(_chatDataFilteredMine).length === 0) {
                                    if (props.caseSortBy.mine === SORT_MESSAGE_BY.last_message) {
                                        setChatDataFilteredMine(generateNewMineObject())
                                    } else {
                                        setChatDataFilteredMine(generateNewMineObject(true))
                                    }
                                } else {
                                    if (props.caseSortBy.mine === SORT_MESSAGE_BY.last_message) {
                                        setChatDataFilteredMine(Object.assign(generateNewMineObject(), _chatDataFilteredMine));
                                    } else { // if sort by created time
                                        //then add to lastAssigned
                                        let newLastAssignedMineCaseObject = Object.assign(generateNewMineObject(true), _lastAssignedMineCase);
                                        setLastAssignedMineCase({ ...newLastAssignedMineCaseObject });

                                        //then remove if exist in current chatDataFilteredMine
                                        if (_chatDataFilteredMine[message.room.roomID]) { // remove if exist in current chatDataFilteredMine
                                            delete _chatDataFilteredMine[message.room.roomID];
                                        }

                                        //then add to mine case
                                        let newChatDataFilteredMine = Object.assign(generateNewMineObject(true), _chatDataFilteredMine);
                                        setChatDataFilteredMine(newChatDataFilteredMine);
                                    }
                                }

                                // setTotalMine(totalMine => totalMine + 1);
                                
                                props.setToggleMineCase(true);
                            }

                            props.clearIsWaitMarkAsJunk();

                            //then remove from unassigned
                            delete _chatDataFilteredUnassigned[message.room.roomID];
                            // setTotalUnassigned(totalUnassigned => totalUnassigned - 1);
                            setChatDataFilteredUnassigned(_chatDataFilteredUnassigned);    
                        }
                        //if handover
                    }
                } else if (message.data.agentAccountID === 0) {  //insert new case to room list handover
                    if (props.topicListProps[message.data.topicID] && passInsertCase(props.caseFilter, "open", message.data)) {
                        let generateNewOpenCase = () => {
                            let newOpenCase = {};
                            newOpenCase[message.room.roomID] = message.data;
                            newOpenCase[message.room.roomID].tapTalkRoom = {};
                            newOpenCase[message.room.roomID].tapTalkRoom.lastMessage = message;
                            newOpenCase[message.room.roomID].tapTalkRoom.unreadCount = 0;
    
                            return newOpenCase;
                        }
    
                        if (Object.keys(_chatDataFilteredUnassigned).length === 0) {
                            setChatDataFilteredUnassigned(generateNewOpenCase());
                        } else {
                            if (props.caseSortBy.unassigned === SORT_MESSAGE_BY.last_message) {
                                setChatDataFilteredUnassigned(Object.assign(generateNewOpenCase(), _chatDataFilteredUnassigned));
                            } else {
                                if (!hasMoreUnassignedCase) {
                                    setChatDataFilteredUnassigned(Object.assign(_chatDataFilteredUnassigned, generateNewOpenCase()));
                                }
                            }
                        }

                        // setTotalUnassigned(totalUnassigned => totalUnassigned + 1);
                        playSoundNotification("case");

                        //remove from mine
                        if(_lastAssignedMineCase[message.room.roomID]) {
                            removeFromNeedReply(LIST_TYPE.MINE, message.room.roomID);

                            //then remove from lastAssigned
                            delete _lastAssignedMineCase[message.room.roomID];
                            // setTotalMine(totalMine => totalMine - 1);
                            setLastAssignedMineCase(_lastAssignedMineCase);
                        }

                        if(_chatDataFilteredMine[message.room.roomID]) {
                            removeFromNeedReply(LIST_TYPE.MINE, message.room.roomID);

                            //then remove from mine
                            delete _chatDataFilteredMine[message.room.roomID];
                            // setTotalMine(totalMine => totalMine - 1);
                            setChatDataFilteredMine(_chatDataFilteredMine);
                        }
                    }
                } else { //this case update was belong to other agent
                    //then remove from mine or unassigned or last assigned
                    if (_chatDataFilteredUnassigned[message.room.roomID]) { //if exist in unassigned
                        //then remove from unassigned
                        delete _chatDataFilteredUnassigned[message.room.roomID];
                        // setTotalUnassigned(totalUnassigned => totalUnassigned - 1);
                        setChatDataFilteredUnassigned(_chatDataFilteredUnassigned);
                    }

                    if (_chatDataFilteredMine[message.room.roomID]) { //if exist in mine
                        removeFromNeedReply(LIST_TYPE.MINE, message.room.roomID);

                        //then remove from mine
                        delete _chatDataFilteredMine[message.room.roomID];
                        // setTotalMine(totalMine => totalMine - 1);
                        setChatDataFilteredMine(_chatDataFilteredMine);
                    }

                    if (_lastAssignedMineCase[message.room.roomID]) { //if exist in lastAssigned
                        removeFromNeedReply(LIST_TYPE.MINE, message.room.roomID);

                        //then remove from lastAssigned
                        delete _lastAssignedMineCase[message.room.roomID];
                        // setTotalMine(totalMine => totalMine - 1);
                        setLastAssignedMineCase(_lastAssignedMineCase);
                    }
                }
            }
            //case update take case case or taken by other agent

            //remove from chat data filtered if case closed
            if (props.activeCasePanel !== 'solved' && props.topicListProps[message.data.topicID]) {
                if (message.type === MESSAGE_TYPE.CASE_CLOSED) {
                    // if(message.data.agentAccountID === myAgentAccountID) {
                    if (_lastAssignedMineCase[message.room.roomID]) {
                        removeFromNeedReply(LIST_TYPE.MINE, message.room.roomID);

                        //remove from lastAssignedMine
                        actionRemoveFromRoomList(LIST_TYPE.LAST_ASSIGNED_MINE);

                        //remove from chatDataFiltered
                        actionRemoveFromRoomList(LIST_TYPE.MINE);
                    } else {
                        if (_chatDataFilteredMine[message.room.roomID]) {
                            removeFromNeedReply(LIST_TYPE.MINE, message.room.roomID);
                            actionRemoveFromRoomList(LIST_TYPE.MINE);
                        } else {
                            actionRemoveFromRoomList(LIST_TYPE.UNASSIGNED);
                        }
                    }
                }
            }
            //remove from chat data filtered if case closed
        }
    }

    useEffect(() => {
        let myAgentID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
        let _soundNotif = JSON.parse(localStorage.getItem("OneTalk.soundNotif"));

        if (_soundNotif[myAgentID]) {
            setOpenCaseSoundNotifEnable(true);
        } else {
            setOpenCaseSoundNotifEnable(false);
        }
    }, [JSON.parse(localStorage.getItem("OneTalk.soundNotif"))])

    useEffect(() => {
        if (onDelaySound) {
            setTimeout(() => {
                setOnDelaySound(false);
            }, 3000)
        }
    }, [onDelaySound])

    useEffect(() => {
        window.addEventListener("resize", () => {
            let _tab = props.toggleMineCase ? "mine" : "unassigned";
            setPanelCaseHeight(
                _tab,
                props.caseListChecked[_tab].isShow
            );
        })

        setPanelCaseHeight(
            "mine" ,
            props.caseListChecked["mine"].isShow
        );

        setPanelCaseHeight(
            "unassigned" ,
            props.caseListChecked["unassigned"].isShow
        );
    }, [])

    useEffect(() => {
        let _connectStatus = props.connectingStatusProps;
        let elOpenCaseTabWrapper = document.querySelectorAll(".open-case-tab-wrapper")[0];

        if (elOpenCaseTabWrapper) {
            if (_connectStatus === CONNECTING_STATUS.disconnected || _connectStatus === CONNECTING_STATUS.loading) {
                // elOpenCaseTabWrapper.style.height = "calc(100vh - 135px)";
                elOpenCaseTabWrapper.style.height = "calc(100vh - 79px)";
            } else {
                // elOpenCaseTabWrapper.style.height = "calc(100vh - 109px)";
                elOpenCaseTabWrapper.style.height = "calc(100vh - 53px)";
            }

            setPanelCaseHeight(
                "mine" ,
                props.caseListChecked["mine"].isShow
            );
    
            setPanelCaseHeight(
                "unassigned" ,
                props.caseListChecked["unassigned"].isShow
            );
        }
    }, [props.connectingStatusProps])

    let setPanelCaseHeight = (type = false, activeSelect = false) => {
        let elOpenCaseTabWrapper = document.querySelectorAll(".open-case-tab-wrapper")[0];

        if (elOpenCaseTabWrapper) {
            // let defaultHeight = elOpenCaseTabWrapper.offsetHeight - 130; //-85 136

            if(type === "mine") {
                let defaultHeight = 0;
    
    
                if(activeSelect) {
                    defaultHeight = elOpenCaseTabWrapper.offsetHeight - 156;
                }else {
                    defaultHeight = elOpenCaseTabWrapper.offsetHeight - 130;
                }

                setPanelMineCaseHeight(defaultHeight);
            }

            if(type === "unassigned") {
                let defaultHeight = 0;
    
                if(activeSelect) {
                    defaultHeight = elOpenCaseTabWrapper.offsetHeight - 156;
                }else {
                    defaultHeight = elOpenCaseTabWrapper.offsetHeight - 130;
                }

                setPanelUnassignedHeight(defaultHeight);
            }
        }
    }

    useEffect(() => {
        // let onlineCallbackOpenCase = () => {
        //     // getUpdatedRoomList({
        //     //     onSuccessGetRoomList: () => {
        //     //         getOpenCaseList(true, 'self');
        //     //         getOpenCaseList(true, 'unassigned');
        //     //     }
        //     // })
        //     getOpenCaseList(INITIAL_OPEN_CASE_PARAM_MINE(false));
        //     getOpenCaseList(INITIAL_OPEN_CASE_PARAM_UNASSIGNED(false));
        // }

        // window.addEventListener('online', onlineCallbackOpenCase);

        if (props.isFetchingRoomListFinishProps) {
            getOpenCaseList(INITIAL_OPEN_CASE_PARAM_MINE(false));
            getOpenCaseList(INITIAL_OPEN_CASE_PARAM_UNASSIGNED(false));
        }

        // set topic list
        let _topiclist = [];
        Object.keys(props.topicListProps).map(value => {
            _topiclist.push(props.topicListProps[value]);
            return null;
        });

        setTopicList(_topiclist);
        // set topic list

        getAgentList(0);

        // return () => {
        //     window.removeEventListener('online', onlineCallbackOpenCase);
        // }
    }, [props.isFetchingRoomListFinishProps]);

    useEffect(() => {
        if (props.newEmitMessageProps !== null) {
            if (isRoomListMineLoadingFinish && isRoomListUnassignedLoadingFinish) {
         
                // console.log("new message emit");
                actionUpdateRoomList(props.newEmitMessageProps);
            }
        }
    }, [props.newEmitMessageProps])

    useEffect(() => {
        if (props.messageListenerNewMessageProps !== null) {
            let newMessage = props.messageListenerNewMessageProps;
            // console.log('new', newMessage);

            if (isRoomListMineLoadingFinish && isRoomListUnassignedLoadingFinish) {
                actionUpdateRoomList(newMessage);
            }
        }
    }, [props.messageListenerNewMessageProps]);

    useEffect(() => {
        if (props.messageListenerUpdateMessageProps !== null) {
            let updateMessage = props.messageListenerUpdateMessageProps;
            // console.log('updet', updateMessage);

            if (isRoomListMineLoadingFinish && isRoomListUnassignedLoadingFinish) {
                actionUpdateRoomList(updateMessage, true);
            }
        }
    }, [props.messageListenerUpdateMessageProps])

    useEffect(() => {
        let startTyping = props.listenerStartTypingProps;

        if (startTyping !== null) {
            runActionTypingFromRoomList(startTyping.roomID, 'show', startTyping.user.fullname);

            let _hashMapTypingTimeout = { ...hashMapTypingTimeout };

            if (_hashMapTypingTimeout[startTyping.roomID]) {
                clearTimeout(_hashMapTypingTimeout[startTyping.roomID]);
            }

            _hashMapTypingTimeout[startTyping.roomID] = setTimeout(function () {
                runActionTypingFromRoomList(startTyping.roomID, 'hide');
            }, 10000);

            setHashMapTypingTimeout(_hashMapTypingTimeout);
        }
    }, [props.listenerStartTypingProps])

    useEffect(() => {
        let stopTyping = props.listenerStopTypingProps;

        if (stopTyping !== null) {
            runActionTypingFromRoomList(stopTyping.roomID, 'hide');
            clearTimeout(hashMapTypingTimeout[stopTyping.roomID]);
        }

    }, [props.listenerStopTypingProps])

    useEffect(() => {
        if (isRoomListMineLoadingFinish) {
            let _toggleMineCase = props.toggleMineCase;
            setLastAssignedMineCase({});
            // setChatDataFilteredWithLastAssignedMine ({});
            setIsRoomListMineLoadingFinish(false);
            getOpenCaseList({
                topicID: _toggleMineCase ? (filterTopicValueMine.value === 0 ? 0 : filterTopicValueMine.value) : (filterTopicValueUnassigned.value === 0 ? 0 : filterTopicValueUnassigned.value),
                agentAccountID: _toggleMineCase ? (filterAgentValueMine.value === 0 ? 0 : filterAgentValueMine.value) : (filterAgentValueUnassigned.value === 0 ? 0 : filterAgentValueUnassigned.value),
                mediums: _toggleMineCase ? filterMediumValueMine : filterMediumValueUnassigned,
                search: props.toggleMineCase ? filterSearchValueMine : filterSearchValueUnassigned,
                lastID: 0,
                isSearchOrFilter: false,
                isReconnect: false,
                isLoadMoreOther: false,
                caseType: _toggleMineCase ? "self" : "unassigned",
                label: _toggleMineCase ? filterLabelMine : filterLabelUnassigned,
                junkStatus: _toggleMineCase ? filterCaseTypeMine : filterCaseTypeUnassigned
            });
        }
    }, [props.caseSortBy.mine])

    useEffect(() => {
        let _toggleMineCase = props.toggleMineCase;

        if (isRoomListUnassignedLoadingFinish) {
            setIsRoomListUnassignedLoadingFinish(false);
            getOpenCaseList({
                topicID: _toggleMineCase ? (filterTopicValueMine.value === 0 ? 0 : filterTopicValueMine.value) : (filterTopicValueUnassigned.value === 0 ? 0 : filterTopicValueUnassigned.value),
                agentAccountID: _toggleMineCase ? (filterAgentValueMine.value === 0 ? 0 : filterAgentValueMine.value) : (filterAgentValueUnassigned.value === 0 ? 0 : filterAgentValueUnassigned.value),
                mediums: _toggleMineCase ? filterMediumValueMine : filterMediumValueUnassigned,
                search: props.toggleMineCase ? filterSearchValueMine : filterSearchValueUnassigned,
                lastID: 0,
                isSearchOrFilter: false,
                isReconnect: false,
                isLoadMoreOther: false,
                caseType: _toggleMineCase ? "self" : "unassigned",
                label: _toggleMineCase ? filterLabelMine : filterLabelUnassigned,
                junkStatus: _toggleMineCase ? filterCaseTypeMine : filterCaseTypeUnassigned
            });
        }
    }, [props.caseSortBy.unassigned])

    let runActionTypingFromRoomList = (roomID, action, name) => {
        let targetChatListWrapper = document.querySelector(`.new-case-list-wrapper[data-room-id="${roomID}"]`);

        if (targetChatListWrapper !== null) {
            if (action === 'hide') {
                targetChatListWrapper.classList.remove("active-typing");
            } else {
                targetChatListWrapper.classList.add("active-typing");

                if(targetChatListWrapper.getElementsByClassName("typing-name").length > 0) {
                    targetChatListWrapper.getElementsByClassName("typing-name")[0].innerHTML = name !== "" ? name+": " : "";
                }
            }
        }
    }

    let getOpenCaseList = (data) => {
        if (!data.isReconnect) {
            if (data.lastID === 0 && !data.isSearchOrFilter && !props.toggleMineCase) {
                setIsRoomListUnassignedLoadingFinish(false);
            }

            if (data.lastID === 0 && !data.isSearchOrFilter && props.toggleMineCase) {
                setIsRoomListMineLoadingFinish(false);
            }
        }

        let _lastAssignedMineCase = { ...lastAssignedMineCase };

        if (data.isSearch) {
            _lastAssignedMineCase = {};
            setLastAssignedMineCase({});
        }

        if (data.isReconnect) {
            setIsRoomListUnassignedLoadingFinish(true);
            setIsRoomListMineLoadingFinish(true);
        }

        let dataLabel = [];
        if (data.label && data.label.length > 0) {
            dataLabel = data.label.map(item => item.id)
        }

        let _data = {
            topicID: data.topicID,
            //   agentAccountID: data.agentAccountID,
            assignmentStatus: data.caseType,
            search: data.search ? data.search : "",
            sortBy: data.caseType === "self" ? props.caseSortBy.mine : props.caseSortBy.unassigned,
            lastID: data.lastID,
            pageSize: 50,
            mediums: data.mediums,
            labelIDs: dataLabel,
            junkStatus: data.junkStatus
        };

        CaseService.postOpenCaseList(props.parentProps.match.params.orgID, _data, (response) => {
            setFetchingUnassignedCase(false);
            setFetchingMineCase(false);

            if (response.dataResult.error.code === "") {
                if (data.isSearchOrFilter) {
                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                            "[Action] Search",
                            {
                                tab: !props.toggleMineCase ? "Unassigned" : "Mine",
                                userFullName: myAgentData.account.fullName,
                                userID: myAgentData.account.id,
                                organizationID: props.parentProps.match.params.orgID.split("-")[0]
                            }
                        )
                    }
                }

                let resultCase = response.dataResult.data.cases;
                let caseData = {};
                // let unreadCounter = data.caseType === 'self' ? totalUnreadCountMine : totalUnreadCountUnassigned;
                let needReply = data.caseType === 'self' ? { ...needReplyDataMine } : { ...needReplyDataUnassigned };

                for (let i in resultCase) {
                    let body = resultCase[i].tapTalkRoom.lastMessage.body;
                    let localID = resultCase[i].tapTalkRoom.lastMessage.localID;
                    let messageData = resultCase[i].tapTalkRoom.lastMessage.data;

                    resultCase[i].tapTalkRoom.lastMessage.body = taptalk.generateBodyAndData(body, localID);

                    if (messageData !== "") {
                        resultCase[i].tapTalkRoom.lastMessage.data = JSON.parse(taptalk.generateBodyAndData(messageData, localID));
                    }

                    if (!_lastAssignedMineCase[resultCase[i].tapTalkRoom.lastMessage.room.roomID]) {
                        caseData[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = resultCase[i];

                        if (data.caseType === "self" &&
                            resultCase[i].tapTalkRoom.lastMessage.user.userID !== taptalk.getTaptalkActiveUser().userID &&
                            resultCase[i].tapTalkRoom.unreadCount === 0 &&
                            resultCase[i].tapTalkRoom.lastMessage.user.userRole.code !== "agent" &&
                            !resultCase[i].isJunk
                        ) {
                            needReply[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = resultCase[i];
                            
                            if(props.needReplyColorIndicator) {
                                needReply[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = HelperChat.needReplyLevelCount(props.needReplyColorIndicator, needReply[resultCase[i].tapTalkRoom.lastMessage.room.roomID]);
                            }
                        }

                    }
                    
                    // if (data.caseType === "unassigned" &&
                    //     !resultCase[i].isJunk
                    // ) {
                    //     needReply[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = resultCase[i];
                    // }

                    // else {
                    //     // on search if case is exist in last assigned hashmap then add key isLastAssigned = true
                    //     if(_lastAssignedMineCase[resultCase[i].tapTalkRoom.lastMessage.room.roomID] && data.caseType === "self") {
                    //         resultCase[i].isLastAssigned = true;
                    //     }
                    // }

                    // unreadCounter = unreadCounter + resultCase[i].tapTalkRoom.unreadCount;
                }

                if (data.caseType === 'self') {
                    setHasMoreMineCase(response.dataResult.data.hasMore);
                    // setTotalMine(response.dataResult.data.totalCases);

                    if (data.isLoadMoreMine) {
                        let currentMineCaseList = { ...chatDataFilteredMine };
                        currentMineCaseList = Object.assign(currentMineCaseList, caseData);
                        setChatDataFilteredMine(currentMineCaseList);

                        setIsRoomListMineLoadingFinish(true);
                    } else {
                        setChatDataFilteredMine(caseData);
                        setIsRoomListMineLoadingFinish(true);

                        if (data.isSearchOrFilter) {
                            setIsLoadingSearchOpenCaseFinishMine(true);

                            if (resultCase.length === 0) {
                                setSearchNotFoundMine(true);
                            }
                        }
                    }

                    setNeedReplyDataMine(needReply);
                    // setTotalUnreadCountMine(unreadCounter);
                } else {
                    setHasMoreUnassignedCase(response.dataResult.data.hasMore);
                    // setTotalUnassigned(response.dataResult.data.totalCases);

                    if (data.isLoadMoreUnassigned) {
                        let currentUnassignedCaseList = { ...chatDataFilteredUnassigned };

                        currentUnassignedCaseList = Object.assign(currentUnassignedCaseList, caseData);
                        setChatDataFilteredUnassigned(currentUnassignedCaseList);
                        setIsRoomListUnassignedLoadingFinish(true);
                    } else {
                        setChatDataFilteredUnassigned(caseData);
                        setIsRoomListUnassignedLoadingFinish(true);

                        if (data.isSearchOrFilter) {
                            setIsLoadingSearchOpenCaseFinishUnassigned(true);

                            if (resultCase.length === 0) {
                                setSearchNotFoundUnassigned(true);
                            }
                        }
                    }

                    // setNeedReplyDataUnassigned(needReply);
                    // setTotalUnreadCountUnassigned(unreadCounter);
                }
            } else {
                if (data.caseType === 'self') {
                    setIsRoomListMineLoadingFinish(true);
                    setIsLoadingSearchOpenCaseFinishMine(true);
                } else {
                    setIsRoomListUnassignedLoadingFinish(true);
                    setIsLoadingSearchOpenCaseFinishUnassigned(true);
                }

                doToast(response.dataResult.error.message, 'fail');

                if (data.isLoadMoreUnassigned) {
                    setFetchingUnassigendCaseIsError(true);
                }

                if (data.isLoadMoreMine) {
                    setFetchingMineCaseIsError(true);
                }
            }
        })
    }

    let toggleSortByOpenCase = () => {
        setIsShowOpenCaseSortBy(!isShowOpenCaseSortBy);
    }

    let actionSwitchSortBy = (sortByVal) => {
        setLastAssignedMineCase({});
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        // if(props.toggleMineCase) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Action] Change Sorting",
                    {
                        sortby: sortByVal === "last_message" ? "Last Message" : "Created Time",
                        tab: !props.toggleMineCase ? "Unassigned" : "Mine",
                        userFullName: myAgentData.account.fullName,
                        userID: myAgentData.account.id,
                        organizationID: props.parentProps.match.params.orgID.split("-")[0]
                    }
                );
            }

        props.setCaseSortBy({
            unassigned: props.toggleMineCase ? props.caseSortBy.unassigned : sortByVal,
            mine: props.toggleMineCase ? sortByVal : props.caseSortBy.mine,
            other: props.caseSortBy.other
        })
        // setOpenCaseMineSortBy(sortByVal);
        // }else {
        //     setOpenCaseUnassignedSortBy(sortByVal);
        // }
    }

    let onChangeSearchValue = (e) => {
        if (e.target) {
            if (props.toggleMineCase) {
                setFilterSearchValueMine(e.target.value);
            } else {
                setFilterSearchValueUnassigned(e.target.value);
            }
        }
    }

    // let resetSearchValue = () => {
    //     let runOpenCaseList = () => {
    //         getOpenCaseList({
    //             lastID: 0,
    //             isReconnect: false,
    //             caseType: props.toggleMineCase ? "self" : "unassigned",
    //             isSearch: true,
    //             search: ""
    //         });
    //     }

    //     if(onSearchCaseMine) {
    //         setOnSearchCaseMine(false);
    //         setFilterSearchValueMine("");
    //         setIsRoomListMineLoadingFinish(false);
    //         runOpenCaseList();
    //     }

    //     if(onSearchCaseUnassigned) {
    //         setOnSearchCaseUnassigned(false);
    //         setFilterSearchValueUnassigned("");
    //         setIsRoomListUnassignedLoadingFinish(false);
    //         runOpenCaseList();
    //     }
    // }

    let submitSearchOpenCase = (e) => {
        // let _chatDataFiltered = props.toggleMineCase ? {...chatDataFilteredMine} : {...chatDataFilteredUnassigned};
        let _toggleMineCase = props.toggleMineCase;

        e.preventDefault();

        if (props.toggleMineCase) {
            if (filterSearchValueMine !== "") {
                setIsSearchActiveMine(filterSearchValueMine.length > 0);

                if (filterSearchValueMine.length > 0) {
                    setIsLoadingSearchOpenCaseFinishMine(false);
                } else {
                    setIsRoomListMineLoadingFinish(false);
                }
            }
        } else {
            if (filterSearchValueUnassigned !== "") {
                setIsSearchActiveUnassigned(filterSearchValueUnassigned.length > 0);

                if (filterSearchValueUnassigned.length > 0) {
                    setIsLoadingSearchOpenCaseFinishUnassigned(false);
                } else {
                    setIsRoomListUnassignedLoadingFinish(false);
                }
            }
        }

        getOpenCaseList({
            topicID: _toggleMineCase ? (filterTopicValueMine.value === 0 ? 0 : filterTopicValueMine.value) : (filterTopicValueUnassigned.value === 0 ? 0 : filterTopicValueUnassigned.value),
            // agentAccountID: _toggleMineCase ? (filterAgentValueMine.value === 0 ? 0 : filterAgentValueMine.value) : (filterAgentValueUnassigned.value === 0 ? 0 : filterAgentValueUnassigned.value),
            mediums: _toggleMineCase ? filterMediumValueMine : filterMediumValueUnassigned,
            search: props.toggleMineCase ? filterSearchValueMine : filterSearchValueUnassigned,
            lastID: 0,
            isSearchOrFilter: true,
            isReconnect: false,
            isLoadMoreOther: false,
            caseType: _toggleMineCase ? "self" : "unassigned",
            junkStatus: _toggleMineCase ? filterCaseTypeMine : filterCaseTypeUnassigned
        });
    }

    let toggleSearchBox = () => {
        setIsShowSearchBox(!isShowSearchBox);
    }

    useEffect(() => {
        if (isShowSearchBox) {
            setTimeout(() => {
                let el = document.querySelector("#open-case-search-input");
                el.focus();
            }, 150);
        }
        // else {
        //     resetSearchValue()
        // }
    }, [isShowSearchBox])

    let toggleModalStartConversation = (show) => {
        setIsShowModalStartConversation(show);
    }

    let toggleModalStartClock = (show) => {
        setIsShowModalStartClock(show);
    }

    // let toggleSwitchMineCase = (isShow) => {
    //     setIsOpenCaseMineActive(isShow);
    // }

    let toggleSelectCaseList = (type) => {
        let _caseSelect = {...props.caseListChecked};
        _caseSelect[type].isShow = !_caseSelect[type].isShow;
        _caseSelect[type].data = {};
        _caseSelect[type].checkedAll = false;

        let _tab = props.toggleMineCase ? "mine" : "unassigned";
        setPanelCaseHeight(
            _tab,
            props.caseListChecked[_tab].isShow
        );

        props.setCaseListChecked(_caseSelect);
    }

    let toggleModalFilter = () => {
        let _toggleMineCase = props.toggleMineCase;
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Button] Filter",
                {
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                }
            );
        }

        if (_toggleMineCase) {
            //if close
            if (showModalFilterTopicAgentMine) {
                setFilterTopicValueMine(filterTopicValueMineBefore);
                setFilterAgentValueMine(filterAgentValueMineBefore);
                setFilterMediumValueMine(filterMediumValueMineBefore);
                setFilterCaseTypeMine(filterCaseTypeMineBefore);
                setFilterLabelMine(filterLabelMineBefore)
            }
            //if close

            setShowModalFilterTopicAgentMine(!showModalFilterTopicAgentMine);
        } else {
            //if close
            if (showModalFilterTopicAgentUnassigned) {
                setFilterTopicValueUnassigned(filterTopicValueUnassignedBefore);
                setFilterAgentValueUnassigned(filterAgentValueUnassignedBefore);
                setFilterMediumValueUnassigned(filterMediumValueUnassignedBefore);
                setFilterCaseTypeUnassigned(filterCaseTypeUnassigned);
                setFilterLabelUnassigned(filterLabelUnassignedBefore)
            }
            //if close

            setShowModalFilterTopicAgentUnassigned(!showModalFilterTopicAgentUnassigned);
        }
    }

    let runUpdateFilter = (data) => {
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setFilterTopicValueMineBefore(filterTopicValueMine);
            setFilterAgentValueMineBefore(filterAgentValueMine);
            setFilterMediumValueMineBefore(filterMediumValueMine);
            setFilterCaseTypeMineBefore(filterCaseTypeMine);
            setFilterLabelMineBefore(filterLabelMine);
            setIsLoadingSearchOpenCaseFinishMine(false);
        } else {
            setFilterTopicValueUnassignedBefore(filterTopicValueUnassigned);
            setFilterAgentValueUnassignedBefore(filterAgentValueUnassigned);
            setFilterMediumValueUnassignedBefore(filterMediumValueUnassigned);
            setFilterCaseTypeUnassignedBefore(filterCaseTypeUnassigned);
            setFilterLabelUnassignedBefore(filterLabelUnassigned)
            setIsLoadingSearchOpenCaseFinishUnassigned(false);
        }

        getOpenCaseList({
            topicID: _toggleMineCase ? (filterTopicValueMine.value === 0 ? 0 : filterTopicValueMine.value) : (filterTopicValueUnassigned.value === 0 ? 0 : filterTopicValueUnassigned.value),
            // agentAccountID: _toggleMineCase ? (filterAgentValueMine.value === 0 ? 0 : filterAgentValueMine.value) : (filterAgentValueUnassigned.value === 0 ? 0 : filterAgentValueUnassigned.value),
            mediums: _toggleMineCase ? filterMediumValueMine : filterMediumValueUnassigned,
            search: props.toggleMineCase ? filterSearchValueMine : filterSearchValueUnassigned,
            lastID: 0,
            isSearchOrFilter: true,
            isReconnect: false,
            isLoadMoreOther: false,
            caseType: _toggleMineCase ? "self" : "unassigned",
            label: _toggleMineCase ? filterLabelMine : filterLabelUnassigned,
            junkStatus: _toggleMineCase ? filterCaseTypeMine : filterCaseTypeUnassigned
        });
    }

    let toggleIsActiveFilter = (toggleState) => {
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setIsFilterTopicAgentChannelActiveMine(toggleState);
        } else {
            setIsFilterTopicAgentChannelActiveUnassigned(toggleState)
        }
    }

    let toggleResetFilter = () => {
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setIsResetFilterMine(false);
        } else {
            setIsResetFilterUnassigned(false);
        }
    }

    let onSelectTopic = (value) => {
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setFilterTopicValueMine(value);
        } else {
            setFilterTopicValueUnassigned(value);
        }
    }

    let onSelectAgent = (value) => {
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setFilterAgentValueMine(value);
        } else {
            setFilterAgentValueUnassigned(value);
        }
    }

    let onSelectMedium = (value) => {
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setFilterMediumValueMine(value);
        } else {
            setFilterMediumValueUnassigned(value);
        }
    }

    const onSelectLabel = (value) => {
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setFilterLabelMine(value)
        } else {
            setFilterLabelUnassigned(value);
        }
    }

    let onSelectCaseType = (value) => {
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setFilterCaseTypeMine(value)
        } else {
            setFilterCaseTypeUnassigned(value);
        }
    }

    let resetFilterAndSearchValue = () => {
        props.clearCaseFilter();
        let _toggleMineCase = props.toggleMineCase;

        if (_toggleMineCase) {
            setIsResetFilterMine(true);

            setIsLoadingSearchOpenCaseFinishMine(true);

            //search
            setSearchNotFoundMine(false);
            setFilterSearchValueMine('');
            setIsSearchActiveMine(false);
            //search

            //fitler topic and agent
            setFilterTopicValueMine(DEFAULT_FILTER_TOPIC_AGENT);
            setFilterAgentValueMine(DEFAULT_FILTER_TOPIC_AGENT);
            setFilterTopicValueMineBefore(DEFAULT_FILTER_TOPIC_AGENT);
            setFilterAgentValueMineBefore(DEFAULT_FILTER_TOPIC_AGENT);
            setIsFilterTopicAgentChannelActiveMine(false);
            //fitler topic and agent

            //medium
            setFilterMediumValueMine([]);
            setFilterMediumValueMineBefore([]);
            //medium

            //label
            setFilterLabelMine([])
            setFilterLabelMineBefore([])
            //label

            //case type
            setFilterCaseTypeMine(CASE_TYPES.allTypes.value);
            setFilterCaseTypeMineBefore(CASE_TYPES.allTypes.value);
            //case type

            getAgentList(0);
            getOpenCaseList(INITIAL_OPEN_CASE_PARAM_MINE(false));
        } else {
            setIsResetFilterUnassigned(true);

            setIsLoadingSearchOpenCaseFinishUnassigned(true);

            //search
            setSearchNotFoundUnassigned(false);
            setFilterSearchValueUnassigned('');
            setIsSearchActiveUnassigned(false);
            //search

            //fitler topic and agent
            setFilterTopicValueUnassigned(DEFAULT_FILTER_TOPIC_AGENT);
            setFilterAgentValueUnassigned(DEFAULT_FILTER_TOPIC_AGENT);
            setFilterTopicValueUnassignedBefore(DEFAULT_FILTER_TOPIC_AGENT);
            setFilterAgentValueUnassignedBefore(DEFAULT_FILTER_TOPIC_AGENT);
            setIsFilterTopicAgentChannelActiveUnassigned(false);
            //fitler topic and agent

            //medium
            setFilterMediumValueUnassigned([]);
            setFilterMediumValueUnassignedBefore([]);
            //medium

            //label
            setFilterLabelUnassigned([])
            setFilterLabelUnassignedBefore([])
            //label

            //case type
            setFilterCaseTypeUnassigned(CASE_TYPES.allTypes.value);
            setFilterCaseTypeUnassignedBefore(CASE_TYPES.allTypes.value);
            //case type

            getAgentList(0);
            getOpenCaseList(INITIAL_OPEN_CASE_PARAM_UNASSIGNED(false));
        }

    }

    let getAgentList = (topicID) => {
        let data = {
            topicID: topicID
        };

        CaseService.postAgentList(props.parentProps.match.params.orgID, data, (response) => {
            if (response.dataResult.error.code === "") {
                let dataResult = response.dataResult;

                for (let i in dataResult.data.agents) {
                    dataResult.data.agents[i].value = dataResult.data.agents[i].accountID;
                    dataResult.data.agents[i].label = dataResult.data.agents[i].accountName;
                }

                setAgentList(dataResult.data.agents);
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }
        })
    }

    let resumeClockAction = () => {
        let orgID = props.parentProps.match.params.orgID;
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        setIsWaitResponseStartClock(true);

        ClockService.postAgentClockResume(orgID, (response) => {
            let dataResult = response.dataResult;

            if (dataResult.error.code === "") {
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                        "[Action] Resume Timer",
                        {
                            userFullName: myAgentData.account.fullName,
                            userID: myAgentData.account.id,
                            organizationID: orgID.split("-")[0]
                        }
                    );
                }

                toggleModalStartClock(false);
                toggleModalStartConversation(true);
                props.setClockState(CLOCK_STATE.isStarted);
            } else {
                doToast(dataResult.error.message, 'fail');
            }

            setIsWaitResponseStartClock(false);
        })
    }

    let getQuickReplyList = () => {
        ServiceQuickReply.getQuickReplyList(props.parentProps.match.params.orgID, (response) => {
          let dataResult = response.dataResult;
          let quickReplyList = [];
    
          if (dataResult.error.message === "") {
            quickReplyList = quickReplyList.concat(dataResult.data.general, dataResult.data.personal);
            props.setQuickReplyList(quickReplyList);
          }else {
            console.log(dataResult.error.message, 'fail');
          }
        })
    }

    // let arrayOfLabel = []

    return (
        <div className="open-case-tab-wrapper main-case-wrapper">
            <PopupConfirmation
                isOpen={isShowModalStartClock}
                toggle={toggleModalStartClock}
                title="Go Online"
                description="You are currently on away status. Press the button 'Go online' below to send new messages."
                className="modal-clock-on-new-message"
            >
                {!isWaitResponseStartClock ?
                    <ButtonWithLoadingOrIcon
                        text="Go Online"
                        className="green-button main-button-48 confirmation-button"
                        onClickAction={resumeClockAction}
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Go Online"
                        isDisabled
                        className="main-button-48 confirmation-button"
                        isLoading
                        position="left"
                        loadingColor="gray"
                    />
                }
            </PopupConfirmation>

            <StartConversation
                {...props}
                isShowModalStartConversationProps={isShowModalStartConversation}
                toggleModalStartConversationProps={toggleModalStartConversation}
                orgIDProps={props.parentProps.match.params.orgID}
                topicListProps={props.topicListProps}
                onClickRoomListProps={props.onClickRoomListProps}
                // toggleSwitchMineCaseProps={toggleSwitchMineCase}
            />

            {/* <Scrollbars autoHide autoHideTimeout={500}
                    renderThumbVertical={props => <div {...props} style={style.scrollStyle} className="room-list-scrollbars" />}
            > */}
            {/* <RoomListNoChat text="No Open Case"/> */}
            <audio id="sound-new-message">
                <source src={soundNewMessage} type="audio/mpeg" />
            </audio>

            <audio id="sound-new-case">
                <source src={soundNewCase} type="audio/mpeg" />
            </audio>

            <div className="open-case-switch-wrapper">
                <div className="case-title-and-search-button-container">
                    <div className={`case-room-search-wrapper ${isShowSearchBox ? "active-case-room-search-wrapper" : ""}`}>
                        <form id="input-search-form" onSubmit={(e) => submitSearchOpenCase(e)}>
                            <input type="text"
                                placeholder="Case ID, full name, alias and phone number"
                                onChange={(e) => onChangeSearchValue(e)}
                                value={props.toggleMineCase ? filterSearchValueMine : filterSearchValueUnassigned}
                                id="open-case-search-input"
                            />

                            <b className="cancel-search"
                                onClick={() => toggleSearchBox()}
                            >
                                Cancel
                                </b>
                            {/* <FiXCircle className="case-search-close-icon" /> */}

                            {/* <button className="submit-filter-button">
                                    <FiSearch />
                                </button> */}
                        </form>
                    </div>

                    <div className={`case-title-and-search-button-wrapper ${isShowSearchBox ? "" : "active-case-title-and-search-button-wrapper"}`}>
                        <b className="inbox-title">
                            Inbox
                            </b>

                        <div className="new-message-button-wrapper">
                            <p>
                                {/* <FiSearch className="icon-search" onClick={() => toggleSearchBox()} /> */}

                                <img src={fiedit} className="icon-edit" onClick={() => toggleModalStartConversation(true)} />

                                <b onClick={() => {
                                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                        mixpanel.track(
                                            "[Button] Start New Conversation",
                                            {
                                                userFullName: myAgentData.account.fullName,
                                                userID: myAgentData.account.id,
                                                organizationID: props.parentProps.match.params.orgID.split("-")[0]
                                            }
                                        );
                                    }

                                    if (props.clockState === CLOCK_STATE.isPaused) {
                                        toggleModalStartClock(true)
                                    } else {
                                        toggleModalStartConversation(true)
                                    }
                                }}>
                                    New Message
                                    </b>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="open-case-switch-content">
                    <div className={`open-case-active-panel ${props.toggleMineCase ? "active-mine-case-panel" : ""}`}></div>

                    <div className={`open-case-switch-panel ${!props.toggleMineCase ? "active-case-panel" : ""}`} onClick={() => {
                        props.setToggleMineCase(false)
                    }}>
                        <div><b>Unassigned</b></div>
                        {/* {totalUnassigned > 0 &&
                            <div className="counter-case"><b>{totalUnassigned}</b></div>
                        } */}

                        {/* {Object.keys(chatDataFilteredUnassigned).length > 0 && */}
                            <div className="counter-case"><b>{Object.keys(chatDataFilteredUnassigned).length}</b></div>
                        {/* } */}
                    </div>

                    <div className={`open-case-switch-panel ${props.toggleMineCase ? "active-case-panel" : ""}`} onClick={() => {
                        props.setToggleMineCase(true)
                    }}>
                        <div><b>Mine</b></div>
                        {/* {totalMine > 0 &&
                            <div className="counter-case"><b>{totalMine}</b></div>
                        } */}
                        {/* {Object.keys(chatDataFilteredMine).length > 0 && */}
                            <div className="counter-case"><b>{Object.keys(chatDataFilteredMine).length}</b></div>
                        {/* } */}
                    </div>

                </div>
            </div>

            {props.toggleMineCase ?
                <>
                    <div className="open-case-sort-by-wrapper">
                        <div className='open-case-sort-by-wrapper-inner'>
                            {/* {onSearchCaseMine &&
                                    <div
                                        className={`case-room-filter-tag-badge case-room-filter-tag-badge-active`}
                                        onClick={() => resetSearchValue()}
                                    >
                                        <FiRotateCcw />
                                        Reset
                                    </div>
                                } */}

                            <CaseListSortBy
                                isShowSortByOptionProps={isShowOpenCaseSortBy}
                                sortByProps={props.caseSortBy.mine}
                                toggleSortByOpenCaseProps={toggleSortByOpenCase}
                                actionSwitchSortByProps={actionSwitchSortBy}
                                disabledSortByProps={!isRoomListMineLoadingFinish || !isRoomListUnassignedLoadingFinish || fetchingMineCase || fetchingUnassignedCase}
                            />

                            <div className='sort-by-wrapper-inner-scroll'>
                                {(isSearchActiveMine || isFilterTopicAgentChannelActiveMine) &&
                                    <div className={`case-room-filter-tag-badge case-room-filter-tag-badge-active`} onClick={() => resetFilterAndSearchValue()}>
                                        <FiRotateCcw />
                                        <b>Reset</b>
                                    </div>
                                }

                                <div
                                    className={`
                                            case-room-filter-tag-badge
                                            ${isFilterTopicAgentChannelActiveMine ? 'case-room-filter-tag-badge-active' : ''}
                                        `}
                                    onClick={() => toggleModalFilter()}
                                >
                                    <img src={isFilterTopicAgentChannelActiveMine ? iconFilterWhite : iconFilterGrey} alt="" />
                                    <b>Filters</b>
                                    {/* <span
                                        className="case-room-filter-tag-counter">2</span> */}
                                </div>
                                
                                {Object.keys(chatDataFilteredMine).length > 0 &&
                                    <div 
                                        className={`
                                            case-room-filter-tag-badge 
                                            ${props.caseListChecked["mine"].isShow ? 'case-room-filter-tag-badge-active' : ''}
                                        `}
                                        onClick={() => toggleSelectCaseList("mine")}
                                    >
                                        <FiList />
                                        <b>Select</b>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>

                    {props.caseListChecked["mine"].isShow &&
                        <CaseListAction 
                            data={chatDataFilteredMine}
                            type="mine"
                            orgID={props.parentProps.match.params.orgID}
                            _setLastBulkJunk={setLastBulkJunkMine}
                            _setLastBulkSolve={setLastBulkSolveMine}
                            toogleCaseListAction={() => toggleSelectCaseList("mine")}
                            caseLength={Object.keys(chatDataFilteredMine).length}
                        />
                    }
                </>
                :
                <>
                    <div className="open-case-sort-by-wrapper">
                        <div className="open-case-sort-by-wrapper-inner">
                            {/* {onSearchCaseUnassigned &&
                                    <div
                                        className={`case-room-filter-tag-badge case-room-filter-tag-badge-active`}
                                        onClick={() => resetSearchValue()}
                                    >
                                        <FiRotateCcw />
                                        Reset
                                    </div>
                                } */}

                            <CaseListSortBy
                                isShowSortByOptionProps={isShowOpenCaseSortBy}
                                sortByProps={props.caseSortBy.unassigned}
                                toggleSortByOpenCaseProps={toggleSortByOpenCase}
                                actionSwitchSortByProps={actionSwitchSortBy}
                                disabledSortByProps={!isRoomListMineLoadingFinish || !isRoomListUnassignedLoadingFinish || fetchingMineCase || fetchingUnassignedCase}
                            />
                            
                            <div className='sort-by-wrapper-inner-scroll'>
                                {(isSearchActiveUnassigned || isFilterTopicAgentChannelActiveUnassigned) &&
                                    <div className={`case-room-filter-tag-badge case-room-filter-tag-badge-active`} onClick={() => resetFilterAndSearchValue()}>
                                        <FiRotateCcw />
                                            Reset
                                        </div>
                                }

                                <div
                                    className={`
                                            case-room-filter-tag-badge
                                            ${isFilterTopicAgentChannelActiveUnassigned ? 'case-room-filter-tag-badge-active' : ''}
                                        `}
                                    onClick={() => toggleModalFilter()}
                                >
                                    <img src={isFilterTopicAgentChannelActiveUnassigned ? iconFilterWhite : iconFilterGrey} alt="" />
                                    <b>Filters</b>
                                    {/* <span className="case-room-filter-tag-counter">2</span> */}
                                </div>
                                
                                {Object.keys(chatDataFilteredUnassigned).length > 0 &&
                                    <div 
                                        className={`
                                            case-room-filter-tag-badge 
                                            ${props.caseListChecked["unassigned"].isShow ? 'case-room-filter-tag-badge-active' : ''}
                                        `}
                                        onClick={() => toggleSelectCaseList("unassigned")}
                                    >
                                        <FiList />
                                        <b>Select</b>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    
                    {props.caseListChecked["unassigned"].isShow &&
                        <CaseListAction 
                            data={chatDataFilteredUnassigned}
                            type="unassigned"
                            orgID={props.parentProps.match.params.orgID}
                            _setLastBulkJunk={setLastBulkJunkUnassigned}
                            _setLastBulkSolve={setLastBulkSolveUnassigned}
                            toogleCaseListAction={() => toggleSelectCaseList("unassigned")}
                            caseLength={Object.keys(chatDataFilteredUnassigned).length}
                        />
                    }
                </>
            }

            <div className={`open-case-unassigned-room-wrapper room-list-chat-container ${!props.toggleMineCase ? "active-open-case-list-panel" : ""}`}>
                {!isRoomListUnassignedLoadingFinish ?
                    <div className="room-list-loading-wrapper">
                        <div className="lds-ring">
                            <div /><div /><div /><div />
                        </div>
                        <b>Loading Cases</b>
                    </div>

                    :

                    !isLoadingSearchOpenCaseFinishUnassigned ?
                        <div className="room-list-loading-wrapper">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                            <b>Searching for Results</b>
                        </div>
                        :
                        Object.keys(chatDataFilteredUnassigned).length > 0 ?
                            <div>
                                <ListCaseComponent
                                    case={chatDataFilteredUnassigned}
                                    caseLabel={props.caseLabelList}
                                    row={printRowCaseList}
                                    dataLength={Object.keys(chatDataFilteredUnassigned).length}
                                    containerHeight={panelUnassignedHeight}
                                    mainProps={props}
                                    fetchingUnassignedCaseProps={fetchingUnassignedCase}
                                    fetchingUnassignedCaseIsErrorProps={fetchingUnassignedCaseIsError}
                                    onClickRetryMoreCaseUnassignedProps={onClickRetryMoreCase}
                                    onScrollRoomListListenerProps={() => onScrollRoomListListener("list-unassigned-case")}
                                    listNameProps={"list-unassigned-case"}
                                    needReplyLengthProps={Object.keys(needReplyDataUnassigned).length}
                                    needReplyData={needReplyDataUnassigned}
                                    refProps={lastUnassignedMineCaseReff}
                                    lastAssignedMineCaseProps={lastAssignedMineCase}
                                />

                                {props.caseSortBy.unassigned === SORT_MESSAGE_BY.last_message &&
                                    Object.keys(needReplyDataUnassigned).length > 0 &&
                                    <div className="need-reply-unread-panel-wrapper">
                                        <div className="need-reply-unread-panel-content">
                                            <div className="need-reply-panel">
                                                <b>Need Reply</b> <div className="need-reply-unread-panel-counter"><b>{Object.keys(needReplyDataUnassigned).length}</b></div>
                                            </div>

                                            {/* <div className="unread-panel">
                                                <b>Unread</b> <div className="need-reply-unread-panel-counter"><b>{totalUnreadCountUnassigned}</b></div>
                                            </div> */}
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            searchNotFoundUnassigned ?
                                <div className="room-list-no-chat-container">
                                    <b>
                                        No Results Were Found
                                        </b>

                                    {(isFilterTopicAgentChannelActiveUnassigned || isSearchActiveUnassigned) &&
                                        <p>
                                            {FILTER_MESSAGE.filter}
                                        </p>
                                    }

                                    {/* <p>
                                            Please try other filter options, or reset your filter options.
                                        </p> */}

                                    <p className="no-chat-button" onClick={() => resetFilterAndSearchValue()}>
                                        <FiRotateCcw /> <b>Reset Filter Options</b>
                                    </p>
                                </div>
                                :
                                <RoomListNoChat text="It seems like there aren't any other ongoing cases at this very moment." />
                }
            </div>

            <div className={`open-case-mine-room-wrapper room-list-chat-container ${props.toggleMineCase ? "active-open-case-list-panel" : ""}`}>
                {!isRoomListMineLoadingFinish ?
                    <div className="room-list-loading-wrapper">
                        <div className="lds-ring">
                            <div /><div /><div /><div />
                        </div>
                        <b>Loading Cases</b>
                    </div>

                    :

                    !isLoadingSearchOpenCaseFinishMine ?
                        <div className="room-list-loading-wrapper">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                            <b>Searching for Results</b>
                        </div>
                        :
                        Object.keys(chatDataFilteredMine).length > 0 ?
                            <div>
                                <ListCaseComponent
                                    case={chatDataFilteredMine}
                                    caseLabel={props.caseLabelList}
                                    row={printRowCaseList}
                                    dataLength={Object.keys(chatDataFilteredMine).length}
                                    containerHeight={panelMineCaseHeight}
                                    mainProps={props}
                                    fetchingMineCaseProps={fetchingMineCase}
                                    fetchingMineCaseIsErrorProps={fetchingMineCaseIsError}
                                    onClickRetryMoreCaseUnassignedProps={onClickRetryMoreCase}
                                    onScrollRoomListListenerProps={() => onScrollRoomListListener("list-mine-case")}
                                    listNameProps={"list-mine-case"}
                                    needReplyLengthProps={Object.keys(needReplyDataMine).length}
                                    needReplyData={needReplyDataMine}
                                    isLastCaseHavingMarginBottomProps={props.caseSortBy.mine !== SORT_MESSAGE_BY.created_time}
                                    refProps={lastAssignedMineCaseReff}
                                    lastAssignedMineCaseProps={lastAssignedMineCase}
                                />

                                {props.caseSortBy.mine === SORT_MESSAGE_BY.last_message &&
                                    Object.keys(needReplyDataMine).length > 0 &&
                                    <div className="need-reply-unread-panel-wrapper">
                                        <div className="need-reply-unread-panel-content">
                                            <div className="need-reply-panel">
                                                <b>Need Reply</b> <div className="need-reply-unread-panel-counter"><b>{Object.keys(needReplyDataMine).length}</b></div>
                                            </div>

                                            {/* <div className="unread-panel">
                                                        <b>Unread</b> <div className="need-reply-unread-panel-counter"><b><b>{totalUnreadCountMine}</b></b></div>
                                                    </div> */}
                                        </div>
                                    </div>
                                }
                            </div>
                            :
                            searchNotFoundMine ?
                                <div className="room-list-no-chat-container">
                                    <b>
                                        No Results Were Found
                                        </b>

                                    {(isFilterTopicAgentChannelActiveMine || isSearchActiveMine) &&
                                        <p>
                                            {FILTER_MESSAGE.filter}
                                        </p>
                                    }

                                    {/* <p>
                                            Please try other filter options, or reset your filter options.
                                        </p> */}

                                    <p className="no-chat-button" onClick={() => resetFilterAndSearchValue()}>
                                        <FiRotateCcw /> <b>Reset Filter Options</b>
                                    </p>
                                </div>
                                :
                                <RoomListNoChat text="It seems like there aren't any other ongoing cases at this very moment." />

                }
            </div>
            {/* </Scrollbars> */}

            <FilterBy
                filterLabelValueProps={filterLabelMine}
                onSelectLabelProps={onSelectLabel}
                filterTopicValueProps={filterTopicValueMine}
                filterAgentValueProps={filterAgentValueMine}
                filterMediumValueProps={filterMediumValueMine}
                filterCaseTypeProps={filterCaseTypeMine}
                filterCaseTypeBeforeProps={filterCaseTypeMineBefore}
                isOpenProps={showModalFilterTopicAgentMine}
                updateFilterProps={runUpdateFilter}
                closeModal={() => setShowModalFilterTopicAgentMine(false)}
                toggleProps={toggleModalFilter}
                agentListProps={agentList}
                topicListProps={topicList}
                toggleIsActiveFilterProps={toggleIsActiveFilter}
                isResetFilterProps={isResetFilterMine}
                toggleResetFilterProps={toggleResetFilter}
                onSelectTopicProps={onSelectTopic}
                onSelectAgentProps={onSelectAgent}
                onSelectMediumProps={onSelectMedium}
                onSelectCaseType={onSelectCaseType}
                isHideAgentProps={true}
                orgID={props.parentProps.match.params.orgID}
                room="open"
            />

            <FilterBy
                filterLabelValueProps={filterLabelUnassigned}
                onSelectLabelProps={onSelectLabel}
                filterTopicValueProps={filterTopicValueUnassigned}
                filterAgentValueProps={filterAgentValueUnassigned}
                filterMediumValueProps={filterMediumValueUnassigned}
                isOpenProps={showModalFilterTopicAgentUnassigned}
                filterCaseTypeProps={filterCaseTypeUnassigned}
                filterCaseTypeBeforeProps={filterCaseTypeUnassignedBefore}
                updateFilterProps={runUpdateFilter}
                closeModal={() => setShowModalFilterTopicAgentUnassigned(false)}
                toggleProps={toggleModalFilter}
                agentListProps={agentList}
                topicListProps={topicList}
                toggleIsActiveFilterProps={toggleIsActiveFilter}
                isResetFilterProps={isResetFilterUnassigned}
                toggleResetFilterProps={toggleResetFilter}
                onSelectTopicProps={onSelectTopic}
                onSelectAgentProps={onSelectAgent}
                onSelectMediumProps={onSelectMedium}
                onSelectCaseType={onSelectCaseType}
                isHideAgentProps={true}
                orgID={props.parentProps.match.params.orgID}
                hideJunk={true}
                room="open"
            />
        </div>
    );
}

const mapStateToProps = state => ({
    updateCaseLabel: state.updateCaseLabel,
    changeContactInfo: state.changeContactInfo,
    caseSortBy: state.caseSortBy,
    countryList: state.countryList,
    toggleMineCase: state.toggleMineCase,
    clockState: state.clockState,
    isWaitMarkAsJunk: state.isWaitMarkAsJunk,
    caseLabelList: state.caseLabelList,
    draftMessage: state.draftMessage,
    caseListChecked: state.caseListChecked,
    needReplyColorIndicator: state.needReplyColorIndicator,
    reconnectRoomList: state.reconnectRoomList,
    caseFilter: state.caseFilter
});

const mapDispatchToProps = {
    clearUpdateCaseLabel,
    clearChangeContactInfo,
    setCaseSortBy,
    clearStartConversation,
    clearStartConversationBa,
    setToggleMineCase,
    setClockState,   
    clearIsWaitMarkAsJunk,
    setQuickReplyList,
    setCaseListChecked,
    clearCaseListChecked,
    setReconnectRoomList,
    clearCaseFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(OpenCaseTab);
