import React, { useEffect, useState } from "react";
import "./AllowHandover.scss";
import { doToast, checkID } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import InboxConfigServices from "../../../../services/newServices/InboxConfigServices";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import mixpanel from "mixpanel-browser";

const AllowHandover = (props) => {
    let [val, setVal] = useState(false);
    let [valOrigin, setValOrigin] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [isWaitingSet, setIsWaitingSet] = useState(false);
    let [isShowModalSave, setIsShowModalSave] = useState(false);
    let [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [orgID, setOrgID] = React.useState(false);

    let back = () => {
        props.history.push(`/o/${props.match.params.orgID}/setup/inbox-settings`);
    }

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Inbox Setting",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if (orgID) {
            getData();
        }
    }, [orgID])

    useEffect(() => {
        checkSaveButtonEnabled();
    }, [val, valOrigin])

    useEffect(() => {
    }, [isSaveButtonEnabled])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        }
        else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/inbox-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getData = () => {
        let _retriveDataError = {...retriveDataError};
        setIsWaitingFetch(true);

        InboxConfigServices.getConfig(orgID, (response) => {
            let dataResult = response.dataResult;
            if (dataResult.error.message === "") {
                setValOrigin(JSON.parse(JSON.stringify(dataResult.data)));
                setVal(JSON.parse(JSON.stringify(dataResult.data)));
            }
            else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitingFetch(false);
        })
    }

    let setAllowHandover = () => {
        if (retriveDataError.code !== "49901") {
            setIsWaitingSet(true);

            let data = {
                allowHandoverCase: val.inboxConfigs.allowHandoverCase
            }

            InboxConfigServices.setAllowHandover(orgID, data, (response) => {
                setIsWaitingSet(false);
                setIsShowModalSave(false);
                let dataResult = response.dataResult;

                if (dataResult.data.success) {
                    let _valOrigin = {...valOrigin};
                    _valOrigin.inboxConfigs.allowHandoverCase = val.inboxConfigs.allowHandoverCase;
                    setValOrigin(_valOrigin);

                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                    
                    if (process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                            "[Action] Set Inbox Setting",
                            {
                                status : val.inboxConfigs.allowHandoverCase ? "On" : "Off",
                                userFullName : myAgentData.account.fullName,
                                userID : myAgentData.account.id,
                                useStateorganizationID : props.match.params.orgID.split("-")[0]
                            }
                        );
                    }

                    doToast(dataResult.data.message);
                }
                else {
                    doToast(dataResult.error.message, "fail");
                }
            })
        }
    }

    let toggleConfig = () => {
        let _inboxConfig = {...val};
        _inboxConfig.inboxConfigs.allowHandoverCase = !_inboxConfig.inboxConfigs.allowHandoverCase;
        setVal(_inboxConfig);
    }

    let checkSaveButtonEnabled = () => {
        if (val && val.inboxConfigs && valOrigin && valOrigin.inboxConfigs) {
            setIsSaveButtonEnabled(val.inboxConfigs.allowHandoverCase !== valOrigin.inboxConfigs.allowHandoverCase);
        }
        else {
            setIsSaveButtonEnabled(false);
        }
    }

    let toggleModalSave = () => {
        setIsShowModalSave(!isShowModalSave);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={valOrigin}
            dataAfterChange={val}
        >
            <BackButton 
                onClick={back}
                text="Back To Inbox Settings"
            />

            <div className="inbox-settings sectionWrap">
                <React.Fragment>
                    <div className="allowreply-settings-header">
                        <b>Allow Handover Case</b>
                            <ButtonWithLoadingOrIcon 
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                onClickAction={toggleModalSave}
                                isDisabled={!isSaveButtonEnabled}
                            />
                    </div>
                    
                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
                        <RetrieveDataLoading 
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message} 
                        />
                        :
                        <>
                            <div className="allowreply-settings-form">
                                <div className="allowreply-settings-form-content form-content-wrapper">
                                    <b>Allow Handover Case</b>
                                    <p>
                                        turning this on will allow agent to handover their case to another agent
                                    </p>
                                    
                                    <div className="closing-welcome-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input type="checkbox" checked={val.inboxConfigs.allowHandoverCase} onChange={() => toggleConfig()} />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </>

                    }
                </React.Fragment>
            </div>

            <PopupConfirmation
                isOpen={isShowModalSave}
                toggle={toggleModalSave}
                title="Save Changes?"
                description="You have changes in your Handover Case details, are you sure you want to continue?"
                className="modal-confirmation-save-changes"
                isLoading={isWaitingSet}
            >
                <ButtonWithLoadingOrIcon 
                    text="Save Changes"
                    className="main-button-48 orange-button"
                    isLoading={isWaitingSet}
                    isDisabled={isWaitingSet}
                    loadingColor="gray"
                    position="left"
                    onClickAction={setAllowHandover}
                />
            </PopupConfirmation>
        </SectionWrap>
    )
}

export default AllowHandover;
