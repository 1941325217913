import React, { useState, useEffect } from 'react';
import './IntegrationChannels.scss';
import channelsIcon from '../../../assets/img/integration/logo-channel-side-menu.svg';
// import IconAdd from '../../../assets/img/icon-add.svg';
// import Integrations from "../Integrations";
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import { TAPLIVE_MEDIUM_LOGO, TAPLIVE_MEDIUM_TEXT, TAPLIVE_MEDIUM_URL } from "../../../constants/taplive";
import { Link } from 'react-router-dom';
// import { FiArrowUpRight } from 'react-icons/fi';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import { checkID, doToast } from '../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

const IntegrationTaptalk = (props) => {
  let { match, history, mediumData } = props;
  let [orgID, setOrgID] = useState(false);
  let [showPopupWarning, setShowPopupWarning] = React.useState(false);

  let toggleShowWarning = () => {
      setShowPopupWarning(!showPopupWarning);
  }

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);
  
  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(orgID) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
            "[Page] Channels List",
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
}, [orgID])

useEffect(() => {
  if(props.history.location.state) {
    if(props.history.location.state.message) {
      doToast(props.history.location.state.message);
    }
    window.history.pushState(null, '');
  }
}, [props.history])

let findMediumCounter = (medium) => {
  let mediumCounter = 0;
  
  if(medium === "channels") {
    mediumData.mediums.map(v => {
      mediumCounter = mediumCounter + v.count;

      return null;
    })
  }else {
    if (mediumData) {
      let findIndex = mediumData.mediums.findIndex(val => val.code === medium);

      if (findIndex !== -1) {
        mediumCounter = mediumData.mediums[findIndex].count;
      }
    }
  }

  return mediumCounter;
}

return (
    <div className="integration-outer-wrapper integration-all-channel">
      <PopupWarning 
          isShow={showPopupWarning}
          toggle={toggleShowWarning}
          text="Maximum number of allowed channels has been reached"
      />
      
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-livechat-list text-left">
          
          <>
            <div className="channels-list-header">
              <img
                src={channelsIcon}
                alt=""
                className="channels-list-header-icon"
              />

              <div className="channels-list-header-text-wrapper">
                <b>Channels</b>
                <p>
                  Set up channels for receiving and sending messages
                </p>
              </div>
            </div>

            <div className="channels-list-outer-wrapper">
              {Object.keys(TAPLIVE_MEDIUM_TEXT).map((item, index) => {
                return (
                  (item !== "line" && item !== "twitter") ?
                    <Link
                      key={`channels-account-${index}`}
                      to={{
                        pathname: `/o/${orgID}/integration/${TAPLIVE_MEDIUM_URL[item]}`,
                        state: { detailChannel: item },
                      }}
                      className="link-livechat-account-list"
                    >
                      <div className="channels-list-inner-wrapper">
                        <div className="platform-wrapper">
                          <img src={TAPLIVE_MEDIUM_LOGO[item]} alt="" />

                          <p className="platform-name">
                            <b>{TAPLIVE_MEDIUM_TEXT[item]}</b>
                          </p>
                        </div>

                        {mediumData &&
                          (findMediumCounter(item) !== 0 &&
                            <span className='medium-counter'>{findMediumCounter(item)}</span>
                          )
                        }
                      </div>
                    </Link>
                    :
                    ""
                );
              })}
            </div> 
          </>
        </div>
        
        {mediumData &&
          <div className="your-channel-limit">
            <p>
              <b>Your Channels</b>
            </p>

            <b className="channel-limit">{mediumData.numberOfItems.current}/{mediumData.numberOfItems.maximum !== -1 ? mediumData.numberOfItems.maximum : "Unlimited"} channels</b>
          </div>
        }
      </SectionWrap>
    </div>
  );
};

const mapStateToProps = state => ({
  mediumData: state.mediumData
});

export default connect(mapStateToProps, null)(IntegrationTaptalk);
