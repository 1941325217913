import React, { useState, useEffect } from "react";
// import "../../auth/Auth.css";
import "./BillingNotification.scss";
import {
  checkID,
  doToast
} from "../../../../helper/HelperGeneral";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { FiPlus, FiInfo } from "react-icons/fi";
// import arrowTopRight from "../../assets/img/arrow-top-right-orange.svg";
import IconRemoveRed from "../../../../assets/img/icon-remove-red.svg";
// import { IoIosRefresh } from "react-icons/io";
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
// import Loading from "../../reuseableComponent/loading/Loading";
import NewButton from "../../../reuseableComponent/NewButton/NewButton";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import BillingServices from "../../../../services/newServices/BillingServices";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import BillingNotificationAdd from "./BillingNotificationAdd/BillingNotificationAdd";
import mixpanel from "mixpanel-browser";

const BillingNotification = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [billingNotifList, setBillingNotifList] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [showPopupSingleRemove, setShowPopupSingleRemove] = useState(false);
  let [singleRemoveBillingNotif, setSingleRemoveBillingNotif] = useState("");
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [reTypeEmail, setReTypeEmail] = useState("");
  let [showAddRecipient, setShowAddRecipient] = useState(false);
  let [valueModalAdd, setValueModalAdd] = useState([""]);
  let [valueModalAddError, setValueModalAddError] = useState([false]);
  let [loadingAddBilling, setLoadingAddBilling] = useState(false);
  let [showModalSubmit, setShowModalSubmit] = useState(false);
  let [showModalMaxLimit, setShowModalMaxLimit] = useState(false);
  let [showModalPleaseAdd, setShowModalPleaseAdd] = useState(false);
  let [emailDidNotMatch, setEmailDidNotMatch] = useState(false);

  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/home`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let toggleModalPleaseAdd = () => {
    setShowModalPleaseAdd(!showModalPleaseAdd);
  }

  let toggleModalMaxLimit = () => {
    setShowModalMaxLimit(!showModalMaxLimit);
  }

  let toggleModalSubmit = () => {
    setShowModalSubmit(!showModalSubmit);
  }

  let toggleModalNotificationAdd = () => {
    setValueModalAddError([false]);
    setValueModalAdd([""]);
    setShowAddRecipient(!showAddRecipient);
  }

  let onSubmitModalAdd = () => {
    setLoadingAddBilling(true);

    BillingServices.setBillingNotif(orgID, {emails: valueModalAdd}, (response) => {
      let dataResult = response.dataResult;
      
      if(dataResult.error.message === "") {
        if(dataResult.data.success) {
          toggleModalNotificationAdd();
          fetchBillingNotifList();
          doToast(dataResult.data.message);
        }else {
          if(dataResult.data.addedEmails === null) {
            toggleModalMaxLimit();
          }else {
            doToast(dataResult.data.message);
          }
        }
      }else {
        if(dataResult.error.code === "40002") {
          toggleModalMaxLimit();
        }else {
          doToast(dataResult.error.message, "fail");
        }
      }

      setLoadingAddBilling(false);
      toggleModalSubmit();
    })
  }

  let onChangeAddEmail = (e, i) => {
      let _data = valueModalAdd.slice();
      _data[i] = e.target.value;
      setValueModalAdd(_data);
  }

  let onAddMore = () => {
    let _data = valueModalAdd.slice();
    let _dataError = valueModalAddError.slice();

    if(_data.length < 5) {
        _data.push("");
        _dataError.push(false);
    }

    setValueModalAddError(_dataError);
    setValueModalAdd(_data);
  }

  let onDeleteClick = (i) => {
    let _data = valueModalAdd.slice();
    let _dataError = valueModalAddError.slice();
    
    _data.splice(i, 1);
    _dataError.splice(i, 1);

    setValueModalAdd(_data);
    setValueModalAddError(_dataError);
  }

  let isDisabledSubmitAdd = () => {
    let dis = false;
    let _data = valueModalAdd.slice();
    
    _data.map((v) => {
        if(v === "") {
            dis = true;
        }

        return null;
    })

    return dis;
  }

  let fetchBillingNotifList = () => {
    setWaitForResponseGetList(true);
    let _retriveDataError = {...retriveDataError};

    BillingServices.getBillingNotif(orgID, (response) => {
      let dataResult = response.dataResult;
      
      if(dataResult.error.message === "") {
        setBillingNotifList(dataResult.data.recipients);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if(orgID) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      fetchBillingNotifList();
      
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Billing Notification", 
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : orgID.split("-")[0]
            }
        );
      }
    }
  }, [orgID]);

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if(props.history.location.state) {
      if(props.history.location.state.message) {
        let _history = {...props.history.location.state};
        delete _history.message;

        doToast(props.history.location.state.message);
        props.history.replace({
          state: _history
        });
      }
    }
  }, [props.history])

  useEffect(() => {
    if(showPopupSingleRemove) {
      let elTarget = document.querySelector("#retype-billing");

      if(elTarget.length > 0) {
        elTarget.focus();
      }
    }
  }, [showPopupSingleRemove])

  let toggleModalRemoveBillingNotif = () => {
    setEmailDidNotMatch(false);
    setReTypeEmail("");
    setShowPopupSingleRemove(!showPopupSingleRemove);
  }

  let deleteBillingNotif = () => {
    if(reTypeEmail !== singleRemoveBillingNotif) {
      setEmailDidNotMatch(true);
    }else {
      setWaitForResponseDelete(true);
      
      BillingServices.deleteBillingNotif(orgID, {emails: [reTypeEmail]}, (response) => {
        let dataResult = response.dataResult;
        setWaitForResponseDelete(false);
        toggleModalRemoveBillingNotif();
  
        if(dataResult.error.message === "") {
          if(!response.dataResult.data.success) {
            doToast(response.dataResult.data.message, "fail");
          }else {
            doToast(response.dataResult.data.message);
            fetchBillingNotifList();
          }
        }else {
          doToast(response.dataResult.error.message, 'fail');
        }
      })
    }
  }

  let onClickSingleDelete = (data) => {
    setSingleRemoveBillingNotif(data.email);
    setShowPopupSingleRemove(true);
  }

  let addBtn = () => {
    let onClick = () => toggleModalNotificationAdd();
    let btnProps = { text: "Add Recipients", icon: FiPlus, onClick };

    return <NewButton {...btnProps} />;
  };

  return (
    <React.Fragment>
        <div className="sectionWrap billing-notif-main-wrapper">
            <p className="billing-notif-top-title">
                <b>Billing Notification</b>
            </p>
            
            {waitForResponseGetList || retriveDataError.code ? 
                <RetrieveDataLoading 
                    isLoading={waitForResponseGetList}
                    errorMessage={retriveDataError.message} 
                />
                :
                <React.Fragment>
                  <div className="topContent">
                      <div className="title">Email Recipients</div>
                      <div className="billing-notif-list-info">
                          All billings will be sent to owner’s email by default, and you can add up to five more recipients for the billings. 
                      </div>
                      <div 
                          className="btn"
                          onClick={() => {
                          let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                          
                          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                              mixpanel.track(
                              "[Button] Add Billing Recipient",
                              {
                                  type : "Billing",
                                  userFullName : myAgentData.account.fullName,
                                  userID : myAgentData.account.id,
                                  organizationID : orgID.split("-")[0]
                              }
                              )
                          }
                          }}
                      >
                          {addBtn()}
                      </div>
                  </div>

                  <div className="billing-notif-list-table-wrapper">
                      <div className="custom-table">
                          <table className="custom-table-content table-billing-notif-list">
                              {billingNotifList.length < 1 ?
                              <tbody>
                                  <tr>
                                  <td colSpan="3" className="no-data-column">
                                      {/* <b className="no-result-found"> */}
                                          Added recipients will be shown here.
                                      {/* </b> */}
                                  </td>
                                  </tr>
                              </tbody>
                              :
                              <tbody>
                                  {billingNotifList.map((value, index) => {
                                  return (
                                      <tr key={`billing-notif-${index}`}>
                                          <td className="col_message">{value.email}</td>
                                          <td className={`col_action`}>
                                          <div className="col-action-button-wrapper">
                                              <button 
                                                  className="single-remove-button no-background-and-border-button" 
                                                  onClick={() => {
                                                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                                    
                                                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                        mixpanel.track(
                                                        "[Button] Delete Billing Notificaton",
                                                            {
                                                            userFullName : myAgentData.account.fullName,
                                                            userID : myAgentData.account.id,
                                                            organizationID : orgID.split("-")[0]
                                                            }
                                                        )
                                                    }
                                                    
                                                    onClickSingleDelete(value)
                                                  }}
                                              >
                                                  <img className="view-agent" src={IconRemoveRed} alt="" />
                                                  <b>Delete</b>
                                              </button>
                                          </div>
                                          </td>
                                      </tr>
                                  )
                                  })}
                              </tbody>
                              }
                          </table>
                      </div>
                      
                      {/* <Pagination total={100} 
                                  page={1} 
                                  listPerPage={8} 
                                  step={3} 
                                  onChangePage={onChangePage} 
                      /> */}

                      <PopupRemove 
                          removeTitle={`Delete ${singleRemoveBillingNotif}?`}
                          removeDescription={`Please re-type email of the user you'd like to delete.`}
                          onClickCancel={() => toggleModalRemoveBillingNotif()}
                          onClickRemove={deleteBillingNotif}
                          waitForResponse={waitForResponseDelete}
                          showModalProps={showPopupSingleRemove}
                          // disabledButtonSubmit={reTypeEmail === "" || reTypeEmail !== singleRemoveBillingNotif}
                          disabledButtonSubmit={false}
                          colorButton={"red-button"}
                          submitText="Delete"
                          _className="popup-remove-billing"
                      >
                          <textarea 
                              id="retype-billing"
                              className="textarea-confirm-email" 
                              placeholder="user@email.com" 
                              value={reTypeEmail}
                              onChange={(e) => {
                                  setReTypeEmail(e.target.value);
                              }}
                          />

                          {emailDidNotMatch &&
                            <p className="red-text">
                              <b><FiInfo /> Emails do not match.</b>
                            </p>
                          }
                      </PopupRemove>
                  </div>
                </React.Fragment>
            }

            <BillingNotificationAdd 
                showModal={showAddRecipient}
                value={valueModalAdd}
                valueError={valueModalAddError}
                setValueError={setValueModalAddError}
                onChange={onChangeAddEmail}
                toggle={toggleModalNotificationAdd}
                onSubmit={onSubmitModalAdd}
                onAddMore={onAddMore}
                onDeleteClick={onDeleteClick}
                isDisabledSubmitAdd={isDisabledSubmitAdd}
                loadingAddBilling={loadingAddBilling}
                toggleModalSubmit={toggleModalSubmit}
                showModalSubmit={showModalSubmit}
                toggleModalMaxLimit={toggleModalMaxLimit}
                showModalMaxLimit={showModalMaxLimit}
                toggleModalPleaseAdd={toggleModalPleaseAdd}
                showModalPleaseAdd={showModalPleaseAdd}
                billingNotifList={billingNotifList}
            />
        </div>
    </React.Fragment>
  );
};

export default BillingNotification;
