import React, { useEffect, useState } from 'react';
import './CaseReportShow.scss';
import ReportService from '../../../../services/newServices/ReportService';
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperDate from '../../../../helper/HelperDate';
import { doToast, checkID } from '../../../../helper/HelperGeneral';
import { TAPLIVE_MEDIUM_LOGO } from "../../../../constants/taplive";
import { FiDownload, FiCircle, FiUser, FiCheckCircle } from 'react-icons/fi';
import NoReportFound from "../../../../assets/img/no-report-found.svg";
import AscendingDescendingTableHeader from '../../../reuseableComponent/AscendingDescendingTableHeader/AscendingDescendingTableHeader';
import AdvancedDateFilter from '../../../reuseableComponent/advancedDateFilter/AdvancedDateFilter';
import Pagination from "../../../reuseableComponent/pagination/Pagination";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";

const CaseReporting = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchCaseReporting, setIsWaitingFetchCaseReporting] = useState(true);
  let [printedCaseReportingData, setPrintedCaseReportingData] = useState([]);
  let [isLoadingExportReport, setIsLoadingExportReport] = useState(false);
  let [retriveDataError, setRetrieveDataError] = React.useState({
      code: false,
      message: false
  });
  let [dateVal, setDateVal] = useState({
    from:  new Date(new Date().setDate(new Date().getDate() - 30)),
    to: new Date()
  });
  let [filterDateForCalender, setFilterDateForCalender] = useState({
    from: undefined,
    to: undefined
  });
  let [filterBy, setFilterBy] = useState({
    text: "Last 30 Days",
    val: "last_30"
  });
  let [sort, setSort] = useState({
    by: "channel",
    order: "ASC"
  })
  // const [listPageVal, setListPageVal] = useState({
  //   label: '20',
  //   value: 20,
  // })
  const [pageDisplay, setPageDisplay] = useState(1);
  // const [listPage, setListPage] = useState(50);
  let [totalItems, setTotalItems] = useState(0);
  let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);

  const TABLE_HEADER = [
    {
      title: "Channel",
      value: "channel",
      class: "col_1" 
    },
    {
      title: "Case ID",
      value: "caseID",
      class: "col_2" 
    },
    {
      title: "Topic Name",
      value: "topicName",
      class: "col_2" 
    },
    {
      title: "Phone Number",
      value: "phoneNumber",
      class: "col_3" 
    },
    {
      title: "Email Address",
      value: "email",
      class: "col_4" 
    },
    {
      title: "Full Name",
      value: "fullName",
      class: "col_5" 
    },
    {
      title: "Created Time",
      value: "caseCreatedTime",
      class: "col_6" 
    },
    {
      title: "First Response Time",
      value: "firstResponseTime",
      class: "col_7" 
    },
    {
      title: "Resolved Time",
      value: "resolvedTime",
      class: "col_8" 
    },
    {
      title: "Assigned Agent Name",
      value: "assignedAgentName",
      class: "col_9" 
    },
    {
      title: "First Response Wait Duration",
      value: "firstResponseWaitDuration",
      class: "col_10" 
    },
    {
      title: "Case Duration",
      value: "caseDuration",
      class: "col_11" 
    },
    {
      title: "Case Status",
      value: "caseStatus",
      class: "col_12" 
    }
  ]

  useEffect(() => {
    if(orgID) {
      getCaseReporting();
    }
  }, [dateVal])

  useEffect(() => {
    if(orgID) {
      getCaseReporting();
    }
  }, [orgID])

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Case Reporting", 
        {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  const doMount = async () => {
      //filter
      setDateVal(props.filterData.dateVal);
      setFilterDateForCalender(props.filterData.filterDateForCalender);
      setFilterBy(props.filterData.filterBy);
      //filter

    const paramID = checkID(props.match);

    if (paramID) {
        setOrgID(paramID);
    } else {
        ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
                let responseData = response.dataResult.data;
                if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                const { id, createdTime } = responseData.memberships[0].organization;
                const urlOrgID = `${id}-${createdTime}`;
                props.history.push(`/o/${urlOrgID}/organization/members`);
            } else {
                console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
        });
    }
  };

  const downloadReport = (data) => {
    // const id = orgID.split('-');
    // const timestamp = + new Date();
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `case-report-data-from-${HelperDate.formatToString(new Date(dateVal.from), "dd-MM-yyyy")}-to-${HelperDate.formatToString(new Date(dateVal.to), "dd-MM-yyyy")}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const exportData = () => {
    let data = {
      startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
      endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : ""
    };

    setIsLoadingExportReport(true);
    
    ReportService.exportCaseReport(orgID, data,
      (response) => {
        setIsLoadingExportReport(false);
        if (!response) {
          // setShowModalFailed(true);
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  let getCaseReporting = (_sort = false, page = false) => {
    setIsWaitingFetchCaseReporting(true);
    let _retriveDataError = {...retriveDataError};
    let data = {
      startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
      endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
      pageNumber: !page ? pageDisplay : page,
      pageSize: 50,
      sortBy: !_sort ? sort.by : _sort.by,
      sortOrder: !_sort ? sort.order : _sort.order 
    }

    ReportService.getCaseReport(props.match.params.orgID, data, (response) => {
      setIsWaitingFetchCaseReporting(false);
      let dataResult = response.dataResult;

      if (dataResult.error.message === '') {
        setPrintedCaseReportingData(dataResult.data.cases);
        setTotalItems(dataResult.data.totalItems);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    });
  };

  let handleChangePage = (page) => {
    setPageDisplay(page)
    
    getCaseReporting(false, page);
  }

  let printText = (val) => {
    return val !== "" ? val : "-";
  }

  let printCaseStatus = (stats) => {
    let s = {
      "Open - Unassigned": {
        text: "Unassigned",
        img: <FiCircle />,
        class: "open-case"
      }, 
      "Open - Assigned": {
        text: "Ongoing",
        img: <FiUser />,
        class: "ongoing-case"
      },
      "Closed": {
        text: "Resolved",
        img: <FiCheckCircle />,
        class: "resolved-case"
      }
    }

    return (
      <div className={`case-report-status  ${s[stats].class}`}>{s[stats].img}<b>{s[stats].text}</b></div>
    )
  }

  let onSort = (_sort) => {
    let _s = {
      by: _sort,
      order: _sort === sort.by ? (sort.order === "ASC" ? "DESC" : "ASC") : "ASC"
    }
    
    setSort(_s);
    getCaseReporting(_s, false);
  }

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      } else {
        doToast(props.history.location.state.message, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history]);

  let printLabels = (labels) => {
    let labs = "";

    if(labels === "") {
      labs = "-";
    }else {
      let split = labels.split(",");
      split.map((v) => {
        labs += `<div class="case-report-label"><b>${v}</b></div>`;
        return null;
      })
    }

    return labs;
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="case-report-wrapper sectionWrap">
        {/* tier blocking */}
        {retriveDataError.code  === "49901" &&
          <RetrieveDataLoading 
            isLoading={isWaitingFetchCaseReporting}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="case-report-title">
            <b>Case Report</b>
          </p>

          {isLoadingExportReport ?
            <ButtonWithLoadingOrIcon 
              className="orange-button main-button-40 add-case-report-button"
              text="Download Report"
              position="left"
              isLoading
              loadingColor="gray"
              isDisabled
            />
            :
            <ButtonWithLoadingOrIcon 
                className="orange-button main-button-40 add-case-report-button"
                onClickAction={exportData}
                text="Download Report"
                position="left"
                icon={{
                  type: "svg",
                  src: FiDownload
                }}
            />
          }

          <div className="case-report-data-outer-wrapper">
            <AdvancedDateFilter 
              toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
              isOpenDropdown={isShowDropdownDate}
              dateVal={dateVal}
              filterDateForCalender={filterDateForCalender}
              filterBy={filterBy}
              onClickDate={(date) => setDateVal(date)}
              onClickFilterBy={(filter) => setFilterBy(filter)}
              onClickCalendar={(date) => {
                setFilterDateForCalender(date)
              }}
              ignoreAllTime
              isDisabled={isWaitingFetchCaseReporting}
              disabledDays={{
                after: new Date()
              }}
              maxRange={365}
            />

            {(isWaitingFetchCaseReporting || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
              <RetrieveDataLoading 
                isLoading={isWaitingFetchCaseReporting}
                errorMessage={retriveDataError.message}
                errorCode={retriveDataError.code}
                loadingText={"Loading Report"}
              />
              :
              <React.Fragment>
                <div className="case-report-data-wrapper">
                  {printedCaseReportingData.length === 0 ? 
                    <div className="no-case-report">
                      <img src={NoReportFound} alt="No Report Found" />

                      <p>
                        <b>No report found. </b>
                      </p>
                    </div>
                    :
                    <table>
                      <thead>
                        <tr>
                          <React.Fragment>
                            {TABLE_HEADER.map((val, idx) => {
                              return (
                                <React.Fragment key={`table-head-${idx}`}>
                                  <AscendingDescendingTableHeader 
                                    className={val.class}
                                    fieldName={val.value}
                                    title={val.title}
                                    onClick={() => onSort(val.value)}
                                    sortByVal={sort}
                                  />
                                </React.Fragment>
                              )
                            })}
                            <td className="col_3">
                              <b>Case Label</b>
                            </td>
                          </React.Fragment>
                        </tr>
                      </thead>

                      <tbody>
                          {printedCaseReportingData.map((value, key) => {
                            return (
                              <tr key={`case-report-row-${key}`}>
                                <td className="col_1">
                                  {TAPLIVE_MEDIUM_LOGO[value.medium] ?
                                    <img src={TAPLIVE_MEDIUM_LOGO[value.medium]} alt={`onetalk-channel-${value.channel}`} className="report-channel-medium" />
                                    :
                                    ""
                                  }
                                  {value.channel}
                                </td>
                                <td className="col_2">
                                  {value.caseID}
                                </td>
                                <td className="col_2">
                                  {value.topicName}
                                </td>
                                <td className="col_3">
                                  {printText(value.phoneNumber)}
                                </td>
                                <td className="col_4">
                                  {printText(value.email)}
                                </td>
                                <td className="col_5">
                                  {printText(value.fullName)}
                                </td>
                                <td className="col_6">
                                  {printText(value.caseCreatedTime)}                              
                                </td>
                                <td className="col_7">
                                  {printText(value.firstResponseTime)}
                                </td>
                                <td className="col_8">
                                  {printText(value.resolvedTime)}
                                </td>
                                <td className="col_9">
                                  {printText(value.assignedAgentName)}
                                </td>
                                <td className="col_10 text-right">
                                  {printText(value.firstResponseWaitDuration)}
                                </td>
                                <td className="col_11 text-right">
                                  {printText(value.caseDuration)}
                                </td>
                                <td className="col_12">
                                  {printCaseStatus(value.caseStatus)}
                                </td>
                                <td className="col_13" dangerouslySetInnerHTML={{__html: printLabels(value.caseLabels)}} />
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  }
                </div>

                <div className="pagination-wrapper">
                {
                    totalItems > 0 &&
                      <Pagination
                        total={totalItems}
                        page={pageDisplay}
                        listPerPage={50}
                        step={1}
                        onChangePage={handleChangePage}
                      />
                }
                </div>
              </React.Fragment>
            }
          </div>
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default CaseReporting;
