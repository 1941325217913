import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './Subscriptions.scss';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import BillingServices from '../../../../services/newServices/BillingServices';
import { rupiahFormat } from '../../../../helper/HelperGeneral';
import HelperDate from "../../../../helper/HelperDate";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
// import Loading from '../../../reuseableComponent/loading/Loading';
import { checkID } from '../../../../helper/HelperGeneral';
import iconCheck from '../../../../assets/img/icon-check-nofill-green.svg';
import iconAlert from '../../../../assets/img/icon-alert.svg';
import { FiAlertTriangle } from 'react-icons/fi';
// import { FiAlertTriangle, FiCreditCard, FiRotateCcw } from 'react-icons/fi';
// import iconUnsubscribe from '../../../../assets/img/icon-unsubscribe.svg';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import mixpanel from "mixpanel-browser";

const Subscriptions = (props) => {
  const { match, history } = props;
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [currentTierData, setCurrentTierData] = useState(false);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(true);
  const [subscriptionCount, setSubscriptionCount] = useState(0);
  const [orgID, setOrgID] = useState(false);
  let [unpaidList, setUnpaidList] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const getList = (orgID) => {
    let _retriveDataError = {...retriveDataError};

    BillingServices.getSubscriptionList(orgID, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.status === 200) {
        setSubscriptionData(response.dataResult.data.groups);
        const countPending = subscriptionTotal(response.dataResult.data.groups);
        setSubscriptionCount(countPending);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingSubscription(false);
    });
  };

  let getUnpaidList = () => {
    let _retriveDataError = {...retriveDataError};

    setIsLoadingSubscription(true);

    BillingServices.getUnpaidList(orgID, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.status === 200) {
        setUnpaidList(response.dataResult.data.invoices);
        getList(orgID);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        setIsLoadingSubscription(false);
      }
    });
  }

  useEffect(() => {
    setCurrentTierData(props.currentTierDetailProps)
  }, [props.currentTierDetailProps])

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(orgID) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
            "[Page] Subscriptions", 
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
}, [orgID])

  useEffect(() => {
    if (orgID) {
      getUnpaidList(orgID);
    }
  }, [orgID])

  const dataTotal = (items) => {
    return items.reduce((sum, item) => {
      return sum + item.amount;
    }, 0);
  };

  const subscriptionTotal = (data) => {
    let total = 0;
    data.map((value) => {
      value.items.map((value) => {
        total = total + 1;

        return null;
      })

      return null;
    })

    return total;
    // return data.reduce((sumTotal, group) => {
    //   const pending = group.items.filter((item) => {
    //     return item.status === 'pending';
    //   });
    //   return pending.length + sumTotal;
    // }, 0);
  };

  const statusDetail = {
    active: {
      icon: iconCheck,
      class: 'active',
    },
    pending: {
      icon: iconAlert,
      class: 'alert',
    },
    stopped: {
      icon: iconAlert,
      class: 'alert',
    },
    suspended: {
      icon: iconAlert,
      class: 'alert',
    },
    waiting_setup: {
      icon: iconAlert,
      class: 'alert'
    }
  };


  return (
    <div className="subscriptions-outer-wrapper">
      {isLoadingSubscription || retriveDataError.code ? 
            <RetrieveDataLoading 
              isLoading={isLoadingSubscription}
              errorMessage={retriveDataError.message} 
            /> 
          : (
          <>
            {unpaidList.length > 0 &&
              <div className="alert-component">
                <FiAlertTriangle />
                <b>You have {unpaidList.length} unpaid invoice{unpaidList.length > 1 ? 's' : ''}</b>

                {/* {subscriptionCount > 0 && */}
                <NavLink to={`/o/${props.match.params.orgID}/organization/billing/unpaid-invoices`}>
                  <button className="orange-button">
                    <b>View</b>
                  </button>
                </NavLink>
                {/* } */}
              </div>
            }

            {currentTierData &&
              (subscriptionCount === 0 || currentTierData.id === 0) &&
              <div className="empty-subscription-wrapper">
                {subscriptionCount === 0 &&
                  <b>No subscriptions found.</b>
                }

                {currentTierData.id === 0 &&
                  <>
                    <b>You can start subscribing to one of OneTalk’s tiers by clicking “Select Tier”.</b>
                    <br />
                    <NavLink to={`/o/${orgID}/select-tier`}>
                      <ButtonWithLoadingOrIcon 
                          className="orange-button main-button-40"
                          text="Select Tier"
                      />
                    </NavLink>
                  </>
                }
              </div>
            }

            {subscriptionData.length > 0
              ? subscriptionData.map((data, id) => {
                return (
                  <div className="subscriptions sectionWrap" key={id}>
                    <>
                      <div className="subscriptions-header">
                        <b>{data.name}</b>

                        {/* <ButtonWithLoadingOrIcon 
                            className="no-fill-button main-button-40"
                            text="Pay Now"
                            icon={{
                              type: "svg",
                              src: FiCreditCard
                            }}
                            position="left"
                        />

                        <ButtonWithLoadingOrIcon 
                          className="no-fill-button main-button-40"
                          text="Retry Payment"
                          icon={{
                            type: "svg",
                            src: FiRotateCcw
                          }}
                          position="left"
                        /> */}
                      </div>
                      <div className="subscription-table">
                        <table>
                          <thead>
                            <tr>
                              <td className="col_desc">
                                <b>Description</b>
                              </td>
                              <td className="col_price">
                                <b>Price</b>
                              </td>
                              <td className="col_paid">
                                <b>Paid until</b>
                              </td>
                              <td className="col_status">
                                <b>Status</b>
                              </td>
                              {/* <td></td> */}
                            </tr>
                          </thead>
                          <tbody>
                            {data.items.map((item, id) => {
                              return (
                                <tr key={id}>
                                  <td className="col_desc">
                                    {item.description}
                                  </td>
                                  <td className="col_price">
                                    {rupiahFormat(item.amount)}
                                  </td>
                                  <td className="col_paid">
                                    {item.paidUntil === 0 ? "-" : HelperDate.formatToString(new Date(item.paidUntil), "d MMM yyyy")}
                                  </td>
                                  <td className="col_status">
                                    <span
                                      className={`subscription-label ${statusDetail[item.status].class
                                        }`}
                                    >
                                      <img
                                        src={statusDetail[item.status].icon}
                                        alt=""
                                      />
                                      <b>{item.statusText}</b>
                                    </span>
                                  </td>
                                  {/* <td className="unsubscribe">
                                    <img src={iconUnsubscribe} alt="" />
                                    <b>Unsubscribe</b>
                                  </td> */}
                                </tr>
                              );
                            })}
                            <tr>
                              <td className="col_total" colSpan="5">
                                <b>
                                  Total: {rupiahFormat(dataTotal(data.items))}
                                </b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </>
                  </div>
                );
              })
              : ''}
          </>
        )}
    </div>
  );
};

export default Subscriptions;
