import React, { useState, useRef, useEffect } from 'react';
import { Modal, ModalBody } from 'reactstrap';
import './ChatRoomInputMessage.scss';
import { FiPaperclip, FiChevronLeft, FiChevronRight, FiX, FiUpload, FiSmile, FiFile, FiVideo, FiImage } from 'react-icons/fi';
import { MdInsertDriveFile, MdImage } from 'react-icons/md';
import { tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { connect } from 'react-redux';
import { IoIosClose } from "react-icons/io";
import AddImageWhite from "../../../../assets/img/add-image-white.svg";
import iconCheckBlack from "../../../../assets/img/icon-check-black.svg";
import Airplane from '../../../../assets/img/icon-airplane.svg';
import IconSalestalkGrey from '../../../../assets/img/chatroom/icon-salestalk-button-grey.svg';
import IconSalestalkOrange from '../../../../assets/img/chatroom/icon-salestalk-button-orange.svg';
import CaseService from "../../../../services/chat/caseServices";
import StartConversationServices from "../../../../services/chat/startConversationServices";
import { doToast } from "../../../../helper/HelperGeneral";
import HelperUpload from "../../../../helper/HelperUpload";
import Helper from '../../../../helper/HelperChat';
import HelperQuickReply from "../../../../helper/HelperQuickReply";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import { clearStartConversation } from '../../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../../redux/actions/reduxActionStartConversationBa';
import { setPreviewImageOrVideo } from "../../../../redux/actions/reduxActionPreviewImageOrVideo";
import { setToggleMineCase } from "../../../../redux/actions/reduxActionToggleMineCase";
import { setActiveCasePanel } from '../../../../redux/actions/reduxActionActiveCasePanel';
import { clearReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { setSalesTalkInbox } from '../../../../redux/actions/reduxActionSalesTalkInbox';
import { setQuickReplyDocument } from '../../../../redux/actions/reduxActionQuickReplyDocument';
import ModalSendTemplatedMessage from "../../../reuseableComponent/modalSendTemplatedMessage/ModalSendTemplatedMessage";
import mixpanel from "mixpanel-browser";
import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';
import ChatRoomReplyMessage from "./chatRoomReplyMessage/ChatRoomReplyMessage";
import { TAPLIVE_MEDIUM } from "../../../../constants/taplive";

const MESSAGE_TYPE = {
  text: "text",
  image: "image",
  file: "file",
  video: "video",
  document: "document"
}

var ChatRoomInputMessage = (props) => {
  let [inputFileShow, setInputFileShow] = useState(false);
  let [isLoadingCloseCase, setisLoadingCloseCase] = useState(false);
  let chatRoomMesageInputRef = useRef("messageInput");
  let [isShowModalMedia, setIsShowModalMedia] = useState(false);
  let [isActiveButtonSend, setIsActiveButtonSend] = useState(false);
  let [isTyping, setIsTyping] = useState(false);
  let [typingTimeoutID, setTypingTimeoutID] = useState(0);
  
  let [arrayOfFileUploadValue, setArrayOfFileUploadValue] = useState([]);

  // let [sendMediaType, setSendMediaType] = useState('');
  // let [captionLimit, setCaptionLimit] = useState(0);
  // let [mediaImageFileSrc, setMediaImageFileSrc] = useState('');
  // let [mediaVideoFileSrc, setMediaVideoFileSrc] = useState('');
  let [lastChangeMediaInput, setLastChangeMediaInput] = useState({
    files: [],
    time: ""
  });
  let [arrayOfMediaUploadValue, setArrayOfMediaUploadValue] = useState([]);
  let [currentActiveSelectedFile, setCurrentActiveSelectedFile] = useState(null);
  let [showDropFileHereOnModal, setShowDropFileHereOnModal] = useState(false);

  let [shorcutListToBeShow, setShortcutListToBeShow] = useState([]);
  let [currentActiveSelectedShortcut, setCurrentActiveSelectedShortcut] = useState(false);
  // let [mediaFile, setMediaFile] = useState('');
  let [loadingResponseWhatsappMessage, setLoadingResponseWhatsappMessage] = useState(false);
  let [startConversationBelongTo, setStartConversationBelongTo] = useState(false);
  
  useEffect(() => { 
    if(currentActiveSelectedShortcut) {
      // let maxOffsetTop = 257;
      let _shorcutListToBeShow = shorcutListToBeShow.slice();
      let shorcutListToBeShowLastIndex = shorcutListToBeShow.length - 1; 
      let _currentActiveSelectedShortcut = {...currentActiveSelectedShortcut};
      let currentIndex = _currentActiveSelectedShortcut.index;
      
      let onKeydownShortcut = (e) => {
        let el = document.querySelectorAll(".quick-reply-inner-wrapper")[0];
        
        if(e.keyCode === 40) {
          // arrow down
          if(currentIndex === shorcutListToBeShowLastIndex) {
            _currentActiveSelectedShortcut.index = 0;
            _currentActiveSelectedShortcut.shortcut = _shorcutListToBeShow[0];
            el.scrollTop = 0;
          }else {
            _currentActiveSelectedShortcut.index = currentIndex + 1;
            _currentActiveSelectedShortcut.shortcut = _shorcutListToBeShow[currentIndex + 1];

            if((currentIndex+1) > 5 ) {
              el.scrollTop = el.scrollTop + 37;
            }
          }

          setCurrentActiveSelectedShortcut(_currentActiveSelectedShortcut);
        }

        if(e.keyCode === 38) {
          // arrow up
          if(currentIndex === 0) {
            _currentActiveSelectedShortcut.index = shorcutListToBeShowLastIndex;
            _currentActiveSelectedShortcut.shortcut = _shorcutListToBeShow[shorcutListToBeShowLastIndex];
            el.scrollTop = el.scrollHeight;
          }else {
            _currentActiveSelectedShortcut.index = currentIndex - 1;
            _currentActiveSelectedShortcut.shortcut = _shorcutListToBeShow[currentIndex - 1];

            // if((currentIndex % 2) === 0 ) {
              el.scrollTop = el.scrollTop - 37;
            // }
          }

          setCurrentActiveSelectedShortcut(_currentActiveSelectedShortcut);
        }
      }

      window.addEventListener('keydown', onKeydownShortcut);

      return () => {
          window.removeEventListener('keydown', onKeydownShortcut);
      }
    }
  }, [currentActiveSelectedShortcut])

  useEffect(() => {
    if(lastChangeMediaInput.time !== "") {
      runMediaInputChange(lastChangeMediaInput.files);
    }
  }, [lastChangeMediaInput])

  useEffect(() => {
    if(props.lastDragAndDropFilesProps.files.length > 0) {
      runMediaInputChange(props.lastDragAndDropFilesProps.files)
    }
  }, [props.lastDragAndDropFilesProps])

  useEffect(() => {
    setIsActiveButtonSend(false);
    setShortcutListToBeShow([]);
    setCurrentActiveSelectedShortcut(false);

    if(props.activeRoom !== null) {
      let selectorInputText = document.querySelectorAll(".main-textarea-input")[0];
      selectorInputText.focus();
    }
  }, [props.activeRoom])
  
  useEffect(() => {
    if(arrayOfFileUploadValue.length > 0) {
      props.runningFileMessageProps(arrayOfFileUploadValue);
      setArrayOfFileUploadValue([]);
    }
  }, [arrayOfFileUploadValue]);

  useEffect(() => {
    bodyClickListener();
    // listenerDocumentInputChange();
    // listenerMediaInputChange();
    let elTextInput = document.getElementsByClassName("main-textarea-input")[0];

    if(props.replyMessage.message) {
      elTextInput.focus();
    }

    // input growing
    let elChatRoomSolve = document.getElementsByClassName("taplive-main-chat-room-solve-wrapper")[0];
    let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
    let maxHeightMarkAsSolvedInactive = 102; // 158
    let maxHeightMarkAsSolvedActive = 142; // 198
    
    let setChatRoomHeight = (height) => {
      elChatRoomMain.style.setProperty("max-height", "calc(100vh - "+height+"px)", "important");
    };

    let onInputMessageListener = (e) => {
      let defaultTextAreaHeight = 42;       
      let maxTextareaHeight = 138; // 5 rows
      let replyMessagaHeight = 68;

      if(e.target.value === "") {
          elTextInput.removeAttribute("style");
          if(elChatRoomSolve.offsetHeight === 40) {
            elChatRoomMain.style.setProperty("max-height", "calc(100vh - "+maxHeightMarkAsSolvedActive+"px)", "important");
          }else {
            if(props.replyMessage.message) {
              elChatRoomMain.style.setProperty("max-height", "calc(100vh - 169px)", "important");
            }else {
              elChatRoomMain.removeAttribute("style");
            }
          }
      }

      let textAreaHeightBefore = e.target.style.height === "" ? 42 : parseInt(e.target.style.height.replace("px", ""));
      
      elTextInput.style.height = "";
      elTextInput.style.height = elTextInput.scrollHeight + "px";

      let textareaCurrentHeight = parseInt(e.target.style.height.replace("px", ""));
      let textareaActualCurrentHeight = elTextInput.offsetHeight;
      
      if(textAreaHeightBefore !== textareaCurrentHeight) {
          if(textareaActualCurrentHeight <= maxTextareaHeight) {
              if(textAreaHeightBefore < textareaCurrentHeight) {
                let chatRoomContainerMaxHeightVal = (elChatRoomSolve.offsetHeight !== 40 ?  maxHeightMarkAsSolvedInactive : maxHeightMarkAsSolvedActive) + (textareaActualCurrentHeight - defaultTextAreaHeight);
                setChatRoomHeight(props.replyMessage.message ? chatRoomContainerMaxHeightVal + replyMessagaHeight : chatRoomContainerMaxHeightVal);
              }

              if(textAreaHeightBefore > textareaCurrentHeight) {
                let chatRoomContainerMaxHeightVal = (elChatRoomSolve.offsetHeight !== 40 ?  maxHeightMarkAsSolvedInactive : maxHeightMarkAsSolvedActive) - (defaultTextAreaHeight - textareaActualCurrentHeight);
                setChatRoomHeight(props.replyMessage.message ? chatRoomContainerMaxHeightVal + replyMessagaHeight : chatRoomContainerMaxHeightVal);
              }
          }
      }
    }

    elTextInput.addEventListener("input", onInputMessageListener);

    //input growing

    return () => {
      elTextInput.removeEventListener("input", onInputMessageListener)
    }
  }, [props.replyMessage]);

  let actionCaseClose = () => {
    setisLoadingCloseCase(true);
    let caseID = props.activeRoom.xcRoomID.split(':')[1];
    let data = {
      id: parseInt(caseID),
    }
    CaseService.postCloseCase(props.parentProps.match.params.orgID, data, (response) => {
      setisLoadingCloseCase(false);
      if(response.dataResult.error.code === "") {
        Helper.resetChatRoomHeightAndInputText();
        props.toggleMarkAsSolvedPanelProps(false);
        doToast(response.dataResult.data.message);
      }else {
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  }

  let bodyClickListener = () => {
    let target = document.querySelector('body');
    
    target.addEventListener('click', function() {
      setInputFileShow(false)
    })
  }

  let listenerDocumentInputChange = (e) => {    
    // let target = document.querySelector('#document-input');
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    // target.addEventListener('change', function(e) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Action] Attach File",
          {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.parentProps.match.params.orgID.split("-")[0]
          }
        );
      }

      HelperUpload.checkFileLimit(e.target.files, {
        onAllowed: () => {
          HelperUpload.checkFileSizeLimit(e.target.files, {
            onAllowed: (arrayOfFiles) => {
              setArrayOfFileUploadValue(arrayOfFiles);
            },
            onReachLimit: (message) => {
              doToast(message, "fail");
            }
          })
        },
        onReachLimit: (message) => {
          doToast(message, "fail");
        }
      })

      e.target.value = null;
    // })
  }

  let actionOnMediaInputChange = (mediaFiles) => {
    setArrayOfMediaUploadValue(mediaFiles);
    
    setCurrentActiveSelectedFile({
      file: mediaFiles[mediaFiles.length - 1],
      index: mediaFiles.length - 1
    })

    setIsShowModalMedia(true);
  }

  let runMediaInputChange = (files) => {
    HelperUpload.checkFileAllowed(files, {
      onAllowed: () => {
        HelperUpload.checkFileLimit(files, {
          onAllowed: () => {
            HelperUpload.checkFileSizeLimitForMedia(arrayOfMediaUploadValue, files, {
              onAllowed: (arrayOfMedia) => {
                actionOnMediaInputChange(arrayOfMedia);
              },
              onReachLimit: (message) => {
                doToast(message, "fail");
              }
            })
          },
          onReachLimit: (message) => {
            doToast(message, "fail");
          }
        })
      },
      onFileTypeDidntMatch: (message) => {
        doToast(message, "fail");
      }
    })
  }

  let listenerMediaInputClick = (e) => {
    e.target.value = null;
  }

  let listenerMediaInputChange = (e) => {
    // let target = document.querySelector('#media-input');

    // target.addEventListener('click', function(e) {
    //   this.value = null;
    // })
    
    // target.addEventListener('change', function(e) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Action] Attach Media",
          {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.parentProps.match.params.orgID.split("-")[0]
          }
        );
      }

      setLastChangeMediaInput({
        files: e.target.files,
        time: new Date().valueOf()
      });
      
    // })
  }


  let showDocument = () => {
    let show = true;

    if(
      props.caseDetailData && 
      (
        props.caseDetailData.case.medium === TAPLIVE_MEDIUM.instagram ||
        (
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.twitter &&
          props.caseDetailData.case.mediumChannelID === 0
        ) ||
        (
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.facebook &&
          props.caseDetailData.case.mediumChannelID === 0
        ) ||
        props.caseDetailData.case.medium === TAPLIVE_MEDIUM.google_business
        // props.caseDetailData
      )
     ) {
      show = false;
    }
    
    return show;
  }

  let allowedMedia = () => {
    let _allow = "image/*,video/*";

    if(props.caseDetailData && props.caseDetailData.case.medium === TAPLIVE_MEDIUM.instagram) {
      _allow = "image/*";
    }
    
    return _allow;
  }

  let inputFileView = () => {
      return (
        <div className="input-file-wrapper-chat" style={{display: inputFileShow ? 'block' : 'none'}}>
          {showDocument() &&
            <>
              <label htmlFor="document-input" onClick={() => {
                let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                  mixpanel.track(
                    "[Button] Attach File",
                    {
                      userFullName : myAgentData.account.fullName,
                      userID : myAgentData.account.id,
                      organizationID : props.parentProps.match.params.orgID.split("-")[0]
                    }
                  );
                }
              }}>
                  <div className="input-file-content">
                      <MdInsertDriveFile />
                      <span>Documents</span>
                  </div>
              </label>
              <input type="file" name="document" id="document-input" multiple onChange={(e) => listenerDocumentInputChange(e)} />
            </>
          }
          
          {/* <label>
              <div className="input-file-content">
              <MdCameraAlt />
              <span>Camera</span> 
              </div>
          </label> */}

          <label htmlFor="media-input" onClick={() => {
            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Button] Attach Media",
                  {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : props.parentProps.match.params.orgID.split("-")[0]
                  }
                );
              }
            }

            onClickMediaInput();
          }}>
              <div className="input-file-content">
                  <MdImage />
                  <span>Media</span> 
              </div>
          </label>

          <input type="file" name="media" id="media-input" accept={allowedMedia()} multiple onClick={(e) => listenerMediaInputClick(e)} onChange={(e) => listenerMediaInputChange(e)} />

          {/* <label>
              <div className="input-file-content">
              <MdPermContactCalendar />
              <span>Contact</span> 
              </div>
          </label> */}
        </div>
      )
  }

  let onClickMediaInput = () => {
    // setCaptionLimit(0);
    // setSendMediaType('');
    // setMediaImageFileSrc('');
    // setMediaFile('');
    // setMediaVideoFileSrc('');
  }

  let onChangeCaptionInput = (e) => {
    let _currentActiveSelectedFile = {...currentActiveSelectedFile};
    let _arrayOfMediaUploadValue = arrayOfMediaUploadValue.slice();

    _arrayOfMediaUploadValue[_currentActiveSelectedFile.index].caption = e.target.value;
    
    setArrayOfMediaUploadValue(_arrayOfMediaUploadValue);
    // setCaptionLimit(e.target.value.length)
  }

  let startTyping = () => {
    if (isTyping) {
        return;
    }

    setIsTyping(true);
    
    tapCoreChatRoomManager.sendStartTypingEmit(props.activeRoom.roomID);
  }

  let stopTyping = () => {
    if (!isTyping) {
        return;
    }

    clearTimeout(typingTimeoutID);

    setIsTyping(false);
    setTypingTimeoutID(0);
    
    tapCoreChatRoomManager.sendStopTypingEmit(props.activeRoom.roomID);
  }

  let onChangeInputMessage = (e) => {
    if(e.target.value.length > 0) {
      setIsActiveButtonSend(true);
    }else {
      setIsActiveButtonSend(false);
    }
  }

  let keyPressInputMessage = async (e) => {
    if(e.which === 13 && !e.shiftKey) {        
        actionSubmitMessage(e);
    }

    if(e.target.value.length > 0) {
      setIsActiveButtonSend(true);
    }else {
      setIsActiveButtonSend(false);
    }
  }

  let actionSubmitMessage = async (e) => {
    e.preventDefault();

    setIsActiveButtonSend(false);
    
    if(props.quickReplyDocument) {
      props.runQuickReplyFile(props.quickReplyDocument);
    }else {
      if(!props.startConversation.conversation) {
        setShortcutListToBeShow([]);
        if(currentActiveSelectedShortcut.shortcut) {
          clickShortCut(currentActiveSelectedShortcut.shortcut);
        }else {
          setCurrentActiveSelectedShortcut(false);
          props.onInputNewMessageProps(chatRoomMesageInputRef.current.value, props.caseDetailData && props.caseDetailData.case.medium === TAPLIVE_MEDIUM.gb_profile);
          // chatRoomMesageInputRef.current.value = "";
  
          if(props.caseDetailData && props.caseDetailData.case.medium !== TAPLIVE_MEDIUM.gb_profile) {
            props.clearReplyMessage();
            Helper.resetChatRoomHeightAndInputText();
          }
        }
      }
    }

  }

  let clickShortCut = (shortcutMessage) => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Action] Use Quick Reply",
        {
          userFullName : myAgentData.account.fullName,
          userID : myAgentData.account.id,
          organizationID : props.parentProps.match.params.orgID.split("-")[0]
        }
      );
    }

    setCurrentActiveSelectedShortcut(false);
    
    if(shortcutMessage.messageType === "file") {
      let elTextInput = document.getElementsByClassName("main-textarea-input")[0];
      elTextInput.value = shortcutMessage.message;
      setShortcutListToBeShow([]);
      elTextInput.focus();
      elTextInput.dispatchEvent(new Event('input', { bubbles: true }));
      props.setQuickReplyDocument(shortcutMessage);
    }else if(shortcutMessage.messageType === "image" || shortcutMessage.messageType === "video") {
      let _data = {
        caption: shortcutMessage.message,
        fileSrc: shortcutMessage.mediaURL,
        size: 0,
        type: shortcutMessage.messageType,
        name: shortcutMessage.mediaFilename,
        isShortcut: true
      };

      setArrayOfMediaUploadValue([_data]);

      setCurrentActiveSelectedFile({
        file: _data,
        index: 0
      });

      setIsShowModalMedia(true);
      setShortcutListToBeShow([]);
    }else {
      let elTextInput = document.getElementsByClassName("main-textarea-input")[0];
      elTextInput.value = shortcutMessage.message;
      setShortcutListToBeShow([]);
      elTextInput.focus();
      elTextInput.dispatchEvent(new Event('input', { bubbles: true }));
    }
  }

  let resetMediaUploadState = () => {
    props.setLastDragAndDropFilesProps();
    setArrayOfMediaUploadValue([]);
    setCurrentActiveSelectedFile(null);
  }

  let toggleModalMedia = () => {
    resetMediaUploadState();
    setIsShowModalMedia(!isShowModalMedia);
  }

  let generateModalMedia = () => {
      let runSetActiveCurrentSelectedFile = (file, index) => {
        setCurrentActiveSelectedFile({
          file: file,
          index: index
        })
      }

      let singeRemoveSelectedFile =(index) => {
        let _arrayOfMediaUploadValue = arrayOfMediaUploadValue.slice();

        _arrayOfMediaUploadValue.splice(index, 1);

        if(_arrayOfMediaUploadValue.length === 0) {
          toggleModalMedia();
        }else {
          runSetActiveCurrentSelectedFile(_arrayOfMediaUploadValue[0], 0);
        }

        setArrayOfMediaUploadValue(_arrayOfMediaUploadValue);
      }

      let onClickSelectedFileNavigator = (direction) => {
        let _arrayOfMediaUploadValue = arrayOfMediaUploadValue.slice();
        let _currentActiveSelectedFile = {...currentActiveSelectedFile};
        
        if(direction === "left") {
          let index = _currentActiveSelectedFile.index - 1;
          runSetActiveCurrentSelectedFile(_arrayOfMediaUploadValue[index], index);
        }else {
          let index = _currentActiveSelectedFile.index + 1;
          runSetActiveCurrentSelectedFile(_arrayOfMediaUploadValue[index], index);
        }
      }

      let toggleShowDropFileHereOnModal = () => {
        setShowDropFileHereOnModal(!showDropFileHereOnModal);
      }

      let generateModalDragAndDropViewHere = () => {
        let handleDropFile = (e) => {
            props.hidedropFileHereProps();
            e.preventDefault();
            toggleShowDropFileHereOnModal();
            let files= e.dataTransfer.files;

            if(files.length > 0) {
              runMediaInputChange(files);
            }
        }
    
        return (
          <div className={`drop-file-here-wrapper ${showDropFileHereOnModal ? "active-drop-file-here-wrapper" : ""}`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {handleDropFile(e)}}
          >
            <div className="drop-file-here-content">
              <div className="drop-file-here-inner-content">
                <FiUpload />
    
                <p>
                  Drop your files, image or video here
                </p>
              </div>
            </div>
          </div>
        )
      }

      return (
          <div onDragEnter={() => toggleShowDropFileHereOnModal()} onDragLeave={() => toggleShowDropFileHereOnModal()}>
            <Modal isOpen={isShowModalMedia} className={'modal-media'}>
              <ModalBody>
                  {generateModalDragAndDropViewHere()}
                  <IoIosClose className="close-modal-video" onClick={() => toggleModalMedia()} />

                  {currentActiveSelectedFile !== null &&
                    <React.Fragment>
                      {currentActiveSelectedFile.index !== 0 &&
                        <FiChevronLeft  className="preview-arrow-navigator left-preview-navigator" onClick={() => onClickSelectedFileNavigator("left")} />
                      }

                      {currentActiveSelectedFile.index !== arrayOfMediaUploadValue.length - 1 &&
                        <FiChevronRight className="preview-arrow-navigator right-preview-navigator" onClick={() => onClickSelectedFileNavigator("right")} />
                      }

                      <div className="video-image-wrapper">
                          <div className="preview-container">
                            {/* {sendMediaType === 'image' ? */}
                              {/* <img src={mediaImageFileSrc} alt='' /> */}
                            {currentActiveSelectedFile.file.type.split("/")[0] === "image" ?
                              <>
                                <div className='blocking-img-preview' />
                                <img src={currentActiveSelectedFile.file.fileSrc} alt="" />
                              </>
                              :
                              <video controls id="video-input-preview" key={`video-preview-${currentActiveSelectedFile.file.name}`}>
                                <source src={currentActiveSelectedFile.file.fileSrc} type="video/mp4" />
                                <source src={currentActiveSelectedFile.file.fileSrc} type="video/ogg" />
                              </video>
                            }
                          </div>

                          {currentActiveSelectedFile !== null &&
                            <div className="caption-container">
                              <textarea 
                                placeholder="Add caption..." 
                                onChange={(e) => onChangeCaptionInput(e)} 
                                maxLength={1000}
                                id="input-caption"
                                value={arrayOfMediaUploadValue[currentActiveSelectedFile.index].caption}
                              />

                              {/* <img src={Airplane} 
                                  alt="" 
                                  className="airplane-icon" 
                                  onClick={() => submitMediaChatAction()} /> */}

                              <span className="caption-limit-text">{arrayOfMediaUploadValue[currentActiveSelectedFile.index].caption.length}/1000</span>
                            </div>
                          }
                      </div>
                    </React.Fragment>
                  }
                  
                  <div className="selected-file-wrapper">
                      <div className="file-counter">
                            <b>{arrayOfMediaUploadValue.length}/5</b>
                      </div>
                      
                      <div className="selected-file-container">
                        {arrayOfMediaUploadValue.length < HelperUpload.fileUploadLimit &&
                          <React.Fragment>
                            <label htmlFor="media-input" onClick={() => onClickMediaInput()}>
                              <div className="add-more-file-button">
                                <img src={AddImageWhite} alt="" />
                                <br />
                                Add More
                              </div>
                            </label>

                            <input type="file" name="media" id="media-input" accept='image/*,video/*' multiple />
                          </React.Fragment>
                        }
                        
                        {currentActiveSelectedFile !== null &&
                          arrayOfMediaUploadValue.map((value, index) => {
                            return (
                              currentActiveSelectedFile.index === index ? 
                                <div className={`selected-file-content active-selected-file`} 
                                    key={`media-thumbnail-${index}`}
                                >
                                  <div className="remove-selected-file-button" onClick={() => singeRemoveSelectedFile(index)}>
                                    <FiX />
                                  </div>
                                  
                                  {value.type.split("/")[0] === "image" ?
                                    <img src={value.fileSrc} alt="" className="media-thumbnail" />
                                    :
                                    <video src={value.fileSrc} alt="" className="media-thumbnail"/>
                                  }

                                  <div className="dark-layer" />
                                </div>
                                :
                                <div className={`selected-file-content ${currentActiveSelectedFile.index === index ? "active-selected-file" : ""}`} 
                                    key={`media-thumbnail-${index}`}
                                    onClick={() => runSetActiveCurrentSelectedFile(value, index)}
                                >
                                  <div className="remove-selected-file-button" onClick={() => singeRemoveSelectedFile(index)}>
                                    <FiX />
                                  </div>
                                  {value.type.split("/")[0] === "image" ?
                                    <img src={value.fileSrc} alt="" className="media-thumbnail" />
                                    :
                                    <video src={value.fileSrc} alt="" className="media-thumbnail" />
                                  }

                                  <div className="dark-layer" />
                                </div>
                            )
                          }
                        )}
                      </div>

                      <div className="send-media-button-wrapper">
                        <button className="orange-button" onClick={() => submitMediaChatAction()}>
                          <img src={Airplane} 
                              alt="" 
                              className="airplane-icon" 
                          />

                          <b>Send</b>
                        </button>
                      </div>
                  </div>
              </ModalBody>
            </Modal>
          </div>
      );
  }

  let submitMediaChatAction = () => {
    let timeout = 0;
    setIsShowModalMedia(false);
    // let targetInputCaption = document.querySelector('#input-caption');
    // let targetInputMedia = document.querySelector('#media-input');
 
    // if(sendMediaType === 'image') {
    //   props.runningImageMessageProps(mediaFile, targetInputCaption.value);
    // }

    // if(sendMediaType === 'video') {
    //   props.runningVideoMessageProps(mediaFile, targetInputCaption.value);
    // }
    arrayOfMediaUploadValue.map((value, index) => {
      if(value.isShortcut) {
        props.runQuickReplyMedia({
            url: value.fileSrc,
            fileURL: value.fileSrc,
            caption: value.caption
        }, value.type);
      }else {
        if(index > 0) { timeout += 700; }
  
        if(value.type.split("/")[0] === "image") {
          setTimeout(() => {
            props.runningImageMessageProps(value, value.caption);
          }, timeout);
        }else {
          setTimeout(() => {
            props.runningVideoMessageProps(value, value.caption);
          }, timeout); 
        }
  
        resetMediaUploadState();
      }
      return null;
    })
  }

  let onSelectedShortcut = (shortcut, index) => {
    setCurrentActiveSelectedShortcut({
      shortcut: shortcut,
      index: index
    });
  }
  
  let printQuickReply = () => {
    return (
      <div className="quick-reply-outer-wrapper">
        <div className="quick-reply-inner-wrapper">
          <div className="quick-reply-title-wrapper">
            <b>Quick Replies</b>
          </div>

          {shorcutListToBeShow.map((value, index) => {
              return (
                <p className={`
                    quick-reply-list-option-wrapper

                    ${value.messageType !== "text" ?
                      "quick-reply-list-option-wrapper-with-media"
                      :
                      ""
                    }

                    ${!currentActiveSelectedShortcut ? 
                      ""
                      :
                      currentActiveSelectedShortcut.index ===   index ?
                        "active-quick-reply-list-option-wrapper"
                        :
                        ""
                    }
                  `} 
                   title={value.message} 
                   key={`shortcut-${index}`}
                   onClick={() => clickShortCut(value)}
                   onMouseEnter={() => onSelectedShortcut(value, index)}
                >
                  <span>{HelperQuickReply.prefixShortcut}{value.shortcut}{value.ownerType === HelperQuickReply.ownerTypePersonal ? HelperQuickReply.suffixShortcut : ""} </span>
                  {value.message}

                  {value.messageType !== "text" &&
                    <div className='quick-reply-media-wrapper'>
                      {value.messageType === "image" &&
                        <>
                          <img src={value.mediaURL} alt="" />
                          
                          <div className='quick-reply-video-wrapper'>
                            <FiImage />
                          </div>
                        </>
                      }

                      {value.messageType === "video" &&
                        <>
                          <video src={value.mediaURL} />
                          
                          <div className='quick-reply-video-wrapper'>
                            <FiVideo />
                          </div>
                        </>
                      }

                      {value.messageType === "file" &&
                        <>
                          <img src={value.mediaURL} alt="" />
                            
                          <div className='quick-reply-video-wrapper quick-reply-video-wrapper-orange'>
                            <FiFile />
                          </div>
                        </>
                      }
                    </div>
                  } 
                </p>
              )
          })}
        </div>
      </div>
    )
  }

  let filterQuickReply = (shortcut) => {
    let _quickReplyList = props.quickReplyList.slice();
    setShortcutListToBeShow(_quickReplyList.filter(value => value.shortcut.startsWith(shortcut)));
    setCurrentActiveSelectedShortcut({
      shortcut: _quickReplyList.filter(value => value.shortcut.startsWith(shortcut))[0],
      index: 0
    })
  }

  let checkShortcutString = (e) => {
    let targetVal = e.target.value;
    if(targetVal[0] === "/") {
      filterQuickReply(targetVal.replace("/", ""));
    }else {
      setShortcutListToBeShow([]);
    }
  }

  let runSendWhatsappMessage = (forceText = false, callback = false) => {
    setLoadingResponseWhatsappMessage(true);
    
    let elInput = document.querySelectorAll(".main-textarea-input")[0];
    let _startConversation = {...props.startConversation};
    
    let dataChat = {
      channelID: _startConversation.conversation.channel.value,
      phone: _startConversation.conversation.recipient.contact.number.replace("+", ""),
      messageType: forceText ? "text" : ( _startConversation.conversation.type === undefined ? "text" : (_startConversation.conversation.type === "" ? "text" : _startConversation.conversation.type) ),
      body: forceText ? elInput.value : ( (_startConversation.conversation.type === MESSAGE_TYPE.image || _startConversation.conversation.type === MESSAGE_TYPE.video || _startConversation.conversation.type === MESSAGE_TYPE.file) ? _startConversation.conversation.fileAsset.fileURL : elInput.value ),
      filename: forceText ? "" : _startConversation.conversation.fileAsset.fileName,
      caption: _startConversation.conversation.type === MESSAGE_TYPE.file ? "" : elInput.value,
      topicID: _startConversation.conversation.topic.id
    }

    StartConversationServices.postSendMessageWhatsapp(props.parentProps.match.params.orgID, dataChat, (response) => {
        let dataResult = response.dataResult;
        let dataResultData = dataResult.data;

        if(dataResult.status === 200) {
            if(dataResultData.success) {
              props.setActiveCasePanel("open");
              props.setToggleMineCase(true);
              setStartConversationBelongTo(false);
              
              if(_startConversation.conversation.type !== MESSAGE_TYPE.file) {
                props.clearStartConversation();
                Helper.resetChatRoomHeightAndInputText();
              }

              if(_startConversation.conversation.type === MESSAGE_TYPE.file && !forceText && elInput.value !== "") {
                runSendWhatsappMessage(true, () => {
                  props.clearStartConversation();
                  Helper.resetChatRoomHeightAndInputText();
                })
              }

              if(elInput.value === "") {
                props.clearStartConversation();
                Helper.resetChatRoomHeightAndInputText();
              }

              if(callback) {
                callback();
              }

              doToast("Successfully send the message");
            }else {
              doToast(dataResultData.message, "fail");
            }
        }else {
            doToast(dataResult.error.message, "fail");
        }

        setLoadingResponseWhatsappMessage(false);
    })
  }

  let runSendWhatsappMessageBa24Hour = () => {
    setLoadingResponseWhatsappMessage(true);
    
    let _startConversation = {...props.startConversationBa};
    Object.keys(_startConversation.conversation.language.parameters).map((value) => {
      if(_startConversation.conversation.language.parameters[value].length > 0) {
        _startConversation.conversation.language.parameters[value].map((_value) => {
            delete _value["isPredefined"];
            delete _value["number"];
            delete _value["type"];
            return null;
        })
      }

      return null;
    })

    let dataChat = {
      id: props.caseDetailData.case.id,
      createdTime: props.caseDetailData.case.createdTime,
      templateID: _startConversation.conversation.template.value,
      languageCode: _startConversation.conversation.language.languageCode,
      parameters: _startConversation.conversation.language.parameters
    };

    StartConversationServices.postReplyMessageWhatsappBa(props.parentProps.match.params.orgID, dataChat, (response) => {
      let dataResult = response.dataResult;
      let dataResultData = dataResult.data;

      if(dataResult.status === 200) {
          if(dataResultData.success) {
            props.setActiveCasePanel("open");
            props.setToggleMineCase(true);
            props.clearStartConversationBa();
            setStartConversationBelongTo(false);
            Helper.resetChatRoomHeightAndInputText();
            doToast("Successfully send the message");
          }else {
            doToast(dataResultData.message, "fail");
          }
      }else {
          doToast(dataResult.error.message, "fail");
      }

      setLoadingResponseWhatsappMessage(false);
      toggleModalConfirmSendMessage();
    })
  }

  let runSendWhatsappMessageBa = () => {
    setLoadingResponseWhatsappMessage(true);
    
    let _startConversation = {...props.startConversationBa};

    Object.keys(_startConversation.conversation.language.parameters).map((value) => {
      if(_startConversation.conversation.language.parameters[value].length > 0) {
        _startConversation.conversation.language.parameters[value].map((_value) => {
            delete _value["isPredefined"];
            delete _value["number"];
            delete _value["type"];
            return null;
        })
      }

      return null;
    })

    if(_startConversation.conversation.template.category === "AUTHENTICATION") {
      delete _startConversation.conversation.language.parameters.buttons;
    }

    let dataChat = {
      channelID: _startConversation.conversation.channel.value,
      phone: _startConversation.conversation.recipient.contact.number.replace("+", ""),
      templateID: _startConversation.conversation.template.value,
      languageCode: _startConversation.conversation.language.languageCode,
      parameters: _startConversation.conversation.language.parameters
    }

    StartConversationServices.postSendMessageWhatsappBa(props.parentProps.match.params.orgID, dataChat, (response) => {
      let dataResult = response.dataResult;
      let dataResultData = dataResult.data;

      if(dataResult.status === 200) {
          if(dataResultData.success) {
            props.setActiveCasePanel("open");
            props.setToggleMineCase(true);
            props.clearStartConversationBa();
            setStartConversationBelongTo(false);
            Helper.resetChatRoomHeightAndInputText();
            doToast("Successfully send the message");
          }else {
            doToast(dataResultData.message, "fail");
          }
      }else {
          doToast(dataResult.error.message, "fail");
      }

      setLoadingResponseWhatsappMessage(false);
      toggleModalConfirmSendMessage();
    })
  }

  let takeOverCaseAction = (firstResponse = false, assignOrTakeOver) => {
    setLoadingResponseWhatsappMessage(true);

    let data = {
      id: props.startConversationBa.conversation ? props.startConversationBa.openCase.id : props.startConversation.openCase.id ,
      topicID: props.startConversationBa.conversation ? props.startConversationBa.openCase.topicID : props.startConversation.openCase.topicID
    }

    CaseService.postTakeCaseAsAgent(props.parentProps.match.params.orgID, data, (response) => {
      if(assignOrTakeOver === "assign") {
        props.setActiveCasePanel("open");

        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Assign Me",
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : props.parentProps.match.params.orgID.split("-")[0]
            }
          );
        }
      }else {
        props.setActiveCasePanel("open");
        
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Take Over Case",
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : props.parentProps.match.params.orgID.split("-")[0]
            }
          )
        }
      }

      if(response.dataResult.error.code === "") {
        if(firstResponse) {
          runFirstResponseTime();
        }

        if(props.startConversation.conversation) {
          runSendWhatsappMessage();
        }

        if(props.startConversationBa.conversation) {
          if(props.startConversationBa.is24Hour) runSendWhatsappMessageBa24Hour();
          else runSendWhatsappMessageBa();
        }

        props.runSetCaseDetailValProps(response.dataResult.data.case);
        props.setCaseDetailActionProps(response.dataResult.data.case)
      }else {
        doToast(response.dataResult.error.message);
        console.log(response.dataResult.error.message)
        setLoadingResponseWhatsappMessage(false);
      }
    })
  }

  let runFirstResponseTime = () => {
    let _caseDetailVal = {...props.startConversation.openCase};

    _caseDetailVal.firstResponseTime = new Date().valueOf();
    props.runSetCaseDetailValProps(_caseDetailVal);
    props.setCaseDetailActionProps(_caseDetailVal);
    //set caseDetailVal firstTimeResponse with date now locally

    CaseService.postSetFirstResponse(props.parentProps.match.params.orgID, {id: _caseDetailVal.id}, (response) => {
      if(response.dataResult.error.code === "") {
        props.runSetCaseDetailValProps(response.dataResult.data.case);
        props.setCaseDetailActionProps(response.dataResult.data.case);
      }else {
        // doToast(response.dataResult.error.message, 'fail');
        console.log(response.dataResult.error.message)
      }
    })
  }

  useEffect(() => {
    let _startConversation = {...props.startConversation};
    let myAgentAccountID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
    
    if(!props.startConversationBa.conversation) {
      if(_startConversation.conversation) {
        // let textAreaTarget = document.querySelectorAll(".main-textarea-input")[0];
        
        // if(_startConversation.conversation.fileAsset.fileURL !== "") {
        //   textAreaTarget.value = _startConversation.conversation.fileAsset.caption;
        //   textAreaTarget.dispatchEvent(new Event('input', { bubbles: true }));
        // }else {
        //   textAreaTarget.value = _startConversation.conversation.message;
        //   textAreaTarget.dispatchEvent(new Event('input', { bubbles: true }));
        // }

        if(_startConversation.openCase.agentAccountID === 0) {
          setStartConversationBelongTo("unassigned");
        }
        
        if(_startConversation.openCase.agentAccountID === myAgentAccountID) {
          setStartConversationBelongTo("mine");
        }
        
        if(_startConversation.openCase.agentAccountID !== myAgentAccountID && 
          _startConversation.openCase.agentAccountID !== "" && 
          _startConversation.openCase.agentAccountID !== 0
        ) {
          setStartConversationBelongTo("other");
        }
      }else {
        setStartConversationBelongTo(false);
      }
    }
  }, [props.startConversation])

  useEffect(() => {
    let _startConversation = {...props.startConversationBa};
    let myAgentAccountID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
    
    if(!props.startConversation.conversation) {
      if(_startConversation.conversation) {
        // let textAreaTarget = document.querySelectorAll(".main-textarea-input")[0];
        
        // if(_startConversation.conversation.fileAsset.fileURL !== "") {
        //   textAreaTarget.value = _startConversation.conversation.fileAsset.caption;
        //   textAreaTarget.dispatchEvent(new Event('input', { bubbles: true }));
        // }else {
        //   textAreaTarget.value = _startConversation.conversation.message;
        //   textAreaTarget.dispatchEvent(new Event('input', { bubbles: true }));
        // }
  
        if(_startConversation.openCase.agentAccountID === 0) {
          setStartConversationBelongTo("unassigned");
        }
        
        if(_startConversation.openCase.agentAccountID === myAgentAccountID) {
          setStartConversationBelongTo("mine");
        }
        
        if(_startConversation.openCase.agentAccountID !== myAgentAccountID && 
          _startConversation.openCase.agentAccountID !== "" && 
          _startConversation.openCase.agentAccountID !== 0
        ) {
          setStartConversationBelongTo("other");
        }
      }else {
        setStartConversationBelongTo(false);
      }
    }
  }, [props.startConversationBa])

  useEffect(() => {
    if(startConversationBelongTo) {
      let textAreaTarget = document.querySelectorAll(".main-textarea-input")[0];
      
      if(props.startConversation.conversation) {
        let _startConversation = {...props.startConversation};
        if(_startConversation.conversation.fileAsset.fileURL !== "") {
          textAreaTarget.value = _startConversation.conversation.fileAsset.caption;
          textAreaTarget.dispatchEvent(new Event('input', { bubbles: true }));
        }else {
          textAreaTarget.value = _startConversation.conversation.message;
          textAreaTarget.dispatchEvent(new Event('input', { bubbles: true }));
        }
      }

      if(props.startConversationBa.conversation) {
        let _startConversation = {...props.startConversationBa};
        if(_startConversation.conversation.fileAsset.fileURL !== "") {
          textAreaTarget.value = _startConversation.conversation.fileAsset.caption;
          textAreaTarget.dispatchEvent(new Event('input', { bubbles: true }));
        }else {
          textAreaTarget.value = _startConversation.conversation.language.bodyTextNamed;
          textAreaTarget.dispatchEvent(new Event('input', { bubbles: true }));
        }
      }
    }
  }, [startConversationBelongTo])

  let onClickClearStartConversation = () => {
    if(!loadingResponseWhatsappMessage) {
      setStartConversationBelongTo(false);
      props.clearStartConversation();
      props.clearStartConversationBa();
      Helper.resetChatRoomHeightAndInputText();
      props.onClickCancelConversation();
    }
  }

  let clickStartConversationMediaPreview = (type) => {
    let _previewImageOrVideo = {...props.previewImageOrVideo};

    _previewImageOrVideo.fileType = type;
    _previewImageOrVideo.fileURL = props.startConversation.conversation.fileAsset.fileURL;
    props.setPreviewImageOrVideo(_previewImageOrVideo);
  }

  let onSelectEmoji = (e) => {
    let target = document.querySelectorAll('.main-textarea-input')[0];
    target.value = target.value + e.native;
    setIsActiveButtonSend(true);
  }

  let [isShowModalConfirmSendMessage, setIsShowModalConfirmSendMessage] = useState(false);

  let toggleModalConfirmSendMessage = () => {
    setIsShowModalConfirmSendMessage(!isShowModalConfirmSendMessage);
  }

  let runOnChange = async (e) => {
    if(props.startConversationBa.conversation) {
      if(!(e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40)) {
        e.preventDefault();
      }
    }else {
      onChangeInputMessage(e);
    }
  }

  let runOnKeyUp = async (e) => {
    if(props.startConversationBa.conversation) {
      if(!(e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40)) {
        e.preventDefault();
      }
    }else {
      //remove shortcut val
      if(e.target.value === "") {
        setCurrentActiveSelectedShortcut(false);
      }
      //remove shortcut val

      //start conversation
      if(props.startConversation.conversation && e.target.value === "") {
        props.clearStartConversation();
        props.onClickCancelConversation();
        setStartConversationBelongTo(false);
      }

      if(props.startConversationBa.conversation && e.target.value === "") {
        props.clearStartConversationBa();
        props.onClickCancelConversation();
        setStartConversationBelongTo(false);
      }
      //start conversation

      if(e.keyCode !== 40 && e.keyCode !== 38) {
        checkShortcutString(e)
      }

      keyPressInputMessage(e)
      
      if (isTyping) {
          if (typingTimeoutID) {
              clearTimeout(typingTimeoutID);
          }

          setTypingTimeoutID(setTimeout(() => {
              stopTyping();
          }, 7000));
      }
    }
  }

  let runOnKeyDown = async (e) => {
    if(props.startConversationBa.conversation) {
      if(!(e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40)) {
        e.preventDefault();
      }
    }else {
      if((e.keyCode === 38 || e.keyCode === 40) && shorcutListToBeShow.length > 0) {
        e.preventDefault();
      }

      if(e.which === 13) {
          if(!e.shiftKey) {
              e.preventDefault();
          }
      }

      startTyping()
    }
  }

  return (
    <div className={`chat-room-input-message-wrapper
        ${startConversationBelongTo === "mine" ? "just-send" : ""}
        ${startConversationBelongTo === "unassigned" ? "assign-me-send" : ""}
        ${startConversationBelongTo === "other" ? "take-over-send" : ""}
        ${props.startConversation.conversation && 
          (props.startConversation.conversation.fileAsset.fileURL !== "" ?
            "start-conversation-with-image-or-video"
            :
            ""
          )
        }
        ${props.startConversationBa.conversation && 
          (props.startConversationBa.conversation.language.headerFormat !== "text" ?
            "start-conversation-with-image-or-video"
            :
            ""
          )
        }
      `}
    >
        {props.quickReplyDocument &&
          <div className='quick-reply-document-wrapper'>
            <FiX className='clear-quick-reply-document' onClick={() => props.setQuickReplyDocument(false)} />

            <div className='quick-reply-document-icon'>
              <FiFile />
            </div>

            <p>
              <b>{props.quickReplyDocument.mediaFilename}</b>
            </p>
          </div>
        }
        {/* <div className="taplive-main-chat-room-send-message-hamburger taplive-mark-as-solved-case-panel-toggle"
             onClick={() => props.toggleMarkAsSolvedPanelProps()}
        >
            <img src={iconHamburgerWhite} alt="" />
        </div> */}
        <ModalSendTemplatedMessage 
          action={() => {
            startConversationBelongTo === "mine" ? 
              (props.startConversationBa.is24Hour ? 
                runSendWhatsappMessageBa24Hour()
                : 
                runSendWhatsappMessageBa())
              : 
              startConversationBelongTo === "other" ?
                takeOverCaseAction(false, "take over")
                :
                takeOverCaseAction(true, "assign")
          }}
          isLoading={loadingResponseWhatsappMessage} 
          isShow={isShowModalConfirmSendMessage} 
          toggle={toggleModalConfirmSendMessage}
        />

        {shorcutListToBeShow.length > 0 && printQuickReply()}
        
        {props.replyMessage.message && <ChatRoomReplyMessage />}

        <form 
          onSubmit={(e) => actionSubmitMessage(e)} 
          className={`${((props.startConversation.conversation && startConversationBelongTo) || (props.startConversationBa.conversation && startConversationBelongTo) ) ? "start-conversation-active" : ""}`}
        >
            <div className="chat-room-textarea-wrapper">
              <textarea 
                rows={1}
                readOnly={loadingResponseWhatsappMessage}
                className={`main-textarea-input ${props.startConversationBa.conversation ? "caret-white" : ""}`}
                placeholder="Send Message..." 
                onBlur={() => stopTyping()}
                ref={chatRoomMesageInputRef}
                onChange={(e) => {
                  runOnChange(e);
                }}
                onKeyUp={(e) => {
                  runOnKeyUp(e)
                }}
                onKeyDown={(e) => {
                  runOnKeyDown(e)
                }}
                disabled={props.startConversation.isForward}
              />
                    
              {/* salestalk */}
              {(props.startConversation.conversation === false && props.startConversationBa.conversation === false) &&
                <>
                  <img 
                    src={IconSalestalkGrey} 
                    alt="" 
                    className={`
                      icon-salestalk 
                      icon-salestalk-grey
                      ${!props.caseDetailData ? "icon-salestalk-46" : ""}
                      ${props.caseDetailData && (props.caseDetailData.case.medium === TAPLIVE_MEDIUM.gb_profile || props.caseDetailData.case.medium === TAPLIVE_MEDIUM.tokopedia) ? "icon-salestalk-46" : ""}
                    `}
                  />
                  <img 
                    src={IconSalestalkOrange} 
                    alt="" 
                    className={`
                      icon-salestalk 
                      icon-salestalk-orange
                      ${!props.caseDetailData ? "icon-salestalk-46" : ""}
                      ${props.caseDetailData && (props.caseDetailData.case.medium === TAPLIVE_MEDIUM.gb_profile || props.caseDetailData.case.medium === TAPLIVE_MEDIUM.tokopedia) ? "icon-salestalk-46" : ""}
                    `}
                    onClick={() => {
                      let _salesTalk = {...props.salesTalkInbox};
                      _salesTalk.isShow = true;
                      props.setSalesTalkInbox(_salesTalk);
                    }}
                  />
                </>
              }
              {/* salestalk */}

              {(
                !props.startConversationBa.conversation &&
                !props.startConversation.conversation &&
                !props.startConversation.isForward
              ) &&
                <div 
                  className={`
                    emoji-picker-outer-wrapper 
                    ${!props.caseDetailData ? "emoji-picker-outer-wrapper-16" : ""}
                    ${props.caseDetailData && (props.caseDetailData.case.medium === TAPLIVE_MEDIUM.gb_profile || props.caseDetailData.case.medium === TAPLIVE_MEDIUM.tokopedia) ? "emoji-picker-outer-wrapper-16" : ""}
                  `}
                >
                  <FiSmile
                    className="emoji-toggle-button"
                  />

                  <div className="emoji-picker-main-wrapper">
                    <Picker 
                      onSelect={(e) => onSelectEmoji(e)} 
                      showPreview={false}
                    />
                  </div>
                </div>
              }

              {(
                (!props.startConversation.conversation && !props.startConversationBa.conversation) &&
                (props.caseDetailData && (
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.launcher ||
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.facebook ||
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.google_business ||
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.instagram ||
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.line ||
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.telegram ||
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.twitter ||
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba ||
                  props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsapp
                ))
              ) &&
                <FiPaperclip 
                  onClick={() => setInputFileShow(!inputFileShow)}
                  className={inputFileShow ? 'active-paperclip' : ''} 
                />
              }
              
              {inputFileView()}
            </div>
            
            {(isActiveButtonSend || props.quickReplyDocument) ? 
              <button className="chat-send-wrapper">
                  <img src={Airplane} alt="" />
              </button>
              :
              <button className="chat-send-wrapper" disabled>
                  <img src={Airplane} alt="" />
              </button>
            }
        </form>
          
        {((props.startConversation.conversation && startConversationBelongTo) || (props.startConversationBa.conversation && startConversationBelongTo)) &&
          <>
            <FiX className="cancel-start-conversation" onClick={onClickClearStartConversation} />

            {props.startConversation.conversation &&
              ((
                props.startConversation.conversation.type === MESSAGE_TYPE.image ||
                props.startConversation.conversation.type === MESSAGE_TYPE.video ||
                props.startConversation.conversation.type === MESSAGE_TYPE.file
              ) &&
                <div className="start-conversation-image-video-priview-small-box">
                  {props.startConversation.conversation.type === MESSAGE_TYPE.image &&
                    <img 
                      src={props.startConversation.conversation.fileAsset.fileURL} 
                      alt="" 
                      onClick={() => clickStartConversationMediaPreview("image")}
                    />
                  }

                  {props.startConversation.conversation.type === MESSAGE_TYPE.video &&
                    <video
                      src={props.startConversation.conversation.fileAsset.fileURL}
                      onClick={() => clickStartConversationMediaPreview("video")}
                    />
                  }

                  {props.startConversation.conversation.type === MESSAGE_TYPE.file &&
                    <FiFile className='icon-file-mini' />
                  }
                </div>
              )
            }

            {props.startConversationBa.conversation &&
              ((
                props.startConversationBa.conversation.language.headerFormat === MESSAGE_TYPE.image ||
                props.startConversationBa.conversation.language.headerFormat === MESSAGE_TYPE.video ||
                props.startConversationBa.conversation.language.headerFormat === MESSAGE_TYPE.document
              ) &&
                <div className="start-conversation-image-video-priview-small-box">
                  {props.startConversationBa.conversation.language.headerFormat === MESSAGE_TYPE.image &&
                    <img 
                     src={
                        !props.startConversationBa.conversation.isDynamicMedia ?
                          props.startConversationBa.conversation.language.headerTextNamed
                          :
                          props.startConversationBa.conversation.language.file.fileSrc
                      } 
                      alt="" 
                      onClick={() => clickStartConversationMediaPreview("image")}
                    />
                  }

                  {props.startConversationBa.conversation.language.headerFormat === MESSAGE_TYPE.video &&
                    <video
                      src={
                        !props.startConversationBa.conversation.isDynamicMedia ?
                          props.startConversationBa.conversation.language.headerTextNamed
                          :
                          props.startConversationBa.conversation.language.file.fileSrc
                      } 
                      onClick={() => clickStartConversationMediaPreview("video")}
                    />
                  }

                  {props.startConversationBa.conversation.language.headerFormat === MESSAGE_TYPE.document &&
                    <FiFile className='icon-file-mini' />
                  }
                </div>
              )
            }
          </>
        }

        {startConversationBelongTo === "other" &&
          (props.startConversation.conversation ?
            !loadingResponseWhatsappMessage ?
              <button 
                className="orange-button button-send-message take-over-send" 
                onClick={() => takeOverCaseAction(false, "take-over")}
              >
                <b>Take Over and Send</b>
                <img src={Airplane} alt="" />
              </button>
              :
              <button className="orange-button button-send-message assign-me-send">
                <b>Take Over and Send</b>
                <div className="lds-ring">
                  <div /><div /><div /><div />
                </div>
              </button>
            :
            <button 
              className="orange-button button-send-message take-over-send" 
              onClick={toggleModalConfirmSendMessage}
            >
              <b>Take Over and Send</b>
              <img src={Airplane} alt="" />
            </button>
          )
        }
        
        {startConversationBelongTo === "unassigned" &&
          (props.startConversation.conversation ?
            !loadingResponseWhatsappMessage ?
              <button 
                className="orange-button button-send-message assign-me-send" 
                onClick={() => takeOverCaseAction(true, "assign")}
              >
                <b>Assign Me and Send</b>
                <img src={Airplane} alt="" />
              </button>
              :
              <button className="orange-button button-send-message assign-me-send">
                <b>Assign Me and Send</b>
                <div className="lds-ring">
                  <div /><div /><div /><div />
                </div>
              </button>
            :
            <button 
              className="orange-button button-send-message assign-me-send" 
              onClick={toggleModalConfirmSendMessage}
            >
              <b>Assign Me and Send</b>
              <img src={Airplane} alt="" />
            </button>
          )
        }

        {startConversationBelongTo === "mine" &&
          (props.startConversation.conversation ?
            !loadingResponseWhatsappMessage ?
              <button 
               className="orange-button button-send-message just-send" 
               onClick={() => runSendWhatsappMessage()}
              >
               <b>Send</b>
               <img src={Airplane} alt="" />
              </button>
              :
              <button className="orange-button button-send-message just-send">
                <b>Send</b>
                <div className="lds-ring">
                  <div /><div /><div /><div />
                </div>
              </button>
            :
            <button 
              className="orange-button button-send-message just-send" 
              onClick={toggleModalConfirmSendMessage}
            >
              <b>Send</b>
              <img src={Airplane} alt="" />
            </button>
          )
        }

        <div className={`taplive-main-chat-room-solve-wrapper ${!props.markAsSolvedPanelShowProps ? 'taplive-main-chat-room-solve-wrapper-hide':''}`} 
             id="taplive-chat-room-mark-as-solved-button"
             onClick={() => actionCaseClose()}
        >   
            {!isLoadingCloseCase ?
              <React.Fragment>
                <img src={iconCheckBlack} alt="" />
                <b>Mark as resolved</b>
              </React.Fragment>
              :
              <div className="lds-ring">
                <div /><div /><div /><div />
              </div>
            }
        </div>

        {generateModalMedia()}
    </div>
  );
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    quickReplyList: state.quickReplyList,
    startConversation: state.startConversation,
    toggleMineCase: state.toggleMineCase,
    replyMessage: state.replyMessage,
    startConversationBa: state.startConversationBa,
    caseDetailData: state.caseDetailData,
    draftMessage: state.draftMessage,
    salesTalkInbox: state.salesTalkInbox,
    quickReplyDocument: state.quickReplyDocument
});

const mapDispatchToProps = {
  clearStartConversation,
  clearStartConversationBa,
  setPreviewImageOrVideo,
  setToggleMineCase,
  setActiveCasePanel,
  clearReplyMessage,
  setSalesTalkInbox,
  setQuickReplyDocument
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomInputMessage);
