import React, { useState, useEffect, useRef } from 'react';
import './ChatRoomCaseDetailTab.scss';
import { connect } from 'react-redux';
import IconHashtag from '../../../../../assets/img/chatroom/user-info-tab/hastag.svg';
import IconList from '../../../../../assets/img/chatroom/user-info-tab/list.svg';
import IconNotif from '../../../../../assets/img/chatroom/user-info-tab/notif.svg';
// import IconCheck from '../../../../../assets/img/chatroom/user-info-tab/check.svg';
import IconPlus from '../../../../../assets/img/chatroom/user-info-tab/plus.svg';
import IconChatList from '../../../../../assets/img/chatroom/user-info-tab/chat-list.svg';
// import IconIOS from '../../../../../assets/img/chatroom/user-info-tab/ios.svg';
// import iconTag from '../../../../../assets/img/chatroom/user-info-tab/tag.svg';
import IconTimer from '../../../../../assets/img/chatroom/user-info-tab/timer.svg';
import IconNote from '../../../../../assets/img/chatroom/user-info-tab/note.svg';
// import IconCommendChecked from '../../../../../assets/img/chatroom/user-info-tab/comment-checked.svg';
import IconCommendCheckedMark from '../../../../../assets/img/chatroom/user-info-tab/check-mark.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import HelperCustomEncryptor from "../../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
import HelperDate from "../../../../../helper/HelperDate";
import Helper from "../../../../../helper/HelperChat";
import PopupRemove from "../../../../reuseableComponent/popupRemove/PopupRemove";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
// import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import PopupConfirmation from "../../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import { doToast, fetchMyAgentData, autoFocusField } from "../../../../../helper/HelperGeneral";
import { TAPLIVE_MEDIUM_LOGO } from "../../../../../constants/taplive";
import CaseService from "../../../../../services/chat/caseServices";
import { FiEdit, FiSearch, FiTag, FiPlus, FiClock } from 'react-icons/fi';
import { IoMdCloseCircle } from 'react-icons/io';
import { Modal, ModalBody } from "reactstrap";
import mixpanel from "mixpanel-browser";
import CaseLabelService from '../../../../../services/newServices/CaseLabelService';
import { setCaseLabelList } from '../../../../../redux/actions/reduxActionCaseLabelList';
import { setUpdateCaseLabel } from "../../../../../redux/actions/reduxActionCaseLabel";
import { setActiveCaseActionHistory } from "../../../../../redux/actions/reduxActionActiveCaseActionHistory";
import { setCaseDetailData } from "../../../../../redux/actions/reduxActionCaseDetailData";
import Select from "react-select";

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};

var ChatRoomCaseDetailTab = (props) => {
  let [intervalFirstResponseTimeRun, setIntervalFirstResponseTimeRun] = useState(false);
  let [intervalCaseDurationRun, setIntervalCaseDurationRun] = useState(false);
  let [caseDurationClock, setCaseDurationClock] = useState(0);
  let [caseDurationClockText, setCaseDurationClockText] = useState(0);
  let [firstResponseTimeClock, setFirstResponseTimeClock] = useState(0);
  let [firstResponseTimeClockText, setFirstResponseTimeClockText] = useState(0);
  let [caseDetailData, setCaseDetailData] = useState(null);
  let [showModalEditRemarkForCase, setShowModalEditRemarkForCase] = useState(false);
  let [valEditRemarkForCase, setValEditRemarkForCase] = useState("");
  let [isWaitReponseEditRemarkForCase, setIsWaitReponseEditRemarkForCase] = useState(false);
  let [isShowEditCaseRemarkButton, setIsShowEditCaseRemarkButton] = useState(false);
  let [showModalRemoveLabel, setShowModalRemoveLabel] = useState(false);
  let [showModalSetLabel, setShowModalSetLabel] = useState(false);
  let [isLoadingSetTopic, setIsLoadingSetTopic] = useState(false);
  let [showEditTopic, setShowEditTopic] = useState(false);
  let [topicEditVal, setTopicEditVal] = useState({
    label: "",
    value: ""
  });
  // let [lastUpdateCaseDetailTab, setLastUpdateCaseDetailTab] = useState(new Date().valueOf())
  let [showConfirmationEditTopic, setShowConfirmationEditTopic] = useState(false);

  // useEffect(() => {
  //   if(showModalSetLabel) {
  //     setTimeout(() => {
  //       autoFocusField("input-search-case-label");
  //       setFocusInput(true);
  //     }, 500)
  //   }
  // }, [showModalSetLabel])

  useEffect(() => {
    if(showModalEditRemarkForCase) {
      setTimeout(() => {
        autoFocusField("edit-remark-for-case-input-1");
      }, 500)
    }
  }, [showModalEditRemarkForCase])

  useEffect(() => {
    if(showEditTopic) {
      setTopicEditVal({
        value: props.caseDetailData.case.topicID,
        label: props.caseDetailData.case.topicName
      })
    }
  }, [showEditTopic])

  useEffect(() => {
    if (caseDetailData !== null) {
      let _userAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).account;

      setIsShowEditCaseRemarkButton(_userAgentData.id === caseDetailData.agentAccountID);
    }
  }, [caseDetailData])

  useEffect(() => {
    if (props.caseDetailData !== null) {
      let caseDetail = props.caseDetailData.case;

      let dateNow = new Date().valueOf();
      let timeFirstResponse = () => {
        setFirstResponseTimeClock(caseDetail.firstResponseTime === 0 ? dateNow - caseDetail.counterStartTime : caseDetail.firstResponseTime - caseDetail.counterStartTime);
        setIntervalFirstResponseTimeRun(caseDetail.firstResponseTime === 0 ? true : false)
      }

      let timeCaseDuration = () => {
        setCaseDurationClock(caseDetail.closedTime === 0 ? dateNow - caseDetail.counterStartTime : caseDetail.closedTime - caseDetail.counterStartTime);
        setIntervalCaseDurationRun(!caseDetail.isClosed)
      }

      setCaseDetailData(caseDetail);
      timeFirstResponse();
      timeCaseDuration();
    }
  }, [props.caseDetailData])

  useEffect(() => {
    if (caseDetailData !== null && intervalFirstResponseTimeRun) {
      let intervalFirstResponseTime = setInterval(function () {
        setFirstResponseTimeClock(firstResponseTimeClock => firstResponseTimeClock + 1000)
      }, 1000)

      return () => clearInterval(intervalFirstResponseTime);
    }
  }, [intervalFirstResponseTimeRun])

  useEffect(() => {
    msToDhms(firstResponseTimeClock).then(r => {
      setFirstResponseTimeClockText(r);
    }); 
  }, [firstResponseTimeClock])

  useEffect(() => {
    msToDhms(caseDurationClock).then(r => {
      setCaseDurationClockText(r);
    }); 
  }, [caseDurationClock])

  useEffect(() => {
    if (caseDetailData !== null && intervalCaseDurationRun) {
      let intervalCaseDuration = setInterval(function () {
        setCaseDurationClock(caseDurationClock => caseDurationClock + 1000)
      }, 1000)

      return () => clearInterval(intervalCaseDuration);
    }
  }, [intervalCaseDurationRun])

  const [labelToRemoved, setLabelToRemoved] = useState(null);

  const iconUpOrange = "url('/image/chevron-up-grey.svg') center / contain no-repeat !important";
  const iconDropGrey = "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

  let styleSelect = () => ({
    indicatorsContainer: provide => ({
        ...provide,
        height: "48px",
    }),
    valueContainer: provide => ({
        ...provide,
        height: "100%"
    }),
    clearIndicator: () => ({
        // display: "none"
    }),
    dropdownIndicator: (provided, { isFocused }) => ({
        ...provided,
        background: isFocused ? iconUpOrange : iconDropGrey,
        transition: "0.25s ease-in-out",
        color: "transparent !important",
        width: "24px",
        height: "24px",
        // transform: isFocused ? "rotate(-180deg)" : ""
    }),
    container: (provided) => ({
        ...provided,
    }),
    control: (provide, { isFocused }) => ({
        ...provide,
        padding: "0 5px",
        // height: "48px",
        borderRadius: "8px",
        border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
        boxShadow: "none !important",
        marginBottom: "24px",
        cursor: "pointer",
        height: "48px"
    }),
    indicatorSeparator: provide => ({ ...provide, display: "none" }),
        menu: provide => ({
        ...provide,
        borderRadius: "8px",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
        overflow: "hidden"
    }),
    option: (provide, state) => {
        const { isSelected } = state;
        const checked = {
            color: "#ff7e00",
            content: '"✔"',
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translate(100%, -50%)"
        };
        return {
            ...provide,
            position: "relative",
            background: "#fff",
            padding: "12px 15px 12px 35px",
            cursor: "pointer",
            color: isSelected ? "#ff7e00" : "#121212",
            ":hover": { background: "#ffe7d0" },
            ":before": isSelected ? checked : null
        };
    }
  })

  let openModalRemoveLabel = (label) => {
    setLabelToRemoved(label)
    setShowModalRemoveLabel(true)
  }

  let msToDhms = async (ms) => {
    ms = parseInt(ms / 1000, 10);
    var days = Math.floor(ms / (3600 * 24));
    ms -= days * 3600 * 24;
    var hrs = Math.floor(ms / 3600);
    ms -= hrs * 3600;
    var mnts = Math.floor(ms / 60);
    ms -= mnts * 60;
    return `${days > 0 ? days + "d" : ""} ${hrs > 0 ? hrs + "h" : ""} ${mnts > 0 ? mnts + "m" : ""} ${ms + "s"}`;
  }

  let generateIconOmnichannel = (medium) => {
    return TAPLIVE_MEDIUM_LOGO[medium] ? TAPLIVE_MEDIUM_LOGO[medium] : "";
  }

  let toggleModalEditRemarkForCase = () => {
    setShowModalEditRemarkForCase(!showModalEditRemarkForCase);
  }

  let renderModalRemarkForCase = () => {
    let onChangeInputRemarkForCase = (e) => {
      setValEditRemarkForCase(e.target.value);
    }

    let submitRemarksForCases = () => {
      setIsWaitReponseEditRemarkForCase(true);
      let data = {
        id: caseDetailData.id,
        agentRemark: valEditRemarkForCase
      }

      CaseService.postUpdateCaseRemark(props.parentProps.match.params.orgID, data, (response) => {
        setIsWaitReponseEditRemarkForCase(false);
        let _dataResult = response.dataResult;

        if (response.dataResult.error.message === "") {
          doToast(response.dataResult.data.message);

          if (_dataResult.data.success) {
            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
              mixpanel.track(
                "[Action] Set Remark for Case",
                {
                  userFullName: myAgentData.account.fullName,
                  userID: myAgentData.account.id,
                  organizationID: props.parentProps.match.params.orgID.split("-")[0]
                }
              );
            }

            toggleModalEditRemarkForCase();
            props.actionRunGetRoomDataProps();
          }
        } else {
          doToast(response.dataResult.error.message, "fail");
        }
      })
    }

    return (
      <Modal isOpen={showModalEditRemarkForCase} toggle={toggleModalEditRemarkForCase} className={"modal-edit-alias"}>
        <ModalBody>
          <p className="title">
            <b>Edit Remarks for Case</b>
          </p>

          <textarea type="text"
            value={valEditRemarkForCase}
            onChange={(e) => onChangeInputRemarkForCase(e)}
            placeholder="Write down anything that may help you or others understand the customer better"
            rows="2"
            className='edit-remark-for-case-input-1'
            disabled={isWaitReponseEditRemarkForCase}
          />

          <ButtonWithLoadingOrIcon
            onClickAction={toggleModalEditRemarkForCase}
            className="no-fill-button main-button-48"
            text="Cancel"
          />

          {isWaitReponseEditRemarkForCase ?
            <ButtonWithLoadingOrIcon
              isLoading
              loadingColor="gray"
              isDisabled
              className="orange-button main-button-48"
              text="Save Changes"
              position="left"
            />
            :
            <ButtonWithLoadingOrIcon
              onClickAction={submitRemarksForCases}
              className="orange-button main-button-48"
              text="Save Changes"
            />
          }
        </ModalBody>
      </Modal>
    )
  }

  let toggleModalEditTopic = () => {
    if(!isLoadingSetTopic) {
      setShowEditTopic(!showEditTopic);
    }
  }

  let toggleConfirmationEditTopic = () => {
    if(!isLoadingSetTopic) {
      setShowConfirmationEditTopic(!showConfirmationEditTopic);
    }
  };

  let renderModalEditTopic = () => {
    // let isDisableEditTopic = () => {
    //   let dis = false;

    //   if(topicEditVal.value === props.caseDetailData.case.topicID) {
    //     dis = true;
    //   }

    //   return dis;
    // };

    return (
      <>
        <Modal isOpen={showEditTopic} toggle={toggleModalEditTopic} className={"modal-edit-alias modal-edit-topic"}>
          <ModalBody>
            <p className="title">
              <b>Case Topic</b>
            </p>

            <label>
              <b>Topic</b>
            </label>
            
            <Select 
              options={props.myTopics} 
              placeholder="Select Topic" 
              onChange={(e) => onClickChangeTopic(e)}
              styles={styleSelect()}
              value={props.allTopicList.filter(
                (obj) => obj.value === topicEditVal.value
              )}
              classNamePrefix="react-select-edit-topic"
              blurInputOnSelect={true}
            />

            <ButtonWithLoadingOrIcon
              onClickAction={toggleModalEditTopic}
              className="no-fill-button main-button-48"
              text="Cancel"
            />

            {isLoadingSetTopic ?
              <ButtonWithLoadingOrIcon
                isLoading
                loadingColor="gray"
                isDisabled
                className="orange-button main-button-48"
                text="Save Changes"
                position="left"
              />
              :
              <ButtonWithLoadingOrIcon
                onClickAction={toggleConfirmationEditTopic}
                className="orange-button main-button-48"
                text="Save Changes"
                // isDisabled={isDisableEditTopic()}
              />
            }
          </ModalBody>
        </Modal>

        <PopupConfirmation
          isOpen={showConfirmationEditTopic}
          toggle={toggleConfirmationEditTopic}
          title="Save Changes?"
          description="You changed this contact's topic case. Are you sure you want to continue?"
          className="modal-confirmation-edit-topic"
        >
          {!isLoadingSetTopic ?
              <ButtonWithLoadingOrIcon
                  text="Save Changes"
                  className="main-button-48 orange-button"
                  onClickAction={runEditTopic}
              />
              :
              <ButtonWithLoadingOrIcon
                  text="Save Changes"
                  className="main-button-48 orange-button"
                  isLoading
                  isDisabled
                  loadingColor="gray"
                  position="left"
              />
          }
      </PopupConfirmation>
      </>
    )
  }

  // const [caseLabelList, setCaseLabelList] = useState([])
  const [caseLabelToSelect, setCaseLabelToSelect] = useState([])
  const [submitQuerySearchLabel, setSubmitQuerySearchLabel] = useState("")
  const [isLoadingGetLabelList, setIsLoadingGetLabelList] = useState(false)
  // const [isWaitResponseAddLabels, setIsWaitResponseAddLabels] = useState(false)
  const [isWaitResponseRemoveLabels, setIsWaitResponseRemoveLabels] = useState(false)
  const [isAllowAgent, setIsAllowAgent] = useState(false)

  const getCaseLabelList = (isCreated = false) => {
    CaseLabelService.getCaseLabelList(props.parentProps.match.params.orgID, submitQuerySearchLabel, (response) => {
      if (response.dataResult.status === 200) {
        setIsAllowAgent(response.dataResult.data.configs.allowCreateInInbox)
        const caseLabel = response.dataResult.data.caseLabels
        props.setCaseLabelList(caseLabel);

        if (isCreated) {
          handleAddLabels(response.dataResult.data.caseLabels)
        }

      } else {
        doToast(response.dataResult.error.message, 'fail');
      }

    })
  }

  const [labelListSelect, setLabelListSelect] = useState([])

  const getCaseLabelSelect = () => {
    setIsLoadingGetLabelList(true);

    CaseLabelService.getCaseLabelList(props.parentProps.match.params.orgID, submitQuerySearchLabel, (response) => {
      setIsLoadingGetLabelList(false);

      if (response.dataResult.status === 200) {
        setLabelListSelect(response.dataResult.data.caseLabels)
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }

    })
  }

  useEffect(() => {
    if (showModalSetLabel) {
      getCaseLabelSelect()
    }
  }, [submitQuerySearchLabel])

  useEffect(() => {
    // getCaseLabelList()
  }, [])

  // useEffect(() => {
  //   const data = HelperGetLocalStorage.getLocalStorageData('caseLabelList')
  //   if (data) {
  //     (data)
  //   }
  // }, [props.caseLabelList])

  useEffect(() => {
    if (caseDetailData && labelListSelect) {
      const _caseLabels = [...labelListSelect]

      if (caseDetailData.labelIDs) {
        if (caseDetailData.labelIDs.length > 0) {
          if (labelListSelect.length > 0) {

            // data labe list dikurang dengan data added label
            const _labels = [...caseDetailData.labelIDs]
            const labelToSelect = _caseLabels.filter((el) => {
              return !_labels.find(val => val === el.id)
            });

            // data list terbaru, dikurang dengan selected label
            const selected = [...selectedCaseLabel]
            const labels = labelToSelect.filter((el) => {
              return !selected.find(val => val.id === el.id)
            })

            setCaseLabelToSelect(labels)
          } else {
            setCaseLabelToSelect([])
          }
        } else {
          if (labelListSelect.length > 0) {
            // data label dikurang dengan selected
            const selected = [...selectedCaseLabel]
            const labels = _caseLabels.filter((el) => {
              return !selected.find(val => val.id === el.id)
            })
            setCaseLabelToSelect(labels)
          } else {
            setCaseLabelToSelect([])
          }
        }
      }
    }
  }, [caseDetailData, labelListSelect])

  const [caseLabels, setCaseLabels] = useState(null)

  useEffect(() => {
    if (caseDetailData && caseDetailData.labelIDs) {
      if (caseDetailData.labelIDs.length > 0) {
        if (props.caseLabelList.length > 0) {
          const _labels = [...caseDetailData.labelIDs]
          const _caseLabels = [...props.caseLabelList]

          const dataLabel = _caseLabels.filter((el) => {
            return _labels.find(val => val === el.id)
          });
          setCaseLabels(dataLabel)
        } else {
          setCaseLabels([])
        }
      } else {
        setCaseLabels([])
      }
    }
  }, [caseDetailData, props.caseLabelList])

  const handleSearchLabel = (e) => {
    setSubmitQuerySearchLabel(e.target.value)
  }

  const [createdLabels, setCreatedLabels] = useState([])

  const COLORS = [
    '#E02D2D', '#E5057E', '#CC1FCF', '#9B30EF', '#6C2FEC', '#4131F5', '#056DFF', '#008496', '#00873F', '#458500', '#857700', '#A16B00', '#CC4B14'
  ]

  let toggleModalSetLabel = () => {
    setShowModalSetLabel(!showModalSetLabel);

    setSelectedCaseLabel([])
    setCreatedLabels([])

    const _caseLabels = [...labelListSelect]
    if (caseDetailData && caseDetailData.labelIDs && caseDetailData.labelIDs.length > 0) {
      const _labels = [...caseDetailData.labelIDs]

      const labelToSelect = _caseLabels.filter((el) => {
        return !_labels.find(val => val === el.id)
      });
      setCaseLabelToSelect(labelToSelect)
    } else {
      setCaseLabelToSelect(_caseLabels)
    }

    setSubmitQuerySearchLabel("")
  }

  const getRandomColor = () => {
    const idx = Math.floor(Math.random() * COLORS.length);
    return COLORS[idx];
  }

  const handleCreateNewLabel = () => {
    const dataLabel = {
      name: submitQuerySearchLabel,
      backgroundColor: getRandomColor(),
      isCreated: true
    }

    const _createdLabels = [...createdLabels, dataLabel]
    setCreatedLabels(_createdLabels)

    const _selectedLabel = [...selectedCaseLabel, dataLabel]
    setSelectedCaseLabel(_selectedLabel)

    setShowNewLabel(false)
    setSubmitQuerySearchLabel("")
    inputRef.current.focus()
  }


  const onSelectLabel = (label) => {
    const _dataLabel = [...selectedCaseLabel]
    const find = _dataLabel.findIndex(val => val.id === label.id)
    if (find !== -1) {
      return
    } else {
      let _selectedLabel = [...selectedCaseLabel, label]
      setSelectedCaseLabel(_selectedLabel)
    }
    const _caseLabelToSelect = [...caseLabelToSelect]
    const filtered = _caseLabelToSelect.filter(item => item.id !== label.id)
    setCaseLabelToSelect(filtered)
    setSubmitQuerySearchLabel("")
    inputRef.current.focus()
  }

  const removeSelectedLabel = (label) => {
    let _selectedLabel = [...selectedCaseLabel];
    const _dataLabel = [..._selectedLabel]
    _selectedLabel = _dataLabel.filter(item => item.name !== label.name)
    setSelectedCaseLabel(_selectedLabel);

    if (label.isCreated) {
      const _createdLabels = [...createdLabels]
      const newCreatedLabels = _createdLabels.filter(item => item.name !== label.name)
      setCreatedLabels(newCreatedLabels)
      setCaseLabelToSelect([...caseLabelToSelect])
    } else {
      const _caseLabelToSelect = [...caseLabelToSelect, label]
      _caseLabelToSelect.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      setCaseLabelToSelect(_caseLabelToSelect)
    }

    setShowListLabel(false)
  }

  const handleAddLabels = (newCaseLabelList = props.caseLabelList) => {
    // setIsWaitResponseAddLabels(true);
    const _caseLabelList = [...newCaseLabelList]
    const _selected = [...selectedCaseLabel]

    const selected = _caseLabelList.filter((el) => {
      return _selected.find(val => val.name === el.name)
    });

    const labels = selected.map(val => Number(val.id))

    const dataLabel = {
      id: caseDetailData.id,
      labelIDs: labels
    }

    CaseService.addLabels(props.parentProps.match.params.orgID, dataLabel, (response) => {
      let dataResult = response.dataResult;
      // setIsWaitResponseAddLabels(false);
      setIsLoadingCreateLabels(false)

      if (dataResult.status === 200) {
        if (dataResult.data.success) {
          toggleModalSetLabel()
          props.actionRunGetRoomDataProps();
          doToast(dataResult.data.message);
          props.setUpdateCaseLabel({
            state: true,
            action: 'add',
            labelIDs: labels,
            id: caseDetailData.id,
            roomID: props.activeRoom
          });
        } else {
          doToast(dataResult.data.message, "fail");
        }
      } else {
        doToast(dataResult.error.message, "fail");
      }
    });
  }

  const createLabel = (label, idx, length) => {
    const dataLabel = {
      name: label.name,
      description: "",
      backgroundColor: label.backgroundColor
    }

    CaseLabelService.createCaseLabel(props.parentProps.match.params.orgID, dataLabel, (response) => {
      let dataResult = response.dataResult;

      if (dataResult.status === 200) {
        if (dataResult.data.success) {
          if (idx + 1 === length) {
            getCaseLabelList(true)
          }
        } else {
          doToast(dataResult.data.message, "fail");
        }
      } else {
        doToast(dataResult.error.message, "fail");
      }
    });

  }

  const [isLoadingCreateLabels, setIsLoadingCreateLabels] = useState(false)

  const handleSaveLabels = async () => {
    setIsLoadingCreateLabels(true)
    if (createdLabels.length > 0) {
      createdLabels.forEach((label, idx) => {
        createLabel(label, idx, createdLabels.length)
      })
    } else {
      handleAddLabels()
    }
  }

  const handleRemoveLabels = () => {
    setIsWaitResponseRemoveLabels(true);

    const dataLabel = {
      id: caseDetailData.id,
      labelIDs: [Number(labelToRemoved)]
    }

    CaseService.removeLabels(props.parentProps.match.params.orgID, dataLabel, (response) => {
      let dataResult = response.dataResult;
      setIsWaitResponseRemoveLabels(false);

      if (dataResult.status === 200) {
        if (dataResult.data.success) {
          props.actionRunGetRoomDataProps();
          doToast(dataResult.data.message);
          props.setUpdateCaseLabel({
            state: true,
            action: 'remove',
            labelIDs: [Number(labelToRemoved)],
            id: caseDetailData.id,
            roomID: props.activeRoom
          });
        } else {
          doToast(dataResult.data.message, "fail");
        }
      } else {
        doToast(dataResult.error.message, "fail");
      }
    });
  }

  const [selectedCaseLabel, setSelectedCaseLabel] = useState([])
  const [focusInput, setFocusInput] = useState(false)
  const [showListLabel, setShowListLabel] = useState(false)

  const labelRef = useRef()
  const inputRef = useRef()
  const wrapperRef = useRef()

  useEffect(() => {
    const onBodyClick = (event) => {
      if(!isLoadingCreateLabels) {
        if (showListLabel) {
          if (!labelRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
            setShowListLabel(false);
            setFocusInput(false)
          }
        } else {
          if (wrapperRef.current) {
            if (wrapperRef.current.contains(event.target)) {
              setFocusInput(true)
              setShowListLabel(true)
            } else {
              setShowListLabel(false);
              setFocusInput(false)
            }
          }
        }
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, [showListLabel]);

  const openModalSetLabel = () => {
    setShowModalSetLabel(true)
    getCaseLabelSelect()
    getCaseLabelList()
  }

  const [showNewLabel, setShowNewLabel] = useState(false)

  useEffect(() => {
    if (submitQuerySearchLabel !== "") {
      const labelToSelect = [...caseLabelToSelect]
      const labelAdded = [...caseLabels]
      const labelCreated = [...createdLabels]
      const selectLabel = labelToSelect.findIndex(label => label.name === submitQuerySearchLabel)
      const addedLabel = labelAdded.findIndex(label => label.name === submitQuerySearchLabel)
      const createdLabel = labelCreated.findIndex(label => label.name === submitQuerySearchLabel)
      if (selectLabel === -1 && addedLabel === -1 && createdLabel) {
        setShowNewLabel(true)
      } else {
        setShowNewLabel(false)
      }
    } else {
      setShowNewLabel(false)
    }
  }, [submitQuerySearchLabel])

  let renderModalSetLabel = () => {
    return (
      <Modal isOpen={showModalSetLabel} className="modal-add-label" keyboard={false} toggle={toggleModalSetLabel}>
        <ModalBody>
          <div className="add-label-wrapper">
            <p className="title">
              <b>Add Labels</b>
            </p>
            <div className={`label-value-wrapper ${isLoadingCreateLabels ? "grey-box" : ""} ${focusInput ? 'focus' : 'blur'}`} ref={wrapperRef} >
              <FiSearch className="search-add-label-icon" color={focusInput ? '#ff7d00' : '#000000'} />
              {
                selectedCaseLabel.length > 0 &&
                selectedCaseLabel.map(label => {
                  return (
                    <div className="label-value" style={{ background: label.backgroundColor }} key={`selected-label-${label.name}`} >
                      <IoMdCloseCircle onClick={() => removeSelectedLabel(label)} color="#ffffff" />
                      <b>{label.name}</b>
                    </div>
                  )
                })
              }
              <input disabled={isLoadingCreateLabels} type="text" placeholder={selectedCaseLabel.length > 0 ? "" : "Search for labels"} ref={inputRef} onChange={handleSearchLabel} style={{ width: selectedCaseLabel.length > 0 ? '50px' : '160px' }} value={submitQuerySearchLabel} className='input-search-case-label' />
            </div>
            <div className="add-label-suggestion-wrapper" style={{ display: `${showListLabel ? 'block' : 'none'}` }} ref={labelRef}>
              <ul>
                {
                  (showNewLabel && isAllowAgent) &&
                  <li onClick={handleCreateNewLabel}>
                    <div className="label-value new-label">
                      <b><FiPlus />Create New Label {`"${submitQuerySearchLabel}"`}</b>
                    </div>
                  </li>
                }
                {
                  !isLoadingGetLabelList ?
                    <>
                      {
                        caseLabelToSelect &&
                          caseLabelToSelect.length > 0 ?
                          caseLabelToSelect.map(label => {
                            return (
                              <li key={`case-label-${label.id}`} onClick={() => onSelectLabel(label)}>
                                <div className="label-value" style={{ background: label.backgroundColor }}>
                                  <b>{label.name}</b>
                                </div>
                              </li>
                            )
                          })
                          :
                          <li className="no-option">
                            No Options
                          </li>
                      }
                    </>
                    :
                    <div className="loading-wrapper">
                      <div className="lds-ring">
                        <div /><div /><div /><div />
                      </div>
                    </div>
                }
              </ul>
            </div>
            <div className="button-container">
              <ButtonWithLoadingOrIcon
                className="no-fill-button main-button-40"
                text="Cancel"
                onClickAction={toggleModalSetLabel}
              />
              {
                isLoadingCreateLabels ?
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    className="orange-button main-button-40"
                    text="Save"
                    position="left"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    text="Save"
                    onClickAction={handleSaveLabels}
                    // isDisabled={selectedCaseLabel.length < 1}
                  />
              }
            </div>
          </div>

        </ModalBody>
      </Modal>
    )
  }

  let onClickChangeTopic = (e) => {
    setTopicEditVal(e);
  }

  let runEditTopic = () => {
    setIsLoadingSetTopic(true);

    let data = {
      id: props.caseDetailData.case.id,
      topicID: topicEditVal.value,
      createdTime: props.caseDetailData.case.createdTime
    }
    
    CaseService.changeTopic(props.parentProps.match.params.orgID, data, (response) => {
      let dataRes = response.dataResult;

      if(dataRes.status === 200) {
        let _caseDetailData = {...props.caseDetailData};
        _caseDetailData.case.topicID = topicEditVal.value;
        _caseDetailData.case.topicName = topicEditVal.label;
        props.setCaseDetailData(_caseDetailData);
        doToast("Topic  has been successfully changed");
        toggleModalEditTopic();
        toggleConfirmationEditTopic();
      }else {
          doToast(dataRes.error.message, "fail");
      }

      setIsLoadingSetTopic(false);
    })
  }

  return (
    <Scrollbars autoHideTimeout={500}
      renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
    >
      <div className="chat-room-case-detail-tab-wrapper main-user-info-wrapper">
        {renderModalRemarkForCase()}
        {renderModalSetLabel()}
        {renderModalEditTopic()}

        <div className="user-info-list-wrapper">
          <label><b>Case ID</b></label>
          <div className="user-info-box-wrapper">
            <img src={IconHashtag} alt="" />
            {caseDetailData !== null && caseDetailData.stringID}
          </div>
        </div>
        
        <div className="user-info-list-wrapper ">
          <label><b>Topic</b></label>

          {/* {fetchMyAgentData().id === props.caseDetailData.case.agentAccountID ?
            <CustomSelectOption
              isLoading={isLoadingSetTopic}
              optionListProps={props.allTopicList}
              valueDropdownProps={{value: props.caseDetailData.case.topicID, label: props.caseDetailData.case.topicName}}
              placeholderProps={"Select Topic"}
              onClickDropDownListOptionProps={(e) => onClickChangeTopic(e)}
              specificClassNameProps="custom-select-topic-case-detail"
            />
            : */}
            <div 
              className={`
                user-info-box-wrapper 
                ${(fetchMyAgentData().id === props.caseDetailData.case.agentAccountID && props.caseDetailData.case.closedTime === 0) ? "user-info-list-wrapper-white": ""}
                ${fetchMyAgentData().id === props.caseDetailData.case.agentAccountID ? "user-info-box-wrapper-topics" : ""}
              `}
            >
              <img src={IconList} alt="" />
              {props.caseDetailData && (props.caseDetailData.case.topicName === "" ? "-" : props.caseDetailData.case.topicName)}

              {(fetchMyAgentData().id === props.caseDetailData.case.agentAccountID && props.caseDetailData.case.closedTime === 0) &&
                <FiEdit className='button-edit-topic' onClick={toggleModalEditTopic} />
              }
            </div>
        {/* } */}
        </div>

        {/* <div className="user-info-list-wrapper">
            <label><b>Platform</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconIOS} alt="" />
              iOS
            </div>
          </div> */}

        <div className="user-info-list-wrapper">
          <label><b>Channel</b></label>
          <div className="user-info-box-wrapper">
            {caseDetailData !== null &&
              <React.Fragment>
                {generateIconOmnichannel(caseDetailData.medium) === "" ?
                  ""
                  :
                  <img src={generateIconOmnichannel(caseDetailData.medium)} alt="" className="channel-icon-detail" />
                }

                {/* {TAPLIVE_MEDIUM_TEXT[caseDetailData.medium]} */}
                {caseDetailData.mediumChannelName}
              </React.Fragment>
            }
          </div>
        </div>

        <div className="user-info-list-wrapper box-labels">
          <label><b>Labels</b></label>
          <div className="labels-wrapper">
            {
              caseLabels &&
              caseLabels.length > 0 &&
              caseLabels.map(label => {
                return (
                  <div className="label-item" key={`case-label-${label.id}`} style={{ backgroundColor: label.backgroundColor }}>
                    <IoMdCloseCircle onClick={() => openModalRemoveLabel(label.id)} />
                    <b>{label.name}</b>
                  </div>
                )
              })
            }
            <button className="add-label" onClick={openModalSetLabel}>
              <FiTag />
              <b>Add</b>
            </button>
          </div>
        </div>

        <div className="user-info-list-wrapper">
          <label><b>Case Created Time</b></label>
          <div className="user-info-box-wrapper">
            <img src={IconPlus} alt="" />
            {caseDetailData !== null &&
              HelperDate.formatToString(new Date(caseDetailData.createdTime), "dd/MM/yyyy HH:mm")
            }
          </div>
        </div>

        <div className="user-info-list-wrapper">
          <label><b>First Response Time</b></label>
          <div className="user-info-box-wrapper">
            <img src={IconChatList} alt="" />
            {caseDetailData !== null &&
              (caseDetailData.firstResponseTime > 0 ?
                HelperDate.formatToString(new Date(caseDetailData.firstResponseTime), "dd/MM/yyyy HH:mm")
                :
                (caseDetailData.firstResponseTime === 0 && caseDetailData.isClosed ?
                  'No response'
                  :
                  'Waiting for response'
                )
              )
            }
          </div>
        </div>

        <div className="user-info-list-wrapper">
          <label><b>First Response Wait Duration</b></label>
          <div className="user-info-box-wrapper">
            <img src={IconNotif} alt="" />
            {caseDetailData !== null &&
              caseDetailData.firstResponseTime === 0 && caseDetailData.isClosed ?
              "No response"
              :
              firstResponseTimeClockText
            }
          </div>
        </div>

        <div className="user-info-list-wrapper">
          <label><b>Resolved Time</b></label>
          <div className="user-info-box-wrapper">
            <img src={IconCommendCheckedMark} alt="" />
            {caseDetailData !== null &&
              caseDetailData.closedTime > 0 ?
              HelperDate.formatToString(new Date(caseDetailData.closedTime), "dd/MM/yyyy HH:mm")
              :
              "Case ongoing"
            }
          </div>
        </div>

        <div className="user-info-list-wrapper">
          <label><b>Case Duration</b></label>
          <div className="user-info-box-wrapper">
            <img src={IconTimer} alt="" />
            {caseDetailData !== null && caseDurationClockText}
          </div>
        </div>

        <div className="user-info-list-wrapper">
          <label><b>Case Action History</b></label>
          <button className="add-label" onClick={() => props.setActiveCaseActionHistory(true)}>
            <FiClock />
            <b>View Action History</b>
          </button>
        </div>

        <div className="user-info-list-wrapper">
          <label><b>Remarks for Case</b></label>
          {isShowEditCaseRemarkButton &&
            <span className="user-info-list-button-action"
              onClick={() => {
                let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                  mixpanel.track(
                    "[Button] Set Remark for Case",
                    {
                      userFullName: myAgentData.account.fullName,
                      userID: myAgentData.account.id,
                      organizationID: props.parentProps.match.params.orgID.split("-")[0]
                    }
                  );
                }
                
                setValEditRemarkForCase(caseDetailData.agentRemark);
                toggleModalEditRemarkForCase();
              }
              }>
              <FiEdit />
              <b>Edit</b>
            </span>
          }
          <div className="user-info-box-wrapper-multiple-line">
            <img src={IconNote} alt="" />
            {caseDetailData !== null &&
              caseDetailData.agentRemark ?
              caseDetailData.agentRemark !== "" ?
                <span dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(caseDetailData.agentRemark) }} /> 
                :
                <span className="grey-font">Write down anything that may help you or others understand the case context better</span>
              :
              <span className="grey-font">Write down anything that may help you or others understand the case context better</span>
            }
          </div>
        </div>

        <PopupRemove 
          removeTitle={`Remove label?`}
          removeDescription={`This label will be removed from this case.`}
          onClickCancel={() => setShowModalRemoveLabel(false)}
          onClickRemove={handleRemoveLabels}
          waitForResponse={isWaitResponseRemoveLabels}
          submitText={"Remove"}
          showModalProps={showModalRemoveLabel}
          colorButton="red-button"
        />
      </div>
    </Scrollbars>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  caseLabelList: state.caseLabelList,
  caseDetailData: state.caseDetailData,
  allTopicList: state.allTopicList,
  myTopics: state.myTopics
});

const mapDispatchToProps = {
  setCaseLabelList,
  setUpdateCaseLabel,
  setActiveCaseActionHistory,
  setCaseDetailData
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomCaseDetailTab);
