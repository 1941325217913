import React, { useState, useEffect } from "react";
import './ChatRoomEditContactInfo.scss';
import { FiEdit, FiUser, FiAtSign, FiPlus, FiInfo, FiBriefcase } from "react-icons/fi";
import { setEditContactInfo } from "../../../../redux/actions/reduxActionEditContactInfo";
import { setChangeContactInfo } from "../../../../redux/actions/reduxActionChangeUserAlias";
import { connect } from 'react-redux';
import { autoFocusField, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperInput from '../../../../helper/HelperInput';
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import UserContactServices from '../../../../services/newServices/UserContactServices';
import iconUsers from '../../../../assets/img/fi-user-circle.svg';

// import ExclamationRed from "../../../../assets/img/exclamation-red.svg";
// import { doToast } from "../../../../helper/HelperGeneral";

let ChatRoomEditContactInfo = (props) => {
    // let [isLoadingData, setIsLoadingData] = useState(false);
    let [contactInfo, setContactInfo] = useState(false);
    let [contactInfoOrigin, setContactInfoOrigin] = useState(false);
    let [showPopupConfirmation, setShowPopupConfirmation] = useState(false);
    let [showDiscard, setShowDiscard] = useState(false);
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        name: "",
        alias: "",
        companyName: "",
        jobTitle: "",
        email: "",
        phone: ""
    }) 

    useEffect(() => {
        // getContactInfo();
        if(props.caseDetailData) {
            let _case = JSON.parse(JSON.stringify(props.caseDetailData));
            _case.user.phone = HelperInput.phoneBeautify(_case.user.phone);
            setContactInfo(_case.user);
            setContactInfoOrigin(_case.user);
        }

        if(props.editContactInfo.isShow) {
            setTimeout(() => {
                autoFocusField("edit-contact-input-1");
            }, 400)
        }
    }, [props.editContactInfo.isShow])

    useEffect(() => {
        if(props.activeRoom) {
            hidePopupContactInfo();
        }
    }, [props.activeRoom])

    let saveContact = () => {
        setIsLoadingSubmit(true);

        const dataUser = {
            userID: contactInfo.userID,
            fullName: contactInfo.fullName,
            alias: contactInfo.alias,
            email: contactInfo.email,
            phone: contactInfo.phone.replaceAll(" ", ""),
            companyName: contactInfo.companyName,
            jobTitle: contactInfo.jobTitle 
        }
    
        UserContactServices.editContact(props.parentProps.match.params.orgID, dataUser, (response) => {
            if (response.dataResult.status === 200) {
                if (!response.dataResult.data.success) {
                    doToast(response.dataResult.data.message);
                } else {
                    setContactInfoOrigin(contactInfo);

                    props.setChangeContactInfo({
                        state: true,
                        alias: contactInfo.alias,
                        userID: contactInfo.userID,
                        fullname: contactInfo.fullName,
                        email: contactInfo.email,
                        phone: contactInfo.phone,
                        companyName: contactInfo.companyName,
                        jobTitle: contactInfo.jobTitle 
                    });

                    doToast(response.dataResult.data.message);
                }
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }

            togglePopupConfirmation();
            setIsLoadingSubmit(false);
            hidePopupContactInfo();
        });
      }

    let togglePopupConfirmation = () => {
        if(!isLoadingSubmit) {
            setShowPopupConfirmation(!showPopupConfirmation);
        }
    }
    
    let toggleDiscard = () => {
        setShowDiscard(!showDiscard);
    }

    let onChangeInput = (e) => {
        let _ctc = {...contactInfo};
        let _errorMessageData = {...errorMessageData};

        if(e.target.id === "fullName") {
            _errorMessageData.name = "";
        }

        if(e.target.id === "phone") {
            if(HelperInput.numberOnly(e.target.value.replaceAll(" ", ""))) {
                _ctc[e.target.id] = HelperInput.phoneBeautify(e.target.value.replaceAll(" ", ""));
            }
        }else {
            _ctc[e.target.id] = e.target.value;
            _errorMessageData[e.target.id] = "";
        }

        setErrorMessageData(_errorMessageData);
        setContactInfo(_ctc);
    }

    // let isDisabledSubmit = () => {
        // let isDis = false;

    //     if(contactInfo.fullName === "") {
    //         isDis = true;
    //     }

    //     if(contactInfo.email !== "") {
    //         if(!HelperInput.emailFormatValid(contactInfo.email)) {
    //             isDis = true;
    //         }
    //     }

    //     return isDis;
    // }

    let hidePopupContactInfo = () => {
        let _editContactInfo = {...props.editContactInfo};
        _editContactInfo.isShow = false;
        props.setEditContactInfo(_editContactInfo);
    }

    return (
        <>
            <div 
                className={`
                    chat-room-contact-info-
                    ${props.editContactInfo.isShow ? "chat-room-contact-info-active" : ""}
                `}
            >   
                <div className={`chat-room-contact-info-title`}>
                    <FiEdit />
                    <b>Edit Contacts</b> 
                </div>

                {showPopupConfirmation &&
                    <PopupConfirmation
                        isOpen={showPopupConfirmation}
                        toggle={togglePopupConfirmation}
                        title="Save Changes?"
                        description="There has been changes in this contact’s detail. Are you sure you want to continue?"
                        className="modal-confirmation-edit-contact"
                        >
                        {!isLoadingSubmit ?
                            <ButtonWithLoadingOrIcon
                                text="Save Changes"
                                className="main-button-48 orange-button"
                                onClickAction={saveContact}
                            />
                            :
                            <ButtonWithLoadingOrIcon
                                text="Save Changes"
                                className="main-button-48 orange-button"
                                isLoading
                                isDisabled
                                loadingColor="gray"
                                position="left"
                            />
                        }
                    </PopupConfirmation>
                }
                
                {showDiscard &&
                    <PopupConfirmation
                        isOpen={showDiscard}
                        toggle={toggleDiscard}
                        title="Discard Changes?"
                        description="There has been unsaved changes in this contact’s detail. Are you sure you want to go back without saving?"
                        className="modal-confirmation-edit-contact"
                    >
                        <ButtonWithLoadingOrIcon
                            text="Discard Changes"
                            className="main-button-48 red-button"
                            onClickAction={() => {
                                toggleDiscard();
                                hidePopupContactInfo();
                            }}
                        />
                    </PopupConfirmation>
                }

                {contactInfo &&
                    <div className="chat-room-contact-info-edit-wrapper">
                        <div className="chat-room-contact-info-edit-inner">
                            <label className='margin-top-0 pos-relative'>
                                <b>Full Name</b>

                                <span className={errorMessageData.name === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{contactInfo.fullName.length}/200</span>
                            </label>

                            <div className="edit-contact-info-input-wrapper">
                                <FiUser />
                                <input 
                                    type="text" 
                                    value={contactInfo.fullName} 
                                    placeholder="Input Full Name"
                                    onChange={(e) => onChangeInput(e)}
                                    id="fullName"
                                    className={`edit-contact-input-1 ${errorMessageData.name !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.name !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.name}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Alias<span>(Optional)</span></b>

                                <span className={errorMessageData.alias === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{contactInfo.alias.length}/100</span>
                            </label>
                            <div className="edit-contact-info-input-wrapper">
                                <FiUser />
                                <input 
                                    type="text" 
                                    value={contactInfo.alias}
                                    placeholder="E.g John" 
                                    id="alias"
                                    onChange={(e) => onChangeInput(e)}
                                    className={`edit-contact-input-2 ${errorMessageData.alias !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.alias !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.alias}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Company<span>(Optional)</span></b>

                                <span className={errorMessageData.companyName === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{contactInfo.companyName.length}/200</span>
                            </label>

                            <div className="edit-contact-info-input-wrapper">
                                <FiBriefcase />
                                <input 
                                    type="text" 
                                    value={contactInfo.companyName}
                                    placeholder="E.g Taptalk" 
                                    id="companyName"
                                    onChange={(e) => onChangeInput(e)}
                                    className={`edit-contact-input-3 ${errorMessageData.companyName !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.companyName !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.companyName}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Job Title<span>(Optional)</span></b>

                                <span className={errorMessageData.jobTitle === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{contactInfo.jobTitle.length}/50</span>
                            </label>

                            <div className="edit-contact-info-input-wrapper">
                                <img alt="" src={iconUsers} />
                                <input 
                                    type="text" 
                                    value={contactInfo.jobTitle}
                                    placeholder="E.g Account Executive" 
                                    id="jobTitle"
                                    onChange={(e) => onChangeInput(e)}
                                    className={`edit-contact-input-4 ${errorMessageData.jobTitle !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.jobTitle !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.jobTitle}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Email</b>
                                <span className={errorMessageData.email === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{contactInfo.email.length}/250</span>
                            </label>

                            <div className="edit-contact-info-input-wrapper">
                                <FiAtSign />
                                <input 
                                    type="text" 
                                    value={contactInfo.email}
                                    placeholder="Input Email Address" 
                                    id="email"
                                    onChange={(e) => onChangeInput(e)}
                                    className={`edit-contact-input-5 ${errorMessageData.email !== "" ? "border-red" : ""}`}
                                />
                            </div>
                            {errorMessageData.email !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.email}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label className='pos-relative'>
                                <b>Phone Number</b>

                                {/* <span className={errorMessageData.phone === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{contactInfo.phone.replaceAll(" ", "").length}/15</span> */}
                            </label>

                            <div className="edit-contact-info-input-wrapper edit-contact-phone">
                                <FiPlus />
                                <input 
                                    type="text" 
                                    value={contactInfo.phone}
                                    placeholder="Input Phone Number" 
                                    id="phone"
                                    onChange={(e) => onChangeInput(e)}
                                    disabled={contactInfoOrigin.phone === "" ? false : true}
                                    maxLength={20}
                                    className={`edit-contact-input-6 ${errorMessageData.phone !== "" ? "border-red" : ""}`}
                                />
                            </div>

                            <p className="grey-font-6">
                                <FiInfo />
                                Always start the phone number with the country code without the "+" sign e.g. "681297304400".
                            </p>
                        </div>

                        <div className="edit-contact-button">
                            <ButtonWithLoadingOrIcon 
                                text="Cancel"
                                className="main-button-40 no-fill-button-grey"
                                onClickAction={() => {
                                    if(JSON.stringify(contactInfo) === JSON.stringify(contactInfoOrigin)) {
                                        hidePopupContactInfo();
                                    }else {
                                        toggleDiscard();
                                    }
                                }}
                            />

                            <ButtonWithLoadingOrIcon 
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                onClickAction={() => {
                                    let errorCount = 0;
                                    let errorClass = "";
                                    let _errorMessageData = {...errorMessageData};

                                    if(contactInfo.fullName === "") {
                                        _errorMessageData.name = "This field is required";
                                        errorCount = errorCount + 1;
                                        
                                        if(errorClass === "") { errorClass = "edit-contact-input-1" }
                                    }

                                    if(contactInfo.fullName.length > 200) {
                                        _errorMessageData.name = "Characters exceed limit";
                                        errorCount = errorCount + 1;
                                        
                                        if(errorClass === "") { errorClass = "edit-contact-input-1" }
                                    }

                                    if(contactInfo.alias.length > 100) {
                                        _errorMessageData.alias = "Characters exceed limit";
                                        errorCount = errorCount + 1;
                                            
                                        if(errorClass === "") { errorClass = "edit-contact-input-2" }
                                    }
                                  
                                      if(contactInfo.companyName.length > 200) {
                                        _errorMessageData.companyName = "Characters exceed limit";
                                        errorCount = errorCount + 1;
                                          
                                        if(errorClass === "") { errorClass = "edit-contact-input-3" }
                                      }
                                  
                                      if(contactInfo.jobTitle.length > 50) {
                                        _errorMessageData.jobTitle = "Characters exceed limit";
                                        errorCount = errorCount + 1;
                                          
                                        if(errorClass === "") { errorClass = "edit-contact-input-4" }
                                      }
                                  
                                      if(contactInfo.email.length > 250) {
                                        _errorMessageData.email = "Characters exceed limit";
                                        errorCount = errorCount + 1;
                                          
                                        if(errorClass === "") { errorClass = "edit-contact-input-5" }
                                      }
                                  
                                      if(contactInfo.phone.replaceAll(" ", "").length > 15) {
                                        _errorMessageData.phone = "Characters exceed limit";
                                        errorCount = errorCount + 1;
                                          
                                        if(errorClass === "") { errorClass = "edit-contact-input-6" }
                                      }
                                  

                                    if(errorCount > 0) {
                                        setErrorMessageData(_errorMessageData);
                                        scrollToClass(`.${errorClass}`);
                                    }else {
                                        togglePopupConfirmation();
                                    }
                                }}
                                // isDisabled={isDisabledSubmit()}
                            />
                        </div>
                    </div>    
                }
            </div>
        </>
    )
}

let mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    editContactInfo: state.editContactInfo,
    caseDetailData: state.caseDetailData
})

let mapDispatchToProps = {
    setEditContactInfo,
    setChangeContactInfo
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomEditContactInfo);