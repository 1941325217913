import React, { useState, useEffect } from "react";
import "./OrganizationDetailsEdit.scss";
import { connect } from "react-redux";
import { FiInfo } from "react-icons/fi";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import CountryPicker from "../../../reuseableComponent/countryPicker/CountryPicker";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";

let OrganizationDetailsEdit = (props) => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [countryList, setCountryList] = useState(false);
    let [loadingEdit, setLoadingEdit] = useState(false);
    let [loadingOrganizationDetail, setLoadingOrganizationDetail] = useState(true);
    let [selectedCountry, setSelectedCountry] = useState({
        id: 1,
        label: "Indonesia"
    });
    let [organizationDetailData, setOrganizationDetailData] = useState(false);
    // let [organizationDetailDataOrigin, setOrganizationDetailDataOrigin] = useState(false);1
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });
    let [errorMessageData, setErrorMessageData] = useState({
        name: "",
        regionName: "",
        cityName: "",
        fullAddress: "",
        postalCode: ""
    })
  
    let goBack = () => {
        history.push(`/o/${orgID}/organization/details`);
    }

    const doMount = async () => {
        const paramID = checkID(match);
        if (paramID) {
          setOrgID(paramID);
        } else {
          ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
              let responseData = response.dataResult.data;
              if (responseData.memberships.length < 1) window.location.href = "/organization/new";
              const { id, createdTime } = responseData.memberships[0].organization;
              const urlOrgID = `${id}-${createdTime}`;
              history.push(`/o/${urlOrgID}/organization/label`);
            } else {
              console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
          });
        }
    };

    let getOrganizationDetail = () => {
        setLoadingOrganizationDetail(true);
        let _retriveDataError = {...retriveDataError};

        ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {
           let dataResult = response.dataResult;
      
            if(dataResult.error.message === "") {
                dataResult.data.organization.countryID = dataResult.data.organization.countryID === 0 ? 1 : dataResult.data.organization.countryID;
                setSelectedCountry({
                    id: dataResult.data.organization.countryID,
                    label: findCountryIndex(dataResult.data.organization.countryID).commonName                    
                })
                setOrganizationDetailData(dataResult.data.organization);
                // setOrganizationDetailDataOrigin(dataResult.data.organization);
               
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setLoadingOrganizationDetail(false);
          });
    }

    let editOrganizationDetail = () => {
        let _data = {...organizationDetailData};
        let _errorMessageData = {...errorMessageData};
        let errorCount = 0;
        let errorClass = "";

        if(_data.name === "") {
            _errorMessageData.name = "This field is required";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-organization" }
        }

        if(_data.name.length > 200) {
            _errorMessageData.name = "Characters exceed limit";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-organization" }
        }

        if(_data.regionName === "") {
            _errorMessageData.regionName = "This field is required";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-region" }
        }

        if(_data.regionName.length > 250) {
            _errorMessageData.regionName = "Characters exceed limit";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-region" }
        }

        if(_data.cityName === "") {
            _errorMessageData.cityName = "This field is required";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-city" }
        }

        if(_data.cityName.length > 250) {
            _errorMessageData.cityName = "Characters exceed limit";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-city" }
        }

        if(_data.fullAddress === "") {
            _errorMessageData.fullAddress = "This field is required";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-address" }
        }

        if(_data.fullAddress.length > 250) {
            _errorMessageData.fullAddress = "Characters exceed limit";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-address" }
        }

        if(_data.postalCode === "") {
            _errorMessageData.postalCode = "This field is required";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-postal" }
        }

        if(_data.postalCode.length > 50) {
            _errorMessageData.postalCode = "Characters exceed limit";
            errorCount = errorCount + 1;
            if(errorClass === "") { errorClass = "input-postal" }
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            setLoadingEdit(true);
            // let _retriveDataError = {...retriveDataError};

            let data = {
                name: _data.name,
                countryID: _data.countryID,
                regionName: _data.regionName,
                cityName: _data.cityName,
                fullAddress: _data.fullAddress,
                postalCode: _data.postalCode
            };

            ServiceOrganization.postEditOrganization(orgID, data, (response) => {
                let dataResult = response.dataResult;
                if(dataResult.error.message === "") {
                    if(dataResult.data.success) {
                        // setOrganizationDetailDataOrigin(organizationDetailData);
                        doToast(dataResult.data.message);

                        setTimeout(() => {
                            window.location.href = `/o/${orgID}/organization/details`
                        }, 1000)
                    }else {
                        doToast(dataResult.data.message, "fail");
                    }
                }else {
                    doToast(dataResult.error.message, "fail");
                    setLoadingEdit(false);
                }

            });
        }
    }

    let onChange = (e) => {
        let val = e.target.value;
        let id = e.target.id;
        let _data = {...organizationDetailData};
        let _errorMessageData = {...errorMessageData};

        _errorMessageData[id] = "";
        _data[id] = val;
        setErrorMessageData(_errorMessageData);
        setOrganizationDetailData(_data);
    }

    // let isDisabled = (e) => {
    //     let isDisabled = false;
    //     let _data = {...organizationDetailData};
    //     let _dataOrigin = {...organizationDetailDataOrigin};

    //     if(JSON.stringify(_data) === JSON.stringify(_dataOrigin)) {
    //         isDisabled = true;
    //     }

    //     if(_data.name === "") {
    //         isDisabled = true;
    //     }

    //     return isDisabled;
    // }

    useEffect(() => {
        doMount();
    }, []);
    
    useEffect(() => {
        if (orgID) {
            getOrganizationDetail();
        }
    }, [orgID]);

    useEffect(() => {
        if(props.countryList) {
            let newCountryList = [];
            props.countryList.map((value) => {
                let data = {
                    value: "",
                    label: "",
                    countryLabel: "",
                    countryFlag: "",
                    countryCodeNumber: ""
                }

                data.value = value.id;
                data.label = value.officialName;
                data.countryLabel = value.officialName;
                data.countryFlag = value.flagIconURL;
                data.countryCodeNumber = value.callingCode

                newCountryList.push(data);

                return null;
            })

            setCountryList(newCountryList)
        }
    }, [props.countryList])

    let onChangeSelectCountry = (title, val) => {
        let _data = {...organizationDetailData};
        let _selectedCountry = {...selectedCountry};
        _data.countryID = val.value;
        _selectedCountry = {
            id: val.value,
            label: val.label
        }
        setSelectedCountry(_selectedCountry);
        setOrganizationDetailData(_data);
    }

    let findCountryIndex = (id) => {
        return props.countryList[props.countryList.findIndex((val) => val.id === id)];
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
        >   
            <BackButton text="Back to Organization Details" onClick={goBack} />

            <div className="sectionWrap organization-details-container">
                <div className="topContent organization-details-top-content">
                    <div className="title title-organization-details">Organization Details</div>
                    {!loadingEdit ?
                        <ButtonWithLoadingOrIcon
                            className="orange-button main-button-40"
                            text="Save Changes"
                            onClickAction={editOrganizationDetail}
                            position="left"
                            // isDisabled={isDisabled()}
                        />
                        :
                        <ButtonWithLoadingOrIcon
                            className="no-fill-button main-button-40"
                            text="Save Changes"
                            position="left"
                            isLoading
                            isDisabled
                            loadingColor={"gray"}
                        />
                    }
                </div>

                {loadingOrganizationDetail || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={loadingOrganizationDetail}
                        errorMessage={retriveDataError.message} 
                    />
                    :
                    <div className="organization-details-bottom-content form-content-wrapper">
                        <p className="organization-details-title">
                            <b>Organization Name</b>

                            <span className={`input-counter ${errorMessageData.name === "Characters exceed limit" ? "red-text" : ""}`}>
                                {organizationDetailData.name.length}/200
                            </span>
                        </p>

                        <input 
                            type="text" 
                            placeholder="Input organization name" 
                            value={organizationDetailData.name} 
                            onChange={(e) => onChange(e)}
                            id="name"
                            disabled={loadingEdit}
                            className={`input-organization ${errorMessageData.name !== "" ? "border-red" : ""}`}
                        />

                        {errorMessageData.name !== "" &&
                            <ErrorOrInfoComp
                                text={errorMessageData.name}
                                _className={"font-red"}
                                icon={<FiInfo />}
                            />
                        }

                        <p className="organization-details-title">
                            <b>Time Zone</b>
                        </p>

                        <p className="organization-details-value">
                            <b>Asia/Jakarta (UTC +07:00)</b>
                        </p>

                        <p className="organization-details-title">
                            <b>Country</b>
                        </p>

                        <CountryPicker
                            list={countryList}
                            placeholder={"Select Country"}
                            onChange={(title, val) => onChangeSelectCountry(title, val)}
                            selectedID={selectedCountry.id}
                            selectedCountryLabel={selectedCountry.label}
                            isDisabled
                        />

                        <p className="organization-details-title">
                            <b>Region Name </b> <span className="grey-font">(optional)</span>

                            <span className={`input-counter ${errorMessageData.regionName === "Characters exceed limit" ? "red-text" : ""}`}>
                                {organizationDetailData.regionName.length}/250
                            </span>
                        </p>

                        <input 
                            type="text" 
                            placeholder="Input region name" 
                            value={organizationDetailData.regionName} 
                            id="regionName"
                            onChange={(e) => onChange(e)}
                            disabled={loadingEdit}
                            className={`input-region ${errorMessageData.regionName !== "" ? "border-red" : ""}`}
                        />

                        {errorMessageData.regionName !== "" &&
                            <ErrorOrInfoComp
                                text={errorMessageData.regionName}
                                _className={"font-red"}
                                icon={<FiInfo />}
                            />
                        }

                        <p className="organization-details-title">
                            <b>City Name</b> <span className="grey-font">(optional)</span>

                            <span className={`input-counter ${errorMessageData.cityName === "Characters exceed limit" ? "red-text" : ""}`}>
                                {organizationDetailData.cityName.length}/250
                            </span>
                        </p>

                        <input 
                            type="text" 
                            placeholder="Input city name" 
                            value={organizationDetailData.cityName}
                            id="cityName"
                            onChange={(e) => onChange(e)}
                            disabled={loadingEdit}
                            className={`input-city ${errorMessageData.cityName !== "" ? "border-red" : ""}`}
                        />

                        {errorMessageData.cityName !== "" &&
                            <ErrorOrInfoComp
                                text={errorMessageData.cityName}
                                _className={"font-red"}
                                icon={<FiInfo />}
                            />
                        }

                        <p className="organization-details-title">
                            <b>Full Address</b> <span className="grey-font">(optional)</span>

                            <span className={`input-counter ${errorMessageData.fullAddress === "Characters exceed limit" ? "red-text" : ""}`}>
                                {organizationDetailData.fullAddress.length}/250
                            </span>
                        </p>

                        <textarea 
                            placeholder="Input address" 
                            rows="4" 
                            value={organizationDetailData.fullAddress}
                            id="fullAddress"
                            onChange={(e) => onChange(e)}
                            disabled={loadingEdit}
                            className={`input-address ${errorMessageData.fullAddress !== "" ? "border-red" : ""}`}
                        />

                        {errorMessageData.fullAddress !== "" &&
                            <ErrorOrInfoComp
                                text={errorMessageData.fullAddress}
                                _className={"font-red"}
                                icon={<FiInfo />}
                            />
                        }

                        <p className="organization-details-title">
                            <b>Postal Code</b> <span className="grey-font">(optional)</span>

                            <span className={`input-counter ${errorMessageData.postalCode === "Characters exceed limit" ? "red-text" : ""}`}>
                                {organizationDetailData.postalCode.length}/50
                            </span>
                        </p>

                        <input 
                            type="text" 
                            placeholder="Input postal code" 
                            value={organizationDetailData.postalCode}
                            id="postalCode"
                            onChange={(e) => onChange(e)}
                            className={`input-postal ${errorMessageData.postalCode !== "" ? "border-red" : ""}`}
                            disabled={loadingEdit}
                        />

                        {errorMessageData.postalCode !== "" &&
                            <ErrorOrInfoComp
                                text={errorMessageData.postalCode}
                                _className={"font-red"}
                                icon={<FiInfo />}
                            />
                        }
                    </div>
                }
            </div>
        </SectionWrap>
    )
}

const mapDispatchToProps = {
    // setParentMenuBlockActive,
};

const mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationDetailsEdit);