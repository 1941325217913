import React from "react";
import "./ErrorOrInfoComp.scss";

let ErrorOrInfoComp = (props) => {
    return (
        <div className={`error-or-info-comp ${props._className}`}>
            {props.icon}
            <b>{props.text}</b>
        </div>
    )
}

export default ErrorOrInfoComp;