import React, { Component } from 'react';
import '../Auth.css';
import './EmailVerification.css';
import Logo from "../../../assets/img/logo-taptalk-white.svg";
import PaperPlaneLeft from "../../../assets/img/auth/img-paper-plane-left.svg";
import PaperPlaneRight from "../../../assets/img/auth/img-paper-plane-right.svg";
import IconFacebookMessenger from "../../../assets/img/auth/icon-fb-messenger-circle.svg";
import IconGoogle from "../../../assets/img/auth/icon-google-circle.svg";
import IconGoogleBusinessProfile from "../../../assets/img/auth/icon-gb-profile-circle.svg";
import IconInstagram from "../../../assets/img/auth/icon-instagram-circle.svg";
import IconMail from "../../../assets/img/auth/icon-mail-circle.svg";
import IconTelegram from "../../../assets/img/auth/icon-telegram-circle.svg";
import IconTokopedia from "../../../assets/img/auth/icon-tokopedia-circle.svg";
import IconWhatsAppSME from "../../../assets/img/auth/icon-whatsapp-circle.svg";
import IconWhatsAppBA from "../../../assets/img/auth/icon-whatsapp-ba-circle.svg";
import { Link } from "react-router-dom";
import ServiceAuthRegister from '../../../services/auth/ServiceAuthRegister';
import CustomToastr from '../../reuseableComponent/customToastr/CustomToastr';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiArrowLeft, FiCheck } from 'react-icons/fi';

var runningCountdownTimer;

class EmailVerification extends Component {
  constructor(props) {
    super(props);

    this.state = {
        isResendEmailShow: false,
        timerResendEmail: 10,
        toastrText: "",
        toastrClassName: "",
        toastrShow: false,
        emailText: ""
    };
  }

  componentDidMount() {
    try{
        this.setState({
            emailText: this.props.location.state.email
        })
    }
    catch(err) {
        this.props.history.push('');
    }

    var countdownFunction = () => {
        this.setState({
            timerResendEmail: this.state.timerResendEmail - 1
        })

        if(this.state.timerResendEmail === 0) {
            this.setState({
                isResendEmailShow: true
            })

            clearInterval(runningCountdownTimer);
        }
    };

    runningCountdownTimer = window.setInterval(countdownFunction, 1000);

    let targetClickTimer = document.querySelector(".resend-button");

    targetClickTimer.addEventListener("click", () => {
        this.setState({
            isResendEmailShow: false,
            timerResendEmail: 10
        });

        runningCountdownTimer = window.setInterval(countdownFunction, 1000);
    });
  }

  componentWillUnmount() {
    clearInterval(runningCountdownTimer);
  }

  render() {
    return (
        <div id="outer-container">

            <img src={Logo} alt="TapTalk Logo" className="logo-image" />
            <p className="subtitle">Omnichannel Chat Platform</p>

            <img alt="" src={PaperPlaneLeft} className="bg-img-paper-plane-left" />
            <img alt="" src={PaperPlaneRight} className="bg-img-paper-plane-right" />
            <img alt="" src={IconWhatsAppSME} className="bg-img-whatsapp-sme" />
            <img alt="" src={IconTelegram} className="bg-img-telegram" />
            <img alt="" src={IconGoogleBusinessProfile} className="bg-img-gb-profile" />
            <img alt="" src={IconGoogle} className="bg-img-google" />
            <img alt="" src={IconInstagram} className="bg-img-instagram" />
            <img alt="" src={IconFacebookMessenger} className="bg-img-fb-messenger" />
            <img alt="" src={IconMail} className="bg-img-mail" />
            <img alt="" src={IconTokopedia} className="bg-img-tokopedia" />
            <img alt="" src={IconWhatsAppBA} className="bg-img-whatsapp-ba" />

            <div id="verification-wrapper">
                <div className="verification-status">
                    <FiCheck />
                </div>

                <div className="verification-title">
                    <b>Instruction Sent to Email</b>
                </div>

                <div className="verification-description">
                    Please follow the instructions that have been sent to the registered account <b>{`${this.state.emailText}`}</b>
                </div>

                <div className="resend-email-wrapper">
                    <span>Didn’t receive an email?</span>
                    
                    <span className="resend-button"
                        style={{
                            display: this.state.isResendEmailShow ? "inline-block" : "none"
                        }}
                        onClick={() => this.onClickResendEmailVerification(this.state.emailText)}
                    >
                        Resend Verification Email
                    </span>

                    <span className="timer-resend-button"
                        style={{
                            display: this.state.isResendEmailShow ? "none" : "inline-block"
                        }}
                    >
                        Sending email {this.state.timerResendEmail}s
                        <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                    </span>
                </div>
                
                <Link to="/">
                    <ButtonWithLoadingOrIcon
                        className="orange-button main-button-48 width-100"
                        text="Back to Log In"
                        position="left"
                        icon={{
                            type: "svg",
                            src: FiArrowLeft
                        }}
                    />
                </Link>

            </div>
            
            <CustomToastr toastrText={this.state.toastrText} toastrClassName={this.state.toastrClassName} />
        </div>
    );
  }

  onClickResendEmailVerification = (paramEmail) => {
    this.setState({
        toastrShow: true,
        toastrText: "Please wait while your request is being processed",
        toastrClassName: "toast-process active-toast"
    });

    ServiceAuthRegister.postResendEmailVerification({email: paramEmail}).then(response => {
      if(response.dataResult.data.success) {
        this.setState({
            toastrText: "Resend verification email success, please check your email",
            toastrClassName: "toast-success active-toast"
        })

        setTimeout(() => {
            this.setState({
                toastrClassName: "toast-success"
            })
        }, 4000);
      }else {
        this.setState({
            toastrText: response.dataResult.data.message ? response.dataResult.data.message : response.dataResult.error.message ? response.dataResult.error.message : "Failed to resend verification email, please try again in a few moments or contact us at help@taptalk.io",
            toastrClassName: "toast-fail active-toast"
        })

        setTimeout(() => {
            this.setState({
                toastrClassName: "toast-fail"
            })
        }, 4000);
      }
    })
  }
}

export default EmailVerification;
