import React, { useEffect, useState } from "react";
import "./OtherTab.scss";
import { doToast, checkID } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import { OTHER_TAB_OPT } from "../../../../helper/HelperConst";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import InboxConfigServices from "../../../../services/newServices/InboxConfigServices";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

const OtherTab = (props) => {
    let [val, setVal] = useState(false);
    let [valOrigin, setValOrigin] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [isWaitingSet, setIsWaitingSet] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [orgID, setOrgID] = React.useState(false);

    let back = () => {
        props.history.push(`/o/${props.match.params.orgID}/setup/inbox-settings`);
    }

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Inbox Setting",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if(orgID) {
            getMessage();
        }
    }, [orgID])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/inbox-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getMessage = () => {
        let _retriveDataError = {...retriveDataError};
        setIsWaitingFetch(true);

        InboxConfigServices.getConfig(orgID, (response) => {
            let dataResult = response.dataResult;

            if(dataResult.error.message === "") {
                let _data = {...dataResult.data};

                if(_data.members.length > 0) {
                    _data.members.map((v) => {
                        if(v.showTabOthers === "default") {
                            v.dropdownVal = OTHER_TAB_OPT[0];
                        }else if(v.showTabOthers === "always_show") {
                            v.dropdownVal = OTHER_TAB_OPT[1];
                        }else {
                            v.dropdownVal = OTHER_TAB_OPT[2];
                        }
                        
                        return null;
                    })
                }

                setValOrigin(JSON.parse(JSON.stringify(dataResult.data)));
                setVal(JSON.parse(JSON.stringify(dataResult.data)));
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitingFetch(false);
        })
    }

    let setSetting = () => {
        if(retriveDataError.code !== "49901") {
            setIsWaitingSet(true);

            let data = {
                showTabOthers: val.inboxConfigs.showTabOthers,
                members: val.members
            }

            InboxConfigServices.setConfig(orgID, data, (response) => {
                let dataResult = response.dataResult;

                if(dataResult.error.message === "") {
                    setValOrigin(val);
                    setIsWaitingSet(false);

                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                    
                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                            "[Action] Set Inbox Setting",
                            {
                                status : val.inboxConfigs.showTabOthers ? "On" : "Off",
                                userFullName : myAgentData.account.fullName,
                                userID : myAgentData.account.id,
                                useStateorganizationID : props.match.params.orgID.split("-")[0]
                            }
                        );
                    }
                    
                    doToast(dataResult.data.message);

                    setTimeout(() => {
                        back();
                    }, 1000)
                }else {
                    doToast(dataResult.error.message, "fail");
                }
            })
        }
    }

    let toggleConfig = () => {
        let _inboxConfig = {...val};
        _inboxConfig.inboxConfigs.showTabOthers = !_inboxConfig.inboxConfigs.showTabOthers;
        setVal(_inboxConfig);
    }

    let onSelectConfig = (_val, i) => {
        let _inboxConfig = {...val};
        _inboxConfig.members[i].showTabOthers = _val.value;
        _inboxConfig.members[i].dropdownVal = _val;
        setVal(_inboxConfig);
    }

    // let isDisabled = () => {
    //     return JSON.stringify(val) === JSON.stringify(valOrigin);
    // }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={JSON.stringify(valOrigin)}
            dataAfterChange={JSON.stringify(val)}
        >
            <BackButton 
                onClick={back}
                text="Back To Inbox Settings"
            />

            <div className="inbox-settings sectionWrap">
                {/* tier blocking */}
                {/* {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                        isLoading={isWaitingFetch}
                        errorMessage={retriveDataError.message}
                        errorCode={retriveDataError.code}
                    />
                } */}
                {/* tier blocking */}

                <React.Fragment>
                    <div className="othertab-settings-header">
                        <b>Show Tab "Others"</b>

                        {isWaitingSet ?
                            <ButtonWithLoadingOrIcon 
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                isDisabled
                                isLoading
                                loadingColor="gray"
                                position="left"
                            />
                            :
                            <ButtonWithLoadingOrIcon 
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                onClickAction={setSetting}
                                // isDisabled={isDisabled()}
                            />
                        }
                    </div>
                    
                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
                        <RetrieveDataLoading 
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message} 
                        />
                        :
                        <>
                            <div className="othertab-settings-form">
                                <div className="othertab-settings-form-content form-content-wrapper">
                                    <b>Show Tab “Others” by Default</b>
                                    <p>
                                        Show list of cases being handled by other agents.
                                    </p>
                                    
                                    <div className="closing-welcome-switch-wrapper">
                                        <label className="custom-switch-wrapper">
                                            <input disabled={isWaitingSet} type="checkbox" checked={val.inboxConfigs.showTabOthers} onChange={() => toggleConfig()} />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            
                            <table className="table-other-tab-config">
                                <thead>
                                    <tr>
                                        <td><b>Full Name</b></td>
                                        <td><b>Email</b></td>
                                        <td><b>Show Tab "Others"</b></td>
                                    </tr>
                                </thead>

                                <tbody>
                                    {val.members.map((v, i) => {
                                        return (
                                            <tr key={`members-${i}`}>
                                                <td>{v.accountName}</td>
                                                <td>{v.accountEmail}</td>
                                                <td className="option-td">
                                                    <CustomSelectOption 
                                                        optionListProps={OTHER_TAB_OPT}
                                                        valueDropdownProps={v.dropdownVal}
                                                        placeholderProps={'Select Config'}
                                                        onClickDropDownListOptionProps={(e) => onSelectConfig(e, i)}
                                                        specificClassNameProps="custom-select-other-tab-config"
                                                        isDisabled={isWaitingSet}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </>

                    }
                </React.Fragment>
            </div>
        </SectionWrap>
    )
}

export default OtherTab;