import React from 'react';
import { FiSearch, FiX } from "react-icons/fi";
import './SearchBox.scss';
// import SearchGrey from '../../../assets/img/icon-search-grey.svg';
// import SearchOrange from '../../../assets/img/icon-search-orange.svg';
// import CloseCircle from '../../../assets/img/icon-xcircle-grey.svg';

var SearchBox = (props) => {
  return (
    <div className="search-box-wrapper" style={props.style}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (!props.isWaitResponse) {
            props.onSubmitSearch();
          }
        }}
      >
        <input type="text"
          placeholder={props.placeholder}
          onChange={(e) => props.onChangeInputSearch(e.target.value)}
          className={`search-box-input ${props.clearInside ? "search-box-input-inside" : ""}`}
          autoComplete="off"
          value={props.searchValue}
        />
        {/* <img src={SearchGrey} className="search-icon search-grey-icon" alt="" />
          <img src={SearchOrange} className="search-icon search-orange-icon" alt="" /> */}
        {/* <img src={CloseCircle} className="delete-search" alt="" /> */}

        <button className="search-icon" disabled={props.isWaitResponse}>
          <FiSearch className="" />
        </button>
      </form>

      {props.searchValue !== "" &&
        (props.clearInside ?
          <p 
            className='clear-search-inside'
            onClick={() => {
              props.onChangeInputSearch("");
              props.onClearSearch && props.onClearSearch()
            }}
          >
            <b><FiX /></b>
          </p>
          :
          <p 
            className="clear-search" 
            onClick={() => {
              props.onChangeInputSearch("");
              props.onClearSearch && props.onClearSearch()
            }}
          >
            <b>
              <FiX />

              {!props.noClearText &&
                "Clear Search"
              }
            </b>
          </p>
        )
      }
    </div>
  );
}

export default SearchBox;
