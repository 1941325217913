import React, { useEffect, useState } from "react";
import "./IntegrationChatbotAdd.scss";
import { FiInfo } from "react-icons/fi";
import "react-toastify/dist/ReactToastify.css";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import { doToast, checkID, scrollToClass } from "../../../../helper/HelperGeneral";
import { CHATBOT_TYPE } from "../../../../constants/chatbot";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import FaqServices from "../../../../services/newServices/FaqServices";
import ChatbotServices from "../../../../services/newServices/ChatbotService";
import Select from "react-select";

const iconDropOrange =
  "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
const iconDropGrey =
  "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

const IntegrationChatbotAdd = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [pathList, setPathList] = useState([]);
    let [chatbotNameVal, setChatbotNameVal] = useState("");
    let [chatbotURLVal, setChatbotURLVal] = useState("");
    // let [chatbotWebhookURLVal, setChatbotWebhookURLVal] = useState("");
    // let [chatbotSecretKeyVal, setChatbotSecretKeyVal] = useState("");
    let [chatbotPathVal, setChatbotPathVal] = useState({
        label: "",
        value: ""
    });
    let [chatbotTypeVal, setChatbotTypeVal] = useState({
        label: "",
        value: ""
    });
    let [chatbotNameValOrigin, setChatbotNameValOrigin] = useState("");
    let [chatbotURLValOrigin, setChatbotURLValOrigin] = useState("");
    let [chatbotPathValOrigin, setChatbotPathValOrigin] = useState({
        label: "",
        value: ""
    });
    let [chatbotTypeValOrigin, setChatbotTypeValOrigin] = useState(CHATBOT_TYPE[0]);
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [errorMessageData, setErrorMessageData] = useState({
        name: "",
        type: "",
        path: "",
        url: ""
    }); 

    useEffect(() => {
        if (props.history.location.state) {
            if(props.history.location.state.whatsappVal) {
                setChatbotNameVal(props.history.location.state.whatsappVal.name)
                setChatbotPathVal(props.history.location.state.whatsappVal.path)
            }

            setChatbotURLValOrigin("");
        }
    }, [props.history])

    const back = () => history.push(`/o/${orgID}/integration/chatbots`);

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getPaths = async (orgID) => {
        FaqServices.getList(orgID, (response) => {
            const data = response.dataResult.data.paths;
            
            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.value = val.id;
                    _data.label = val.title;
                    newData.push(_data);
                    return null;
                });
                setPathList(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        if(orgID) {
            getPaths(orgID);
        }
    }, [orgID])

    let onSelectPath = (value) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.path = "";
        setErrorMessageData(_errorMessageData);
        setChatbotPathVal(value);
    }

    let onSelectType = (value) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.type = "";
        _errorMessageData.path = "";
        _errorMessageData.url = "";
        setErrorMessageData(_errorMessageData);
        setChatbotPathVal({
            value: "",
            label: ""
        })
        setChatbotTypeVal(value)
    }

    // let isFormValid = () => {
    //     let formValid = true;
    //     let _chatbotNameVal = chatbotNameVal;
    //     let _chatbotURLVal = chatbotURLVal;
    //     // let _chatbotWebhookURLVal = chatbotWebhookURLVal;
    //     // let _chatbotSecretKeyVal = chatbotSecretKeyVal
    //     let _chatbotTypeVal = chatbotTypeVal.value;
    //     let _chatbotPathVal = chatbotPathVal.value;

    //     if(_chatbotNameVal === "") {
    //         formValid = false;
    //     }

    //     if(_chatbotTypeVal === CHATBOT_TYPE[1].value) {
    //         if(_chatbotURLVal === "" || !HelperInput.validURL(_chatbotURLVal)) {
    //             formValid = false;
    //         }

    //         // if(_chatbotWebhookURLVal === "" || !HelperInput.validURL(_chatbotWebhookURLVal)) {
    //         //     formValid = false;
    //         // }

            
    //         // if(_chatbotSecretKeyVal === "") {
    //         //     formValid = false;
    //         // }
    //     }

    //     if(_chatbotTypeVal === CHATBOT_TYPE[0].value) {
    //         if(_chatbotPathVal === "") {
    //             formValid = false;
    //         }
    //     }

    //     return formValid;
    // }

    let onChangeChatbotName= (e) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.name = "";
        setErrorMessageData(_errorMessageData);
        setChatbotNameVal(e.target.value);
    }

    let onChangeChatbotURL= (e) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.url = "";
        setErrorMessageData(_errorMessageData);
        setChatbotURLVal(e.target.value);
    }

    // let onChangeChatbotWebhookURL = (e) => {
    //     setChatbotWebhookURLVal(e.target.value);
    // }

    // let onChangeChatbotSecretkey= (e) => {
    //     setChatbotSecretKeyVal(e.target.value);
    // }

    let onSubmitAction = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = {...errorMessageData};

        if(chatbotNameVal === "") {
            _errorMessageData.name = "This field is required";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-name" }
        }

        if(chatbotNameVal.length > 30) {
            _errorMessageData.name = "Characters exceed limit";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-name" }
        }

        if(chatbotTypeVal.value === "") {
            _errorMessageData.type = "Please select chatbot type";
            errorCount = errorCount + 1;
            
            if(errorClass === "") { errorClass = "input-type" }
        }

        if(chatbotTypeVal.value === CHATBOT_TYPE[0].value) {
            if(chatbotPathVal.value === 0) {
                _errorMessageData.path = "Please select qna path";
                errorCount = errorCount + 1;
                
                if(errorClass === "") { errorClass = "input-path" }
            }
        }

        if(chatbotTypeVal.value === CHATBOT_TYPE[1].value) {
            if(chatbotURLVal === "") {
                _errorMessageData.url = "This field is required";
                errorCount = errorCount + 1;
                
                if(errorClass === "") { errorClass = "input-url" }
            }
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            setIsLoadingSubmit(true);
            
            if(chatbotTypeVal.value === CHATBOT_TYPE[0].value) {
                let data = {
                    name: chatbotNameVal,
                    scfPathID: chatbotPathVal.value
                }
                
                ChatbotServices.addChatbot(props.match.params.orgID, data, (response) => {
                    let dataResult = response.dataResult;
                    
                    if(dataResult.error.message === "") {
                        if(dataResult.data.success) {                        
                            setChatbotPathValOrigin(chatbotPathVal);
                            setChatbotNameValOrigin(chatbotNameVal);
                            setChatbotTypeValOrigin(chatbotTypeVal);
        
                            setTimeout(() => {
                                props.history.push({
                                    pathname: props.location.state && props.location.state.pathname ? props.location.state.pathname : `/o/${props.match.params.orgID}/integration/chatbots`,
                                    // state: {
                                    //     success: true,
                                    //     message: dataResult.data.message
                                    // }
                                    state: props.location.state && props.location.state.pathname ?
                                        {
                                            accountDetail: props.location.state.accountDetail,
                                            chatbot: props.location.state.chatbot
                                        }
                                        :
                                        undefined
                                })
                            }, 3000)
        
                        }else {
                            setIsLoadingSubmit(false)
                        }
        
                        doToast(dataResult.data.message);
                    }else {
                        doToast(dataResult.error.message, "fail");
                        setIsLoadingSubmit(false);
                    }
                })
            }else {
                let data = {
                    name: chatbotNameVal,
                    chatbotURL: chatbotURLVal
                }

                ChatbotServices.addChatbotCustom(props.match.params.orgID, data, (response) => {
                    let dataResult = response.dataResult;
                    
                    if(dataResult.error.message === "") {
                        if(dataResult.data.success) {                        
                            setChatbotPathValOrigin(chatbotPathVal);
                            setChatbotNameValOrigin(chatbotNameVal);
                            setChatbotTypeValOrigin(chatbotTypeVal);
        
                            setTimeout(() => {
                                props.history.push({
                                    pathname: props.location.state && props.location.state.pathname ? props.location.state.pathname : `/o/${props.match.params.orgID}/integration/chatbots/detail/${dataResult.data.chatbot.id}`,
                                    // state: {
                                    //     success: true,
                                    //     message: dataResult.data.message
                                    // }
                                    state: props.location.state && props.location.state.pathname ?
                                        {
                                            accountDetail: props.location.state.accountDetail,
                                            chatbot: props.location.state.chatbot
                                        }
                                        :
                                        undefined
                                })
                            }, 3000)
        
                        }else {
                            setIsLoadingSubmit(false)
                        }
        
                        doToast(dataResult.data.message);
                    }else {
                        doToast(dataResult.error.message, "fail");
                        setIsLoadingSubmit(false);
                    }
                })
            }
        }
    }

    let styleSelect = () => ({
        indicatorsContainer: provide => ({
            ...provide,
            height: "48px",
        }),
        valueContainer: provide => ({
            ...provide,
            height: "100%"
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused ? iconDropOrange : iconDropGrey,
            transition: "0.25s ease-in-out",
            color: "transparent !important",
            width: "24px",
            height: "24px",
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            width: "320px"
        }),
        control: (provide, { isFocused }) => ({
            ...provide,
            padding: "0 5px",
            // height: "48px",
            borderRadius: "8px",
            border: errorMessageData.path !== "" ? " 1px solid #ff3f57 !important" : (isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important"),
            boxShadow: "none !important",
            // marginBottom: "24px",
            cursor: "pointer"
        }),
        indicatorSeparator: provide => ({ ...provide, display: "none" }),
            menu: provide => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden"
        }),
        option: (provide, state) => {
            const { isSelected } = state;
            const checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)"
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null
            };
        }
    })

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={JSON.stringify({
                    name: chatbotNameValOrigin,
                    path: chatbotPathValOrigin,
                    type: chatbotTypeValOrigin,
                    url: chatbotURLValOrigin
                })}
                dataAfterChange={JSON.stringify({
                    name: chatbotNameVal,
                    path: chatbotPathVal,
                    type: chatbotTypeVal,
                    url: chatbotURLValOrigin
                })}
            >
                {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}
                <div className="sectionWrap integration-main-container chatbot-add-chatbot text-left">
                    <BackButton text="Back to Chatbot" onClick={back} />

                    <div className="chatbot-add-chatbot-box">
                        <div className="chatbot-add-chatbot-box-top">
                            <b>Add Chatbot</b>
                            <p>
                                Create automated messages to answer repetitive questions from your customers.
                            </p>
                        </div>

                        <div className="chatbot-add-chatbot-box-form">
                            <label>
                                <b>Chatbot Name</b>
                                <span className={errorMessageData.name === "Characters exceed limit" ? "red-text" : "grey-font"}>{chatbotNameVal.length}/30</span>
                            </label>
                            <input className={`input-name ${errorMessageData.name !== "" ? "border-red" : ""}`} disabled={isLoadingSubmit} type="text" placeholder="Your chatbot name" onChange={(e) => {onChangeChatbotName(e)}} value={chatbotNameVal} />
                            {errorMessageData.name !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.name}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            <label><b>Chatbot Type</b></label>
                            <CustomSelectOption
                                // isDisabled={true}
                                optionListProps={CHATBOT_TYPE}
                                valueDropdownProps={chatbotTypeVal}
                                placeholderProps={"Select chatbot type"}
                                onClickDropDownListOptionProps={onSelectType}
                                isDisabled={isLoadingSubmit}
                                _className={`input-type ${errorMessageData.type !== "" ? "border-red" : ""}`}
                            />
                            {errorMessageData.type !== "" &&
                                <ErrorOrInfoComp
                                    text={errorMessageData.type}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                />
                            }

                            {chatbotTypeVal.value === CHATBOT_TYPE[0].value &&
                                <>
                                    <label><b>QnA Path</b></label>
                                    <Select 
                                        options={pathList} 
                                        placeholder="Select QnA Path" 
                                        onChange={onSelectPath}
                                        styles={styleSelect()}
                                        isDisabled={isLoadingSubmit}
                                        blurInputOnSelect={true}
                                        _className={`input-path ${errorMessageData.path !== "" ? "border-red" : ""}`}
                                    />
                                    {errorMessageData.path !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.path}
                                            _className={"font-red"}
                                            icon={<FiInfo />}
                                        />
                                    }
                                </>
                            }

                            {chatbotTypeVal.value === CHATBOT_TYPE[1].value &&
                                <>
                                    <label><b>Chatbot URL</b></label>
                                    <input disabled={isLoadingSubmit} className={`input-url ${errorMessageData.url !== "" ? "border-red" : ""}`} type="text" placeholder="Insert URL" onChange={(e) => {onChangeChatbotURL(e)}} value={chatbotURLVal} />

                                    {errorMessageData.url !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.url}
                                            _className={"font-red"}
                                            icon={<FiInfo />}
                                        />
                                    }
                                    {/* <label><b>Webhook URL</b></label>
                                    <input type="text" placeholder="Insert URL" onChange={(e) => {onChangeChatbotWebhookURL(e)}} value={chatbotWebhookURLVal} />
                                
                                    <label><b>Secret Key</b></label>
                                    <input type="text" placeholder="Insert URL" onChange={(e) => {onChangeChatbotSecretkey(e)}} value={chatbotSecretKeyVal} /> */}
                                </>
                            }

                        </div>

                        <div className="chat-bot-chatbot-button-wrapper">
                            <ButtonWithLoadingOrIcon
                                text="Cancel"
                                onClickAction={back}
                                className="main-button-40 no-fill-button"
                            />
                            
                            {isLoadingSubmit ?
                                <ButtonWithLoadingOrIcon
                                    isLoading
                                    loadingColor="gray"
                                    isDisabled
                                    position="left"
                                    text="Save Changes"
                                    className="main-button-40 orange-button"
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    text="Save Changes"
                                    className="main-button-40 orange-button"
                                    onClickAction={onSubmitAction}
                                    // isDisabled={!isFormValid()}
                                />    
                            }
                        </div>
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationChatbotAdd;