import React, { useState } from "react";
import "../../auth/Auth.css";
import "../QuickReply.scss";
import "react-toastify/dist/ReactToastify.css";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import { Modal, ModalBody } from "reactstrap";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { FiPlus, FiImage, FiFile } from "react-icons/fi";
import iconChatGrey from "../../../assets/img/icon-chat-grey.svg";
import iconEditOrange from "../../../assets/img/icon-edit.svg";
import IconRemoveRed from "../../../assets/img/icon-remove-red.svg";
import iconImage from "../../../assets/img/broadcast-message/icon_image.svg";
import iconFile from "../../../assets/img/broadcast-message/icon_file.svg";
import iconText from "../../../assets/img/broadcast-message/icon_text.svg";
// import { IoIosRefresh } from "react-icons/io";
import PopupRemove from "../../reuseableComponent/popupRemove/PopupRemove";
// import Loading from "../../reuseableComponent/loading/Loading";
import NewButton from "../../reuseableComponent/NewButton/NewButton";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import ServiceQuickReply from "../../../services/newServices/QuickReplyService";
import HelperQuickReply from "../../../helper/HelperQuickReply";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

let QuickReplyGeneral = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = React.useState(false);
    let [waitForResponseDelete, setWaitForResponseDelete] = React.useState(false);
  let [quickReplyList, setQuickReplyList] = React.useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = React.useState(true);
  let [showPopupSingleRemove, setShowPopupSingleRemove] = React.useState(false);
  let [singleRemoveQuickReply, setSingleRemoveQuickReply] = React.useState({
    shortcut: "",
    id: ""
  });
  let [retriveDataError, setRetrieveDataError] = React.useState({
    code: false,
    message: false
  })
  let [showModalAdd, setShowModalAdd] = useState(false);
  let [activeSelect, setActiveSelect] = useState(0);
  
  let toggleModalAdd = () => {
    setShowModalAdd(!showModalAdd);
    setActiveSelect(1);
  }

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/setup/quick-reply`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let fetchQuickReplyList = () => {
    let _retriveDataError = {...retriveDataError};

    ServiceQuickReply.getQuickReplyList(orgID, (response) => {
      let dataResult = response.dataResult;
      if(dataResult.error.message === "") {
        setQuickReplyList(dataResult.data.general);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  React.useEffect(() => {
    if(orgID) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      fetchQuickReplyList();
      
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
            "[Page] General Quick Reply", 
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
  }, [orgID]);

  React.useEffect(() => {
    doMount();
  }, [location.pathname]);

  React.useEffect(() => {
    if(props.history.location.state) {
      if(props.history.location.state.message) {
        let _history = {...props.history.location.state};
        delete _history.message;

        doToast(props.history.location.state.message);
        props.history.replace({
          state: _history
        });
      }
    }
  }, [props.history])

  // let onChangePage = () => {
  //   console.log('change page')
  // }

  let toggleModalRemoveQuickReply = () => {
    setShowPopupSingleRemove(!showPopupSingleRemove);
  }

  let deleteQuickReply = () => {
    setWaitForResponseDelete(true);
    let _singleRemoveQuickReply = {...singleRemoveQuickReply};
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    ServiceQuickReply.deleteQuickReply(orgID, _singleRemoveQuickReply, (response) => {
      let dataResult = response.dataResult;
      setWaitForResponseDelete(false);
      toggleModalRemoveQuickReply();

      if(dataResult.error.message === "") {
        if(!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, "fail");
        }else {
          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Delete Quick Reply", 
              {
                  type: "General",
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
              }
            );
          }

          doToast(response.dataResult.data.message);
          fetchQuickReplyList();
        }
      }else {
        doToast(response.dataResult.message, 'fail');
      }

    })
  }

  let onClickSingleDelete = (data) => {
    let _data = {
      ownerType: HelperQuickReply.ownerTypeGeneral,
      shortcut: data.shortcut,
      id: data.id,
      createdTime: data.createdTime
    }

    setSingleRemoveQuickReply(_data);
    setShowPopupSingleRemove(true);
  }

  let addBtn = () => {
    // let fullUrl = `/o/${orgID}/setup/quick-reply/general/add`;
    
    // let onClick = () => history.push(fullUrl);

    let onClick = () => {};
    let btnProps = { text: "Add Quick Reply", icon: FiPlus, onClick };

    return <NewButton {...btnProps} />;
  };

  let ModalAdd = () => {
    return (
      <Modal className="modal-add-quick-reply" isOpen={showModalAdd} toggle={toggleModalAdd}>
        <ModalBody>
          <b className="add-quick-reply-title">Add Quick Reply</b>

          <label>
            <b>Type</b>
          </label>

          <div className={`add-quick-reply-selection ${activeSelect === 1 ? "active-add-quick-reply-selection" : ""}`} onClick={() => setActiveSelect(1)}>
            <img src={iconText} alt="" />
            Text
          </div>

          <div className={`add-quick-reply-selection ${activeSelect === 2 ? "active-add-quick-reply-selection" : ""}`} onClick={() => setActiveSelect(2)}>
            <img src={iconImage} alt="" />
            Text with Media
          </div>

          <div className={`add-quick-reply-selection no-margin-right ${activeSelect === 3 ? "active-add-quick-reply-selection" : ""}`} onClick={() => setActiveSelect(3)}>
            <img src={iconFile} alt="" />
            Text with Document
          </div>

          <ButtonWithLoadingOrIcon 
            text="Create"
            onClickAction={() => {
              props.history.push({
                pathname: `/o/${orgID}/setup/quick-reply/general/add`,
                state: {
                  type: activeSelect === 1 ? "text" : (activeSelect === 2 ? "media" : "file")
                }
              })
            }}
            isDisabled={activeSelect === 0}
            className="orange-button main-button-48"
          />
        </ModalBody>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      {ModalAdd()}

      <div className="sectionWrap quick-reply-main-wrapper">
          {/* tier blocking */}
          {retriveDataError.code  === "49901" &&
            <RetrieveDataLoading 
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
          }
          {/* tier blocking */}

          <div className="topContent">
              <div className="title">General Quick Replies</div>
              
              {!waitForResponseGetList &&
                // <NavLink to={`/o/${orgID}/setup/quick-reply/general/add`}>
                  <div 
                    className="btn"
                    onClick={() => {
                      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                      
                      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                          "[Button] Add Quick Reply",
                          {
                            type : "General",
                            userFullName : myAgentData.account.fullName,
                            userID : myAgentData.account.id,
                            organizationID : orgID.split("-")[0]
                          }
                        )
                      }

                      toggleModalAdd();
                    }}
                  >
                    {addBtn()}
                  </div>
                // </NavLink>
              }
          </div>

          <div className="quick-reply-list-info">
              Speed up your workflow by adding quick replies for frequently sent messages. To send quick replies, simply type “/“ in OneTalk’s inbox and select the shortcut from the list.
          </div>

          {waitForResponseGetList || retriveDataError.code ? 
            <RetrieveDataLoading 
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message} 
            />
            :
            <React.Fragment>
              <div className="quick-reply-list-table-wrapper">
                <div className="custom-table">
                    <table className="custom-table-content table-quick-reply-list">
                        <thead>
                            <tr>
                              <td className="col_message">Message</td>
                              <td className="col_shortcut">Shortcut</td>
                              <td className="col_type">Type</td>
                              <td className="col_action"></td>
                            </tr>
                        </thead>
                        
                        {quickReplyList.length < 1 ?
                          <tbody>
                            <tr>
                              <td colSpan="4" className="no-data-column"><b className="no-result-found">No quick replies found, start with ‘+ Add Quick Reply’</b></td>
                            </tr>
                          </tbody>
                          :
                          <tbody>
                            {quickReplyList.map((value, index) => {
                              return (
                                <tr key={`quick-reply-${index}`}>
                                    <td className="col_message">{value.message}</td>
                                    <td className="col_shortcut">
                                      <span className="shortcut-badge"><b>{HelperQuickReply.prefixShortcut}{value.shortcut}</b></span>
                                    </td>
                                    <td className="col_type">
                                      {(value.messageType === "text" || value.messageType === "") ?
                                        <>
                                          <img src={iconChatGrey} alt="" />
                                          Text
                                        </>
                                        :
                                        (value.messageType === "file" ?
                                          <>
                                            <FiFile />
                                            Text with Document
                                          </>
                                          :
                                          <>
                                            <FiImage />
                                            Text with Media
                                          </>
                                        )
                                      }
                                    </td>
                                    <td className={`col_action`}>
                                      <div className="col-action-button-wrapper">
                                          <button 
                                            className="single-remove-button no-background-and-border-button" 
                                            onClick={() => {
                                              let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                                              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                mixpanel.track(
                                                  "[Button] Edit Quick Reply",
                                                    {
                                                      type : "General",
                                                      userFullName : myAgentData.account.fullName,
                                                      userID : myAgentData.account.id,
                                                      organizationID : orgID.split("-")[0]
                                                    }
                                                )
                                              }

                                              props.history.push({
                                                pathname: `/o/${orgID}/setup/quick-reply/general/edit/${value.id}`,
                                                state: {
                                                  shortcut: value.shortcut,
                                                  message: value.message,
                                                  id: value.id,
                                                  mediaURL: value.mediaURL,
                                                  mediaFilename: value.mediaFilename,
                                                  messageType: value.messageType
                                                }
                                              })
                                            }}
                                          >
                                              <img className="view-agent" src={iconEditOrange} alt="" />
                                              <b className="view-agent">Edit</b>
                                          </button>
                                          
                                          <button 
                                            className="single-remove-button no-background-and-border-button" 
                                            onClick={() => {
                                              let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                                              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                mixpanel.track(
                                                  "[Button] Delete Quick Reply",
                                                    {
                                                      type : "General",
                                                      userFullName : myAgentData.account.fullName,
                                                      userID : myAgentData.account.id,
                                                      organizationID : orgID.split("-")[0]
                                                    }
                                                )
                                              }

                                              onClickSingleDelete(value)
                                            }}>
                                            <img className="view-agent" src={IconRemoveRed} alt="" />
                                            <b>Delete</b>
                                          </button>
                                      </div>
                                    </td>
                                </tr>
                              )
                            })}
                          </tbody>
                        }
                    </table>
                </div>
                  
                {/* <Pagination total={100} 
                            page={1} 
                            listPerPage={8} 
                            step={3} 
                            onChangePage={onChangePage} 
                /> */}

                <PopupRemove removeTitle={`Delete Quick Reply ${singleRemoveQuickReply.shortcut}?`}
                            removeDescription={`This quick reply will be removed from the list`}
                            onClickCancel={() => toggleModalRemoveQuickReply()}
                            onClickRemove={deleteQuickReply}
                            waitForResponse={waitForResponseDelete}
                            showModalProps={showPopupSingleRemove}
                            submitText="Delete Quick Reply"
                />
              </div>
            </React.Fragment>
          }
      </div>
    </React.Fragment>
  );
};

export default QuickReplyGeneral;
