import React, { useEffect, useState, useRef } from "react";
import './FilterBy.scss';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import CustomSelectOption from "../../../../reuseableComponent/customSelectOption/CustomSelectOption";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import iconWhatsapp from "../../../../../assets/img/integration/launcher/icon-whatsapp.svg";
import iconWhatsappBa from "../../../../../assets/img/integration/launcher/icon-whatsapp-ba.svg";
import iconTelegram from "../../../../../assets/img/integration/launcher/icon-telegram.svg";
import iconGoogle from "../../../../../assets/img/integration/launcher/icon-google.svg";
import iconGoogleProfile from "../../../../../assets/img/integration/launcher/icon-google-profile.svg";
// import iconLine from "../../../../../assets/img/integration/launcher/icon-line.svg";
import iconFacebook from "../../../../../assets/img/integration/launcher/icon-facebook.svg";
import iconTaptalk from "../../../../../assets/img/integration/logo-taptalk.svg";
// import iconTwitter from "../../../../../assets/img/integration/launcher/icon-twitter.svg";
import iconInstagram from "../../../../../assets/img/integration/launcher/icon-instagram.svg";
import iconTokopedia from "../../../../../assets/img/user-contact/icon-tokopedia.svg";
import { FiSearch } from 'react-icons/fi'
import { IoMdCloseCircle } from 'react-icons/io'
import CaseService from "../../../../../services/chat/caseServices";
import CaseLabelService from "../../../../../services/newServices/CaseLabelService";
import { doToast } from "../../../../../helper/HelperGeneral";
import { CASE_TYPES } from "../../../../../constants/inbox";
import { connect } from "react-redux";
import { setCaseFilter } from "../../../../../redux/actions/reduxActionCaseFilter";
// let DEFAULT_FILTER_TOPIC_AGENT = {
//     value: 0,
//     label: ""
// }

let MEDIUM_LIST = [
    {
        value: "launcher",
        label: "Live Chat",
        img: iconTaptalk
    },
    {
        value: "whatsapp",
        label: "WhatsApp SME",
        img: iconWhatsapp
    },
    {
        value: "whatsappba",
        label: "WhatsApp BA",
        img: iconWhatsappBa
    },
    {
        value: "facebook",
        label: "Facebook Messenger",
        img: iconFacebook
    },
    {
        value: "telegram",
        label: "Telegram",
        img: iconTelegram
    },
    // {
    //     value: "",
    //     label: "",
    //     img: iconLine
    // },
    // {
    //     value: "twitter",
    //     label: "Twitter DM",
    //     img: iconTwitter
    // },
    {
        value: "instagram",
        label: "Instagram",
        img: iconInstagram
    },
    {
        value: "google_business",
        label: "Google Business Messages",
        img: iconGoogle
    },
    {
        value: "gb_profile",
        label: "Google Business Profile",
        img: iconGoogleProfile
    },
    {
        value: "tokopedia",
        label: "Tokopedia",
        img: iconTokopedia
    },
]

let FilterBy = (props) => {
    // let [filterTopicValue, setFilterTopicValue] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    // let [filterAgentValue, setFilterAgentValue] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    // let [filterMediumValue, setFilterMediumValue] = useState([]);
    let [caseLabelList, setCaseLabelList] = useState(null)
    let [assignedAgentList, setAssignedAgentList] = useState([])
    let [submitQuerySearchLabel, setSubmitQuerySearchLabel] = useState("")
    let [isLoadingGetLabelList, setIsLoadingGetLabelList] = useState(false)
    let [caseLabelToSelect, setCaseLabelToSelect] = useState([])
    let [focusInput, setFocusInput] = useState(false)
    let [showListLabel, setShowListLabel] = useState(false)
    // let [selectedCaseLabel, setSelectedCaseLabel] = useState([])
    let labelRef = useRef()
    let inputRef = useRef()
    let wrapperRef = useRef()
    let [isLoadingFilterAgent, setIsLoadingFilterAgent] = useState(false);

    useEffect(() => {
        if(props.isOpenProps && props.filterTopicValueProps.value === 0) {
            getAssignedAgentList()
        }
    }, [props.isOpenProps])

    useEffect(() => {
        // clearFilterTopicAndAgent();
        props.toggleResetFilterProps();
    }, [props.isResetFilterProps])

    let onSelectTopic = (value) => {
        props.onSelectTopicProps(value);

        if(!props.isHideAgentProps) {
            getAssignedAgentList(value);
        }
        // setFilterTopicValue(value)
    }

    let onSelectAgent = (value) => {
        props.onSelectAgentProps(value);
        // setFilterAgentValue(value)
    }

    let onChangeChannel = (e, value) => {
        let _filterMediumValue = props.filterMediumValueProps.slice();
        let findIndex = _filterMediumValue.findIndex(val => val === e.target.id);
        if (e.target.checked) {
            _filterMediumValue.push(value);
        } else {
            if (findIndex !== -1) {
                _filterMediumValue.splice(findIndex, 1);
            }
        }

        props.onSelectMediumProps(_filterMediumValue);
        // setFilterMediumValue(_filterMediumValue);
    }

    let actionFilterTopicAgentChannel = () => {
        let _caseFilter = {...props.caseFilter};
        let med = {};
        
        props.toggleIsActiveFilterProps(true);

        props.updateFilterProps({
            topic: props.filterTopicValueProps,
            agent: props.filterAgentValueProps,
            channel: props.filterMediumValueProps,
            label: props.filterLabelValueProps,
            filter: props.filterCaseTypeProps
        })

        props.closeModal();

        if(props.filterMediumValueProps.length > 0) {
            props.filterMediumValueProps.map(v => {
                med[v] = v;
                return null;
            })
        }

        _caseFilter[props.room] = {
            active: true,
            medium: med,
            agent: props.filterAgentValueProps.value === "" ? 0 : Number(props.filterAgentValueProps.value),
            topic: props.filterTopicValueProps.value === "" ? 0 : Number(props.filterTopicValueProps.value)
        }

        props.setCaseFilter(_caseFilter);
    }

    // let clearFilterTopicAndAgent = () => {
    //     setFilterMediumValue([]);
    //     setFilterTopicValue(DEFAULT_FILTER_TOPIC_AGENT);
    //     setFilterAgentValue(DEFAULT_FILTER_TOPIC_AGENT);
    // }

    let getCaseLabelList = () => {
        // setWaitForResponseFetchLabelList(true);
        setIsLoadingGetLabelList(true);

        CaseLabelService.getCaseLabelList(props.orgID, submitQuerySearchLabel, (response) => {
            // setWaitForResponseFetchLabelList(false);
            setIsLoadingGetLabelList(false);
            if (response.dataResult.status === 200) {
                // console.log(response.dataResult.data.caseLabels)
                setCaseLabelList(response.dataResult.data.caseLabels)
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }
        })
    }

    useEffect(() => {
        if (caseLabelList) {
            // setCaseLabelToSelect(caseLabelList)
            let _caseLabels = [...caseLabelList]

            if (caseLabelList.length > 0) {
                // data label dikurang dengan selected
                let selected = [...props.filterLabelValueProps]
                let labels = _caseLabels.filter((el) => {
                    return !selected.find(val => val.id === el.id)
                })
                setCaseLabelToSelect(labels)
            } else {
                setCaseLabelToSelect([])
            }
        }
    }, [caseLabelList])

    let handleSearchLabel = (e) => {
        setSubmitQuerySearchLabel(e.target.value)
    }

    useEffect(() => {
        if(submitQuerySearchLabel !== "") {
            getCaseLabelList()
        }else {
            setCaseLabelList(props.caseLabelList)
        }
    }, [submitQuerySearchLabel, props.filterLabelValueProps])

    let onSelectLabel = (label) => {
        // let _dataLabel = [...selectedCaseLabel, label]
        let _dataLabel = [...props.filterLabelValueProps, label]

        // setSelectedCaseLabel(_dataLabel)
        props.onSelectLabelProps(_dataLabel)

        let _caseLabelToSelect = [...caseLabelToSelect]
        let newCaseLabelToSelect = _caseLabelToSelect.filter(item => item.id !== label.id)
        setCaseLabelToSelect(newCaseLabelToSelect)
        setSubmitQuerySearchLabel("")
        inputRef.current.focus()
    }

    let removeSelectedLabel = (label) => {
        // let _selectedLabel = [...selectedCaseLabel];
        let _selectedLabel = [...props.filterLabelValueProps];

        let _dataLabel = [..._selectedLabel]
        _selectedLabel = _dataLabel.filter(item => item.id !== label.id)

        // setSelectedCaseLabel(_selectedLabel);
        props.onSelectLabelProps(_selectedLabel)

        let _caseLabelToSelect = [...caseLabelToSelect, label]
        _caseLabelToSelect.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
        setCaseLabelToSelect(_caseLabelToSelect)

        setShowListLabel(false)
    }

    useEffect(() => {
        let onBodyClick = (event) => {
            if (showListLabel) {
                if (!labelRef.current.contains(event.target) && !inputRef.current.contains(event.target)) {
                    setShowListLabel(false);
                    setFocusInput(false)
                }
            } else {
                if (wrapperRef.current) {
                    if (wrapperRef.current.contains(event.target)) {
                        setFocusInput(true)
                        setShowListLabel(true)
                    } else {
                        setShowListLabel(false);
                        setFocusInput(false)
                    }
                }
            }
        };

        document.body.addEventListener('click', onBodyClick);

        return () => {
            document.body.removeEventListener('click', onBodyClick);
        };
    }, [showListLabel]);

    let getAssignedAgentList = (val = false) => {
        setIsLoadingFilterAgent(true);

        CaseService.postAssignedAgentList(props.orgID, {topicID: val ? val.value : 0}, (response) => {
            setIsLoadingGetLabelList(false);

            if (response.dataResult.status === 200) {
                let list = [];
                response.dataResult.data.agents.map((value) => {
                    list.push({
                        value: value.accountID,
                        label: value.accountName
                    })

                    return null;
                })

                let _findIndex = list.findIndex(val => val.value === props.filterAgentValueProps.value);

                if(_findIndex === -1) {
                    props.onSelectAgentProps({
                        value: 0,
                        label: ""
                    });                    
                }

                setAssignedAgentList(list)
            } else {
                doToast(response.dataResult.error.message, 'fail');
            }
        })

        setIsLoadingFilterAgent(false); 
    }

    let isDisabledUpdateFilter = () => {
        let isDisabled = true;

        if(props.filterTopicValueProps.value !== 0) {
            isDisabled = false;
        }
        
        if(!props.isHideAgentProps && props.filterAgentValueProps.value !== 0) {
            isDisabled = false;
        }
        
        if(props.filterMediumValueProps.length !== 0) {
            isDisabled = false;
        }


        if(props.filterLabelValueProps.length !== 0) {
            isDisabled = false;
        }

        if(props.filterCaseTypeProps !== props.filterCaseTypeBeforeProps) {
            isDisabled = false;
        }
    
        return isDisabled;
    }

    return (
        <Modal isOpen={props.isOpenProps} toggle={props.toggleProps} className="case-modal-filter-option">
            <ModalBody>
                {/* topic {props.filterTopicValueProps} */}
                <p className="modal-date-filter-title"><b>Filters</b></p>

                <div className="case-filter-option-wrapper">
                    <label><b>Topic</b></label>
                    <CustomSelectOption 
                        isDisabled={isLoadingFilterAgent}
                        optionListProps={props.topicListProps}
                        valueDropdownProps={props.filterTopicValueProps}
                        placeholderProps={"Select Topic"}
                        onClickDropDownListOptionProps={onSelectTopic}
                        specificClassNameProps="custom-select-filter-option"
                    />

                    {!props.isHideAgentProps &&
                        <>
                            <label><b>Agent</b></label>
                            <CustomSelectOption 
                                isLoading={isLoadingFilterAgent}
                                isDisabled={isLoadingFilterAgent}
                                // optionListProps={props.agentListProps}
                                optionListProps={assignedAgentList}
                                valueDropdownProps={props.filterAgentValueProps}
                                placeholderProps={"Select Agent"}
                                onClickDropDownListOptionProps={onSelectAgent}
                                specificClassNameProps="custom-select-filter-option"
                            />
                        </>
                    }

                    <label><b>Case Labels</b></label>
                    <div className="case-label-container">
                        <div className={`input-contact-container ${focusInput ? 'focus' : 'blur'}`} ref={wrapperRef} >
                            {
                                props.filterLabelValueProps.length > 0 &&
                                props.filterLabelValueProps.map(label => {
                                    return (
                                        <div className="label-value" style={{ background: label.backgroundColor }} key={`selected-label-${label.name}`} >
                                            <IoMdCloseCircle onClick={() => removeSelectedLabel(label)} color="#ffffff" />
                                            <b>{label.name}</b>
                                        </div>
                                    )
                                })
                            }

                            <textarea placeholder={props.filterLabelValueProps.length > 0 ? "" : "Search and select multiple labels"} ref={inputRef} onChange={handleSearchLabel} style={{ width: props.filterLabelValueProps.length > 0 ? '50px' : '90%' }} value={submitQuerySearchLabel} />
                            <span className="search-icon">
                                <FiSearch color={focusInput ? '#ff7d00' : '#000000'} />
                            </span>
                        </div>
                        <div className="add-label-suggestion-wrapper" style={{ display: `${showListLabel ? 'block' : 'none'}` }} ref={labelRef}>
                            <ul>
                                {
                                    !isLoadingGetLabelList ?
                                        <>
                                            {
                                                caseLabelToSelect &&
                                                    caseLabelToSelect.length > 0 ?
                                                    caseLabelToSelect.map(label => {
                                                        return (
                                                            <li key={`case-label-${label.id}`} onClick={() => onSelectLabel(label)}>
                                                                <div className="label-value" style={{ background: label.backgroundColor }}>
                                                                    <b>{label.name}</b>
                                                                </div>
                                                            </li>
                                                        )
                                                    })
                                                    :
                                                    <li className="no-option">
                                                        No Options
                                            </li>
                                            }
                                        </>
                                        :
                                        <div className="loading-wrapper">
                                            <div className="lds-ring">
                                                <div /><div /><div /><div />
                                            </div>
                                        </div>
                                }
                            </ul>
                        </div>
                    </div>
                    
                    {!props.hideJunk &&
                        <>
                            {/* {typeof props.hideNeedReply !== "undefined" &&
                                <>
                                    <label><b>Case Status</b></label>
                                    <div className="custom-checkbox">
                                        <div className="input-wrapper checkbox-input">
                                            <input
                                                type="checkbox"
                                                name="need-reply"
                                                id="need-reply"
                                                value={props.needReply}
                                                onChange={(e) => props.onChangeNeedReply(e)}
                                                checked={props.needReply}
                                            />
                                            <label htmlFor="need-reply">Need Reply</label>
                                        </div>
                                    </div>
                                </>
                            } */}

                            <label><b>Case Type</b></label>
                            <div className="custom-radio show-case-type">
                                <div className="input-wrapper">
                                    <input
                                        type="radio"
                                        name="all-cases"
                                        id="all-cases"
                                        value={CASE_TYPES.allTypes.value}
                                        onChange={(e) => props.onSelectCaseType(CASE_TYPES.allTypes.value)}
                                        checked={props.filterCaseTypeProps === CASE_TYPES.allTypes.value}
                                    />
                                    <label htmlFor="all-cases">{CASE_TYPES.allTypes.label}</label>
                                </div>
                                <div className="input-wrapper">
                                    <input
                                        type="radio"
                                        name="junk-only"
                                        id="junk-only"
                                        value={CASE_TYPES.junkOnly.value}
                                        onChange={(e) => props.onSelectCaseType(CASE_TYPES.junkOnly.value)}
                                        checked={props.filterCaseTypeProps === CASE_TYPES.junkOnly.value}
                                    />
                                    <label htmlFor="junk-only">{CASE_TYPES.junkOnly.label}</label>
                                </div>
                                <div className="input-wrapper">
                                    <input
                                        type="radio"
                                        name="non-junk-only"
                                        id="non-junk-only"
                                        value={CASE_TYPES.nonJunkOnly.value}
                                        onChange={(e) => props.onSelectCaseType(CASE_TYPES.nonJunkOnly.value)}
                                        checked={props.filterCaseTypeProps === CASE_TYPES.nonJunkOnly.value}
                                    />
                                    <label htmlFor="non-junk-only">{CASE_TYPES.nonJunkOnly.label}</label>
                                </div>
                            </div>
                        </>
                    }

                    <label><b>Channels</b></label>
                    <div className="custom-checkbox filter-by-channel">
                        {MEDIUM_LIST.map((value, index) => {
                            return (
                                <div key={`channel-${index}`}>
                                    <input
                                        checked={props.filterMediumValueProps.findIndex(val => val === value.value) !== -1}
                                        type="checkbox"
                                        id={value.value}
                                        onChange={(e) => onChangeChannel(e, value.value)}
                                    />
                                    <label htmlFor={value.value}>
                                        <img src={value.img} alt="" />
                                        <b>{value.label}</b>
                                    </label>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <ButtonWithLoadingOrIcon
                    onClickAction={() => props.toggleProps()}
                    className="no-fill-button main-button-48"
                    text="Cancel"
                />


                {props.filterTopicValueProps === 0 && props.filterAgentValueProps === 0 ?
                    <ButtonWithLoadingOrIcon
                        isDisabled={true}
                        className="orange-button main-button-48"
                        text="Update Filter"
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        isDisabled={isDisabledUpdateFilter()}
                        onClickAction={actionFilterTopicAgentChannel}
                        className="orange-button main-button-48"
                        text="Update Filter"
                    />
                }
            </ModalFooter>
        </Modal>
    )
}


let mapStateToProps = state => ({
    caseLabelList: state.caseLabelList,
    caseFilter: state.caseFilter
});
  
let mapDispatchToProps = {
    setCaseFilter
};
  
export default connect(mapStateToProps, mapDispatchToProps)(FilterBy);