import React, { useState, useEffect } from 'react';
import './ChatRoomCaseHistoryDetailTab.css';
import { connect } from 'react-redux';
import IconHashtag from '../../../../../assets/img/chatroom/user-info-tab/hastag.svg';
import IconList from '../../../../../assets/img/chatroom/user-info-tab/list.svg';
import IconNotif from '../../../../../assets/img/chatroom/user-info-tab/notif.svg';
// import IconCheck from '../../../../../assets/img/chatroom/user-info-tab/check.svg';
import IconPlus from '../../../../../assets/img/chatroom/user-info-tab/plus.svg';
// import IconIOS from '../../../../../assets/img/chatroom/user-info-tab/ios.svg';
import IconTimer from '../../../../../assets/img/chatroom/user-info-tab/timer.svg';
import IconChatList from '../../../../../assets/img/chatroom/user-info-tab/chat-list.svg';
import IconCommendChecked from '../../../../../assets/img/chatroom/user-info-tab/comment-checked.svg';
import IconNote from '../../../../../assets/img/chatroom/user-info-tab/note.svg';
import { Scrollbars } from 'react-custom-scrollbars';
import HelperDate from "../../../../../helper/HelperDate";
import Helper from "../../../../../helper/HelperChat";
// import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
import { setCaseLabelList } from '../../../../../redux/actions/reduxActionCaseLabelList';
import { TAPLIVE_MEDIUM_LOGO } from "../../../../../constants/taplive";

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};

var ChatRoomCaseHistoryDetailTab = (props) => {
  let [intervalFirstResponseTimeRun, setIntervalFirstResponseTimeRun] = useState(false);
  let [intervalCaseDurationRun, setIntervalCaseDurationRun] = useState(false);
  let [caseDurationClock, setCaseDurationClock] = useState(0);
  let [firstResponseTimeClock, setFirstResponseTimeClock] = useState(0);
  let [caseDetailData, setCaseDetailData] = useState(null);
  let [caseLabels, setCaseLabels] = useState(null);
  // let [caseLabelList, setCaseLabelList] = useState(null);

  useEffect(() => {
    if(props.caseHistoryPopupDataProps !== null) {
      let caseDetail = props.caseHistoryPopupDataProps.case;

      let dateNow = new Date().valueOf();

      let timeFirstResponse = () => {        
        setFirstResponseTimeClock(caseDetail.firstResponseTime === 0 ? dateNow - caseDetail.counterStartTime : caseDetail.firstResponseTime - caseDetail.counterStartTime);
        setIntervalFirstResponseTimeRun(caseDetail.firstResponseTime === 0 ? true : false)
      }

      let timeCaseDuration = () => {
        setCaseDurationClock(caseDetail.closedTime === 0 ? dateNow - caseDetail.counterStartTime : caseDetail.closedTime - caseDetail.counterStartTime);
        setIntervalCaseDurationRun(!caseDetail.isClosed)
      }

      setCaseDetailData(caseDetail);
      timeFirstResponse();
      timeCaseDuration();
    }
  }, [props.caseHistoryPopupDataProps])

  // useEffect(() => {
  //   const data = HelperGetLocalStorage.getLocalStorageData('caseLabelList')
  //   if (data) {
  //     setCaseLabelList(data)
  //   }
  // }, [props.caseLabelList])

  
  useEffect(() => {
    if (caseDetailData && caseDetailData.labelIDs) {
      if (caseDetailData.labelIDs.length > 0) {
        if (props.caseLabelList.length > 0) {
          const _labels = [...caseDetailData.labelIDs]
          const _caseLabels = [...props.caseLabelList]

          const dataLabel = _caseLabels.filter((el) => {
            return _labels.find(val => val === el.id)
          });
          setCaseLabels(dataLabel)
        } else {
          setCaseLabels([])
        }
      } else {
        setCaseLabels([])
      }
    }
  }, [caseDetailData, props.caseLabelList])

  useEffect(() => {
    if(caseDetailData !== null && intervalFirstResponseTimeRun) {
      let intervalFirstResponseTime = setInterval(function() {
        setFirstResponseTimeClock(firstResponseTimeClock => firstResponseTimeClock + 1000)
      }, 1000)
  
      return () => clearInterval(intervalFirstResponseTime);
    }
  }, [intervalFirstResponseTimeRun])

  useEffect(() => {
    if(caseDetailData !== null && intervalCaseDurationRun) {
      let intervalCaseDuration = setInterval(function() {
        setCaseDurationClock(caseDurationClock => caseDurationClock + 1000)
      }, 1000)
  
      return () => clearInterval(intervalCaseDuration);
    }
  }, [intervalCaseDurationRun])

  let msToDhms =  (ms) => {
    ms = parseInt(ms/1000, 10);
    var days = Math.floor(ms / (3600*24));
    ms  -= days*3600*24;
    var hrs   = Math.floor(ms / 3600);
    ms  -= hrs*3600;
    var mnts = Math.floor(ms / 60);
    ms  -= mnts*60;
    return `${days > 0 ? days+"d" : ""} ${hrs > 0 ? hrs+"h" : ""} ${mnts > 0 ? mnts+"m" : ""} ${ms+"s"}`;
  }

  let generateIconOmnichannel = (medium) => {
    return TAPLIVE_MEDIUM_LOGO[medium] ? TAPLIVE_MEDIUM_LOGO[medium] : "";
  }

  return (
    <Scrollbars autoHideTimeout={500}
                renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
    >
      <div className="chat-room-case-detail-tab-wrapper main-user-info-wrapper">
          <div className="user-info-list-wrapper">
            <label><b>Case ID</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconHashtag} alt="" />
              {caseDetailData !== null && caseDetailData.stringID}
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Topics</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconList} alt="" />
              {caseDetailData !== null && caseDetailData.topicName}
            </div>
          </div>

          {/* <div className="user-info-list-wrapper">
            <label><b>Platform</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconIOS} alt="" />
              iOS
            </div>
          </div> */}

          <div className="user-info-list-wrapper">
            <label><b>Channel</b></label>
            <div className="user-info-box-wrapper">
              {caseDetailData !== null && 
                <React.Fragment>
                  {generateIconOmnichannel(caseDetailData.medium) === "" ?
                    ""
                    :
                    <img src={generateIconOmnichannel(caseDetailData.medium)} alt="" className="channel-icon-detail" />
                  }
                  
                  {/* {TAPLIVE_MEDIUM_TEXT[caseDetailData.medium]} */}
                  {caseDetailData.mediumChannelName}
                </React.Fragment>
              }
            </div>
          </div>

          {(caseLabels && caseLabels.length > 0) &&
            <div className="user-info-list-wrapper">
              <label><b>Label</b></label>
              <div className="user-info-label-wrapper">
                {
                  caseLabels &&
                  caseLabels.length > 0 &&
                  caseLabels.map(label => {
                    return (
                      <div className="user-info-label-tag-content" key={`case-label-${label.id}`} style={{ backgroundColor: label.backgroundColor }}>
                        <b>{label.name}</b>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          }

          <div className="user-info-list-wrapper">
            <label><b>Case Created Time</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconPlus} alt="" />
              {caseDetailData !== null && 
                HelperDate.formatToString(new Date(caseDetailData.createdTime), "dd/MM/yyyy HH:mm")
              }
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>First Response Time</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconChatList} alt="" />
              {caseDetailData !== null && 
                (caseDetailData.firstResponseTime > 0 ? 
                  HelperDate.formatToString(new Date(caseDetailData.firstResponseTime), "dd/MM/yyyy HH:mm") 
                  : 
                  (caseDetailData.firstResponseTime === 0 && caseDetailData.isClosed ?
                    'No response'
                    :
                    'Waiting for response'
                  )
                )
              }
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>First Response Wait Duration</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconNotif} alt="" />
              {caseDetailData !== null && 
                caseDetailData.firstResponseTime === 0 && caseDetailData.isClosed ?
                "No response"
                :
                msToDhms(firstResponseTimeClock)
              }
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Resolved Time</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconCommendChecked} alt="" />
              {caseDetailData !== null && 
                (caseDetailData.closedTime > 0 ? 
                  HelperDate.formatToString(new Date(caseDetailData.closedTime), "dd/MM/yyyy HH:mm") 
                  : 
                    'Case is ongoing'
                )
              }
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Case Duration</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconTimer} alt="" />
              {caseDetailData !== null && msToDhms(caseDurationClock)}
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Remarks for Case</b></label>
            <div className="user-info-box-wrapper-multiple-line">
              <img src={IconNote} alt="" />
              {caseDetailData !== null &&
                caseDetailData.agentRemark ?
                  caseDetailData.agentRemark !== "" ?
                    <span dangerouslySetInnerHTML={{ __html: Helper.lineBreakChatRoom(caseDetailData.agentRemark) }} /> 
                    :
                    <span className="grey-font">Write down anything that may help you or others understand the case context better</span>
                :
                <span className="grey-font">Write down anything that may help you or others understand the case context better</span>
              }
            </div>
        </div>
      </div>
    </Scrollbars>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  caseLabelList: state.caseLabelList
});

const mapDispatchToProps = {
  setCaseLabelList,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomCaseHistoryDetailTab);
