import React, { useEffect, useState } from 'react';
import './AgentReportShow.scss';
import ReportService from '../../../../services/newServices/ReportService';
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperDate from '../../../../helper/HelperDate';
import { doToast, checkID } from '../../../../helper/HelperGeneral';
import { FiDownload } from 'react-icons/fi';
import NoReportFound from "../../../../assets/img/no-report-found.svg";
import AscendingDescendingTableHeader from '../../../reuseableComponent/AscendingDescendingTableHeader/AscendingDescendingTableHeader';
import AdvancedDateFilter from '../../../reuseableComponent/advancedDateFilter/AdvancedDateFilter';
import Pagination from "../../../reuseableComponent/pagination/Pagination";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser";

const AgentReport = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchAgentReport, setIsWaitingFetchAgentReport] = useState(true);
  let [printedAgentReportData, setPrintedAgentReportData] = useState([]);
  let [isLoadingExportReport, setIsLoadingExportReport] = useState(false);
  let [retriveDataError, setRetrieveDataError] = React.useState({
      code: false,
      message: false
  });
  let [dateVal, setDateVal] = useState({
    from:  new Date(new Date().setDate(new Date().getDate() - 30)),
    to: new Date()
  });
  let [filterDateForCalender, setFilterDateForCalender] = useState({
    from: undefined,
    to: undefined
  });
  let [filterBy, setFilterBy] = useState({
    text: "Last 30 Days",
    val: "last_30"
  });
  let [sort, setSort] = useState({
    by: "fullName",
    order: "ASC"
  })
  // const [listPageVal, setListPageVal] = useState({
  //   label: '20',
  //   value: 20,
  // })
  const [pageDisplay, setPageDisplay] = useState(1);
  // const [listPage, setListPage] = useState(50);
  let [totalItems, setTotalItems] = useState(0);
  let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);

  const TABLE_HEADER = [
    {
      title: "Agent Name",
      value: "fullName",
      class: "col_1 " 
    },
    {
      title: "Email Address",
      value: "email",
      class: "col_2 " 
    },
    {
      title: "Cases First Responded",
      value: "totalCasesFirstResponded",
      class: "col_3 " 
    },
    {
      title: "Cases Resolved",
      value: "totalCasesClosed",
      class: "col_4 " 
    },
    {
      title: "Cases Marked as Junk",
      value: "totalCasesMarkedAsJunk",
      class: "col_6 " 
    },
    {
      title: "Cases Transferred",
      value: "totalCasesHandedOver",
      class: "col_6 " 
    },
    {
      title: "Cases Taken Over",
      value: "totalCasesTakenOver",
      class: "col_7 " 
    },
    {
      title: "Cases Rated",
      value: "totalCasesRated",
      class: "col_8 " 
    },
    {
      title: "Average First Response Wait Duration",
      value: "averageFirstResponseWaitDuration",
      class: "col_9 " 
    },
    {
      title: "Average Case Duration",
      value: "averageCaseDuration",
      class: "col_10 " 
    },
    {
      title: "Average Rating",
      value: "averageCaseRating",
      class: "col_11 " 
    },
  ]

  useEffect(() => {
    if(orgID) {
      getAgentReport();
    }
  }, [dateVal])

  useEffect(() => {
    if(orgID) {
      getAgentReport();
    }
  }, [orgID])

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Agent Reporting", 
        {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  const doMount = async () => {
    //filter
    setDateVal(props.filterData.dateVal);
    setFilterDateForCalender(props.filterData.filterDateForCalender);
    setFilterBy(props.filterData.filterBy);
    //filter

    const paramID = checkID(props.match);

    if (paramID) {
        setOrgID(paramID);
    } else {
        ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
                let responseData = response.dataResult.data;
                if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                const { id, createdTime } = responseData.memberships[0].organization;
                const urlOrgID = `${id}-${createdTime}`;
                props.history.push(`/o/${urlOrgID}/organization/members`);
            } else {
                console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
        });
    }
  };

  const downloadReport = (data) => {
    // const id = orgID.split('-');
    // const timestamp = + new Date();
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `agent-report-data-from-${HelperDate.formatToString(new Date(dateVal.from), "dd-MM-yyyy")}-to-${HelperDate.formatToString(new Date(dateVal.to), "dd-MM-yyyy")}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const exportData = () => {
    let data = {
      startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
      endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : ""
    };

    setIsLoadingExportReport(true);
    
    ReportService.exportAgentReport(orgID, data,
      (response) => {
        setIsLoadingExportReport(false);
        if (!response) {
          // setShowModalFailed(true);
        } else {
          downloadReport(response.dataResult)
        }
      }
    )
  }

  let getAgentReport = (_sort = false, page = false) => {
    setIsWaitingFetchAgentReport(true);
    let _retriveDataError = {...retriveDataError};
    let data = {
      startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
      endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : "",
      pageNumber: !page ? pageDisplay : page,
      pageSize: 50,
      sortBy: !_sort ? sort.by : _sort.by,
      sortOrder: !_sort ? sort.order : _sort.order 
    }

    ReportService.getAgentReport(props.match.params.orgID, data, (response) => {
      setIsWaitingFetchAgentReport(false);
      let dataResult = response.dataResult;

      if (dataResult.error.message === '') {
        setPrintedAgentReportData(dataResult.data.agents);
        setTotalItems(dataResult.data.totalItems);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    });
  };

  let handleChangePage = (page) => {
    setPageDisplay(page)
    
    getAgentReport(false, page);
  }

  let printText = (val) => {
    return val !== "" ? val : "-";
  }

  let onSort = (_sort) => {
    let _s = {
      by: _sort,
      order: _sort === sort.by ? (sort.order === "ASC" ? "DESC" : "ASC") : "ASC"
    }
    
    setSort(_s);
    getAgentReport(_s, false);
  }

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      } else {
        doToast(props.history.location.state.message, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history]);

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="agent-report-wrapper sectionWrap">
        {/* tier blocking */}
        {retriveDataError.code  === "49901" &&
          <RetrieveDataLoading 
            isLoading={isWaitingFetchAgentReport}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
            loadingText={"Loading Report"}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="agent-report-title">
            <b>Agent Report</b>
          </p>

          {isLoadingExportReport ?
            <ButtonWithLoadingOrIcon 
              className="orange-button main-button-40 add-agent-report-button"
              text="Download Report"
              position="left"
              isLoading
              loadingColor="gray"
              isDisabled
            />
            :
            <ButtonWithLoadingOrIcon 
                className="orange-button main-button-40 add-agent-report-button"
                onClickAction={exportData}
                text="Download Report"
                position="left"
                icon={{
                  type: "svg",
                  src: FiDownload
                }}
            />
          }

          <div className="agent-report-data-outer-wrapper">
            <AdvancedDateFilter 
              toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
              isOpenDropdown={isShowDropdownDate}
              dateVal={dateVal}
              filterDateForCalender={filterDateForCalender}
              filterBy={filterBy}
              onClickDate={(date) => setDateVal(date)}
              onClickFilterBy={(filter) => setFilterBy(filter)}
              onClickCalendar={(date) => {
                setFilterDateForCalender(date)
              }}
              ignoreAllTime
              isDisabled={isWaitingFetchAgentReport}
              disabledDays={{
                after: new Date()
              }}
              maxRange={365}
            />
              
            {(isWaitingFetchAgentReport || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
              <RetrieveDataLoading 
                isLoading={isWaitingFetchAgentReport}
                errorMessage={retriveDataError.message}
                errorCode={retriveDataError.code}
              />
              :
              <React.Fragment>
                <div className="agent-report-data-wrapper">
                  {printedAgentReportData.length === 0 ? 
                    <div className="no-agent-report">
                      <img src={NoReportFound} alt="No Report Found" />

                      <p>
                        <b>No report found. </b>
                      </p>
                    </div>
                    :
                    <table>
                      <thead>
                        <tr>
                          {TABLE_HEADER.map((val, idx) => {
                            return (
                              <React.Fragment key={`table-head-${idx}`}>
                                <AscendingDescendingTableHeader 
                                  className={val.class}
                                  fieldName={val.value}
                                  title={val.title}
                                  onClick={() => onSort(val.value)}
                                  sortByVal={sort}
                                />
                              </React.Fragment>
                            )
                          })}
                        </tr>
                      </thead>

                      <tbody>
                          {printedAgentReportData.map((value, key) => {
                            return (
                              <tr key={`agent-report-row-${key}`}>
                                <td className="col_1">
                                  {value.fullName}
                                </td>
                                <td className="col_2">
                                  {value.email}
                                </td>
                                <td className="col_3 text-right">
                                  {printText(value.totalCasesFirstResponded)}
                                </td>
                                <td className="col_4 text-right">
                                  {printText(value.totalCasesClosed)}
                                </td>
                                <td className="col_5 text-right">
                                  {printText(value.totalCasesMarkedAsJunk)}
                                </td>
                                <td className="col_6 text-right">
                                  {printText(value.totalCasesHandedOver)}                              
                                </td>
                                <td className="col_7 text-right">
                                  {printText(value.totalCasesTakenOver)}
                                </td>
                                <td className="col_8 text-right">
                                  {printText(value.totalCasesRated)}
                                </td>
                                <td className="col_9 text-right">
                                  {printText(value.averageFirstResponseWaitDuration)}
                                </td>
                                <td className="col_10 text-right">
                                  {printText(value.averageCaseDuration)}
                                </td>
                                <td className="col_11 text-right">
                                  {printText(value.averageCaseRating)}
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </table>
                  }
                </div>

                <div className="pagination-wrapper">
                {
                    totalItems > 0 &&
                      <Pagination
                        total={totalItems}
                        page={pageDisplay}
                        listPerPage={50}
                        step={1}
                        onChangePage={handleChangePage}
                      />
                }
                </div>
              </React.Fragment>
            }
          </div>   
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default AgentReport;