import React, { Component } from "react";
import "./AccountSettingMyProfileEdit.scss";
import { FiInfo } from "react-icons/fi";
import dummyUserAvatar from "../../../../assets/img/dummy-user-avatar.svg";
import ServiceProfile from "../../../../services/profile/ServiceProfile";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import { checkID, doToast } from "../../../../helper/HelperGeneral";
import HelperInput from "../../../../helper/HelperInput";
import "react-toastify/dist/ReactToastify.css";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
// import iconEditOrange from "../../../../assets/img/icon-edit.svg";

const style = {
  keyStyle: {
    position: "relative",
    top: "-3px"
  },
  lockStyle: {
    position: "relative",
    top: "-2px"
  },
  marginBottom: {
    marginBottom: 0
  },
  divTwoFactor: {
    position: "relative",
    width: "50%",
    display: "inline-block"
  },
  lastWrapper: {
    borderBottom: 0
  }
};

class AccountSettingMyProfileEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
        userImage: dummyUserAvatar,
        loadingEdit: false,
        loadingGetData: true,
        name: "",
        nameOrigin: "",
        email: "",
        phone: "",
        orgData: "",
        contactNumber: "",
        retrieveDataStatus: {
          code: false,
          message: false
        },
        orgID: "",
        errorAccountName: ""
    };
  }

  onChangeInput = (e) => {
    let id = e.target.id;
    let runChange = () => {
      this.setState({
        [e.target.id] : e.target.value,
      })
    }
     
    if(id === "name") {
      if(HelperInput.alphanumericAndSpaceOnly(e.target.value) || e.target.value === "") {
        this.setState({
          [e.target.id] : e.target.value,
          errorAccountName : e.target.value === "" ? "This field is required" : (e.target.value.length > 200 ? "Characters exceed limit" : "")
        })
      }
    }else {
      runChange();
    }
  }

  getUserProfile = () => {
    ServiceProfile.postGetProfile((response) => {
      if(response.dataResult.error.message === "") {
        let accountData = response.dataResult.data.account;
        let indexOrg = response.dataResult.data.memberships.findIndex(val => val.organization.id === parseInt(this.state.orgID));
        this.setState({
          loadingGetData: false,
          orgData: response.dataResult.data.memberships[indexOrg],
          retrieveDataStatus: {
            code: false,
            message: false
          },
          name: accountData.fullName,
          nameOrigin: accountData.fullName,
          email: accountData.email,
          phone: accountData.phone,
          userImage: accountData.imageURL.thumbnail !== "" ? accountData.imageURL.thumbnail : dummyUserAvatar
        })
      }else {
        this.setState({
          retrieveDataStatus: {
            code: response.dataResult.error.code,
            message: response.dataResult.error.message
          }
        })

        console.log(response.dataResult.error.message);
      }
    });
  }

  editUserProfile = () => {
    if(this.state.errorAccountName === "") {
      this.setState({
        loadingEdit: true
      });
  
      let data = {
        fullName: this.state.name
      }
  
      ServiceProfile.postEditProfile(data, (response) => {
        let dataResult = response.dataResult;
  
        if(response.dataResult.error.message === "") {
          if(dataResult.data.success) {
            doToast(dataResult.data.message);
            this.setState({
              nameOrigin: this.state.name
            })
            setTimeout(() => {
              window.location.href = window.location.href.replace("/edit", "");
            }, 1000);
          }else {
            doToast(dataResult.data.message, "fail");
            this.setState({
              loadingEdit: false
            });
          }
        }else {
          this.setState({
            loadingEdit: false
          });
  
          console.log(response.dataResult.error.message);
        }
      });
    }
  }

  doMount() {
    // setUserData(HelperCustomEncryptor.doDecrypt(props.user.data));
    const paramID = checkID(this.props.match);
    if (paramID) {
      this.setState({
        orgID: paramID
      });
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          this.props.history.push(`/o/${urlOrgID}/account/security`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  
  componentDidMount() {
    this.doMount();
    this.getUserProfile();
  }

  isActiveOrganizationCheck(orgID) {
    let orgName = "";
    if (this.state.orgID) {
      let matchParam = this.state.orgID;
      
      return matchParam.split("-")[0] === orgID.toString();
    }
    
    return orgName;
  }

  back(props) { 
    props.history.push(`/o/${props.match.params.orgID}/account/profile`);
  }

  isDisabled() {
    let pass = false;
    let _data = {...this.state};
    if(_data.name === "" || _data.nameOrigin === _data.name) {
      pass = true;
    }

    return pass;
  }

  render() {
    return (
      <React.Fragment>
        <SectionWrap
          {...this.props}
          orgID={this.state.orgID}
          withHeader
          withSideMenu
          dataOrigin={{name: this.state.nameOrigin}}
          dataAfterChange={{name: this.state.name}}
        >
            <BackButton text="Back to Profile" onClick={() => this.back(this.props)} />
            
            <div className="sectionWrap">
              <div id="page-account-my-profile-edit">
                {(this.state.loadingGetData || this.state.retrieveDataStatus.code) ?
                  <RetrieveDataLoading 
                    isLoading={this.state.loadingGetData}
                    errorMessage={this.state.retrieveDataStatus.message} 
                  />
                  :
                  <React.Fragment>
                    <div className="account-setting-card">
                      <div className="account-setting-card-title">
                        <b>Edit Profile</b>

                        <div className="my-profile-input account-setting-save-wrapper" style={style.lastWrapper}>
                          {/* <button className="orange-button" onClick={this.toggleModalEditProfile}>
                            Save Changes
                          </button> */}
                          {this.state.loadingEdit ?
                            <ButtonWithLoadingOrIcon
                              className="orange-button main-button-48 edit-profile-button"
                              text="Save Changes"
                              isLoading={true}
                              loadingColor="gray"
                              position="left"
                              isDisabled
                           />
                           :
                           <ButtonWithLoadingOrIcon
                            className="orange-button main-button-48 edit-profile-button"
                            text="Save Changes"
                            onClickAction={this.editUserProfile}
                            // isDisabled={this.isDisabled()}
                          />
                          }
                        </div>
                      </div>

                      <div className="my-profile-input" style={style.lastWrapper}>
                          {/* <div className="profile-avatar-wrapper">
                              <img src={this.state.userImage} alt="" />

                              <input type="file" id="profile-avatar-file" />
                              
                              <label htmlFor="profile-avatar-file" className="no-fill-button">
                                <img src={iconEditOrange} alt="" />
                                <b>
                                  Change Photo
                                </b>
                              </label>
                          </div> */}

                          <div className="my-profile-input form-content-wrapper">
                                <label><b>Account Name</b></label>
                                <span className={`input-counter ${this.state.name.length > 200 ? "red-text" : ""}`}>
                                  {this.state.name.length}/200
                                </span>
                                <input 
                                    type="text" 
                                    placeholder="Your account Name" 
                                    value={this.state.name}
                                    id="name"
                                    name="name"
                                    onChange={this.onChangeInput}
                                    disabled={this.state.loadingEdit}
                                    className={this.state.errorAccountName !== "" || this.state.name.length > 200 ? "border-red" : ""}
                                />
                                {this.state.errorAccountName !== "" &&
                                  <ErrorOrInfoComp
                                    text={this.state.errorAccountName}
                                    _className={"font-red"}
                                    icon={<FiInfo />}
                                  />
                                }
                          </div>

                          <div className="my-profile-input form-content-wrapper">
                                <label><b>Email</b></label>
                                <input type="text" 
                                    placeholder="YOur email" 
                                    value={this.state.email}
                                    id="email"
                                    name="email"
                                    disabled
                                    onChange={this.onChangeInput}
                                />
                          </div>

                          <div className="my-profile-input form-content-wrapper">
                                <label><b>Phone Number</b></label>
                                <input type="text" 
                                    placeholder="Your phone number" 
                                    value={this.state.phone === "" ? "-" : "+"+HelperInput.phoneBeautify(this.state.phone)}
                                    id="phone"
                                    name="phone"
                                    disabled
                                    onChange={this.onChangeInput}
                                />
                          </div>
                      </div>
                    </div>
                  </React.Fragment>
                }
              </div>
            </div>
        </SectionWrap>
      </React.Fragment>
    );
  }
}

export default AccountSettingMyProfileEdit;