import React, { useState, useEffect, useRef, useCallback } from 'react';
import './RoomListGlobalSearchRoom.scss';
import { FiRefreshCw } from 'react-icons/fi';
import { connect } from 'react-redux';
import { taptalk } from '@taptalk.io/web-sdk';
// import HelperChat from '../../../../helper/HelperChat';
import HelperDate from '../../../../helper/HelperDate';
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import { doToast, getItemFromArray, generateElipsis } from '../../../../helper/HelperGeneral';
import CaseService from "../../../../services/chat/caseServices";
// import OtherAgentIcon from "../../../../assets/img/roomlist/icon-other-agent.svg";
import { VariableSizeList as List } from 'react-window';
import { clearUpdateCaseLabel } from "../../../../redux/actions/reduxActionCaseLabel";
import { clearChangeContactInfo } from "../../../../redux/actions/reduxActionChangeUserAlias";
import { clearStartConversation } from '../../../../redux/actions/reduxActionStartConversation';
import { clearStartConversationBa } from '../../../../redux/actions/reduxActionStartConversationBa';
// import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
// import { CASE_TYPES } from '../../../../constants/inbox';
import CaseListComponent from "../../roomListTab/caseListComponent/CaseListComponent";
import CaseListAction from "../../roomListTab/caseListAction/CaseListAction";
import mixpanel from "mixpanel-browser";

// const CONNECTING_STATUS = {
//     disconnected: 1,
//     loading: 2,
//     connected: 3
// };

const MESSAGE_TYPE = {
    CASE_CREATED: 3005,
    CASE_CLOSED: 3001,
    CASE_REOPEN: 3002,
    CASE_RATING_BUTTON: 3003,
    CASE_RATING_BUTTON_DISABLED: 3004,
    CASE_UPDATE_AGENT_CHANGE: 3006,
    CASE_UPDATE_DETAIL_UPDATE: 3007
}

const DEFAULT_CELL_HEIGHT = 92;

// const INITIAL_CASE_PARAM = {
//     topicID: 0,
//     agentAccountID: 0,
//     mediums: [],
//     junkStatus: "",
//     search: "",
//     labelIDs: [],
//     startDate: "",
//     endDate: "",
//     lastID: 0,
//     pageSize: 50
// };

//print
let ListCaseComponent = (props) => {
    let { 
        caseLabel, dataLength, containerHeight, row,
        mainProps, fetchingCaseIsErrorProps, fetchingCaseProps,
        onClickRetryMoreCaseProps, onScrollRoomListListenerProps, refProps,
        needReplyData
    } = props;

    let arrayOfRowHeight = [];

    let generateRowHeight = () => {
        arrayOfRowHeight = [];

        Object.keys(props.case).map((value) => {
            if (props.case[value].labelIDs && props.case[value].labelIDs.length > 0) {
                // arrayOfRowHeight.push(121);
                if(props.case[value].assigneeType === "chatbot") {
                    // arrayOfRowHeight.push(183);
                    arrayOfRowHeight.push(154);
                }else {
                    // arrayOfRowHeight.push(181);
                    arrayOfRowHeight.push(154);
                }
            } else {
                if(props.case[value].assigneeType === "chatbot") {
                    // arrayOfRowHeight.push(156);
                    arrayOfRowHeight.push(127);
                }else {
                    if(props.case[value].agentAccountID === 0) {
                        // arrayOfRowHeight.push(156);
                        arrayOfRowHeight.push(127);
                    }else {
                        // arrayOfRowHeight.push(154);
                        arrayOfRowHeight.push(127);
                    }
                }
            }


            return null;
        })
    }

    generateRowHeight();

    const getItemSize = (index) => {
        return arrayOfRowHeight[index];
    }

    let arrayOfLabel = [];

    const generateLabelCase = () => {
        Object.keys(props.case).map((value, index) => {
            if (props.case[value].labelIDs && caseLabel) {
                if (props.case[value].labelIDs.length > 0) {
                    if (caseLabel.length > 0) {
                        const labels = caseLabel.filter((el) => {
                            return props.case[value].labelIDs.find(val => val === el.id)
                        });

                        const labelCase = [...labels]
                        // console.log('labelCase', labelCase)
                        arrayOfLabel.push([...Array(labelCase.length).fill(false)])
                        // arrayOfLabel.push(labelCase)
                        // setHiddenUnassigned(labelCase)
                    }
                }
            }
            return null
        })
    }
    
    generateLabelCase()

    return (
        <List
            className="List all-case-list list-search-case last-case-no-margin-bottom"
            height={containerHeight}
            itemCount={dataLength}
            itemSize={getItemSize}
            width={"100%"}
            onScroll={onScrollRoomListListenerProps}
            ref={refProps}
            itemData={{
                caseData: props.case,
                caseLabel: caseLabel,
                labelHidden: arrayOfLabel,
                mainProps: mainProps,
                _fetchingCaseIsErrorProps: fetchingCaseIsErrorProps,
                _fetchingCaseProps: fetchingCaseProps,
                _onClickRetryMoreCaseProps: onClickRetryMoreCaseProps,
                _refProps: refProps,
                _needReplyData: needReplyData
            }}
        >
            {row}
        </List>
    )
};

const getLabelCase = (labelIDs, labelList) => {
    if (labelIDs && labelList) {
        if (labelIDs.length > 0) {
            if (labelList.length > 0) {
                let newLabel = [];

                labelIDs.map((v) => {
                    if(getItemFromArray(labelList, "id", v)) {
                        newLabel.push(getItemFromArray(labelList, "id", v))
                    }else {
                        newLabel.push({name: "", id: ""})
                    }
                    return null;
                })
                
                const labelCase = [...newLabel];
                return labelCase;
            }
        }
    }
    return []
}

const LabelItem = ({ label, container, index, isItemHidden, setIsItemHidden }) => {
    const itemRef = useRef();
    
    // useEffect(() => {
    //     if (!container.current) return;

    //     const { offsetLeft, offsetWidth } = itemRef.current;

    //     if (offsetLeft + offsetWidth > container.current.offsetWidth) {

    //         const copyIsItemHidden = [...isItemHidden];
    //         copyIsItemHidden[index] = true;
    //         setIsItemHidden(copyIsItemHidden);
    //     }
    // }, [index, container, isItemHidden, setIsItemHidden]);

    return (
        <div key={`label-${label.id}`} className="case-tab-label-content" style={{ background: label.backgroundColor, display: isItemHidden[index] ? "none" : "inline-block" }} ref={itemRef} >
            <b>{generateElipsis(label.name, 13)}</b>
        </div>
    );
};

let printRowCaseList = ({ index, style, data }) => {
    let myAgentAccountID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
    let caseDetail = data.caseData[Object.keys(data.caseData)[index]];
    let message = data.caseData[Object.keys(data.caseData)[index]].tapTalkRoom;
    let _props = data.mainProps;
    let _needReplyData = data._needReplyData;

    let onClickCaseRoom = (room, caseData) => {
        _props.clearStartConversation();
        _props.clearStartConversationBa();
        _props.onClickRoomListProps(room, caseData)
    }

    const containerRef = useRef()

    const labelCase = getLabelCase(caseDetail.labelIDs, data.caseLabel)

    const hidden = data.labelHidden[index] ? data.labelHidden[index] : []

    const [isItemHidden, setIsItemHidden] = useState([...hidden])

    // useEffect(() => {
    //     setIsItemHidden(hidden)
    // }, [data.labelHidden])

    const totalHidden = () => {
        let totalHiddenItem = 0;
        if (isItemHidden) {
            for (let hideItem of isItemHidden) {
                if (hideItem === true) totalHiddenItem++;
            }
        }
        return totalHiddenItem;
    };

    return (
        <div style={style}>
            <CaseListComponent 
                caseDetail={caseDetail}
                roomType={"search-global"}
                message={message}
                myAgentAccountID={myAgentAccountID}
                labelCase={labelCase}
                totalHidden={totalHidden()}
                _containerRef={containerRef}
                LabelItem={LabelItem}
                isItemHidden={isItemHidden}
                setIsItemHidden={setIsItemHidden}
                onClickCase={onClickCaseRoom}
                _className={"main-case-wrapper"}
                checkedType="globalSearch"
                allCase={data.caseData}
                needReplyData={_needReplyData}
            />
            {/* <div 
                className={`chat-list-wrapper main-case-wrapper
                            ${(_props.parentProps.activeRoom !== null) && (message.lastMessage.room.roomID === _props.parentProps.activeRoom.roomID) ?
                                "active-chat-list"
                                :
                                ""
                            }
                            ${(caseDetail.agentAccountID === 0) && (!caseDetail.isClosed) ?
                                'chat-list-unassigned'
                                :
                                ''
                            } 
                `}
                data-room-id={message.lastMessage.room.roomID}
                key={`chat-list-${message.lastMessage.localID}`}
                onClick={() => onClickCaseRoom(message.lastMessage.room, caseDetail)}
            >
                <div className="chat-avatar-wrapper">
                    {HelperChat.generateIconOmnichannel(caseDetail) === "" ?
                        ""
                        :
                        <img src={HelperChat.generateIconOmnichannel(caseDetail)} alt="" className="group-badge" />
                    }
                </div>
                <div className="dialog-message-wrapper">
                    <div className="dialog-top">
                        <p className="room-list-room-name">
                            <b>{caseDetail.userAlias ?
                                caseDetail.userAlias !== "" ? `${caseDetail.userAlias} (${caseDetail.userFullName})` : caseDetail.userFullName
                                :
                                caseDetail.userFullName
                            }
                            </b>
                        </p>

                        <span className="dialog-date font-root-medium">
                            {HelperDate.isToday(message.lastMessage.created) ?
                                HelperDate.formatToString(new Date(message.lastMessage.created), "HH:mm")
                                :
                                HelperDate.isYerterday(message.lastMessage.created) ?
                                    "Yesterday"
                                    :
                                    HelperDate.formatToString(new Date(message.lastMessage.created), "dd/MM/yyyy")
                            }
                        </span>
                    </div>
                    <div className="dialog-bottom">
                        <p className="chat-room-room-name">
                            {caseDetail.topicName} (#{caseDetail.stringID})
                        </p>

                        <p className="last-message-room-list" dangerouslySetInnerHTML={ {__html: HelperChat.generateLastMessage(message.lastMessage)} } />

                        <p className="typing-room-list">
                            Typing<span className="typing-dot-one">.</span><span className="typing-dot-two">.</span><span className="typing-dot-three">.</span>
                        </p>

                        <div className="message-status">
                            {(caseDetail.isJunk) &&
                                <span className={`need-reply-badge  ${(_props.parentProps.activeRoom !== null) && (message.lastMessage.room.roomID === _props.parentProps.activeRoom.roomID) ? "white-junk-badge" : ""}`}>
                                    <FiAlertTriangle />
                                    Junk
                                </span>
                            }
                        </div>
                    </div>

                    <div className="case-tab-label-wrapper">
                        <div className="label-container" ref={containerRef}>
                            {
                                labelCase.length > 0 &&
                                labelCase.map((label, index) => {
                                    return (
                                        <LabelItem
                                            key={label.id}
                                            index={index}
                                            label={label}
                                            container={containerRef}
                                            isItemHidden={isItemHidden}
                                            setIsItemHidden={setIsItemHidden}
                                        />
                                    )
                                })
                            }
                        </div>

                        {totalHidden() === 0 ?
                            null
                            :
                            <span className="more-label"><b>+{totalHidden()}</b></span>
                        }

                    </div>

                    {((caseDetail.agentAccountID !== 0) && (caseDetail.agentAccountID !== myAgentAccountID)) &&
                        <div className="agent-badge-wrapper">
                            <div className="agent-badge other-agent-badge">
                                <img src={OtherAgentIcon} alt="" />
                                {caseDetail.agentFullName}
                            </div>
                        </div>
                    }

                    {((caseDetail.agentAccountID !== 0) && (caseDetail.agentAccountID === myAgentAccountID)) &&
                        <div className="agent-badge-wrapper">
                            <div className="agent-badge my-agent-badge">
                                <img src={OtherAgentIcon} alt="" />
                                Me
                            </div>
                        </div>
                    }

                    {caseDetail.agentAccountID === 0 &&
                        <div className="agent-badge-wrapper">
                            <div className="agent-badge unassigned-agent-badge">
                                <img src={OtherAgentIcon} alt="" />
                                Unassigned
                            </div>
                        </div>
                    }

                    {(!caseDetail.isClosed) &&
                        <div className="agent-badge-wrapper">
                            <div className="agent-badge my-agent-badge">
                                <FiCircle />
                                Open
                            </div>
                        </div>
                    }

                    {caseDetail.isClosed &&
                        <div className="agent-badge-wrapper">
                            <div className="agent-badge resolved-agent-badge">
                                <FiCheckCircle />
                                Resolved
                            </div>
                        </div>
                    }
                </div>
            </div> */}

            <React.Fragment>
                {data._fetchingCaseProps &&
                    <div className="fetch-solved-case">
                        <div className="fetch-solved-case-content">
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>

                            <b>More Cases</b>
                        </div>
                    </div>
                }

                {data._fetchingCaseIsErrorProps &&
                    <div className="fetch-solved-case">
                        <div className="fetch-solved-case-content no-fill-button retry-fetch"
                            onClick={() => data._onClickRetryMoreCaseProps()}
                        >
                            <FiRefreshCw />

                            <b>Retry Fetch</b>
                        </div>
                    </div>
                }
            </React.Fragment>
        </div>
    )
}
//print

var RoomListGlobalSearchRoom = (props) => {
    let [chatDataFiltered, setChatDataFiltered] = useState({});
    let [searchNotFound, setSearchNotFound] = useState(false);
    let [fetchingAllCase, setFetchingAllCase] = useState(false);
    let [fetchingCase, setFetchingCase] = useState(false);
    let [fetchingCaseIsError, setFetchingCaseIsError] = useState(false);
    let [hasMoreCase, setHasMoreCase] = useState(false);
    let [panelCaseHeight, setPanelCaseHeight] = useState(0);
    let [needReplyDataSearch, setNeedReplyDataSearch] = useState({});
    let [hashMapTypingTimeout, setHashMapTypingTimeout] = useState({});

    useEffect(() => {
        if(props.lastReset) {
            setChatDataFiltered({});
            props.runSetLastReset();
        }
    }, [props.lastReset])

    // listener
    useEffect(() => {
        if (props.newEmitMessageProps !== null) {
            if (!fetchingAllCase && !fetchingCase && Object.keys(chatDataFiltered).length > 0) {
                actionUpdateRoomList(props.newEmitMessageProps);
            }
        }
    }, [props.newEmitMessageProps])

    useEffect(() => {
        if(props.messageListenerNewMessageProps !== null && Object.keys(chatDataFiltered).length > 0) {
            let newMessage = props.messageListenerNewMessageProps;
            // console.log('new', newMessage);

            if(!fetchingAllCase && chatDataFiltered[newMessage.room.roomID]) {
                actionUpdateRoomList(newMessage);
            }
        }
    }, [props.messageListenerNewMessageProps]);

    useEffect(() => {
       if(props.messageListenerUpdateMessageProps !== null && Object.keys(chatDataFiltered).length > 0) {
            let updateMessage = props.messageListenerUpdateMessageProps;
            // console.log('updet', updateMessage);

            if(!fetchingAllCase && chatDataFiltered[updateMessage.room.roomID] ) {
                actionUpdateRoomList(updateMessage);
            }
       }
    }, [props.messageListenerUpdateMessageProps])

    useEffect(() => {
        let startTyping = props.listenerStartTypingProps;

        if (startTyping !== null) {
            runActionTypingFromRoomList(startTyping.roomID, 'show', startTyping.user.fullname);

            let _hashMapTypingTimeout = { ...hashMapTypingTimeout };

            if (_hashMapTypingTimeout[startTyping.roomID]) {
                clearTimeout(_hashMapTypingTimeout[startTyping.roomID]);
            }

            _hashMapTypingTimeout[startTyping.roomID] = setTimeout(function () {
                runActionTypingFromRoomList(startTyping.roomID, 'hide');
            }, 10000);

            setHashMapTypingTimeout(_hashMapTypingTimeout);
        }
    }, [props.listenerStartTypingProps])

    useEffect(() => {
        let stopTyping = props.listenerStopTypingProps;

        if (stopTyping !== null) {
            runActionTypingFromRoomList(stopTyping.roomID, 'hide');
            clearTimeout(hashMapTypingTimeout[stopTyping.roomID]);
        }

    }, [props.listenerStopTypingProps])

    let runActionTypingFromRoomList = (roomID, action, name) => {
        let targetChatListWrapper = document.querySelector(`.new-case-list-wrapper[data-room-id="${roomID}"]`);

        if (targetChatListWrapper !== null) {
            if (action === 'hide') {
                targetChatListWrapper.classList.remove("active-typing");
            } else {
                targetChatListWrapper.classList.add("active-typing");

                if(targetChatListWrapper.getElementsByClassName("typing-name").length > 0) {
                    targetChatListWrapper.getElementsByClassName("typing-name")[0].innerHTML = name !== "" ? name+": " : "";
                }
            }
        }
    }

    // listener

    //get case
    let actionUpdateRoomList = (message) => {
        let _chatDataFiltered = {...chatDataFiltered};
        
        if(
            message.type === MESSAGE_TYPE.CASE_CLOSED ||
            message.type === MESSAGE_TYPE.CASE_REOPEN ||
            message.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE ||
            message.type === MESSAGE_TYPE.CASE_UPDATE_DETAIL_UPDATE
        ) {
            let currentRoom = {..._chatDataFiltered[message.room.roomID]}
            let messageData = {...message.data};
            _chatDataFiltered[message.room.roomID] = messageData;
            _chatDataFiltered[message.room.roomID].tapTalkRoom = currentRoom.tapTalkRoom;
            setChatDataFiltered(_chatDataFiltered);
        }
    }

    let caseRef = useCallback(node => {
        if (node !== null) {
            node.resetAfterIndex(0);
        }
    }, [chatDataFiltered]);

    useEffect(() => {
        setSearchNotFound(false);
        setChatDataFiltered({});
    }, [props.globalSearch.show]) 

    useEffect(() => {
        setSearchNotFound(false);
        
        if(props.lastSearch) {
            getCaseList({
                topicID: props.filterTopicValue.value === 0 ? 0 : props.filterTopicValue.value,
                agentAccountID: props.filterAgentValue.value === 0 ? 0 : props.filterAgentValue.value,
                mediums: props.filterMediumValue,
                search: props.filterSearchValue,
                startDate: props.filterDateValue.from ? HelperDate.formatToString(new Date(props.filterDateValue.from), 'yyyy-MM-dd') : "",
                endDate: props.filterDateValue.to ? HelperDate.formatToString(new Date(props.filterDateValue.to), 'yyyy-MM-dd') : "",
                // lastID: Object.keys(chatDataFiltered).length !== 0 ? chatDataFiltered[Object.keys(chatDataFiltered)[Object.keys(chatDataFiltered).length - 1]].id : 0,
                lastID: 0,
                isSearchOrFilter: true,
                isReconnect: false,
                isLoadMore: true,
                labelIDs: props.filterLabelValue,
                junkStatus: props.filterCaseTypeValue,
                pageSize: 50
            });
        }
    }, [props.lastSearch])

    useEffect(() => {
        if(props.caseListChecked["globalSearch"].isShow) {
            actionSetPanelCaseResolvedHeight(true);
        }
    }, [props.caseListChecked])

    useEffect(() => {
        window.addEventListener("resize", () => {
            actionSetPanelCaseResolvedHeight(props.caseListChecked["globalSearch"].isShow);
        })

        actionSetPanelCaseResolvedHeight(props.caseListChecked["globalSearch"].isShow);
    }, [])

    // useEffect(() => {
    //     let _connectStatus = props.connectingStatusProps;
    //     let elOpenCaseTabWrapper = document.querySelectorAll(".room-list-global-search-wrapper")[0];

    //     if (elOpenCaseTabWrapper) {
    //         if (_connectStatus === CONNECTING_STATUS.disconnected || _connectStatus === CONNECTING_STATUS.loading) {
    //             // elOpenCaseTabWrapper.style.height = "calc(100vh - 135px)";
    //             elOpenCaseTabWrapper.style.height = "calc(100vh - 79px)";
    //         } else {
    //             // elOpenCaseTabWrapper.style.height = "calc(100vh - 109px)";
    //             elOpenCaseTabWrapper.style.height = "calc(100vh - 53px)";
    //         }

    //         actionSetPanelCaseResolvedHeight();
    //     }
    // }, [props.connectingStatusProps])

    let actionSetPanelCaseResolvedHeight = (activeSelect) => {
        let elCaseTabWrapper = document.querySelectorAll(".room-list-global-search-wrapper")[0];

        if (elCaseTabWrapper) {
            // let defaultHeight = elCaseTabWrapper.offsetHeight - 90;
            // 197

            let defaultHeight = 0;

            if(activeSelect) {
                defaultHeight = elCaseTabWrapper.offsetHeight - 116;
            }else {
                defaultHeight = elCaseTabWrapper.offsetHeight - 90;
            }

            setPanelCaseHeight(defaultHeight);
        }
    }

    let getCaseList = (data, fetchAll = true) => {
        setFetchingAllCase(fetchAll);

        let dataLabel = [];

        if (data.labelIDs && data.labelIDs.length > 0) {
            dataLabel = data.labelIDs.map(item => item.id)
        }

        //   let today = new Date().valueOf();
        //   let last30Day = new Date().valueOf() - 2592000000;

        let _data = {
            topicID: data.topicID,
            agentAccountID: data.agentAccountID,
            search: data.search,
            startDate: data.startDate,
            junkStatus: data.junkStatus,
            endDate: data.endDate,
            lastID: data.lastID,
            // if page = 1, lastID = 0
            pageSize: 50,
            mediums: data.mediums,
            labelIDs: dataLabel
        }
        
        CaseService.postAllCaseList(props.match.params.orgID, _data, (response) => {
            setFetchingAllCase(false);
            setFetchingCase(false);

            if (response.dataResult.error.code === "") {
                if (data.isSearchOrFilter) {
                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                            "[Action] Search",
                            {
                                tab: "Resolved",
                                userFullName: myAgentData.account.fullName,
                                userID: myAgentData.account.id,
                                organizationID: props.parentProps.match.params.orgID.split("-")[0]
                            }
                        )
                    }
                }

                let resultCase = response.dataResult.data.cases;
                let caseData = {};

                for (let i in resultCase) {
                    let body = resultCase[i].tapTalkRoom.lastMessage.body;
                    let localID = resultCase[i].tapTalkRoom.lastMessage.localID;
                    let data = resultCase[i].tapTalkRoom.lastMessage.data;

                    resultCase[i].tapTalkRoom.lastMessage.body = taptalk.generateBodyAndData(body, localID);

                    if (data !== "") {
                        resultCase[i].tapTalkRoom.lastMessage.data = JSON.parse(taptalk.generateBodyAndData(data, localID));
                    }

                    caseData[resultCase[i].tapTalkRoom.lastMessage.room.roomID] = resultCase[i];
                }

                setHasMoreCase(response.dataResult.data.hasMore);

                if (data.isSearchOrFilter) {
                    if (resultCase.length === 0) {
                        setSearchNotFound(true);
                    }

                    setChatDataFiltered(caseData);
                } else {
                    if (data.isLoadMore) {
                        let currentCaseList = { ...chatDataFiltered };

                        currentCaseList = Object.assign(currentCaseList, caseData);
                        setChatDataFiltered(currentCaseList);
                    } else {
                        setChatDataFiltered(caseData);
                    }
                }
            } else {
                doToast(response.dataResult.error.message, 'fail');
                if (data.isLoadMore) {
                    setFetchingCaseIsError(true);
                }
            }
        })
    }

    let runMoreCase = () => {
        if (hasMoreCase && Object.keys(chatDataFiltered).length > 0) {
            setFetchingCase(true);

            let _chatDataFiltered = { ...chatDataFiltered };

            getCaseList({
                topicID: props.filterTopicValue.value === 0 ? 0 : props.filterTopicValue.value,
                agentAccountID: props.filterAgentValue.value === 0 ? 0 : props.filterAgentValue.value,
                mediums: props.filterMediumValue,
                search: props.filterSearchValue,
                startDate: props.filterDateValue.from ? HelperDate.formatToString(new Date(props.filterDateValue.from), 'yyyy-MM-dd') : "",
                endDate: props.filterDateValue.to ? HelperDate.formatToString(new Date(props.filterDateValue.to), 'yyyy-MM-dd') : "",
                lastID: _chatDataFiltered[Object.keys(_chatDataFiltered)[Object.keys(_chatDataFiltered).length - 1]].id,
                isSearchOrFilter: true,
                isReconnect: false,
                isLoadMore: true,
                labelIDs: props.filterLabelValue,
                junkStatus: props.filterCaseTypeValue,
                pageSize: 50
            }, false)
        }
    }

    let onScrollRoomListListener = () => {
        let el = document.getElementsByClassName("list-search-case")[0];
        if (el && !fetchingCase && !fetchingCaseIsError) {
            if ((el.scrollHeight - el.scrollTop - DEFAULT_CELL_HEIGHT) <= el.clientHeight) {
                runMoreCase();
            }
        }
    };

    let onClickRetryMoreCase = () => {
        runMoreCase();
    }
    //get case

    // search & no data
    let OnSearch = () => (
        <div className="search-for-result">
            <div className="lds-ring">
                <div /><div /><div /><div />
            </div>

            <b>Search for Results</b>
        </div>
    )

    let OnNotFound = () => (
        <div className="search-not-found">
            <b>
                No Result Were Found
            </b>

            <p>
                We can’t find any item matching your search, please try again
            </p>
        </div>
    )
    // search & no data

    return (
        <>
            {props.caseListChecked["globalSearch"].isShow &&
                <CaseListAction 
                    data={chatDataFiltered}
                    type="globalSearch"
                    orgID={props.parentProps.match.params.orgID}
                    caseLength={Object.keys(chatDataFiltered).length}
                />
            }

            <div className="room-list-global-search-room-list">
                <div className={`room-list-global-search-room-wrapper room-list-chat-container`}>
                    {fetchingAllCase ?
                        <OnSearch />
                        :
                        searchNotFound ?
                            <OnNotFound />
                            :
                            <div className="room-list-main-chat-container">
                                {Object.keys(chatDataFiltered).length > 0 &&
                                    <>
                                        <ListCaseComponent 
                                            case={chatDataFiltered}
                                            caseLabel={props.caseLabelList}
                                            row={printRowCaseList}
                                            dataLength={Object.keys(chatDataFiltered).length}
                                            containerHeight={panelCaseHeight}
                                            mainProps={props}
                                            needReplyProps={Object.keys(needReplyDataSearch).length}
                                            needReplyData={needReplyDataSearch}
                                            fetchingCaseProps={fetchingCase}
                                            fetchingCaseIsErrorProps={fetchingCaseIsError}
                                            onClickRetryMoreCaseProps={onClickRetryMoreCase}
                                            onScrollRoomListListenerProps={onScrollRoomListListener}
                                            refProps={caseRef}
                                        />
                                    
                                        {Object.keys(needReplyDataSearch).length > 0 &&
                                            <div className="need-reply-unread-panel-wrapper">
                                                <div className="need-reply-unread-panel-content">
                                                    <div className="need-reply-panel">
                                                        <b>Need Reply</b> <div className="need-reply-unread-panel-counter"><b>{Object.keys(needReplyDataSearch).length}</b></div>
                                                    </div>

                                                    {/* <div className="unread-panel">
                                                        <b>Unread</b> <div className="need-reply-unread-panel-counter"><b><b>{totalUnreadCountMine}</b></b></div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                    }
                </div>
            </div>
        </>
    );
}

const mapStateToProps = state => ({
    // activeCasePanel: state.activeCasePanel
    // globalSearch: state.globalSearch
    globalSearch: state.globalSearch,
    caseListChecked: state.caseListChecked,
    needReplyColorIndicator: state.needReplyColorIndicator,
});

const mapDispatchToProps = {
    clearUpdateCaseLabel,
    clearChangeContactInfo,
    clearStartConversation,
    clearStartConversationBa
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomListGlobalSearchRoom);