import React, { Component } from 'react';
import '../Auth.css';
import './ForgotPasswordEmailSent.css';
import Logo from "../../../assets/img/logo-taptalk-white.svg";
import PaperPlaneLeft from "../../../assets/img/auth/img-paper-plane-left.svg";
import PaperPlaneRight from "../../../assets/img/auth/img-paper-plane-right.svg";
import IconFacebookMessenger from "../../../assets/img/auth/icon-fb-messenger-circle.svg";
import IconGoogle from "../../../assets/img/auth/icon-google-circle.svg";
import IconGoogleBusinessProfile from "../../../assets/img/auth/icon-gb-profile-circle.svg";
import IconInstagram from "../../../assets/img/auth/icon-instagram-circle.svg";
import IconMail from "../../../assets/img/auth/icon-mail-circle.svg";
import IconTelegram from "../../../assets/img/auth/icon-telegram-circle.svg";
import IconTokopedia from "../../../assets/img/auth/icon-tokopedia-circle.svg";
import IconWhatsAppSME from "../../../assets/img/auth/icon-whatsapp-circle.svg";
import IconWhatsAppBA from "../../../assets/img/auth/icon-whatsapp-ba-circle.svg";
import { Link } from "react-router-dom";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiArrowLeft, FiCheck, FiX } from 'react-icons/fi';

class ForgotPasswordEmailSent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isError: false
        }; 
    }

    componentDidMount() {
        try {
            this.setState({
                isError: this.props.location.state.isError
            })
        }
        catch(err) {
            this.props.history.push('');
        }
    }

  render() {
    return (
        <div id="outer-container">

            <img src={Logo} alt="TapTalk Logo" className="logo-image" />
            <p className="subtitle">Omnichannel Chat Platform</p>

            <img alt="" src={PaperPlaneLeft} className="bg-img-paper-plane-left" />
            <img alt="" src={PaperPlaneRight} className="bg-img-paper-plane-right" />
            <img alt="" src={IconWhatsAppSME} className="bg-img-whatsapp-sme" />
            <img alt="" src={IconTelegram} className="bg-img-telegram" />
            <img alt="" src={IconGoogleBusinessProfile} className="bg-img-gb-profile" />
            <img alt="" src={IconGoogle} className="bg-img-google" />
            <img alt="" src={IconInstagram} className="bg-img-instagram" />
            <img alt="" src={IconFacebookMessenger} className="bg-img-fb-messenger" />
            <img alt="" src={IconMail} className="bg-img-mail" />
            <img alt="" src={IconTokopedia} className="bg-img-tokopedia" />
            <img alt="" src={IconWhatsAppBA} className="bg-img-whatsapp-ba" />

            <div id="verification-wrapper">
                {
                    this.state.isError ?
                    <>
                        <div className="verification-status-failed">
                            <FiX />
                        </div>

                        <div className="verification-title">
                            <b>Email Address is Invalid</b>
                        </div>

                        <div className="verification-description">
                            The email address you have entered has not been verified. Please make sure you enter the registered account.
                        </div>

                        <div style={{marginTop: "32px"}}>
                            <Link to="/">
                                <ButtonWithLoadingOrIcon
                                    className="orange-button main-button-48 width-100"
                                    text="Back to Log In"
                                    position="left"
                                    icon={{
                                        type: "svg",
                                        src: FiArrowLeft
                                    }}
                                />
                            </Link>
                        </div>
                    </>
                    :
                    <>
                        <div className="verification-status-success">
                            <FiCheck />
                        </div>
        
                        <div className="verification-title">
                            <b>Verification Email Sent</b>
                        </div>
        
                        <div className="verification-description">
                            Please check your inbox with the registered email address and verify your account.
                        </div>
                        
                        <div style={{marginTop: "32px"}}>
                            <Link to="/">
                                <ButtonWithLoadingOrIcon
                                    className="orange-button main-button-48 width-100"
                                    text="Got it Thanks!"
                                />
                            </Link>
                        </div>
                    </>
                }
            </div>
        </div>
    );
  }
}

export default ForgotPasswordEmailSent;
