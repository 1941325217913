import React, { useState, useEffect, useRef } from 'react';
import './RoomListGlobalSearch.scss';
import { FiX, FiSliders, FiRotateCcw, FiCalendar } from "react-icons/fi";
import { connect } from 'react-redux';
import { setGlobalSearch } from '../../../redux/actions/reduxActionGlobalSearch';
import FilterBy from "../roomListTab/modalFilter/filterBy/FilterBy";
import HelperDate from '../../../helper/HelperDate';
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
// import { doToast } from "../../../helper/HelperGeneral";
import CalendarPicker from "../../reuseableComponent/calendarPicker/CalendarPicker";
import RoomListGlobalSearchRoom from './roomListGlobalSearchRoom/RoomListGlobalSearchRoom';
// import CaseLabelService from '../../../services/newServices/CaseLabelService';
// import CaseService from "../../../services/chat/caseServices";
import { CASE_TYPES } from '../../../constants/inbox';
import mixpanel from "mixpanel-browser";


const DEFAULT_FILTER_TOPIC_AGENT = {
    value: 0,
    label: ""
}

var RoomListGlobalSearch = (props) => {
    let [searchVal, setSearchVal] = useState("");
    // let [searchValOrigin, setSearchValOrigin] = useState("");
    let [lastReset, setLastReset] = useState(false);
    let [lastSearch, setLastSearch] = useState(false);
    let [isFilterActive, setIsFilterActive] = useState(false);
    // let [caseLabelList, setCaseLabelList] = useState([]);

    //filter
    let [showModalFilter, setShowModalFilter] = useState(false);
    let [filterLabelValue, setFilterLabelValue] = useState([]);
    let [filterLabelValueBefore, setFilterLabelValueBefore] = useState([]);
    let [topicList, setTopicList] = useState([]);
    // let [agentList, setAgentList] = useState(null);

    let [filterTopicValue, setFilterTopicValue] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValue, setFilterAgentValue] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValue, setFilterMediumValue] = useState([]);
    let [filterCaseTypeValue, setFilterCaseTypeValue] = useState(CASE_TYPES.allTypes.value);

    let [filterTopicValueBefore, setFilterTopicValueBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterAgentValueBefore, setFilterAgentValueBefore] = useState(DEFAULT_FILTER_TOPIC_AGENT);
    let [filterMediumValueBefore, setFilterMediumValueBefore] = useState([]);
    let [filterCaseTypeValueBefore, setFilterCaseTypeValueBefore] = useState(CASE_TYPES.allTypes.value);
    let [isResetFilter, setIsResetFilter] = useState(false);
    let [isFilterTopicAgentChannelActive, setIsFilterTopicAgentChannelActive] = useState(false);
    //fitter

    // date
    const [isOpenModalCalendar, setIsOpenModalCalendar] = useState(false)
    const [isFilterDateActive, setIsFilterDateActive] = useState(false)
    const [filterDate, setFilterDate] = useState({
        from: undefined,
        to: undefined
    })
    const [filterDateForCalender, setFilterDateForCalender] = useState({
      from: undefined,
      to: undefined
    })
    // date
    const [showClearInputText, setShowClearInputText] = useState(false);
    let showClearInputTextRef = useRef(null);
    
    // useEffect(() => {
        
    // }, [searchVal])

    let toggleShowClearInputText = () => {
        setShowClearInputText(!showClearInputText);
    }

    // const getCaseLabelList = () => {
    //     // setIsLoadingGetLabelList(true);
    //     CaseLabelService.getCaseLabelList(props.match.params.orgID, "", (response) => {
    //         // setIsLoadingGetLabelList(false);
            
    //         if (response.dataResult.status === 200) {
    //             setCaseLabelList(response.dataResult.data.caseLabels)
    //             // console.log(response.dataResult.data.caseLabels)
    //         } else {
    //             doToast(response.dataResult.error.message, 'fail');
    //         }

    //     })
    // }

    // let getAgentList = (topicID) => {
    //     let data = {
    //         topicID: topicID
    //     };

    //     CaseService.postAgentList(props.parentProps.match.params.orgID, data, (response) => {
    //         if (response.dataResult.error.code === "") {
    //             let dataResult = response.dataResult;

    //             for (let i in dataResult.data.agents) {
    //                 dataResult.data.agents[i].value = dataResult.data.agents[i].accountID;
    //                 dataResult.data.agents[i].label = dataResult.data.agents[i].accountName;
    //             }

    //             setAgentList(dataResult.data.agents);
    //         } else {
    //             doToast(response.dataResult.error.message, 'fail');
    //         }
    //     })
    // }

    useEffect(() => {
        const onBodyClick = async (event) => {
          if (showClearInputText) {
            if (!showClearInputTextRef.current.contains(event.target) && !showClearInputTextRef.current.previousSibling.contains(event.target)) {
                setShowClearInputText(false);
            }
          }
        };
    
        document.body.addEventListener('click', onBodyClick);
    
        return () => {
          document.body.removeEventListener('click', onBodyClick);
        };
    }, [showClearInputText]);

    useEffect(() => {
        //set topic list
        let _topiclist = [];

        Object.keys(props.topicListProps).map(value => {
            _topiclist.push(props.topicListProps[value]);
            return null;
        });

        setTopicList(_topiclist);
        //set topic list

        // getCaseLabelList()
        // getAgentList()
    }, [])

    useEffect(() => {
        onClickReset();
    }, [props.globalSearch])

    let submitSearchGlobal = (e = false) => {
        if(e) {
            e.preventDefault();
        }

        setLastSearch(new Date().valueOf())
        setIsFilterActive(true);
    }

    let onChangeSearchValue = (e) => {
        // setSearchValOrigin(e.target.value);
        setSearchVal(e.target.value);
    }

    let clearAllVal = () => {
        //clear all val
        setSearchVal("")
        // setSearchValOrigin("");
        setIsFilterActive(false)
        setFilterDate({
            from: undefined,
            to: undefined
        })
        setFilterDateForCalender({
          from: undefined,
          to: undefined
        })
        // clear all val
    }

    let clearSearchBox = () => {
        let _globalSearch = {...props.globalSearch};
        _globalSearch.show = false;

        props.setGlobalSearch(_globalSearch);
        clearAllVal()
    }

    let clearSearchBoxInput = () => {
        setSearchVal("");
        toggleShowClearInputText();
    }

    // date
    let toggleModalCalendar = () => {
        setIsOpenModalCalendar(!isOpenModalCalendar)
    }

    let handleDateFilterClick = (date) => {
        setFilterDateForCalender(date);
    } 

    let handleDateFilterCancel = () => {
        setFilterDateForCalender(filterDate)
    }

    let onClearDatePicker = () => {
        let _date = {
          from: undefined,
          to: undefined
        }
    
        setFilterDateForCalender(_date)
        // setFilterDate(_date)
        // toggleModalCalendar()
    }

    let submmitDateFilter = () => {
        setIsFilterActive(true);
        setFilterDate(filterDateForCalender);
        toggleModalCalendar();
        setLastSearch(new Date().valueOf());
        setIsFilterDateActive(true);
    }
    // date
    
    let onClickReset = () => {
        setLastReset(new Date().valueOf())
        setIsFilterTopicAgentChannelActive(false);
        setIsFilterActive(false);
        setIsFilterDateActive(false);
        setSearchVal("");
        setLastSearch(false);
        setFilterTopicValue(DEFAULT_FILTER_TOPIC_AGENT);
        setFilterMediumValue([]);
        setFilterDateForCalender({
            from: undefined,
            to: undefined
        });
        setFilterDate({
            from: undefined,
            to: undefined
        });
        setFilterLabelValue([]);
        setFilterCaseTypeValue(CASE_TYPES.allTypes.value);
        setFilterAgentValue(DEFAULT_FILTER_TOPIC_AGENT);
    }

    //filter
    let toggleModalFilter = () => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Button] Filter",
                {
                    userFullName: myAgentData.account.fullName,
                    userID: myAgentData.account.id,
                    organizationID: props.parentProps.match.params.orgID.split("-")[0]
                }
            );
        }

        //if close
        if (showModalFilter) {
            setFilterTopicValue(filterTopicValueBefore);
            setFilterAgentValue(filterAgentValueBefore);
            setFilterMediumValue(filterMediumValueBefore);
            setFilterLabelValue(filterLabelValueBefore);
            setFilterCaseTypeValue(filterCaseTypeValueBefore);
        }
        //if close

        setShowModalFilter(!showModalFilter);
    }

    let runUpdateFilter = () => {
        // setIsLoadingSearchSolvedCaseFinish(false);

        setFilterTopicValueBefore(filterTopicValue);
        setFilterAgentValueBefore(filterAgentValue);
        setFilterMediumValueBefore(filterMediumValue);
        setFilterLabelValueBefore(filterLabelValue)
        setFilterCaseTypeValueBefore(filterCaseTypeValue);
        setIsFilterActive(true);
        setLastSearch(new Date().valueOf());
    }

    let toggleResetFilter = () => {
        setIsResetFilter(false);
    }

    let onSelectTopic = (value) => {
        setFilterTopicValue(value)
    }

    let onSelectAgent = (value) => {
        setFilterAgentValue(value)
    }

    let onSelectMedium = (value) => {
        setFilterMediumValue(value);
    }

    const onSelectLabel = (value) => {
        setFilterLabelValue(value)
    }

    let onSelectCaseType = (value) => {
        setFilterCaseTypeValue(value);
    }

    let toggleIsActiveFilter = (toggleState) => {
        setIsFilterTopicAgentChannelActive(toggleState)
    }
    //filter

    return (
        <div className={`room-list-global-search-wrapper ${props.globalSearch.show ? "room-list-global-search-wrapper-active" : ""}`}>
             <CalendarPicker 
                isOpen={isOpenModalCalendar}
                toggle={toggleModalCalendar}
                filterDate={filterDateForCalender}
                handleDateFilterClick={handleDateFilterClick}
                isFilterDateActive={isFilterDateActive}
                submmitDateFilter={submmitDateFilter}
                cancelDatePicker={handleDateFilterCancel}
                clearDatePicker={onClearDatePicker}
                {...props}
            />

            <div className="room-list-global-search-header">
                <div className={`case-room-search-wrapper`}>
                    <form id="input-search-form" onSubmit={(e) => submitSearchGlobal(e)}>
                        <input type="text"
                            placeholder="Case ID, full name, alias and phone number"
                            onChange={(e) => onChangeSearchValue(e)}
                            value={searchVal}
                            id="open-case-search-input"
                            className={searchVal === "" ? "" : "active-search"}
                            autoComplete={"off"}
                            onClick={toggleShowClearInputText}
                        />

                        {/* {searchVal !== "" &&
                            <div className="clear-search-wrapper" onClick={() => setSearchVal("")}>
                                <FiX />
                            </div>
                        } */}

                        {/* {showClearInputText && */}
                            <div 
                                className={`clear-search-x ${showClearInputText ? "active-clear-search-x" : ""}`}
                                ref={showClearInputTextRef}
                                onClick={() => {
                                    clearSearchBoxInput();
                                    // submitSearchGlobal();
                                }}
                            >
                                <FiX />
                            </div>
                        {/* } */}

                        <b className="cancel-search"
                            onClick={clearSearchBox}
                        >
                            {/* <FiX /> */}
                            Cancel
                        </b>

                        {/* <button className="submit-search">
                            <FiSearch />
                        </button> */}
                    </form>
                </div>

                <div className="room-list-global-search-header-bottom">
                    {isFilterActive &&
                        <div
                            className={`case-room-filter-tag-badge reset-button`}
                            onClick={onClickReset}
                        >
                            <FiRotateCcw />
                            Reset
                        </div>
                    }

                    <div
                        className={`
                            case-room-filter-tag-badge
                            ${isFilterTopicAgentChannelActive ? "case-room-filter-tag-badge-active" : ""}
                        `}
                        onClick={() => toggleModalFilter()}
                    >
                        <FiSliders />
                        Filters
                    </div>

                    <div
                        className={`
                            case-room-filter-tag-badge
                            ${isFilterDateActive ? "case-room-filter-tag-badge-active" : ""}
                        `}
                        onClick={() => toggleModalCalendar()}
                    >
                        <FiCalendar />
                        {!filterDate.from ? "All Dates" : HelperDate.formatToString(new Date(filterDate.from), 'dd MMM yyyy') + " - " +  HelperDate.formatToString(new Date(filterDate.to), 'dd MMM yyyy')}
                    </div>
                </div>

                {/* filter */}
                <FilterBy
                    filterLabelValueProps={filterLabelValue}
                    onSelectLabelProps={onSelectLabel}
                    filterTopicValueProps={filterTopicValue}
                    filterAgentValueProps={filterAgentValue}
                    filterMediumValueProps={filterMediumValue}
                    filterCaseTypeProps={filterCaseTypeValue}
                    filterCaseTypeBeforeProps={filterCaseTypeValueBefore}
                    isOpenProps={showModalFilter}
                    updateFilterProps={runUpdateFilter}
                    closeModal={() => setShowModalFilter(false)}
                    toggleProps={toggleModalFilter}
                    // agentListProps={agentList}
                    agentListProps={null}
                    topicListProps={topicList}
                    toggleIsActiveFilterProps={toggleIsActiveFilter}
                    isResetFilterProps={isResetFilter}
                    toggleResetFilterProps={toggleResetFilter}
                    onSelectTopicProps={onSelectTopic}
                    onSelectAgentProps={onSelectAgent}
                    onSelectMediumProps={onSelectMedium}
                    onSelectCaseType={onSelectCaseType}
                    isHideAgentProps={false}
                    orgID={props.match.params.orgID}
                    room="search"
                />
                {/* filter */}
            </div>
            
            {/* room list */}
            <RoomListGlobalSearchRoom 
                {...props}
                lastSearch={lastSearch}
                // caseLabelList={caseLabelList}
                caseLabelList={props.caseLabelList}
                onClickRoomListProps={props.onClickRoomListProps}
                filterTopicValue={filterTopicValue}
                filterMediumValue={filterMediumValue}
                filterSearchValue={searchVal}
                filterDateValue={filterDate}
                filterLabelValue={filterLabelValue}
                filterCaseTypeValue={filterCaseTypeValue}
                filterAgentValue={filterAgentValue}
                newEmitMessageProps={props.newEmitMessageProps}
                messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                listenerStartTypingProps={props.listenerStartTypingProps}
                listenerStopTypingProps={props.listenerStopTypingProps}
                lastReset={lastReset}
                runSetLastReset={() => setLastReset(false)}
            />
            <div className="room-list-global-search-room-list">
                
            </div>
            {/* room list */}
        </div>
    );
}

const mapStateToProps = state => ({
//   activeCasePanel: state.activeCasePanel
    globalSearch: state.globalSearch
});

const mapDispatchToProps = {
  setGlobalSearch
};

export default connect(mapStateToProps, mapDispatchToProps)(RoomListGlobalSearch);
