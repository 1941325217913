import React, { useState, useEffect } from "react";
import { useHistory, NavLink } from "react-router-dom";
import { FiEye, FiInfo } from "react-icons/fi"; 
import { Modal, ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import "./FaqDetail.scss";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";
import FaqServices from '../../../../services/newServices/FaqServices'
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { FiUser, FiEdit, FiTrash2, FiPlus, FiX, FiMessageSquare } from "react-icons/fi";
import { setPreviewImageOrVideo } from "../../../../redux/actions/reduxActionPreviewImageOrVideo";
import { autoFocusField, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import Helper from "../../../../helper/HelperChat";
import { TAPLIVE_FAQ_TYPE } from '../../../../constants/taplive';


const FaqDetail = (props) => {
  const DEFAULT_PATH_VAL = {
    title: "",
    content: "",
    type: "",
    topicIDs: []
  }
  const history = useHistory();
  let { match } = props;
  const [orgID, setOrgID] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [topicListOrigin, setTopicListOrigin] = useState([]);
  const [faqDetail, setFaqDetail] = useState(false);
  const [faqDetailEdit, setFaqDetailEdit] = useState();
  const [faqDetailEditOrigin, setFaqDetailEditOrigin] = useState(false);
  const [faqCreateChild, setFaqCreateChild] = useState(DEFAULT_PATH_VAL)
  const [showModalEditPath, setShowModalEditPath] = useState(false)
  const [showModalCreateChild, setShowModalCreateChild] = useState(false)
  const [showModalDelete, setShowModalDelete] = useState(false)
  const [isWaitDelete, setIsWaitDelete] = useState(false)
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  // const [isLoadingCreateChild, setIsLoadingCreateChild] = useState(false);
  const [isLoadingGetDetail, setIsLoadingGetDetail] = useState(true);
  const [isShowPopupSaveChanges, setIsShowPopupSaveChanges] = useState(false);

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState([]);
  const [searchTopic, setSearchTopic] = useState("");

  const [isCheckAllEdit, setIsCheckAllEdit] = useState(false);
  const [selectedTopicEdit, setSelectedTopicEdit] = useState([]);
  const [selectedTopicEditOrigin, setSelectedTopicEditOrigin] = useState([]);
  const [searchTopicEdit, setSearchTopicEdit] = useState("");
  const [errorMessageData, setErrorMessageData] = useState({
    title: "",
    content: "",
    type: "",
    topic: ""
  })

  const [errorMessageDataEdit, setErrorMessageDataEdit] = useState({
    title: "",
    content: "",
    type: "",
    topic: ""
  })

  useEffect(() => {
    if(showModalCreateChild) {
      setTimeout(() => {
        autoFocusField("faq-detail-create-child");
      }, [500])
    }

    if(showModalEditPath) {
      setTimeout(() => {
        autoFocusField("edit-path-input");
      }, 500)
    }
  }, [showModalCreateChild, showModalEditPath])
  
  useEffect(() => {
    if(orgID) {
      getTopic();
      setRetrieveDataError({
        code: false,
        message: false,
      })
      getDetail();
    }
  }, [orgID, match])

  useEffect(() => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.topic = "";
    setErrorMessageData(_errorMessageData);
  }, [JSON.stringify(selectedTopic)])

  useEffect(() => {
    let _errorMessageData = {...errorMessageDataEdit};
    _errorMessageData.topic = "";
    setErrorMessageDataEdit(_errorMessageData);
  }, [JSON.stringify(selectedTopicEdit)])

  // useEffect(() => {
  //   if (props.history.location.state) {
  //     if(props.history.location.state.message) {
  //       doToast(props.history.location.state.message);
  //       window.history.pushState(null, '');
  //     }
  //   }
  // }, [props.history])

  const toggleModalDelete = () => {
    if(!isWaitDelete) {
      setShowModalDelete(!showModalDelete)
    }
  }

  const togglePopupSaveChanges = () => {
    if(!isLoadingEdit) {
      setIsShowPopupSaveChanges(!isShowPopupSaveChanges)
    }
  }

  const getDetail = () => {
    let _retrieveDataError = { ...retrieveDataError };
    setIsLoadingGetDetail(true);
    
    let data = {
      itemID: Number(match.params.itemID),
      pathID: Number(match.params.pathID)
    }

    FaqServices.get_detail(orgID, data, (response) => {
      if (response.dataResult.status === 200) {
        let item = response.dataResult.data.item;
        setFaqDetail(item);
        let _topic = [];

        if(item.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val) {
          item.topics.map(val => {
            _topic.push({
              id: val.id,
              name: val.name
            })

            return null;
          }) 
        }
        let data = {
          itemID: item.itemID,
          pathID: item.pathID,
          title: item.title,
          content: item.content,
          type: item.type,
          topicIDs: _topic
        }

        setSelectedTopicEdit(_topic);
        setSelectedTopicEditOrigin(_topic);
        setFaqDetailEdit({...data});
        setFaqDetailEditOrigin({...data});
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetail(false);
    });
  };

  const closeModalEditPath = () => {
    setFaqDetailEdit(faqDetailEditOrigin);
    setSelectedTopicEdit(selectedTopicEditOrigin);
    setShowModalEditPath(false);
    setSearchTopicEdit("");
    setTopicList(topicListOrigin);
    setIsCheckAllEdit(false);
  }

  const closeModalCreateChild = () => {
    // if(!isLoadingCreateChild) {
      setSelectedTopic([]);
      setFaqCreateChild(DEFAULT_PATH_VAL);
      setSearchTopic("");
      setTopicList(topicListOrigin);
      setIsCheckAll(false);
      setShowModalCreateChild(false);
    // }
  }

  const checkOrgID = (match) => {
    const { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  const back = () => {
    // props.history.push({
    //   pathname: `/o/${orgID}/setup/qna`,
    // }
    history.go(-1);
  };

  const doMount = async () => {
    const paramID = checkOrgID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  const getTopic = () => {
    topicServices.getTopic(orgID, "list", null, (response) => {
      const success = response.dataResult.status === 200;
      const hasError = response.dataResult.error && response.dataResult.error.code && response.dataResult.error.message;
      if (success) {
        setTopicList(response.dataResult.data.topics)
        setTopicListOrigin(response.dataResult.data.topics)
      }else if(hasError) {
        doToast(response.dataResult.error.message, "fail");
      }
    });
  };

  const editPath = async () => {
    let _faqDetailEdit = {...faqDetailEdit};

    let _errorMessageData = {...errorMessageDataEdit};
    let errorCount = 0;
    let errorClass = "";

    if(_faqDetailEdit.title === "") {
      _errorMessageData.title = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title-edit" }
    }

    if(_faqDetailEdit.title.length > 200) {
      _errorMessageData.title = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title-edit" }
    }

    if(_faqDetailEdit.content === "") {
      _errorMessageData.content = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-content-edit" }
    }

    if(_faqDetailEdit.content.length > 1000) {
      _errorMessageData.content = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-content-edit" }
    }

    if(_faqDetailEdit.type === "") {
      _errorMessageData.type = "Please select type";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-type-edit" }
    }

    if(_faqDetailEdit.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val) {
      if(selectedTopicEdit.length === 0) {
        _errorMessageData.topic = "Please Choose Related Departments";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-topic-edit" }
      }
    }

    if(errorCount > 0) {
      togglePopupSaveChanges();
      setErrorMessageDataEdit(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      // let _retrieveDataError = {...retrieveDataError}
      setIsLoadingEdit(true)
      // faqDetailEdit.title = Helper.removeHTMLTag(faqDetailEdit.title);
      // faqDetailEdit.content =  Helper.removeHTMLTag(faqDetailEdit.content);
      _faqDetailEdit.topicIDs = [];
      selectedTopicEdit.map((val) => {
        _faqDetailEdit.topicIDs.push(val.id);
        return null;
      })

      FaqServices.edit(orgID, _faqDetailEdit, (response) => {
        if (response.dataResult.status === 200) {
          doToast(response.dataResult.data.message);
          if(response.dataResult.data.success) {
            closeModalEditPath();
            setFaqDetailEditOrigin(faqDetailEdit);
            togglePopupSaveChanges();
            getDetail();
          }
        } else {
          // _retrieveDataError.message = response.dataResult.error.message;
          // _retrieveDataError.code = response.dataResult.error.code;
          // setRetrieveDataError(_retrieveDataError);
          doToast(response.dataResult.error.message, 'fail');
        }

        setIsLoadingEdit(false);
      });
    }
  };

  const createChild = async () => {
    // let _retrieveDataError = {...retrieveDataError}
    let _faqCreateChild = {...faqCreateChild};
    let _errorMessageData = {...errorMessageData};
    let errorCount = 0;
    let errorClass = "";

    if(_faqCreateChild.title === "") {
      _errorMessageData.title = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    if(_faqCreateChild.title.length > 200) {
      _errorMessageData.title = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    if(_faqCreateChild.content === "") {
      _errorMessageData.content = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-content" }
    }

    if(_faqCreateChild.content.length > 1000) {
      _errorMessageData.content = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-content" }
    }

    if(_faqCreateChild.type === "") {
      _errorMessageData.type = "Please select type";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-type" }
    }

    if(_faqCreateChild.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val) {
      if(selectedTopic.length === 0) {
        _errorMessageData.topic = "Please Choose Related Departments";
        errorCount = errorCount + 1;
        if(errorClass === "") { errorClass = "input-topic" }
      }
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      setIsLoadingEdit(true)
      _faqCreateChild.pathID = faqDetail.pathID;
      _faqCreateChild.parentItemID = faqDetail.itemID;
      
      _faqCreateChild.topicIDs = [];
      selectedTopic.map((val) => {
        _faqCreateChild.topicIDs.push(val.id);
        return null;
      })

      // _faqCreateChild.title = Helper.removeHTMLTag(_faqCreateChild.title);
      // _faqCreateChild.content =  Helper.removeHTMLTag(_faqCreateChild.content);

      FaqServices.addChild(orgID, _faqCreateChild, (response) => {
        if (response.dataResult.status === 200) {
          doToast(response.dataResult.data.message);
          
          if(response.dataResult.data.success) {
            closeModalCreateChild();
            setFaqDetail(false);
            // let data = response.dataResult.data;
            closeModalCreateChild();
            getDetail();
            // props.history.push({
            //   pathname: `/o/${orgID}/setup/qna/detail/${match.params.pathID}/${data.item.itemID}`,
            // })
            // getDetail();
          }
        } else {
          // _retrieveDataError.message = response.dataResult.error.message;
          // _retrieveDataError.code = response.dataResult.error.code;
          // setRetrieveDataError(_retrieveDataError);
          doToast(response.dataResult.error.message, 'fail');
        }

        setIsLoadingEdit(false);
      });
    }
  };

  const deletePath = async () => {
    // let _retrieveDataError = {...retrieveDataError}
    setIsWaitDelete(true)
    let data = {
      itemID: Number(faqDetail.itemID),
      pathID: Number(faqDetail.pathID),
      createdTime: faqDetail.createdTime
    }
    FaqServices.delete(orgID, data, (response) => {
      if (response.dataResult.status === 200) {
        doToast(response.dataResult.data.message);
        if(response.dataResult.data.success) {
          setTimeout(() => {
            toggleModalDelete();
            setIsWaitDelete(false);
            history.goBack();
          }, 2000)
          // props.history.push({
          //   pathname: `/o/${orgID}/setup/qna`,
          //   state: {
          //     message: response.dataResult.data.message
          //   }
          // })
        }else {
          setIsWaitDelete(false);
        }
      } else {
        // _retrieveDataError.message = response.dataResult.error.message;
        // _retrieveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retrieveDataError);
        doToast(response.dataResult.error.message, 'fail');
        setIsWaitDelete(false);
      }      
    });
  };

  const disableEditPath = () => {
    let dis = false;

    // if(faqDetailEdit.title === "" || faqDetailEdit.type === "") {
    //   dis = true;
    // }

    // if(faqDetailEdit.type === TAPLIVE_FAQ_TYPE["talk_to_agent"].val) {
    //   if(faqDetailEdit.topicIDs.length === 0) {
    //     dis = true;
    //   }
    // }

    // if(JSON.stringify(faqDetailEdit) === JSON.stringify(faqDetailEditOrigin)) {
    //   dis = true;
    // }

    return dis;
  }

  const disableCreateChild = () => {
    let dis = false;

    // if(faqCreateChild.title === "" || faqCreateChild.type === "") {
    //   dis = true;
    // }

    // if(faqCreateChild.type === TAPLIVE_FAQ_TYPE["talk_to_agent"].val) {
    //   if(faqCreateChild.topicIDs.length === 0) {
    //     dis = true;
    //   }
    // }

    return dis;
  }

  const onChange = (e) => {
    let id = e.target.id;
    let val = e.target.value;
    let _faqDetail = {...faqDetailEdit};
    let _errorMessageData = {...errorMessageDataEdit};
    _faqDetail[id] = val;
    _errorMessageData[id] = "";
    setErrorMessageDataEdit(_errorMessageData);
    setFaqDetailEdit(_faqDetail);
  }

  // const onChangeType = (val) => {
  //   let _faqDetail = {...faqDetailEdit};
  //   _faqDetail.type = val;
  //   _faqDetail.topic = [];
  //   setFaqDetailEdit(_faqDetail);
  // }

  // const onChangeTopic = (e, id) => {
  //   let _faqDetail = {...faqDetailEdit};
  //   let _faqDetailTopic = _faqDetail.topicIDs.slice();
  //   let _index = findIndexTopic(id);

  //   if(_index === -1) {
  //     _faqDetailTopic.push(id);
  //   }else {
  //     _faqDetailTopic.splice(_index, 1);
  //   }
  //   // _faqDetail.topicIDs = [id];
  //   _faqDetail.topicIDs = _faqDetailTopic;
  //   setFaqDetailEdit(_faqDetail);
  // }

  // const findIndexTopic = (id) => {
  //   let _idx = faqDetailEdit.topicIDs.findIndex(val => val === id);
  //   return _idx;
  // }

  const onChangeCreateChild = (e) => {
    let id = e.target.id;
    let val = e.target.value;
    let _faqCreateChild = {...faqCreateChild};
    let _errorMessageData = {...errorMessageData};
    _faqCreateChild[id] = val;
    _errorMessageData[id] = "";
    setErrorMessageData(_errorMessageData);
    setFaqCreateChild(_faqCreateChild);
  }

  const onChangeTypeCreateChild = (val) => {
    if(!isLoadingEdit) {
      let _faqCreateChild = {...faqCreateChild};
      let _errorMessageData = {...errorMessageData};
      _faqCreateChild.type = val;
      _faqCreateChild.topic = [];
      _errorMessageData.type = "";
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
      setFaqCreateChild(_faqCreateChild);
    }
  }

  // const onChangeTopicCreateChild = (e, id) => {
  //   let _faqCreateChild = {...faqCreateChild};
  //   let _index = findIndexTopicCreateChild(id);
    
  //   if(_index === -1) {
  //     _faqCreateChild.topicIDs.push(id);
  //   }else {
  //     _faqCreateChild.topicIDs.splice(_index, 1);
  //   }
  //   // _faqCreateChild.topicIDs = [id];
  //   setFaqCreateChild(_faqCreateChild);
  // }

  // const findIndexTopicCreateChild = (id) => {
  //   let _idx = faqCreateChild.topicIDs.findIndex(val => val === id);
  //   return _idx;
  // }

  // const isAllChecked = () => {
  //   let _faqCreateChild = {...faqCreateChild};

  //   return _faqCreateChild.topicIDs.length === topicList.length;
  // }

  // const onSelectAll = (e) => {
  //   let _checked = e.target.checked;
  //   let _faqCreateChild = {...faqCreateChild};

  //   if(_checked) {
  //     topicList.map((val) => {
  //       if(findIndexTopic(val.id) === -1) {
  //         _faqCreateChild.topicIDs.push(val.id);
  //       }

  //       return null;
  //     })
  //   }else {
  //     _faqCreateChild.topicIDs = [];
  //   }

  //   setFaqCreateChild(_faqCreateChild);
  // }

  // const onClearAll = () => {
  //   let _faqCreateChild = {...faqCreateChild};
  //   _faqCreateChild.topicIDs = [];
  //   setFaqCreateChild(_faqCreateChild);
  // }

  // const isAllCheckedEdit = () => {
  //   let _faqDetail = {...faqDetailEdit};

  //   return _faqDetail.topicIDs.length === topicList.length;
  // }

  // const onSelectAllEdit = (e) => {
  //   let _checked = e.target.checked;
  //   let _faqDetail = {...faqDetailEdit};

  //   if(_checked) {
  //     topicList.map((val) => {
  //       if(findIndexTopic(val.id) === -1) {
  //         _faqDetail.topicIDs.push(val.id);
  //       }

  //       return null;
  //     })
  //   }else {
  //     _faqDetail.topicIDs = [];
  //   }

  //   setFaqDetailEdit(_faqDetail);
  // }

  // const onClearAllEdit = () => {
  //   let _faqDetail = {...faqDetailEdit};
  //   _faqDetail.topicIDs = [];
  //   setFaqDetailEdit(_faqDetail);
  // }
  let onChangeSingleSelect = (e, val) => {
    let _selectedTopic = [...selectedTopic]

    if (e.target.checked) {
      _selectedTopic = [..._selectedTopic, val]
    } else {
      const _dataSelectedTopic = [..._selectedTopic]
      _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
      setIsCheckAll(false);
    }

    setSelectedTopic(_selectedTopic);
  }

  const onClearAll = () => {
    setIsCheckAll(false);
    setSelectedTopic([]);
  }

  let onCheckAll = (e) => {
    if (e.target.checked) {
      setSelectedTopic(topicList);
    } else {
      setSelectedTopic([]);
    }

    setIsCheckAll(e.target.checked);
  }

  let isChecked = (id) => {
    const _data = [...selectedTopic]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let removeSingleTopic = (val) => {
    setIsCheckAll(false);
    let _selectedTopic = [...selectedTopic];
    const _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    setSelectedTopic(_selectedTopic);
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
      for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
          if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
          }
        }
      }
    }

    setTopicList(result);
  }

  let onChangeSingleSelectEdit = (e, val) => {
    let _selectedTopic = [...selectedTopicEdit]

    if (e.target.checked) {
      _selectedTopic = [..._selectedTopic, val]
    } else {
      const _dataSelectedTopic = [..._selectedTopic]
      _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
      setIsCheckAll(false);
    }

    setSelectedTopicEdit(_selectedTopic);
  }

  const onClearAllEdit = () => {
    setIsCheckAllEdit(false);
    setSelectedTopicEdit([]);
  }

  let onCheckAllEdit = (e) => {
    if (e.target.checked) {
      setSelectedTopicEdit(topicList);
    } else {
      setSelectedTopicEdit([]);
    }

    setIsCheckAllEdit(e.target.checked);
  }

  let isCheckedEdit = (id) => {
    const _data = [...selectedTopicEdit]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let removeSingleTopicEdit = (val) => {
    setIsCheckAllEdit(false);
    let _selectedTopic = [...selectedTopicEdit];
    const _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    setSelectedTopicEdit(_selectedTopic);
  }

  let onChangeSearchTopicEdit = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopicEdit(e.target.value);

    if (_topicOrigin.length > 0) {
      for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
          if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
          }
        }
      }
    }

    setTopicList(result);
  }

  const renderModalEditPath = () => {
    return (
      <Modal isOpen={showModalEditPath} toggle={closeModalEditPath} className={"edit-path-modal"}>
        <ModalBody>
          <div className="create-path-wrapper edit-path-wrapper">
            <b className="create-path-title">Edit Path</b>

            <FiX onClick={closeModalEditPath} className="close-modal-create-path" />
            
            <div className="form-content-wrapper">
              <label><b>Title</b> <span className={`input-counter grey-font ${errorMessageDataEdit.title === "Characters exceed limit" ? "red-text" : ""}`}>{faqDetailEdit.title.length}/200</span></label>
              <input type="text" id="title" onChange={(e) => onChange(e)} value={faqDetailEdit.title} placeholder="Insert title" className={`edit-path-input input-title-edit ${errorMessageDataEdit.title !== "" ? "border-red" : ""}`} />
              {errorMessageDataEdit.title !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageDataEdit.title}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }

              <label><b>Content</b><span className="input-counter grey-font">{faqDetailEdit.content.length}/1000</span></label>
              <textarea rows={4} id="content"  onChange={(e) => onChange(e)} value={faqDetailEdit.content} placeholder="Insert your content here" className={`input-content-edit ${errorMessageDataEdit.content !== "" ? "border-red" : ""}`} />
              {errorMessageDataEdit.content !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageDataEdit.content}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }

              <label className="input-type-edit"><b>Type</b></label>
              <div className="create-path-type-wrapper no-hover">
                {faqDetailEdit.type !== TAPLIVE_FAQ_TYPE.talk_to_agent.val ?
                  <div className={`create-path-type-select edit-path-type-select`}>
                    <FiMessageSquare />
                    {TAPLIVE_FAQ_TYPE.qna.text}
                  </div>
                  :
                  <div className={`create-path-type-select edit-path-type-select`}>
                    <FiUser />
                    {TAPLIVE_FAQ_TYPE.talk_to_agent.text}
                  </div>  
                }
              </div>
              {errorMessageDataEdit.type !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageDataEdit.title}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }
              
              {faqDetailEdit.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val &&
                <>
                  {/* <label>
                    <b>Choose Related Departments</b>
                    {faqDetailEdit.topicIDs.length > 0 &&
                      <b className="clear-all" onClick={() => onClearAllEdit()}>Clear all</b>
                    }
                  </label>

                  <div className="choose-related-department-wrapper">
                    <div className={`choose-related-department-select custom-checkbox ${isAllCheckedEdit() ? "choose-related-department-select-active" : ""}`}> */}
                        {/* <div className="custom-checkbox"> */}
                          {/* <input type="checkbox" id={`select-all`} checked={isAllCheckedEdit()} onClick={(e) => onSelectAllEdit(e)}/>
                          <label htmlFor={`select-all`}>Select All</label> */}
                      {/* </div> */}
                    {/* </div> */}

                    {/* {topicList.map((val, idx) => {
                      return (
                        <div className={`choose-related-department-select custom-checkbox ${findIndexTopic(val.id) !== -1 ? "choose-related-department-select-active" : ""}`} key={`select-${idx}`}> */}
                          {/* <div className="custom-checkbox"> */}
                              {/* <input type="checkbox" id={`select-${val.id}`} checked={findIndexTopic(val.id) !== -1} onClick={(e) => onChangeTopic(e, val.id)}/>
                              <label htmlFor={`select-${val.id}`}>{val.name}</label> */}
                          {/* </div> */}
                        {/* </div>
                      )
                    })} */}
                  {/* </div> */}
                  <SelectTopic 
                    onChangeSearchTopic={onChangeSearchTopicEdit}
                    onCheckAll={onCheckAllEdit}
                    onClearAll={onClearAllEdit}
                    topicList={topicList}
                    onChangeSingleSelect={onChangeSingleSelectEdit}
                    isChecked={isCheckedEdit}
                    isCheckAll={isCheckAllEdit}
                    searchTopic={searchTopicEdit}
                    title={"Choose Related Departments"}
                    selectedTopic={selectedTopicEdit}
                    removeSingleTopic={removeSingleTopicEdit}
                    _className={"input-topic-edit"}
                  />

                  {errorMessageDataEdit.topic !== "" &&
                    <ErrorOrInfoComp
                      text={errorMessageDataEdit.topic}
                      _className={"font-red"}
                      icon={<FiInfo />}
                    />
                  }
                </>
              }
            </div>
            
            {/* {!isLoadingEdit ? */}
              <ButtonWithLoadingOrIcon 
                className="orange-button main-button-48 button-create-path"
                text="Save Changes"
                isDisabled={disableEditPath()}
                onClickAction={togglePopupSaveChanges}
              />
              {/* :
              <ButtonWithLoadingOrIcon 
                className="orange-button main-button-48 button-create-path"
                text="Save Changes"
                isLoading
                loadingWhite
                position="left"
              />
             } */}
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const renderModalCreateChild = () => {
    return (
      <Modal isOpen={showModalCreateChild} toggle={closeModalCreateChild} className={"add-path-modal"}>
        <ModalBody>
          <div className="create-path-wrapper">
            <b className="create-path-title">Create Child Path</b>

            <FiX onClick={closeModalCreateChild} className="close-modal-create-path" />
            
            <div className="form-content-wrapper">
              <label><b>Title</b> <span className={`input-counter grey-font ${errorMessageData.title === "Characters exceed limit" ? "red-text" : ""}`}>{faqCreateChild.title.length}/200</span></label>
              <input disabled={isLoadingEdit} type="text" id="title" onChange={(e) => onChangeCreateChild(e)} value={faqCreateChild.title} placeholder="Insert title" className={`faq-detail-create-child input-text ${errorMessageData.title !== "" ? "border-red" : ""}`} />
              {errorMessageData.title !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageData.title}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }

              <label><b>Content</b><span className={`input-counter grey-font ${errorMessageData.content === "Characters exceed limit" ? "red-text" : ""}`}>{faqCreateChild.content.length}/1000</span></label>
              <textarea disabled={isLoadingEdit} rows={4} id="content"  onChange={(e) => onChangeCreateChild(e)} value={faqCreateChild.content} placeholder="Insert your content here" className={`input-content ${errorMessageData.content !== "" ? "border-red" : ""}`} />
              {errorMessageData.content !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageData.content}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }

              <label className="input-type"><b>Type</b></label>
              <div className="create-path-type-wrapper">
                <div className={`create-path-type-select ${faqCreateChild.type === TAPLIVE_FAQ_TYPE.qna.val ? "create-path-type-active" : ""}`} onClick={() => onChangeTypeCreateChild(TAPLIVE_FAQ_TYPE.qna.val)}>
                  <FiMessageSquare />
                  {TAPLIVE_FAQ_TYPE.qna.text}
                </div>

                <div className={`create-path-type-select ${faqCreateChild.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val ? "create-path-type-active" : ""}`} onClick={() => onChangeTypeCreateChild(TAPLIVE_FAQ_TYPE.talk_to_agent.val)}>
                  <FiUser />
                  {TAPLIVE_FAQ_TYPE.talk_to_agent.text}
                </div>  
              </div>
              {errorMessageData.type !== "" &&
                  <ErrorOrInfoComp
                  text={errorMessageData.type}
                  _className={"font-red"}
                  icon={<FiInfo />}
                  />
              }
              
              {faqCreateChild.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val &&
                <>
                  {/* <label>
                    <b>Choose Related Departments</b>
                    {faqCreateChild.topicIDs.length > 0 &&
                      <b className="clear-all" onClick={() => onClearAll()}>Clear all</b>
                    }
                  </label>
                  <div className="choose-related-department-wrapper">
                    <div className={`choose-related-department-select custom-checkbox ${isAllChecked() ? "choose-related-department-select-active" : ""}`}> */}
                        {/* <div className="custom-checkbox"> */}
                          {/* <input type="checkbox" id={`select-all`} checked={isAllChecked()} onClick={(e) => onSelectAll(e)}/>
                          <label htmlFor={`select-all`}>Select All</label> */}
                      {/* </div> */}
                    {/* </div> */}

                    {/* {topicList.map((val, idx) => {
                      return (
                        <div className={`choose-related-department-select custom-checkbox ${findIndexTopicCreateChild(val.id) !== -1 ? "choose-related-department-select-active" : ""}`} key={`select-${idx}`}> */}
                          {/* <div className="custom-checkbox"> */}
                              {/* <input type="checkbox" id={`select-${val.id}`} checked={findIndexTopicCreateChild(val.id) !== -1} onClick={(e) => onChangeTopicCreateChild(e, val.id)}/>
                              <label htmlFor={`select-${val.id}`}>{val.name}</label> */}
                          {/* </div> */}
                        {/* </div> */}
                      {/* )
                    })} */}
                  {/* </div> */}
                  <SelectTopic 
                    onChangeSearchTopic={onChangeSearchTopic}
                    onCheckAll={onCheckAll}
                    onClearAll={onClearAll}
                    topicList={topicList}
                    onChangeSingleSelect={onChangeSingleSelect}
                    isChecked={isChecked}
                    isCheckAll={isCheckAll}
                    searchTopic={searchTopic}
                    title={"Choose Related Departments"}
                    selectedTopic={selectedTopic}
                    removeSingleTopic={removeSingleTopic}
                    _className={"input-topic"}
                  />

                  {errorMessageData.topic !== "" &&
                    <ErrorOrInfoComp
                      text={errorMessageData.topic}
                      _className={"font-red"}
                      icon={<FiInfo />}
                    />
                  }
                </>
              }
            </div>
            
            {!isLoadingEdit ?
              <ButtonWithLoadingOrIcon 
                className="orange-button main-button-48 button-create-path"
                text="Create"
                isDisabled={disableCreateChild()}
                onClickAction={createChild}
              />
              :
              <ButtonWithLoadingOrIcon 
                className="orange-button main-button-48 button-create-path"
                text="Create"
                isLoading
                loadingColor="gray"
                isDisabled
                position="left"
              />
            }
          </div>
        </ModalBody>
      </Modal>
    )
  }

  useEffect(() => {
    doMount();
  }, []);

  // const goToDetail = (rootItemID) => {
  //   props.history.push({
  //     pathname: `/o/${orgID}/setup/qna/detail/${match.params.pathID}/${rootItemID}`
  //   })

  //   // getDetail();
  // }

  return (
    <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
      
      {!isLoadingGetDetail && renderModalEditPath()}
      {!isLoadingGetDetail && renderModalCreateChild()}
      {/* <ModalPreviewImageOrVideo /> */}
      <BackButton text="Back" onClick={back} />
      {/* BROADCAST */}
      <div className="view-faq-detail sectionWrap">
        <div className="view-faq-detail-top">
          <b>QnA Path Details</b>
          
          {!isLoadingGetDetail &&
            <>
              <ButtonWithLoadingOrIcon
                onClickAction={() => setShowModalEditPath(true)}
                position="left"
                icon={{
                  type: "svg",
                  src: FiEdit
                }}
                text="Edit"
                className="main-button-40 no-fill-button button-edit"
              />

              <ButtonWithLoadingOrIcon
                onClickAction={toggleModalDelete}
                position="left"
                icon={{
                  type: "svg",
                  src: FiTrash2
                }}
                text="Delete"
                className="main-button-40 red-button button-delete"
              />
            </>
          }
        </div>
        
        {isLoadingGetDetail || retrieveDataError.code ? (
          <div className="faq-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetDetail}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : faqDetail ? 
          (
            <React.Fragment>
              <PopupConfirmation
                isOpen={showModalDelete}
                toggle={toggleModalDelete}
                title="Delete Path?"
                description="This path and its children will be deleted permanently and will be removed from your QnA Path list. Are you sure you want to delete this path?"
                className="modal-confirmation-delete-path"
              >
                {!isWaitDelete ?
                    <ButtonWithLoadingOrIcon
                        text="Delete"
                        className="main-button-48 red-button"
                        onClickAction={deletePath}
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Delete"
                        className="main-button-48 red-button"
                        isLoading
                        loadingWhite
                        position="left"
                    />
                }
              </PopupConfirmation>

              <PopupConfirmation
                isOpen={isShowPopupSaveChanges}
                toggle={togglePopupSaveChanges}
                title="Save Changes?"
                description="Are you sure want to save changes?"
                className="modal-confirmation-delete-path"
              >
                {!isLoadingEdit ?
                    <ButtonWithLoadingOrIcon
                        text="Save Changes"
                        className="main-button-48 orange-button"
                        onClickAction={editPath}
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Save Changes"
                        className="main-button-48 orange-button"
                        isLoading
                        loadingColor="gray"
                        isDisabled
                        position="left"
                    />
                }
              </PopupConfirmation>

              <div className="view-faq-detail-middle view-faq-detail-middle-top">
                <div className="view-faq-detail-middle view-faq-detail-middle-name">
                  <b>Title</b>
                  <p>{faqDetail.title}</p>
                </div>
                <div className="view-faq-detail-middle view-faq-detail-middle-channel">
                  <b>Content</b>
                  <p dangerouslySetInnerHTML={{__html: Helper.lineBreakChatRoom(faqDetail.content)}} />
                </div>
                <div className="view-faq-detail-middle view-faq-detail-middle-sender">
                  <b>Type</b>
                  <p>{faqDetail.type === TAPLIVE_FAQ_TYPE.talk_to_agent.val ? <FiUser /> : <FiMessageSquare />} {TAPLIVE_FAQ_TYPE[faqDetail.type] ? TAPLIVE_FAQ_TYPE[faqDetail.type].text : ""}</p>
                </div>
                {TAPLIVE_FAQ_TYPE.talk_to_agent.val === faqDetail.type &&
                  <div className="view-faq-detail-middle view-faq-detail-middle-sender">
                    <b>Topics</b>
                    <br />
                    {faqDetail.topics.map((v, i) => {
                      return (
                        <p key={`topics-${v.i}`} className="faq-detail-topics" title={v.name}>
                          <b>{v.name}</b>
                        </p>
                      )
                    })}
                  </div>
                }
              </div>
            </React.Fragment>
          ) 
          : 
          (
            ""
          )
        }
      </div>
      
      {(faqDetail && faqDetail.type !== TAPLIVE_FAQ_TYPE.talk_to_agent.val) &&
        <div className="view-faq-detail sectionWrap">
          <div className="view-faq-detail-top">
            <b>List of Child</b>
          
            <ButtonWithLoadingOrIcon
              position="left"
              icon={{
                type: "svg",
                src: FiPlus
              }}
              text="Create Child"
              className="main-button-40 no-fill-button button-export"
              onClickAction={() => setShowModalCreateChild(true)}
            />
          </div>
          {isLoadingGetDetail || retrieveDataError.code ? (
            <div className="faq-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetail}
                errorMessage={retrieveDataError.message}
              />
            </div>
          ) : faqDetail ? (
            <React.Fragment>
              <div className="view-faq-detail-middle">
                <div className="scf-table-wrapper">
                  <table className="custom-table-content table-scf">
                    <thead>
                      <tr>
                        <td className="col_id">ID</td>
                        <td className="col_title">Title</td>
                        {/* <td className="col_content">Content</td> */}
                        <td className="col_type">Type</td>
                        <td className="col_action"></td>
                      </tr>
                    </thead>

                    {!faqDetail.childItems ? (
                      <tbody>
                        <tr className="no-hover">
                          <td colSpan="4" className="no-data-column">
                            <b className="no-result-found">
                              No children found.
                            </b>
                          </td>
                        </tr>
                      </tbody>
                    ) :
                      <tbody>
                        {faqDetail.childItems.map((item, index) => {
                          return (
                            <tr 
                              key={`faq-${index}`} 
                            >
                              <td className="col_id">
                                {item.itemID}
                              </td>
                              <td className="col_title">
                                {item.title}
                              </td>
                              {/* <td className="col_content">
                                {item.content}
                              </td> */}
                              <td className="col_type">
                                {TAPLIVE_FAQ_TYPE[item.type] ? TAPLIVE_FAQ_TYPE[item.type].text : "-"}
                              </td>
                              <td className="col_action">
                                <NavLink to={`/o/${orgID}/setup/qna/detail/${match.params.pathID}/${item.itemID}`}>
                                  <b 
                                    className="orange-link-text" 
                                    // onClick={() => {
                                    //   setFaqDetail(false);
                                    // }}
                                  >
                                    <FiEye />
                                    View
                                  </b>
                                </NavLink>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    }
                  </table>
                </div>
              </div>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
      }
    </SectionWrap>
  );
};

const mapStateToProps = (state) => ({
  previewImageOrVideo: state.previewImageOrVideo,
});

const mapDispatchToProps = {
  setPreviewImageOrVideo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FaqDetail);
