import React, { Component } from "react";
import "./AccountSettingSecurity.css";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { MdLockOutline } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";
import { FaRegCircle } from "react-icons/fa";
import ServiceChangePassword from "../../../services/auth/ServiceChangePassword";
import CustomToastr from "../../reuseableComponent/customToastr/CustomToastr";
import PopupPassword from "../../reuseableComponent/popupPassword/PopupPassword";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {
//   doToast
// } from "../../../helper/HelperGeneral";

const style = {
  lockStyle: {
    position: "relative",
    top: "-2px"
  },
  marginBottom: {
    marginBottom: 0
  },
  divTwoFactor: {
    position: "relative",
    width: "50%",
    display: "inline-block"
  },
  lastWrapper: {
    borderBottom: 0,
    paddingBottom: 0
  },
  toastPosition: {
    top: 0,
    right: 0
  }
};

class AccountSettingSecurity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toastrText: "",
      toastrClassName: "",
      popupPasswordShow: false,
      peekPassword: false,
      peekRePassword: false,
      lowerCase: false,
      upperCase: false,
      number: false,
      specialCharacter: false,
      minimumCharacter: false,
      valPassword: "",
      valRePassword: "",
      isWaitingResponseChangePassword: ""
    };
  }

  togglePopupPassword = (e) => {
    e.preventDefault();
    
    this.setState({
        popupPasswordShow: true
    });
  }

  onChangeShowPopup = () => {
    this.setState({
        popupPasswordShow: false
    });
  }

  peekPasswordClick = () => {
    this.setState(prevState => ({
      peekPassword: !prevState.peekPassword
    }))
  }

  peekRePasswordClick = () => {
    this.setState(prevState => ({
      peekRePassword: !prevState.peekRePassword
    }))
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  passwordStrengthCheck = () => {
    let passPasswordCheck;

    if(
        this.state.lowerCase && this.state.upperCase && this.state.number && 
        this.state.specialCharacter && this.state.minimumCharacter &&
        this.state.password === this.state.rePassword
    ){
      passPasswordCheck = true;
    }else {
      passPasswordCheck = false;
    }

    return passPasswordCheck;
  }

  onChangeRePassword = e => {
    this.setState({ 
      [e.target.id]: e.target.value
    });
  }

  handlePassword = e => {
    let value = e.target.value;
    this.setState({ 
      [e.target.id]: e.target.value
    });

    let setStatePasswordCheck = (id, value) => {
        this.setState({
          [id]: value
        });
    };

    //lowercase
    if((/[a-z]/.test(value))) {
      setStatePasswordCheck("lowerCase", true);
    }else {
      setStatePasswordCheck("lowerCase", false);
    }

    //uppercase
    if((/[A-Z]/.test(value))) {
      setStatePasswordCheck("upperCase", true);
    }else {
      setStatePasswordCheck("upperCase", false);
    }

    //number
    if((/[0-9]/.test(value))) {
      setStatePasswordCheck("number", true);
    }else {
      setStatePasswordCheck("number", false);
    }
    
    //special character
    if(!(/^[a-zA-Z0-9]*$/).test(value)) {
      setStatePasswordCheck("specialCharacter", true);
    }else {
      setStatePasswordCheck("specialCharacter", false);
    }

    //8 minimum character
    if(value.length > 7) {
      setStatePasswordCheck("minimumCharacter", true);
    }else {
      setStatePasswordCheck("minimumCharacter", false);
    }
  }

  doChangePassword = (currentPassword) => {
    let param = {
      password: currentPassword,
      newPassword: this.state.valPassword
    };

    this.setState({
      // toastrShow: true,
      // toastrText: "Please wait, while your request is being process",
      // toastrClassName: "toast-process active-toast"
      isWaitingResponseChangePassword: true
    });

    ServiceChangePassword.postChangePassword(param, (response) => {
      this.setState({
        isWaitingResponseChangePassword: false
      });

      if(response.dataResult.error.code === "") {
        // this.setState({
        //     toastrText: response.dataResult.data.message,
        //     toastrClassName: "toast-success active-toast"
        // })

        // setTimeout(() => {
        //     this.setState({
        //         toastrClassName: "toast-success"
        //     })
        // }, 4000);
        this.setState({
          popupPasswordShow: false,
          valPassword: "",
          valRePassword: ""
        });

        let targetValPassword = document.querySelector("#valPassword");
        let targetValRePassword = document.querySelector("#valRePassword");

        targetValPassword.value = "";
        targetValRePassword.value = "";      

        this.props.doToastAccountSettingProps(response.dataResult.data.message);
      }else {
        this.props.doToastAccountSettingProps(response.dataResult.error.message, "fail");
        // this.setState({
            // toastrText: response.dataResult.error.message,
            // toastrClassName: "toast-fail active-toast"
        // });

        // setTimeout(() => {
        //     this.setState({
        //         toastrClassName: "toast-fail"
        //     })
        // }, 4000);
      }
    })
  }

  render() {
    return (
        <React.Fragment>
          <div id="page-account-security">
              <CustomToastr toastrText={this.state.toastrText} 
                            toastrClassName={this.state.toastrClassName}
                            style={style.toastPosition}
              />
              
              Change Password
              <p id="instruction-text">
                Follow the instructions below.
              </p>

              <form onSubmit={this.togglePopupPassword}>
                <div className="security-input">
                    <label>New Password</label>
                    <div className="popup-input-password-wrapper">
                        <input 
                          type={this.state.peekPassword ? "text" : "password"} 
                          autoComplete="false" 
                          placeholder="Input your new password"
                          id="valPassword"
                          onChange={(e) => this.handlePassword(e)}
                          disabled={this.state.isWaitingResponseChangePassword}
                        />

                        <div 
                          className="peek-password" 
                          onMouseDown={() => this.peekPasswordClick()} 
                          onMouseUp={() => this.peekPasswordClick()}
                        >
                            {this.state.peekPassword ?
                                <FiEye />

                                :

                                <FiEyeOff />
                            }
                        </div>
                    </div>

                    <div className="input-wrapper password-strength" style={style.fontSize0}>
                        <div className="div50">
                          <div className="checkLowerCase">
                              { this.state.lowerCase ?
                                  <FaCheckCircle size={17} color="#7EC82A" /> :
                                  <FaRegCircle size={17} color="#A4A4A4"/>
                              }
                              One lowercase character
                          </div>
                          <div className="checkUpperCase">
                              { this.state.upperCase ?
                                  <FaCheckCircle size={17} color="#7EC82A" /> :
                                  <FaRegCircle size={17} color="#A4A4A4"/>
                              }
                              One uppercase character
                          </div>
                          <div className="checkNumber">
                              { this.state.number ?
                                  <FaCheckCircle size={17} color="#7EC82A" /> :
                                  <FaRegCircle size={17} color="#A4A4A4"/>
                              }
                              One Number
                          </div>
                        </div>

                        <div className="div50">
                          <div className="checkSpecialCharacter">
                              { this.state.specialCharacter ?
                                  <FaCheckCircle size={17} color="#7EC82A" /> :
                                  <FaRegCircle size={17} color="#A4A4A4"/>
                              }
                              One special character
                          </div>
                          <div className="checkMinimumCharacter">
                              { this.state.minimumCharacter ?
                                  <FaCheckCircle size={17} color="#7EC82A" /> :
                                  <FaRegCircle size={17} color="#A4A4A4"/>
                              }
                              8 minimum character
                          </div>
                        </div>
                    </div>
                    
                    {this.passwordStrengthCheck() &&
                      <React.Fragment>
                        <label>Re-type New Password</label>
                        <div className="popup-input-password-wrapper popup-reinput-password-wrapper">
                            <input 
                              type={this.state.peekRePassword ? "text" : "password"} 
                              autoComplete="false" 
                              placeholder="Input your new password"
                              id="valRePassword"
                              onChange={(e) => this.onChangeRePassword(e)}
                              disabled={this.state.isWaitingResponseChangePassword}
                            />

                            <div 
                              className="peek-password" 
                              onMouseDown={() => this.peekRePasswordClick()} 
                              onMouseUp={() => this.peekRePasswordClick()}
                            >
                                {this.state.peekRePassword ?
                                    <FiEye />

                                    :

                                    <FiEyeOff />
                                }
                            </div>

                            {(this.state.valPassword !== this.state.valRePassword && this.state.valRePassword.length > 0) &&
                              <div className="input-warning">Password didn't match</div>
                            }
                        </div>
                      </React.Fragment>
                    }

                    <ButtonWithLoadingOrIcon
                        className="no-fill-button main-button-48"
                        text="Change Password"
                        position="right"
                        icon={{
                          type: "svg",
                          src: MdLockOutline
                        }}
                        isDisabled={!this.validateForm()}
                    />
                </div>
              </form>

              {/* <div className="security-input" style={style.lastWrapper}>
                  <div style={style.divTwoFactor}>
                      Two-Factor Authentication <MdLockOutline size="22" color="#9b9b9b" style={style.lockStyle} />
                      <span style={style.marginBottom}>
                            Add an additional layer of security to your account. Two-factor authentication requires 
                            you to enter a unique verification code in addition to your password anytime we notice 
                            an unusual login.
                      </span>
                  </div>

                  <label className="custom-switch-wrapper">
                      <input type="checkbox" />
                      <span className="custom-switch round"></span>
                  </label>
              </div> */}

              {this.state.popupPasswordShow &&
                  <PopupPassword showPopupPassword={true} 
                                onChangeShowPopupPassword={this.onChangeShowPopup} 
                                onClickSubmit={this.doChangePassword}
                                isWaitingResponseChangePasswordProps={this.state.isWaitingResponseChangePassword}
                  />
              }
          </div>
        </React.Fragment>
    );
  }

  validateForm = () => {
    return this.state.valPassword.length && this.state.valRePassword.length > 0 && this.passwordStrengthCheck() &&  this.state.valPassword ===  this.state.valRePassword
  }
}

export default AccountSettingSecurity;
