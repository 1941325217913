import React, { useState } from "react";
import "./IntegrationGoogleCreateChannel.scss";
import { taptalk, tapCoreMessageManager } from "@taptalk.io/web-sdk"; 
import { connect } from "react-redux";
import { FiUpload, FiInfo, FiArrowLeft, FiUser } from "react-icons/fi"; 
import { setCreateGoogle } from "../../../../../redux/actions/reduxActionCreateGoogle";
import { doToast, getImageDimention } from "../../../../../helper/HelperGeneral";
import HelperUpload from "../../../../../helper/HelperUpload";
import ErrorOrInfoComp from '../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import Loading from "../../../../reuseableComponent/loading/Loading";
import ChatService from "../../../../../services/chat/caseServices";

let IntegrationGoogleCreateChannel = (props) => {
    let [isWaitingUpload, setIsWaitingUpload] = useState(false);

    let onClickContinue = () => {
        if(!isWaitingUpload) {
            let _createGoogle = JSON.stringify(props.createGoogle);
            let _parse = JSON.parse(_createGoogle);
    
            if(_parse.data.channel.displayName !== "" && _parse.data.channel.logoURL !== "") {
                props._setActiveForm(2);
            }else {
                if(_parse.data.channel.displayName === "") {
                    _parse.data.channel.errorDisplayName = "This field is required";
                    props.setCreateGoogle(_parse);
                }

                if(_parse.data.channel.displayName.length > 100) {
                    _parse.data.channel.errorDisplayName = "Characters exceed limit";
                    props.setCreateGoogle(_parse);
                }
    
                if(_parse.data.channel.logoURL === "") {
                    _parse.data.channel.errorLogo = "You currently have no profile logo";
                    props.setCreateGoogle(_parse);
                }
            }
        }
    }

    let taptalkAuthTicket = (file) => {
        ChatService.postRequestAuthTicket(props.match.params.orgID, (response) => {
          if (response.dataResult.error.code === "") {
            taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
              onSuccess: () => {
                doUpload(file);
              },
              onError: (errorCode, errorMessage) => {
                doToast(errorMessage, 'fail')
                console.log(errorCode, errorMessage);
              }
            })
          } else {
            doToast(response.dataResult.error.message, 'fail');
          }
        })
    }

    let doUpload = (file) => {
        //do upload
        let uploadData = {
            file: file,
            caption: "",
            room: ""
        };

        tapCoreMessageManager.uploadChatFile(uploadData, {
            onProgress: (percentage, bytes) => {
            },
    
            onSuccess: (response) => {
                if(response) {
                    let _createGoogle = JSON.stringify(props.createGoogle);
                    let _parse = JSON.parse(_createGoogle);

                    _parse.data.channel.logoURL = response.url;
                    _parse.data.channel.errorLogo = "";

                    props.setCreateGoogle(_parse);
                    setIsWaitingUpload(false);
                }
            },
    
            onError: (error) => {
                doToast(error, "fail");
                console.log(error);
            }
        })
        //do upload
    }

    let onChangeUploadLogo = (e) => {
        let _createGoogle = JSON.stringify(props.createGoogle);
        let _parse = JSON.parse(_createGoogle);

        HelperUpload.checkFileSizeLimit(e.target.files, {
            onAllowed: (arrayOfFiles) => {
                
                getImageDimention(e.target.files[0], (imageHeight, imageWidth, file) => {
                    if(imageHeight > 1023 && imageWidth > 1023) {
                        _parse.data.channel.logoFile = file;
        
                        props.setCreateGoogle(_parse);
                        
                        setIsWaitingUpload(true);
                        taptalkAuthTicket(file);
                    }else {
                        doToast("Minimum image resolution is 1024x1024px.", "fail");
                        _parse.data.channel.errorLogo = "Minimum image resolution is 1024x1024px.";
                    }
                })

            },
            onReachLimit: (message) => {
              doToast("Error uploading image. The file size may be too large! PNG or JPG. 50Kb max with a minimum of 1024x1024px.", "fail");
            }
        }, 51200)
        
        e.target.value = null;
    }

    return (
        <div className="main-integration-google-create-channel">
            <input type="file" id="upload-logo" accept="image/jpg, image/png, image/jpeg" onChange={(e) => onChangeUploadLogo(e)} />

            <label className="pos-relative">
                <b>Display Name</b>
                <span className={props.createGoogle.data.channel.errorDisplayName === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{props.createGoogle.data.channel.displayName.length}/100</span>
            </label>

            <input 
                type="text"
                onChange={(e) => {
                    let _createGoogle = JSON.stringify(props.createGoogle);
                    let _parse = JSON.parse(_createGoogle);

                    _parse.data.channel.displayName = e.target.value;
                    _parse.data.channel.errorDisplayName = "";
                    props.setCreateGoogle(_parse);
                }}
                value={props.createGoogle.data.channel.displayName}
                placeholder="Type your display name here"
                className={`${props.createGoogle.data.channel.errorDisplayName === "" ? "" : "border-red"}`}
                disabled={isWaitingUpload}
            />

            <ErrorOrInfoComp
                text={props.createGoogle.data.channel.errorDisplayName === "" ? `The display name should not be representing a personal/test/demo and also it should have the connection with the business/brand. 
                Display name is visible when users interact with your channel's agent.` : props.createGoogle.data.channel.errorDisplayName}
                _className={props.createGoogle.data.channel.errorDisplayName === "" ? "" : "font-red"}
                icon={<FiInfo />}
            />

            <div className="create-channel-upload">
                {!isWaitingUpload &&
                    <ButtonWithLoadingOrIcon 
                        className="no-fill-button main-button-40"
                        text="Upload Logo"
                        position="left"
                        icon={{
                            src: FiUpload,
                            type: "svg"
                        }}
                        onClickAction={() => {
                            let el = document.querySelector("#upload-logo");
                            el.click();
                        }}
                    />
                }
                
                <label>
                    <b>Profile Logo</b>
                </label>

                <div className="logo-wrapper">
                    {(props.createGoogle.data.channel.logoFile === null && props.createGoogle.data.channel.logoURL === "") &&
                        <FiUser />
                    }


                    <>  
                        {(props.createGoogle.data.channel.logoFile !== null && props.createGoogle.data.channel.logoURL === "") &&
                            <img src={URL.createObjectURL(props.createGoogle.data.channel.logoFile)} alt="" /> 
                        }

                        {props.createGoogle.data.channel.logoURL !== "" &&
                            <img src={props.createGoogle.data.channel.logoURL} alt="" /> 
                        }

                        {isWaitingUpload &&
                            <div className="loading-wrapper">
                                <Loading />
                            </div>
                        }
                    </>
                </div>

                <ErrorOrInfoComp
                    text={"The profile logo should be related to the business in some manner."}
                    _className={props.createGoogle.data.channel.errorLogo === "" ? "" : "font-red"}
                    icon={<FiInfo />}
                />
                
                {props.createGoogle.data.channel.logoURL === "" &&
                    <ErrorOrInfoComp
                        text={"You currently have no profile logo"}
                        _className={props.createGoogle.data.channel.errorLogo === "" ? "no-margin-top" : "no-margin-top font-red"}
                        icon={""}
                    />
                }

                {props.createGoogle.data.channel.logoURL === "" &&
                    <ErrorOrInfoComp
                        text={"PNG or JPG. 50KB max with a minimum of 1024x1024px."}
                        _className={props.createGoogle.data.channel.errorLogo === "" ? "no-margin-top" : "no-margin-top font-red"}
                        icon={""}
                    />
                }
            </div>

            <ButtonWithLoadingOrIcon 
                className="no-fill-button main-button-40"
                onClickAction={() => props._setActiveForm(0)}
                text="Go Back"
                icon={{
                    src: FiArrowLeft,
                    type: "svg"
                }}
                position="left"
                isDisabled={isWaitingUpload}
            />

            <ButtonWithLoadingOrIcon 
                className="orange-button main-button-40"
                onClickAction={onClickContinue}
                text="Continue"
                isDisabled={isWaitingUpload}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    createGoogle: state.createGoogle
});
  
const mapDispatchToProps = {
    setCreateGoogle
};
  
export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleCreateChannel);
