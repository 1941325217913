import React, { useEffect, useState } from "react";
import "./BroadcastMessageFormContacts.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiDownload, FiUpload, FiInfo, FiEye } from "react-icons/fi";
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import iconContactGreen from "../../../../../assets/img/broadcast-message/contacts-green.svg";
import iconContactGreen2 from "../../../../../assets/img/broadcast-message/contacts-green-2.svg";
import { convertFileToCsv, doToast } from "../../../../../helper/HelperGeneral";
import BroadcastMessageContactsPreview from "./broadcastMessageContactsPreview/BroadcastMessageContactsPreview";

let BroadcastMessageFormContacts = (props) => {
    let [showReplace, setShowReplace] = useState(false);
    let [openPreview, setOpenPreview] = useState(false);
    let [headerResult, setHeaderResult] = useState([]);
    let [headerResultTemp, setHeaderResultTemp] = useState({
        list: [],
        time: false
    });
    let [contactsResult, setContactsResult] = useState([]);
    let [contactsResultTemp, setContactsResultTemp] = useState({
        list: [],
        time: false
    });
    let [contactsFileName, setContactsFileName] = useState("");

    useEffect(() => {
        if(headerResultTemp.time) {
            setHeaderResult(headerResultTemp.list);
        }
    }, [headerResultTemp])

    useEffect(() => {
        if(contactsResultTemp.time) {
            setContactsResult(contactsResultTemp.list);

            props.onChangeCsvCustomContacts({
                header: headerResult,
                body: contactsResultTemp.list
            })
        }
    }, [contactsResultTemp])

    let togglePreview = () => {
        setOpenPreview(!openPreview);
    }
    
    let toggleReplace = () => {
        setShowReplace(!showReplace);
    }

    let onChangeContactsCsvFile = (e, file = false) => {
        let _file = file ? file : e.target.files[0];

        let fileExt = _file.name.split(".");
        
        if(fileExt[fileExt.length - 1] !== "csv") {
            doToast("Invalid CSV File", "fail")
        }else {
            convertFileToCsv(_file, {
                onSuccessConvertCsv: (result) => {
                    let errorIndex = [];
                    
                    let findIndex = (array, key) => {
                        return array.findIndex(v => v === key);
                    }

                    if(findIndex(result[0], "phone_number") === -1) {
                        errorIndex.push("phone_number");
                    }
                    if(findIndex(result[0], "full_name") === -1) {
                        errorIndex.push("full_name");
                    }
                    
                    if(findIndex(result[0], "alias") === -1) {
                        errorIndex.push("alias");
                    }

                    if(errorIndex.length > 0) {
                        doToast(`Failed to import CSV file (${errorIndex.join(", ")} not found)`, "fail");
                    }else {
                        setContactsFileName(_file.name);
    
                        let _headers = [];
                        let _headersRes = [];
                        let newResult = [];
        
                        result.map((rowVal, index) => {
                            if(index === 0) {
                                _headers = rowVal;
        
                                _headers.map((_v, _i) => {
                                    _headersRes.push({
                                        value: _v,
                                        label: _v
                                    })
        
                                    return null;
                                })
                            }else {
                                newResult.push(rowVal);
                            }
        
                            return null;
                        })

                        doToast("Import csv file success");
        
                        setHeaderResultTemp({
                            time: new Date().valueOf(),
                            list: _headersRes,
                        });
        
                        setContactsResultTemp({
                            time: new Date().valueOf(),
                            list: newResult
                        })
                    }
                }
            });
        }

        if(e) {
            e.target.value = null;
        }
    }

    let handleDropFile = (e) => {
        e.preventDefault();
        let files= e.dataTransfer.files;
        let _fileType = files[0].type;

        if(_fileType.split("/")[1] !== "csv") {
            doToast("Invalid CSV File", "fail")
        }else {
            onChangeContactsCsvFile(false, files[0])
        }
    }

    let ModalReplace = () => (
        <Modal 
            isOpen={showReplace} 
            className="modal-replace-data" 
            toggle={() => {
                toggleReplace();
                let el = document.querySelector("#contacts-csv-file-select");
                el.click();
            }
        }>
            <ModalBody>
                <b className="modal-title">
                    Replace CSV File?
                </b>

                <p className="modal-desc">
                    You will be replacing this file, all the data imported from this file will be fully replaced by the data from new imported file. 
                    Are you sure you want to continue?
                </p>

                <ButtonWithLoadingOrIcon 
                    text="Cancel"
                    className="main-button-48 no-fill-button"
                    onClickAction={toggleReplace}
                />

                <ButtonWithLoadingOrIcon 
                    text="Replace File"
                    className="main-button-48 orange-button"
                    onClickAction={() => {
                        toggleReplace();
                        let el = document.querySelector("#contacts-csv-file-select");
                        el.click();
                    }}
                />
            </ModalBody>
        </Modal>
    )

    return (
        <div className="broadcast-message-form">
            <ModalReplace />

            <BroadcastMessageContactsPreview 
                isOpen={openPreview}
                toggle={togglePreview}
                data={{
                    header: headerResult,
                    body: contactsResult
                }}
            />

            <input type="file" id="contacts-csv-file-select" onChange={(e) => onChangeContactsCsvFile(e)} />

            <div className={`preview-outer-wrapper `}>
                {contactsFileName === "" &&
                    <div 
                        className="preview-inner-wrapper"
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={(e) => handleDropFile(e)}
                    >
                        <>
                            <img src={iconContactGreen} alt="" className="import-csv-icon" />

                            <p className="import-csv-title">
                                <b>Import CSV</b>
                            </p>

                            <p className="import-csv-desc">
                                Import existing recipients by importing CSV
                                <br />
                                Drop your CSV file here or Press Select File Button Below
                            </p>
                        </>

                        {/* <>  
                            <div className="lds-ring">
                                <div /><div /><div /><div />
                            </div>
                            <p className="importing-csv-title">
                                <b>Importing CSV File</b>
                            </p>
                        </> */}
                    </div>
                }

                {contactsFileName !== "" &&
                    <div className="preview-inner-wrapper-2">
                        <>
                            <img src={iconContactGreen2} alt="" className="csv-fileicon" />

                            <p className="csv-filename">
                                <b>{contactsFileName}</b>
                            </p>

                            <p className="preview-data-button" onClick={togglePreview}>
                                <FiEye />
                                <b>Preview Data</b>
                            </p>
                        </>
                    </div>
                }
            </div>

            {contactsResult.length > 0 &&
                <p className="total-recipient">
                    <b>Total Recipients : {contactsResult.length}</b>
                </p>
            }

            <p className="contacts-info">
                <FiInfo />
                <b>Only recipient with valid WhatsApp number will be sent.</b>
            </p>

            <div className="outer-button-wrapper">
                {contactsFileName === "" ?
                    <ButtonWithLoadingOrIcon 
                        text="Select File"
                        className="main-button-40 orange-button"
                        icon={{
                            type: "svg",
                            src: FiUpload
                        }}
                        position="left"
                        onClickAction={() => {
                            let el = document.querySelector("#contacts-csv-file-select");
                            el.click();
                        }}
                    />
                    :
                    <ButtonWithLoadingOrIcon 
                        text="Replace File"
                        className="main-button-40 orange-button"
                        icon={{
                            type: "svg",
                            src: FiUpload
                        }}
                        position="left"
                        onClickAction={toggleReplace}
                    />
                }
                
                <a  
                    href={require(`../../../Custom_contacts_example.csv`)}
                    download="Custom_contacts_example.csv"
                >
                    <ButtonWithLoadingOrIcon 
                        text="Download CSV Template"
                        className="main-button-40 no-fill-button"
                        icon={{
                            type: "svg",
                            src: FiDownload
                        }}
                        position="left"
                    />
                </a>
            </div>
        </div>
    )
}

export default BroadcastMessageFormContacts;