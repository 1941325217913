import React, { useState } from "react";
import "./ModalChooseChannel.scss";
import { Modal, ModalBody } from "reactstrap";
import { FiEdit3 } from "react-icons/fi";
import { TAPLIVE_MEDIUM } from "../../../../../constants/taplive";
import LogoWhatsAppSME from "../../../../../assets/img/integration/logo-whatsapp-sme.svg";
import LogoWhatsAppBusiness from "../../../../../assets/img/integration/logo-whatsapp-business.svg";
import ButtonWithLoadingOrIcon from "../../../../../components/reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

let ModalChooseChannel = (props) => {
  let [whatsAppSMESelected, setwhatsAppSMESelected] = useState(true);
  let [whatsAppBusinessSelected, setwhatsAppBusinessSelected] = useState(false);
  let [loadingStartConversation, setLoadingStartConversation] = useState(false);

  let startConversation = () => {
      setLoadingStartConversation(true);
      // TODO
      // setTimeout(() => {
        setLoadingStartConversation(false);
        cancelModal()

        if(props.toggleModalStartConversation) {
          props.toggleModalStartConversation(whatsAppBusinessSelected ? "waba" : "sme")
        }
      // }, 2000);
  }

  let cancelModal = () => {
      setwhatsAppSMESelected(false)
      setwhatsAppBusinessSelected(false)
      setLoadingStartConversation(false)
      props.toggleModalChooseChannelProps(false)
  }

  return (
    <Modal
      className="modal-choose-channel"
      isOpen={props.isShowModalChooseChannelProps}
      toggle={cancelModal}
    >
      <ModalBody>
        <div id="modal-choose-channel-title">
          <b>
            <FiEdit3 className="icon-edit" />
            New Message
          </b>
        </div>

        <div>
          <b>Channel Type</b>
        </div>

        <div className="modal-choose-channel-options">
          <div
            className={
              whatsAppSMESelected ? "modal-choose-channel-selected" : ""
            }
            onClick={() => {
              setwhatsAppSMESelected(true);
              setwhatsAppBusinessSelected(false);
            }}
          >
            <img src={LogoWhatsAppSME} alt="" />
            <b>WhatsApp SME</b>
          </div>
          <div
            className={
              whatsAppBusinessSelected ? "modal-choose-channel-selected" : ""
            }
            onClick={() => {
              setwhatsAppBusinessSelected(true);
              setwhatsAppSMESelected(false);
            }}
          >
            <img src={LogoWhatsAppBusiness} alt="" />
            <b>WhatsApp Business API</b>
          </div>
        </div>

        <div className="modal-choose-channel-buttons">
          <button
            className="no-fill-button"
            onClick={cancelModal}
            disabled={loadingStartConversation}
          >
            <b>Cancel</b>
          </button>

          <ButtonWithLoadingOrIcon
            className="orange-button main-button-40"
            text="Continue"
            position="left"
            loadingColor="gray"
            onClickAction={props.onClickContinue ? () => props.onClickContinue(whatsAppSMESelected ? TAPLIVE_MEDIUM.whatsapp : TAPLIVE_MEDIUM.whatsappba) : startConversation}
            isLoading={loadingStartConversation}
            isDisabled={(!whatsAppSMESelected && !whatsAppBusinessSelected) || loadingStartConversation}
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

// const mapStateToProps = state => ({
//     changeContactInfo: state.changeContactInfo,
//     caseSortBy: state.caseSortBy,
//     countryList: state.countryList
// });

// export default connect(mapStateToProps, null)(ModalChooseChannel);
export default ModalChooseChannel;
