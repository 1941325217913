import React, { useEffect, useState } from "react";
import "./FilterReportForm.scss";
import AdvancedDateFilter from "../../reuseableComponent/advancedDateFilter/AdvancedDateFilter";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SelectTopic from "../../reuseableComponent/selectTopic/SelectTopic";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import TopicService from "../../../services/newServices/TopicServices";
import MessageHistoryFilter from "../../reuseableComponent/messageHistoryFilter/MessageHistoryFilter";
import NumberDropdown from "../../reuseableComponent/numberDropdown/NumberDropdown";

const TopicReportForm = props => {
  let { 
    match,
    topicReportData,
    withoutGetTopicList,
    showMessageFilterButton,
    showNumberDropdown
    // maxRange
  } = props;
  
  let [isWaitLoadingTopicList, setIsWaitLoadingTopicList] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  //   let [isCheckAll, setIsCheckAll] = useState(false);
  //   let [selectedTopic, setSelectedTopic] = useState([]);
  //   let [topicListOrigin, setTopicListOrigin] = useState([]);
  //   let [topicList, setTopicList] = useState([]);
  let [searchTopic, setSearchTopic] = useState("");
  let [filterDataTopic, setFilterDataTopic] = useState({
    selectedTopic: [],
    isCheckAll: false,
    topicListOrigin: [],
    topicList: [],
  });
  let [filterDataMessageHistory, setFilterDataMessageHistory] = useState({
    messageTypes: [],
    channelTypes: [],
    status: "",
    contactPhone: "",
  });
  let [dateVal, setDateVal] = useState({
    from:  new Date(new Date().setDate(new Date().getDate() - 30)),
    to: new Date()
  });
  let [filterDateForCalender, setFilterDateForCalender] = useState({
    from: undefined,
    to: undefined
  });
  let [filterBy, setFilterBy] = useState({
    text: "Last 30 Days",
    val: "last_30"
  });

  let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);
  let [isShowDropdownPageSize, setIsShowDropdownPageSize] = useState(false);

  const pageSizeSelectionArray = [20, 50, 100];
  let [selectedPageSize, setSelectedPageSize] = useState(pageSizeSelectionArray[0]);
  
  useEffect(() => {
    if(!withoutGetTopicList && !topicReportData) {
      getTopic();
    }
    
    if(topicReportData) {
      actionSetFilterOrigin();
    }
  }, []);

  let actionSetFilterOrigin = () => {
    let _topicReportData = {...topicReportData};
    setFilterDataTopic(_topicReportData.filterDataTopic);
    setDateVal(_topicReportData.dateVal);
    setFilterDateForCalender(_topicReportData.filterDateForCalender);
    setFilterBy(_topicReportData.filterBy);
  }

  // let actionSetTopicReportData = () => {

  // }

  let getTopic = () => {
    setIsWaitLoadingTopicList(true);
    
    TopicService.getTopic(match.params.orgID, "list", null, (response) => {
      if (response.dataResult.error.message === "") {
        let _filterDataTopic = {...filterDataTopic};

        response.dataResult.data.topics.push({
          id: 0,
          name: "NO TOPIC"
        })

        _filterDataTopic.topicList = response.dataResult.data.topics;
        _filterDataTopic.topicListOrigin = response.dataResult.data.topics;  

        setFilterDataTopic(_filterDataTopic);
      } else {
        let _retriveDataError = { ...retriveDataError };
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsWaitLoadingTopicList(false);
    });
  }

  let onCheckAll = (e) => {
    let _filterDataTopic = {...filterDataTopic};

    if (e.target.checked) {
      _filterDataTopic.selectedTopic = _filterDataTopic.topicList;
    } else {
      _filterDataTopic.selectedTopic = [];
    }

    _filterDataTopic.isCheckAll = e.target.checked;

    setFilterDataTopic(_filterDataTopic);
  }

  const onClearAll = () => {
    let _filterDataTopic = {...filterDataTopic};

    _filterDataTopic.isCheckAll = false;
    _filterDataTopic.selectedTopic = [];

    setFilterDataTopic(_filterDataTopic);
  }

  let isChecked = (id) => {
    let _filterDataTopic = {...filterDataTopic};
    const _data = [..._filterDataTopic.selectedTopic];
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let onChangeSingleSelect = (e, val) => {
    let _filterDataTopic = {...filterDataTopic};

    let _selectedTopic = [..._filterDataTopic.selectedTopic];

    if (e.target.checked) {
      _selectedTopic = [..._selectedTopic, val];
    } else {
      const _dataSelectedTopic = [..._selectedTopic]
      _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id);
      _filterDataTopic.isCheckAll = false;
    }

    _filterDataTopic.selectedTopic = _selectedTopic;
    setFilterDataTopic(_filterDataTopic);
  }

  let removeSingleTopic = (val) => {
    let _filterDataTopic = {...filterDataTopic};

    _filterDataTopic.isCheckAll = false;
    let _selectedTopic = [..._filterDataTopic.selectedTopic];
    const _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    _filterDataTopic.selectedTopic = _selectedTopic;
    setFilterDataTopic(_filterDataTopic);
  }

  let onChangeSearchTopic = (e) => {
    let result = [];
    let _filterDataTopic = {...filterDataTopic};
    let _topicOrigin = _filterDataTopic.topicListOrigin.slice();
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
      for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
          let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
          if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
          }
        }
      }
    }

    _filterDataTopic.topicList = result;
    setFilterDataTopic(_filterDataTopic);
  }

  let _cancelTopicReport = () => {
    actionSetFilterOrigin();
    props.cancelTopicReport();
  }

  let _submitFilterReportForm = () => {
    props.submitFilterReport({
        filterDataTopic: {...filterDataTopic},
        filterDataMessageHistory: {...filterDataMessageHistory},
        dateVal: {...dateVal},
        filterDateForCalender: {...filterDateForCalender},
        filterBy: {...filterBy},
        pageSize: selectedPageSize
    })
  }

  let onNumberSelected = (val) => {
    setSelectedPageSize(val);
  }

  let onMessageHistoryFilterUpdated = (val) => {
    setFilterDataMessageHistory(val);
  }

  return (
    <>
        {isWaitLoadingTopicList || retriveDataError.code ?
            <RetrieveDataLoading
                noPaddingTop={true}
                isLoading={isWaitLoadingTopicList}
                errorMessage={retriveDataError.message}
            />
            :
            <>
                <div className="form-filter-report">
                    <div className={showMessageFilterButton ? "date-report-select-wrapper" : ""}>
                        {/* {
                          !showMessageFilterButton &&
                          <label>
                              <b>Date</b>
                          </label>
                        } */}
                        <AdvancedDateFilter 
                            toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
                            isOpenDropdown={isShowDropdownDate}
                            dateVal={dateVal}
                            filterDateForCalender={filterDateForCalender}
                            filterBy={filterBy}
                            onClickDate={(date) => setDateVal(date)}
                            onClickFilterBy={(filter) => setFilterBy(filter)}
                            onClickCalendar={(date) => setFilterDateForCalender(date)}
                            ignoreAllTime
                            isDisabled={false}
                            disabledDays={{
                                after: new Date()
                            }}
                            maxRange={365}
                        />
                    </div>
                    
                    {!withoutGetTopicList &&
                      <SelectTopic 
                          onChangeSearchTopic={onChangeSearchTopic}
                          onCheckAll={onCheckAll}
                          onClearAll={onClearAll}
                          topicList={filterDataTopic.topicList}
                          onChangeSingleSelect={onChangeSingleSelect}
                          isChecked={isChecked}
                          isCheckAll={filterDataTopic.isCheckAll}
                          searchTopic={searchTopic}
                          title={"Topics"}
                          isTitleOptional
                          selectedTopic={filterDataTopic.selectedTopic}
                          removeSingleTopic={removeSingleTopic}
                      />
                    }

                    {
                      showMessageFilterButton &&
                      <MessageHistoryFilter 
                        currentFilter={filterDataMessageHistory}
                        onFilterUpdated={onMessageHistoryFilterUpdated}
                        isDisabled={false}
                      />
                    }

                    {
                      showNumberDropdown &&
                      <NumberDropdown 
                        isOpenDropdown={isShowDropdownPageSize}
                        toggleDropdown={() => setIsShowDropdownPageSize(!isShowDropdownPageSize)}
                        numbersArray={pageSizeSelectionArray}
                        selectedNumber={selectedPageSize}
                        onNumberSelected={onNumberSelected}
                        isDisabled={false}
                      />
                    }

                    <div className="form-filter-report-button-wrapper">
                      {!props.withoutCancel &&
                        <ButtonWithLoadingOrIcon 
                            className="no-fill-button main-button-48"
                            onClickAction={_cancelTopicReport}
                            text="Cancel"
                            position="left"
                        />
                      }

                      <ButtonWithLoadingOrIcon 
                          className="orange-button main-button-48"
                          onClickAction={_submitFilterReportForm}
                          text="Search"
                          position="left"
                      />
                    </div>

                </div>
            </>
        }
    </>
  );
};

export default TopicReportForm;