import React, { useState, useEffect } from "react";
import "./UserContact.scss";
import "react-toastify/dist/ReactToastify.css";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import { FiPlus } from "react-icons/fi";
import iconUser from '../../assets/img/user-contact/icon-user-plus.svg'
import iconNew from '../../assets/img/user-contact/icon-add-new.svg'
import iconImport from '../../assets/img/user-contact/icon-add-import.svg'
import iconUpload from '../../assets/img/user-contact/icon-upload.svg'
import iconAdd from '../../assets/img/user-contact/icon-add-user.svg'
import { FiX, FiSearch, FiAlertTriangle, FiRotateCcw, FiDownload } from "react-icons/fi";
import { Modal, ModalBody } from 'reactstrap';
import Pagination from '../reuseableComponent/pagination/Pagination'
import CustomSelectOption from '../reuseableComponent/customSelectOption/CustomSelectOption'
import ServiceOrganization from '../../services/organization/ServiceOrganization'
import UserContactServices from '../../services/newServices/UserContactServices'
import { checkID, doToast, convertFileToCsv } from '../../helper/HelperGeneral';
import HelperInput from '../../helper/HelperInput';
import HelperDate from '../../helper/HelperDate'
import PopupRemove from '../reuseableComponent/popupRemove/PopupRemove';
import iconWhatsapp from '../../assets/img/user-contact/icon-whatsapp.svg'
import iconWhatsappBa from '../../assets/img/user-contact/icon-whatsapp-ba.svg'
import iconFacebook from '../../assets/img/user-contact/icon-facebook.svg'
import iconGoogle from '../../assets/img/user-contact/icon-google.svg'
import iconGoogleProfile from '../../assets/img/user-contact/icon-google-profile.svg'
import iconTelegram from '../../assets/img/user-contact/icon-telegram.svg'
import iconLauncher from '../../assets/img/user-contact/icon-launcher.svg'
import iconBroadcast from '../../assets/img/user-contact/icon-broadcast.svg'
import iconTwitter from '../../assets/img/user-contact/icon-twitter.svg'
import iconLine from '../../assets/img/user-contact/icon-line.svg'
import iconInstagram from '../../assets/img/user-contact/icon-instagram.svg'
import iconTokopedia from '../../assets/img/user-contact/icon-tokopedia.svg'
import userTemplate from './user-contact-template.csv'
import ButtonWithLoadingOrIcon from '../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'

const UserContact = props => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [showModalAddContact, setShowModalAddContact] = useState(false);
  const [listPage, setListPage] = useState(20)
  const [isSearchFocus, setIsSearchFocus] = useState(false)
  const [showModalImporting, setShowModalImporting] = useState(false)
  const [isImporting, setIsImporting] = useState(false);
  // const [firstLoad, setFirstLoad] = useState(false)
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  // const [isFailedImporting, setIsFailedImporting] = useState(false);
  const [isLoadingTableUser, setIsLoadingTableUser] = useState(false)
  const [dataUserContact, setDataUserContact] = useState([])
  const [totalUsers, setTotalUsers] = useState(0)
  const [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  const [pageDisplay, setPageDisplay] = useState(1)
  const [isShowModalSaveChanges, setIsShowModalSaveChanges] = useState(false)
  // const [waitForResponseSaveChanges, setWaitForResponseSaveChanges] = useState(false)
  const [isShowModalDiscard, setIsShowModalDiscard] = useState(false)
  // const [waitForResponseDiscard, setWaitForResponseDiscard] = useState(false)
  const [query, setQuery] = useState("")
  const [submitQuery, setSubmitQuery] = useState("")
  const [dataUserImport, setDataUserImport] = useState([])
  // const [importCount, setImportCount] = useState(0)
  const [loadingExportContact, setLoadingExportContact] = useState(false)

  const listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      // getUserContactList(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const getUserContactList = (orgID, onload, dataContact = {}) => {
    let _retriveDataError = { ...retriveDataError };
    setIsLoadingTableUser(true);
    
    let data = {}
    if (Object.keys(dataContact).length === 0) {
      data.pageDisplay = pageDisplay
      data.listPage = listPage
      data.submitQuery = submitQuery
    } else {
      data.pageDisplay = dataContact.pageDisplay
      data.listPage = dataContact.listPage
      data.submitQuery = dataContact.submitQuery
    }

    UserContactServices.getContactList(orgID, data.pageDisplay, data.listPage, data.submitQuery, (response) => {
      if (response.dataResult.status === 200) {
        setDataUserContact(response.dataResult.data.users)
        setTotalUsers(response.dataResult.data.totalUsers)
        setIsLoadingTableUser(false);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail');
        setIsLoadingTableUser(false);
      }
    });
  };

  const downloadReport = (data) => {
    // const id = orgID.split('-');
    // const timestamp = + new Date();
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `User_contacts.csv`);
    document.body.appendChild(link);
    link.click();
  }

  const exportContactsList = () => {
    setLoadingExportContact(true);

    UserContactServices.exportContacts(orgID, {format: "csv"}, (response) => {
      setLoadingExportContact(false);
      downloadReport(response.dataResult)
    })
  }

  useEffect(() => {
    const paramID = checkID(match);
    if (props.history.location.state) {
      if (props.history.location.state.previousPage) {
        const dataState = props.history.location.state.previousPage

        if (dataState.pageDisplay && dataState.listPage && dataState.submitQuery !== undefined) {
          setPageDisplay(dataState.pageDisplay)
          setListPage(dataState.listPage)
          setListPageVal({
            label: `${dataState.listPage}`,
            value: dataState.listPage
          })
          setQuery(dataState.submitQuery)
          setSubmitQuery(dataState.submitQuery)
          const dataContact = {
            pageDisplay: dataState.pageDisplay,
            listPage: dataState.listPage,
            submitQuery: dataState.submitQuery
          }
          getUserContactList(paramID, false, dataContact);
        } else {
          getUserContactList(paramID, false);
        }
      }
      window.history.pushState(null, '');
    } else {
      getUserContactList(paramID, true);
    }

  }, [props.history])

  useEffect(() => {
    doMount();
  }, []);

  const closeModalAddContact = () => {
    setShowModalAddContact(false);
  }

  const closeModalImporting = () => {
    setShowModalImporting(false);
  }

  const addNewUserHandler = () => history.push(`/o/${orgID}/operational/user-contact/add`)

  const importContact = (dataUser) => {
    setShowModalImporting(true)
    setIsImporting(true);

    UserContactServices.importContact(orgID, dataUser, (response) => {
      if (response.dataResult.status === 200) {
        closeModalImporting();
        if (!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, 'fail');
        } else {
          closeModalAddContact()
          // window.location.reload();
          const paramID = checkID(match);
          getUserContactList(paramID, true);
          doToast(response.dataResult.data.message);
        }
      } else {
        setIsImporting(false);
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  }

  const importContactHandler = () => {
    // let guessDelimiters = (text, possibleDelimiters) => {
    //   return possibleDelimiters.filter(weedOut);
  
    //   function weedOut (delimiter) {
    //       var cache = -1;
    //       return text.split('\n').every(checkLength);
  
    //       function checkLength (line) {
    //           if (!line) {
    //               return true;
    //           }
  
    //           var length = line.split(delimiter).length;
    //           if (cache < 0) {
    //               cache = length;
    //           }
    //           return cache === length && length > 1;
    //       }
    //   }
    // }
    
    const input = document.querySelector('.input-contact-csv')
    input.click()

    input.onchange = (e) => {
      if (input.files.length > 0) {

        // var reader = new FileReader();
        // reader.onload = function () {
        //   const data = reader.result.split('\n')
        //   const cleanData = data.map(item => item.replace(/"/g, ""))
        //   const rawDataUser = cleanData.map(item => item.split(guessDelimiters(item, [";", ","])[0]))

        //   const dataUser = rawDataUser.slice(1)
        //   setDataUserImport(dataUser)

        //   // call api importing contact
        //   importContact(dataUser)
        // };

        // // start reading the file. When it is done, calls the onload event defined above.
        // reader.readAsText(input.files[0]);
        //sampe sini

        convertFileToCsv(e.target.files[0], {
          onSuccessConvertCsv: (result) => {
              result.splice(0, 1);
              
              setDataUserImport(result);

              importContact(result);
          }
        })

        e.target.value = null;
      }
    }
  }

  const renderModalImporting = () => {
    return (
      <Modal isOpen={showModalImporting} className={"importing-contact-modal"}>
        <ModalBody>
          <div className="importing-contact-wrapper">
            {
              isImporting ?
                <>
                  <div className="integration-list-loading-wrapper">
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  </div>
                  <p className="importing-text">
                    Importing... Please make sure you have a stable connection.
                  </p>
                </>
                :
                <>
                  <div className="warning-svg">
                    <FiAlertTriangle />
                  </div>
                  <p className="importing-text">Failed to import CSV File.</p>
                  
                  <ButtonWithLoadingOrIcon 
                    text="Cancel"
                    onClickAction={() => {
                      setShowModalImporting(false);
                    }}
                    className="no-fill-button main-button-48"
                  />

                  <ButtonWithLoadingOrIcon 
                    text="Retry"
                    icon={{
                      type: "svg",
                      src: FiRotateCcw
                    }}
                    onClickAction={() => importContact(dataUserImport)}
                    className="orange-button main-button-48"
                  />
                </>
            }
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const renderModal = () => {
    return (
      <Modal isOpen={showModalAddContact} toggle={closeModalAddContact} className={"add-contact-modal"}>
        <ModalBody>
          <div className="add-contact-wrapper">
            <b className="add-contact-title">Add Contact</b>
            <p className="add-contact-description">There are 2 ways to add a contact.</p>

            <FiX onClick={closeModalAddContact} />

            <div className="add-contact-option-container">
              <div className="add-contact-new-user">
                <img src={iconNew} alt="" />
                <p>
                  <b>New Contact</b>
                </p>
                <p className="add-description">Create a new contact by inputting name and phone number.</p>
                <button className="orange-button add-new-contact-btn" onClick={addNewUserHandler}>
                  <img src={iconAdd} alt="" />
                  Add New Contact
                </button>
              </div>
              <div className="add-contact-import-contact">
                <img src={iconImport} alt="" />
                <p>
                  <b>Import Contacts</b>
                </p>
                <p className="add-description">Download the template and use it to bring your existing contacts here.</p>
                <div className="button-import-container">
                  <a href={userTemplate} target="_blank" rel="noopener noreferrer" download="user-contact-template.csv">
                    <button className="no-fill-button no-border">
                      <FiDownload />
                      Download Template
                    </button>
                  </a>
                  <button className="orange-button upload-csv-btn" onClick={importContactHandler}>
                    <img src={iconUpload} alt="" />
                    Upload CSV
                  </button>
                  <input type="file" className="input-contact-csv" style={{ display: 'none' }} />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    )
  }

  const handleChangePage = (page) => {
    const data = {
      pageDisplay: page,
      listPage: listPage,
      submitQuery: submitQuery
    }
    const paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setPageDisplay(page)
  }

  const searchFocus = () => {
    setIsSearchFocus(true);
  }

  const searchBlur = () => {
    setIsSearchFocus(false);
  }

  const onSelectListPage = (val) => {
    const data = {
      pageDisplay: 1,
      listPage: val.value,
      submitQuery: submitQuery
    }
    const paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setListPageVal(val)
    setListPage(val.value)
    setPageDisplay(1)
  }

  const toggleModalSaveChanges = () => {
    setIsShowModalSaveChanges(!isShowModalSaveChanges)
  }

  const saveChanges = () => {
    // console.log('save changes')
  }

  const toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  const discardProgress = () => {
    // console.log('discard progress')
  }

  const source =  (src) => {
    switch (src) {
      case 'gb_profile':
        return {
          text: 'Google Business Profile',
          icon: iconGoogleProfile
        }
      case 'google_business':
        return {
          text: 'Google Business Messages',
          icon: iconGoogle
        }
      case 'customer':
        return {
          text: 'Customer',
          icon: iconUser
        }
      case 'facebook': {
        return {
          text: 'Facebook Messenger',
          icon: iconFacebook
        }
      }
      case 'launcher': {
        return {
          text: 'Live Chat',
          icon: iconLauncher
        }
      }
      case 'line': {
        return {
          text: 'LINE',
          icon: iconLine
        }
      }
      case 'telegram': {
        return {
          text: 'Telegram',
          icon: iconTelegram
        }
      }
      case 'twitter': {
        return {
          text: 'Twitter DM',
          icon: iconTwitter
        }
      }
      case 'instagram': {
        return {
          text: 'Instagram DM',
          icon: iconInstagram
        }
      }
      case 'whatsapp': {
        return {
          text: 'WhatsApp SME',
          icon: iconWhatsapp
        }
      }
      case 'whatsappba': {
        return {
          text: 'WhatsApp Business API',
          icon: iconWhatsappBa
        }
      }
      case 'manual': {
        return {
          text: 'User Added',
          icon: iconUser
        }
      }
      case 'manual_import': {
        return {
          text: 'Manual Import',
          icon: iconUser
        }
      }
      case 'broadcast': {
        return {
          text: 'Broadcast',
          icon: iconBroadcast
        }
      }
      case 'integration_api': {
        return {
          text: 'Integration API',
          icon: iconUser
        }
      }
      case 'integrationAPI': {
        return {
          text: 'Integration API',
          icon: iconUser
        }
      }
      case 'inbox_new_chat': {
        return {
          text: 'Inbox - New Chat',
          icon: iconUser
        }
      }
      case 'tokopedia': {
        return {
          text: 'Tokopedia',
          icon: iconTokopedia
        }
      }
      default: {
        return {
          text: 'User Added',
          icon: iconUser
        }
      }
    }
  }

  const onChangeSearch = (e) => {
    setQuery(e.target.value)
  }

  const onSubmitSearch = (e) => {
    e.preventDefault()
    const data = {
      pageDisplay: 1,
      listPage: listPage,
      submitQuery: query
    }
    const paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setPageDisplay(1)
    setSubmitQuery(query)
  }

  const goToDetailContact = (id) => {
    history.push({
      pathname: `/o/${orgID}/operational/user-contact/${id}`,
      state: {
        pageDisplay,
        listPage,
        submitQuery
      }
    })
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="sectionWrap add-contact-main-wrapper">
        {renderModal()}
        {renderModalImporting()}

        <>
          <div className="topContent add-contact-header">
            <div className="title">Contacts <span className="grey-font">({totalUsers})</span></div>
            <div className="topContent-button-wrapper">
              {!loadingExportContact ?
                <ButtonWithLoadingOrIcon
                  className="no-fill-button main-button-40"
                  text="Download All"
                  position="left"
                  onClickAction={exportContactsList}
                  icon={{
                    type: "svg",
                    src: FiDownload
                  }}
                />
                :
                <ButtonWithLoadingOrIcon
                  className="no-fill-button main-button-40"
                  text="Download All"
                  isLoading
                  loadingColor={"#ff7e00"}
                  position="left"
                />
              }

              <ButtonWithLoadingOrIcon
                className="orange-button main-button-40"
                text="Add Contact"
                position="left"
                onClickAction={() => setShowModalAddContact(true)}
                icon={{
                  type: "svg",
                  src: FiPlus
                }}
              />
            </div>
          </div>

          {
            (isLoadingTableUser || retriveDataError.code) ?
              <RetrieveDataLoading
                isLoading={isLoadingTableUser}
                errorMessage={retriveDataError.message}
              />
              :
              <React.Fragment>
                <form className={`add-contact-search ${isSearchFocus ? 'focus-search' : ''}`} onFocus={searchFocus} onBlur={searchBlur} onSubmit={onSubmitSearch}>
                  <input type="text" placeholder="Search for a contact" value={query} onChange={onChangeSearch} />
                  <span onClick={onSubmitSearch}>
                    <FiSearch />
                  </span>
                </form>

                <div className="add-contact-list-table-wrapper">
                  <div className="custom-table">
                    <table className="custom-table-content table-user-contact-list">
                      <thead>
                        <tr>
                          <td className="col_name">Full Name</td>
                          <td className="col_email">Email</td>
                          <td className="col_phone">Phone Number</td>
                          <td className="col_origin">Origin</td>
                          <td className="col_created">Created At</td>
                          <td>
                            <CustomSelectOption
                              optionListProps={listPageOption}
                              valueDropdownProps={listPageVal}
                              onClickDropDownListOptionProps={onSelectListPage}
                            />
                          </td>
                        </tr>
                      </thead>

                      <tbody>
                        {
                          dataUserContact.length > 0 ?

                            dataUserContact.map(item => {
                              return (
                                <tr key={`user-contact-${item.userID}`} onClick={() => goToDetailContact(item.userID)}>
                                  <td className="col_name">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user" > */}
                                    <p className="">{item.fullName}</p>
                                    <p className="col_name_alias">{item.alias ? item.alias : <span className="unavailable">-</span>}</p>
                                    {/* </NavLink> */}
                                  </td>
                                  <td className="col_email">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user"  > */}
                                    <p>{item.email ? item.email : <span className="unavailable">-</span>}</p>
                                    {/* </NavLink> */}
                                  </td>
                                  <td className="col_phone">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user"  > */}
                                    {item.phone ? "+"+HelperInput.phoneBeautify(item.phone) : <span className="unavailable">-</span>}
                                    {/* </NavLink> */}
                                  </td>
                                  <td className="col-origin">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user col-origin"  > */}
                                    
                                    <p>
                                      <img src={source(item.source).icon} alt="" />
                                      {source(item.source).text}
                                    </p>
                                    {/* </NavLink> */}
                                  </td>
                                  {/* <td className="">{getCreatedTime(item.createdTime)}</td> */}
                                  <td className="col_created">
                                    {/* <NavLink to={`/o/${orgID}/operational/user-contact/${item.userID}`} className="link-user"> */}
                                    {HelperDate.formatToString(new Date(item.createdTime), 'd MMM yyyy')}
                                    {/* </NavLink> */}
                                  </td>
                                  <td></td>
                                </tr>
                              )
                            })
                            :
                            <tr>
                              <td colSpan="6" className="no-data-column"><b className="no-result-found">No Contacts found. Start by clicking “+Add Contact”.</b></td>
                            </tr>
                        }
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-wrapper">
                    {
                      dataUserContact.length > 0 &&
                      <Pagination
                        total={totalUsers}
                        page={pageDisplay}
                        listPerPage={listPage}
                        step={1}
                        onChangePage={handleChangePage}
                      ></Pagination>
                    }
                  </div>
                </div>
              </React.Fragment>
          }
        </>

        <PopupRemove
          removeTitle={`This Contact Has Open Cases from WhatsApp`}
          removeDescription={`To save changes to this contact’s phone number, you must resolve all ongoing cases from WhatsApp.`}
          onClickCancel={toggleModalSaveChanges}
          onClickRemove={saveChanges}
          // waitForResponse={waitForResponseSaveChanges}
          showModalProps={isShowModalSaveChanges}
          submitText="Resolve and Save Changes"
        // colorButton="red-button"
        />
        <PopupRemove
          removeTitle={`Discard Changes?`}
          removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
          onClickCancel={toggleModalDiscard}
          onClickRemove={discardProgress}
          // waitForResponse={waitForResponseDiscard}
          showModalProps={isShowModalDiscard}
          submitText="Resolve and Save Changes"
        // colorButton="red-button"
        />
      </div>
    </SectionWrap >
  );
};

export default UserContact;