import React, { useState, useEffect, useRef } from 'react';
import './ChatRoomMain.scss';
// import ChatRoomNewContact from './chatRoomNewContact/ChatRoomNewContact';
import { FiAlertTriangle, FiClock, FiUpload } from "react-icons/fi";
import { RiEditLine } from "react-icons/ri";
import ChatRoomChatInfo from './chatRoomChatInfo/ChatRoomChatInfo';
import ChatRoomMessageIn from './chatRoomMessageIn/ChatRoomMessageIn';
import ChatRoomMessageInRating from './chatRoomMessageInRating/ChatRoomMessageInRating';
import ChatRoomMessageInBroadcast from './chatRoomMessageInBroadcast/ChatRoomMessageInBroadcast';
import ChatRoomMessageInBroadcastImage from './chatRoomMessageInBroadcastImage/ChatRoomMessageInBroadcastImage';
import ChatRoomMessageInBroadcastVideo from './chatRoomMessageInBroadcastVideo/ChatRoomMessageInBroadcastVideo';
import ChatRoomMessageInBroadcastFile from './chatRoomMessageInBroadcastFile/ChatRoomMessageInBroadcastFile';
import ChatRoomMessageInFile from './chatRoomMessageInFile/ChatRoomMessageInFile';
import ChatRoomMessageInImage from './chatRoomMessageInImage/ChatRoomMessageInImage';
import ChatRoomMessageInVideo from './chatRoomMessageInVideo/ChatRoomMessageInVideo';
import ChatRoomMessageInLocation from './chatRoomMessageInLocation/ChatRoomMessageInLocation';
import ChatRoomMessageInTokopedia from './chatRoomMessageInTokopedia/ChatRoomMessageInTokopedia';
// import ChatRoomMessageInWabaTemplate from './chatRoomMessageInWabaTemplate/ChatRoomMessageInWabaTemplate';
import ChatRoomMessageOut from './chatRoomMessageOut/ChatRoomMessageOut';
import ChatRoomMessageOutRating from './chatRoomMessageOutRating/ChatRoomMessageOutRating';
import ChatRoomMessageOutFile from './chatRoomMessageOutFile/ChatRoomMessageOutFile';
import ChatRoomMessageOutImage from './chatRoomMessageOutImage/ChatRoomMessageOutImage';
import ChatRoomMessageOutVideo from './chatRoomMessageOutVideo/ChatRoomMessageOutVideo';
import ChatRoomMessageOutWabaTemplate from './chatRoomMessageOutWabaTemplate/ChatRoomMessageOutWabaTemplate';
import ChatRoomMessageOutWabaTemplateImage from './chatRoomMessageOutWabaTemplateImage/ChatRoomMessageOutWabaTemplateImage';
import ChatRoomMessageOutBroadcast from './chatRoomMessageOutBroadcast/ChatRoomMessageOutBroadcast';
import ChatRoomMessageOutBroadcastImage from './chatRoomMessageOutBroadcastImage/ChatRoomMessageOutBroadcastImage';
import ChatRoomMessageOutBroadcastVideo from './chatRoomMessageOutBroadcastVideo/ChatRoomMessageOutBroadcastVideo';
import ChatRoomMessageOutBroadcastFile from './chatRoomMessageOutBroadcastFile/ChatRoomMessageOutBroadcastFile';
import ChatRoomMessageOutLocation from './chatRoomMessageOutLocation/ChatRoomMessageOutLocation';
import ChatRoomInputMessage from './chatRoomInputMessage/ChatRoomInputMessage';
// import { Scrollbars } from 'react-custom-scrollbars';
import { taptalk, tapCoreMessageManager, tapCoreChatRoomManager } from '@taptalk.io/web-sdk';
import { setActiveRoom } from '../../../redux/actions/reduxActionActiveRoom';
import { setActiveCasePanel } from '../../../redux/actions/reduxActionActiveCasePanel';
import { clearCaseDetailData } from '../../../redux/actions/reduxActionCaseDetailData';
import { setCaseDetailData } from '../../../redux/actions/reduxActionCaseDetailData';
import { setReplyMessage, clearReplyMessage } from '../../../redux/actions/reduxActionReplyMessage';
import { setIsWaitMarkAsJunk } from '../../../redux/actions/reduxActionIsWaitMarkAsJunk';
import { setReloadCaseDetail, clearReloadCaseDetail } from '../../../redux/actions/reduxActionReloadCaseDetail';
import { clearSubmitHandoverCase } from '../../../redux/actions/reduxActionSubmitHandoverCase';
import { setDraftMessage } from "../../../redux/actions/reduxActionDraftMessage";
import { setActiveHandoverCase, clearActiveHandoverCase } from '../../../redux/actions/reduxActionActiveHandoverCase';
import { setSalestalkSelectedProduct, clearSalestalkSelectedProduct } from '../../../redux/actions/reduxActionSalestalkSelectedProduct';
import { setForwardMessage } from '../../../redux/actions/reduxActionForwardMessage';
import { setReconnectChatRoom } from '../../../redux/actions/reduxActionReconnectChatRoom';
import { setQuickReplyDocument } from '../../../redux/actions/reduxActionQuickReplyDocument';
import { connect } from 'react-redux';
import iconClock from '../../../assets/img/icon-clock.png';
import iconCheck from '../../../assets/img/icon-check.svg'; 
import iconAvatar from '../../../assets/img/icon-avatar-circle-orange.svg';
import iconBot from '../../../assets/img/icon-bot-circle-orange.svg';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupRemove from "../../reuseableComponent/popupRemove/PopupRemove";
// import iconClose from '../../../assets/img/icon-close-circle-white.svg';
import CaseService from '../../../services/chat/caseServices';
import { doToast, rupiahFormat, trimTextTo } from "../../../helper/HelperGeneral";
import { WHATSAPP_FILE_SIZE_LIMIT } from "../../../constants/taplive";
import "react-toastify/dist/ReactToastify.css";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperChat from "../../../helper/HelperChat";
import { TAPLIVE_MEDIUM, BLOCKING_REASON } from "../../../constants/taplive";
// import WebWorker from "../../../helper/HelperWebWorker";
import caseServices from '../../../services/chat/caseServices';
import mixpanel from "mixpanel-browser";
import IntegrationWhatsappBusinessServices from '../../../services/integration/IntegrationWhatsappBusinessServices';
// import PopupRemove from '../../reuseableComponent/popupRemove/PopupRemove';
import MarkAsJunk from '../markAsJunk/MarkAsJunk';
// import Helper from '../../../helper/HelperChat';

// const BLOCK_UNASSIGNED = "You are not assigned to this case";
// const BLOCK_IS_CLOSED = "This case has been closed";

const CHAT_TYPE = {
  TAPChatMessageTypeText : 1001,
  TAPChatMessageTypeImage : 1002,
  TAPChatMessageTypeVideo : 1003,
  TAPChatMessageTypeFile : 1004,
  TAPChatMessageTypeLocation : 1005,
  TAPChatMessageTypeContact : 1006,
  TAPChatMessageTypeSticker : 1007,
  TAPChatMessageTypeLink : 1010,
  TAPChatMessageTypeSystemMessage : 9001,
  TAPChatMessageTypeUnreadMessageIdentifier : 9002,
  TAPChatMessageTypeCaseClosed : 3001,
  TAPChatMessageTypeLeaveReview: 3003,
  TAPChatMessageTypeLeaveReviewDisabled: 3004,
  MessageTypeBroadcastText: 3011,
  MessageTypeBroadcastImage: 3012,
  MessageTypeBroadcastVideo: 3013,
  MessageTypeBroadcastFile: 3014,
  MessageTypeWhatsAppBATemplateText: 3021,
  MessageTypeWhatsAppBATemplateImage: 3022,
  MessageTypeWhatsAppBATemplateVideo: 3023,
  MessageTypeWhatsAppBATemplateFile: 3024,
  MessageTypeTokopedia: 3031
}
    
// var style = {
//     scrollStyle: {
//       position: "relative",
//       backgroundColor: "#ff7d00",
//       right: "-2px",
//       width: "5px",
//       borderRadius: "8px"
//     }
// };

const CLOCK_STATE = {
  isOff: "off",
  isStarted: "started",
  isPaused: "paused"
}

const MESSAGE_TYPE = {
  CASE_CREATED: 3005,
  CASE_CLOSED: 3001,
  CASE_REOPEN: 3002,
  CASE_RATING_BUTTON: 3003,
  CASE_RATING_BUTTON_DISABLED: 3004,
  CASE_UPDATE_AGENT_CHANGE: 3006,
  CASE_UPDATE_DETAIL_UPDATE: 3007
}

var ChatRoomMain = (props) => {
  let [chatDataRoomMain, setChatDataRoomMain] = useState({});
  let [chatDataRoomMainTemp, setChatDataRoomMainTemp] = useState(false);
  let [isLoadingChat, setIsLoadingChat] = useState(false);
  let chatRoomMesageListRef = useRef("messageLists");
  
  // chat room height
  let [showInputMessage, setShowInputMessage] = useState(false);
  let [showCaseClosedPanel, setShowCaseClosedPanel] = useState(false);
  let [takenByOtherAgentOrUnassigned, setTakenByOtherAgentOrUnassigned] = useState(false);
  let [forceHideInputMessage, setForceHideInputMessage] = useState(false); //if case closed or taken by other agent
  let [ignoreBlockingMessage, setIgnoreblockingMessage] = useState(false); //ignore blocking if block reason is case closed
  let [isPauseClockState, setIsPauseClockState] = useState(false);
  // chat room height

  let [loadingTakeOverCase, setLoadingTakeOverCase] = useState(false);

  let [caseDetailVal, setCaseDetailVal] = useState(null);

  // let [webWorkerChatRoomMain, setWebWorkerChatRoomMain] = useState(null);

  let [fileUploadProgress, setFileUploadProgress] = useState({});

  let [hasMoreChatBefore, setHasMoreChatBefore] = useState(false);

  let [showDropFileHere, setShowDropFileHere] = useState(false);
  let [lastDragAndDropFiles, setLastDragAndDropFiles] = useState({
    files: [],
    time: ""
  });

  let [hideReplyButton, setHideReplyButton] = useState(true);
  let [isShowLoadingBlocking, setIsShowLoadingBlocking] = useState(false);
  let [temporaryCaseDetailVal, setTemporaryCaseDetailVal] = useState(false);

  // modal send
  let [showModalSend, setShowModalSend] = useState(false);
  let [checkMarkAsSolved, setCheckMarkAsSolved] = useState(false);
  let [temporaryMessageText, setTemporaryMessageText] = useState(false);

  let toggleModalSend = (clearInput = false) => {
    setCheckMarkAsSolved(false);
    setShowModalSend(!showModalSend);

    if(clearInput) {
      props.clearReplyMessage();
      HelperChat.resetChatRoomHeightAndInputText();
    }
  }
  // modal send 

  //reconnect
  useEffect(() => {
    let _reconChatRoom = props.reconnectChatRoom;
    if(_reconChatRoom && _reconChatRoom > 0) {
      getMessageAfter(true);

      props.setReconnectChatRoom(0);
    }
  }, [props.reconnectChatRoom])
  //reconnect

  //send product
  useEffect(() => {
    if(props.salesTalkSelectedProduct.lastSend) {
      runSendProductMessage();
    }
  }, [props.salesTalkSelectedProduct])
  //send product

  useEffect(() => {
    if(props.activeHandoverCase.lastSubmit) {
      takeOverCaseAction(false)
    }
  }, [props.activeHandoverCase])

  useEffect(() => {
    if(temporaryCaseDetailVal) {
        if(temporaryCaseDetailVal.room.roomID === props.activeRoom.roomID) {
            props.setCaseDetailData(temporaryCaseDetailVal.detail);
        }
    }
  }, [temporaryCaseDetailVal])

  useEffect(() => {
    if(props.submitHandoverCase.lastChange) {
      let _submitHandoverCase = {...props.submitHandoverCase};
      setCaseDetailVal(_submitHandoverCase.data);
      props.setCaseDetailActionProps(_submitHandoverCase.data);
      props.clearSubmitHandoverCase();
    }
  }, [props.submitHandoverCase])

  let [showModalMarkAsJunk, setShowModalMarkAsJunk] = useState(false);
  let [showModalUnmarkAsJunk, setShowModalUnmarkAsJunk] = useState(false);
  let [isLoadingMarkCase, setIsLoadingMarkCase] = useState(false);
  let [isSetMarkAsResolved, setIsSetMarkAsResolved] = useState(false);

  useEffect(() => {
    let _replyMessage = {...props.replyMessage};

    let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
    let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 102 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
    let heightNew = 0;

    let setNewHeight = (height, isSet) => {
      heightNew = height;
      elChatRoomMain.style.setProperty("max-height", "calc(100vh - " + heightNew + "px)", "important");
      _replyMessage.setHeightChatRoom = isSet;
      props.setReplyMessage(_replyMessage);
    }
    
    if(_replyMessage.message && !_replyMessage.setHeightChatRoom) {
      setNewHeight(chatRoomContainerHeight + 68, true);
    }
  }, [props.replyMessage])

  useEffect(() => {
  //   let myAgentID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;

  //   if(props.startConversation.conversation) {
  //     setForceStartConversation(true);
  //   }else {
  //     setForceStartConversation(false);
      
  //     if(caseDetailVal !== null) {
  //       if((caseDetailVal.agentAccountID !== myAgentID) || (caseDetailVal.agentAccountID === 0)) {
  //         setShowInputMessage(false);
  //       }
  //     }
  //   }
    if(props.startConversation.conversation) {
      setShowInputMessage(true);
      setForceHideInputMessage(false);            
      setTakenByOtherAgentOrUnassigned(false);
    }
  }, [props.startConversation])

  useEffect(() => {
  //   let myAgentID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
  //   if(props.startConversationBa.conversation) {
  //     setForceStartConversation(true);
  //   }else {
  //     setForceStartConversation(false);

  //     if(caseDetailVal !== null) {
  //       if((caseDetailVal.agentAccountID !== myAgentID) || (caseDetailVal.agentAccountID === 0)) {
  //         setShowInputMessage(false);
  //       }
  //     }
  //   }
    if(props.startConversationBa.conversation) {
      if(props.caseDetailData) {
        setShowInputMessage(true);
      }
      
      setForceHideInputMessage(false);            
      setTakenByOtherAgentOrUnassigned(false);
    }
  }, [props.startConversationBa])

  let reverseMessagesObject = (object) => {
    var newObject = {};
    var keys = [];

    for (var key in object) {
        keys.push(key);
    }

    for (var i = keys.length - 1; i >= 0; i--) {
      var value = object[keys[i]];
      newObject[keys[i]]= value;
    }       

    return newObject;
  }

  useEffect(() => {
    let runPropsCaseDetailData = async () => {
      if(props.caseDetailData !== null) {
          if(props.caseDetailData.blockingReason === BLOCKING_REASON.agent_not_assigned.blockingReason) {
            setIgnoreblockingMessage(true);

            if(props.startConversation.conversation || props.startConversationBa.conversation) {
              setShowInputMessage(true);
            }
          }else {
            if(props.caseDetailData.blockingReason === BLOCKING_REASON.reply_hours_exceeded.blockingReason) {
              if(!props.startConversationBa.conversation) {
                setShowInputMessage(false);
              }else {
                setShowInputMessage(true);
              }
            }else if(props.caseDetailData.blockingReason === BLOCKING_REASON.case_closed.blockingReason) {
              setIgnoreblockingMessage(true);
            }else {
              setIgnoreblockingMessage(false);
              if(!props.caseDetailData.canReply) {
                setForceHideInputMessage(true);
                setShowInputMessage(false);
              }else {
                if(caseDetailVal && !caseDetailVal.isClosed) {
                  setForceHideInputMessage(false);
                  setShowInputMessage(true);
                }
              }
          }
        }
      }
    }

    runPropsCaseDetailData();
  }, [props.caseDetailData])

  useEffect(() => {
    let _fileUploadProgress = {...fileUploadProgress};

    if(Object.keys(_fileUploadProgress).length > 0) {
      let _localID = Object.keys(_fileUploadProgress)[0];
      let _chatDataRoomMain = {...chatDataRoomMain};
      
      if(_fileUploadProgress[_localID].message !== null) {
        _chatDataRoomMain[_fileUploadProgress[_localID].message.localID] = _fileUploadProgress[_localID].message;
        setChatDataRoomMain(_chatDataRoomMain);
        scrollChatViewToBottom();
      }else {
        if(_chatDataRoomMain[_localID]) {
          _chatDataRoomMain[_localID].bytesUpload = _fileUploadProgress[_localID].bytes;
          _chatDataRoomMain[_localID].percentageUpload = _fileUploadProgress[_localID].percentage;
          setChatDataRoomMain(_chatDataRoomMain);
        }
      }

      setFileUploadProgress({});
    }
  }, [fileUploadProgress])

  useEffect(() => {
    setIsPauseClockState(props.clockState === CLOCK_STATE.isPaused ? true : false);
  }, [props.clockState])

  useEffect(() => {
    if(chatDataRoomMainTemp) {
      if(chatDataRoomMainTemp.activeRoom.roomID === props.activeRoom.roomID) {
        setChatDataRoomMain(chatDataRoomMainTemp.chats);
        if (chatDataRoomMainTemp.isFirstLoad) {
          scrollChatViewToBottom();
        } 
      
        if(chatDataRoomMainTemp.scrollingBackHeight) {
          setTimeout(() => {
            chatRoomMesageListRef.current.scrollTop = document.querySelectorAll(".chat-room-main-content")[0].scrollHeight - chatDataRoomMainTemp.scrollingBackHeight;
          }, 0)
        }

        if(chatDataRoomMainTemp.findLocalID) {
          if(!chatDataRoomMainTemp.chats[chatDataRoomMainTemp.findLocalID] && chatDataRoomMainTemp.hasMore) {
            getMessageBefore(false, false, chatDataRoomMainTemp.findLocalID);
          }else if(!chatDataRoomMainTemp.chats[chatDataRoomMainTemp.findLocalID] && !chatDataRoomMainTemp.hasMore) {
            setIsShowLoadingBlocking(false);
          }else {
            setTimeout(() => {
              scrollToReply(chatDataRoomMainTemp.findLocalID);
              setIsShowLoadingBlocking(false);
            }, 0)
          }
        }
      }
    }
  }, [chatDataRoomMainTemp])
  
  useEffect(() => {
    let runFirst = async () => {
      setIsShowLoadingBlocking(false);
      setCaseDetailVal(null);
      setChatDataRoomMainTemp(false);
      setHideReplyButton(true);
      setTakenByOtherAgentOrUnassigned(false);
      setShowCaseClosedPanel(false);  
      setForceHideInputMessage(false);
      setIsSetMarkAsResolved(false);
      // props.clearCaseDetailData();
  
      if(props.activeRoom !== null) {
        setCaseDetailVal(props.caseDetailValProps);
        props.setCaseDetailActionProps(props.caseDetailValProps);
      }
      
      let isShow = true;
      
      if(props.caseDetailValProps.medium === TAPLIVE_MEDIUM.whatsappba || props.caseDetailValProps.medium === TAPLIVE_MEDIUM.instagram) {
        isShow = false;
      }

      setShowInputMessage(isShow);
    }

    runFirst()
    
    runGetCurrentChat();

    // let onlineCallbackOnChatRoom = () => {
    //   if(props.activeRoom !== null) {
    //     setTimeout(() => {
    //       getMessageAfter(true)
    //     }, 5000);
    //   }
    // }

    // window.addEventListener('online', onlineCallbackOnChatRoom);
    
    // return () => {
    //   window.removeEventListener('online', onlineCallbackOnChatRoom);
    // }
  }, [props.activeRoom]);

  useEffect(() => {
    let runCaseDetailVal = async () => {
      let myAgentID = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id;
      
      if(caseDetailVal !== null) {
        if(!caseDetailVal.isClosed) {
          setShowCaseClosedPanel(false);
          //check case is taken by other agent or unsassigned
          if((caseDetailVal.agentAccountID !== myAgentID) || (caseDetailVal.agentAccountID === 0)) {

            if(!props.startConversation.conversation && !props.startConversationBa.conversation) {
              setShowInputMessage(false);
              setForceHideInputMessage(true);            
              setTakenByOtherAgentOrUnassigned(true);
            }
          }else {
            // if(caseDetailVal.medium !== TAPLIVE_MEDIUM.whatsappba) {
            //   setShowInputMessage(true);
            //   setForceHideInputMessage(false);
            //   setTakenByOtherAgentOrUnassigned(false);
            // }
            
            if(props.caseDetailData !== null) {
              let isShow = true;
              if(caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba || caseDetailVal.medium === TAPLIVE_MEDIUM.instagram) {
                isShow = false;
              }

              if(isShow) {
                setShowInputMessage(true);
                setForceHideInputMessage(false);
                setTakenByOtherAgentOrUnassigned(false);
              }
            }
          }
          //show case is taken by other agent
        }else {
          setTakenByOtherAgentOrUnassigned(false);
          setShowInputMessage(false);

          if(props.activeCasePanel !== 'solved') {
            setForceHideInputMessage(true);
          }else {
            setForceHideInputMessage(false);
          }
        }
        
        //reply button
        if(
            (
              caseDetailVal.medium === TAPLIVE_MEDIUM.telegram || 
              caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp ||
              caseDetailVal.medium === TAPLIVE_MEDIUM.launcher
            ) &&  
            (myAgentID === caseDetailVal.agentAccountID) &&
            (!caseDetailVal.isClosed)
        ) {
          setHideReplyButton(false);
        }
        //reply button
      }
    }

    runCaseDetailVal();
  }, [caseDetailVal]);

  useEffect(() => {
    let listenerNewMessage = props.messageListenerNewMessageProps === null ? null : props.messageListenerNewMessageProps;
    let cloneChatDataRoomMain = {...chatDataRoomMain};
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    // if(listenerNewMessage !== null && webWorkerChatRoomMain !== null) {
    //   webWorkerChatRoomMain.postMessage({
    //     type: 'new',
    //     activeRoom: props.activeRoom,
    //     caseDetailVal: {...caseDetailVal},
    //     message: listenerNewMessage,
    //     messageType: MESSAGE_TYPE,
    //     cloneChatDataRoomMain: cloneChatDataRoomMain,
    //     user: taptalk.getTaptalkActiveUser(),
    //     activeCasePanel: props.activeCasePanel
    //   })
    // }
    if(props.activeRoom !== null && caseDetailVal !== null) {

      if(listenerNewMessage !== null) {

        if(props.activeRoom.roomID === listenerNewMessage.room.roomID) {
          let _caseDetailData = {...props.caseDetailData};

          //case close
          if(listenerNewMessage.type === MESSAGE_TYPE.CASE_CLOSED) {
            // props.setActiveRoom(null);
            setShowCaseClosedPanel(true);
            setCaseDetailVal({...listenerNewMessage.data});
            props.setCaseDetailActionProps({...listenerNewMessage.data});
          }
          //case close 

          //case update
          if(listenerNewMessage.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE) {
            _caseDetailData.case = {...listenerNewMessage.data};

            if(listenerNewMessage.type !== MESSAGE_TYPE.CASE_UPDATE_DETAIL_UPDATE) {
              if({...listenerNewMessage.data}.agentAccountID === myAgentData.account.id) {
                _caseDetailData.blockingMessage = "";
                _caseDetailData.blockingReason = "";
                _caseDetailData.canReply = true;
              }else {
                _caseDetailData.blockingMessage = BLOCKING_REASON.agent_not_assigned.blockingMessage;
                _caseDetailData.blockingReason = BLOCKING_REASON.agent_not_assigned.blockingReason;
                _caseDetailData.canReply = false;
              }
            }
            
            setTimeout(() => {
              // props.setCaseDetailData(_caseDetailData);
              setTemporaryCaseDetailVal({
                detail: _caseDetailData,
                room: props.activeRoom,
                date: new Date().valueOf()
              })
            }, 0);
          }
          //case update

          if(listenerNewMessage.user.userID === taptalk.getTaptalkActiveUser().userID) {
            // if message was from me            
            cloneChatDataRoomMain[listenerNewMessage.localID] = listenerNewMessage;
            
            //imediately scrolling to bottom
            scrollChatViewToBottom();
          }else {
            //if chat was from another device
            cloneChatDataRoomMain[listenerNewMessage.localID] = listenerNewMessage;
            unreadMessageAction({[listenerNewMessage.localID]: listenerNewMessage});
          }
          
          setChatDataRoomMain(cloneChatDataRoomMain);
          if((chatRoomMesageListRef.current.scrollHeight - chatRoomMesageListRef.current.scrollTop) === chatRoomMesageListRef.current.clientHeight) {
            scrollChatViewToBottom();
          }

          //waba clear 24 hour blocking
          if((listenerNewMessage.user.userID !== taptalk.getTaptalkActiveUser().userID) && 
             (_caseDetailData.blockingReason === BLOCKING_REASON.reply_hours_exceeded.blockingReason) &&
             (listenerNewMessage.user.userRole.code === "visitor") &&
             !props.startConversationBa.conversation
          ) {
            _caseDetailData.blockingMessage = "";
            _caseDetailData.blockingReason = "";
            _caseDetailData.canReply = true;
            
            setTimeout(() => {
              // props.setCaseDetailData(_caseDetailData);
              setTemporaryCaseDetailVal({
                detail: _caseDetailData,
                room: props.activeRoom,
                date: new Date().valueOf()
              })
              setForceHideInputMessage(false);
              setShowInputMessage(true);
            }, 0);
          }
          //waba clear 24 hour blocking
        }
        
      }
      
    }
  }, [props.messageListenerNewMessageProps]);

  useEffect(() => {
    let listenerUpdateMessage = props.messageListenerUpdateMessageProps === null ? null : props.messageListenerUpdateMessageProps;
    
    // let cloneChatDataRoomMain = {...chatDataRoomMain};
    
    // if(listenerUpdateMessage !== null && webWorkerChatRoomMain !== null) {
    //   webWorkerChatRoomMain.postMessage({
    //     type: 'update',
    //     activeRoom: props.activeRoom,
    //     caseDetailVal: {...caseDetailVal},
    //     message: listenerUpdateMessage,
    //     messageType: MESSAGE_TYPE,
    //     cloneChatDataRoomMain: cloneChatDataRoomMain,
    //     user: taptalk.getTaptalkActiveUser(),
    //     activeCasePanel: props.activeCasePanel
    //   })
    // }
    if(props.activeRoom !== null && caseDetailVal !== null) {

      if(listenerUpdateMessage !== null) { 

        if(props.activeRoom.roomID === listenerUpdateMessage.room.roomID) {

          if(listenerUpdateMessage.isDelivered && !listenerUpdateMessage.isRead) {
            let cloneChatDataUndelivered = {...chatDataRoomMain};

            if(cloneChatDataUndelivered[listenerUpdateMessage.localID]) {
              cloneChatDataUndelivered[listenerUpdateMessage.localID].isDelivered = true;
              setChatDataRoomMain(cloneChatDataUndelivered);
            }
          }

          //set current chat with mtch localID isRead to true 
          if(listenerUpdateMessage.isRead) {
            let cloneChatDataUnRead = {...chatDataRoomMain};
            
            if(cloneChatDataUnRead[listenerUpdateMessage.localID]) {
              cloneChatDataUnRead[listenerUpdateMessage.localID].isRead = true; 
              setChatDataRoomMain(cloneChatDataUnRead);
            }

            // Object.keys(cloneChatDataUnRead).map(function(i) {
            //   if(!cloneChatDataUnRead[i].isRead) {
            //     cloneChatDataUnRead[i].isRead = true;
            //     setChatDataRoomMain(cloneChatDataUnRead);
            //   }
            //   return null;
            // });
          }

          //hide input message panel if case taken by other agent
          if(props.activeCasePanel !== 'solved') {
            if(listenerUpdateMessage.type === MESSAGE_TYPE.CASE_UPDATE_AGENT_CHANGE) {
              setCaseDetailVal({...listenerUpdateMessage.data});
              props.setCaseDetailActionProps({...listenerUpdateMessage.data});

              // if((myAgentID !== listenerUpdateMessage.data.agentAccountID) && !caseDetailVal.isClosed) {
              //   setForceHideInputMessage(true);
              //   setTakenByOtherAgentOrUnassigned(true);
              // }
              // else {
              //   setForceHideInputMessage(false);
              //   setTakenByOtherAgentOrUnassigned(false);
              // }
            }
          }
          //hide input message panel if case taken by other agent
          
        }

      }

    }
  }, [props.messageListenerUpdateMessageProps]);

  let runGetCurrentChat = async () => {
    if(tapCoreChatRoomManager.getCurrentChatInRoom(props.activeRoom.roomID) !== null) {
      // setChatDataRoomMain(reverseMessagesObject(tapCoreChatRoomManager.getCurrentChatInRoom(props.activeRoom.roomID)));
      setChatDataRoomMainTemp({
        chats: reverseMessagesObject(tapCoreChatRoomManager.getCurrentChatInRoom(props.activeRoom.roomID)),
        activeRoom: props.activeRoom,
        timeStamp: new Date().valueOf()
      })
    }else {
      // setChatDataRoomMain({});
      setChatDataRoomMainTemp({
        chats: {},
        activeRoom: props.activeRoom,
        timeStamp: new Date().valueOf()
      })
    }

    setTimeout(function() {
      if(props.activeRoom !== null) {
        // clearTimeout(getMessageAfter);
        // getMessageAfter(false);
        clearTimeout(getMessageBefore);
        getMessageBefore(true, false);
      }
    }, 0);
  }

  let unreadMessageAction = (arrayOfMessage) => {
    let unreadMessageArray = [];

    Object.keys(arrayOfMessage).map(function(i) {
      if(!arrayOfMessage[i].isRead && (arrayOfMessage[i].user.userID !== taptalk.getTaptalkActiveUser().userID)) {
        unreadMessageArray.push(arrayOfMessage[i].messageID);
      }
      return null;
    });

    if(unreadMessageArray.length > 0) {
      tapCoreMessageManager.markMessageAsRead(unreadMessageArray);
    }
  }

  let scrollChatViewToBottom = () => {
    setTimeout(function() {
      if(chatRoomMesageListRef.current !== null) {
        chatRoomMesageListRef.current.scrollTop = chatRoomMesageListRef.current.scrollHeight;
      }
    }, 0);
  }

  let onScrollListener = () => {
    let currentViewHeight = chatRoomMesageListRef.current.scrollHeight;
    if(chatRoomMesageListRef.current.scrollTop === 0 && props.activeRoom && hasMoreChatBefore) {
      getMessageBefore(false, currentViewHeight);
    }
  }

  let getMessageAfter = async (isReconnect) => {
    setTimeout(function() {
      if(!isReconnect) {
       setIsLoadingChat(true);
      }

      tapCoreMessageManager.getNewerMessagesAfterTimestamp(props.activeRoom.roomID, {
        onSuccess: (messages) => {
              setIsLoadingChat(false);
              unreadMessageAction(messages);

              if(messages !== null) {
                // setChatDataRoomMain(reverseMessagesObject(messages));
                setChatDataRoomMainTemp({
                  chats: reverseMessagesObject({...messages}),
                  activeRoom: props.activeRoom,
                  timeStamp: new Date().valueOf()
                })
        
                if(!isReconnect) {
                  Object.keys(messages).length < 50 && getMessageBefore(true, false);
                }
                
                scrollChatViewToBottom();
              }
          },
          onError: (errorCode, errorMessage) => {
              setIsLoadingChat(false);
              console.log(errorCode, errorMessage);
          }
      });
    }, isReconnect ? 0 : 100);
  }

  let getMessageBefore = async (isFirstLoad= false, scrollingBackHeight, findLocalID) => {
    let numberOfItems = 40;
    let roomID = props.activeRoom.roomID;

    if(!findLocalID) {
      setIsLoadingChat(true);
    }else {
      setIsShowLoadingBlocking(true);
    }
    
    setTimeout(function() {
      if(roomID && roomID === props.activeRoom.roomID) {
        tapCoreMessageManager.getOlderMessagesBeforeTimestamp(roomID, numberOfItems, {
          onSuccess: (messages, hasMore) => {
              if(roomID === props.activeRoom.roomID || props.activeRoom !== null ) {
                setHasMoreChatBefore(hasMore);
                setIsLoadingChat(false);
                unreadMessageAction({...messages});
                // setChatDataRoomMain(reverseMessagesObject({...messages}));
                setChatDataRoomMainTemp({
                  chats: reverseMessagesObject({...messages}),
                  activeRoom: props.activeRoom,
                  timeStamp: new Date().valueOf(),
                  scrollingBackHeight: scrollingBackHeight,
                  findLocalID: findLocalID,
                  isFirstLoad: isFirstLoad,
                  hasMore: hasMore
                })
              }
          },
          onError: (errorCode, errorMessage) => {
              setIsLoadingChat(false);
              console.log(errorCode, errorMessage);
          }
        });
      }
    }, 100);
  }

  let onReplyMessage = (message) => {
    let _replyMessage = {...props.replyMessage};
    _replyMessage.message = message;
    props.setReplyMessage(_replyMessage)
  }

  let onForwardMessage = (message) => {
    let data = {
      isShow: true,
      message: message,
      case: props.caseDetailData.case
    };

    props.setForwardMessage(data);
  }

  let scrollToReply = (localID) => {
    let targetScroll = document.querySelectorAll(".chat-room-main-content")[0];
    let targetLocalID = document.querySelector(`#message-${localID}`);

    if(targetLocalID !== null) {
        targetScroll.scrollTop = targetLocalID.offsetTop;

        // targetScroll.scrollTo({
        //     top: targetLocalID.offsetTop,
        //     behavior: 'smooth',
        // });
        
        targetLocalID.classList.add("highlight-chat-bubble");

        setTimeout(() => {
            targetLocalID.classList.remove("highlight-chat-bubble");
        }, 2000);
    }else {
      getMessageBefore(false, false, localID)
    }
  }

  let generateMessageBubble = (messageData, index) => {
    let activeUser = taptalk.getTaptalkActiveUser().userID;

    switch(messageData.type) {
      case CHAT_TYPE.TAPChatMessageTypeText:
        return (
          messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onReplyMessage={onReplyMessage}
                scrollToReply={scrollToReply}
                medium={props.caseDetailValProps.medium}
                onForwardMessage={onForwardMessage}
                caseData={props.caseDetailData ? props.caseDetailData.case : false}
            />
            :
            <ChatRoomMessageOut 
              key={index} 
              singleChatDataProps={messageData} 
              activeUserID={activeUser}
              onReplyMessage={onReplyMessage}
              scrollToReply={scrollToReply}
              medium={props.caseDetailValProps.medium}
              onForwardMessage={onForwardMessage}
            />
        )
      case CHAT_TYPE.TAPChatMessageTypeLink:
        return (
          messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onReplyMessage={onReplyMessage}
                scrollToReply={scrollToReply}
                medium={props.caseDetailValProps.medium}
                onForwardMessage={onForwardMessage}
                caseData={props.caseDetailData ? props.caseDetailData.case : false}
            />
            :
            <ChatRoomMessageOut 
              key={index} 
              singleChatDataProps={messageData} 
              activeUserID={activeUser}
              onReplyMessage={onReplyMessage}
              scrollToReply={scrollToReply}
              medium={props.caseDetailValProps.medium}
              onForwardMessage={onForwardMessage}
            />
        )
      case CHAT_TYPE.TAPChatMessageTypeLeaveReview:
        return (
          messageData.user.userID !== activeUser ?
            <ChatRoomMessageInRating
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onReplyMessage={onReplyMessage}
                scrollToReply={scrollToReply}
                medium={props.caseDetailValProps.medium}
                onForwardMessage={onForwardMessage}
                caseData={props.caseDetailData ? props.caseDetailData.case : false}
            />
            :
            <ChatRoomMessageOutRating
              key={index} 
              singleChatDataProps={messageData} 
              activeUserID={activeUser}
              onReplyMessage={onReplyMessage}
              scrollToReply={scrollToReply}
              medium={props.caseDetailValProps.medium}
              onForwardMessage={onForwardMessage}
            />
        )
      case CHAT_TYPE.TAPChatMessageTypeLeaveReviewDisabled:
        return (
          messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onReplyMessage={onReplyMessage}
                scrollToReply={scrollToReply}
                medium={props.caseDetailValProps.medium}
                onForwardMessage={onForwardMessage}
                caseData={props.caseDetailData ? props.caseDetailData.case : false}
            />
            :
            <ChatRoomMessageOut 
              key={index} 
              singleChatDataProps={messageData} 
              activeUserID={activeUser}
              onReplyMessage={onReplyMessage}
              scrollToReply={scrollToReply}
              medium={props.caseDetailValProps.medium}
              onForwardMessage={onForwardMessage}
            />
        )
      case CHAT_TYPE.TAPChatMessageTypeImage:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
                <ChatRoomMessageInImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeVideo:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
                <ChatRoomMessageInVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeSystemMessage:
          return (
            <ChatRoomChatInfo 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onReplyMessage={onReplyMessage}
                scrollToReply={scrollToReply}
                medium={props.caseDetailValProps.medium}
                onForwardMessage={onForwardMessage}
            />
          )
      case CHAT_TYPE.TAPChatMessageTypeCaseClosed:
          return (
            <ChatRoomChatInfo 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onReplyMessage={onReplyMessage}
                scrollToReply={scrollToReply}
                medium={props.caseDetailValProps.medium}
                onForwardMessage={onForwardMessage}
            />
          )
      case CHAT_TYPE.TAPChatMessageTypeLocation:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
                <ChatRoomMessageInLocation
                    key={index}
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
            :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutLocation 
                    key={index} 
                    singleChatDataProps={messageData}
                    isOnUpload={messageData.isOnUpload}
                    uploadFile={messageData.uploadFile}
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeFile:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
                <ChatRoomMessageInFile 
                    key={index}
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutFile 
                    key={index} 
                    singleChatDataProps={messageData}
                    isOnUpload={messageData.isOnUpload}
                    uploadFile={messageData.uploadFile}
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
          )
       case CHAT_TYPE.MessageTypeWhatsAppBATemplateText:
        return (
          messageData.user.userID !== activeUser ?
              <ChatRoomMessageIn
                  key={index}
                  singleChatDataProps={messageData} 
                  onReplyMessage={onReplyMessage}
                  scrollToReply={scrollToReply}
                  medium={props.caseDetailValProps.medium}
                  onForwardMessage={onForwardMessage}
                  caseData={props.caseDetailData ? props.caseDetailData.case : false}
              />
              // messageData.isDeleted ?
              //   <ChatRoomMessageIn 
              //       key={index} 
              //       singleChatDataProps={messageData} 
              //   />
              //   :
              //   <ChatRoomMessageInWabaTemplate
              //       key={index}
              //       singleChatDataProps={messageData} 
              //   />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData}
                    onReplyMessage={onReplyMessage} 
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutWabaTemplate
                    key={index} 
                    singleChatDataProps={messageData}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
        )
      case CHAT_TYPE.MessageTypeBroadcastText:
        return (
          messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn
                    key={index}
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
                <ChatRoomMessageInBroadcast
                    key={index}
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutBroadcast
                    key={index} 
                    singleChatDataProps={messageData}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
        )
      case CHAT_TYPE.MessageTypeBroadcastImage:
        return (
          messageData.user.userID !== activeUser ?
            messageData.isDeleted ?
              <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData}
                  onReplyMessage={onReplyMessage} 
                  scrollToReply={scrollToReply}
                  medium={props.caseDetailValProps.medium}
                  onForwardMessage={onForwardMessage}
                  caseData={props.caseDetailData ? props.caseDetailData.case : false}
              />
              :
              <ChatRoomMessageInBroadcastImage 
                  key={index} 
                  singleChatDataProps={messageData} 
                  onReplyMessage={onReplyMessage}
                  scrollToReply={scrollToReply}
                  medium={props.caseDetailValProps.medium}
                  onForwardMessage={onForwardMessage}
              />
            :
            messageData.isDeleted ?
              <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData}
                  onReplyMessage={onReplyMessage} 
                  scrollToReply={scrollToReply}
                  medium={props.caseDetailValProps.medium}
                  onForwardMessage={onForwardMessage}
              />
              :
              <ChatRoomMessageOutBroadcastImage 
                  key={index} 
                  singleChatDataProps={messageData} 
                  onReplyMessage={onReplyMessage}
                  scrollToReply={scrollToReply}
                  medium={props.caseDetailValProps.medium}
                  onForwardMessage={onForwardMessage}
              />
          )
          case CHAT_TYPE.MessageTypeBroadcastFile:
            return (
              messageData.user.userID !== activeUser ?
                messageData.isDeleted ?
                  <ChatRoomMessageIn 
                      key={index} 
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={onReplyMessage}
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                      caseData={props.caseDetailData ? props.caseDetailData.case : false}
                  />
                  :
                  <ChatRoomMessageInBroadcastFile 
                      key={index}
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={onReplyMessage}
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                      caseData={props.caseDetailData ? props.caseDetailData.case : false}
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOut 
                      key={index} 
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={onReplyMessage}
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                  />
                  :
                  <ChatRoomMessageOutBroadcastFile 
                      key={index} 
                      singleChatDataProps={messageData}
                      isOnUpload={messageData.isOnUpload}
                      uploadFile={messageData.uploadFile}
                      activeUserID={activeUser}
                      onReplyMessage={onReplyMessage}
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                  />
            )
        case CHAT_TYPE.MessageTypeBroadcastVideo:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
                <ChatRoomMessageInBroadcastVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutBroadcastVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
          )
        case CHAT_TYPE.MessageTypeWhatsAppBATemplateImage:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData}
                    onReplyMessage={onReplyMessage} 
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
                <ChatRoomMessageInImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutWabaTemplateImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
            )
        case CHAT_TYPE.MessageTypeWhatsAppBATemplateFile:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageInFile
                    key={index} 
                    singleChatDataProps={messageData}
                    onReplyMessage={onReplyMessage} 
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
                :
                <ChatRoomMessageInFile
                    key={index} 
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                    caseData={props.caseDetailData ? props.caseDetailData.case : false}
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOutFile
                    key={index} 
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
                :
                <ChatRoomMessageOutFile 
                    key={index} 
                    singleChatDataProps={messageData} 
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                />
          )
          case CHAT_TYPE.MessageTypeWhatsAppBATemplateVideo:
            return (
              messageData.user.userID !== activeUser ?
                messageData.isDeleted ?
                  <ChatRoomMessageInVideo
                      key={index} 
                      singleChatDataProps={messageData}
                      onReplyMessage={onReplyMessage} 
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                      caseData={props.caseDetailData ? props.caseDetailData.case : false}
                  />
                  :
                  <ChatRoomMessageInVideo
                      key={index} 
                      singleChatDataProps={messageData} 
                      onReplyMessage={onReplyMessage}
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                      caseData={props.caseDetailData ? props.caseDetailData.case : false}
                  />
                :
                messageData.isDeleted ?
                  <ChatRoomMessageOutVideo
                      key={index} 
                      singleChatDataProps={messageData} 
                      onReplyMessage={onReplyMessage}
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                  />
                  :
                  <ChatRoomMessageOutVideo
                      key={index} 
                      singleChatDataProps={messageData} 
                      onReplyMessage={onReplyMessage}
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                  />
            )
        case CHAT_TYPE.MessageTypeTokopedia:
              return (
                messageData.user.userID !== activeUser ?
                  <ChatRoomMessageInTokopedia
                      key={index} 
                      singleChatDataProps={messageData} 
                      activeUserID={activeUser}
                      onReplyMessage={onReplyMessage}
                      scrollToReply={scrollToReply}
                      medium={props.caseDetailValProps.medium}
                      onForwardMessage={onForwardMessage}
                      caseData={props.caseDetailData ? props.caseDetailData.case : false}
                  />
                  :
                  <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                    activeUserID={activeUser}
                    onReplyMessage={onReplyMessage}
                    scrollToReply={scrollToReply}
                    medium={props.caseDetailValProps.medium}
                    onForwardMessage={onForwardMessage}
                  />
              )
        default:
          return (
            messageData.user.userID !== activeUser ?
              <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData} 
                  activeUserID={activeUser}
                  onReplyMessage={onReplyMessage}
                  scrollToReply={scrollToReply}
                  medium={props.caseDetailValProps.medium}
                  onForwardMessage={onForwardMessage}
                  caseData={props.caseDetailData ? props.caseDetailData.case : false}
              />
              :
              <ChatRoomMessageOut 
                key={index} 
                singleChatDataProps={messageData} 
                activeUserID={activeUser}
                onReplyMessage={onReplyMessage}
                scrollToReply={scrollToReply}
                medium={props.caseDetailValProps.medium}
                onForwardMessage={onForwardMessage}
              />
          )
    }
  }

  // let runMarkAsSolved = () => {
  //   let caseID = props.activeRoom.xcRoomID.split(':')[1];
  //   let _caseDetailVal = {...props.caseDetailData};

  //   let data = {
  //     id: parseInt(caseID),
  //     sendClosingMessage: true
  //   }

  //   CaseService.postCloseCase(props.parentProps.match.params.orgID, data, (response) => {
  //     if(response.dataResult.error.code === "") {
  //       _caseDetailVal.case.closedTime = new Date().valueOf();
  //       _caseDetailVal.case.isClosed = true;
  //       props.setCaseDetailData(_caseDetailVal);
  //       // goToSolveCaseClick();
  //       let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
  //       if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
  //         mixpanel.track(
  //           "[Action] Mark Resolved",
  //           {
  //             userFullName : myAgentData.account.fullName,
  //             userID : myAgentData.account.id,
  //             organizationID : props.parentProps.match.params.orgID.split("-")[0]
  //           }
  //         );
  //       }

  //       doToast(response.dataResult.data.message);
  //     }else {
  //       doToast(response.dataResult.error.message, "fail");
  //     }
  //   });
  // }

  let runNormalEmit = (mess) => {
    let split4000char = mess.match(/.{1,4000}/gs);
    
    for(let i in split4000char) {
      tapCoreMessageManager.sendTextMessage(split4000char[i], props.activeRoom, function(message) { 
        message.body = split4000char[i];
        
        props.setNewEmitMessageProps(message);
        
        let currentChatRoomData = chatDataRoomMain;
        
        currentChatRoomData[message.localID] = message;
        
        setChatDataRoomMain(currentChatRoomData);
        
        scrollChatViewToBottom();
      }, props.replyMessage.message);
    }
  }

  let onInputNewMessage = async (messageText, toggleSend) => {
    if(props.caseDetailData && props.caseDetailData.case.medium === TAPLIVE_MEDIUM.gb_profile && toggleSend) {
      toggleModalSend();
      setTemporaryMessageText(messageText);
    }else {
      let clearDraftMessage = async () => {
        let _draftMessage = {...props.draftMessage};
  
        if(props.activeRoom && _draftMessage[props.activeRoom.roomID]) {
          _draftMessage[props.activeRoom.roomID] = false;
          props.setDraftMessage(_draftMessage)
        }
      }
  
      clearDraftMessage();
      // let _caseDetailVal = JSON.parse(JSON.stringify(caseDetailVal));
      let _caseDetailVal = {...props.caseDetailValProps};
      
      if(props.caseDetailData !== null) {
        _caseDetailVal = {...props.caseDetailData.case};
      }
      
      tapCoreChatRoomManager.sendStopTypingEmit(props.activeRoom.roomID);
      
      if((props.activeRoom) && (messageText !== "") && (messageText.replace(/\s/g, '').length !== 0)) {
        //run first response time
        if(_caseDetailVal.firstResponseTime === 0) {
          runFirstResponseTime()
        }
        //run first response time
  
        // if (_caseDetailVal.medium === TAPLIVE_MEDIUM.launcher) {
        //   runNormalEmit();
        // }else
        if (_caseDetailVal.medium === TAPLIVE_MEDIUM.facebook && _caseDetailVal.mediumChannelID === 0) { 
          CaseService.postReplyToFbMessenger(messageText, _caseDetailVal.userID);
          runNormalEmit(messageText);
        }else if (_caseDetailVal.medium === TAPLIVE_MEDIUM.twitter && _caseDetailVal.mediumChannelID === 0) { 
          CaseService.postReplyToTwitter(messageText, _caseDetailVal.userID);
          runNormalEmit(messageText);
        }else { 
          let split4000char = messageText.match(/.{1,4000}/gs);
  
          for(let i in split4000char) {
            tapCoreMessageManager.sendTextMessageWithoutEmit(split4000char[i], props.activeRoom, function(message) {
              message.body = split4000char[i];
      
              props.setNewEmitMessageProps(message);
      
              let currentChatRoomData = chatDataRoomMain;
              currentChatRoomData[message.localID] = message;
      
              setChatDataRoomMain(currentChatRoomData);
              scrollChatViewToBottom();
              
              let data = {
                message: message,
                id: _caseDetailVal.id,
                createdTime: _caseDetailVal.createdTime,
                body: split4000char[i],
                localID: message.localID,
                replyToLocalID: props.replyMessage.message ? props.replyMessage.message.localID : null,
                callbackSolved: checkMarkAsSolved ? actionCaseClose : false,
                callback: (response) => {
                  if(_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba || _caseDetailVal.medium === TAPLIVE_MEDIUM.instagram) {
                    // props.setNewEmitMessageProps(message);
                    let _caseDetailData = {...props.caseDetailData};
                    _caseDetailData.blockingReason = response.reason;
                    _caseDetailData.blockingMessage = response.message;
                    _caseDetailData.canReply = false;
  
                    setTimeout(() => {
                      let _mess = {...tapCoreMessageManager.hideMessageInRoom(props.activeRoom.roomID, message.localID)}
                      currentChatRoomData[message.localID] = _mess.message;
                      props.setNewEmitMessageProps(_mess.lastMessage);
                      setChatDataRoomMain(currentChatRoomData);
                      // props.setCaseDetailData(_caseDetailData);
                      setTemporaryCaseDetailVal({
                        detail: _caseDetailData,
                        room: props.activeRoom,
                        date: new Date().valueOf()
                      })
  
                      setForceHideInputMessage(true);
                      setShowInputMessage(false);
                    }, 0);
                  }
                }
              }
              
              props.pushSendMessageQueueProps(data);
            }, props.replyMessage.message)
          }
        }
      }
    }
  }

  let generateNewFileUploadProgress = (localID, percentage, bytes, message = null) => {
    let newFileUploadProgress = {
      [localID]: {
        percentage: percentage,
        bytes: bytes,
        message: message
      }
    }

    return newFileUploadProgress;
  }

  // let pushNewChat = (newMessage) => {
  //   // let targetChatData = JSON.parse(document.querySelector('#chat-room-data').getAttribute('data'));
  //   // targetChatData[newMessage.localID]= newMessage;
  //   let _chatDataRoomMain = {...chatDataRoomMain};
  //   _chatDataRoomMain[newMessage.localID] = newMessage;
  //   setChatDataRoomMain(_chatDataRoomMain); 
  // }

  let onStartUploadFile = (message) => {
    // message.percentageUpload = 0;
    // message.bytesUpload = 0;
    // pushNewChat(message);
    setFileUploadProgress(generateNewFileUploadProgress(message.localID, 0, 0, message));
    // scrollChatViewToBottom();
  }

  let onProgressUploadFile = (localID, percentage, bytes) => {
    // let _chatDataRoomMain = {...chatDataRoomMain};
    
    // let targetChatData = JSON.parse(document.querySelector('#chat-room-data').getAttribute('data'));
    
    // targetChatData[localID].percentageUpload = percentage;
    // targetChatData[localID].bytesUpload = bytes;

    // _chatDataRoomMain[localID].percentageUpload = percentage;
    // _chatDataRoomMain[localID].bytesUpload = bytes;

    // setChatDataRoomMain(_chatDataRoomMain);
    setFileUploadProgress(generateNewFileUploadProgress(localID, percentage, bytes));
  }

  let runSendProductMessage = () => {
    let _caseDetailVal = {...props.caseDetailValProps};
    let _salesTalkSelectedProduct = {...props.salesTalkSelectedProduct};
    
    
    Object.keys(_salesTalkSelectedProduct.data).map((v, k) => {
      let _caption = `${HelperChat.boldTextSalestalkProduct(_salesTalkSelectedProduct.data[v].name, props.caseDetailValProps.medium)}
${rupiahFormat(_salesTalkSelectedProduct.data[v].prices[0].unitPrice)}
${_salesTalkSelectedProduct.data[v].productURL}

Descriptions:
${trimTextTo(_salesTalkSelectedProduct.data[v].description, 200)}
      `;
  
      let _data = {
          url: _salesTalkSelectedProduct.data[v].imageURL,
          fileURL: _salesTalkSelectedProduct.data[v].imageURL,
          caption: _caption
      };
  
      let messageModel = tapCoreMessageManager.constructTapTalkMessageModel(
        _salesTalkSelectedProduct.data[v].description, 
        props.activeRoom, 
        CHAT_TYPE.TAPChatMessageTypeImage, 
        _data
      );
      
      if(
        _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.telegram ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.instagram ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.facebook ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.google_business
      ) {
        //without emit
        tapCoreMessageManager.sendCustomMessage(messageModel, (message) => { 
          message.body = _caption;
          message.data = _data;
    
          props.setNewEmitMessageProps(message);
              
          let currentChatRoomData = chatDataRoomMain;
          
          currentChatRoomData[message.localID] = message;
          
          setChatDataRoomMain(currentChatRoomData);
          
          scrollChatViewToBottom();
          
          let data = {
            id: _caseDetailVal.id,
            createdTime: _caseDetailVal.createdTime,
            body: message.data.fileURL,
            localID: message.localID,
            messageType: "image",
            filename:"",
            caption: _caption,
            callback: (response) => {
              if(_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) {
                // props.setNewEmitMessageProps(message);
                let _caseDetailData = {...props.caseDetailData};
                _caseDetailData.blockingReason = response.reason
                _caseDetailData.blockingMessage = response.message;;
                _caseDetailData.canReply = false;

                setTimeout(() => {
                  let _mess = {...tapCoreMessageManager.hideMessageInRoom(props.activeRoom.roomID, message.localID)}
                  currentChatRoomData[message.localID] = _mess.message;
                  props.setNewEmitMessageProps(_mess.lastMessage);
                  setChatDataRoomMain(currentChatRoomData);
                  // props.setCaseDetailData(_caseDetailData);
                  setTemporaryCaseDetailVal({
                    detail: _caseDetailData,
                    room: props.activeRoom,
                    date: new Date().valueOf()
                  })

                  setForceHideInputMessage(true);
                  setShowInputMessage(false);
                }, 0);
              }
            }
          }

          props.pushSendMessageQueueProps(data);
        //without emit
        }, true)
      }else{
        tapCoreMessageManager.sendCustomMessage(messageModel, (message) => { 
          message.body = _caption;
          message.data = _data;
    
          props.setNewEmitMessageProps(message);
              
          let currentChatRoomData = chatDataRoomMain;
          
          currentChatRoomData[message.localID] = message;
          
          setChatDataRoomMain(currentChatRoomData);
          
          scrollChatViewToBottom();
        })
      }
      
      return null;
    })

    props.setSalestalkSelectedProduct({
      isShow: false,
      data: {},
      lastSend: false
    });
  }

  let runningFileMessage = (files) => {
      let timeout = 0;
      // let _caseDetailVal = JSON.parse(JSON.stringify(caseDetailVal));
      let _caseDetailVal = {...props.caseDetailValProps};
    
      if(props.caseDetailData !== null) {
        _caseDetailVal = {...props.caseDetailData.case};
      }

      let runWithOutEmit = () => {
        files.map((value, index) => {
          if(index > 0) { timeout += 700;}

          setTimeout(() => {
            tapCoreMessageManager.sendFileMessageWithoutEmit(value, props.activeRoom, 
              {
                onStart: (message) => {
                  onStartUploadFile(message);
                  // HelperChat.resetHeightClearReply(props.replyMessage);
                  HelperChat.resetChatRoomHeightAndInputText();
                  props.clearReplyMessage();
                },
        
                onProgress: (localID, percentage, bytes) => {
                  onProgressUploadFile(localID, percentage, bytes);
                },
        
                onSuccess: (message) => { 
                  let currentChatRoomData = chatDataRoomMain;
    
                  currentChatRoomData[message.localID] = message;

                  let data = {
                    id: _caseDetailVal.id,
                    createdTime: _caseDetailVal.createdTime,
                    body: message.data.fileURL,
                    localID: message.localID,
                    messageType: "file",
                    filename: message.data.fileName,
                    caption: message.data.caption,
                    callback: (response) => {
                      if(_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) {
                        // props.setNewEmitMessageProps(message);
                        let _caseDetailData = {...props.caseDetailData};
                        _caseDetailData.blockingReason = response.reason;
                        _caseDetailData.blockingMessage = response.message;
                        _caseDetailData.canReply = false;
      
                        setTimeout(() => {
                          let _mess = {...tapCoreMessageManager.hideMessageInRoom(props.activeRoom.roomID, message.localID)}
                          currentChatRoomData[message.localID] = _mess.message;
                          props.setNewEmitMessageProps(_mess.lastMessage);
                          setChatDataRoomMain(currentChatRoomData);
                          // props.setCaseDetailData(_caseDetailData);
                          setTemporaryCaseDetailVal({
                            detail: _caseDetailData,
                            room: props.activeRoom,
                            date: new Date().valueOf()
                          })

                          setForceHideInputMessage(true);
                          setShowInputMessage(false);
                        }, 0);
                      }
                    }
                  }

                  props.pushSendMessageQueueProps(data);
                },
        
                onError: (errorCode, errorMessage) => {
                  doToast(errorMessage, "fail");
                  console.log(errorCode, errorMessage);
                }
              }, props.replyMessage.message, false, (_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp || _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) ? WHATSAPP_FILE_SIZE_LIMIT.document(taptalk.getProjectConfigs().core.chatMediaMaxFileSize) : false);
          }, timeout);

          return null;
        })
      }
  
      let runWithEmit = () => {
        files.map((value, index) => {
          if(index > 0) {
            timeout += 500;
          }

          setTimeout(() => {
            tapCoreMessageManager.sendFileMessage(value, props.activeRoom, 
              {
                onStart: (message) => {
                 onStartUploadFile(message);
                //  HelperChat.resetHeightClearReply(props.replyMessage);
                HelperChat.resetChatRoomHeightAndInputText();
                 props.clearReplyMessage();
                },
        
                onProgress: (localID, percentage, bytes) => {
                  onProgressUploadFile(localID, percentage, bytes);
                },
        
                onSuccess: (message) => {
                  
                },
        
                onError: (errorCode, errorMessage) => {
                  doToast(errorMessage, "fail");
                  console.log(errorCode, errorMessage);
                }
              }, props.replyMessage.message);
          }, timeout);

          return null;
        })
      }  

      if(
        _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.telegram ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.instagram ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.facebook ||
        _caseDetailVal.medium === TAPLIVE_MEDIUM.google_business
        // _caseDetailVal.medium === TAPLIVE_MEDIUM.launcher
      ) {
        runWithOutEmit();
      }else{
        runWithEmit();
      }
  }

  let runningImageMessage = (file, caption) => {
    let _caseDetailVal = {...props.caseDetailValProps};

    if(props.caseDetailData !== null) {
      _caseDetailVal = {...props.caseDetailData.case};
    }

    // let _caseDetailVal = JSON.parse(JSON.stringify(caseDetailVal));

    let runWithOutEmit = () => {
      tapCoreMessageManager.sendImageMessageWithoutEmit(file, caption, props.activeRoom, 
        {
          onStart: (message) => {
            onStartUploadFile(message);
            // HelperChat.resetHeightClearReply(props.replyMessage);
            HelperChat.resetChatRoomHeightAndInputText();
            props.clearReplyMessage();
          },
  
          onProgress: (localID, percentage, bytes) => {
            onProgressUploadFile(localID, percentage, bytes);
          },
  
          onSuccess: (message) => { 
            let currentChatRoomData = chatDataRoomMain;
    
            currentChatRoomData[message.localID] = message;

            let data = {
              id: _caseDetailVal.id,
              createdTime: _caseDetailVal.createdTime,
              body: message.data.fileURL,
              localID: message.localID,
              messageType: "image",
              filename: message.data.fileName,
              caption: message.data.caption,
              callback: (response) => {
                if(_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) {
                  // props.setNewEmitMessageProps(message);
                  let _caseDetailData = {...props.caseDetailData};
                  _caseDetailData.blockingReason = response.reason
                  _caseDetailData.blockingMessage = response.message;;
                  _caseDetailData.canReply = false;

                  setTimeout(() => {
                    let _mess = {...tapCoreMessageManager.hideMessageInRoom(props.activeRoom.roomID, message.localID)}
                    currentChatRoomData[message.localID] = _mess.message;
                    props.setNewEmitMessageProps(_mess.lastMessage);
                    setChatDataRoomMain(currentChatRoomData);
                    // props.setCaseDetailData(_caseDetailData);
                    setTemporaryCaseDetailVal({
                      detail: _caseDetailData,
                      room: props.activeRoom,
                      date: new Date().valueOf()
                    })

                    setForceHideInputMessage(true);
                    setShowInputMessage(false);
                  }, 0);
                }
              }
            }

            props.pushSendMessageQueueProps(data);
          },
  
          onError: (errorCode, errorMessage) => {
            doToast(errorMessage, "fail");
            console.log(errorCode, errorMessage);
          }
        }, props.replyMessage.message, false, (_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp || _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) ? WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize) : false);
    }

    let runWithEmit = () => {
      tapCoreMessageManager.sendImageMessage(file, caption, props.activeRoom, 
        {
          onStart: (message) => {
            // console.log(message)
            onStartUploadFile(message);
            // HelperChat.resetHeightClearReply(props.replyMessage);
            HelperChat.resetChatRoomHeightAndInputText();
            props.clearReplyMessage();
          },

          onProgress: (localID, percentage, bytes) => {
            onProgressUploadFile(localID, percentage, bytes);
          },

          onSuccess: (message) => {
            
          },

          onError: (errorCode, errorMessage) => {
            doToast(errorMessage, "fail");
            console.log(errorCode, errorMessage);
          }
        }, props.replyMessage.message);
    }
    
    if(
      _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.telegram ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.instagram || 
      _caseDetailVal.medium === TAPLIVE_MEDIUM.facebook ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.google_business
      // _caseDetailVal.medium === TAPLIVE_MEDIUM.launcher
    ) {
      runWithOutEmit();
    }else{
      runWithEmit();
    }
  }

  let runningVideoMessage = (file, caption) => {
    // let _caseDetailVal = JSON.parse(JSON.stringify(caseDetailVal));
    let _caseDetailVal = {...props.caseDetailValProps};
    
    if(props.caseDetailData !== null) {
      _caseDetailVal = {...props.caseDetailData.case};
    }

    let runWithOutEmit = () => {
      tapCoreMessageManager.sendVideoMessageWithoutEmit(file, caption, props.activeRoom, 
        {
          onStart: (message) => {
            onStartUploadFile(message);
            // HelperChat.resetHeightClearReply(props.replyMessage);
            HelperChat.resetChatRoomHeightAndInputText();
            props.clearReplyMessage();
          },
  
          onProgress: (localID, percentage, bytes) => {
            onProgressUploadFile(localID, percentage, bytes);
          },
  
          onSuccess: (message) => { 
            let currentChatRoomData = chatDataRoomMain;
    
            currentChatRoomData[message.localID] = message;
            
            let data = {
              id: _caseDetailVal.id,
              createdTime: _caseDetailVal.createdTime,
              body: message.data.fileURL,
              localID: message.localID,
              messageType: "video",
              filename: message.data.fileName,
              caption: message.data.caption,
              callback: (response) => {
                if(_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) {
                  // props.setNewEmitMessageProps(message);
                  let _caseDetailData = {...props.caseDetailData};
                  _caseDetailData.blockingReason = response.reason;
                  _caseDetailData.blockingMessage = response.message;
                  _caseDetailData.canReply = false;

                  setTimeout(() => {
                    let _mess = {...tapCoreMessageManager.hideMessageInRoom(props.activeRoom.roomID, message.localID)}
                    currentChatRoomData[message.localID] = _mess.message;
                    props.setNewEmitMessageProps(_mess.lastMessage);
                    setChatDataRoomMain(currentChatRoomData);
                    // props.setCaseDetailData(_caseDetailData);
                    setTemporaryCaseDetailVal({
                      detail: _caseDetailData,
                      room: props.activeRoom,
                      date: new Date().valueOf()
                    })

                    setForceHideInputMessage(true);
                    setShowInputMessage(false);
                  }, 0);
                }
              }
            }

            props.pushSendMessageQueueProps(data);
          },
  
          onError: (errorCode, errorMessage) => {
            doToast(errorMessage, "fail");
            console.log(errorCode, errorMessage);
          }
        }, props.replyMessage.message, false, (_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp || _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) ? WHATSAPP_FILE_SIZE_LIMIT.media(taptalk.getProjectConfigs().core.chatMediaMaxFileSize) : false);
    }

    let runWithEmit = () => {
      tapCoreMessageManager.sendVideoMessage(file, caption, props.activeRoom, 
        {
          onStart: (message) => {
            onStartUploadFile(message);
            // HelperChat.resetHeightClearReply(props.replyMessage);
            HelperChat.resetChatRoomHeightAndInputText();
            props.clearReplyMessage();
          },

          onProgress: (localID, percentage, bytes) => {
            onProgressUploadFile(localID, percentage, bytes);
          },

          onSuccess: (message) => {
            
          },

          onError: (errorCode, errorMessage) => {
            doToast(errorMessage, "fail");
            console.log(errorCode, errorMessage);
          }
        }, props.replyMessage.message);
    }

    if(
      _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.telegram ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.instagram ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.facebook ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.google_business
      // _caseDetailVal.medium === TAPLIVE_MEDIUM.launcher
    ) {
      runWithOutEmit();
    }else{
      runWithEmit();
    }
  }

  // let generateBubbleThisGroupUnavailable = () => {
  //   return (
  //     <div className="room-is-unavailable">
  //       Sorry, this group is unavailable
  //     </div>
  //   )
  // }

  let goToSolveCaseClick = () => {
    props.setActiveCasePanel('solved');
    props.setActiveRoom(null);
  }

  let [loadingGetMessageTemplates, setLoadingGetMessageTemplates] = useState(false)
  let getMessageTemplates = () => {
    setLoadingGetMessageTemplates(true)
    let data = {
      channelID: props.caseDetailData.case.mediumChannelID
    }
    IntegrationWhatsappBusinessServices.getTemplateList(
      props.parentProps.match.params.orgID,
      data,
      (response) => {
        if(response.dataResult.error.code === "") {
          let _data = response.dataResult.data
          let templates = _data.templates.filter((item) => item.isEnabled)
          _data.templates = templates
          props.toggleModalStartConversationProps(_data)
        } else {
          doToast(response.dataResult.error.message);
          console.log(response.dataResult.error.message)
        }
        setLoadingGetMessageTemplates(false)
      }
    )
  }

  let takeOverCaseAction = (takeOver = true, runMarkAsJunk = false) => {
    let _reloadCaseDetail = {...props.reloadCaseDetail};

    if(runMarkAsJunk) {
      setIsLoadingMarkCase(true);
    }

    if(runMarkAsJunk && isSetMarkAsResolved) {
      props.setIsWaitMarkAsJunk(true);
    }

    setLoadingTakeOverCase(true);

    let data = {
      id: caseDetailVal.id, 
      topicID: props.activeHandoverCase.topicValue ? props.activeHandoverCase.topicValue : caseDetailVal.topicID
    }

    CaseService.postTakeCaseAsAgent(props.parentProps.match.params.orgID, data, (response) => {
      props.clearActiveHandoverCase();
      setLoadingTakeOverCase(false);
      setTakenByOtherAgentOrUnassigned(false);

      if(response.dataResult.error.code === "") {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
              `[Action] ${takeOver ? "Take Over Case" : "Assign Me"}`,
              {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : props.parentProps.match.params.orgID.split("-")[0]
              }
          );
        }

        setCaseDetailVal(response.dataResult.data.case);

        let action = () => {
          HelperChat.resetChatRoomHeightAndInputText();
          props.setCaseDetailActionProps(response.dataResult.data.case)
  
          setForceHideInputMessage(false);
  
          if(runMarkAsJunk) {
            markCase()
          }
        }

        if(
          response.dataResult.data.case.medium === TAPLIVE_MEDIUM.instagram ||
          response.dataResult.data.case.medium === TAPLIVE_MEDIUM.whatsappba
        ) {
          _reloadCaseDetail.lastRun = new Date();
          _reloadCaseDetail.callback = () => {
            action();
            props.clearReloadCaseDetail();
          };
          _reloadCaseDetail.room = props.activeRoom;

          props.setReloadCaseDetail(_reloadCaseDetail);
        }else {
          action();
        }
      }else {
        doToast(response.dataResult.error.message);
        console.log(response.dataResult.error.message)
      }
    })
  }

  let runFirstResponseTime = () => {
    //set caseDetailVal firstTimeResponse with date now locally
    let _caseDetailVal = {...props.caseDetailData};

    _caseDetailVal.case.firstResponseTime = new Date().valueOf();

    // setCaseDetailVal(_caseDetailVal);
    // props.setCaseDetailActionProps(_caseDetailVal);
    props.setCaseDetailData(_caseDetailVal);
    //set caseDetailVal firstTimeResponse with date now locally

    caseServices.postSetFirstResponse(props.parentProps.match.params.orgID, {id: _caseDetailVal.case.id}, (response) => {
      if(response.dataResult.error.code === "") {
        setCaseDetailVal(response.dataResult.data.case);
        _caseDetailVal.case = response.dataResult.data.case;
        // props.setCaseDetailActionProps(response.dataResult.data.case);
        props.setCaseDetailData(_caseDetailVal);
      }else {
        // doToast(response.dataResult.error.message, 'fail');
        console.log(response.dataResult.error.message)
      }
    })
  }

  let runSetCaseDetailVal = (caseDetail) => {
    setCaseDetailVal(caseDetail);
  }

  let runSetLastDragAndDropFiles = () => {
    setLastDragAndDropFiles({
      files: [],
      time: ""
    })
  }

  let toggleDropFileHere = () => {
    if((!isPauseClockState || props.allowReplyOnAway) && caseDetailVal.agentAccountID !== 0 && !caseDetailVal.isClosed && props.caseDetailData && props.caseDetailData.blockingReason === "") {
      setShowDropFileHere(!showDropFileHere);
    }else {
      return false;
    }
  }

  let hidedropFileHere = () => {
    setShowDropFileHere(false);
  }

  let checkIsFileorMedia = (files) => {
    if((!isPauseClockState || props.allowReplyOnAway) && caseDetailVal.agentAccountID !== 0 && !caseDetailVal.isClosed && props.caseDetailData && props.caseDetailData.blockingReason === "") {
      let _files = Array.from(files);
      let isMedia = null;
      
      _files.map((value) => {
        if(value.type.split("/")[0] === "video" || value.type.split("/")[0] === "image") {
          isMedia === null && (isMedia = true);
        }else {
          isMedia = false;
        }

        return null;
      })

      if(!isMedia) {
        runningFileMessage(_files);
      }else {
        setLastDragAndDropFiles({
          files: _files,
          time: new Date().valueOf()
        })
      }
    }else {
      return false;
    }
  }

  let generateViewDropFileHere = () => {
    let handleDropFile = (e) => {
      toggleDropFileHere();
      e.preventDefault();
      let files= e.dataTransfer.files;

      if(files.length > 0) {
        let _fileType = files[0].type; 
        if(props.caseDetailData && props.caseDetailData.case.medium === TAPLIVE_MEDIUM.instagram) {
          if(_fileType.split("/")[0] === "image") {
            checkIsFileorMedia(files);
          }
        }else {
          checkIsFileorMedia(files);
        }
      }
    }

    return (
      <div className={`drop-file-here-wrapper ${showDropFileHere ? "active-drop-file-here-wrapper" : ""}`}
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {handleDropFile(e)}}
      >
        <div className="drop-file-here-content">
          <div className="drop-file-here-inner-content">
            <FiUpload />

            <p>
              Drop your files, image or video here
            </p>
          </div>
        </div>
      </div>
    )
  }

  let actionCaseClose = () => {
    let caseID = props.activeRoom.xcRoomID.split(':')[1];
    let _caseDetailVal = {...props.caseDetailData};

    let data = {
      id: parseInt(caseID),
    }

    CaseService.postCloseCase(props.parentProps.match.params.orgID, data, (response) => {
      // setIsloadingResolvedCase(false);
      if(response.dataResult.error.code === "") {
        _caseDetailVal.case.closedTime = new Date().valueOf();
        _caseDetailVal.case.isClosed = true;
        props.setCaseDetailData(_caseDetailVal);
        // goToSolveCaseClick();
        // props.setActiveCasePanel("solved");
        // props.setActiveRoom(null);

        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Mark Resolved",
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : props.parentProps.match.params.orgID.split("-")[0]
            }
          );
        }
        doToast(response.dataResult.data.message);
      }else {
        doToast(response.dataResult.error.message, "fail");
      }
    });
  }

  let markCase = (markAsJunk = true) => {
    setIsLoadingMarkCase(true);

    let caseID = props.activeRoom.xcRoomID.split(':')[1];
    let _caseDetailVal = {...props.caseDetailData};

    let data = {
      id: parseInt(caseID),
      createdTime: caseDetailVal.createdTime,
      isJunk: markAsJunk
    }
    
    CaseService.updateJunkStatus(props.parentProps.match.params.orgID, data, (response) => {
      setIsLoadingMarkCase(false);

      if(response.dataResult.error.code === "") {
        _caseDetailVal.case.isJunk = markAsJunk;
        props.setCaseDetailData(_caseDetailVal);

        if(isSetMarkAsResolved) {
          actionCaseClose()
        }
        
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Update Junk Status",
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : props.parentProps.match.params.orgID.split("-")[0]
            }
          );
        }

        setShowModalMarkAsJunk(false);
        setIsSetMarkAsResolved(false);

        doToast(response.dataResult.data.message);
      }else {
        doToast(response.dataResult.error.message, "fail");
      }
    })
  }

  let is2LineBlocking = () => {
    let block = false;

    if(
      props.caseDetailData.blockingReason !== BLOCKING_REASON.channel_not_found.blockingReason ||
      props.caseDetailData.blockingReason !== BLOCKING_REASON.channel_not_set_up.blockingReason ||
      props.caseDetailData.blockingReason !== BLOCKING_REASON.channel_status_invalid.blockingReason ||
      props.caseDetailData.blockingReason !== BLOCKING_REASON.channel_disabled.blockingReason ||
      props.caseDetailData.blockingReason !== BLOCKING_REASON.channel_suspended.blockingReason ||
      props.caseDetailData.blockingReason !== BLOCKING_REASON.channel_unknown.blockingReason ||
      props.caseDetailData.blockingReason !== BLOCKING_REASON.internal_error.blockingReason 
    ) {
      block = true;
    }

    return block;
  }

  //quick reply
  let quickReplySendCustom = (messageType, body, data) => {
    let messageModel = tapCoreMessageManager.constructTapTalkMessageModel(
      body, 
      props.activeRoom, 
      messageType, 
      data
    );
      
    tapCoreMessageManager.sendCustomMessage(messageModel, (message) => { 
      message.body = body;
      message.data = data;
    
      props.setNewEmitMessageProps(message);
          
      let currentChatRoomData = chatDataRoomMain;
      
      currentChatRoomData[message.localID] = message;
      
      setChatDataRoomMain(currentChatRoomData);
      
      scrollChatViewToBottom();
    })
  }

  let runQuickReplyFile = (shortcutMessage) => {
    let elTarget = document.querySelectorAll(".main-textarea-input");
    let _shortcutMessage = {...shortcutMessage};
    let _caseDetailVal = {...props.caseDetailValProps};
    
    if(props.caseDetailData !== null) {
      _caseDetailVal = {...props.caseDetailData.case};
    }

    if(_caseDetailVal.firstResponseTime === 0) {
      runFirstResponseTime()
    }

    let _dataConstruct = {
      fileName: _shortcutMessage.mediaFilename,
      url: _shortcutMessage.mediaURL,
      fileURL: _shortcutMessage.mediaURL,
      // mediaType: _shortcutMessage.messageType,
      mediaType: "application/pdf",
      fileID: "",
      size: 0,
      caption: elTarget.length > 0 ? elTarget[0].value : "",
    };

    if(
      _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.telegram ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.instagram ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.facebook ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.google_business
      // _caseDetailVal.medium === TAPLIVE_MEDIUM.launcher
    ) {
      let message = tapCoreMessageManager.constructTapTalkMessageModel(`📎 ${shortcutMessage.mediaFilename}`, props.activeRoom, CHAT_TYPE.TAPChatMessageTypeFile, _dataConstruct);
      let currentChatRoomData = chatDataRoomMain;
      message.body = `📎 ${shortcutMessage.mediaFilename}`;
      message.data = _dataConstruct;
      currentChatRoomData[message.localID] = message;

      let data = {
        id: _caseDetailVal.id,
        createdTime: _caseDetailVal.createdTime,
        body: _shortcutMessage.mediaURL,
        localID: message.localID,
        messageType: "file",
        filename: _shortcutMessage.mediaFilename,
        caption: elTarget.length > 0 ? elTarget[0].value : "",
        callback: (response) => {
          if(_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) {
            // props.setNewEmitMessageProps(message);
            let _caseDetailData = {...props.caseDetailData};
            _caseDetailData.blockingReason = response.reason;
            _caseDetailData.blockingMessage = response.message;
            _caseDetailData.canReply = false;

            setTimeout(() => {
              let _mess = {...tapCoreMessageManager.hideMessageInRoom(props.activeRoom.roomID, message.localID)}
              currentChatRoomData[message.localID] = _mess.message;
              props.setNewEmitMessageProps(_mess.lastMessage);
              setChatDataRoomMain(currentChatRoomData);
              setTemporaryCaseDetailVal({
                detail: _caseDetailData,
                room: props.activeRoom,
                date: new Date().valueOf()
              })

              setForceHideInputMessage(true);
              setShowInputMessage(false);
              scrollChatViewToBottom();
            }, 0);
          }
        }
      }

      props.pushSendMessageQueueProps(data);
    }else {
      quickReplySendCustom(CHAT_TYPE.TAPChatMessageTypeFile, `📎 ${shortcutMessage.mediaFilename}`, _dataConstruct);
    }
  
    // onInputNewMessage(elTarget.length > 0 ? elTarget[0].value : "");
    
    props.setQuickReplyDocument(false);
    HelperChat.resetChatRoomHeightAndInputText();
  }

  let runQuickReplyMedia = (_data, messageType) => {
    HelperChat.resetChatRoomHeightAndInputText();
    
    let _caseDetailVal = {...props.caseDetailValProps};
    
    if(_caseDetailVal.firstResponseTime === 0) {
      runFirstResponseTime()
    }

    let _dataConstruct = {
      fileName: '',
      url: _data.fileURL,
      fileURL: _data.fileURL,
      // mediaType: _shortcutMessage.messageType,
      // mediaType: messageType,
      // fileID: "",
      // size: 0,
      caption: _data.caption
    };

    if(
      _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsapp ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.telegram ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.instagram ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.facebook ||
      _caseDetailVal.medium === TAPLIVE_MEDIUM.google_business
      // _caseDetailVal.medium === TAPLIVE_MEDIUM.launcher
    ) {
      let message = tapCoreMessageManager.constructTapTalkMessageModel(messageType === "image" ? `${_data.caption !== "" ? `🖼 ${_data.caption}` : '🖼 Photo'}` : `${_data.caption !== "" ? `🎥 ${_data.caption}` : '🎥 Video'}`, props.activeRoom, messageType === "image" ? CHAT_TYPE.TAPChatMessageTypeImage : CHAT_TYPE.TAPChatMessageTypeVideo, _dataConstruct);
      let currentChatRoomData = chatDataRoomMain;
      message.body = messageType === "image" ?
        `${_data.caption !== "" ? `🖼 ${_data.caption}` : '🖼 Photo'}`
        :
        `${_data.caption !== "" ? `🎥 ${_data.caption}` : '🎥 Video'}`
      ;
      message.data = _dataConstruct;
      currentChatRoomData[message.localID] = message;

      let data = {
        id: _caseDetailVal.id,
        createdTime: _caseDetailVal.createdTime,
        body: _data.fileURL,
        localID: message.localID,
        messageType: messageType,
        filename: "",
        caption: _data.caption,
        callback: (response) => {
          if(_caseDetailVal.medium === TAPLIVE_MEDIUM.whatsappba) {
            // props.setNewEmitMessageProps(message);
            let _caseDetailData = {...props.caseDetailData};
            _caseDetailData.blockingReason = response.reason;
            _caseDetailData.blockingMessage = response.message;
            _caseDetailData.canReply = false;

            setTimeout(() => {
              let _mess = {...tapCoreMessageManager.hideMessageInRoom(props.activeRoom.roomID, message.localID)}
              currentChatRoomData[message.localID] = _mess.message;
              props.setNewEmitMessageProps(_mess.lastMessage);
              setChatDataRoomMain(currentChatRoomData);
              setTemporaryCaseDetailVal({
                detail: _caseDetailData,
                room: props.activeRoom,
                date: new Date().valueOf()
              })

              setForceHideInputMessage(true);
              setShowInputMessage(false);
              scrollChatViewToBottom();
            }, 0);
          }
        }
      }

      props.pushSendMessageQueueProps(data);
    }else {
      quickReplySendCustom(messageType === "image" ? CHAT_TYPE.TAPChatMessageTypeImage : CHAT_TYPE.TAPChatMessageTypeVideo, _data.caption, _dataConstruct);
    }

    // quickReplySendCustom(messageType === "image" ? CHAT_TYPE.TAPChatMessageTypeImage : CHAT_TYPE.TAPChatMessageTypeVideo, _data.caption, _data);
  }
  //quick reply

  return (
    <div 
      className={`
        wrap-all-chatroom 
        ${(props.salesTalkInbox.isShow || props.isUserInfoShowProps) ? 'active-chat-user-info' : ''}
      `}
      onDragEnter={() => {
        if(props.caseDetailData && (
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.launcher ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.facebook ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.google_business ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.instagram ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.line ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.telegram ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.twitter ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsapp
        )) {
          toggleDropFileHere()
        }
      }}
      onDragLeave={() => {
        if(props.caseDetailData && (
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.launcher ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.facebook ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.google_business ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.instagram ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.line ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.telegram ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.twitter ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsapp
        )) {
          toggleDropFileHere()
        }
      }}
      onPaste={(e) => {
        if(props.caseDetailData && (
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.launcher ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.facebook ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.google_business ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.instagram ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.line ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.telegram ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.twitter ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba ||
          props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsapp
        )) {
          checkIsFileorMedia(e.clipboardData.files);
        }
      }}
    >   
        {(props.caseDetailData && props.caseDetailData.case.medium !== TAPLIVE_MEDIUM.gb_profile) &&
          generateViewDropFileHere()
        }

        <PopupRemove 
          removeTitle={`Send Message?`}
          removeDescription={`This message will replace your last reply, if any.`}
          onClickCancel={toggleModalSend}
          onClickRemove={() => {
            onInputNewMessage(temporaryMessageText, false);
            toggleModalSend(true);
          }}
          additionalContent={
            <div 
              className="set-send-closing-message-wrapper"
              style={{
                display: "flex",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: 14,
                lineHeight: "21px",
                letterSpacing: "-0.2px",
                color: "rgba(25, 25, 25, 0.6)",
                padding: "0 8px 8px",
                background: "rgba(25, 25, 25, 0.05)",
                borderRadius: 8,
                marginTop: 8,
              }}
            >
              <div className="custom-checkbox">
                  <input 
                    type="checkbox"
                    id="set-send-mark-as-resolved"
                    checked={checkMarkAsSolved}
                    onClick={() => setCheckMarkAsSolved(!checkMarkAsSolved)}
                    disabled={false}
                    readOnly
                  />
                  <label htmlFor="set-send-mark-as-resolved" />
              </div>
              <p style={{marginTop: 7}}>Mark as Resolved</p>
            </div>
          }
          withoutBorder
          loadingButtonGray
          waitForResponse={false}
          showModalProps={showModalSend}
          submitText={'Send Message'}
        />

        <div className={`chat-room-main-wrapper 
                         ${props.markAsSolvedPanelShowProps ? 'mark-as-solved-is-active' : ''} 

                         ${props.caseDetailData === null ?
                            ((props.clockState === CLOCK_STATE.isPaused && !props.allowReplyOnAway) ? 
                              'chat-room-respond-is-disabled b' 
                              : 
                              ''
                            )
                            :
                            (
                              (
                                (props.clockState === CLOCK_STATE.isPaused && !props.allowReplyOnAway) ||
                                (!props.caseDetailData.canReply && !ignoreBlockingMessage)
                              ) ? 
                                'chat-room-respond-is-disabled c' 
                                : 
                                (
                                  props.caseDetailData.blockingReason !== "" && 
                                  props.caseDetailData.blockingReason !== BLOCKING_REASON.agent_not_assigned.blockingReason && 
                                  props.caseDetailData.blockingReason !== BLOCKING_REASON.case_closed.blockingReason &&
                                  props.caseDetailData.blockingReason !== BLOCKING_REASON.reply_hours_exceeded.blockingReason && 
                                  !ignoreBlockingMessage
                                ) ? 
                                  'chat-room-respond-is-disabled d'
                                  :
                                  ''
                            )
                          }

                          ${(
                            props.caseDetailData && 
                            !props.caseDetailData.case.isClosed && 
                            forceHideInputMessage && 
                            (!props.startConversation.conversation && !props.startConversationBa.conversation) &&
                            (props.clockState !== CLOCK_STATE.isPaused || props.allowReplyOnAway) &&
                            !is2LineBlocking() &&
                            props.caseDetailData.case.medium !== TAPLIVE_MEDIUM.instagram
                          ) ? 
                            'force-hide-input-message' 
                            : 
                            ''
                          } 

                          ${(props.caseDetailData &&
                            (props.clockState !== CLOCK_STATE.isPaused || props.allowReplyOnAway) &&
                             !(
                              !props.showOtherCase.configShow && 
                              props.caseDetailData && 
                              props.caseDetailData.case.agentAccountID !== HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id &&
                              props.caseDetailData.case.agentAccountID !== 0
                             )
                          ) ?
                            (!showInputMessage ? 
                              (showCaseClosedPanel ||
                               takenByOtherAgentOrUnassigned
                              ) ? 
                                'chat-room-panel-info-show' 
                                : 
                                'chat-room-nopanel-input-or-panel-info' 
                              : 
                              'chat-room-show-input-message'
                            )
                            :
                            ""
                          }

                          ${(
                            props.caseDetailData &&
                            props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba &&
                            props.caseDetailData.blockingReason === BLOCKING_REASON.reply_hours_exceeded.blockingReason &&
                            (props.clockState !== CLOCK_STATE.isPaused || props.allowReplyOnAway) &&
                            !props.startConversationBa.conversation
                          ) ?
                            'chat-room-panel-info-show b'  
                            :
                            ""
                          }

                          ${hideReplyButton ?
                            'hide-reply-button'
                            :
                            ''
                          }
                          
                          ${(!props.showOtherCase.configShow && 
                             props.caseDetailData && 
                             props.caseDetailData.case.agentAccountID !== HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data).account.id &&
                             !props.caseDetailData.case.isClosed
                          ) ?
                            "chat-room-respond-is-disabled e"
                            :
                            ""
                          }
                      `}
            //  style={{maxHeight: `calc(100vh - ${
            //     !showInputMessage ? 
            //       (showCaseClosedPanel || takenByOtherAgentOrUnassigned) ? 
            //         '232' 
            //         : 
            //         '104' 
            //       : 
            //       '158'
            //  }px)`}}
        >   
            {isShowLoadingBlocking &&
              <div className="chat-room-main-wrapper-loading-blocking">
                    <div className="lds-ring">
                      <div /><div /><div /><div />
                    </div>
              </div>
            }

            {/* <Scrollbars autoHide autoHideTimeout={500}
                    renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}> */}
                
                <div className="chat-room-main-content" 
                     onScroll={() => onScrollListener()} ref={chatRoomMesageListRef}
                    //  style={{maxHeight: `calc(100vh - ${!showInputMessage ? '41' : props.markAsSolvedPanelShowProps ? '208' : '160'}px)`}}
                >
                {/* <Scrollbars autoHide autoHideTimeout={500}
                    renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}> */}
                  {isLoadingChat &&
                    <div className="loading-message-wrapper">
                      <div className="lds-ring">
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                    </div>
                  }

                  {/* <div id="chat-room-data" data={JSON.stringify(chatDataRoomMain)} /> */}

                  {Object.keys(chatDataRoomMain).length > 0 &&
                    Object.keys(chatDataRoomMain).map(function(key) {
                      if(!chatDataRoomMain[key].isHidden) {
                        return (
                          generateMessageBubble(chatDataRoomMain[key], chatDataRoomMain[key].localID)
                        )
                      }
                      return null;
                    })
                  }
                  {/* </Scrollbars> */}
                </div>
            {/* </Scrollbars> */}
        </div>
        
        {/* {props.caseDetailData !== null && */}
          {(showInputMessage && (!(props.clockState === CLOCK_STATE.isPaused) || props.allowReplyOnAway)) &&
            <ChatRoomInputMessage 
              caseDetail={props.caseDetailData}
              onInputNewMessageProps={onInputNewMessage}
              runningFileMessageProps={runningFileMessage}
              runningImageMessageProps={runningImageMessage}
              runningVideoMessageProps={runningVideoMessage}
              toggleMarkAsSolvedPanelProps={props.toggleMarkAsSolvedPanelProps}
              markAsSolvedPanelShowProps={props.markAsSolvedPanelShowProps}
              parentProps={props.parentProps}
              lastDragAndDropFilesProps={lastDragAndDropFiles}
              setLastDragAndDropFilesProps={runSetLastDragAndDropFiles}
              hidedropFileHereProps={hidedropFileHere}
              runSetCaseDetailValProps={runSetCaseDetailVal}
              setCaseDetailActionProps={props.setCaseDetailActionProps}
              onClickCancelConversation={() => {
                let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                if(props.caseDetailData.case.agentAccountID !== myAgentData.account.id) {
                  setShowInputMessage(false);
                  setForceHideInputMessage(true);            
                  setTakenByOtherAgentOrUnassigned(true);
                }
              }}
              runQuickReplyFile={runQuickReplyFile}
              runQuickReplyMedia={runQuickReplyMedia}
            />
          }
        {/* } */}
        {(showCaseClosedPanel && (!(props.clockState === CLOCK_STATE.isPaused) || props.allowReplyOnAway)) &&
          <div className={`no-longer-participant`}>
            <p>
              <img src={iconClock} alt="" />
              <b>Case has been resolved</b>
            </p>

            <p>
              This case will be moved to resolved cases.
            </p>

            <button className="orange-button main-button-48" onClick={() => goToSolveCaseClick()}>
              <img src={iconCheck} alt="" />
              <b>Go to Resolved</b>
            </button>
          </div>
        }

        {/* {((props.clockState === CLOCK_STATE.isPaused) && (props.activeCasePanel !== 'solved')) && */}
        {(isPauseClockState && !props.allowReplyOnAway) &&
            <div className={`no-longer-participant`}>
            <p>
              <b>Responding is disabled</b>
            </p>

            <p>
              You have to "Resume" before you can respond to cases.
            </p>
            </div>
        }

        {(props.caseDetailData !== null && !props.startConversationBa.conversation && (props.clockState === CLOCK_STATE.isStarted || props.allowReplyOnAway)) &&
          (props.caseDetailData.blockingReason === BLOCKING_REASON.reply_hours_exceeded.blockingReason && 
            <div className={`last-message-received`}>
              <p>
                <FiClock className="icon-title-send-template" />
                <b>{props.caseDetailData.blockingMessage}</b>
              </p>

              {props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba ?
                <p>
                  You will have to send a templated message to this customer.
                </p>
                :
                <p>
                  You have to wait until the customer reply first.
                </p>
              }

              {props.caseDetailData.case.medium === TAPLIVE_MEDIUM.whatsappba &&
                (!loadingGetMessageTemplates ? 
                  <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-48"  
                    onClickAction={getMessageTemplates}
                    icon={{
                      type: "svg",
                      src: RiEditLine
                    }}
                    text="Send Templated Message"
                    position="left"
                  />
                  :
                  <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-48" 
                    loadingColor="gray"
                    isLoading
                    isDisabled
                    text="Send Templated Message"
                    position="left"
                  />
                )
              }
            </div>
          )
        }

        {(takenByOtherAgentOrUnassigned && 
         (!(props.clockState === CLOCK_STATE.isPaused) || props.allowReplyOnAway) &&
         props.caseDetailData && 
         !props.caseDetailData.case.isClosed && 
         props.caseDetailData.blockingReason !== BLOCKING_REASON.reply_hours_exceeded.blockingReason
        ) &&
          <div className={`no-longer-participant`}>
            <p>
              <img src={(caseDetailVal.assigneeType !== undefined  && caseDetailVal.assigneeType !== "agent") ? iconBot : iconAvatar} alt="" />
              {(caseDetailVal.assigneeType !== undefined  && caseDetailVal.assigneeType !== "agent") ?
                <b>Chatbot</b>
                :
                (caseDetailVal.agentAccountID === 0 ?
                  <b>This case is unassigned</b>
                  :
                  <b>Case has been assigned to {caseDetailVal.agentFullName}</b>
                )
              }
            </p>

            <p>
              {(caseDetailVal.assigneeType !== undefined  && caseDetailVal.assigneeType !== "agent") ?
                'You are still able to take over and assign yourself to this case'
                :
                (caseDetailVal.agentAccountID === 0 ?
                  'You can assign yourself to this case or mark as junk.'
                  :
                  props.showOtherCase.configShow ?
                    'You are still able to take over and assign yourself to this case'
                    :
                    'You are not allowed to assign this case to yourself'
                )
              }
            </p>
            
            {(props.showOtherCase.configShow || caseDetailVal.agentAccountID === 0) &&
              (!loadingTakeOverCase ?
                <div style={{display:"flex", justifyContent:"space-evenly"}}>
                  <button 
                    className="orange-button main-button-48"
                    onClick={() => 
                      caseDetailVal.topicID === 0 ?
                        props.setActiveHandoverCase({
                          show: true,
                          topicID: caseDetailVal.topicID
                        })
                        :
                        takeOverCaseAction(false)
                    }
                    style={{width: ((caseDetailVal.assigneeType !== undefined  && caseDetailVal.assigneeType !== "agent") || caseDetailVal.agentAccountID !== 0 ? "100%" : "47%")}}
                  >
                    <b>{(caseDetailVal.assigneeType !== undefined  && caseDetailVal.assigneeType !== "agent") || caseDetailVal.agentAccountID !== 0 ? 'Take Over Case' : 'Assign Me'}</b>
                  </button>
                  
                  {(caseDetailVal.assigneeType === 'agent' && caseDetailVal.agentAccountID === 0) &&
                    // (!caseDetailVal.isJunk ?
                      <button 
                        className="no-fill-button main-button-48" 
                        onClick={() => setShowModalMarkAsJunk(true)}
                        style={{width: "47%"}}
                      >
                        <FiAlertTriangle />
                        <b>Mark as Junk</b>
                      </button>
                    //   :
                    //   <button 
                    //     className="no-fill-button main-button-48" 
                    //     onClick={() => setShowModalUnmarkAsJunk(true)}
                    //     style={{width: "47%"}}
                    //   >
                    //     <FiAlertTriangle />
                    //     <b>Unmark as Junk</b>
                    //   </button>
                    // )
                  }
                </div>
                :
                <button className="orange-button main-button-48">
                  <div className="lds-ring">
                    <div /><div /><div /><div />
                  </div>
                </button>
              )
            }
            
          </div>
        }
        
        {(props.caseDetailData !== null && caseDetailVal !== null) &&
          (!props.caseDetailData.canReply && 
           props.caseDetailData.blockingReason !== BLOCKING_REASON.agent_not_assigned.blockingReason && 
           props.caseDetailData.blockingReason !== BLOCKING_REASON.reply_hours_exceeded.blockingReason && 
           !showCaseClosedPanel && 
           !caseDetailVal.isClosed
          ) &&
            <div className={`no-longer-participant`}>
            <p>
              <b>Responding is disabled</b>
            </p>

            <p>
              {props.caseDetailData.blockingMessage}
            </p>
           </div>
        } 

        <MarkAsJunk
          onClickCancel={() => {
            setShowModalMarkAsJunk(false);
            setIsSetMarkAsResolved(false);
          }}
          markCase={() => takeOverCaseAction(true, true)}
          isSetMarkAsResolved={isSetMarkAsResolved}
          toggleMarkAsResolved={() => setIsSetMarkAsResolved(!isSetMarkAsResolved)}
          showModal={showModalMarkAsJunk}
          isLoadingMarkCase={isLoadingMarkCase}
        />

        <MarkAsJunk
          onClickCancel={() => {
            setShowModalUnmarkAsJunk(false);
            setIsSetMarkAsResolved(false);
          }}
          markCase={() => markCase(false)}
          isSetMarkAsResolved={isSetMarkAsResolved}
          toggleMarkAsResolved={() => setIsSetMarkAsResolved(!isSetMarkAsResolved)}
          showModal={showModalUnmarkAsJunk}
          unmarkJunk
          isLoadingMarkCase={isLoadingMarkCase}
        />
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  activeCasePanel: state.activeCasePanel,
  activeHandoverCase: state.activeHandoverCase,
  caseDetailData: state.caseDetailData,
  startConversation: state.startConversation,
  replyMessage: state.replyMessage,
  startConversationBa: state.startConversationBa,
  clockState: state.clockState,
  reloadCaseDetail: state.reloadCaseDetail,
  showOtherCase: state.showOtherCase,
  allowReplyOnAway: state.allowReplyOnAway,
  submitHandoverCase: state.submitHandoverCase,
  draftMessage: state.draftMessage,
  salesTalkInbox: state.salesTalkInbox,
  salesTalkSelectedProduct: state.salesTalkSelectedProduct,
  reconnectChatRoom: state.reconnectChatRoom
});

const mapDispatchToProps = {
  setActiveRoom,
  setActiveCasePanel,
  clearCaseDetailData,
  setCaseDetailData,
  setReplyMessage,
  clearReplyMessage,
  setIsWaitMarkAsJunk,
  // clearIsWaitMarkAsJunk,
  setReloadCaseDetail,
  clearActiveHandoverCase,
  clearReloadCaseDetail,
  clearSubmitHandoverCase,
  setDraftMessage,
  setActiveHandoverCase,
  setSalestalkSelectedProduct,
  clearSalestalkSelectedProduct,
  setForwardMessage,
  setReconnectChatRoom,
  setQuickReplyDocument
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMain);