import React from "react";
import PopupRemove from "../popupRemove/PopupRemove";

const PopupDiscard = ({
    isOpenDiscard,
    toggleDiscard,
    discardProgress,
    discardTitle,
    discardDesc
}) => {
    return (
        <PopupRemove
            removeTitle={discardTitle ? discardTitle : 'Discard Changes?'}
            removeDescription={discardDesc ? discardDesc : `You have unsaved progress on this page. Are you sure you want to discard your progress?`}
            onClickCancel={toggleDiscard}
            onClickRemove={discardProgress}
            // waitForResponse={waitForResponseDiscard}
            showModalProps={isOpenDiscard}
            submitText="Discard Changes"
            colorButton="red-button"
        />
    )
}

export default PopupDiscard;
