import React, { useState } from 'react';
import './ChatRoomMessageOutWabaTemplate.scss';
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import { Modal, ModalBody } from "reactstrap";
import { FiCopy, FiInfo } from 'react-icons/fi';
import { FaReply } from 'react-icons/fa';
import Helper from '../../../../helper/HelperChat';
import MessageDeleteWhite from "../../../../assets/img/icon-notallowed-white.svg";

var ChatRoomMessageOut = (props) => {
  let [modalInfo, setModalInfo] = useState(false)

  let messageActionView = (message) => {
    let onClickReply = () => {
        props.onReplyMessage(message)
    }

    return (
        <div className="message-action-wrapper">  
              <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
                  <FiCopy />
              </div>

              <div className="message-action-button-wrapper reply-button" title="Reply"  onClick={() => onClickReply()}>
                  <FaReply />
              </div>
         </div>
    )
}

  return (
    <div className="chat-room-message-out-wrapper">
        <Modal
            className="modal-templated-message"
            isOpen={modalInfo}
            >
            <ModalBody>
                <div>
                    <FiInfo />
                    <b>This is a templated message</b>
                </div>
                <p>
                    Templated messages are messages that has been saved and approved by WhatsApp officially and cannot be altered.
                </p>

                <button
                    className="orange-button main-button-40"
                    onClick={() => setModalInfo(false)}
                >
                    Dismiss
                </button>
            </ModalBody>
        </Modal>
        {props.singleChatDataProps.isDeleted ?
            <div className="message-out-bubble-waba-template deleted-bubble">
                <React.Fragment>
                    <img src={MessageDeleteWhite} alt="" className="deleted-icon" />
                    This message was deleted.
                </React.Fragment>
            </div>
            :

            <div className="message-out-bubble-waba-template">
                {/* {props.singleChatDataProps.forwardFrom.localID !== "" &&
                    <div className="forwarded-message">
                        <b>Forwarded</b>
                        <br />
                        From: <b>{props.singleChatDataProps.forwardFrom.fullname}</b>
                    </div>
                } */}

                <span className="message-body" dangerouslySetInnerHTML={ {__html: Helper.convertFormatTextWhatsapp(Helper.lineBreakChatRoom(props.singleChatDataProps.body))} }></span>
                <p className="message-info">
                    {Helper.getDateMonthYear(props.singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(props.singleChatDataProps.created)}
                
                    {(props.singleChatDataProps.isSending && !props.singleChatDataProps.isRead) &&
                        <img src={AirplaneDark} alt="" />
                    }

                    {(!props.singleChatDataProps.isSending && !props.singleChatDataProps.isDelivered && !props.singleChatDataProps.isRead) &&
                        <img src={CheckMarkDark} alt="" />
                    }

                    {(!props.singleChatDataProps.isSending && props.singleChatDataProps.isDelivered && !props.singleChatDataProps.isRead) &&
                        <img src={CheckMarkDoubleDark} alt="" />
                    }

                    {props.singleChatDataProps.isRead &&
                        <img src={CheckMarkDoubleWhite} alt="" />
                    }

                    {/* {!props.singleChatDataProps.isDelivered &&
                        <img src={CheckMarkDark} alt="" />
                    } */}
    
                </p>
                
                <div className="message-out-template-banner" onClick={() => {console.log("clicked"); setModalInfo(true)}}>
                    <FiInfo />
                    {/* <div> */}
                        <p>This is a templated message</p>
                    {/* </div> */}
                </div>
                
                {messageActionView(props.singleChatDataProps)}
            </div>
        }
    </div>
  );
}

export default ChatRoomMessageOut;
