import React from 'react';
import GoogleMapReact from 'google-map-react';
import './ChatRoomMessageInLocation.scss';
import { FiCopy } from 'react-icons/fi';
import { FaReply } from 'react-icons/fa';
import Helper from '../../../../helper/HelperChat';
import { printLastMessageName } from '../../../../helper/HelperGeneral';
import MessageDeleteGrey from "../../../../assets/img/icon-notallowed-grey.svg";
import MarkerMap from '../../../../assets/img/marker-map.svg';
import { taptalk } from '@taptalk.io/web-sdk';
// import { MdInsertDriveFile } from 'react-icons/md';
import iconAgent from '../../../../assets/img/chatroom/icon-agent.svg';
import iconChatbot from '../../../../assets/img/chatroom/icon-chatbot.svg';
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";
import ChatRoomReplyInMessage from "../chatRoomReplyInMessage/chatRoomReplyInMessage/ChatRoomReplyInMessage";
import { setReplyMessage } from '../../../../redux/actions/reduxActionReplyMessage';
import { connect } from 'react-redux';

const ROLE_LIST = {
    agent: {
        value: "agent",
        label: "Agent"
    },
    organization: {
        value: "organization",
        label: "Organization"
    },
    chatbot: {
        value: "chatbot",
        label: "Chatbot"
    }
}

var ChatRoomMessageInLocation = (props) => {
  let { 
      singleChatDataProps,
      activeUserID,
      onReplyMessage,
      onForwardMessage,
      scrollToReply,
      caseData
    //   activeRoom
  } = props;

  let messageActionView = (message) => {
      let onClickReply = () => {
         onReplyMessage(message)
      }

      return (
        <div
            className={`message-action-wrapper message-action-right with-forward`}
        >  
                <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
                    <FiCopy />
                </div>

                <div className="message-action-button-wrapper reply-button" title="Reply"  onClick={() => onClickReply()}>
                    <FaReply />
                </div>

                <div className="message-action-button-wrapper reply-button forward-button" title="Forward" onClick={() => onForwardMessage(message)}>
                    <FaReply />
                </div>
           </div>
      )
  }

  let MarkerMapComponent = () => <div className="marker-map"><img src={MarkerMap} alt="" /></div>;

  return (
    <div className="chat-room-message-in-wrapper" id={`message-${singleChatDataProps.localID}`}>
        {/* {singleChatDataProps.room.type === 2 && */}
            <div className="group-sender-avatar-wrapper" style={{background: taptalk.getRandomColor(printLastMessageName(singleChatDataProps, caseData, true, true))}}>
                {singleChatDataProps.user.imageURL.thumbnail !== "" ? 
                    <img src={singleChatDataProps.user.imageURL.thumbnail} alt="" onError={(e) => {e.target.onerror = null; e.target.src = iconReplaceAvatar;}} />
                    :
                    <b>{Helper.renderUserAvatarWord(printLastMessageName(singleChatDataProps, caseData, true, true))}</b>
                }
                
                {(singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value ||
                    singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value
                ) &&
                    <img src={iconAgent} alt="" className="avatar-icon-badge" />
                }

                {(singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value) &&
                    <img src={iconChatbot} alt="" className="avatar-icon-badge" />
                }
             </div>
        {/* } */}
        
        {singleChatDataProps.isDeleted ?
            <div className={`message-in-bubble-location deleted-bubble ${singleChatDataProps.isDeleted ? 'deleted-group-in' : ''}`}>
                {/* {props.forwarded &&
                    <div className="forwarded-message">
                        <b>Forwarded</b>
                        <br />
                        From: <b>Keju Manis</b>
                    </div>
                } */}

                <React.Fragment>
                    {singleChatDataProps.room.type === 2 &&
                        <p className="group-sender-name-wrapper">
                            <b>{printLastMessageName(singleChatDataProps, caseData, true)}</b>
                        </p>
                    }

                    <img src={MessageDeleteGrey} alt="" className="deleted-icon" />
                    This message was deleted.
                </React.Fragment>
            </div> 
            :
            
            <React.Fragment>
                <div className="message-in-bubble-location message-in-bubble-location-location">
                   <p className="group-sender-name-wrapper">
                        <b>
                            {singleChatDataProps.user.userRole.code === ROLE_LIST.agent.value &&
                                <span className="agent-span">{ROLE_LIST.agent.label} - </span>
                            }

                            {singleChatDataProps.user.userRole.code === ROLE_LIST.organization.value &&
                                <span className="agent-span">{ROLE_LIST.organization.label} - </span>
                            }

                            {singleChatDataProps.user.userRole.code === ROLE_LIST.chatbot.value &&
                                <span className="agent-span">{ROLE_LIST.chatbot.label} - </span>
                            }

                            {printLastMessageName(singleChatDataProps, caseData, true)}
                        </b>
                    </p>

                    {singleChatDataProps.replyTo.localID !== "" &&
                        <ChatRoomReplyInMessage 
                            message={singleChatDataProps}
                            activeUserID={activeUserID}
                            scrollToReply={scrollToReply}
                        />
                    }
                    
                    {/* {singleChatDataProps.forwardFrom.localID !== "" &&
                        <div className="forwarded-message">
                            <b>Forwarded</b>
                            <br />
                            From: <b>{singleChatDataProps.forwardFrom.fullname}</b>
                        </div>
                    } */}

                    <div className="location-wrapper">
                        <a href={`http://maps.google.com?q=${singleChatDataProps.data.latitude} ${singleChatDataProps.data.longitude}`} target="_blank" rel="noopener noreferrer">
                            <div className="location-content"
                                //  style={{borderRadius: props.singleChatDataProps.forwardFrom.localID !== "" ? "0" : "16px 2px 0 0"}}
                            >
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_KEY}}
                                    defaultCenter={{
                                        lat: singleChatDataProps.data.latitude,
                                        lng: singleChatDataProps.data.longitude
                                    }}
                                    defaultZoom={16}
                                    draggable={false}
                                >
                                    <MarkerMapComponent
                                        lat={singleChatDataProps.data.latitude}
                                        lng={singleChatDataProps.data.longitude}
                                    />
                                </GoogleMapReact>
                            </div>
                        </a>
                    </div>

                    <span className="message-body" dangerouslySetInnerHTML={ {__html: Helper.lineBreakChatRoom(singleChatDataProps.data.address)} }></span>
                    
                    <p className="message-info">
                        {Helper.getDateMonthYear(singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(singleChatDataProps.created)}
                    </p>
                    
                    {messageActionView(singleChatDataProps)}
                </div>
            </React.Fragment>
        }
    </div>
  );
}


const mapStateToProps = state => ({
    replyMessage: state.replyMessage,
    activeRoom: state.activeRoom
});

const mapDispatchToProps = {
  setReplyMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomMessageInLocation);
