import React from "react";

const TDATableHead = ({ data, select, selected }) => {
  const hasSelected =
    selected && selected.length > 0 && selected.length === data.length;

  const change = () => {
    if (data) {
      const arrs = [...data];
      if (hasSelected) {
        select([]);
      } else {
        select(arrs);
      }
    }
  };

  return (
    <thead>
      <tr className="tcHeader">
        <td width="10%" className="custom-checkbox tcColCheck">
          <input
            type="checkbox"
            id="checkAllAgent"
            onChange={change}
            checked={hasSelected}
            disabled={!data ? "disabled" : false}
          />
          <label htmlFor="checkAllAgent"></label>
        </td>
        <td width="40%" className="tcCol">
          Fullname
        </td>
        <td width="50%" className="tcCol">
          Email
        </td>
      </tr>
    </thead>
  );
};

export default TDATableHead;
