import React, { useEffect } from "react";
import TDTopicForm from "./TDTopicForm";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";

const TDTopic = props => {
  const { detailsData: data } = props;
  const { retriveDataError: error } = props;
  const { isLoadingTopic: loadingTopic } = props;

  const viewForm = data ? <TDTopicForm {...props} /> : <RetrieveDataLoading isLoading={loadingTopic} errorMessage={error.message} />;

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(props.orgID) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
        mixpanel.track(
          "[Page] Topic Details",
          {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.orgID.split("-")[0]
          }
        );
      }
    }
  }, [props.orgID])

  return (
    <div className="topic sectionWrap">
      <div className="topContent bordered">
        <div className="title">Topic Details</div>
      </div>
      <br />
      {viewForm}
    </div>
  );
};

export default TDTopic;
