import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Login from './components/auth/login/Login';
import Register from './components/auth/register/Register';
import EmailVerification from './components/auth/emailVerification/EmailVerification';
import VerifyEmail from './components/auth/verifyEmail/VerifyEmail';
import ForgotPassword from './components/auth/forgotPassword/ForgotPassword';
import ResetPassword from './components/auth/resetPassword/ResetPassword';
import AccountSetting from './components/accountSetting/AccountSetting';
import AccountSettingMyProfile from "./components/accountSetting/accountSettingMyProfile/AccountSettingMyProfile";
import AccountSettingMyProfileEdit from "./components/accountSetting/accountSettingMyProfile/AccountSettingMyProfileEdit/AccountSettingMyProfileEdit";
import OrganizationBilling from './components/organization/organizationBilling/OrganizationBilling';
import Error404 from './components/error/error404/Error404';
import WasLoggedIn from './components/reuseableComponent/privateRoute/WasLoggedIn';
import MustLogin from './components/reuseableComponent/privateRoute/MustLogin';
import MustLoginWithoutNavigation from './components/reuseableComponent/privateRoute/MustLoginWithoutNavigation';
// import GA from "./GoogleAnalytics";
import Home from "./components/home/Home";
import Topics from "./components/topic/Topics";
import TopicsDetails from "./components/topic/TopicsDetails";
// import SecretKey from './components/secretKey/SecretKey';
import AssignRules from "./components/assignmentRules/AssignRules";
import Members from "./components/members/Members";
import MemberDetail from "./components/members/memberDetail/MemberDetail";
import MemberInvite from "./components/members/memberInvite/MemberInvite";
// import Setup from "./components/setup/Setup";
import Faq from "./components/setup/faq/Faq";
import FaqDetail from "./components/setup/faq/faqDetail/FaqDetail";
import InboxSetting from "./components/setup/inboxSetting/InboxSetting";
import OtherTab from './components/setup/inboxSetting/OtherTab/OtherTab';
import NeedReply from './components/setup/inboxSetting/NeedReply/NeedReply';
import AllowReplyOnAgentAway from './components/setup/inboxSetting/AllowReplyOnAgentAway/AllowReplyOnAgentAway';
import AllowHandover from './components/setup/inboxSetting/AllowHandover/AllowHandover';
import ClosingMessage from "./components/setup/closingMessage/ClosingMessage";
import AwayMessage from "./components/setup/awayMessage/AwayMessage";
import WelcomeMessage from "./components/setup/welcomeMessage/WelcomeMessage";
import OfficeHour from "./components/setup/officeHour/OfficeHour";
import OfficeHourNew from "./components/setup/officeHour/officeHourNew/OfficeHourNew";
import OfficeHourEdit from "./components/setup/officeHour/officeHourEdit/OfficeHourEdit";
import UserRatingSetup from "./components/setup/userRatingSetup/UserRatingSetup";
import AssignmentRules from "./components/setup/assignmentRules/AssignmentRules";
import UserRating from './page/userRating/UserRating';
import Billing from "./components/organization/billing/Billing";
import ViewPending from "./components/organization/billing/subscriptions/viewPending/ViewPending";
import PayPending from "./components/organization/billing/subscriptions/payPending/PayPending";
import PayNow from './components/organization/billing/subscriptions/payNow/PayNow';
import PayNowTransfer from './components/organization/billing/subscriptions/payNowTransfer/PayNowTransfer';
import AddCard from "./components/organization/billing/paymentSettings/addCard/AddCard";
import VerifyCard from "./components/organization/billing/paymentSettings/verifyCard/VerifyCard";
import OrganizationDetails from './components/organization/organizationDetails/OrganizationDetails'
import OrganizationDetailsEdit from './components/organization/organizationDetails/OrganizationDetailsEdit/OrganizationDetailsEdit'
import Labels from './components/labels/Labels';
import LabelsAdd from './components/labels/labelAdd/LabelAdd';
import LabelsEdit from './components/labels/labelEdit/LabelEdit';
// import Integrations from "./components/integrations/Integrations";
import IntegrationChannels from './components/integrations/integrationChannels/IntegrationChannels';
import IntegrationLine from './components/integrations/integrationLine/IntegrationLine';
import IntegrationInstagram from './components/integrations/integrationInstagram/IntegrationInstagram';
import IntegrationInstagramAddAccount from './components/integrations/integrationInstagram/integrationInstagramAddAccount/IntegrationInstagramAddAccount';
import IntegrationInstagramEditTopicAccount from './components/integrations/integrationInstagram/integrationInstagramEditTopicAccount/IntegrationInstagramEditTopicAccount';
import IntegrationInstagramDetailAccount from './components/integrations/integrationInstagram/integrationInstagramDetailAccount/IntegrationInstagramDetailAccount';
import IntegrationInstagramEditChatbot from './components/integrations/integrationInstagram/integrationInstagramEditChatbot/IntegrationInstagramEditChatbot';
import IntegrationInstagramAutoMessageSetup from './components/integrations/integrationInstagram/integrationInstagramAutoMessageSetup/IntegrationInstagramAutoMessageSetup';
import IntegrationWhatsapp from './components/integrations/integrationWhatsapp/IntegrationWhatsapp';
import IntegrationWhatsappAddAccount from './components/integrations/integrationWhatsapp/integrationWhatsappAddAccount/IntegrationWhatsappAddAccount';
import IntegrationWhatsappCheckout from './components/integrations/integrationWhatsapp/integrationWhatsappCheckout/IntegrationWhatsappCheckout'
import IntegrationWhatsappVerify from './components/integrations/integrationWhatsapp/integrationWhatsappVerify/IntegrationWhatsappVerify'
import IntegrationWhatsappEditAccount from './components/integrations/integrationWhatsapp/integrationWhatsappEditAccount/IntegrationWhatsappEditAccount';
import IntegrationWhatsappEditChannelButton from './components/integrations/integrationWhatsapp/integrationWhatsappEditChannelButton/IntegrationWhatsappEditChannelButton';
import IntegrationWhatsappDetailAccount from './components/integrations/integrationWhatsapp/integrationWhatsappDetailAccount/IntegrationWhatsapDetailAccount';
import IntegrationWhatsappAutoMessageSetup from './components/integrations/integrationWhatsapp/integrationWhatsappAutoMessageSetup/IntegrationWhatsappAutoMessageSetup';
import IntegrationWhatsappAuthenticateNow from './components/integrations/integrationWhatsapp/integrationWhatsappAuthenticateNow/IntegrationWhatsappAuthenticateNow';
import IntegrationWhatsappSecureAuthenticate from './components/integrations/integrationWhatsapp/integrationWhatsappSecureAuthentication/IntegrationWhatsappSecureAuthenticate';
import IntegrationWhatsappEditChatbot from './components/integrations/integrationWhatsapp/integrationWhatsappEditChatbot/IntegrationWhatsappEditChatbot';
import IntegrationTelegram from './components/integrations/integrationTelegram/IntegrationTelegram';
import IntegrationTelegramAddAccount from './components/integrations/integrationTelegram/integrationTelegramAddAccount/IntegrationTelegramAddAccount';
import IntegrationTelegramDetailAccount from './components/integrations/integrationTelegram/integrationTelegramDetailAccount/IntegrationTelegramDetailAccount';
import IntegrationTelegramAutoMessageSetup from './components/integrations/integrationTelegram/integrationTelegramAutoMessageSetup/IntegrationTelegramAutoMessageSetup';
import IntegrationTelegramEditTopicAccount from './components/integrations/integrationTelegram/integrationTelegramEditTopicAccount/IntegrationTelegramEditTopicAccount';
import IntegrationTelegramEditChannelButton from './components/integrations/integrationTelegram/integrationTelegramEditChannelButton/IntegrationTelegramEditChannelButton';
import IntegrationTelegramEditBotTokenAccount from './components/integrations/integrationTelegram/integrationTelegramEditBotTokenAccount/IntegrationTelegramEditBotTokenAccount';
import IntegrationTelegramEditChatbot from './components/integrations/integrationTelegram/integrationTelegramEditChatbot/IntegrationTelegramEditChatbot';
import IntegrationTwitter from "./components/integrations/integrationTwitter/IntegrationTwitter";
import IntegrationFacebook from "./components/integrations/integrationFacebookMessenger/IntegrationFacebookMessenger";
// import IntegrationFacebookMessengerEditTopicAccount from "./components/integrations/integrationFacebookMessanger/integrationFacebookMessengerEditTopicAccount/IntegrationFacebookMessengerEditTopicAccount";
// import IntegrationFacebookMessengerEditChannelName from "./components/integrations/integrationFacebookMessanger/integrationFacebookMessengerEditChannelName/IntegrationFacebookMessengerEditChannelName";
import IntegrationFacebookMessengerEditChannelButton from "./components/integrations/integrationFacebookMessenger/integrationFacebookMessengerEditChannelButton/IntegrationFacebookMessengerEditChannelButton";
import IntegrationFacebookMessengerDetailAccount from "./components/integrations/integrationFacebookMessenger/integrationFacebookMessengerDetailAccount/IntegrationFacebookMessengerDetailAccount";
import IntegrationFacebookMessengerAutoMessageSetup from "./components/integrations/integrationFacebookMessenger/integrationFacebookMessengerAutoMessageSetup/IntegrationFacebookMessengerAutoMessageSetup";
import IntegrationFacebookMessengerEditTopicAccount from './components/integrations/integrationFacebookMessenger/integrationFacebookMessengerEditTopicAccount/IntegrationFacebookMessengerEditTopicAccount'
import IntegrationFacebookMessengerAddAccount from "./components/integrations/integrationFacebookMessenger/integrationFacebookMessengerAddAccount/IntegrationFacebookMessengerAddAccount";
import IntegrationFacebookMessengerEditChatbot from './components/integrations/integrationFacebookMessenger/integrationFacebookMessengerEditChatbot/IntegrationFacebookMessengerEditChatbot';
import IntegrationCustomizeLauncher from './components/integrations/integrationTaptalk/integrationCustomizeLauncher/IntegrationCustomizeLauncher';
import IntegrationTaptalkAutoMessageSetup from './components/integrations/integrationTaptalk/integrationTaptalkAutoMessageSetup/IntegrationTaptalkAutoMessageSetup';
import IntegrationTaptalkAddAccount from './components/integrations/integrationTaptalk/integrationTaptalkAddAccount/IntegrationTaptalkAddAccount';
import IntegrationTaptalk from './components/integrations/integrationTaptalk/IntegrationTaptalk';
import IntegrationAndroid from './components/integrations/integrationAndroid/IntegrationAndroid';
import IntegrationIOS from './components/integrations/integrationIOS/IntegrationIOS';
import IntegrationWhatsappBusiness from './components/integrations/integrationWhatsappBusiness/IntegrationWhatsappBusiness';
import IntegrationWhatsappBusinessAddAccount from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessAddAccount/IntegrationWhatsappBusinessAddAccount';
import IntegrationWhatsappBusinessDetailAccount from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessDetailAccount/IntegrationWhatsappBusinessDetailAccount';
import IntegrationWhatsappBusinessAutoMessageSetup from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessAutoMessageSetup/IntegrationWhatsappBusinessAutoMessageSetup';
import IntegrationWhatsappBusinessEditChannelButton from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessEditChannelButton/IntegrationWhatsappBusinessEditChannelButton';
import IntegrationWabaChannelDetailEdit from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessDetailAccount/channelDetail/channelDetailEdit/ChannelDetailEdit';
import IntegrationWabaCreateMessageTemplate from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessDetailAccount/requestMessageTemplate/createMessageTemplate/CreateMessageTemplate';
import ViewMessageTemplate from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessDetailAccount/viewMessageTemplate/ViewMessageTemplate';
import ViewRequestMessageTemplate from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessDetailAccount/viewRequestMessageTemplate/ViewRequestMessageTemplate';
import IntegrationWhatsappBusinessEditChatbot from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessEditChatbot/IntegrationWhatsappBusinessEditChatbot';
import IntegrationHome from './components/integrations/integrationHome/IntegrationHome';
import IntegrationApiKeys from './components/integrations/integrationApiKeys/IntegrationApiKeys';
import IntegrationApiKeysAdd from './components/integrations/integrationApiKeys/integrationApiKeysAdd/IntegrationApiKeysAdd';
// import IntegrationApiKeysEdit from './components/integrations/integrationApiKeys/integrationApiKeysEdit/IntegrationApiKeysEdit';
import IntegrationChatbot from './components/integrations/integrationChatBot/IntegrationChatbot';
import IntegrationChatbotAdd from './components/integrations/integrationChatBot/integrationChatbotAdd/IntegrationChatbotAdd';
import IntegrationChatbotEdit from './components/integrations/integrationChatBot/integrationChatbotEdit/IntegrationChatbotEdit';
import IntegrationChatbotDetail from './components/integrations/integrationChatBot/integrationChatbotDetail/IntegrationChatbotDetail';
// import IntegrationWebhook from './components/integrations/integrationWebhook/IntegrationWebhook';
// import IntegrationWebhookSetup from './components/integrations/integrationWebhook/integrationWebhookSetup/IntegrationWebhookSetup';
import IntegrationSalestalk from './components/integrations/integrationSalestalk/IntegrationSalestalk';
import IntegrationSalestalkCustomConfig from './components/integrations/integrationSalestalk/integrationSalestalkCustomConfig/IntegrationSalestalkCustomConfig';
import IntegrationSalestalkCustomConfigEdit from './components/integrations/integrationSalestalk/integrationSalestalkCustomConfigEdit/IntegrationSalestalkCustomConfigEdit';
import IntegrationGoogle from './components/integrations/integrationGoogle/IntegrationGoogle';
import IntegrationGoogleCreate from './components/integrations/integrationGoogle/integrationGoogleCreate/IntegrationGoogleCreate';
import IntegrationGoogleBusinessDetailAccount from './components/integrations/integrationGoogle/integrationGoogleBusinessDetailAccount/IntegrationGoogleBusinessDetailAccount';
import IntegrationGoogleChannelDetailEdit from './components/integrations/integrationGoogle/integrationGoogleBusinessDetailAccount/channelDetail/channelDetailEdit/ChannelDetailEdit';
import IntegrationGoogleEditAccountProfile from './components/integrations/integrationGoogle/integrationGoogleBusinessDetailAccount/accountProfile/editAccountProfile/EditGreetingMessage';
import IntegrationGoogleEditChannelProfile from './components/integrations/integrationGoogle/integrationGoogleBusinessDetailAccount/accountProfile/editAccountProfile/EditChannelProfile';
import IntegrationGoogleEditEntryPointLocal from './components/integrations/integrationGoogle/integrationGoogleBusinessDetailAccount/entryPoint/EditEntryPointLocal/EditEntryPointLocal';
import IntegrationGoogleEditEntryPointNonLocal from './components/integrations/integrationGoogle/integrationGoogleBusinessDetailAccount/entryPoint/EditEntryPointNonLocal/EditEntryPointNonLocal';
import IntegrationGoogleAddLocationEntryPoint from './components/integrations/integrationGoogle/integrationGoogleBusinessDetailAccount/entryPoint/AddLocation/AddLocation';
import IntegrationGoogleBusinessEditChatbot from './components/integrations/integrationGoogle/integrationGoogleBusinessEditChatbot/IntegrationGoogleBusinessEditChatbot';
import IntegrationGoogleBussinessAutoMessageSetup from './components/integrations/integrationGoogle/integrationGoogleBussinessAutoMessageSetup/IntegrationGoogleBussinessAutoMessageSetup';
import IntegrationGoogleProfile from './components/integrations/integrationGoogleProfile/IntegrationGoogleProfile';
import IntegrationGoogleProfileDetailAccount from './components/integrations/integrationGoogleProfile/integrationGoogleProfileDetailAccount/IntegrationGoogleProfileDetailAccount';
import IntegrationGoogleProfileEditDetail from './components/integrations/integrationGoogleProfile/integrationGoogleProfileEditDetail/IntegrationGoogleProfileEditDetail';
import IntegrationGoogleProfileAddAccount from './components/integrations/integrationGoogleProfile/integrationGoogleProfileAddAccount/IntegrationGoogleProfileAddAccount';
import IntegrationTokopedia from './components/integrations/integrationTokopedia/IntegrationTokopedia';
import IntegrationTokopediaAdd from './components/integrations/integrationTokopedia/IntegrationTokopediaAdd/IntegrationTokopediaAdd';
import IntegrationTokopediaDetailAccount from './components/integrations/integrationTokopedia/integrationTokopediaDetail/IntegrationTokopediaDetailAccount';
import IntegrationTokopediaEdit from './components/integrations/integrationTokopedia/IntegrationTokopediaEdit/IntegrationTokopediaEdit';
import IntegrationTokopediaEditChatbot from './components/integrations/integrationTokopedia/integrationTokopediaEditChatbot/IntegrationTokopediaEditChatbot';
import IntegrationTokopediaAutoMessageSetup from './components/integrations/integrationTokopedia/integrationTokopediaAutoMessageSetup/IntegrationTokopediaAutoMessageSetup';
import QuickReply from './components/quickReply/QuickReply';
// import QuickReplyPersonal from './components/quickReply/personal/QuickReply';
import QuickReplyPersonalAdd from './components/quickReply/personal/quickReplyAdd/QuickReplyAdd';
import QuickReplyPersonalEdit from './components/quickReply/personal/quickReplyEdit/QuickReplyEdit';
// import QuickReplyGeneral from './components/quickReply/general/QuickReply';
import QuickReplyGeneralAdd from './components/quickReply/general/quickReplyAdd/QuickReplyAdd';
import QuickReplyGeneralEdit from './components/quickReply/general/quickReplyEdit/QuickReplyEdit';
import SelectTierForm1 from "./components/organization/selectTier/selectTierForm1/SelectTierForm1";
import ReviewTransaction from './components/organization/selectTier/reviewTransaction/ReviewTransaction'
import VerifyCardTransaction from './components/organization/selectTier/verifyCard/VerifyCardTransaction'
import AddPrimaryCard from './components/organization/selectTier/addCard/AddPrimaryCard'
import PayMethods from "./components/organization/selectTier/payMethods/PayMethods";
import BroadcastMessage from './components/broadcastMessage/BroadcastMessage';
import BroadcastMessageDetail from './components/broadcastMessage/broadcastMessageDetail/BroadcastMessageDetail';
import BroadcastMessageDetailEdit from './components/broadcastMessage/broadcastMessageDetailEdit/BroadcastMessageDetailEdit'
import BroadcastMessageForm from './components/broadcastMessage/broadcastMessageForm/BroadcastMessageForm';
import BroadcastMessagePreview from './components/broadcastMessage/broadcastMessagePreview/BroadcastMessagePreview';
import UserContact from './components/userContact/UserContact'
import AddUserContact from './components/userContact/AddUserContact/AddUserContact'
import DetailContact from './components/userContact/DetailContact/DetailContact'
import EditContact from './components/userContact/EditContact/EditContact'
import UserSegment from './components/userSegment/UserSegment'
import DetailSegment from './components/userSegment/DetailSegment/DetailSegment'
import AddContactSegment from './components/userSegment/AddContactSegment/AddContactSegment'
import CaseReport from './components/caseReport/CaseReport'
import ChatView from './page/chatView/ChatView';
import { connect } from 'react-redux';
import { clearActiveRoom, setActiveRoom } from './redux/actions/reduxActionActiveRoom';
import CheckoutSuccess from './components/organization/selectTier/checkoutSuccess/CheckoutSuccess';
import EditAccountProfile from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessDetailAccount/accountProfile/editAccountProfile/EditAccountProfile';
import EditBusinessProfile from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessDetailAccount/businessProfile/editBusinessProfile/EditBusinessProfile';
// import EditPhotoProfile from './components/integrations/integrationWhatsappBusiness/integrationWhatsappBusinessDetailAccount/accountProfile/editAccountProfile/EditPhotoProfile';
// import TopUp from './components/topUp/TopUp';
import CaseRatings from './components/report/caseRatings/CaseRatings';
import CaseReporting from './components/report/caseReporting/CaseReporting';
import AgentReport from './components/report/agentReport/AgentReport';
import TopicReport from './components/report/topicReport/TopicReport';
import MessageApiHistory from './components/report/messageApiHistory/MessageApiHistory';
import OverviewReport from './components/report/overview/OverviewReport';
import ForgotPasswordEmailSent from './components/auth/forgotPasswordEmailSent/ForgotPasswordEmailSent';

const Routes = (props) => {
  props.clearActiveRoom();

  return (
    <BrowserRouter>
      {/* {GA.init() && <GA.RouteTracker />} */}
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/login" />} />
        <Route path="/case_rating" component={UserRating} />

        <WasLoggedIn path="/login" component={Login} />
        <WasLoggedIn path="/register" component={Register} />
        <WasLoggedIn path="/verify/register-account" component={EmailVerification} />
        <WasLoggedIn path="/verify/forgot-password" component={ForgotPasswordEmailSent} />
        <WasLoggedIn path="/verify/email" component={VerifyEmail} />
        <WasLoggedIn path="/forgot-password" component={ForgotPassword} />
        <WasLoggedIn path="/reset-password" component={ResetPassword} />

        <MustLogin path="/o/:orgID/account/profile/edit" component={AccountSettingMyProfileEdit} withoutTier />
        <MustLogin path="/o/:orgID/account/profile" component={AccountSettingMyProfile} withoutTier />
        <MustLogin path="/profile" component={AccountSettingMyProfile} withoutTier />
        <MustLogin path="/o/:orgID/account/security" component={AccountSetting} withoutTier />
        <MustLogin path="/security" component={AccountSetting} withoutTier />

        {/* <MustLoginWithoutNavigation path="/organization/new" component={OrganizationBilling} /> */}
        <MustLoginWithoutNavigation path="/o/:orgID/select-tier/pay-methods/review-transaction/success" component={CheckoutSuccess} />
        <MustLoginWithoutNavigation path="/o/:orgID/select-tier/pay-methods/review-transaction/verify" component={VerifyCardTransaction} />
        <MustLoginWithoutNavigation path="/o/:orgID/select-tier/pay-methods/review-transaction" component={ReviewTransaction} />
        <MustLoginWithoutNavigation path="/o/:orgID/select-tier/pay-methods/add-card" component={AddPrimaryCard} />
        <MustLoginWithoutNavigation path="/o/:orgID/select-tier/pay-methods" component={PayMethods} />
        <MustLoginWithoutNavigation path="/o/:orgID/select-tier" component={SelectTierForm1} />
        <MustLogin path="/o/:orgID/account/security" component={AccountSetting} />
        <MustLogin path="/security" component={AccountSetting} />

        <MustLoginWithoutNavigation path="/organization/new" component={OrganizationBilling} />

        <MustLogin path="/assign-rules/:orgID" component={AssignRules} restrictedForAgent />
        <MustLogin path="/assign-rules" component={AssignRules} restrictedForAgent />

        {/* <MustLogin path="/o/:orgID/technical/secret-keys/add" component={SecretKey} restrictedForAgent />
        <MustLogin path="/o/:orgID/technical/secret-keys" component={SecretKey} restrictedForAgent /> 
        <MustLogin path="/secret-keys" component={SecretKey} restrictedForAgent /> */}

        <MustLogin path="/o/:orgID/organization/topics/details/:topicID" component={TopicsDetails} restrictedForAgent />
        <MustLogin path="/o/:orgID/organization/topics/details" component={TopicsDetails} restrictedForAgent />
        <MustLogin path="/o/:orgID/organization/topics" component={Topics} restrictedForAgent />
        <MustLogin path="/topics" component={Topics} restrictedForAgent />

        <MustLogin path="/o/:orgID/organization/members/invite" component={MemberInvite} restrictedForAgent />
        <MustLogin path="/o/:orgID/organization/members/detail/:memberID" component={MemberDetail} restrictedForAgent />
        <MustLogin path="/o/:orgID/organization/members" component={Members} restrictedForAgent />
        <MustLogin path="/organization/members" component={Members} />
        
        <MustLogin path="/o/:orgID/organization/billing/verify-card" component={VerifyCard} restrictedForAgent withoutTier />
        <MustLogin path="/o/:orgID/organization/billing/add-card" component={AddCard} restrictedForAgent withoutTier />
        <MustLogin path="/o/:orgID/organization/billing/unpaid-invoices-detail/:id" component={PayPending} restrictedForAgent withoutTier />
        <MustLogin path="/o/:orgID/organization/billing/unpaid-invoices-detail" component={PayPending} restrictedForAgent withoutTier />
        <MustLogin path="/o/:orgID/organization/billing/unpaid-invoices" component={ViewPending} restrictedForAgent withoutTier />
        <MustLogin path="/o/:orgID/organization/billing/pay-now/transfer" component={PayNowTransfer} restrictedForAgent withoutTier />
        <MustLogin path="/o/:orgID/organization/billing/pay-now" component={PayNow} restrictedForAgent withoutTier />
        <MustLogin path="/o/:orgID/organization/billing" component={Billing} restrictedForAgent withoutTier />
        <MustLogin path="/organization/billing" component={Billing} restrictedForAgent withoutTier />
        
        <MustLogin path="/o/:orgID/organization/details/edit" component={OrganizationDetailsEdit} restrictedForAgent withoutTier />
        <MustLogin path="/o/:orgID/organization/details" component={OrganizationDetails} restrictedForAgent withoutTier />
        <MustLogin path="/organization/details" component={OrganizationDetails} withoutTier />

        {/* <MustLogin path="/o/:orgID/organization/top-up" component={TopUp} restrictedForAgent withoutTier /> */}

        <MustLogin path="/o/:orgID/setup/quick-reply/general/edit/:quickReplyID" component={QuickReplyGeneralEdit} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/quick-reply/general/add" component={QuickReplyGeneralAdd} restrictedForAgent />
        {/* <MustLogin path="/o/:orgID/setup/quick-reply/general" component={QuickReplyGeneral} restrictedForAgent /> */}
        {/* <MustLogin path="/quick-reply/general" component={QuickReplyGeneral} restrictedForAgent /> */}

        <MustLogin path="/o/:orgID/setup/quick-reply/personal/edit/:quickReplyID" component={QuickReplyPersonalEdit} />
        <MustLogin path="/o/:orgID/setup/quick-reply/personal/add" component={QuickReplyPersonalAdd} />
        {/* <MustLogin path="/o/:orgID/setup/quick-reply/personal" component={QuickReplyPersonal} /> */}
        {/* <MustLogin path="/quick-reply/personal" component={QuickReplyPersonal} /> */}
        <MustLogin path="/o/:orgID/setup/quick-reply" component={QuickReply} />
        <MustLogin path="/o/:orgID/setup/office-hour/edit" component={OfficeHourEdit} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/office-hour/add" component={OfficeHourNew} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/office-hour" component={OfficeHour} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/user-rating" component={UserRatingSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/office-message" component={OfficeHour} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/welcome-message" component={WelcomeMessage} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/away-message" component={AwayMessage} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/closing-message" component={ClosingMessage} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/inbox-settings" component={InboxSetting} restrictedForAgent exact />
        <MustLogin path="/o/:orgID/setup/inbox-settings/other-tab" component={OtherTab} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/inbox-settings/need-reply" component={NeedReply} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/inbox-settings/allow-reply-on-away" component={AllowReplyOnAgentAway} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/inbox-settings/allow-handover" component={AllowHandover} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/assignment-rules" component={AssignmentRules} restrictedForAgent />

        <MustLogin path="/o/:orgID/setup/qna/detail/:pathID/:itemID" component={FaqDetail} restrictedForAgent />
        <MustLogin path="/o/:orgID/setup/qna" component={Faq} restrictedForAgent />
        {/* <MustLogin path="/o/:orgID/setup" component={Setup} restrictedForAgent /> */}
        {/* <MustLogin path="/setup" component={Setup} restrictedForAgent /> */}

        <MustLogin path="/o/:orgID/report/case-ratings" component={CaseRatings} restrictedForAgent />
        <MustLogin path="/o/:orgID/report/case-report" component={CaseReporting} restrictedForAgent />
        <MustLogin path="/o/:orgID/report/agent-report" component={AgentReport} restrictedForAgent />
        <MustLogin path="/o/:orgID/report/topic-report" component={TopicReport} restrictedForAgent exact />
        <MustLogin path="/o/:orgID/report/message-api-history" component={MessageApiHistory} restrictedForAgent />
        <MustLogin path="/o/:orgID/report/overview" component={OverviewReport} restrictedForAgent />
        {/* <MustLogin path="/o/:orgID/report/case-ratings" component={CaseRatings} restrictedForAgent /> */}

        <MustLogin path="/o/:orgID/organization/labels/edit/:id" component={LabelsEdit} restrictedForAgent />
        <MustLogin path="/o/:orgID/organization/labels/add" component={LabelsAdd} restrictedForAgent />
        <MustLogin path="/o/:orgID/organization/labels" component={Labels} restrictedForAgent />
        <MustLogin path="/organization/labels" component={Labels} restrictedForAgent />

        {/* Channels */}
        <MustLogin path="/o/:orgID/integration/channels" component={IntegrationChannels} restrictedForAgent />

        {/* Taptalk Launcher */}
        <MustLogin path="/o/:orgID/integration/launcher/auto-message/setup/:accountID" component={IntegrationTaptalkAutoMessageSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/launcher/detail/:accountID" component={IntegrationCustomizeLauncher} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/launcher/add" component={IntegrationTaptalkAddAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/launcher" component={IntegrationTaptalk} restrictedForAgent />

        {/* Telegram */}
        <MustLogin path="/o/:orgID/integration/telegram/edit-chatbot/:accountID" component={IntegrationTelegramEditChatbot} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/telegram/edit-token/:accountID" component={IntegrationTelegramEditBotTokenAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/telegram/edit-topic/:accountID" component={IntegrationTelegramEditTopicAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/telegram/edit-channel-button/:accountID" component={IntegrationTelegramEditChannelButton} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/telegram/auto-message/setup/:accountID" component={IntegrationTelegramAutoMessageSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/telegram/detail/:accountID" component={IntegrationTelegramDetailAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/telegram/add" component={IntegrationTelegramAddAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/telegram" component={IntegrationTelegram} restrictedForAgent />

        {/* whatsapp */}
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/edit-chatbot/:accountID" component={IntegrationWhatsappEditChatbot} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/secure-authenticate/:accountID" component={IntegrationWhatsappSecureAuthenticate} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/authenticate-now/:accountID" component={IntegrationWhatsappAuthenticateNow} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/edit/:accountID" component={IntegrationWhatsappEditAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/edit-channel-button/:accountID" component={IntegrationWhatsappEditChannelButton} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/detail/:accountID" component={IntegrationWhatsappDetailAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/auto-message/setup/:accountID" component={IntegrationWhatsappAutoMessageSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/verify" component={IntegrationWhatsappVerify} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/checkout" component={IntegrationWhatsappCheckout} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme/add" component={IntegrationWhatsappAddAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-sme" component={IntegrationWhatsapp} restrictedForAgent />
        {/* whatsapp */}

        {/* instagram */}
        <MustLogin path="/o/:orgID/integration/instagram/edit-chatbot/:accountID" component={IntegrationInstagramEditChatbot} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/instagram/edit-topic/:accountID" component={IntegrationInstagramEditTopicAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/instagram/auto-message/setup/:accountID" component={IntegrationInstagramAutoMessageSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/instagram/detail/:accountID" component={IntegrationInstagramDetailAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/instagram/add" component={IntegrationInstagramAddAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/instagram" component={IntegrationInstagram} restrictedForAgent />
        {/* instagram */}

        {/* waba */}
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/edit-chatbot/:accountID" component={IntegrationWhatsappBusinessEditChatbot} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/detail/:id/request-message-template/:templateID" component={ViewRequestMessageTemplate} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/detail/:id/:phoneID/edit-topic" component={IntegrationWabaChannelDetailEdit} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/detail/:id/create-message-template" component={IntegrationWabaCreateMessageTemplate} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/detail/:id/:phoneID/edit-business-profile" component={EditBusinessProfile} restrictedForAgent />
        {/* <MustLogin path="/o/:orgID/integration/whatsapp-business-api/detail/:id/edit-account-profile/edit-photo" component={EditPhotoProfile} restrictedForAgent /> */}
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/detail/:id/:phoneID/edit-account-profile" component={EditAccountProfile} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/detail/:id/message-template/:templateID" component={ViewMessageTemplate} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/detail/:id" component={IntegrationWhatsappBusinessDetailAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/auto-message/setup/:id" component={IntegrationWhatsappBusinessAutoMessageSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/edit-channel-link/:id/:phoneID" component={IntegrationWhatsappBusinessEditChannelButton} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api/add" component={IntegrationWhatsappBusinessAddAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/whatsapp-business-api" component={IntegrationWhatsappBusiness} restrictedForAgent />
        {/* waba */}

        {/*Api keys*/}
        {/* <MustLogin path="/o/:orgID/integration/api-keys/edit/:id" component={IntegrationApiKeysEdit} restrictedForAgent /> */}
        <MustLogin path="/o/:orgID/integration/api-keys/create" component={IntegrationApiKeysAdd} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/api-keys" component={IntegrationApiKeys} restrictedForAgent />
        {/*Api keys*/}

        {/*Salestalk*/}
        <MustLogin path="/o/:orgID/integration/salestalk/custom-provider/edit" component={IntegrationSalestalkCustomConfigEdit} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/salestalk/custom-provider" component={IntegrationSalestalkCustomConfig} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/salestalk" component={IntegrationSalestalk} restrictedForAgent />
        {/*Salestalk*/}

        {/*Chatbot*/}
        {/* <MustLogin path="/o/:orgID/integration/api-keys/edit/:id" component={IntegrationApiKeysEdit} restrictedForAgent /> */}
        <MustLogin path="/o/:orgID/integration/chatbots/detail/:id" component={IntegrationChatbotDetail} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/chatbots/create" component={IntegrationChatbotAdd} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/chatbots/:type/:id" component={IntegrationChatbotEdit} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/chatbots" component={IntegrationChatbot} restrictedForAgent />
        {/*Chatbot*/}

        {/* Webhook */}
        {/* <MustLogin path="/o/:orgID/integration/webhook/setup" component={IntegrationWebhookSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/webhook" component={IntegrationWebhook} restrictedForAgent /> */}
        {/* Webhook */}
        {/*google*/}
        {/* <MustLogin path="/o/:orgID/integration/api-keys/edit/:id" component={IntegrationApiKeysEdit} restrictedForAgent /> */}
        {/* <MustLogin path="/o/:orgID/integration/google-business-messages/detail/:id/launch" component={IntegrationGoogleEditEntryPoint} restrictedForAgent /> */}
        <MustLogin path="/o/:orgID/integration/google-business-messages/auto-message/setup/:accountID" component={IntegrationGoogleBussinessAutoMessageSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages/edit-chatbot/:accountID" component={IntegrationGoogleBusinessEditChatbot} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages/detail/:id/add-location" component={IntegrationGoogleAddLocationEntryPoint} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages/detail/:id/edit-entry-point-local" component={IntegrationGoogleEditEntryPointLocal} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages/detail/:id/edit-entry-point-non-local" component={IntegrationGoogleEditEntryPointNonLocal} restrictedForAgent />        
        <MustLogin path="/o/:orgID/integration/google-business-messages/detail/:id/edit-channel-profile" component={IntegrationGoogleEditChannelProfile} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages/detail/:id/edit-greeting-message" component={IntegrationGoogleEditAccountProfile} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages/detail/:id/edit-topic" component={IntegrationGoogleChannelDetailEdit} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages/detail/:id" component={IntegrationGoogleBusinessDetailAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages/add" component={IntegrationGoogleCreate} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-messages" component={IntegrationGoogle} restrictedForAgent />
        {/*google*/}

        {/* google profile */}
        <MustLogin path="/o/:orgID/integration/google-business-profile/detail/:id/edit-detail" component={IntegrationGoogleProfileEditDetail} restrictedForAgent />        
        <MustLogin path="/o/:orgID/integration/google-business-profile/detail/:id" component={IntegrationGoogleProfileDetailAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/google-business-profile/add" component={IntegrationGoogleProfileAddAccount} restrictedForAgent />       
        <MustLogin path="/o/:orgID/integration/google-business-profile" component={IntegrationGoogleProfile} restrictedForAgent />
        {/*google profile*/}

        <MustLogin path="/o/:orgID/integration/twitter" component={IntegrationTwitter} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/line" component={IntegrationLine} restrictedForAgent />

        {/* Messenger */}
        <MustLogin path="/o/:orgID/integration/messenger/edit-chatbot/:accountID" component={IntegrationFacebookMessengerEditChatbot} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/messenger/edit-topic/:accountID" component={IntegrationFacebookMessengerEditTopicAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/messenger/detail/:accountID" component={IntegrationFacebookMessengerDetailAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/messenger/auto-message/setup/:accountID" component={IntegrationFacebookMessengerAutoMessageSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/messenger/edit-channel-button/:accountID" component={IntegrationFacebookMessengerEditChannelButton} restrictedForAgent />
        {/* <MustLogin path="/o/:orgID/integration/messenger/edit-channel-name/:accountID" component={IntegrationFacebookMessengerEditChannelName} restrictedForAgent /> */}
        {/* <MustLogin path="/o/:orgID/integration/messenger/edit-topic/:accountID" component={IntegrationFacebookMessengerEditTopicAccount} restrictedForAgent /> */}
        <MustLogin path="/o/:orgID/integration/messenger/add" component={IntegrationFacebookMessengerAddAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/messenger" component={IntegrationFacebook} restrictedForAgent />
        {/* Messenger */}

        {/* tokopedia */}
        <MustLogin path="/o/:orgID/integration/tokopedia/auto-message/setup/:accountID" component={IntegrationTokopediaAutoMessageSetup} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/tokopedia/edit-chatbot/:accountID" component={IntegrationTokopediaEditChatbot} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/tokopedia/edit-topic/:accountID" component={IntegrationTokopediaEdit} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/tokopedia/detail/:accountID" component={IntegrationTokopediaDetailAccount} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/tokopedia/add" component={IntegrationTokopediaAdd} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/tokopedia" component={IntegrationTokopedia} restrictedForAgent />
        {/* tokopedia */}

        <MustLogin path="/o/:orgID/integration/ios" component={IntegrationIOS} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration/android" component={IntegrationAndroid} restrictedForAgent />
        <MustLogin path="/o/:orgID/integration" component={IntegrationHome} restrictedForAgent />
        <MustLogin path="/integration" component={IntegrationHome} restrictedForAgent />

        {/* User Contact */}
        <MustLogin path="/o/:orgID/operational/user-contact/add" component={AddUserContact} />
        <MustLogin path="/o/:orgID/operational/user-contact/:id/edit" component={EditContact} />
        <MustLogin path="/o/:orgID/operational/user-contact/:id" component={DetailContact} />
        <MustLogin path="/o/:orgID/operational/user-contact" component={UserContact} />

        {/* User Segment */}
        <MustLogin path="/o/:orgID/operational/user-segment/:id/add-contact" component={AddContactSegment} />
        <MustLogin path="/o/:orgID/operational/user-segment/:id" component={DetailSegment} />
        <MustLogin path="/o/:orgID/operational/user-segment" component={UserSegment} />

        <MustLoginWithoutNavigation path="/o/:orgID/custom/report/case_by_created_month" component={CaseReport} />

        <MustLogin path="/o/:orgID/operational/broadcast-message/edit/:id" component={BroadcastMessageDetailEdit} />
        <MustLogin path="/o/:orgID/operational/broadcast-message/detail/:id" component={BroadcastMessageDetail} />
        <MustLogin path="/o/:orgID/operational/broadcast-message/preview" component={BroadcastMessagePreview} />
        <MustLogin path="/o/:orgID/operational/broadcast-message/create" component={BroadcastMessageForm} />
        <MustLogin path="/o/:orgID/operational/broadcast-message" component={BroadcastMessage} />
        <MustLogin path="/broadcast-message" component={BroadcastMessage} />

        <MustLogin path="/o/:orgID/home" component={Home} />
        {/* <MustLogin path="/o/:orgID/home" component={(props) => <Home {...props} teProps={testProps} />} /> */}
        <MustLogin path="/home" component={Home} />

        <MustLoginWithoutNavigation
          path="/o/:orgID/inbox"
          component={ChatView}
          withoutTier
        />

        <Route component={Error404} />
      </Switch>
    </BrowserRouter>
  );
};

const mapDispatchToProps = {
  clearActiveRoom,
  setActiveRoom,
};

export default connect(null, mapDispatchToProps)(Routes);
