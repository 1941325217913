import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./IntegrationInstagram.scss";
import { connect } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { FiArrowUpRight, FiPlus } from "react-icons/fi";
// import IconAdd from "../../../assets/img/icon-add.svg";
import Instagram32 from "../../../assets/img/integration/logo-instagram-32.svg";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import { checkID } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import IntegrationInstagramServices from "../../../services/integration/IntegrationInstagramServices";
// import Integrations from "../Integrations";
import mixpanel from "mixpanel-browser";

const IntegrationInstagram = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingInstagramAccount, setIsLoadingInstagramAccount] = useState(true);
    let [instagramAccountData, setInstagramAccountData] = useState([]);
    let [showPopupWarning, setShowPopupWarning] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });

    let toggleShowWarning = () => {
        setShowPopupWarning(!showPopupWarning);
    }

    let getInstagramAccount = (orgID) => {
        let _retriveDataError = {...retriveDataError};
        setIsLoadingInstagramAccount(true);

        IntegrationInstagramServices.getList(orgID, (response) => {
            let dataResult = response.dataResult;

            if(response.dataResult.status === 200) {
                setInstagramAccountData(response.dataResult.data.channels);
            }else {
                setInstagramAccountData([]);
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsLoadingInstagramAccount(false);
        })
    }

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
            getInstagramAccount(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(orgID) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Page] WhatsApp SME Integration",
                    {
                        userFullName : myAgentData.account.fullName,
                        userID : myAgentData.account.id,
                        organizationID : orgID.split("-")[0]
                    }
                );
            }
        }
    }, [orgID])

    let goToAdd = () => {
        // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        // if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        //     mixpanel.track(
        //         "[Button] Add Channel",
        //         {
        //             channel: "WhatsApp SME",
        //             userFullName : myAgentData.account.fullName,
        //             userID : myAgentData.account.id,
        //             organizationID : orgID.split("-")[0]
        //         }
        //     );
        // }

        if(!props.mediumData) {
            toggleShowWarning();
        }else {
            if(props.mediumData.numberOfItems.maximum !== -1 && props.mediumData.numberOfItems.current >= props.mediumData.numberOfItems.maximum) {
                toggleShowWarning();
            }else {
                history.push(`/o/${orgID}/integration/instagram/add`);
            }
        }
    };

    // let goToDetail = (account) => history.push(`/o/${orgID}/integration/instagram/detail/${account.id}`);

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <PopupWarning
                    isShow={showPopupWarning}
                    toggle={toggleShowWarning}
                    text="Maximum number of allowed channels has been reached"
                />
                {/* <Integrations activeIntegrationChannel="instagram" parentProps={props} orgIDProps={orgID} /> */}
                {/* tier blocking */}
                {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                    isLoading={isLoadingInstagramAccount}
                    errorMessage={retriveDataError.message}
                    errorCode={retriveDataError.code}
                    />
                }
                {/* tier blocking */}
                <div className="sectionWrap integration-main-container integration-instagram-list text-left">
                    <React.Fragment>
                        <div className="integration-list-header">
                            <img src={Instagram32} alt="" className="integration-list-header-icon" />

                            <div className="integration-list-header-text-wrapper">
                                <b>Instagram DM</b>
                                <p>
                                    To integrate with Instagram DM, <a rel="noopener noreferrer" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-channel-integration/instagram-dm-integration" target="_blank">please read the documentation.</a>
                                </p>
                            </div>

                            {!isLoadingInstagramAccount &&
                                <ButtonWithLoadingOrIcon 
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    className="no-fill-button integration-list-header-button main-button-40"
                                    text="Add Channel"
                                    position="left"
                                    onClickAction={goToAdd}
                                />
                            }
                        </div>
                        
                        {isLoadingInstagramAccount || retriveDataError.code ?
                            <RetrieveDataLoading
                                isLoading={isLoadingInstagramAccount}
                                errorMessage={retriveDataError.message}
                            />
                            :
                            instagramAccountData.length > 0 ?
                                <>
                                    <div className="integration-list-outer-wrapper">
                                        {instagramAccountData.map((value, index) => {
                                            return (
                                                <Link key={`instagram-account-${index}`}
                                                    to={`/o/${orgID}/integration/instagram/detail/${value.id}`}
                                                    
                                                    className="link-instagram-account-list"
                                                >
                                                    <div className="integration-list-inner-wrapper">
                                                        <FiArrowUpRight />
                                                        <p>
                                                            <b>@{value.igUsername}</b>
                                                        </p>

                                                        <span>{value.stringID}</span>

                                                        <p className="font-root-medium">
                                                            {value.isConnected ? 'Active' : 'Inactive'}
                                                        </p>

                                                        {/* <p className={`font-root-medium ${value.isChannelLinkEnabled ? "font-green" : "font-grey"}`}>
                                                            Channel Button for Live Chat is {!value.isChannelLinkEnabled ? "disabled" : "enabled"}
                                                        </p> */}
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <div className="integration-list-no-data">
                                    <b>No Channel</b>
                                    <p>
                                        To add a channel, simply click the "+ Add Channel" button on the top right.
                                    </p>
                                </div>
                        }
                    </React.Fragment>
                </div>
            </SectionWrap>
        </div>
    )
}

const mapStateToProps = state => ({
    mediumData: state.mediumData
});

export default connect(mapStateToProps, null)(IntegrationInstagram);
