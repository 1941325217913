import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom"; 
import { NavLink } from "react-router-dom";
import "./BroadcastMessageScheduled.scss";
import "react-toastify/dist/ReactToastify.css";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
// import ColoredInfoBox from "../../../reuseableComponent/coloredInfoBox/ColoredInfoBox";
import Pagination from "../../../reuseableComponent/pagination/Pagination";
import NewButton from "../../../reuseableComponent/NewButton/NewButton";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import HelperDate from "../../../../helper/HelperDate";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import BroadcastMessageServices from "../../../../services/newServices/BroadcastMessageServices";
import ClockService from "../../../../services/chat/clockServices";
import { FiPlus, FiEye, FiInfo } from "react-icons/fi";
// import { FiX } from "react-icons/fi";
import { Modal, ModalBody } from 'reactstrap';
import iconImage from "../../../../assets/img/broadcast-message/icon_image.svg";
import iconFile from "../../../../assets/img/broadcast-message/icon_file.svg";
import iconText from "../../../../assets/img/broadcast-message/icon_text.svg";
import iconWhatsappSME from "../../../../assets/img/integration/logo-whatsapp-side-menu.svg";
import iconWhatsappBA from "../../../../assets/img/integration/logo-whatsapp-business.svg";
// import IconMessage from "../../../../assets/img/sidemenu-icon/icon-messages-black.svg";
// import IconComment from "../../../../assets/img/message-bubbles/comment.svg";
import mixpanel from "mixpanel-browser";
import { TAPLIVE_MEDIUM_LOGO } from "../../../../constants/taplive";

const MESSAGE_TYPE = {
  text: "text",
  image: "image",
  file: "file",
  video: "video"
}

const CHANNEL_LIST = [
  // WhatsApp business API
  {
    img: iconWhatsappSME,
    channel: "whatsappSME",
    value: "whatsapp",
    text: "WhatsApp SME"
  },
  {
    img: iconWhatsappBA,
    channel: "whatsappBA",
    value: "whatsappba",
    text: "WhatsApp BA"
  }
]

const NEW_MESSAGE_OPTION_LIST = [
    {
        image: iconText,
        title: "Text",
        desc: "Send messages to people as plain text",
        type: MESSAGE_TYPE.text
    },
    {
        image: iconImage,
        title: "Image",
        desc: "Send an image along with a caption to people",
        type: MESSAGE_TYPE.image
    },
    {
        image: iconFile,
        title: "File",
        desc: "Send a file to people",
        type: MESSAGE_TYPE.file
    },
];

const CONTACT_TYPE = [
  {
      value: "contact",
      title: "Contact"
  },
  {
      value: "segment",
      title: "Contact Segment"
  }
];

const DEFAULT_DATA = {
  // selectedChannel: {
  //   img: "",
  //   channel: "",
  //   text: ""
  // },
  selectedChannel: {
    img: "",
    channel: "",
    text: ""
  },
  name: "",
  channel: {
    label: "",
    value: ""
  },
  contactType: CONTACT_TYPE[0].value,
  contacts: [],
  customContacts: {},
  segment: "",
  messages: [
    {
      type: "",
      body: "",
      filename: "",
      caption: ""
    }
  ],
  // fileAsset: {
  //   fileURL: "",
  //   fileName: "",
  //   fileData: false,
  //   caption: ""
  // },
  // caption: "",
  // messageType: ""
  messageTemplate: {
    value: "",
    label: "",
    channel: ""
  },
  language: {
    value: "",
    label: "",
    language: ""
  },
  withCase: false,
  scheduleDateSelect: undefined,
  scheduleDate: undefined,
  scheduleTime: "",
  opics: [],
  topic: {
    value: "",
    label: ""
  },
  phoneVal: {
    value: "",
    label: ""
  }
};

const BroadcastMessageScheduled = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [broadcastMessageList, setBroadcastMessageList] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [showModalNewMessage, setShowModalNewMessage] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [selectChannelNumberList, setSelectChannelNumberList] = useState(false);
  let [channelNumberList, setChannelNumberList] = useState([]);
  let [totalItems, setTotalItems] = useState(0);
  let [broadcastMessageData, setBroadcastMessageData] = useState({...DEFAULT_DATA});
  let [activeSelectMessageType, setActiveSelectMessageType] = useState(false);
  const [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  const [pageDisplay, setPageDisplay] = useState(1)
  const [listPage, setListPage] = useState(20)
  const [topicList, setTopicList] = useState(false)
  let [errorMessageData, setErrorMessageData] = useState({
    type: "",
    account: ""
  })

  const listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  const onSelectListPage = (val) => {
    const data = {
      pageNumber: 1,
      pageSize: val.value
    }
    fetchBroadcastMessageList(data);
    setListPageVal(val)
    setListPage(val.value)
    setPageDisplay(1)
  }

  const handleChangePage = (page) => {
    const data = {
      pageNumber: page,
      pageSize: listPage
    }
    fetchBroadcastMessageList(data);
    setPageDisplay(page)
  }

  const goToCreate = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(broadcastMessageData.selectedChannel.channel === "" ) {
      _errorMessageData.type = "Please select channel";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-channel" }
    }

    if(broadcastMessageData.selectedChannel.channel !== "" && broadcastMessageData.channel.value === "") {
        _errorMessageData.account = "Please select account";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-account" }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
        mixpanel.track(
          "[Button] New Broadcast Message",
          {
            type: broadcastMessageData.messages[0].type,
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : orgID.split("-")[0],
            channel : broadcastMessageData.selectedChannel.text
          }
        );
      }
      
      history.push({
        pathname: `/o/${orgID}/operational/broadcast-message/create`,
        state: {
          tab: props.tab,
          broadcastMessageData: broadcastMessageData,
          topicListAgent: topicList,
          isNew: true
        }
      })
    }
  }
  
  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational/broadcast-message`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  let getAgentDetailAndStatusAction = () => {
    let orgID = props.match.params.orgID;
  
    ClockService.postGetAgentDetail(orgID, (response) => {
      let dataResult = response.dataResult;
      let topicsHashMap = {};
  
      if (dataResult.error.code === "") {
        for (let i in dataResult.data.topics) {
          dataResult.data.topics[i].value = dataResult.data.topics[i].id;
          dataResult.data.topics[i].label = dataResult.data.topics[i].name;
  
          topicsHashMap[dataResult.data.topics[i].id] = dataResult.data.topics[i];
        }
  
        setTopicList(topicsHashMap);
      } else {
        doToast(dataResult.error.message, 'fail');
      }
    })
  }

  let fetchBroadcastMessageList = (data) => {
    let _retriveDataError = {...retriveDataError};
    setWaitForResponseGetList(true);

    BroadcastMessageServices.getBroadcastScheduled(orgID, data, (response) => {
      let dataResult = response.dataResult;
      
      if(dataResult.error.message === "") {
        setBroadcastMessageList(dataResult.data.broadcasts);
        setTotalItems(dataResult.data.totalItems);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  let getChannelListWhatsapp = () => {
    BroadcastMessageServices.getAllBroadcastChannelList(orgID, (response) => {
      let dataResult = response.dataResult;
      
      if(dataResult.status === 200) {
        let channels = {...dataResult.data};
    
        // if(channels.length > 0) {
          Object.keys(channels).map((_value, index) => {
            for(let i in channels[_value]) {
              channels[_value][i].value = channels[_value][i].id;
              channels[_value][i].label = channels[_value][i].accountName;

              if(!topicList[channels[_value][i].topicID] && channels[_value][i].topicID !== 0) {
                channels[_value].splice(i)
              }
            }

            return null;
          })

          setChannelNumberList(channels);
        // }
        
      }else {
        doToast(dataResult.error.message, "fail");
      }
    })
  }

  let onSelectChannel = (val) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.account = "";
    setErrorMessageData(_errorMessageData);
    let _broadcastMessageData = {...broadcastMessageData};
    _broadcastMessageData.channel = val;
    setBroadcastMessageData(_broadcastMessageData);
  }

  useEffect(() => {
    if(broadcastMessageData.selectedChannel !== "") {
      let _broadCastMessageData = {...broadcastMessageData};
      _broadCastMessageData.messages[0].type = _broadCastMessageData.selectedChannel.value === "whatsappba" ? "text" : "";
      _broadCastMessageData.channel = {
        value: "",
        label: ""
      }
      setSelectChannelNumberList(channelNumberList[_broadCastMessageData.selectedChannel.value]);
      setBroadcastMessageData(_broadCastMessageData);
    }
  }, [broadcastMessageData.selectedChannel])

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    let data = {
      pageNumber: pageDisplay,
      pageSize: listPage
    }
    if(orgID) {
      getAgentDetailAndStatusAction();
      // getChannelListWhatsapp();
      fetchBroadcastMessageList(data);

      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
        mixpanel.track(
          "[Page] Broadcast Message",
          {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : orgID.split("-")[0]
          }
        );
      }
    }
  }, [orgID]);

  useEffect(() => {
    if(topicList) {
      getChannelListWhatsapp();
    }
  }, [topicList])

  useEffect(() => {
    doMount();
    closeModalNewMessage();
  }, [location.pathname]);

  useEffect(() => {
    if(props.history.location.state) {
      if (props.history.location.state.pageNumber) {
        setPageDisplay(props.history.location.state.pageNumber);
      }
      if (props.history.location.state.pageSize) {
        setListPage(props.history.location.state.pageSize);
        setListPageVal({
          label: String(props.history.location.state.pageSize),
          value: props.history.location.state.pageSize
        })
      }

      // if(props.history.location.state.message) {
      //   doToast(props.history.location.state.message);
      // }
      window.history.pushState(null, '');
    }
  }, [props.history])

  let closeModalNewMessage = () => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.account = "";
    _errorMessageData.type = "";
    setErrorMessageData(_errorMessageData);
    setActiveSelectMessageType(false);
    setActiveSelectMessageType(false);
    setBroadcastMessageData(JSON.parse(JSON.stringify(DEFAULT_DATA)));
    setShowModalNewMessage(false);
  }

  const addBtn = () => {
    const onClick = () => setShowModalNewMessage(true);
    const btnProps = { text: "New Scheduled Message", icon: FiPlus, onClick };

    return <NewButton {...btnProps} />;
  };

  // let toCapitalize = (word) => {
  //   return word.charAt(0).toUpperCase() + word.slice(1);
  // }

  let renderModal = () => {
      let onClickSelectChannel = (channel) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.type = "";
        setErrorMessageData(_errorMessageData);
        let _broadcastMessageData = {...broadcastMessageData};
        _broadcastMessageData.selectedChannel = channel;
        setBroadcastMessageData(_broadcastMessageData);
      }

      // let disableContinueChannel = () => {
      //   let _broadcastMessageData = {...broadcastMessageData};
      //   return (
      //     _broadcastMessageData.selectedChannel.channel === "" ||
      //     _broadcastMessageData.channel.value === ""
      //   )
      // }

      let disableContinueType = () => {
        let _broadcastMessageData = {...broadcastMessageData};
        return _broadcastMessageData.messages[0].type === "";
      }

      let onClickMessageType = (type) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.account = "";
        setErrorMessageData(_errorMessageData);
        let _broadcastMessageData = {...broadcastMessageData};
        _broadcastMessageData.messages[0].type = type;
        setBroadcastMessageData(_broadcastMessageData);
      }

      return (
        <Modal isOpen={showModalNewMessage} toggle={closeModalNewMessage} className={`new-message-modal ${!activeSelectMessageType ? "new-message-select-channel" : ""}`}>
            {!activeSelectMessageType ?
              <ModalBody>
                <div className="new-message-wrapper">
                    <b className="new-message-title">
                        New Scheduled Message
                    </b>

                    <p className="new-message-description">Select from the following message type</p>

                  <div className="new-message-channel-option-container">
                    <label>
                      <b>Channel Type</b>
                    </label>

                    <div className="new-message-channel-option-wrapper input-type">
                      {CHANNEL_LIST.map((value, index) => {
                        return (
                          <div 
                            className={`new-message-channel-option ${broadcastMessageData.selectedChannel.channel === value.channel ? "active-channel" : ""}`} 
                            key={`channel-option-${index}`}
                            onClick={() => onClickSelectChannel(value)}
                          >
                            <img src={value.img} alt="" />
                            <b>{value.text}</b>
                          </div>
                        )
                      })}
                    </div>
                    {errorMessageData.type !== "" &&
                      <ErrorOrInfoComp
                          text={errorMessageData.type}
                          _className={"font-red"}
                          icon={<FiInfo />}
                      />
                    }

                    <label>
                      <b>Channel Account</b>
                    </label>

                    <CustomSelectOption
                        isDisabled={!selectChannelNumberList}
                        optionListProps={selectChannelNumberList}
                        valueDropdownProps={broadcastMessageData.channel}
                        placeholderProps={"Select Channel"}
                        onClickDropDownListOptionProps={onSelectChannel}
                        specificClassNameProps="custom-select-broadcastchannel"
                        _className={`input-account ${errorMessageData.account === "" ? "" : "border-red"}`}
                    />
                    
                    {errorMessageData.account !== "" &&
                      <ErrorOrInfoComp
                          text={errorMessageData.account}
                          _className={"font-red"}
                          icon={<FiInfo />}
                      />
                    }

                    <ButtonWithLoadingOrIcon 
                        className="no-fill-button main-button-40 cancel-broadcast"
                        text="Cancel"
                        onClickAction={closeModalNewMessage}
                    />

                    <ButtonWithLoadingOrIcon 
                        className="orange-button main-button-40 continue-broadcast"
                        text="Continue"
                        onClickAction={() => {
                          // if(broadcastMessageData.selectedChannel.value === "whatsappba") {
                          //   goToCreate()
                          // }else {
                          //   setActiveSelectMessageType(true)
                          // }
                          goToCreate();
                        }}
                        // isDisabled={disableContinueChannel()}
                    />
                  </div>
                </div>
              </ModalBody>
              :
              <ModalBody>
                <div className="new-message-wrapper">
                    <BackButton text="Back to Select Channel" onClick={() => setActiveSelectMessageType(false)} />

                    <br />

                    {/* <FiX onClick={closeModalNewMessage} className="close-modal-new-message" /> */}
                   
                    <div className="new-message-option-container">
                          {NEW_MESSAGE_OPTION_LIST.map((value, index) => {
                              return (
                                  <div 
                                    className={`new-message-option-list ${broadcastMessageData.messages[0].type === value.type ? "active-message-type" : ""}`}
                                    // className={`new-message-option-list ${broadcastMessageData.messages[0].type === value.type ? "active-message-type" : ""}`}
                                    key={`new-message-option-${index}`} 
                                    onClick={() => onClickMessageType(value.type)}
                                  >
                                      <img src={value.image} alt="" />
                                      <br />
                                      <b>{value.title}</b>

                                      <p>
                                          {value.desc}
                                      </p>
                                  </div>
                              )
                          })}
                    </div>

                    <ButtonWithLoadingOrIcon 
                        className="no-fill-button main-button-40 cancel-broadcast"
                        text="Cancel"
                        onClickAction={closeModalNewMessage}
                    />

                    <ButtonWithLoadingOrIcon 
                        className="orange-button main-button-40 continue-broadcast"
                        text="Continue"
                        onClickAction={goToCreate}
                        isDisabled={disableContinueType()}
                    />
                </div>
              </ModalBody>
            }
        </Modal>
      )
  }

  return (
    <React.Fragment>
      <div className="sectionWrap broadcast-message-main-wrapper">
          {renderModal()}
          <div className="topContent">
              <div className="title">Scheduled Broadcast</div>
              <div className="btn">
                {addBtn()}
              </div>
          </div>

          <div className="broadcast-message-list-info">
            Scheduled Broadcast allow you to schedule and send a broadcast message in your desired date & time  
          </div>

          {waitForResponseGetList || retriveDataError.code ? 
            <RetrieveDataLoading 
              isLoading={waitForResponseGetList}
              errorMessage={retriveDataError.message} 
            />
            :
            <React.Fragment>
              <div className="broadcast-message-list-table-wrapper">
                <div className="custom-table">
                    <table className="custom-table-content table-broadcast-message-list">
                        <thead>
                            <tr>
                              <td className="col_name">Broadcast Name</td>
                              <td className="col_sender">Sender</td>
                              <td className="col_total">Recipients</td>
                              <td className="col_created">Created Time</td>
                              <td className="col_end">Scheduled Time</td>
                              {/* <td className="col_finished">Finished Time</td> */}
                              <td>
                                <CustomSelectOption
                                  optionListProps={listPageOption}
                                  valueDropdownProps={listPageVal}
                                  onClickDropDownListOptionProps={onSelectListPage}
                                />
                              </td>
                            </tr>
                        </thead>
                        
                        {broadcastMessageList.length < 1 ?
                          <tbody>
                            <tr>
                              <td colSpan="7" className="no-data-column"><b className="no-result-found">No Broadcast Messages found. Start by clicking “+New Scheduled Message”.</b></td>
                            </tr>
                          </tbody>
                          :
                          <tbody>
                              {broadcastMessageList.map((value, index) => {
                                  return (
                                      <tr key={`broadcast-message-${index}`}>
                                          <td className="col_name">
                                            <div>
                                              <img className="medium-logo" src={TAPLIVE_MEDIUM_LOGO[value.medium]} alt="" />
                                              {value.name}
                                            </div>
                                          </td>
                                          <td className="col_sender">{value.senderAccountName}</td>
                                          <td className="col_total">{value.totalUsers}</td>
                                          <td className="col_created">
                                              <>
                                                {HelperDate.formatToString(new Date(value.createdTime), "dd MMM yyyy")}
                                                <br />
                                                {HelperDate.formatToString(new Date(value.createdTime), "HH:mm")}
                                              </>
                                          </td>
                                          <td className="col_end">
                                            <>
                                              {HelperDate.formatToString(new Date(value.scheduledInputDate), "dd MMM yyyy")}
                                              <br />
                                              {value.scheduledInputHour}
                                            </>
                                          </td>
                                          {/* <td className="col_fnished">{HelperDate.formatToString(new Date(value.updatedTime), "dd MMM yyyy HH:mm")}</td> */}
                                          <td className="col_view">
                                            <NavLink
                                              to={{
                                                pathname: `/o/${orgID}/operational/broadcast-message/detail/${value.id}`,
                                                state: {
                                                  pageNumber: pageDisplay,
                                                  pageSize: listPage,
                                                  tab: props.tab
                                                }
                                              }}
                                            >
                                              <FiEye />
                                              View
                                            </NavLink>
                                          </td>
                                      </tr>
                                  )
                              })}
                          </tbody>
                        }
                    </table>
                </div>
                  
                <div className="pagination-wrapper">
                  {
                    totalItems > 0 &&
                    <Pagination
                      total={totalItems}
                      page={pageDisplay}
                      listPerPage={listPage}
                      step={1}
                      onChangePage={handleChangePage}
                    ></Pagination>
                  }
                </div>
              </div>
            </React.Fragment>
          }
      </div>
    </React.Fragment>
  );
};

export default BroadcastMessageScheduled;