import React, { useEffect, useState, useRef } from "react";
import { FiX, FiExternalLink, FiInfo } from "react-icons/fi";
import { SketchPicker } from 'react-color';
import "./IntegrationAutoMessageSetup.scss";
import { scrollToClass } from "../../../../helper/HelperGeneral";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import iconBrushBlack from '../../../../assets/img/icon-brush-black.svg';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import IntegrationTaptalkServices from '../../../../services/integration/IntegrationTaptalkServices';
import IntegrationWhatsappServices from '../../../../services/integration/IntegrationWhatsappServices';
import IntegrationWhatsappBusinessServices from '../../../../services/integration/IntegrationWhatsappBusinessServices';
import IntegrationFacebookServices from '../../../../services/integration/IntegrationFacebookServices';
import IntegrationInstagramServices from '../../../../services/integration/IntegrationInstagramServices';
import IntegrationTelegramServices from '../../../../services/integration/IntegrationTelegramServices';
import IntegrationGoogleServices from '../../../../services/integration/IntegrationGoogleServices';
import IntegrationTokopediaServices from '../../../../services/integration/IntegrationTokopediaServices';
import { doToast } from "../../../../helper/HelperGeneral";

let IntegrationAutoMessageSetup =   (props) => {
    let { back, backToText, data } = props;
    let [autoMessageSetup, setAutoMessageSetup] = useState(false);
    let [autoMessageVal, setAutoMessageVal] = useState(false);
    let [autoMessageValOrigin, setAutoMessageValOrigin] = useState(false);
    let [isShowColorPicker, setIsShowColorPicker] = useState(false);
    let [loadingSubmitData, setLoadingSubmitData] = useState();
    let [errorMessageData, setErrorMessageData] = useState({
        message: "",
        color: ""
    });    
    let colorPickerRef = useRef(null);

    useEffect(() => {
        if(props.location.state) {
            setAutoMessageVal(data);
            setAutoMessageValOrigin(data);
            setAutoMessageSetup(generateTypeData(props.location.state.type));
        }else {
            back();
        }
    }, [props])

    useEffect(() => {
        const onBodyClick = (event) => {
          if (isShowColorPicker) {
            if (!colorPickerRef.current.contains(event.target) && !colorPickerRef.current.previousSibling.contains(event.target)) {
                setIsShowColorPicker(false);
            }
          }
        };
    
        document.body.addEventListener('click', onBodyClick);
    
        return () => {
          document.body.removeEventListener('click', onBodyClick);
        };
    }, [isShowColorPicker]);

    let generateTypeData = (t) => {
        let _data = {
            title: "",
            description1: "",
            description2: "",
            type: ""
        }

        switch(t) {
            case "welcome":
                _data = {
                    title: "Welcome Message",
                    description1: "Use Global Welcome Message from setup",
                    description2: "Enable or disable Welcome message to setup your message in this channel.",
                    type: t
                }

                break;
            case "away":
                _data = {
                    title: "Away Message",
                    description1: "Use Global Away Message from setup",
                    description2: "Notify your visitors with a custom type message when you are away.",
                    type: t
                }

                break;
            case "closing":
                _data = {
                    title: "Closing Message",
                    description1: "Use Global Closing Message from setup",
                    description2: "Leave a custom type message to your visitors once you have resolved their case. ",
                    type: t
                }

                break;
            default:
                _data = {
                    title: "User Rating",
                    description1: "Use Global User Rating from setup",
                    description2: "Send a rating form to your customers once you have resolved their case. ",
                    type: t
                }
        }

        return _data;
    }

    let toggleColorPicker = () => {
        setIsShowColorPicker(!isShowColorPicker);
    };

    let changeColorPicker = (color) => {
        let _errorMessageData = {...errorMessageData}
        _errorMessageData.color = "";
        setErrorMessageData(_errorMessageData);
        let data = { ...autoMessageVal };
        data.themeColor = color.hex.substring(1, 7);
        // _addData.rgb = hexToRgb(color.hex);
        // toggleColorPicker();
        setAutoMessageVal(data);
    };

    let handleChangeColor = (event) => {
        // const val = /^[0-9A-F]{6}$/i.test(event.target.value);
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.color = "";
        setErrorMessageData(_errorMessageData);
        let data = { ...autoMessageVal };
        data.themeColor = event.target.value;
        // _addData.rgb = val ? hexToRgb(`#${event.target.value}`) : '';
        // console.log("val ? hexToRgb(`#${event.target.value}`) : ''", val ? hexToRgb(`#${event.target.value}`) : '')
        setAutoMessageVal(data);
    }

    let handleChangeGlobalConfig = (e) => {
        let _autoMessageVal = {...autoMessageVal};
        _autoMessageVal.useGlobalConfig = e.target.checked;
        setAutoMessageVal(_autoMessageVal);
    }

    let handleEnable = (e) => {
        let _autoMessageVal = {...autoMessageVal};
        _autoMessageVal.isEnabled = e.target.checked;
        setAutoMessageVal(_autoMessageVal);
    }

    let handleChangeText = (e) => {
        let _autoMessageVal = {...autoMessageVal};
        let _errorMessageData = {...errorMessageData};
        
        _errorMessageData.message = "";
        
        if(typeof(_autoMessageVal.text) !== "undefined") {
            _autoMessageVal.text = e.target.value;
        }else {
            _autoMessageVal.message = e.target.value;
        }

        setErrorMessageData(_errorMessageData);
        setAutoMessageVal(_autoMessageVal);
    }
    
    let onSaveChanges = () => {
        let errorCount = 0;
        let errorClass = "";
        let _errorMessageData = {...errorMessageData};

        if(typeof autoMessageVal.text === "string") {
            if(autoMessageVal.text.length === 0) {
                _errorMessageData.message = "This field is required";
                errorCount = errorCount + 1;
                errorClass = "input-message";
            }

            if(autoMessageVal.text.length > (autoMessageSetup.type === "rating" ? 250 : 1000)) {
                _errorMessageData.message = "Characters exceed limit";
                errorCount = errorCount + 1;
                errorClass = "input-message";
            }
        }else {
            if(autoMessageVal.message.length === 0) {
                _errorMessageData.message = "This field is required";
                errorCount = errorCount + 1;
                errorClass = "input-message";
            }

            if(autoMessageVal.message.length > (autoMessageSetup.type === "rating" ? 250 : 1000)) {
                _errorMessageData.message = "Characters exceed limit";
                errorCount = errorCount + 1;
                errorClass = "input-message";
            }
        }

        if(errorCount > 0) {
            setErrorMessageData(_errorMessageData);
            scrollToClass(`.${errorClass}`);
        }else {
            setLoadingSubmitData(true);

            //tokopedia
            if(autoMessageSetup.type === "welcome" && autoMessageVal.medium === "tokopedia") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTokopediaServices.setWelcomeMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "away" && autoMessageVal.medium === "tokopedia") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTokopediaServices.setAwayMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "closing" && autoMessageVal.medium === "tokopedia") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTokopediaServices.setClosingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "rating" && autoMessageVal.medium === "tokopedia") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    message: autoMessageVal.message,
                    themeColor: (autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor )
                };

                IntegrationTokopediaServices.setRatingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            //tokopedia
            
            //launcher
            if(autoMessageSetup.type === "welcome" && autoMessageVal.medium === "launcher") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTaptalkServices.setWelcomeMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "away" && autoMessageVal.medium === "launcher") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTaptalkServices.setAwayMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "closing" && autoMessageVal.medium === "launcher") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTaptalkServices.setClosingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "rating" && autoMessageVal.medium === "launcher") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    message: autoMessageVal.message,
                    themeColor: (autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor )
                };

                IntegrationTaptalkServices.setRatingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            //launcher

            // whatsapp
            if(autoMessageSetup.type === "welcome" && autoMessageVal.medium === "whatsapp") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationWhatsappServices.setWelcomeMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "away" && autoMessageVal.medium === "whatsapp") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationWhatsappServices.setAwayMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            
            if(autoMessageSetup.type === "closing" && autoMessageVal.medium === "whatsapp") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationWhatsappServices.setClosingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "rating" && autoMessageVal.medium === "whatsapp") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    message: autoMessageVal.message,
                    themeColor: (autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor )
                };

                IntegrationWhatsappServices.setRatingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            // whatsapp

            // whatsappba
            if(autoMessageSetup.type === "welcome" && autoMessageVal.medium === "whatsappba") {
                let data = {
                    id: parseInt(props.match.params.id),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationWhatsappBusinessServices.setWelcomeMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "away" && autoMessageVal.medium === "whatsappba") {
                let data = {
                    id: parseInt(props.match.params.id),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationWhatsappBusinessServices.setAwayMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "closing" && autoMessageVal.medium === "whatsappba") {
                let data = {
                    id: parseInt(props.match.params.id),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationWhatsappBusinessServices.setClosingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "rating" && autoMessageVal.medium === "whatsappba") {
                let data = {
                    id: parseInt(props.match.params.id),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    message: autoMessageVal.message,
                    themeColor: (autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor )
                };

                IntegrationWhatsappBusinessServices.setRatingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            // whatsappba

            // facebook
            if(autoMessageSetup.type === "welcome" && autoMessageVal.medium === "facebook") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationFacebookServices.setWelcomeMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "away" && autoMessageVal.medium === "facebook") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationFacebookServices.setAwayMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "closing" && autoMessageVal.medium === "facebook") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationFacebookServices.setClosingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "rating" && autoMessageVal.medium === "facebook") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    message: autoMessageVal.message,
                    themeColor: (autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor )
                };

                IntegrationFacebookServices.setRatingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            // facebook

            // instagram
            if(autoMessageSetup.type === "welcome" && autoMessageVal.medium === "instagram") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationInstagramServices.setWelcomeMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "away" && autoMessageVal.medium === "instagram") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationInstagramServices.setAwayMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "closing" && autoMessageVal.medium === "instagram") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationInstagramServices.setClosingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "rating" && autoMessageVal.medium === "instagram") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    message: autoMessageVal.message,
                    themeColor: (autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor )
                };

                IntegrationInstagramServices.setRatingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            // instagram

            // telegram
            if(autoMessageSetup.type === "welcome" && autoMessageVal.medium === "telegram") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTelegramServices.setWelcomeMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "away" && autoMessageVal.medium === "telegram") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTelegramServices.setAwayMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "closing" && autoMessageVal.medium === "telegram") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationTelegramServices.setClosingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "rating" && autoMessageVal.medium === "telegram") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    message: autoMessageVal.message,
                    themeColor: (autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor)
                };

                IntegrationTelegramServices.setRatingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            //telegram

            //google
            if(autoMessageSetup.type === "welcome" && autoMessageVal.medium === "google") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationGoogleServices.setWelcomeMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "away" && autoMessageVal.medium === "google") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationGoogleServices.setAwayMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "closing" && autoMessageVal.medium === "google") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    text: autoMessageVal.text
                };

                IntegrationGoogleServices.setClosingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }

            if(autoMessageSetup.type === "rating" && autoMessageVal.medium === "google") {
                let data = {
                    id: parseInt(props.match.params.accountID),
                    useGlobalConfig: autoMessageVal.useGlobalConfig,
                    isEnabled: autoMessageVal.isEnabled,
                    message: autoMessageVal.message,
                    themeColor: (autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor )
                };

                IntegrationGoogleServices.setRatingMessage(props.match.params.orgID, data, (res) => {
                    if(res.dataResult.error.message !== "") {
                        doToast(res.dataResult.error.message, "fail");
                        setLoadingSubmitData(false);
                    }else {
                        doToast(res.dataResult.data.message);

                        if(res.dataResult.data.success) {
                            setAutoMessageValOrigin(autoMessageVal);

                            setTimeout(() => {
                                back();
                            }, 3000)
                        }
                    }

                })
            }
            //google
        }
    }
    
    return (
        <SectionWrap 
            {...props} 
            orgID={props.match.params.orgID} 
            withHeader 
            withSideMenu
            dataOrigin={autoMessageValOrigin}
            dataAfterChange={autoMessageVal}
        >
            {(autoMessageSetup && autoMessageVal) ?
                <>
                    <BackButton 
                        text={backToText} 
                        onClick={back}
                    />
                    
                    <div className="integration-auto-message-setup">
                        <div className="title-wrapper">
                            <div className="title-description">
                                <p className="title-customize">
                                    <b>Edit {autoMessageSetup.title}</b>
                                </p>
                            </div>

                            {!loadingSubmitData ?
                                <ButtonWithLoadingOrIcon 
                                    text="Save Changes"
                                    onClickAction={() => onSaveChanges()}
                                    className="main-button-40 orange-button"
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    text="Save Changes"
                                    className="main-button-40 orange-button"
                                    isLoading
                                    loadingColor="gray"
                                    isDisabled
                                    position="left"
                                />
                            }
                        </div>

                        <div className="top-setup-wrapper">
                            <div className="top-setup-description">
                                <p className="title-customize">
                                    <b>Use Global Config</b>
                                </p>
        
                                <p className="description-customize">
                                    {autoMessageSetup.description1}
                                </p>
        
                                <label className="custom-switch-wrapper">
                                    <input disabled={loadingSubmitData} type="checkbox" checked={autoMessageVal.useGlobalConfig} onChange={(e) => handleChangeGlobalConfig(e)} />
                                    <span className="custom-switch round"></span>
                                </label>
                            </div>
                        </div>
                        {!autoMessageVal.useGlobalConfig &&
                            <>
                                <div className="top-setup-wrapper">
                                    <div className="top-setup-description">
                                        <p className="title-customize">
                                            <b>Enable {autoMessageSetup.title}</b>
                                        </p>
                
                                        <p className="description-customize">
                                            {autoMessageSetup.description2}
                                        </p>
                
                                        <label className="custom-switch-wrapper">
                                            <input type="checkbox" checked={autoMessageVal.isEnabled} onChange={(e) => handleEnable(e)} />
                                            <span className="custom-switch round"></span>
                                        </label>
                                    </div>
                                </div>

                                {/* {!autoMessageVal.useGlobalConfig && */}
                                    <>
                                        <label className="label-auto-message">
                                            <span className={errorMessageData.message === "Characters exceed limit" ? "red-text" : "grey-font"}>{typeof autoMessageVal.text === "string" ? autoMessageVal.text.length : autoMessageVal.message.length}/{autoMessageSetup.type === "rating" ? 250 : 1000}</span>
                                            <b>Message</b>
                                        </label>
                                        
                                        <br />
                                        
                                        <div className="auto-message-setup-textarea-wrapper">
                                            <textarea className={`input-message auto-message-setup-textarea ${errorMessageData.message !== "" ? "border-red" : ""}`} value={autoMessageVal.text ? autoMessageVal.text : autoMessageVal.message} onChange={(e) => handleChangeText(e)} disabled={!autoMessageVal.isEnabled || loadingSubmitData} />
                                            {errorMessageData.message !== "" &&
                                                <ErrorOrInfoComp
                                                    text={errorMessageData.message}
                                                    _className={"font-red margin-top-0"}
                                                    icon={<FiInfo />}
                                                />
                                            }
                                            {/* <span className="grey-font">{typeof(autoMessageVal.text) !== "undefined" ? autoMessageVal.text.length : autoMessageVal.message.length} / 1000</span> */}
                                        </div>
                                    </>
                                {/* } */}
                            </>
                        }

                        {(autoMessageSetup.type === "rating" && !autoMessageVal.useGlobalConfig) &&
                            <div className="user-rating-wrapper">
                                <div className="color-select-wrapper">
                                    <b className="color-select-title">Theme Color</b>
                                    <p className="color-select-desc">
                                        Used for background and action buttons.
                                    </p>

                                    <ButtonWithLoadingOrIcon 
                                        position="left"
                                        icon={{
                                            type: "svg",
                                            src: FiExternalLink
                                        }}
                                        onClickAction={() => {
                                            window.open(window.location.origin+`/case_rating?token=example&color=${autoMessageVal.themeColor.replace("#", "")}`, "_blank")
                                        }}
                                        className="no-fill-button main-button-40 button-go-preview"
                                        text="Preview Form"
                                    />

                                    <div className={`color-input-wrapper ${errorMessageData.color !== "" ? "border-red" : ""}`}>
                                        <div
                                            className="color-input-container"
                                            onClick={() => {
                                                toggleColorPicker();
                                            }}
                                        >
                                            <>
                                                {autoMessageVal.themeColor === '' ?
                                                    <img src={iconBrushBlack} alt="" className="brush-icon" />
                                                    :
                                                    <div className="color-value" style={{ background: `${autoMessageVal.themeColor.substr(0, 1) !== "#" ? "#"+autoMessageVal.themeColor : autoMessageVal.themeColor}` }} />
                                                }
                                                <label className="color-tag">#</label>
                                                <input className="color-input" type="text" onChange={handleChangeColor} value={autoMessageVal.themeColor.replace("#", "")} placeholder="Select Color" maxLength="6" />
                                            </>
                                        </div>

                                        {isShowColorPicker && (
                                            <div className="color-picker-container" ref={colorPickerRef}>
                                            <FiX
                                                onClick={toggleColorPicker}
                                                className="close-color-picker"
                                            />
                                            
                                            <SketchPicker
                                                color={`#${autoMessageVal.themeColor}`}
                                                onChange={changeColorPicker}
                                            />
                                            </div>
                                        )}

                                    </div>

                                    {errorMessageData.color !== "" &&
                                        <ErrorOrInfoComp
                                            text={errorMessageData.color}
                                            _className={"font-red"}
                                            icon={<FiInfo />}
                                        />
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </>
                :
                ""
            }
        </SectionWrap>
    )
}

export default IntegrationAutoMessageSetup;