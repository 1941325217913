import React, { useEffect, useState } from 'react';
import './IntegrationWhatsappDetailAccount.scss';
import { NavLink } from "react-router-dom";
// import Integrations from "../../Integrations";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import 'react-toastify/dist/ReactToastify.css';
import { FiEdit, FiAlertTriangle, FiPower, FiLogOut, FiClock, FiAlertCircle, FiCopy, FiRefreshCcw, FiLink2 } from 'react-icons/fi';
import iconDelete from '../../../../assets/img/integration/icon-delete.svg';
// import iconAuthenticate from '../../../../assets/img/authenticate-icon.svg';
import iconCheckGreen from '../../../../assets/img/icon-check-green.svg';
import iconCloseRed from '../../../../assets/img/close-red-icon.svg';
import iconXgrey from '../../../../assets/img/integration/icon-x-grey.svg';
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
import PopupRemove from '../../../reuseableComponent/popupRemove/PopupRemove';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import IntegrationAutoMessageDetail from "../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";
import Loading from "../../../reuseableComponent/loading/Loading";
import IntegrationWhatsappServices from '../../../../services/integration/IntegrationWhatsappServices';
import Helper from '../../../../helper/HelperDate';
import HelperChat from '../../../../helper/HelperChat';
import HelperInput from '../../../../helper/HelperInput';
import { Modal, ModalBody } from 'reactstrap';

const ALLOWED_ACTION = {
  authenticate: 'authenticate',
  logout: 'logout',
  takeover: 'takeover',
  reboot: 'reboot',
};

const INSTANCE_STATUS = {
  waiting_setup: 'waiting_setup',
  authenticated: 'authenticated',
  'got qr code': 'got qr code',
  got_qr_code: 'got_qr_code',
  loading: 'loading',
  init: 'init',
  not_paid: 'not_paid',
  unauthenticated: 'unauthenticated',
  error: 'error',
  conflict: 'conflict',
  offline: 'offline',
};

// const INSTANCE_STATUS = {
//   StatusGotQRCode: "got_qr_code",
//   StatusAuthenticated: "authenticated",
//   StatusNotPaid: "not_paid",
//   StatusWaitingSetup: "waiting_setup",
//   StatusError: "error"
// }

const IntegrationWhatsappDetailAccount = (props) => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);

  let [showModalRemoveChannel, setShowModalRemoveChannel] = useState(false);
  // let [waitForResponseDeleteChannel, setWaitForResponseDeleteChannel] = useState(false);

  let [showModalRebootChannel, setShowModalRebootChannel] = useState(false);
  // let [waitForResponseRebootChannel, setWaitForResponseRebootChannel] = useState(false);

  let [showModalLogoutChannel, setShowModalLogoutChannel] = useState(false);
  let [waitForResponseLogoutChannel, setWaitForResponseLogoutChannel] = useState(false);

  let [showModalDisconnectChannel, setShowModalDisconnectChannel] = useState(false);
  // let [waitForResponseDisconnectChannel, setWaitForResponseDisconnectChannel] = useState(false);

  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [accountDetailVal, setAccountDetailVal] = useState(null);
  let [whatsappChannelLink, setWhatsappChannelLink] = useState(null);
  let [whatsappChannelLinkNeverSet, setWhatsappChannelLinkNeverSet] = useState(false);

  let [showModalAuthentication, setShowModalAuthentication] = useState(false);

  let [waitForResponseRebootChannel, setWaitForResponseRebootChannel] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [isWaitingScreenImage, setIsWaitingScreenImage] = useState(false);
  let [screenImage, setScreenImage] = useState(false);
  let [whatsappChatbot, setWhatsappChatbot] = useState(false);
  let [isShowModalRemoveChatbot, setIsShowModalRemoveChatbot] = useState(false);
  let [waitForResponseRemoveChatbot, setWaitForResponseRemoveChatbot] = useState(false);
  let [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(false);
  let [welcomeMessageData, setWelcomeMessageData] = useState(false);
  let [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
  let [awayMessageData, setAwayMessageData] = useState(false);
  let [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
  let [closingMessageData, setClosingMessageData] = useState(false);
  let [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
  let [ratingMessageData, setRatingMessageData] = useState(false);
  let [errorWelcome, setErrorWelcome] = useState(false);
  let [errorAway, setErrorAway] = useState(false);
  let [errorClosing, setErrorClosing] = useState(false);
  let [errorRating, setErrorRating] = useState(false);
  let [errorChatbot, setErrorChatbot] = useState(false);

  useEffect(() => {
    if(accountDetailVal !== null) {
      getWhatsappChannelLink()
      runGetWelcomeMessage()
      runGetAwayMessage()
      runGetClosingMessage()
      runGetRatingMessage()
    }
  }, [accountDetailVal])

  useEffect(() => {
    if(whatsappChannelLink !== null || whatsappChannelLinkNeverSet) {
      getChatBot();
    }
  }, [whatsappChannelLink, whatsappChannelLinkNeverSet])

  let toggleModalRemoveChatbot = () => {
    setIsShowModalRemoveChatbot(!isShowModalRemoveChatbot);
  };

  let back = () => history.push(`/o/${orgID}/integration/whatsapp-sme/`);
  // let goToEdit = (id) => history.push(`/o/${orgID}/integration/whatsapp-sme/edit/${id}`);
  // let goToEditChannelLink = (id) => history.push(`/o/${orgID}/integration/whatsapp-sme/edit-channel-button/${id}`);
  // let goToAuthenticateNow = (id) => history.push(`/o/${orgID}/integration/whatsapp-sme/authenticate-now/${id}`);
  // let goToSecureAuthenticate = (id) => history.push(`/o/${orgID}/integration/whatsapp-sme/secure-authenticate/${id}`);

  let doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getWhatsappAccountDetail(paramID, match.params.accountID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  let removeChatbot = () => {
    setWaitForResponseRemoveChatbot(true);

    let data = {
      connectionID: whatsappChatbot.item.id,
      channelID: parseInt(match.params.accountID),
      chatbotID: whatsappChatbot.item.chatbotID,
    };
    
    IntegrationWhatsappServices.removeChatBot(
      orgID,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.message) {
            doToast(response.dataResult.data.message);
            setWaitForResponseRemoveChatbot(false);
          } else {
            doToast(response.dataResult.data.message);
            toggleModalRemoveChatbot();
            setIsLoadingGetDetailData(true);
            setWhatsappChannelLink(null);
            setWhatsappChatbot(null);
            setWhatsappChannelLinkNeverSet(false);
            getWhatsappAccountDetail(orgID, match.params.accountID);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
          setWaitForResponseRemoveChatbot(false);
        }
      }
    );
  }

  let getChatBot = () => {
    IntegrationWhatsappServices.getChatBot(orgID, {channelID: parseInt(match.params.accountID)}, (response) => {
      //  let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setWhatsappChatbot(response.dataResult.data);
      } else {
        // setAccountDetailVal(null);
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorChatbot({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        })

        // doToast(response.dataResult.error.message, 'fail');
        setIsLoadingGetDetailData(false);
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let getWhatsappAccountDetail = (orgID, accountID) => {
    setIsLoadingGetDetailData(true);

    IntegrationWhatsappServices.getDetail(orgID, accountID, (response) => {
      let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setAccountDetailVal(response.dataResult.data);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        setAccountDetailVal(null);
        setIsLoadingGetDetailData(false);
      }
    });
  };

  let getWhatsappChannelLink = () => {
    let data = {
      id: parseInt(match.params.accountID)
    }

    IntegrationWhatsappServices.getChannelLink(orgID, data, (response) => {
      let _retriveDataError = {...retriveDataError};
      let dataResult = response.dataResult;

      if (dataResult.status === 200) {
        setWhatsappChannelLink(dataResult.data.channelLink);
      } else {
        if(dataResult.error.code === "40401") {
          setWhatsappChannelLinkNeverSet(true);
        }else {
          setWhatsappChannelLink(null);
          _retriveDataError.message = dataResult.error.message;
          _retriveDataError.code = dataResult.error.code;
          setRetrieveDataError(_retriveDataError); 
        }
      }

      // setIsLoadingGetDetailData(false);
    });
  }

  let toggleModalRemoveChannel = () => {
    setShowModalRemoveChannel(!showModalRemoveChannel);
  };

  let deleteChannel = () => {};

  let toggleModalRebootChannel = () => {
    setShowModalRebootChannel(!showModalRebootChannel);
  };

  let rebootChannel = async () => {
    if(accountDetailVal.channel.vendor === "chat-api.com") {
      // chatapi
      setWaitForResponseRebootChannel(true);
      const urlWidget = accountDetailVal.chatapicom.widgetURL.split('/');
      const instanceId = urlWidget[3];
      const token = urlWidget[4];
  
      const reboot = await IntegrationWhatsappServices.reboot(instanceId, token);
      
      setWaitForResponseRebootChannel(false);
      
      toggleModalRebootChannel();
  
      if (reboot.dataResult.success) {
        doToast('Reboot Success');
        getWhatsappAccountDetail(checkID(match), match.params.accountID);
        return;
      }
  
      doToast('Reboot Failed', 'fail');
      //chat api
    }else {
      //maytapi
      setWaitForResponseRebootChannel(true);
  
      IntegrationWhatsappServices.rebootInstance(
        orgID,
        {id: parseInt(match.params.accountID)},
        (response) => {
  
          if (response.dataResult.status === 200) {
            doToast(response.dataResult.data.message);
  
            if (response.dataResult.data.success) {
              setShowModalLogoutChannel(false);
  
              setTimeout(() => {
                toggleModalRebootChannel();
                getWhatsappAccountDetail(orgID, match.params.accountID);
                setWaitForResponseRebootChannel(false);
              }, 1000);
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setWaitForResponseRebootChannel(false);
          }
        }
      );
      //maytapi
    }
  };

  let toggleModalLogoutChannel = () => {
    setShowModalLogoutChannel(!showModalLogoutChannel);
  };

  let logoutChannel = () => {
    setWaitForResponseLogoutChannel(true);
    
    IntegrationWhatsappServices.logout(
      orgID,
      match.params.accountID,
      (response) => {
        setWaitForResponseLogoutChannel(false);

        if (response.dataResult.status === 200) {
          doToast(response.dataResult.data.message);

          if (response.dataResult.data.success) {
            setShowModalLogoutChannel(false);

            setTimeout(() => {
              getWhatsappAccountDetail(orgID, match.params.accountID);
            }, 1000);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      }
    );
  };

  let toggleModalDisconnectChannel = () => {
    setShowModalDisconnectChannel(!showModalDisconnectChannel);
  };

  let disconnectChannel = () => {};

  let barPaymentValue = () => {
    let nextPaymentTime = accountDetailVal.channel.nextPaymentTime;
    let lastPaymentTime = accountDetailVal.channel.lastPaymentTime;
    let totalTimeDiff = Helper.dayDiff(nextPaymentTime, lastPaymentTime).day;
    let currentTimeDiff = Helper.dayDiff(nextPaymentTime, new Date().valueOf())
      .day;
    return 100 / (totalTimeDiff / currentTimeDiff);
  };

  let findAllowedButton = (action) => {
    let findIndex = accountDetailVal.allowedActions.findIndex(
      (value) => value.act === action
    );
    return findIndex !== -1;
  };

  let toogleModalAuthentication = () => {
    setShowModalAuthentication(!showModalAuthentication);
  };

  let printModalSecureAuthentication = () => {
    return (
      <Modal
        isOpen={showModalAuthentication}
        toggle={toogleModalAuthentication}
        className={'modal-secure-authentication'}
      >
        <ModalBody>
          <p className="modal-secure-authentication-top">
            <FiAlertCircle />
            <b>
              Has the Mobile Number Been Active for More Than 30 Days in
              WhatsApp?
            </b>

            <br />

            <span>
              This will determine the authentication method that suits best for
              this WhatsApp account.
            </span>
          </p>

          {accountDetailVal &&
            <div className="">
              <NavLink to={`/o/${orgID}/integration/whatsapp-sme/secure-authenticate/${accountDetailVal.channel.id}`}>
                <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-48"
                    text="No, Under 30 Days"
                />
              </NavLink>

              <NavLink to={`/o/${orgID}/integration/whatsapp-sme/authenticate-now/${accountDetailVal.channel.id}`}>
                <ButtonWithLoadingOrIcon 
                    className="green-button main-button-48"
                    text="Yes, More Than 30 Days"
                />
              </NavLink>
            </div>
          }
        </ModalBody>
      </Modal>
    );
  };

  useEffect(() => {
    doMount();

    if (location.state) {
      doToast(location.state.message);
      history.replace({
        state: undefined,
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    if(accountDetailVal !== null) {
      if(
        (accountDetailVal.channel.vendor === 'whatstalk' || accountDetailVal.channel.vendor === 'maytapi') && 
        (
          accountDetailVal.channel.channelStatus === INSTANCE_STATUS.authenticated ||
          accountDetailVal.channel.channelStatus === INSTANCE_STATUS.loading ||
          accountDetailVal.channel.channelStatus === INSTANCE_STATUS.got_qr_code ||
          accountDetailVal.channel.channelStatus === INSTANCE_STATUS["got qr code"]
        )
      ) {
        getScreen()
      }
    }
  }, [accountDetailVal])

  let getScreen = () => {
    setIsWaitingScreenImage(true);
    
    IntegrationWhatsappServices.getScreen(orgID, {id: parseInt(match.params.accountID)}, (response) => {
        setIsWaitingScreenImage(false);
        
        if(response.dataResult.status === 200) {
            setScreenImage(`data:image/png;base64, ${response.dataResult.value}`);
        }else {
            doToast("Generating QR Code is failed", "fail"); 
        }
    })
  }

  let getChannelLink = () => {
    let link = whatsappChannelLink.url;
    const CURRENT_LOCATION = encodeURIComponent(window.location.href);

    link = `${link}${whatsappChannelLink.sendPageURL ? `%0A%0AFrom URL: ${CURRENT_LOCATION}` : ""}`;
    
    return link;
  }

  let renderTopics = () => {
    let text = "";
    
    accountDetailVal.channel.topics.map((v, i) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  let runGetWelcomeMessage = () => {
    setLoadingGetWelcomeMessage(true);

    IntegrationWhatsappServices.getWelcomeMessage(orgID, match.params.accountID, (response) => {
        // let _retriveDataError = {...retriveDataError};
        
        if (response.dataResult.error.message === "") {
          setWelcomeMessageData(response.dataResult.data.welcomeMessage);
        } else {
          // _retriveDataError.message = response.dataResult.error.message;
          // _retriveDataError.code = response.dataResult.error.code;
          // setRetrieveDataError(_retriveDataError);
          setErrorWelcome({
            errorCode: response.dataResult.error.code,
            errorMessage: response.dataResult.error.message
          });
          // doToast(response.dataResult.error.message, 'fail');
        }

        setLoadingGetWelcomeMessage(false);
    });
  }

  let runGetAwayMessage = () => {
    setLoadingGetAwayMessage(true);

    IntegrationWhatsappServices.getAwayMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAwayMessageData(response.dataResult.data.awayMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAway({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetAwayMessage(false);
    });
  }

  let runGetClosingMessage = () => {
    setLoadingGetClosingMessage(true);

    IntegrationWhatsappServices.getClosingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setClosingMessageData(response.dataResult.data.closingMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorClosing({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetClosingMessage(false);
    });
  }

  let runGetRatingMessage = () => {
    setLoadingGetRatingMessage(true);

    IntegrationWhatsappServices.getRatingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setRatingMessageData(response.dataResult.data.userRating);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorRating({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });        
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetRatingMessage(false);
    });
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}

        <div className="sectionWrap integration-main-container integration-whatsapp-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              {/* <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
              </div> */}
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
          ) : (
            <React.Fragment>
              <BackButton text="Back to WhatsApp for SME" onClick={back} />

              <div className="integration-whatsapp-detail-account-box-1">
                <div className="integration-whatsapp-detail-account-box-1-top">
                  <b>{accountDetailVal.channel.accountName}</b>

                  <div className="integration-whatsapp-detail-account-box-1-top-button">
                    {/* <ButtonWithLoadingOrIcon 
                        className="red-button main-button-40"
                        text="Delete"
                        onClickAction={() => toggleModalRemoveChannel()}
                        position="left"
                        icon={{
                          type: "svg",
                          src: FiTrash2
                        }}
                    /> */}

                    <NavLink to={`/o/${orgID}/integration/whatsapp-sme/edit/${accountDetailVal.channel.id}`}>
                      <ButtonWithLoadingOrIcon 
                          className="no-fill-button main-button-40"
                          text="Edit"
                          position="left"
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                      />
                    </NavLink>

                  </div>
                </div>

                <div className="integration-whatsapp-detail-account-box-1-bottom">
                  <div className="form-content-wrapper" style={{marginBottom: "16px"}}>
                    <label>
                      <b>Channel ID</b>
                    </label>
                    <div className="disabled-form" style={{marginTop: "8px"}}> 
                      <input type="text"
                        value={accountDetailVal.channel.stringID}
                        // onClick={copyToClip}
                        id="copy-string-id"
                        readOnly
                        style={{paddingRight: "40px"}}
                      /> 
              
                      <FiCopy 
                        onClick={() => {
                          copyToClip("copy-string-id", "Channel ID copied")
                        }}
                      />
                    </div>
                  </div>
                  
                  {accountDetailVal.channel.topics.length > 0 &&
                    <>
                      <label>
                        <b>Topic</b>
                      </label>
                      <p className="topic-val">
                        <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                      </p>
                    </>
                  }

                  <label>
                    <b>Instance Status</b>
                  </label>
                  {accountDetailVal.channel.channelStatus ===
                    INSTANCE_STATUS.authenticated && (
                    <p className="integration-whatsapp-detail-account-box-1-top-authenticated">
                      <img src={iconCheckGreen} alt="" />
                      <b>{accountDetailVal.channel.channelStatusText}</b>

                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          )
                          :
                          accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          )
                        )
                      }
                    </p>
                  )}

                  {accountDetailVal.channel.channelStatus ===
                    INSTANCE_STATUS.unauthenticated && (
                    <p>
                      <FiAlertTriangle />
                      <b>{accountDetailVal.channel.channelStatusText}</b>

                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          )
                          :
                          accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          )
                        )
                      }
                    </p>
                  )}

                  {accountDetailVal.channel.channelStatus ===
                    INSTANCE_STATUS[accountDetailVal.channel.vendor === "chat-api.com" ? 'got qr code' : 'got_qr_code'] && (
                    <p className="waiting-setup">
                      <FiAlertTriangle />
                      <b>{accountDetailVal.channel.channelStatusText}</b>
                      
                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          (accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          ))
                          :
                          (accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          ))
                        )
                      }
                    </p>
                  )}

                  {accountDetailVal.channel.channelStatus ===
                    INSTANCE_STATUS.waiting_setup && (
                    <p className="waiting-setup">
                      <FiClock />
                      <b>{accountDetailVal.channel.channelStatusText}</b>

                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          )
                          :
                          accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          )
                        )
                      }
                    </p>
                  )}

                  {accountDetailVal.channel.channelStatus ===
                    INSTANCE_STATUS.not_paid && (
                    <p className="not-paid">
                      <img src={iconCloseRed} alt="" />
                      <b>{accountDetailVal.channel.channelStatusText}</b>
                      
                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          )
                          :
                          accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          )
                        )
                      }
                    </p>
                  )}

                  {accountDetailVal.channel.channelStatus ===
                    INSTANCE_STATUS.error && (
                    <p className="not-paid">
                      <img src={iconCloseRed} alt="" />
                      <b>{accountDetailVal.channel.channelStatusText}</b>

                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          )
                          :
                          accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          )
                        )
                      }
                    </p>
                  )}

                  {accountDetailVal.channel.channelStatus ===
                    INSTANCE_STATUS.conflict && (
                    <p className="not-paid">
                      <b>{accountDetailVal.channel.channelStatusText}</b>
                      
                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          )
                          :
                          accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          )
                        )
                      }
                    </p>
                  )}

                  {accountDetailVal.channel.channelStatus ===
                    INSTANCE_STATUS.offline && (
                    <p className="not-paid">
                      <b>{accountDetailVal.channel.channelStatusText}</b>

                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          )
                          :
                          accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          )
                        )
                      }

                      {accountDetailVal.maytapi.instance.error !== '' && (
                        <span className="error-message font-root-medium">
                          {accountDetailVal.maytapi.instance.error}
                        </span>
                      )}
                    </p>
                  )}

                  {!INSTANCE_STATUS[
                    accountDetailVal.channel.channelStatus
                  ] && (
                    <p className="waiting-setup">
                      <FiAlertTriangle />
                      <b>{accountDetailVal.channel.channelStatusText}</b>
                      
                      {accountDetailVal.channel.vendor !== "" &&
                        (accountDetailVal.channel.vendor === "chat-api.com" ?
                          accountDetailVal.chatapicom.instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal.chatapicom.instance.error}
                            </span>
                          )
                          :
                          accountDetailVal[accountDetailVal.channel.vendor].instance.error !== '' && (
                            <span className="error-message font-root-medium">
                              {accountDetailVal[accountDetailVal.channel.vendor].instance.error}
                            </span>
                          )
                        )
                      }
                    </p>
                  )}

                  <div className="integration-whatsapp-detail-account-box-1-bottom-button">
                    {findAllowedButton(ALLOWED_ACTION.reboot) && (
                      <ButtonWithLoadingOrIcon 
                          className="no-fill-button-red main-button-40"
                          text="Reboot"
                          onClickAction={() => toggleModalRebootChannel()}
                          position="left"
                          icon={{
                            type: "svg",
                            src: FiPower
                          }}
                      />
                    )}

                    {findAllowedButton(ALLOWED_ACTION.logout) && (
                      <ButtonWithLoadingOrIcon 
                        className="no-fill-button-red main-button-40"
                        text="Log Out"
                        onClickAction={() => toggleModalLogoutChannel()}
                        position="left"
                        icon={{
                          type: "svg",
                          src: FiLogOut
                        }}
                      />
                    )}

                    {/* {findAllowedButton(ALLOWED_ACTION.authenticate) && 
                      <ButtonWithLoadingOrIcon 
                        className="no-fill-button main-button-40"
                        text="Show QR Code"
                        onClickAction={() => {
                          accountDetailVal.channel.vendor === "chat-api.com" ?
                            (
                              accountDetailVal.chatapicom.secureAuthEnabled ? 
                                toogleModalAuthentication()
                                :
                                goToAuthenticateNow(accountDetailVal.channel.id)
                            )
                            :
                            goToAuthenticateNow(accountDetailVal.channel.id)
                        }}
                        position="left"
                        icon={{
                          type: "img",
                          src: iconAuthenticate
                        }}
                      />
                    } */}

                    {(accountDetailVal.channel.vendor === "chat-api.com" && accountDetailVal.chatapicom.widgetURL !== '') &&
                      (accountDetailVal.channel.channelStatus === INSTANCE_STATUS.conflict ||
                       accountDetailVal.channel.channelStatus === INSTANCE_STATUS.offline ||
                       accountDetailVal.channel.channelStatus === INSTANCE_STATUS.authenticated
                      ) && (
                        <div className="iframe-space">
                          <iframe
                            src={accountDetailVal.chatapicom.widgetURL}
                            title="WhatApp Instance Status"
                          ></iframe>
                        </div>
                      )
                    }

                    {(accountDetailVal.channel.vendor === 'maytapi' || accountDetailVal.channel.vendor === 'whatstalk') &&
                      <div className="iframe-space-maytapi">
                        {!isWaitingScreenImage ?
                          <ButtonWithLoadingOrIcon 
                            text="Refresh"
                            icon={{
                              type: "svg",
                              src: FiRefreshCcw
                            }}
                            className="orange-button main-button-40"
                            position="left"
                            onClickAction={getScreen}
                          />
                          :
                          <ButtonWithLoadingOrIcon 
                            text="Refresh"
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            className="orange-button main-button-40"
                            position="left"
                          />
                        }

                        <div className="iframe-space-maytapi-content">
                          {isWaitingScreenImage ?
                            <div className="loading-maytapi">
                              <Loading />
                            </div>
                            :
                            screenImage ?
                              <img src={screenImage} alt="" />
                              :
                              <p>
                                <b>Click the button refresh if nothing is showing</b>
                              </p>
                          }
                        </div>
                      </div>
                    }

                    {/* disini */}
                  </div>
                </div>
              </div>

              {/* channel button */}
              <div className="integration-whatsapp-detail-account-box-1 box-channel-button">
                <div className="integration-whatsapp-detail-account-box-1-top">
                  <b>Channel Button for Live Chat</b>
                  <p className="bot-name">
                    For more details, <a className="link-orange" rel="noopener noreferrer" href="https://docs.taptalk.io/onetalk-omnichannel-documentation/social-channel-button" target="_blank">please read the documentation.</a>
                  </p>

                  <div className="integration-whatsapp-detail-account-box-1-top-button">
                    {!whatsappChannelLinkNeverSet && whatsappChannelLink.isEnabled &&
                      <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "svg",
                            src: FiLink2
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Preview Link"
                          position="left"
                          onClickAction={() => {
                            window.open(getChannelLink(), "_blank");
                          }}
                      />
                    }
                    
                    <NavLink to={`/o/${orgID}/integration/whatsapp-sme/edit-channel-button/${accountDetailVal.channel.id}`}>
                      <ButtonWithLoadingOrIcon 
                          icon={{
                            type: "svg",
                            src: FiEdit
                          }}
                          className="no-fill-button main-button-40 button-channel-detail"
                          text="Edit"
                          position="left"
                      />
                    </NavLink>
                  </div>
                </div>

                <div className="whatsapp-detail-channel-button">
                  <div className="channel-button-wrapper">
                      <b className="channel-button-title">Title</b>
                      <br />
                      <b>{whatsappChannelLinkNeverSet ? "Not Set" : (whatsappChannelLink.title === "" ? "Not Set" : whatsappChannelLink.title)}</b>
                  </div>

                  <div className="channel-button-wrapper">
                    <b className="channel-button-title">Phone Number</b>
                    <br />
                    <b>{whatsappChannelLinkNeverSet ? "Not Set" : (whatsappChannelLink.targetID[0] === "6" ? "+" : "")+HelperInput.phoneBeautify(whatsappChannelLink.targetID)}</b>
                  </div>

                  <div className="channel-button-wrapper">
                      <b className="channel-button-title">First Message</b>
                      <br />
                      <b dangerouslySetInnerHTML={{__html: 
                          whatsappChannelLinkNeverSet ? 
                            "Not Set" 
                            : 
                            (whatsappChannelLink.message === "" ? "Not Set" : HelperChat.lineBreakChatRoom(whatsappChannelLink.message))
                        }}
                      />
                  </div>

                  {/* <div className="channel-button-wrapper">
                    <b className="channel-button-title">Send Page URL</b>
                    <br />
                    <b>{whatsappChannelLinkNeverSet ? "Not Set" : (whatsappChannelLink.sendPageURL? "Yes" : "No")}</b>
                  </div>  */}

                  <div className="channel-button-wrapper status-wrapper">
                    <b className="channel-button-title">Status</b>
                    <br />
                    
                    {whatsappChannelLinkNeverSet ? 
                      <>
                        <img src={iconXgrey} alt="" />
                        <b className="status-value">Disabled</b>
                      </>
                      :
                      whatsappChannelLink.isEnabled ?
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-value status-enabled">Enabled</b>
                        </>
                        :
                        <>
                          <img src={iconXgrey} alt="" />
                          <b className="status-value">Disabled</b>
                        </>
                    }
                  </div>
                </div>
              </div>
              {/* channel button */}
              
              
              {//!accountDetailVal.channel.isSuspended &&
                Helper.dayDiff(
                  accountDetailVal.channel.nextPaymentTime,
                  new Date().valueOf()
                ).day > -1 && (
                  <div className="integration-whatsapp-detail-account-box-2">
                    <p className="integration-whatsapp-detail-account-box-2-title">
                      <b>Payment</b>
                    </p>

                    <div className="integration-whatsapp-detail-account-box-2-payment-wrapper">
                      <label>
                        <b>Payment Status</b>
                      </label>

                      <div className="payment-info-wrapper">
                        {(accountDetailVal.channel.channelStatus !== INSTANCE_STATUS.not_paid) &&
                          (accountDetailVal.channel.lastPaymentTime !== 0) &&
                          (accountDetailVal.channel.nextPaymentTime !== 0) ? (
                          //  paid until
                          <React.Fragment>
                            <p className="payment-until">
                              <img src={iconCheckGreen} alt="" />
                              <b>
                                Paid until{' '}
                                {Helper.formatToString(
                                  new Date(
                                    accountDetailVal.channel.nextPaymentTime
                                  ),
                                  'dddd, dd MMMM yyyy'
                                )}
                              </b>
                            </p>

                            <div className="payment-bar">
                              <div style={{ width: `${barPaymentValue()}%` }} />
                            </div>

                            <p className="payment-left">
                              <b>
                                {
                                  Helper.dayDiff(
                                    accountDetailVal.channel.nextPaymentTime,
                                    new Date().valueOf()
                                  ).day
                                }{' '}
                                {Helper.dayDiff(
                                  accountDetailVal.channel.nextPaymentTime,
                                  new Date().valueOf()
                                ).day > 1
                                  ? 'days'
                                  : 'day'}{' '}
                                {
                                  Helper.dayDiff(
                                    accountDetailVal.channel.nextPaymentTime,
                                    new Date().valueOf()
                                  ).hour
                                }{' '}
                                {Helper.dayDiff(
                                  accountDetailVal.channel.nextPaymentTime,
                                  new Date().valueOf()
                                ).hour > 1
                                  ? 'hours'
                                  : 'hour'}{' '}
                                left until next billing. Last payment{' '}
                                {Helper.formatToString(
                                  new Date(
                                    accountDetailVal.channel.lastPaymentTime
                                  ),
                                  'dd MMMM yyyy'
                                )}
                                .
                              </b>
                            </p>
                          </React.Fragment>
                        ) : (
                          <>
                            <div className="payment-info-wrapper">
                              <p className="not-paid">
                                <b>Please make payment to activate account.</b>
                              </p>
                            </div>

                            {/* <div className="alert-component alet-verifying">
                              <FiAlertCircle />
                              <b>
                                We are currently verifying your most recent payment, please allow up to 24 hours for our team to verify your payment.
                              </b>
                            </div> */}
                          </>
                        )}

                        {/* expired */}
                        {/* <p className="payment-expired">
                                                <FiAlertTriangle />
                                                <b>Payment Due</b>
                                            </p>

                                            <div className="payment-bar">
                                                <div style={{width:`${barPaymentValue()}%`}} />
                                            </div>

                                            <p className="payment-left">
                                                <b>{Helper.dayDiff(accountDetailVal.channel.nextPaymentTime, accountDetailVal.channel.lastPaymentTime)} {Helper.dayDiff(accountDetailVal.channel.nextPaymentTime, accountDetailVal.channel.lastPaymentTime) > 1 ? "days" : "day"} left until next billing. Last payment {Helper.formatToString(new Date(accountDetailVal.channel.lastPaymentTime), "dd MMMM yyyy")}.</b>
                                            </p> */}
                        {/* expired */}
                      </div>

                      {/* <div className="how-to-make-payment">
                        <div className="how-to-make-payment-head">
                          <FiInfo />

                          <b>
                            {accountDetailVal.channel.channelStatus !==
                            INSTANCE_STATUS.not_paid
                              ? 'Instructions for Next Payment'
                              : 'Payment Instructions'}
                          </b>
                        </div>

                        <p className="add-on-price">
                          <b>+ WhatsApp SME add-on price</b>
                        </p>

                        <div className="price-tier">
                          <div className="price-tier-card">
                            <span className="price-tier-name">
                              <b>Essestials</b>
                            </span>
                            <b className="price-tier-value">Rp 499.000/month</b>

                            <a
                              target="_blank"
                              href="https://taptalk.io/onetalk/pricing"
                              rel="noopener noreferrer"
                            >
                              <FiArrowUpRight />{' '}
                              <b className="price-tier-more-detail">
                                More Details
                              </b>
                            </a>
                          </div>

                          <div className="price-tier-card">
                            <span className="price-tier-name">
                              <b>Professional</b>
                            </span>
                            <b className="price-tier-value">Rp 749.000/month</b>

                            <a
                              target="_blank"
                              href="https://taptalk.io/onetalk/pricing"
                              rel="noopener noreferrer"
                            >
                              <FiArrowUpRight />{' '}
                              <b className="price-tier-more-detail">
                                More Details
                              </b>
                            </a>
                          </div>

                          <div className="price-tier-card">
                            <span className="price-tier-name">
                              <b>Enterprise</b>
                            </span>
                            <b className="price-tier-value">Rp 999.000/month</b>

                            <a
                              target="_blank"
                              href="https://taptalk.io/onetalk/pricing"
                              rel="noopener noreferrer"
                            >
                              <FiArrowUpRight />{' '}
                              <b className="price-tier-more-detail">
                                More Details
                              </b>
                            </a>
                          </div>
                        </div>

                        <div className="payment-step-wrapper">
                          {accountDetailVal.channel.channelStatus ===
                          INSTANCE_STATUS.not_paid ? (
                            <React.Fragment>
                              <div className="payment-step-list">
                                <div className="payment-step-counter">
                                  <b>1</b>
                                </div>
                                <p className="font-root-medium">
                                  Please make a payment transfer of the{' '}
                                  <b>exact amount</b> from the prices listed
                                  above to this account:
                                </p>

                                <div className="bank-account-wrapper">
                                  <b>
                                    Bank: Mandiri
                                    <br />
                                    Account No: {process.env.REACT_APP_TAPTALK_BANK_ACCOUNT_NUMBER_MANDIRI} - {process.env.REACT_APP_TAPTALK_BANK_ACCOUNT_NAME_MANDIRI}
                                  </b>
                                </div>
                              </div>

                              <div className="payment-step-list">
                                <div className="payment-step-counter">
                                  <b>2</b>
                                </div>
                                <p className="font-root-medium">
                                  Please allow up to 24 hours for your{' '}
                                  <b>instance status</b> change to{' '}
                                  <span className="payment-list-icon">
                                    <b>
                                      <FiAlertTriangle /> Unauthenticated
                                    </b>
                                  </span>
                                  . Your <b>payment status</b> will also display
                                  the amount of days you have until your next
                                  billing.
                                </p>
                              </div>

                              <div className="payment-step-list">
                                <div className="payment-step-counter">
                                  <b>3</b>
                                </div>
                                <p className="font-root-medium">
                                  After you have successfully transferred,
                                  please attach a screenshot of the transaction
                                  with the subject line "WhatsApp Payment" and
                                  email it to{' '}
                                  <a
                                    href={`mailto:sales@taptalk.io?subject= Payment Confirmation for WhatsApp Add-On&body=Hi TapTalk.io Team, I want to confirm my payment of WhatsApp Instance of ${accountDetailVal.channel.accountName}`}
                                  >
                                    <b>sales@taptalk.io</b>
                                  </a>
                                  .
                                </p>
                              </div>

                              <div className="payment-step-list">
                                <div className="payment-step-counter">
                                  <b>4</b>
                                </div>
                                <p className="font-root-medium">
                                  Once your <b>Instance Status</b> has changed
                                  to Unauthenticated, click the button{' '}
                                  <span className="payment-list-icon">
                                    <b>
                                      <img src={iconAuthenticate} alt="" />{' '}
                                      Authenticate
                                    </b>
                                  </span>{' '}
                                  and follow the instructions.
                                </p>
                              </div>

                              <div className="payment-step-list">
                                <div className="payment-step-counter">
                                  <b>5</b>
                                </div>
                                <p className="font-root-medium">
                                  After successful authentication, your{' '}
                                  <b>Instance Status</b> will change to{' '}
                                  <span className="payment-list-icon authenticated">
                                    <b>
                                      <img src={iconCheckGreen} alt="" />{' '}
                                      Authenticated
                                    </b>
                                  </span>
                                  . By this time you should be able to recieve
                                  and reply all incoming messages from WhatsApp.
                                </p>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <div className="payment-step-list">
                                <div className="payment-step-counter">
                                  <b>1</b>
                                </div>
                                <p className="font-root-medium">
                                  Please make a payment transfer of the{' '}
                                  <b>exact amount</b> from the prices listed
                                  above to this account:
                                </p>

                                <div className="bank-account-wrapper">
                                  <b>
                                    Bank: Mandiri
                                    <br />
                                    Account No: {process.env.REACT_APP_TAPTALK_BANK_ACCOUNT_MANDIRI} - TAP TALK
                                    TEKNOLOGI
                                  </b>
                                </div>
                              </div>

                              <div className="payment-step-list">
                                <div className="payment-step-counter">
                                  <b>2</b>
                                </div>
                                <p className="font-root-medium">
                                  After you have successfully transferred,
                                  please attach a screenshot of the transaction
                                  with the subject line "WhatsApp Payment" and
                                  email it to{' '}
                                  <a
                                    href={`mailto:sales@taptalk.io?subject= Payment Confirmation for WhatsApp Add-On&body=Hi TapTalk.io Team, I want to confirm my payment of WhatsApp Instance of ${accountDetailVal.channel.accountName}`}
                                  >
                                    <b>sales@taptalk.io</b>
                                  </a>
                                  .
                                </p>
                              </div>
                            </React.Fragment>
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                )}
              {/* :
                    <div className="integration-whatsapp-detail-account-box-2">
                        <p className="integration-whatsapp-detail-account-box-2-title">
                            <b>Payment</b>
                        </p>

                        <div className="integration-whatsapp-detail-account-box-2-payment-wrapper">
                            <label><b>Payment Status</b></label>

                            <div className="payment-info-wrapper">
                                <p className="not-paid">
                                    <b>Please make payment to activate account.</b>
                                </p>
                            </div>

                            <div className="how-to-make-payment">
                                <FiInfo />

                                <b>How To Make Payment?</b>
                                <p>
                                    To make payment please email us at <a href="mailto:sales@taptalk.io?subject=Whatsapp Integration - Payment&body=Hi TapTalk.io Team, saya ingin bertanya terkait cara pembayaran instance WhatsApp yang akan saya digunakan. Terima kasih.">sales@taptalk.io</a>. Once processed, your payment  and instance status will be updated.
                                </p>
                            </div>
                        </div>
                    </div>
                } */}

                {/* chatbot */}
                <div className="integration-whatsapp-detail-account-box-1 box-channel-button">
                  <div className="integration-whatsapp-detail-account-box-1-top padding-bottom24">
                    <b>Chatbot</b>
                    {/* <p className='bot-name'>
                      Setup Your Chatbot
                    </p> */}

                    {!errorChatbot &&
                      <div className="integration-whatsapp-detail-account-box-1-top-button">
                        {whatsappChatbot.item.id !== 0 &&
                          <ButtonWithLoadingOrIcon 
                            icon={{
                              type: "img",
                              src: iconDelete
                            }}
                            className="red-button main-button-40 button-channel-detail"
                            text="Remove Chatbot"
                            position="left"
                            onClickAction={() => toggleModalRemoveChatbot()}
                          />
                        }

                        <ButtonWithLoadingOrIcon 
                            icon={{
                              type: "svg",
                              src: FiEdit
                            }}
                            className="no-fill-button main-button-40 button-channel-detail"
                            text="Set Chatbot"
                            position="left"
                            onClickAction={() => {
                              history.push({
                                pathname: `/o/${orgID}/integration/whatsapp-sme/edit-chatbot/${match.params.accountID}`,
                                state: {
                                  accountDetail: accountDetailVal,
                                  chatbot: whatsappChatbot.item
                                }
                              })
                            }}
                        />
                      </div>
                    }
                  </div>
                  
                  {errorChatbot ?
                    <RetrieveDataLoading 
                      isLoading={false}
                      errorCode={errorChatbot ? errorChatbot.errorCode : false}
                      errorMessage={errorChatbot ? errorChatbot.errorMessage : false}
                    />
                    :
                    <div className="facebook-detail-topic">
                      <div className="channel-button-wrapper margin-top24">
                          <b className="channel-button-title">Chatbot Name</b>
                          <br />
                          <b>{whatsappChatbot.item.id === 0 ? "Not Set" : whatsappChatbot.item.chatbotName}</b>
                      </div>

                      <div className="channel-button-wrapper margin-top24">
                          <b className="channel-button-title">Chatbot Type</b>
                          <br />
                          <b>{whatsappChatbot.item.id === 0 ? "Not Set" : whatsappChatbot.item.chatbotTypeName}</b>
                      </div>

                      <div className="channel-button-wrapper status-wrapper margin-top24">
                        <b className="topic-title">Status</b>
                        <br />
                        
                        {whatsappChatbot.item.id !== 0 ? 
                          <>
                            <img src={statusConnected} alt="" />
                            <b className="status-value text-green">Enabled</b>
                          </>
                          :
                          <>
                            <img src={iconXgrey} alt="" />
                            <b className="status-value">Disabled</b>
                          </>
                        }
                      </div>

                      {/* <ButtonWithLoadingOrIcon

                      /> */}
                    </div>
                  }
                </div>

                <IntegrationAutoMessageDetail 
                    withMarginTop
                    onClickEdit={(type) => {
                        props.history.push({
                            pathname: `/o/${orgID}/integration/whatsapp-sme/auto-message/setup/${match.params.accountID}`,
                            state: {
                                type: type
                            }
                        })
                    }}
                    data={welcomeMessageData}
                    isLoading={loadingGetWelcomeMessage}
                    type="welcome"
                    error={errorWelcome}
                />

                <IntegrationAutoMessageDetail 
                    withMarginTop
                    onClickEdit={(type) => {
                        props.history.push({
                            pathname: `/o/${orgID}/integration/whatsapp-sme/auto-message/setup/${match.params.accountID}`,
                            state: {
                                type: type
                            }
                        })
                    }}
                    data={awayMessageData}
                    isLoading={loadingGetAwayMessage}
                    type="away"
                    error={errorAway}
                />

                <IntegrationAutoMessageDetail 
                    withMarginTop
                    onClickEdit={(type) => {
                        props.history.push({
                            pathname: `/o/${orgID}/integration/whatsapp-sme/auto-message/setup/${match.params.accountID}`,
                            state: {
                                type: type
                            }
                        })
                    }}
                    data={closingMessageData}
                    isLoading={loadingGetClosingMessage}
                    type="closing"
                    error={errorClosing}
                />

                <IntegrationAutoMessageDetail 
                    withMarginTop
                    onClickEdit={(type) => {
                        props.history.push({
                            pathname: `/o/${orgID}/integration/whatsapp-sme/auto-message/setup/${match.params.accountID}`,
                            state: {
                                type: type
                            }
                        })
                    }}
                    data={ratingMessageData}
                    isLoading={loadingGetRatingMessage}
                    type="rating"
                    error={errorRating}
                />
                {/* chatbot */}
            </React.Fragment>
          )}
        </div>
      </SectionWrap>

      <PopupRemove
        removeTitle={`Delete Channel?`}
        removeDescription={`This channel will be deleted and your will not be able to recieve and send messages from this channel anymore.`}
        onClickCancel={() => toggleModalRemoveChannel(true)}
        onClickRemove={() => deleteChannel(true)}
        waitForResponse={false}
        submitText={'Delete'}
        showModalProps={showModalRemoveChannel}
      />

      <PopupRemove
        removeTitle={`Reboot Instance?`}
        removeDescription={`Instance will reboot and return to authenticated once done.`}
        onClickCancel={() => toggleModalRebootChannel(true)}
        onClickRemove={() => rebootChannel(true)}
        waitForResponse={waitForResponseRebootChannel}
        submitText={'Reboot'}
        showModalProps={showModalRebootChannel}
        colorButton="red-button"
      />

      <PopupRemove
        removeTitle={`Log Out From Channel?`}
        removeDescription={`You will be logged out from this channel and will have to authenticate again.`}
        onClickCancel={() => toggleModalLogoutChannel(true)}
        onClickRemove={() => logoutChannel(true)}
        waitForResponse={waitForResponseLogoutChannel}
        submitText={'Log Out'}
        showModalProps={showModalLogoutChannel}
      />

      <PopupRemove
        removeTitle={`Disconnect Channel?`}
        removeDescription={`This channel will be disconnected, your customers will not be able to reach you on this channel.`}
        onClickCancel={() => toggleModalDisconnectChannel(true)}
        onClickRemove={() => disconnectChannel(true)}
        waitForResponse={false}
        submitText={'Disconnect'}
        showModalProps={showModalDisconnectChannel}
      />

      <PopupRemove
        removeTitle={`Remove Chatbot?`}
        removeDescription={`This chatbot will be removed from this channel.`}
        onClickCancel={toggleModalRemoveChatbot}
        onClickRemove={removeChatbot}
        waitForResponse={waitForResponseRemoveChatbot}
        showModalProps={isShowModalRemoveChatbot}
        submitText="Delete"
        colorButton="red-button"
      />

      {printModalSecureAuthentication()}
    </div>
  );
};

export default IntegrationWhatsappDetailAccount;
