import React, { useState } from "react";
import "./QuickReply.scss";
import { TabContent, Nav, NavItem } from 'reactstrap';
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import { checkID } from "../../helper/HelperGeneral";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import QuickReplyPersonal from "./personal/QuickReply";
import QuickReplyGeneral from "./general/QuickReply";
// import { connect } from 'react-redux';

const QuickReply = (props) => {
    const QUICK_REPLY = {
        generalQuickReply: {
            id: "generalQuickReply",
            tab: "General Quick Reply",
            component: <QuickReplyGeneral {...props} />,
            isHidden: props.memberRole === "member"
        },
        personalQuickReply: {
            id: "personalQuickReply",
            tab: "Personal Quick Reply",
            component: <QuickReplyPersonal {...props} />
        },
    }

    let [activeTab, setActiveTab] = useState(props.memberRole === "member" ? QUICK_REPLY.personalQuickReply.id : QUICK_REPLY.generalQuickReply.id);
    let { match, history, location } = props;
    let [orgID, setOrgID] = React.useState(false);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const doMount = async () => {
        const paramID = checkID(match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    React.useEffect(() => {
        doMount();
    }, [location.pathname]);

    React.useEffect(() => {
        if(props.history.location.state) {
          if(props.history.location.state.tab) {
            setActiveTab(props.history.location.state.tab);
          }
        }
    }, [props.history])

    return (
        <React.Fragment>
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <div className="quick-reply-page-wrapper">
                    <Nav tabs>
                        {Object.keys(QUICK_REPLY).map((value, index) => {
                            return (
                                !QUICK_REPLY[value].isHidden &&
                                    <NavItem key={`tab-setup-${index}`} className={`${activeTab === QUICK_REPLY[value].id ? 'active-setup-tab' : ''}`}>
                                        <div className="setup-tab-wrapper"
                                            onClick={() => { toggle(QUICK_REPLY[value].id); }}
                                        >
                                            <b>{QUICK_REPLY[value].tab}</b>
                                        </div>
                                    </NavItem>
                            )
                        })}
                    </Nav>

                    <TabContent>
                        {QUICK_REPLY[activeTab].component}
                    </TabContent>
                </div>
            </SectionWrap>
        </React.Fragment>
    )
}

export default QuickReply;