import React from "react";
// import { Modal } from "reactstrap";
// import NewButton from "../reuseableComponent/NewButton/NewButton";
// import Loading from "../reuseableComponent/loading/Loading";
import topicServices from "../../services/newServices/TopicServices";
import { doToast } from "../../helper/HelperGeneral";
import PopupRemove from "../reuseableComponent/popupRemove/PopupRemove";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const clickDelete = async (data, fn) => {
  const { setDelLoading, setPopupDel, reloadTopic, setPopData } = fn;
  const { id, createdTime, orgID } = data;
  
  topicServices.delTopic(id, createdTime, orgID, (response) => {
    let dataResult = response.dataResult;
    setDelLoading(false);
    setPopupDel(false);
    setPopData(false);

    if(dataResult.error.message === "") {
      if(dataResult.data.success) {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
          mixpanel.track(
            "[Action] Delete Topic",
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : orgID.split("-")[0]
            }
          );
        }

        doToast(dataResult.data.message); 
        reloadTopic();
      }else {
        doToast(dataResult.data.message);
      }
    }else {
      doToast(dataResult.error.message, "fail");
    }
  });
};

const TopicDelete = ({ data, isOpen }) => {
  const { setPopupDel, reloadTopic, setPopData } = data;
  const [delLoading, setDelLoading] = React.useState(false);
  // const textLoading = <Loading />;
  // const textSubmit = delLoading ? textLoading : "Delete Topic";

  const fn = { setDelLoading, setPopupDel, reloadTopic, setPopData };

  const fnDelete = async () => {
    setDelLoading(true);
    const waitAPI = setTimeout(() => {
      setDelLoading(false);
      doToast("Failed to delete topic");
    }, 5500);
    await clickDelete(data, fn);
    clearTimeout(waitAPI);
  };

  return (
    <PopupRemove removeTitle={`Delete Topic?`}
                removeDescription={`This topic and its agents will be removed. This action can't be undone.`}
                onClickCancel={() => setPopupDel(false)}
                onClickRemove={fnDelete}
                waitForResponse={delLoading}
                showModalProps={isOpen}
    />

    // <Modal isOpen={isOpen} className="Modal">
    //   <div className="popup-header">Delete Topic?</div>
    //   <div className="popup-body">
    //     <p className="popup-desc">
    //       This topic and its agents will be removed. This action can't be
    //       undone.
    //     </p>
    //     <div className="button-flex">
    //       <NewButton
    //         text={textSubmit}
    //         className="red-button solid"
    //         onClick={fnDelete}
    //         disabled={delLoading}
    //       />
    //       &nbsp; &nbsp;
    //       <NewButton
    //         text="Cancel"
    //         className="no-fill-button"
    //         onClick={() => setPopupDel(false)}
    //       />
    //     </div>
    //   </div>
    // </Modal>
  );
};

export default TopicDelete;
