import React, { useState, useEffect } from 'react';
import './IntegrationGoogleProfileDetailAccount.scss';
import { FiExternalLink } from 'react-icons/fi';
import { NavLink } from "react-router-dom";
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import IntegrationGoogleBusinessProfile from '../../../../services/integration/integrationGoogleBusinessProfile';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import { FiCopy, FiEdit } from 'react-icons/fi';
import statusConnected from '../../../../assets/img/integration/status-connected.svg';
import statusDisconnect from '../../../../assets/img/integration/icon-disconnect.svg';
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import { connect } from 'react-redux';
import iconGoogleButton from "../../../../assets/img/icon-google-button.svg";

let IntegrationGoogleProfileDetailAccount = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [googleAccountDetails, setGoogleAccountDetails] = useState(null);
  // let [isConnected, setIsConnected] = useState(false);
  let [googleApp, setGoogleApp] = useState(false);
  let [googleClient, setGoogleClient] = useState(false);
  let [googleAccessToken, setGoogleAccessToken] = useState(false);
    
  let [loadingRecon, setLoadingRecon] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  
  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if(googleApp) {
      //google script
      if(!document.querySelector("#google-jssdk")) {
        (function(d, s, id){
          var js, fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) {return;}
          js = d.createElement(s); js.id = id;
          js.onload = () => {
              initClient();
          }
          js.src = "https://accounts.google.com/gsi/client";
          fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'google-jssdk'));
      }else {
        initClient();
      }
      
      function initClient() {
        setGoogleClient(window.google.accounts.oauth2.initCodeClient({
            client_id: googleApp.appClientID,
            scope: 'https://www.googleapis.com/auth/business.manage',
            callback: (tokenResponse) => {
                setGoogleAccessToken(tokenResponse.code);
            },
        }));
      }
      
      //google script
    }
  }, [googleApp])

  useEffect(() => {
    if(googleClient) {
      if(googleClient) {
        googleClient.requestCode();
      }
    }
  }, [googleClient])

  useEffect(() => {
    if(googleAccessToken) {
      reconAction(googleAccessToken);
    }
  }, [googleAccessToken])

  let reconAction = () => {
    setLoadingRecon(true);

    let data = {
      id: googleAccountDetails.channel.id,
      appClientID: googleApp.appClientID,
      authorizationCode: googleAccessToken
    };

    IntegrationGoogleBusinessProfile.reconnectAccount(orgID, data, res => {
      let dataRes = res.dataResult;

      if(dataRes.status === 200) {
        if(dataRes.data.success) {
          doToast(dataRes.data.message);
          getGoogleProfileAccountDetail(orgID, match.params.id);
        }else {
          doToast(dataRes.data.message, "fail")
        }
      }else {
        doToast(dataRes.error.message, "fail");
      }

      setLoadingRecon(false);
    })
  }

  let connectTo = () => {
    if(googleClient) {
      googleClient.requestCode();
    }else {
      setLoadingRecon(true);

      IntegrationGoogleBusinessProfile.getGoogleApp(orgID, (response) => {
        let _retriveDataError = {...retriveDataError};
        let dataResult = response.dataResult;
        
        if(response.dataResult.status === 200) {
            setGoogleApp(dataResult.data);
        }else {
            _retriveDataError.message = dataResult.error.message;
            _retriveDataError.code = dataResult.error.code;
            setRetrieveDataError(_retriveDataError);
        }
        
        setLoadingRecon(false);
      });
    }
  }

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getGoogleProfileAccountDetail(paramID, match.params.id);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };
  
  let copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  let getGoogleProfileAccountDetail = (orgID, id) => {
    setIsLoadingGetDetailData(true);
    let data = {
      id: parseInt(id)
    }

    IntegrationGoogleBusinessProfile.getDetail(orgID, data, (response) => {
       let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setGoogleAccountDetails(response.dataResult.data);
      } else {
        setGoogleAccountDetails(null);
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);

        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetailData(false);
    });
  };

  

  let back = () => history.push(`/o/${orgID}/integration/google-business-profile`);

  // let editTopic = (id) => history.push(`/o/${orgID}/integration/messenger/edit-topic/${id}`);
  
  // let goToEditChannelButton = (id) => history.push(`/o/${orgID}/integration/messenger/edit-channel-button/${id}`);

  // let goToEditChannelNameButton = (id) => history.push(`/o/${orgID}/integration/messenger/edit-channel-name/${id}`);

  let renderTopics = () => {
    let text = "";
    
    googleAccountDetails.channel.topics.map((v, i) => {
      text += `
        <div class="topic-list-content">
            <b>${v.name}</b>
        </div>
      `

      return null;
    })

    return text;
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        <div className="sectionWrap integration-main-container integration-google-profile-detail-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading 
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message} 
              />
            </div>
          ) : (
            <>
              <BackButton text="Back to Google Business Profile" onClick={back} />

              <div className="integration-google-profile-detail-account-box-1">
                <div className="integration-google-profile-detail-account-box-1-top">
                  <b>Channel Details</b>

                  {/* <div className="integration-google-profile-detail-account-box-1-top-button"> */}
                    {/* <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "img",
                          src: iconDelete
                        }}
                        className="red-button main-button-40"
                        text="Delete"
                        position="left"
                        onClickAction={() => toggleModalDeleteChannel()}
                    /> */}
                    {/* <ButtonWithLoadingOrIcon 
                      icon={{
                        type: "svg",
                        src: FiEdit
                      }}
                      className="no-fill-button main-button-40"
                      text="Edit Topic"
                      position="left"
                      onClickAction={() => editTopic(googleAccountDetails.channel.id)}
                    /> */}
                  {/* </div> */}
                </div>

                <div className="form-content-wrapper">
                  <label>
                    <b>Channel ID</b>
                  </label>
                  <div className="disabled-form"> 
                    <input type="text"
                      value={googleAccountDetails.channel.stringID}
                      // onClick={copyToClip}
                      id="copy-string-id"
                      readOnly
                      style={{paddingRight: "40px"}}
                    /> 
            
                    <FiCopy 
                      onClick={() => {
                        copyToClip("copy-string-id", "Channel ID copied")
                      }}
                    />
                  </div>
                </div>

                <div className="messenger-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title">Channel Name</b>
                    <p className="topic-val">
                      {googleAccountDetails.channel.channelName}
                    </p>
                  </div>
                </div>

                {googleAccountDetails.channel.topics.length > 0 &&
                  <div className="messenger-detail-topic">
                    <div className="topic-wrapper">
                      <b className="topic-title">Topic</b>
                      <p className="topic-val">
                        <b dangerouslySetInnerHTML={{ __html: renderTopics() }} />
                      </p>
                    </div>

                    <NavLink to={`/o/${orgID}/integration/google-business-profile/detail/${googleAccountDetails.channel.id}/edit-detail`}>
                      <ButtonWithLoadingOrIcon 
                        icon={{
                          type: "svg",
                          src: FiEdit
                        }}
                        className="no-fill-button main-button-40"
                        text="Edit Details"
                        position="left"
                      />
                    </NavLink>
                  </div>
                }

                <div className='separator-line-grey margin-top-24' />

                <div className="form-content-wrapper">
                  <label>
                    <b>Location ID</b>
                  </label>
                  <div className="disabled-form"> 
                    <input type="text"
                      value={googleAccountDetails.channel.gmbLocationID}
                      // onClick={copyToClip}
                      id="copy-loc-id"
                      readOnly
                      style={{paddingRight: "40px"}}
                    /> 
            
                    <FiCopy 
                      onClick={() => {
                        copyToClip("copy-loc-id", "Location ID copied")
                      }}
                    />
                  </div>
                </div>

                <div className="messenger-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title">Location Name</b>
                    <p className="topic-val">
                      {googleAccountDetails.channel.gmbLocationName === "" ? "" : googleAccountDetails.channel.gmbLocationName}
                    </p>
                  </div>
                </div>

                <div className="messenger-detail-topic">
                  <div className="topic-wrapper">
                    <b className="topic-title">Location Address</b>
                    <p className="topic-val">
                      {googleAccountDetails.channel.gmbLocationFullAddress === "" ? "-" : googleAccountDetails.channel.gmbLocationFullAddress}
                    </p>

                    <a href={googleAccountDetails.channel.gmbLocationMapsUri} target="_blank" rel="noopener noreferrer" className='view-in-google-maps'>
                      <FiExternalLink />
                      <b>View in Google Maps</b>
                    </a>
                  </div>
                </div>

                {/* <ButtonWithLoadingOrIcon 
                    icon={{
                      type: "svg",
                      src: FiEdit
                    }}
                    className="no-fill-button main-button-40"
                    text="Edit Topic"
                    position="left"
                    onClickAction={() => editTopic(googleAccountDetails.channel.id)}
                /> */}

                <div className='separator-line-grey margin-top-24' />
              
                <div className="messenger-bot-token-status">
                  <div className="bot-status-wrapper">
                    <b className="bot-status-title">Status</b>
                    <div className="status-wrapper">
                      {googleAccountDetails.channel.status === "active" ?
                        <>
                          <img src={statusConnected} alt="" />
                          <b className="status-text text-green">{googleAccountDetails.channel.statusText}</b>
                        </>
                        :
                        <>
                          <img src={statusDisconnect} alt="" />
                          <b className="status-text text-red">{googleAccountDetails.channel.statusText}</b>
                        </>
                      }

                      {!googleAccountDetails.channel.isConnected &&
                        <>
                          <br />
                          
                           {!loadingRecon ?
                            <ButtonWithLoadingOrIcon
                              position="left"
                              icon={{
                                type: "img",
                                src: iconGoogleButton
                              }}
                              text="Reconnect"
                              onClickAction={connectTo}
                              className="main-button-48 no-fill-black-button"
                            />
                            :
                            <ButtonWithLoadingOrIcon
                              position="left"
                              isLoading
                              loadingColor="gray"
                              text="Reconnect"
                              className="main-button-48 no-fill-black-button"
                            />
                          }
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

let mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

let mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationGoogleProfileDetailAccount);
