var HelperQueryString = {
    parseQuerystring: () => {
        let s = window.location.search;
        console.log(s)
        if (!s) {
          return {};
        } else if (s[0] === "?") {
          s = s.substr(1);
        }
        let pairs = s.split("&");
        let res = {};
        for (var i in pairs) {
          let parts = pairs[i].split("=");
          if (parts.length === 1) {
              parts[1] = true;
          }
          res[parts[0]] = decodeURIComponent(parts[1]);
        }
        return res;
      }
}

export default HelperQueryString;



