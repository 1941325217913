import React from 'react';
import './OverviewTopicReportNoData.scss';
import NoReportFound from "../../../../assets/img/no-report-found.svg";

const OverviewTopicReportNoData = (props) => {
    return (
        <div className="overview-box-wrapper overview-topic-report-wrapper">
            {!props.noTitle &&
                <p className="overview-box-title">
                    <b>Topic Report</b>
                </p>
            }

            <div className="overview-box-no-data">
                <img src={NoReportFound} alt="" />
                <br />
                <p>
                    <b>No report found.</b>
                </p>
            </div>
        </div>
    );
};

export default OverviewTopicReportNoData;
