import BaseService from "../BaseService";
import { API_KEY } from "../../config";
// import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
// import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";

class ServiceProfile {
    postGetProfile(callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            // "Authorization": "Bearer " + HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).accessToken
        };
        
        BaseService.doPostAccessToken(API.GET_PROFILE, {}, headers, callback);
    }

    postEditProfile(data, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            // "Authorization": "Bearer " + HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).accessToken
        };
        
        BaseService.doPostAccessToken(API.EDIT_PROFILE, data, headers, callback);
    }
}

const API = {
    GET_PROFILE: "/account/profile/get",
    EDIT_PROFILE: "/account/profile/edit"
}

export default new ServiceProfile()