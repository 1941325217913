import React, { useState } from 'react';
import './ChatRoomBroadcastHistoryUserInfoTab.css';
// import IconWorld from '../../../../../assets/img/chatroom/user-info-tab/world.svg';
import IconAt from '../../../../../assets/img/chatroom/user-info-tab/at.svg';
import IconPhone from '../../../../../assets/img/chatroom/user-info-tab/phone.svg';
import IconNote from '../../../../../assets/img/chatroom/user-info-tab/note.svg';
import { connect } from 'react-redux';
import { FiCopy } from 'react-icons/fi';
import { doToast } from '../../../../../helper/HelperGeneral';
import HelperInput from '../../../../../helper/HelperInput';
import { Tooltip } from 'reactstrap';
import IconUser from '../../../../../assets/img/chatroom/user-info-tab/user.svg';
import IconHelpCircle from '../../../../../assets/img/chatroom/user-info-tab/icon-help-circle.svg';
import { Scrollbars } from 'react-custom-scrollbars';

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};

const copyToClip = (selector, text) => {
  document.getElementById(selector).select();
  document.execCommand("copy");
  doToast(text);
};

var ChatRoomBroadcastHistoryUserInfoTab = (props) => {
  let [showTooltipAlias, setShowTooltipAlias] = useState(false);
  
  let toggleTooltip = () => {
    setShowTooltipAlias(!showTooltipAlias);
  }

  return (
    <Scrollbars autoHideTimeout={500}
                renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
    >
      <div className="chat-room-user-info-tab-wrapper main-user-info-wrapper">
          <div className="user-info-list-wrapper">
            <label><b>Full Name</b></label>
            <div className="user-info-box-wrapper with-button-copy">
              <img src={IconUser} alt="" />
              {props.broadcastHistoryPopupDataProps.user.fullName}
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label>
              <b>Alias</b>
              <img src={IconHelpCircle} className="tooltip-icon" id="tooltip-alias-on-modal" alt="" />
              <div>
                <Tooltip className="tooltip-content" placement="left" isOpen={showTooltipAlias} target="tooltip-alias-on-modal" toggle={toggleTooltip}>
                  Alias will be added as a prefix to the user’s name e.g Alias (Full Name)
                </Tooltip>
              </div>
            </label>
            <div className="user-info-box-wrapper with-button-copy">
              <img src={IconUser} alt="" />
              {props.broadcastHistoryPopupDataProps.user.alias ? 
                props.broadcastHistoryPopupDataProps.user.alias !== "" ?
                  props.broadcastHistoryPopupDataProps.user.alias
                  :
                  <span className="grey-font">E.g John</span>
                :
                <span className="grey-font">E.g John</span>
              }
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Email Address</b></label>
            <div className="user-info-box-wrapper with-button-copy">
              <img src={IconAt} alt="" />
              {props.broadcastHistoryPopupDataProps.user.email === "" ? 
                "-" 
                :
                <input type="text"
                      value={props.broadcastHistoryPopupDataProps.user.email}
                      // onClick={copyToClip}
                      id="copy-email-detail-modal"
                      readOnly
                /> 
              }
              
              {props.broadcastHistoryPopupDataProps.user.email !== "" &&
                  <div className="user-detail-copy-wrapper" onClick={() => {
                    copyToClip("copy-email-detail-modal", "Email address copied")
                  }}>
                    <FiCopy />
                    <b>Copy</b>
                  </div>
              }
            </div>
          </div>
          
          {props.broadcastHistoryPopupDataProps.user.phone !== "" &&
            <div className="user-info-list-wrapper">
              <label><b>Phone Number</b></label>
              <div className="user-info-box-wrapper with-button-copy">
                <img src={IconPhone} alt="" />

                +
                <input type="text"
                      value={`${HelperInput.phoneBeautify(props.broadcastHistoryPopupDataProps.user.phone)}`}
                      // onClick={copyToClip}
                      id="copy-phone-detail-modal"
                      readOnly
                /> 

                <div className="user-detail-copy-wrapper" onClick={() => {
                      copyToClip("copy-phone-detail-modal", "Phone number copied")
                    }}>
                  <FiCopy />
                  <b>Copy</b>
                </div>
              </div>
            </div>
          }

          {/* <div className="user-info-list-wrapper">
            <label><b>Location</b></label>
            <div className="user-info-box-wrapper">
              <img src="https://storage.googleapis.com/9a3048-taptalk-prd-public/static/flags/ID@2x.png" alt="" className="broadcast-nation-flag" />
              Jakarta, Indonesia
            </div>
          </div> */}

          {/* <div className="user-info-list-wrapper">
            <label><b>IP Address</b></label>
            <div className="user-info-box-wrapper">
              <img src={IconWorld} alt="" />
              192.169.1.1
            </div>
          </div>

          <div className="user-info-list-wrapper">
            <label><b>Tags</b></label>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
            <div className="user-info-tags">
              <b>paid member</b>
            </div>
          </div> */}
          <div className="user-info-list-wrapper">
              <label><b>Remarks for Contact</b></label>
              <div className="user-info-box-wrapper-multiple-line">
                <img src={IconNote} alt="" />
                {props.broadcastHistoryPopupDataProps.user.agentRemark ?
                  props.broadcastHistoryPopupDataProps.user.agentRemark !== "" ?
                    props.broadcastHistoryPopupDataProps.user.agentRemark
                    :
                    <span className="grey-font">Write down anything that may help you or others understand the user better</span>
                  :
                  <span className="grey-font">Write down anything that may help you or others understand the user better</span>
                }
              </div>
          </div>
        </div>
      </Scrollbars>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomBroadcastHistoryUserInfoTab);
