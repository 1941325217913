import React, { Component } from 'react';
import './PopupRemove.css';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

class PopupRemove extends Component {
  constructor(props) {
    super(props);

    this.state = {
      waitForResponseDelete: false,
    };
  }

  toggleModalRemoveMember = () => {
    this.props.onClickCancel();
  };

  onClickRemove = () => {
    this.props.onClickRemove();
  };

  componentWillReceiveProps(newProps) {
    const oldProps = this.props;
    if (oldProps.waitForResponse !== newProps.waitForResponse) {
      this.setState({
        waitForResponseDelete: newProps.waitForResponse,
      });
    }
  }

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.showModalProps}
          toggle={this.toggleModalRemoveMember}
          className={`modal-confirmation-delete ${this.props._className}`}
        >
          <ModalBody>
            <b>{this.props.removeTitle}</b>
            <p>{this.props.removeDescription}</p>
            {this.props.additionalContent ? this.props.additionalContent : ""}

            {this.props.children && this.props.children}
          </ModalBody>

          <ModalFooter className={this.props.withoutBorder ? "modal-footer-without-border" : ""}>
            <ButtonWithLoadingOrIcon 
                className="no-fill-button main-button-48"
                text="Cancel"
                onClickAction={this.toggleModalRemoveMember}
            />

            {this.state.waitForResponseDelete ? (
              <ButtonWithLoadingOrIcon 
                className={`
                  ${this.props.colorButton ? 
                    this.props.colorButton
                    : 
                    `orange-button`
                  } 
                  main-button-48`
                }
                isLoading
                loadingColor={this.props.loadingButtonGray ? "gray" : "white"}
                position="left"
                isDisabled={this.props.loadingButtonGray}
                text={this.props.submitText ? this.props.submitText : 'Remove'}
              />
            ) : (
              <ButtonWithLoadingOrIcon 
                className={`
                  ${this.props.colorButton ? 
                    this.props.colorButton
                    : 
                    `orange-button`
                  } 
                  main-button-48`
                }
                text={this.props.submitText ? this.props.submitText : 'Remove'}
                onClickAction={this.onClickRemove}
                isDisabled={this.props.disabledButtonSubmit}
              />
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PopupRemove;
