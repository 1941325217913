import React, { useEffect, useState } from "react";
import ModalStartConversation from "./modalStartConversation/ModalStartConversation";
import ModalStartConversationWABA from "./modalStartConversation/ModalStartConversationWABA";
import ModalSending from "./modalSending/ModalSending";
import ModalOngoingCase from "./modalOngoingCase/ModalOngoingCase";
import ModalHasOpenCase from "./modalHasOpenCase/ModalHasOpenCase";
import ModalChooseChannel from "./modalChooseChannel/ModalChooseChannel";
import PreviewMessage from "./previewMessage/PreviewMessage";
import ModalIsHideOther from "./modalIsHideOther/ModalIsHideOther";
import { TAPLIVE_MEDIUM } from "../../../../constants/taplive";
// import { taptalk } from '@taptalk.io/web-sdk';

let StartConversation = (props) => {
  let [
    isShowModalStartConversation,
    setIsShowModalStartConversation,
  ] = useState(false);
  let [
    isShowModalStartConversationWABA,
    setIsShowModalStartConversationWABA,
  ] = useState(false);
  let [isShowModalSending, setIsShowModalSending] = useState(false);
  let [isShowModalPreviewMessage, setIsShowModalPreviewMessage] = useState(
    false
  );
  let [isShowModalOnGoingCase, setIsShowModalOngoingCase] = useState(false);
  let [onGoingCaseData, setOnGoingCaseData] = useState(false);
  let [dataPreviewMessage, setDataPreviewMessage] = useState();
  let [type, setType] = useState("sme");
  let [reset, setReset] = useState(false);

  // useEffect(() => {
  //   if(props.history.location.state) {
  //     if(props.history.location.state.startConversation) {
  //       if(props.history.location.state.startConversation.medium === TAPLIVE_MEDIUM.whatsapp) {
  //         setIsShowModalStartConversation(true);
  //       }else {
  //         setIsShowModalStartConversationWABA(true);
  //       }
  //     }
  //   }
  // }, [props.history])

  useEffect(() => {
    let _localnm = localStorage.getItem("nm");

    if(_localnm) {
        let _startConversation = JSON.parse(_localnm);
        let _startConversation2 = {..._startConversation};

        if(_startConversation2) {
          if(_startConversation2.medium === TAPLIVE_MEDIUM.whatsapp) {
            setIsShowModalStartConversation(true);
          }else {
            setIsShowModalStartConversationWABA(true);
          }
        }
    }
  }, [localStorage])

  let toggleModalStartConversation = (type = "sme") => {
    setType(type);
    if (type === "sme")
      setIsShowModalStartConversation(!isShowModalStartConversation);
    else if (type === "waba")
      setIsShowModalStartConversationWABA(!isShowModalStartConversationWABA);
  };

  let toggleModalPreviewMessage = (isShow) => {
    setIsShowModalPreviewMessage(isShow);
  };

  let close = () => {
    toggleModalPreviewMessage(false);
    setReset(!reset)
    setIsShowModalStartConversation(false);
    setIsShowModalStartConversationWABA(false);
  };

  let toggleModalSending = (isShow) => {
    setIsShowModalSending(isShow);
  };

  let toggleModalOngoingCase = () => {
    setIsShowModalOngoingCase(!isShowModalOnGoingCase);
  };

  let runActionCaseIsOnGoing = (caseData) => {
    setOnGoingCaseData(caseData);
    toggleModalOngoingCase();
  };

  let resetDataPreviewMessage = (data = null) => {
    setDataPreviewMessage(data)
  }

  return (
    <>
      <ModalChooseChannel
        isShowModalChooseChannelProps={props.isShowModalStartConversationProps}
        toggleModalChooseChannelProps={props.toggleModalStartConversationProps}
        toggleModalStartConversation={toggleModalStartConversation}
      />

      <ModalStartConversation
        {...props}
        isShowModalStartConversationProps={isShowModalStartConversation}
        toggleModalStartConversationProps={toggleModalStartConversation}
        orgIDProps={props.orgIDProps}
        topicListProps={props.topicListProps}
        runActionCaseIsOnGoingProps={runActionCaseIsOnGoing}
        toggleModalSendingProps={toggleModalSending}
        toggleSwitchMineCaseProps={props.toggleSwitchMineCaseProps}
        onClickRoomListProps={props.onClickRoomListProps}
        parentProps={props.parentProps}
      />
      
      <ModalStartConversationWABA
        {...props}
        isShowModalStartConversationProps={isShowModalStartConversationWABA}
        toggleModalStartConversationProps={toggleModalStartConversation}
        orgIDProps={props.orgIDProps}
        topicListProps={props.topicListProps}
        runActionCaseIsOnGoingProps={runActionCaseIsOnGoing}
        runSetDataPreviewMessage={(data) => setDataPreviewMessage({ ...data })}
        toggleModalPreviewMessageProps={toggleModalPreviewMessage}
        toggleModalSendingProps={toggleModalSending}
        toggleSwitchMineCaseProps={props.toggleSwitchMineCaseProps}
        toggleReset={reset}
        onClickRoomListProps={props.onClickRoomListProps}
        parentProps={props.parentProps}
      />

      <PreviewMessage
        {...props}
        type={type}
        resetDataPreviewMessage={resetDataPreviewMessage}
        isShowModalPreviewMessageProps={isShowModalPreviewMessage}
        dataPreviewMessage={dataPreviewMessage}
        topicListProps={props.topicListProps}
        toggleModalSendingProps={toggleModalSending}
        runActionCaseIsOnGoingProps={runActionCaseIsOnGoing}
        onClickRoomListProps={props.onClickRoomListProps}
        toggleModalStartConversationProps={toggleModalStartConversation}
        toggleModalPreviewMessageProps={toggleModalPreviewMessage}
        closeModal={close}
      />

      <ModalSending
        isShowModalSendingProps={isShowModalSending}
        toggleModalSendingProps={toggleModalSending}
      />

      <ModalOngoingCase
        isShowModalOngoingProps={isShowModalOnGoingCase}
        toggleModalOngoingCaseProps={toggleModalOngoingCase}
        onGoingCaseDataProps={onGoingCaseData}
        onClickRoomListProps={props.onClickRoomListProps}
      />

      <ModalHasOpenCase topicListProps={props.topicListProps} />

      <ModalIsHideOther {...props} />
    </>
  );
};

export default StartConversation;
