// import axios from "axios";
// import getHeaderServices from "./headerServices";
import BaseService from "../BaseService";
import { API_KEY } from "../../config";

// const setUrl = `${process.env.REACT_APP_ENVIRONMENT_API}/organization/member/`;
// const getHeader = getHeaderServices;

const InboxConfigServices = {
  getConfig(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET, {}, headers, callback);  
  },

  setConfig(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET, data, headers, callback);
  },

  getNeedReplyConfig(idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.GET_NEED_REPLY, {}, headers, callback);
  },

  setNeedReplyConfig(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_NEED_REPLY, data, headers, callback);
  },

  setAllowReplyOnAway(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_ALLOW_REPLY_ON_AWAY, data, headers, callback);
  },

  setAllowHandover(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_ALLOW_HANDOVER, data, headers, callback);
  },
};

const API = {
  GET: "/organization/setup/inbox_configs/get",
  SET: "/organization/setup/inbox_configs/set",
  GET_NEED_REPLY: "/organization/setup/inbox_need_reply_indicator/get",
  SET_NEED_REPLY: "/organization/setup/inbox_need_reply_indicator/set",
  SET_ALLOW_REPLY_ON_AWAY: "/organization/setup/inbox_configs/set_allow_reply_on_away",
  SET_ALLOW_HANDOVER: "/organization/setup/inbox_configs/set_allow_handover_case",
}

export default InboxConfigServices;
