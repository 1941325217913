import React, { useState, useEffect } from 'react';
import './AddUserContact.scss'
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from '../../reuseableComponent/BackButton/BackButton';
import { FiUser, FiInfo, FiAlertCircle } from 'react-icons/fi'
import ServiceOrganization from '../../../services/organization/ServiceOrganization'
import { checkID, doToast, scrollToClass } from '../../../helper/HelperGeneral';
import HelperInput from '../../../helper/HelperInput';
import UserContactServices from '../../../services/newServices/UserContactServices'
import PopupRemove from '../../reuseableComponent/popupRemove/PopupRemove';
import BlockingDiscardChanges from '../../reuseableComponent/blockingDiscardChanges/BlockingDiscardChanges'
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";

const AddUserContact = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [phoneFocus, setPhoneFocus] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(false)
  const [errorEmailPhone, setErrorEmailPhone] = useState(false)
  const [isLoadingAddContact, setIsLoadingAddContact] = useState(false)
  // const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [fullname, setFullname] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [alias, setAlias] = useState('')
  const [email, setEmail] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [jobTitle, setJobTitle] = useState('')
  const [fullnameOrigin, setFullnameOrigin] = useState('')
  const [phoneNumberOrigin, setPhoneNumberOrigin] = useState('')
  const [aliasOrigin, setAliasOrigin] = useState('')
  const [emailOrigin, setEmailOrigin] = useState('')
  // const [companyNameOrigin, setCompanyNameOrigin] = useState('')
  // const [jobTitleOrigin, setJobTitleOrigin] = useState('')
  const [isShowModalDiscard, setIsShowModalDiscard] = useState(false)
  const [errorMessageData, setErrorMessageData] = useState({
    fullname: "",
    alias: "",
    company: "",
    job: "",
    email: "",
    phone: ""
  }) 

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  const createContactUser = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(fullname === "") {
      _errorMessageData.fullname = "This field is required";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-fullname" }
    }

    if(fullname.length > 200) {
      _errorMessageData.fullname = "This field is required";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-fullname" }
    }

    if(alias.length > 100) {
      _errorMessageData.alias = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-alias" }
    }

    if(companyName.length > 200) {
      _errorMessageData.company = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-company" }
    }

    if(jobTitle.length > 50) {
      _errorMessageData.job = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-job" }
    }

    if(email.length > 250) {
      _errorMessageData.email = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-email" }
    }

    if(phoneNumber.replaceAll(" ", "").length > 15) {
      _errorMessageData.phone = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-phone" }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      setIsLoadingAddContact(true);
      setCondition(false)
      const dataUser = {
        fullName: fullname,
        alias: alias,
        email: email,
        phone: phoneNumber.replaceAll(" ", ""),
        companyName: companyName,
        jobTitle: jobTitle
      }

      UserContactServices.createContact(orgID, dataUser, (response) => {
        setIsLoadingAddContact(false);

        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message, "fail");
          } else {
            setFullnameOrigin(fullname);
            setEmailOrigin(email);
            setPhoneNumberOrigin(phoneNumber); 
            setAliasOrigin(alias);
            // setCompanyNameOrigin(companyName);
            // setJobTitleOrigin(jobTitle);
            setTimeout(() => {
              history.push(
                `/o/${orgID}/operational/user-contact/${response.dataResult.data.user.userID}`
              );
            }, 0);
            doToast(response.dataResult.data.message);
          }

        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      });
    }
  };

  const back = () =>
    history.push(`/o/${orgID}/operational/user-contact`);

  const [borderPhone, setBorderPhone] = useState('1px solid #dcdcdc')

  const handlePhoneFocus = () => {
    setPhoneFocus(true);
  }

  const handleBlurPhone = () => {
    setPhoneFocus(false);
  }

  useEffect(() => {
    if (phoneFocus) {
      if (errorPhoneNumber || errorEmailPhone) {
        setBorderPhone('1px solid #FF3F57')
      } else {
        setBorderPhone('1px solid #ff7e00')
      }
    } else {
      setBorderPhone('1px solid #dcdcdc')
    }
  }, [phoneFocus, errorPhoneNumber, errorEmailPhone])

  const validateEmail = (e) => {
    // setErrorEmail(true);
    setEmail(e.target.value)
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (e.target.value === '') {
      if (phoneNumber === '') {
        setErrorEmailPhone(true)
        setErrorEmail(false)
      } else { // phoneNumber !== ''
        setErrorEmailPhone(false);
        setErrorEmail(false)
      }
    } else { // e.target.value !== ''
      setErrorEmailPhone(false);
      if (re.test(String(e.target.value).toLowerCase())) {
        setErrorEmail(false)
      } else {
        setErrorEmail(true)
      }
    }

    let _errorMessageData = {...errorMessageData};
    _errorMessageData.email = "";
    setErrorMessageData(_errorMessageData);
  }

  const validatePhoneNumber = (e) => {
    // setErrorPhoneNumber(true);

    if(HelperInput.numberOnly(e.target.value.replaceAll(" ", ""))) {
      setPhoneNumber(HelperInput.phoneBeautify(e.target.value.replaceAll(" ", "")));
    }
    
    const val = /^[0-9]*$/

    if (e.target.value === '' && email === '') {
      setErrorEmailPhone(true)
      setErrorPhoneNumber(false)
    } else {
      setErrorEmailPhone(false)
      if (val.test(e.target.value.replaceAll(" ", ""))) {
        setErrorPhoneNumber(false)
      } else {
        setErrorPhoneNumber(true)
      }
    }

    let _errorMessageData = {...errorMessageData};
    _errorMessageData.phone = "";
    setErrorMessageData(_errorMessageData);
  }

  const handleFullname = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.fullname = "";
    setErrorMessageData(_errorMessageData);
    setFullname(e.target.value)
  }

  const handleAlias = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.alias = "";
    setErrorMessageData(_errorMessageData);
    setAlias(e.target.value)
  }

  const handleCompanyName = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.company = "";
    setErrorMessageData(_errorMessageData);
    setCompanyName(e.target.value)
  }

  const handleJobTitle = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.job = "";
    setErrorMessageData(_errorMessageData);
    setJobTitle(e.target.value)
  }

  // useEffect(() => {
  //   if (fullname === "" || email === "" || phoneNumber === "" || errorEmail || errorPhoneNumber) {
  //     setIsButtonDisabled(true)
  //   } else {
  //     setIsButtonDisabled(false)
  //   }
  // }, [fullname, email, phoneNumber, errorEmail, errorPhoneNumber])

  const toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }

  const discardProgress = () => {
    setIsShowModalDiscard(false)
    setConfirmedNavigation(true)
  }

  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [condition, setCondition] = useState(false)

  const handleOpenModal = (isOpen) => {
    setIsShowModalDiscard(isOpen)
  }

  useEffect(() => {
    if (fullname !== "" || alias !== "" || email !== "" || phoneNumber !== "") {
      setCondition(true)
    } else {
      setCondition(false)
    }
  }, [fullname, alias, email, phoneNumber])

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
      dataOrigin={{name: fullnameOrigin, email: emailOrigin, phone: phoneNumberOrigin, alias: aliasOrigin}}
      dataAfterChange={{name: fullname, email: email, phone: phoneNumber, alias: alias}}
    >
      <BlockingDiscardChanges
        openModal={handleOpenModal}
        condition={condition}
        confirmedNavigation={confirmedNavigation}
      />
      <div className="sectionWrap integration-main-container add-user-contact-container text-left">
        <>
          <BackButton
            text={`Back to User Contacts`}
            onClick={() => back()}
          />
          <div className="add-user-contact-box">
            <div className="add-user-contact-box-top">
              <div className="title-wrapper">
                <FiUser />
                <b>New User</b>
              </div>
              <p>Create a new user by inputting name and phone number.</p>
            </div>
            <div className="add-user-contact-box-form">
              {
                errorEmailPhone &&
                <div className="alert-component alert-email-phone">
                  <FiInfo />
                  <b>
                    Either email address or phone number must be filled
                  </b>
                </div>
              }
              <div className="form-contact-fullname">
                <label className='pos-relative'>
                  <b>Full Name</b>

                  <span className={errorMessageData.fullname === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{fullname.length}/200</span>
                </label>
                <input
                  type="text"
                  value={fullname}
                  placeholder="Type full name here"
                  onChange={handleFullname}
                  disabled={isLoadingAddContact}
                  className={`form-fullname input-fullname ${errorMessageData.fullname !== "" ? "border-red" : ""}`}
                />
              </div>
              {errorMessageData.fullname !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.fullname}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-alias">
                <label className='pos-relative'>
                  <b>Alias<span>(Optional)</span></b>

                  <span className={errorMessageData.alias === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{alias.length}/100</span>
                </label>
                <input
                  type="text"
                  value={alias}
                  onChange={handleAlias}
                  placeholder="Type alias name here"
                  className={`form-alias input-alias ${errorMessageData.alias !== "" ? "border-red" : ""}`}
                  disabled={isLoadingAddContact}
                />
              </div>
              {errorMessageData.alias !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.alias}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-alias">
                <label className='pos-relative'>
                  <b>Company<span>(Optional)</span></b>

                  <span className={errorMessageData.company === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{companyName.length}/200</span>
                </label>
                <input
                  type="text"
                  value={companyName}
                  onChange={handleCompanyName}
                  placeholder="Type company name here"
                  className={`form-alias input-company ${errorMessageData.company !== "" ? "border-red" : ""}`}
                  disabled={isLoadingAddContact}
                />
              </div>
              {errorMessageData.company !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.company}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-alias">
                <label className='pos-relative'>
                  <b>Job Title<span>(Optional)</span></b>

                  <span className={errorMessageData.job === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{jobTitle.length}/50</span>
                </label>
                <input
                  type="text"
                  value={jobTitle}
                  onChange={handleJobTitle}
                  placeholder="Type job title here"
                  className={`form-alias input-alias ${errorMessageData.job !== "" ? "border-red" : ""}`}
                  disabled={isLoadingAddContact}
                />
              </div>
              {errorMessageData.job !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.job}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-email">
                <label className='pos-relative'>
                  <b>Email</b>
                  <span className={errorMessageData.email === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{email.length}/250</span>
                </label>
                <input
                  type="text"
                  value={email}
                  placeholder="Type email address here"
                  className={`form-email ${errorEmail ? 'error-email' : ''} input-email ${errorMessageData.email !== "" ? "border-red" : ""}`}
                  onChange={validateEmail}
                  disabled={isLoadingAddContact}
                />
                {(errorMessageData.phone === "" && errorEmailPhone) &&
                  <div className="warning-error-red">
                    <FiAlertCircle />
                    <b>You cannot leave this field empty if “Phone Number” is also empty</b>
                  </div>
                }
                
                {(errorMessageData.phone === "" && errorEmail) &&
                  <div className="warning-error-red">
                    <FiAlertCircle />
                    <b>Invalid email address</b>
                  </div>
                }
              </div>
              {errorMessageData.email !== "" &&
                <ErrorOrInfoComp
                    text={errorMessageData.email}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
              }

              <div className="form-contact-phonenumber">
                <label className='pos-relative'>
                  <b>Phone Number</b>

                  <span className={errorMessageData.phone === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{phoneNumber.replaceAll(" ", "").length}/15</span>
                </label>
                <div className={`input-phonenumber-container ${isLoadingAddContact ? "grey-box" : ""} ${isLoadingAddContact ? 'phone-disabled' : ''} ${errorMessageData.phone !== "" ? "border-red" : ""}`} style={{ border: borderPhone }}>
                  <label>+</label>
                  <input
                    type="text"
                    value={phoneNumber}
                    placeholder="62 8XXX XXX XXX"
                    className={`form-phonenumber input-phonenumber input-phone`}
                    onFocus={handlePhoneFocus}
                    onBlur={handleBlurPhone}
                    onChange={validatePhoneNumber}
                    disabled={isLoadingAddContact}
                  />
                </div>
                {
                  errorEmailPhone &&
                  <div className="warning-error-red">
                    <FiAlertCircle />
                    <b>You cannot leave this field empty if “Email” is also empty</b>
                  </div>
                }
                {
                  errorPhoneNumber &&
                  <div className="warning-error-red">
                    <FiAlertCircle />
                    <b>Invalid Number</b>
                  </div>
                }
                
                <ErrorOrInfoComp
                    text={errorMessageData.phone === "" ? "Always start the phone number with the country code without the “+” sign e.g. “628XXXXXXXXX”." : errorMessageData.phone}
                    _className={errorMessageData.phone === "" ? "" : "font-red"}
                    icon={<FiInfo />}
                />
              </div>
              {isLoadingAddContact ?
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    className="orange-button main-button-40 add-contact-button"
                    text="Add Contact"
                    position="left"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40 add-contact-button"
                    text="Save Changes"
                    onClickAction={createContactUser}
                    // isDisabled={isButtonDisabled}
                  />
              }
            </div>
            <PopupRemove
              removeTitle={`Discard Changes?`}
              removeDescription={`You have unsaved progress on this page. Are you sure you want to discard your progress?`}
              onClickCancel={toggleModalDiscard}
              onClickRemove={discardProgress}
              // waitForResponse={waitForResponseDiscard}
              showModalProps={isShowModalDiscard}
              submitText="Discard Changes"
              colorButton="red-button"
            />
          </div>
        </>
      </div>
    </SectionWrap >
  );
};

export default AddUserContact;