import React from 'react';
import './CalendarPickerSingle.scss';
import DayPicker from 'react-day-picker';
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import 'react-day-picker/lib/style.css';
import { FiCalendar } from 'react-icons/fi';

const CalendarPickerSingle = (props) => {
    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle} className="single-date-modal-calendar">
            <ModalBody>
                <p className="modal-date-filter-single-title"><b><FiCalendar />Choose Date</b></p>

                <div id="date-filter-singlewrapper" className="custom-date-picker">
                    <DayPicker
                        numberOfMonths={1}
                        disabledDays={props.disabledDays}
                        selectedDays={props.date}
                        onDayClick={(date, {disabled}) => props.handleDateFilterClick(date, {disabled})}
                    />
                </div>
            </ModalBody>
            
            <ModalFooter>
                <button 
                    className="no-fill-button main-button-40 cancel-single-picker" 
                    onClick={props.clearDatePicker}
                >
                    Clear
                </button>
                <button 
                    className="no-fill-button main-button-40" 
                    onClick={props.toggle}
                >
                    Cancel
                </button>

                {!props.date ?
                    <button className="orange-button main-button-40" disabled>
                        Select Dates
                    </button>
                    :
                    <button className="orange-button main-button-40" onClick={() => props.submmitDateFilter()}>
                        Apply Dates
                    </button>
                }
            </ModalFooter>
        </Modal>
    );
};

export default CalendarPickerSingle;
