import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import './IntegrationWhatsappCheckout.scss';
import { connect } from 'react-redux';
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
// import Loading from "../../../reuseableComponent/loading/Loading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiInfo, FiCreditCard, FiDollarSign } from 'react-icons/fi'
import iconVisa from "../../../../assets/img/payment-card/visa-medium.png";
import iconJsb from "../../../../assets/img/payment-card/jcb-medium.png";
import iconMastercard from "../../../../assets/img/payment-card/mastercard-medium.png";
import iconAmex from "../../../../assets/img/payment-card/amex-medium.png";
import WhatsApp32 from "../../../../assets/img/integration/logo-whatsapp-32.svg";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import BillingServices from '../../../../services/newServices/BillingServices';
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import IntegrationWhatsappServices from "../../../../services/integration/IntegrationWhatsappServices";

const CARD_BRAND = {
  visa: {
      label: "Visa",
      img: iconVisa
  },
  mastercard: {
      label: "Mastercard",
      img: iconMastercard
  },
  jcb: {
      label: "JCB",
      img: iconJsb
  },
  american_express: {
      label: "American Express",
      img: iconAmex
  },
}

const IntegrationWhatsappCheckout = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  // const [detailPrice, setDetailPrice] = useState([])
  const [paymentSetting, setPaymentSetting] = useState(null)
  const [isLoadingPaymentSetting, setIsLoadingPaymentSetting] = useState(true)
  const [isLoadingGetPrice, setIsLoadingGetPrice] = useState(true)
  const [isLoadingGetCardDetail, setIsLoadingGetCardDetail] = useState(true)
  const [amountPrice, setAmountPrice] = useState(0);
  const [tierName, setTierName] = useState("");
  const [cardDetail, setCardDetail] = useState(false);
  const [isAnnualy, setIsAnnually] = useState(false);
  let [whatsappPrice, setWhatsappPrice] = useState(false);
  let [isLoadingCreateAccount, setIsLoadingCreateAccount] = useState(false);

  useEffect(() => {
    if (props.history.location.state) {
      if (!props.history.location.state.whatsappAccount) {
        props.history.push(`/o/${props.match.params.orgID}/home`); 
      }

      if(props.history.location.state.message) {
        doToast(props.history.location.state.message);
      }
    }else {
      props.history.push(`/o/${props.match.params.orgID}/home`); 
    }
  }, [props.history]);

  // const goToPaymentSetting = () => {
  //   history.push({
  //     pathname: `/o/${orgID}/organization/billing`,
  //     state: {
  //       whichTab: `paymentSettings`
  //     }
  //   })
  // }

  const back = () => {
    let _whatsappAccount = props.history.location.state.whatsappAccount;
    
    history.push({
      pathname: `/o/${orgID}/integration/whatsapp-sme/add`,
      state: {
        whatsappVal: {
          name: _whatsappAccount.name,
          topic: _whatsappAccount.topic
        }
      }
    })
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if(orgID) {
      getWhatsappPrice(orgID);
      getPaymentSetting(orgID);
    }
  }, [orgID])

  useEffect(() => {
    if(!isLoadingGetPrice) {
      findAmountDue();
    }
  }, [isAnnualy])

  let doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      // getWhatsappAccountDetail(paramID, match.params.accountID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const amountDue = (price) => {
    const monthPrice = price.filter((item) => item.validityType === 'month');

    if (monthPrice.length > 0) {
      const price = monthPrice.reduce((sum, item) => sum + item.prices[0].unitPrice, 0)
      setAmountPrice(price)
    }
  }

  const findAmountDue = () => {
    const priceMonthOrYear = whatsappPrice.filter((item) => item.validityType === (isAnnualy ? 'year' : 'month'));

    if (whatsappPrice.length > 0) {
      const priceResult = priceMonthOrYear.reduce((sum, item) => sum + item.prices[0].unitPrice, 0)
      setAmountPrice(priceResult)
    }
  }

  const getWhatsappPrice = (paramID) => {
    // console.log('orgID', paramID)
    setIsLoadingGetPrice(true)
    IntegrationWhatsappServices.getPrice(paramID,
      (response) => {
        // console.log(response)
        if (response.dataResult.status === 200) {
          // setDetailPrice(response.dataResult.data.skuItems)
          setWhatsappPrice(response.dataResult.data.skuItems);
          amountDue(response.dataResult.data.skuItems);
          setTierName(response.dataResult.data.skuItems[0].name.replace(" (monthly)", "").replace(" (yearly)", ""));
          setIsLoadingGetPrice(false)
        } else {
          doToast(response.dataResult.error.message, 'fail')
        }
      });
  }

  const isYearlyAvailable = (data) => {
    let _dis = false;

    if(data.findIndex(v => v.validityType === "year") !== -1) {
      _dis = true;
    }

    return _dis;
  }

  let convertCardMasking = (cardNumber) => {
    return cardNumber.replace(/X/g, "<div class='masking-dot'></div>");
  }

  const getPaymentSetting = (paramID) => {
    setIsLoadingPaymentSetting(true)

    BillingServices.getPaymentSetting(paramID, (response) => {
      let dataResult = response.dataResult;
      
      if (dataResult.status === 200) {
        setPaymentSetting(dataResult.data)
        setIsLoadingPaymentSetting(false)
        
        if(dataResult.data.isAutomaticPayment) {
          getCardDetail(dataResult.data.primaryCardID)
        }else {
          setIsLoadingGetCardDetail(false);
        }

      } else {
        doToast(dataResult.error.message, 'fail')
      }
    })
  }

  const getCardDetail = (cardID) => {
    setIsLoadingGetCardDetail(true);

    BillingServices.detailCard(match.params.orgID, {cardID: cardID}, {
      onFailCredential: (errorMessage, errorCode) => {
          doToast(errorMessage, "fail")
      },
      onSuccessCredential: (response) => {
          if(response.error.message === "") {
              setCardDetail(response.data.card);
          }else {
              doToast(response.error.message, "fail");
          }

          setIsLoadingGetCardDetail(false);
      }
  })
  }

  const rupiahFormat = (amount) => {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(amount).replace("Rp", "IDR");
  };

  let createWhatsappAccount = () => {
    let _whatsappAccount = props.history.location.state.whatsappAccount;
    let _index = whatsappPrice.findIndex(val => val.validityType === (isAnnualy ? "year" : "month"));

    let data = {
      accountName: _whatsappAccount.name,
      topicID: _whatsappAccount.topic.value,
      skuID: whatsappPrice[_index].id,
      skuString: whatsappPrice[_index].sku,
      currency: whatsappPrice[_index].prices[0].currency,
      unitPrice: whatsappPrice[_index].prices[0].unitPrice,
      cardID: paymentSetting.isAutomaticPayment ? "" : null,
      authenticationID: paymentSetting.isAutomaticPayment ? paymentSetting.primaryCardID : null
    }

    let runCreateAccount = () => {
      setIsLoadingCreateAccount(true);
  
      IntegrationWhatsappServices.create(orgID, data, (response) => {
          let dataRes = response.dataResult;

          if(dataRes.status === 200) {
              doToast(dataRes.data.message);
              if(dataRes.data.success) {
                runGetOrganizationDetail(dataRes.data.redirectTo, dataRes.data.id, dataRes.data.invoiceID)
              }
          }else {
              setIsLoadingCreateAccount(false);
              doToast(dataRes.error.message, "fail"); 
          }
      });
    }

    let runGetOrganizationDetail = (redirectTo, channelID, invoiceID) => {
      let runRedirectTo = (to, channelID, invoiceID) => {
        if(to === "channel") {
          props.history.push(`/o/${orgID}/integration/whatsapp-sme/detail/${channelID}`);
        }else {
          props.history.push(`/o/${orgID}/organization/billing/unpaid-invoices-detail/${invoiceID}`);
        }
      }
  
      ServiceOrganization.postGetOrganizationDetail(orgID, (response) => {  
        if(response.dataResult.status === 200) {
          props.setAllOrganizationDetail(response.dataResult.data);
  
          setTimeout(() => {
            window.history.pushState(null, '');
            runRedirectTo(redirectTo, channelID, invoiceID)
          }, 2000)
        }else {
          doToast(response.dataResult.error.message, "fail")
        }
      })
    }

    if(paymentSetting.isAutomaticPayment) {
      data.topic =  _whatsappAccount.topic;

      props.history.push({
        pathname: `/o/${orgID}/integration/whatsapp-sme/verify`,
        state: {
          whatsappAccount: data,
          cardData: cardDetail,
          paymentSetting: paymentSetting
        }
      })
    }else {
      runCreateAccount();
    }
  };

  let onChangeAnnualy = (e) => {
    if(e.target.checked) {
      setIsAnnually(true);
    }else {
      setIsAnnually(false);
    }
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        {/* <Integrations activeIntegrationChannel="whatsapp" parentProps={props} orgIDProps={orgID} /> */}
        <div className="sectionWrap integration-main-container integration-whatsapp-checkout text-left">
          {
            (isLoadingPaymentSetting || isLoadingGetPrice || isLoadingGetCardDetail)
              ?
              (<div className="integration-detail-loading-wrapper">
                <div className="lds-ring">
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>)
              :
              (
                <>
                  <BackButton text="Back to Add Channel" onClick={back} />

                  <div className="integration-whatsapp-checkout-box">
                    <div className="integration-whatsapp-checkout-box-top">
                      <b>Review Payment</b>

                      {/* {isLoadingCreateAccount ?
                        <button className="orange-button payment-button" >
                          <div className="lds-ring">
                            <div /><div /><div /><div />
                          </div>
                        </button>
                        :
                        <button className="orange-button payment-button" onClick={createWhatsappAccount}>
                          <FiCreditCard />
                          {!paymentSetting.isAutomaticPayment
                            ?
                            <b>Make Payment</b>
                            :
                            <b>Verify Payment</b>
                          }
                        </button>
                      } */}
                    </div>

                    <div className="integration-whatsapp-checkout-box-form">
                      {/* <div className="alert-component alert-blue alert-checkout">
                        <FiInfo />
                        {
                          !paymentSetting.isAutomaticPayment &&
                          <>
                            <b>
                              Your current payment settings is set to manual payments.<br />
                              You have to make manual payment each month in order to continue using this service.
                            </b>
                            <br></br>
                            <button className="btn blue-button" onClick={goToPaymentSetting}>
                              Change to Automatic Payment
                            </button>
                          </>
                        }

                        {
                          paymentSetting.isAutomaticPayment &&
                          <>
                            <b>
                              Your current payment settings is set to automatic payments.<br />
                              We will automatically charge the following amount from your primary card each month.
                            </b>
                            <br></br>
                          </>
                        }

                      </div> */}

                      <div className="primary-card">
                          <div className="card-box">
                            <div className="card-box-frame card-box-frame-whatsapp">
                              <img src={WhatsApp32} alt="" />
                            </div>

                            <div className="card-info">
                              <b className="card-top-info">
                                {tierName} {isAnnualy && "(Annually)"}
                              </b>
                              <p className="card-footer-info">
                                {rupiahFormat(amountPrice)}/{isAnnualy ? "yr" : "mo"}
                              </p>
                            </div>
                          </div>

                          {/* <NavLink to={{
                            pathname: `/o/${orgID}/organization/billing`,
                            state: {
                              whichTab: "paymentSettings"
                            }}}
                            target="_blank"
                          >
                            <button className="light-orange-button">
                              <b>Change Card</b>
                            </button>
                          </NavLink> */}
                          {isYearlyAvailable(whatsappPrice) &&
                            <div className="annualy-selection-wrapper">
                              <b>Annual Payment</b>
                              <label className="custom-switch-wrapper">
                                <input disabled={isLoadingCreateAccount} type="checkbox" onChange={(e) => onChangeAnnualy(e)} />
                                <span className="custom-switch round"></span>
                              </label>
                            </div>
                          }
                      </div>
                      
                      <div className={`primary-card ${!paymentSetting.isAutomaticPayment ? "no-border-bottom" : ""}`}>
                          <div className="card-box">
                            {paymentSetting.isAutomaticPayment ?
                              <>
                                <div className="card-box-frame card-box-frame-auto">
                                  <FiCreditCard />
                                </div>

                                <div className="card-info">
                                  <b className="card-top-info">
                                    Automatic Payments
                                  </b>
                                  <p className="card-footer-info">
                                    Automatically charge the selected primary payment method.
                                  </p>
                                </div>
                              </>
                              :
                              <>
                                <div className="card-box-frame card-box-frame-manual">
                                  <FiDollarSign />
                                </div>

                                <div className="card-info">
                                  <b className="card-top-info">
                                    Manual Payments
                                  </b>
                                  <p className="card-footer-info">
                                    Make payments manually via bank transfers.
                                  </p>
                                </div>
                              </>
                            }
                          </div>

                          <NavLink to={{
                            pathname: `/o/${orgID}/organization/billing`,
                            state: {
                              whichTab: "paymentSettings"
                            }}}
                            // target="_blank"
                          >
                            <ButtonWithLoadingOrIcon 
                                className="light-orange-button main-button-40"
                                text="Change Method"
                            />
                          </NavLink>
                      </div>

                      {paymentSetting.isAutomaticPayment &&
                        <div className="primary-card no-border-bottom">
                          <div className="card-box">
                            <img src={CARD_BRAND[cardDetail.cardBrand].img} alt="" className="cc-image" />
                            <div className="card-info">
                              <b className="card-top-info" dangerouslySetInnerHTML={{__html: convertCardMasking(cardDetail.cardNumberMasked)}} />
                              <p className="card-footer-info">Expires {cardDetail.cardExpiryMonth}/{cardDetail.cardExpiryYear.substr(2, 2)}</p>
                            </div>
                          </div>

                          <NavLink to={{
                            pathname: `/o/${orgID}/organization/billing`,
                            state: {
                              whichTab: "paymentSettings"
                            }}}
                            // target="_blank"
                          >
                            <ButtonWithLoadingOrIcon 
                                className="light-orange-button main-button-40"
                                text="Change Card"
                            />
                          </NavLink>
                        </div>
                      }

                      <div className="amount-due">
                        <b>Total Amount Due:</b>
                        <div className="amount-box">
                          <b>{rupiahFormat(amountPrice)}</b>
                        </div>
                      </div>

                      <div className="alert-component alert-blue alert-checkout">
                        <FiInfo />
                        <b>Please allow up to 24 hours for our team to setup your channel after your payment has been verified.</b>
                        {paymentSetting.isAutomaticPayment ?
                          <b> Your next payment will be auto deducted from the card above once it reaches the due date.</b>
                          :
                          <b> You will need to complete the payment before it reaches the due date once the invoice is generated.</b>
                        }
                      </div>
                      
                      {!paymentSetting.isAutomaticPayment ?
                        (!isLoadingCreateAccount ?
                          <ButtonWithLoadingOrIcon 
                              className="orange-button main-button-40"
                              text="Continue &amp; Make Payment"
                              onClickAction={() => createWhatsappAccount()}
                          />
                          :
                          <ButtonWithLoadingOrIcon 
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            className="orange-button main-button-40"
                            text="Continue &amp; Make Payment"
                            position="left"
                          />
                        )
                        :
                        <ButtonWithLoadingOrIcon 
                            className="orange-button main-button-40"
                            text="Continue &amp; Make Payment"
                            onClickAction={() => createWhatsappAccount()}
                            posïtion="left"
                            icon={{
                              type: "svg",
                              src: FiCreditCard
                            }}
                        />
                      }
                    </div>
                  </div>
                </>
              )
          }
        </div>
      </SectionWrap>
    </div>
  )
};

const mapStateToProps = state => ({
  allOrganizationDetail: state.allOrganizationDetail
});

const mapDispatchToProps = {
  setAllOrganizationDetail
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationWhatsappCheckout);