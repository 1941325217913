import React, { useState } from 'react';
import './MessageDetailsModal.scss';
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import 'react-day-picker/lib/style.css';
import { FiEye, FiFile, FiCopy } from 'react-icons/fi';
import { IoIosClose } from 'react-icons/io';
import { doToast } from '../../../helper/HelperGeneral';

const MessageDetailsModal = (props) => {

    let [isShowModalImage, setIsShowModalImage] = useState(false);

    let toggleModalImage = () => {
        setIsShowModalImage(!isShowModalImage);
    }

    let generateModalImage = () => {
        if (!props.message.contentBody) {
            return;
        }

        let zoomImage = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '200%';
            var offsetX = e.nativeEvent.offsetX;
            var offsetY = e.nativeEvent.offsetY;
            
            var x = offsetX/zoomer.offsetWidth*100
            var y = offsetY/zoomer.offsetHeight*100
            zoomer.style.backgroundPosition = x + '% ' + y + '%';
        }

        let zoomImageOut = (e) => {
            var zoomer = e.currentTarget;
            zoomer.style.backgroundSize = '0%';
        }
        
        return (
            <div>
              <Modal isOpen={isShowModalImage} className={'modal-image'} toggle={toggleModalImage}>
                <ModalBody onClick={() => toggleModalImage()}>
                    <IoIosClose className="close-modal-image" onClick={() => toggleModalImage()} />
                    <div className="image-wrapper">
                        <figure 
                            className="zoom" 
                            style={{"backgroundImage": `url(${props.message.contentBody})`}} 
                            onMouseMove={(e) => zoomImage(e, props.message.contentBody)}
                            onMouseLeave={(e) => zoomImageOut(e)}
                        >
                            <img 
                                src={props.message.contentBody} 
                                alt="" 
                                className="image-preview-val"
                            />
                        </figure>
                    </div>
                </ModalBody>
              </Modal>
            </div>
        );
    }

    let downloadFile = () => {
        if (props.message.contentBody) {
            window.open(props.message.contentBody, "_blank");
        }
    }

    let copyToClip = (textToCopy, toastText) => {
        navigator.clipboard.writeText(textToCopy);
        doToast(toastText);
    };

    return (
        <>
            <Modal isOpen={props.isOpen} toggle={props.toggle} className="modal-message-details">
                <ModalBody>
                    <div className="modal-title">
                        <FiEye className="icon-view" />
                        <h3>View Message</h3>
                    </div>

                    {
                        props.message.trxID &&
                        <>
                            <h5>Trx ID</h5>
                            <div className="text-wrapper copy-button">
                                {props.message.trxID}
                                <FiCopy onClick={() => {
                                    copyToClip(props.message.trxID, "Trx ID copied")
                                }}/>
                            </div>
                        </>
                    }

                    {
                        props.message.templateName &&
                        <>
                            <h5>Template Name</h5>
                            <div className="text-wrapper">
                                {props.message.templateName}
                            </div>
                        </>
                    }

                    {
                        props.message.messageType === "image" &&
                        props.message.contentBody &&
                        <>
                            <h5>Image</h5>
                            <div>
                                <img className="thumbnail" src={props.message.contentBody} alt="Thumbnail" />
                                <div className="thumbnail view-image" onClick={toggleModalImage}>
                                    <FiEye />
                                </div>
                            </div>
                        </>
                    }

                    {
                        props.message.messageType === "file" &&
                        <>
                            <h5>Document</h5>
                            <div 
                                className="document-wrapper"
                                onClick={downloadFile}
                            >
                                <FiFile />
                                {
                                    props.message.contentFilename ?
                                    props.message.contentFilename :
                                    "Document"
                                }
                            </div>
                        </>
                    }

                    {
                        (props.message.contentCaption || 
                        (props.message.messageType === "text" && 
                        props.message.contentBody)) &&
                        <>
                            <h5>Text Message</h5>
                            <div className="text-wrapper body">
                                {
                                    props.message.contentCaption ?
                                    props.message.contentCaption :
                                    props.message.contentBody
                                }
                            </div>
                        </>
                    }

                    {
                        props.message.reason &&
                        <>
                            <h5>Reason</h5>
                            <div className="text-wrapper">
                                {props.message.reason}
                            </div>
                        </>
                    }
                </ModalBody>
                
                <ModalFooter>
                    <button className="orange-button" onClick={() => { props.toggle() }}>
                        Dismiss
                    </button>
                </ModalFooter>
            </Modal>

            {generateModalImage()}
        </>
    );
};

export default MessageDetailsModal;
