import React, { useState, useEffect } from "react";
import "./ModalStartConversation.scss";
import "./ModalStartConversationWABA.scss";
import { connect } from "react-redux";
import Select from "react-select";
import { Scrollbars } from "react-custom-scrollbars";
import { doToast } from "../../../../../helper/HelperGeneral";
import HelperInput from "../../../../../helper/HelperInput";
import { FiX, FiEdit3, FiAlertCircle, FiSearch, FiPlus, FiPhone, FiUser } from "react-icons/fi";
import UserContactServices from "../../../../../services/newServices/UserContactServices";
import IntegrationWhatsappBusinessServices from "../../../../../services/integration/IntegrationWhatsappBusinessServices";
import BroadcastMessageServices from "../../../../../services/newServices/BroadcastMessageServices";
import PhoneCodePicker from "../../../../reuseableComponent/phoneCodePicker/PhoneCodePicker";
import BackButton from "../../../../reuseableComponent/BackButton/BackButton";
import CustomSelectOption from '../../../../reuseableComponent/customSelectOption/CustomSelectOption';
import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { clearStartConversationBa, setStartConversationBa } from "../../../../../redux/actions/reduxActionStartConversationBa";
import { setActiveCasePanel } from "../../../../../redux/actions/reduxActionActiveCasePanel";
import { setToggleMineCase } from "../../../../../redux/actions/reduxActionToggleMineCase";
import Loading from "../../../../reuseableComponent/loading/Loading";
import { TAPLIVE_MEDIUM_LOGO, TAPLIVE_MEDIUM } from "../../../../../constants/taplive";

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-2px",
    width: "5px",
    borderRadius: "8px",
  },
};

const REG_NUMERIC = /^[0-9]*$/;

const ERROR_MESSAGE_NUMBER = {
  length: "The combined country code and phone number must be between 7-15 digits",
  isNumeric: "Only numerics are allowed",
  isStartZero: "Phone number cannot start from 0",
};

const iconDropOrange =
  "url('/image/chevron-up-orange.svg') center / contain no-repeat !important";
const iconDropDark =
  "url('/image/chevron-down-dark.svg') center / contain no-repeat !important";
const iconDropGrey =
  "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

// const MESSAGE_TYPE = {
//   text: "text",
//   image: "image",
//   fileAsset: "file",
//   video: "video",
// };

let ModalStartConversation = (props) => {
  let [isLoadingWhatsappStatus, setIsLoadingWhatsappStatus] = useState(false);
  // let [isLoadingRecipientPhone, setIsLoadingRecipientPhone] = useState(false);
  let [isLoadingMessageTemplate, setIsLoadingMessageTemplate] = useState(false);
  let [isLoadingLanguage, setIsLoadingLanguage] = useState(false);
  let [messageTemplates, setMessageTemplates] = useState([]);
  let [templateLanguages, setTemplateLanguages] = useState([]);
  let [channelList, setChannelList] = useState([]);
  let [conversationData, setConversationData] = useState({
    channel: {
      label: "",
      value: "",
    },
    recipient: {
      country: {
        id: "0",
        countryCodeNumber: "62",
        countryCode: "IDN",
        countryName: "Indonesia",
        countryFlag:
          "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png",
      },
      number: "",
      contact: {
        name: "",
        number: "",
      },
    },
    fileAsset: {
      fileURL: "",
      fileName: "",
      fileData: false,
      caption: "",
    },
    template: {
      value: "",
      label: "",
    },
    language: {
      value: "",
      label: "",
    },
    topic: {
      value: "",
      label: ""
    },
    phoneVal: {
      value: "",
      label: ""
    }
  });
  let [contactSearchVal, setContactSearchVal] = useState("");
  let [temporaryContactList, setTemporaryContactList] = useState({
    search: "",
    list: [],
    time: 0,
  });
  let [contactList, setContactList] = useState([]);

  let [temporaryFileValue, setTemporaryFileValue] = useState({
    fileAsset: {},
    time: false,
  });

  let [errorFieldNumber, setErrorFieldNumber] = useState("");

  let [countryList, setCountryList] = useState(false);
  let [toggleAddPhoneForm, setToggleAddPhoneForm] = useState(false);
  let [phoneNumberList, setPhoneNumberList] = useState([]);
  let [lastPhoneNumberList, setLastPhoneNumberList] = useState({
    time: false,
    list: []
  })
  let [lastLangList, setLastLangList] = useState({
    time: false,
    list: []
  })

  useEffect(() => {
    if(lastPhoneNumberList.time) {
      if(lastPhoneNumberList.list.length === 1) {
        let _conversationData = {...conversationData};
        _conversationData.phoneVal = lastPhoneNumberList.list[0];
        setConversationData(_conversationData);
      }

      setPhoneNumberList(lastPhoneNumberList.list);
    }
  }, [lastPhoneNumberList])

  useEffect(() => {
    if(lastLangList.time) {
      if(lastLangList.list.length === 1) {
        let _conversationData = {...conversationData};
        _conversationData.language = lastLangList.list[0];
        setConversationData(_conversationData);
      }

      setTemplateLanguages(lastLangList.list);
    }
  }, [lastLangList])

  useEffect(() => {
    if (temporaryContactList.search === contactSearchVal) {
      setContactList(temporaryContactList.list);
    }
  }, [temporaryContactList]);

  //   useEffect(() => {
  //     let _conversationData = {...conversationData};
  //     if(props.history.location.state) {
  //         let _startConversation = props.history.location.state.startConversation; 
  //         if(_startConversation) {
  //             _conversationData.recipient = _startConversation.recipient;
  //         }
  //         setConversationData(_conversationData);
  //     }
  // }, [props.history])

  useEffect(() => {
    let _conversationData = {...conversationData};
    let _localnm = localStorage.getItem("nm");

    if(_localnm && props.isShowModalStartConversationProps) {
        let _startConversation = JSON.parse(_localnm);
        if(_startConversation) {
            _conversationData.recipient = _startConversation.recipient;
            _conversationData.isFromContact = true;
        }

        setConversationData(_conversationData);

        setTimeout(() => {
          localStorage.removeItem("nm");
        }, 5000)
    }
  }, [props.isShowModalStartConversationProps])

  useEffect(() => {
    if (temporaryFileValue.time) {
      let _temporaryFileValue = { ...temporaryFileValue };
      let _conversationData = { ...conversationData };

      _conversationData.fileAsset.fileName =
        _temporaryFileValue.fileAsset.fileName;
      _conversationData.fileAsset.fileURL =
        _temporaryFileValue.fileAsset.fileURL;
      _conversationData.fileAsset.fileData =
        _temporaryFileValue.fileAsset.fileData;
      setConversationData(_conversationData);
    }
  }, [temporaryFileValue]);

  useEffect(() => {
    if (props.countryList) {
      let newCountryList = [];

      props.countryList.map((value) => {
        let country = {
          id: value.id,
          countryCodeNumber: value.callingCode,
          countryCode: value.iso3Code,
          countryName: value.commonName,
          countryFlag: value.flagIconURL,
          countryLabel: `${value.commonName} (+${value.callingCode})`,
        };

        newCountryList.push(country);

        return null;
      });

      setCountryList(newCountryList);
    }
  }, [props.countryList]);

  let styleSelectChannel = (isLoading) => ({
    indicatorsContainer: (provide) => ({
      ...provide,
      height: "48px",
    }),
    valueContainer: (provide) => ({
      ...provide,
      height: "100%",
    }),
    clearIndicator: () => ({
      // display: "none"
    }),
    dropdownIndicator: (provided, { isFocused, isDisabled }) => ({
      ...provided,
      background: isDisabled ? iconDropGrey : (isFocused ? iconDropOrange : iconDropDark),
      transition: "0.25s ease-in-out",
      width: "24px",
      height: "24px",
      visibility: isLoading ? "hidden" : "visible",
      padding: "8px 32px 8px 8px"
      // transform: isFocused ? "rotate(-180deg)" : ""
    }),
    container: (provided) => ({
      ...provided,
      width: "100%",
      // zIndex: 9999
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "rgba(25, 25, 25, 0.4)",
    }),
    control: (provide, { isFocused, isDisabled }) => ({
      ...provide,
      padding: "0 5px",
      // height: "48px",
      borderRadius: "8px",
      border: isFocused
        ? "1px solid #ff7e00 !important"
        : "1px solid rgba(25, 25, 25, 0.1) !important",
      boxShadow: "none !important",
      marginBottom: "24px",
      cursor: "pointer",
      backgroundColor: isDisabled ? "rgba(25, 25, 25, 0.1)" : "white",
    }),
    indicatorSeparator: (provide) => ({ ...provide, display: "none" }),
    menu: (provide) => ({
      ...provide,
      borderRadius: "8px",
      boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
      overflow: "hidden",
    }),
    option: (provide, state) => {
      const { isSelected } = state;
      const checked = {
        color: "#ff7e00",
        content: '"✔"',
        position: "absolute",
        left: "0",
        top: "50%",
        transform: "translate(100%, -50%)",
      };
      return {
        ...provide,
        position: "relative",
        background: "#fff",
        padding: "12px 15px 12px 35px",
        cursor: "pointer",
        color: isSelected ? "#ff7e00" : "#121212",
        ":hover": { background: "#ffe7d0" },
        ":before": isSelected ? checked : null,
      };
    },
    input: (baseStyles) => ({
      ...baseStyles,
      color: 'transparent'
      })
  });

  let resetValue = () => {
    setIsLoadingWhatsappStatus(false);
    setConversationData({
      channel: {
        label: "",
        value: "",
      },
      recipient: {
        country: {
          id: "0",
          countryCodeNumber: "62",
          countryCode: "IDN",
          countryName: "Indonesia",
          countryFlag:
            "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png",
        },
        number: "",
        contact: {
          name: "",
          number: "",
        },
      },
      fileAsset: {
        fileURL: "",
        fileName: "",
        fileData: false,
        caption: "",
      },
      template: {
        value: "",
        label: "",
      },
      language: {
        value: "",
        label: "",
      },
      topic: {
        value: "",
        label: ""
      },
      phoneVal: {
        value: "",
        label: ""
      }
    });

    setChannelList([])

    setContactSearchVal("")

    setTemporaryFileValue({
      fileAsset: {},
      time: false,
    });

    setErrorFieldNumber("");
  };

  let getPhoneNumber = (id) => {
    IntegrationWhatsappBusinessServices.getPhoneNumber(props.orgIDProps, {channelID: Number(id)}, (res) => {
        let _phone = [];
        
        if(res.dataResult.data.items) {
          res.dataResult.data.items.map(v => {
              v.label = v.phoneNumber.split("")[0] === "+" ? "+"+HelperInput.phoneBeautify(v.phoneNumber) : "+"+HelperInput.phoneBeautify(v.phoneNumber);
              v.value = v.id;
              _phone.push(v);
  
              return null;
          })
        }
        
        setLastPhoneNumberList({
          time: new Date().valueOf(),
          list: _phone
        })
        // setPhoneNumberList(_phone);
    });
}

  let onChangeConversationMessage = (e) => {
    let _conversationData = { ...conversationData };

    if (e.target.id === "phoneNumber") {
      let val = e.target.value.replaceAll(" ", "");
      if(HelperInput.numberOnly(val)) {
          _conversationData.recipient.number = HelperInput.phoneBeautify(val, true);
          setErrorFieldNumber("");
      }
    } else if (e.target.id === "caption") {
      _conversationData.fileAsset.caption = e.target.value;
    } else {
      _conversationData[e.target.id] = e.target.value;
    }
    setConversationData(_conversationData);
  };

  let onCloseClick = () => {
    if (!isLoadingWhatsappStatus) {
      resetValue();

      if(toggleAddPhoneForm) {
        runToggleAddPhoneForm();
      }

      props.toggleModalStartConversationProps(props.newMessage24h ? props.data : "waba");

      let _history = {...props.history.location.state};
      delete _history.startConversation;

      props.history.replace({
          state: _history
      });
    }
  };

  let onBlurAction = () => {
    let _errorFieldNumber = errorFieldNumber.slice();
    let errorMessage = "";

    let phoneLength = (
      conversationData.recipient.country.countryCodeNumber +
      conversationData.recipient.number
    ).replaceAll(" ", "").length;

    if (conversationData.recipient.number[0] === "0") {
      errorMessage = ERROR_MESSAGE_NUMBER.isStartZero;
    }

    // if (phoneLength > conversationData.recipient.country.countryCodeNumber.length && (phoneLength < 7 || phoneLength > 15)) {
    //   errorMessage = ERROR_MESSAGE_NUMBER.length;
    // }

    if (!REG_NUMERIC.test(conversationData.recipient.number.replaceAll(" ", ""))) {
      errorMessage = ERROR_MESSAGE_NUMBER.isNumeric;
    }

    _errorFieldNumber = errorMessage;
    setErrorFieldNumber(_errorFieldNumber);
  };

  let getChannelList = () => {
    BroadcastMessageServices.getAllBroadcastChannelList(
      props.orgIDProps,
      (response) => {
        let dataResult = response.dataResult;

        if (dataResult.status === 200) {
          let _channelList = [];
          let channels = dataResult.data.whatsappba;

          if (channels.length > 0) {
            channels.map((value) => {
              if(props.topicListProps[value.topicID] || value.topicID === 0) {
                _channelList.push({
                  value: value.id,
                  label: value.accountName,
                  //topicID: value.topicID,
                  topics: value.topics
                });
              }

              return null;
            });

            setChannelList(_channelList);
          }
        } else {
          doToast(dataResult.error.message, "fail");
        }
      }
    );
  };

  let getMessageTemplateList = (channelID) => {
    setIsLoadingMessageTemplate(true)
    let data = {
      channelID: Number(channelID),
    };
    IntegrationWhatsappBusinessServices.getTemplateList(
      props.orgIDProps,
      data,
      (response) => {
        let dataResult = response.dataResult;

        if (dataResult.status === 200) {
          let _templateList = [];
          let templates = dataResult.data.templates;

          if (templates.length > 0) {
            templates = templates.filter((item) => item.isEnabled)
            templates.map((value) => {
              _templateList.push({
                value: value.id,
                label: value.name,
                category: value.categoryCode
              });

              return null;
            });

            setMessageTemplates(_templateList);
          }
        } else {
          doToast(dataResult.error.message, "fail");
        }
        setIsLoadingMessageTemplate(false)
      }
    )
  };

  let getTemplateLanguage = (templateID) => {
    setIsLoadingLanguage(true);

    let data = {
      channelID: Number(conversationData.channel.value),
      templateID: Number(templateID)
    }

    IntegrationWhatsappBusinessServices.getTemplateDetail(
      props.orgIDProps,
      data,
      (response) => {
        let dataResult = response.dataResult;

        if (dataResult.status === 200) {
          let _languageList = [];
          let languages = dataResult.data.languages;
          
          if (languages.length > 0) {
            languages.map((lang, idx) => {
              lang.value = idx;
              lang.label = lang.languageName;
              lang.category = dataResult.data.template.categoryCode;
              _languageList.push(lang);

              return null;
            });
            
            setLastLangList({
              time: new Date().valueOf(),
              list: _languageList
            })
            // setTemplateLanguages(_languageList);
          }
        } else {
          doToast(dataResult.error.message, "fail");
        }
        setIsLoadingLanguage(false)
      }
    )
  };

  let onChangeSelect = (e, id) => {
    let _conversationData = { ...conversationData };
    _conversationData[id] = e;
    setConversationData(_conversationData);

    if(id === "channel") {
      _conversationData.template = {
        value: "",
        label: ""
      };

      _conversationData.language = {
        value: "",
        label: ""
      };

      _conversationData.phoneVal = {
        value: "",
        label: ""
      }

      getMessageTemplateList(_conversationData.channel.value);
      getPhoneNumber(_conversationData.channel.value);
    }
  };

  let onSelectPhone = (val) => {
    let _conversationData = {...conversationData};
    _conversationData.phoneVal = val;
    setConversationData(_conversationData);
  } 

  let onChangeTemplate = (e) => {
    let _conversationData = {...conversationData};
    _conversationData.template = e;
    _conversationData.language = {
      value: "",
      label: "",
    };
    setConversationData(_conversationData);
    getTemplateLanguage(e.value);
  }

  let onChangeCountryCode = (data) => {
    let _conversationData = { ...conversationData };
    _conversationData.recipient.country = data;
    setConversationData(_conversationData);
  };

  // TODO
  let onSubmit = () => {
    setIsLoadingWhatsappStatus(true);
    props.runSetDataPreviewMessage(conversationData)
    props.toggleModalStartConversationProps(props.newMessage24h ? props.data : "waba")
    props.toggleModalPreviewMessageProps(true)
    setIsLoadingWhatsappStatus(false);
  };

  useEffect(() => {
    if (props.isShowModalStartConversationProps) {
      if(props.newMessage24h) {
          if(props.data && props.caseDetailData && props.caseDetailData.case) {
            let _conversationData = conversationData
            conversationData.channel.value = props.caseDetailData.case.mediumChannelID
            conversationData.recipient.contact.number = props.caseDetailData.case.userPhone
            setConversationData(_conversationData)
            let _messageTemplates = [];
            
            props.data.templates.map((item) => {
                _messageTemplates.push({
                    value: item.id,
                    label: item.name,
                    category: item.categoryCode
                })

                return null;
            })
            setMessageTemplates(_messageTemplates)
        }
      } else {
        getChannelList();
      }
    }
  }, [props.isShowModalStartConversationProps]);

  useEffect(() => {
    if(props.toggleReset) {
      resetValue();
    }
  }, [props.toggleReset])


  useEffect(() => {
    if(!props.isShowModalStartConversationProps) {
      resetValue();
    }
  }, [props.isShowModalStartConversationProps])

  // useEffect(() => {
  //     console.log(conversationData)
  // }, [conversationData])

  let isDisabledSubmit = () => {
    let _conversationData = { ...conversationData };
    let isDisabled = false;
    // let phoneLength = (conversationData.recipient.country.countryCodeNumber + conversationData.recipient.number).length;

    if (_conversationData.channel.value === "") {
      isDisabled = true;
    }

    if (_conversationData.recipient.contact.number === "") {
      isDisabled = true;
    }

    if (_conversationData.template.value === "") {
      isDisabled = true;
    }

    if (_conversationData.language.value === "") {
      isDisabled = true;
    }

    if(!props.newMessage24h && _conversationData.topic.value === "") {
      isDisabled = true;
    }

    if(!props.newMessage24h && _conversationData.phoneVal.value === "") {
      isDisabled = true;
    }

    return isDisabled;
  };

  let disabledAddPhoneNumber = () => {
    let isDisabled = false;
    let phoneLength = (
      conversationData.recipient.country.countryCodeNumber +
      conversationData.recipient.number
    ).replaceAll(" ", "").length;

    if (conversationData.recipient.number[0] === "0") {
      isDisabled = true;
    }

    if (phoneLength < 7 || phoneLength > 15) {
      isDisabled = true;
    }

    if (!REG_NUMERIC.test(conversationData.recipient.number.replaceAll(" ", ""))) {
      isDisabled = true;
    }

    return isDisabled;
  };

  let onChangeSearchContact = (e) => {
    runGetContactList(e.target.value);
    setContactSearchVal(e.target.value);
  };

  let runToggleAddPhoneForm = () => {
    setContactSearchVal("");
    setToggleAddPhoneForm(!toggleAddPhoneForm);
  };

  let submitAddPhoneNumber = () => {
    let errorCount = 0;

    if(conversationData.recipient.number === "") {
        setErrorFieldNumber("This field is required");
        errorCount = errorCount + 1;
    }

    if(conversationData.recipient.number.replaceAll(" ", "").length > 15) {
        setErrorFieldNumber("Characters exceed limit");
        errorCount = errorCount + 1;
    }

    if(errorCount === 0 && errorFieldNumber === "") {
      let _conversationData = { ...conversationData };
      _conversationData.recipient.contact = {
        name: "",
        number: `+${_conversationData.recipient.country.countryCodeNumber}${_conversationData.recipient.number.replaceAll(" ", "")}`,
        isFromAdd: true
      };

      setConversationData(_conversationData);
      runToggleAddPhoneForm();
    }
  };

  let clearContactNumber = () => {
    let _conversationData = { ...conversationData };
    _conversationData.recipient.contact = {
      name: "",
      number: "",
    };

    setConversationData(_conversationData);
  };

  let runGetContactList = (search) => {
    // setIsLoadingRecipientPhone(true)
    let data = {
      search: search,
      pageNumber: 1,
      pageSize: 1000,
    };

    UserContactServices.getContactWhatsapp(
      props.orgIDProps,
      data,
      (response) => {
        if (response.dataResult.status === 200) {
          let dataResult = response.dataResult;
          let newData = {
            search: search,
            list: dataResult.data.users,
            time: new Date().valueOf(),
          };

          setTemporaryContactList(newData);
          // setContactList(dataResult.data.users);
        }
        // setIsLoadingRecipientPhone(false)
      }
    );
  };

  let onClickContact = (contact) => {
    let _conversationData = { ...conversationData };
    _conversationData.recipient.contact.name = contact.fullName;
    _conversationData.recipient.contact.number = "+" + contact.phone;
    setContactList([]);
    setContactSearchVal("");
    setConversationData(_conversationData);
  };

  let onSelectTopic = (value) => {
    let _conversationData = {...conversationData};
    _conversationData.topic = value;
    setConversationData(_conversationData);
  };

  let getListTopic = () => {
    let list = [];
    let _conversationData = {...conversationData};
    if(_conversationData.channel.topics) {
        Object.keys(props.topicListProps).map(v => {
            if(_conversationData.channel.topics.findIndex(e => e.id === parseInt(v)) !== -1) {
                list.push(props.topicListProps[v]);
            }

            return null;
        })
    }

    return list;
  }

  return (
    <div
      className={`modal-new-conversation ${
        props.isShowModalStartConversationProps
          ? "active-modal-new-conversation"
          : ""
      } `}
    >
      <div
        className={`modal-body ${
          props.isShowModalStartConversationProps
            ? "action-modal-body-new-conversation"
            : ""
        }`}
      >
        <div onClick={onCloseClick} className="close-modal-start-conversation">
          <FiX />
        </div>

        {!toggleAddPhoneForm ? (
          // main form
          <>
            <div className="new-conversation-wrapper">
              <Scrollbars
                autoHideTimeout={500}
                renderThumbVertical={(props) => (
                  <div {...props} style={style.scrollStyle} />
                )}
              >
                <div className="new-conversation-container">
                  {
                    props.newMessage24h ?
                      <div className="new-templated-message-title">
                        <FiEdit3 />

                        <b>Send Templated Message</b>
                        <p>
                            Select a templated message to send.
                        </p>
                      </div>
                      :
                      <div className="new-conversation-title">
                        <FiEdit3 />

                        <b>New Message</b>
                        <p>
                          Send a new message to a single recipient through one of
                          your active channels.
                        </p>
                      </div>
                  }
                  {
                    props.newMessage24h ?
                      <div
                        className={"form-content-wrapper case-id"}
                      >
                        <label>
                          <b># Case ID</b>
                        </label>
                        <p>{props.caseDetailData && props.caseDetailData.case ? `#${props.caseDetailData.case.stringID}` : ""}</p>
                      </div>
                      :
                      <>
                        <div className="form-content-wrapper">
                            <label><b>Channel Type</b></label>

                            <p className='channel-type'>
                                <img src={TAPLIVE_MEDIUM_LOGO[TAPLIVE_MEDIUM.whatsappba]} alt="" />
                                WhatsApp Business API
                            </p>
                        </div>

                        <div
                          className={
                            "form-content-wrapper" +
                            (isLoadingWhatsappStatus ? " disabled" : "")
                          }
                        >
                          <label>
                            <b>Channel</b>
                          </label>
                        </div>

                        <Select
                          options={channelList}
                          placeholder="Select channel"
                          onChange={(e) => onChangeSelect(e, "channel")}
                          styles={styleSelectChannel()}
                          isDisabled={isLoadingWhatsappStatus}
                          value={channelList.filter(
                            (obj) => obj.value === conversationData.channel.value
                          )}
                          blurInputOnSelect
                        />

                        <div className={"form-content-wrapper"}>
                          <label className="no-margin-top">
                            <b>Phone Number</b>
                          </label>
                        </div>

                        {/* <CustomSelectOption
                            isDisabled={conversationData.channel.value === ""}
                            optionListProps={phoneNumberList}
                            valueDropdownProps={conversationData.phoneVal}
                            placeholderProps={"Select Phone Number"}
                            onClickDropDownListOptionProps={onSelectPhone}
                            specificClassNameProps="custom-select-phonenumber"
                        /> */}

                        <Select
                          options={phoneNumberList}
                          placeholder={
                            conversationData.channel.value === "" ? 
                              "Select channel first"
                              : 
                              "Select phone number"
                          }
                          onChange={(e) => onSelectPhone(e, "channel")}
                          styles={styleSelectChannel()}
                          isDisabled={conversationData.channel.value === ""}
                          value={phoneNumberList.filter(
                            (obj) => obj.value === conversationData.phoneVal.value
                          )}
                          blurInputOnSelect
                          classNamePrefix="select"
                        />
                      </>
                  }

                  { props.newMessage24h ? "" :
                  <div
                    className={
                      "start-conversation-contact-wrapper" +
                      // || isLoadingRecipientPhone
                      (isLoadingWhatsappStatus || conversationData.channel.value === "" ? 
                        " disabled"
                        :
                        ""
                      )
                    }
                  >
                    <div className="form-content-wrapper">
                      <label>
                        <b>Recipient</b>
                      </label>

                      {conversationData.channel.value !== "" &&  conversationData.recipient.contact.number === "" && (
                        <div
                          className="light-orange-button add-by-phone-number"
                          onClick={runToggleAddPhoneForm}
                        >
                          <FiPlus />
                          Add by Phone Number
                        </div>
                      )}
                    </div>
                    
                    <>
                      {conversationData.isFromContact ?
                        <div className='conversation-data-contact-wrapper'>
                            <p>{conversationData.recipient.contact.name}</p>
                        </div>
                        :
                        <>
                          <div className="search-contact-wrapper">
                            {conversationData.recipient.contact.number === "" && (
                              <>
                                <input
                                  type="text"
                                  onChange={(e) => onChangeSearchContact(e)}
                                  value={contactSearchVal}
                                  placeholder={
                                    conversationData.channel.value === ""
                                      ? "Select channel first"
                                      : "Search for a contact"
                                  }
                                  disabled={
                                    conversationData.channel.value === ""
                                    // || isLoadingRecipientPhone
                                  }
                                />
                                {/* {isLoadingRecipientPhone ? (
                                  <Loading color="gray" />
                                ) : ( */}
                                  <FiSearch />
                                {/* )} */}
                              </>
                            )}

                            {contactSearchVal !== "" && (
                              <div className="dropdown-contact-list">
                                {contactList.length === 0 ? (
                                  <div className="option-contact no-contact-found">
                                    No Contact Found
                                  </div>
                                ) : (
                                  contactList.map((value, index) => {
                                    return (
                                      <div
                                        className={`option-contact ${
                                          conversationData.recipient.contact
                                            .number === value.phone
                                            ? "selected-number-active"
                                            : ""
                                        }`}
                                        onClick={() => onClickContact(value)}
                                        key={`contact-${index}`}
                                      >
                                        {value.fullName}
                                        <span>+{HelperInput.phoneBeautify(value.phone)}</span>
                                      </div>
                                    );
                                  })
                                )}
                              </div>
                            )}
                          </div>

                          {conversationData.recipient.contact.number !== "" && (
                            <div className="selected-contact">
                              {conversationData.recipient.contact.isFromAdd ?
                                  <FiPhone />
                                  :
                                  <FiUser />
                              }
                              
                              <b>
                                {conversationData.recipient.contact.name}{" "}
                                {"+"+HelperInput.phoneBeautify(conversationData.recipient.contact.number)}
                              </b>

                              <div
                                className="remove-contact"
                                onClick={() => {
                                  if (!isLoadingWhatsappStatus) clearContactNumber();
                                }}
                              >
                                <FiX />
                              </div>
                            </div>
                          )}
                        </>
                      }
                    </>

                    <div
                      className={
                        "add-phone-number-info" +
                        // || isLoadingRecipientPhone
                        (conversationData.channel.value === "" || isLoadingWhatsappStatus ? 
                          " disabled"
                          : 
                          ""
                        )
                      }
                    >
                      <FiAlertCircle />
                      <b>
                        User contact’s with case history in the selected channel
                        will be displayed.
                      </b>
                    </div>
                  </div>}
                  
                  {!props.newMessage24h &&
                    <div className="form-content-wrapper">
                        <label>
                            <b>Topic</b>
                        </label>

                        <CustomSelectOption
                            optionListProps={getListTopic()}
                            valueDropdownProps={conversationData.topic}
                            placeholderProps={
                              conversationData.channel.value === "" ? 
                              "Select channel first"
                              : 
                              "Select topic"
                            }
                            onClickDropDownListOptionProps={onSelectTopic}
                            specificClassNameProps="custom-select-topic"
                            isDisabled={conversationData.channel.value === ""}
                        />
                    </div>
                  }

                  <div
                    className={
                      "start-conversation-message-template-wrapper" +
                      (conversationData.channel.value === "" ||
                      isLoadingWhatsappStatus ||
                      isLoadingMessageTemplate
                        ? " disabled"
                        : "")
                    }
                  >
                    <div className="form-content-wrapper">
                      <label>
                        <b>Message Template</b>
                      </label>
                    </div>

                    <div
                      className={
                        "search-message-template-wrapper" +
                        (conversationData.channel.value === "" ||
                        isLoadingWhatsappStatus ||
                        isLoadingMessageTemplate
                          ? " disabled"
                          : "")
                      }
                    >
                      <Select
                        options={messageTemplates}
                        placeholder={
                          conversationData.channel.value === ""
                            ? "Select channel first"
                            : "Please select"
                        }
                        onChange={(e) => onChangeTemplate(e)}
                        styles={styleSelectChannel(isLoadingMessageTemplate)}
                        isDisabled={
                          conversationData.channel.value === "" ||
                          isLoadingMessageTemplate ||
                          isLoadingWhatsappStatus
                        }
                        value={messageTemplates.filter(
                          (obj) => obj.value === conversationData.template.value
                        )}
                        blurInputOnSelect
                      />
                      {isLoadingMessageTemplate ? <Loading color="gray" /> : ""}
                    </div>

                    <div className={"new-conversation-field-info"}>
                      <FiAlertCircle />
                      <b>
                        Message templates that has been approved will be
                        displayed.
                      </b>
                    </div>
                  </div>

                  <div
                    className={
                      "start-conversation-language-template-wrapper" +
                      (conversationData.channel.value === "" ||
                      conversationData.template.value === "" ||
                      isLoadingWhatsappStatus ||
                      isLoadingLanguage
                        ? " disabled"
                        : "")
                    }
                  >
                    <div className="form-content-wrapper">
                      <label>
                        <b>Language</b>
                      </label>
                    </div>

                    <div
                      className={
                        "search-language-template-wrapper" +
                        (conversationData.channel.value === "" ||
                        conversationData.template.value === "" ||
                        isLoadingLanguage ||
                        isLoadingWhatsappStatus
                          ? " disabled"
                          : "")
                      }
                    >
                      <Select
                        options={templateLanguages}
                        placeholder={
                          conversationData.template.value === ""
                            ? "Select message template first"
                            : "Please select"
                        }
                        onChange={(e) => onChangeSelect(e, "language")}
                        styles={styleSelectChannel(isLoadingLanguage)}
                        isDisabled={
                          conversationData.channel.value === "" ||
                          conversationData.template.value === "" ||
                          isLoadingLanguage ||
                          isLoadingWhatsappStatus
                        }
                        value={templateLanguages.filter(
                          (obj) => obj.value === conversationData.language.value
                        )}
                        blurInputOnSelect
                      />
                      {isLoadingLanguage ? <Loading color="gray" /> : ""}
                    </div>
                  </div>
                </div>
              </Scrollbars>
            </div>

            <div className="send-message-button">
              {!isLoadingWhatsappStatus ? (
                <ButtonWithLoadingOrIcon
                  onClickAction={onSubmit}
                  isDisabled={isDisabledSubmit()}
                  className="orange-button main-button-48"
                  text="Continue"
                  position="left"
                />
              ) : (
                <ButtonWithLoadingOrIcon
                  isLoading
                  isDisabled
                  loadingColor="gray"
                  className="orange-button main-button-48"
                  text="Continue"
                  position="left"
                />
              )}
            </div>
          </>
        ) : (
          // main form
          // add phone form
          <>
            <div className="new-conversation-wrapper">
              <div className="new-conversation-container">
                <BackButton
                  text="Back to New Message"
                  onClick={runToggleAddPhoneForm}
                />

                <div className="new-conversation-title">
                  <b>Add by Phone Number</b>
                </div>

                <div className="form-content-wrapper">
                  <div className="form-label form-label-phone">
                    <p>
                      <b>Country</b>
                    </p>
                    <p className='pos-relative'>
                      <b>Phone Number</b>

                      <span className={errorFieldNumber === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{conversationData.recipient.number.replaceAll(" ", "").length}/15</span>
                    </p>
                  </div>
                </div>

                <div className="phone-number-form">
                  <PhoneCodePicker
                    countryListProps={countryList}
                    indexPhoneCodePickerProps={0}
                    countryProps={conversationData.recipient.country}
                    onChangeCountryCodeProps={onChangeCountryCode}
                    isMultipleProps={false}
                  />

                  <div className="phone-wrapper">
                    <input
                      type="text"
                      placeholder="ex: 1234 567 890"
                      onChange={(e) => onChangeConversationMessage(e)}
                      value={conversationData.recipient.number}
                      onBlur={() => onBlurAction()}
                      id="phoneNumber"
                      className={errorFieldNumber !== "" ? "border-red" : ""}
                    />

                    {errorFieldNumber !== "" && (
                      <p>
                        <FiAlertCircle />
                        <b>{errorFieldNumber}</b>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="send-message-button">
              <ButtonWithLoadingOrIcon
                // isDisabled={disabledAddPhoneNumber()}
                onClickAction={() => submitAddPhoneNumber()}
                className="orange-button main-button-48"
                text="Add Phone Number"
                position="left"
              />
            </div>
          </>
        )
        // add phone form
        }
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  changeContactInfo: state.changeContactInfo,
  caseSortBy: state.caseSortBy,
  countryList: state.countryList,
  startConversationBa: state.startConversationBa,
  toggleMineCase: state.toggleMineCase,
  caseDetailData: state.caseDetailData,
});

const mapDispatchToProps = {
  setActiveCasePanel,
  setStartConversationBa,
  clearStartConversationBa,
  setToggleMineCase,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalStartConversation);