// import { createStore } from "redux";
// import rootReducer from "../reducers";

// let store = createStore(rootReducer);

// export default store;


import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import rootReducer from "../reducers";

const persistConfig = {
    key: "root",
    storage: storage,
    blacklist: [
        "changeContactInfo",
        "updateCaseLabel",
        "sideMenu",
        "caseSortBy",
        "caseDetailData",
        "countryList",
        "startConversation",
        "startConversationBa",
        "selectedTier",
        "billingWarning",
        "allOrganizationDetail",
        "mediumData",
        "previewImageOrVideo",
        "toggleMineCase",
        "clockState",
        "balanceData",
        "modalStartConversationBalance",
        "globalSearch",
        "isWaitMarkAsJunk",
        "replyMessage",
        "activeCaseActionHistory",
        "showOtherCase",
        "allowReplyOnAway",
        "mediumLastRun",
        "reloadCaseDetail",
        "activeHandoverCase",
        "allTopicList",
        "caseLabelList", 
        "priceList",
        "submitHandoverCase",
        "quickReplyList",
        "activeRoom",
        "draftMessage",
        "activeCasePanel",
        "myTopics",
        "salesTalkInbox",
        "salesTalkProductDetail",
        "salesTalkSelectedProduct",
        "salesTalkProductFilter",
        "salesTalkNotConnect",
        "salesTalkErrorServer",
        "caseListChecked",
        "editContactInfo",
        "forwardMessage",
        "reconnectChatRoom",
        "reconnectRoomList",
        // "needReplyColorIndicator",
        "createGoogle",
        "caseFilter",
        "quickReplyDocument",
        "allowHandover"
    ],
    stateReconciler: autoMergeLevel2 // see "Merge Process" section for details.
};


const pReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(pReducer);
export const persistor = persistStore(store);

export default store;