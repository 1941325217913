import React, { useState, useEffect } from 'react';
import './IntegrationTaptalk.scss';
import TaptalkIcon from '../../../assets/img/integration/logo-taptalk.svg';
// import IconAdd from '../../../assets/img/icon-add.svg';
// import Integrations from "../Integrations";
import SecretKeyServices from '../../../services/newServices/SecretKeyServices';
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap';
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { Link } from 'react-router-dom';
import { FiArrowUpRight, FiPlus } from 'react-icons/fi';
import ServiceOrganization from '../../../services/organization/ServiceOrganization';
import { checkID, doToast } from '../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import iconWeb from '../../../assets/img/integration/launcher/icon-web.svg';
import iconIos from '../../../assets/img/integration/launcher/icon-ios.svg';
import iconAndroid from '../../../assets/img/integration/launcher/icon-android.svg';
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

const IntegrationTaptalk = (props) => {
  // console.log(props);
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingLiveChatAccount, setIsLoadingLiveChatAccount] = useState(
    true
  );
  const [listSecretKey, setListSecretKey] = useState([]);
  // const [liveChatAccountData, setLiveChatAccountData] = useState([]);
  let [showPopupWarning, setShowPopupWarning] = React.useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });

  let toggleShowWarning = () => {
      setShowPopupWarning(!showPopupWarning);
  }

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getChannels(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const getChannels = (orgID) => {
    let _retriveDataError = {...retriveDataError};
    setIsLoadingLiveChatAccount(true);

    SecretKeyServices.get(orgID, (response) => {
      let dataResult = response.dataResult;
      if (response.dataResult.status === 200) {
        // console.log(response.dataResult.data.secretKeys);
        setListSecretKey(response.dataResult.data.secretKeys);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsLoadingLiveChatAccount(false);
    });
  };

  useEffect(() => {
    doMount();
  }, []);
  
  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(orgID) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
            "[Page] Live Chat Integration",
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
}, [orgID])

useEffect(() => {
  if(props.history.location.state) {
    if(props.history.location.state.message) {
      doToast(props.history.location.state.message);
    }
    window.history.pushState(null, '');
  }
}, [props.history])

  const platformDetail = {
    android: {
      img: iconAndroid,
      text: 'Android'
    },
    ios: {
      img: iconIos,
      text: 'iOS'
    },
    web: {
      img: iconWeb,
      text: 'Web'
    }
  };

  let goToAdd = () => {
    // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    // if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
    //   mixpanel.track(
    //     "[Button] Add Channel",
    //     {
    //       channel: "Launcher",
    //       userFullName : myAgentData.account.fullName,
    //       userID : myAgentData.account.id,
    //       organizationID : orgID.split("-")[0]
    //     }  
    //   );
    // }

    if(!props.mediumData) {
        toggleShowWarning();
    }else {
        if(props.mediumData.numberOfItems.maximum !== -1 && props.mediumData.numberOfItems.current >= props.mediumData.numberOfItems.maximum) {
            toggleShowWarning();
        }else {
          history.push(`/o/${orgID}/integration/launcher/add`)             
        }
    }
};

  return (
    <div className="integration-outer-wrapper">
      <PopupWarning 
          isShow={showPopupWarning}
          toggle={toggleShowWarning}
          text="Maximum number of allowed channels has been reached"
      />
      
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {/* <Integrations activeIntegrationMenu="launcher" parentProps={props} orgIDProps={orgID} /> */}
        {/* tier blocking */}
        {retriveDataError.code  === "49901" &&
          <RetrieveDataLoading 
            isLoading={isLoadingLiveChatAccount}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <div className="sectionWrap integration-main-container integration-livechat-list text-left">

          <>
            <div className="integration-list-header">
              <img
                src={TaptalkIcon}
                alt=""
                className="integration-list-header-icon"
              />

              <div className="integration-list-header-text-wrapper">
                <b>Live Chat</b>
                {/* <p>
                To integrate with Telegram,
                <a href="/"> please read the documentation.</a>
              </p> */}
              </div>
{/* 
              <button
                className="no-fill-button integration-list-header-button"
                onClick={goToAdd}
              >
                <img src={IconAdd} alt="" />
                <b>Add Channel</b>
              </button> */}

              {!isLoadingLiveChatAccount &&
                <ButtonWithLoadingOrIcon 
                    onClickAction={goToAdd}
                    className="no-fill-button main-button-40 integration-list-header-button"
                    text="Add Channel"
                    icon={{
                      type: "svg",
                      src: FiPlus
                    }}
                    position="left"
                />
              }
            </div>
            {(isLoadingLiveChatAccount || retriveDataError.code) ? 
              <RetrieveDataLoading 
                isLoading={isLoadingLiveChatAccount}
                errorMessage={retriveDataError.message} 
              /> 
              : 
              listSecretKey.length > 0 ?
                <div className="integration-list-outer-wrapper">
                  {listSecretKey.map((item, index) => {
                    return (
                      <Link
                        key={`livechat-account-${index}`}
                        to={{
                          pathname: `/o/${orgID}/integration/launcher/detail/${item.id}`,
                          state: { detailChannel: item },
                        }}
                        className="link-livechat-account-list"
                      >
                        <div className="integration-list-inner-wrapper">
                          <FiArrowUpRight />
                          <div className="platform-wrapper">
                            <img src={platformDetail[item.platform].img} alt="" />

                            <p className="platform-name">
                              <b>{platformDetail[item.platform].text}</b>
                            </p>
                          </div>

                          <span>{item.identifier}</span>
                        </div>
                      </Link>
                    );
                  })}
                </div> 
              : 
              <div className="integration-list-no-data">
                <b>No Channel</b>
                <p>
                  To add an account, simply click the “+ Add Channel” button
                  on the top right.
                </p>
              </div>
          }
          </>
        </div>
      </SectionWrap>
    </div>
  );
};

const mapStateToProps = state => ({
  mediumData: state.mediumData
});

export default connect(mapStateToProps, null)(IntegrationTaptalk);
