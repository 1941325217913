import React, { useState, useEffect, useRef } from 'react';
import './IntegrationCustomizeLauncher.scss';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'reactstrap';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import iconWeb from '../../../../assets/img/integration/launcher/icon-web.svg';
import iconIos from '../../../../assets/img/integration/launcher/icon-ios.svg';
import iconAndroid from '../../../../assets/img/integration/launcher/icon-android.svg';
import iconCopy from '../../../../assets/img/integration/launcher/icon-copy.svg';
import iconScript from '../../../../assets/img/integration/launcher/icon-script.svg';
import iconCustomize from '../../../../assets/img/integration/launcher/icon-customize.svg';
import iconBrushBlack from '../../../../assets/img/icon-brush-black.svg';
import iconCopyOrange from '../../../../assets/img/integration/launcher/icon-copy-orange.svg';
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import FaqServices from '../../../../services/newServices/FaqServices'
import SecretKeyServices from '../../../../services/newServices/SecretKeyServices';
import { checkID, doToast } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import PopupRemove from '../../../reuseableComponent/popupRemove/PopupRemove';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import IntegrationAutoMessageDetail from "../../integrationAutoMessage/integrationAutoMessageDetail/IntegrationAutoMessageDetail";
import { FiTrash2, FiInfo } from 'react-icons/fi';
import { SketchPicker } from 'react-color';
import LauncherBox from './components/LauncherBox';
import { FiX, FiPlus } from 'react-icons/fi';
import IntegrationTaptalkServices from '../../../../services/integration/IntegrationTaptalkServices';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { monokai } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import mixpanel from "mixpanel-browser";

let IntegrationCustomizeLauncher = (props) => {
  let { match, history } = props;
  let [detailConfig, setDetailConfig] = useState(null);
  let [pathList, setPathList] = useState(false);
  let [isLoadingDetailAccount, setIsLoadingDetailAccount] = useState(true);
  let [isLoadingSaveChanges, setIsLoadingSaveChanges] = useState(false);
  let [isLoadingClearFaq, setIsLoadingClearFaq] = useState(false);
  let [orgID, setOrgID] = useState(false);
  let [isWelcomeTabActive, setIsWelcomeTabActive] = useState(true);
  let [isFormTabActive, setIsFormTabActive] = useState(false);
  let [isChatroomTabActive, setIsChatroomTabActive] = useState(false);
  let [launcherData, setLauncherData] = useState(false);
  let [initialLauncherData, setInitialLauncherData] = useState(false);
  let [addLabelData, setAddLabelData] = useState({
    color: '',
    rgb: ''
  });
  // let [initialColor, setInitialColor] = useState({color: ''});
  let [isShowLabelColorPicker, setIsShowLabelColorPicker] = useState(false);
  let secretKeyRef = useRef(null);
  let [isShowModalDeleteChannel, setIsShowModalDeleteChannel] = useState(false);
  let [waitForResponseDeleteChannel, setWaitForResponseDeleteChannel ] = useState(false);
  let [scriptText, setScriptText] = useState('');
  let colorPickerRef = useRef(null);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  let [tooltipOpen, setTooltipOpen] = useState(false);
  let [showDeletePath, setShowDeletePath] = useState(false);
  let [loadingGetWelcomeMessage, setLoadingGetWelcomeMessage] = useState(true);
  let [welcomeMessageData, setWelcomeMessageData] = useState(false);
  let [loadingGetAwayMessage, setLoadingGetAwayMessage] = useState(false);
  let [awayMessageData, setAwayMessageData] = useState(false);
  let [loadingGetClosingMessage, setLoadingGetClosingMessage] = useState(false);
  let [closingMessageData, setClosingMessageData] = useState(false);
  let [loadingGetRatingMessage, setLoadingGetRatingMessage] = useState(false);
  let [ratingMessageData, setRatingMessageData] = useState(false);
  let [errorWelcome, setErrorWelcome] = useState(false);
  let [errorAway, setErrorAway] = useState(false);
  let [errorClosing, setErrorClosing] = useState(false);
  let [errorRating, setErrorRating] = useState(false);

  let toggleDeletePath = () => {
    setShowDeletePath(!showDeletePath);
  }

  let toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  useEffect(() => {
    doMount();
  }, []);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getFaqList(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    if(pathList) {
      getDetailConfig(orgID, match.params.accountID);
      runGetWelcomeMessage();
      runGetAwayMessage();
      runGetClosingMessage();
      runGetRatingMessage();
    }
  }, [pathList])

  useEffect(() => {
    let onBodyClick = (event) => {
      if (isShowLabelColorPicker) {
        if (!colorPickerRef.current.contains(event.target) && !colorPickerRef.current.previousSibling.contains(event.target)) {
          setIsShowLabelColorPicker(false);
        }
      }
    };

    document.body.addEventListener('click', onBodyClick);

    return () => {
      document.body.removeEventListener('click', onBodyClick);
    };
  }, [isShowLabelColorPicker]);

  let getFaqList = (orgID) => {
    setIsLoadingDetailAccount(true);
    let _retriveDataError = { ...retriveDataError };
    
    FaqServices.getList(orgID, (response) => {
      if (response.dataResult.status === 200) {
        if(response.dataResult.data.paths.length > 0) {
          response.dataResult.data.paths.map(v => {
            v.label = v.title;
            v.value = v.id;   
            return null;
          })
          setPathList(response.dataResult.data.paths);
        }else {
          setPathList([]);
        }
        // setDataFaq(response.dataResult.data.paths);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail');
        setIsLoadingDetailAccount(false);
      }

    });
  };

  let findIndexPath = (id) => {
    let i = 0; 
    i = pathList.findIndex(v => v.id === id);
    return i;
  }

  let getDetailConfig = (orgID, accountID) => {
    setIsLoadingDetailAccount(true);

    IntegrationTaptalkServices.getConfig(orgID, accountID, (response) => {
      let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setDetailConfig(response.dataResult.data.secretKey);
        if (response.dataResult.data.launcherConfigs.hexColor !== '') {
          let _addLabelData = { ...addLabelData };
          _addLabelData.color = response.dataResult.data.launcherConfigs.hexColor.substring(1, 7);
          _addLabelData.rgb = hexToRgb(response.dataResult.data.launcherConfigs.hexColor)
          setAddLabelData(_addLabelData);
          // setInitialColor(_addLabelData);
        }

        response.dataResult.data.launcherConfigs.pathVal = {
          label: response.dataResult.data.launcherConfigs.scfPathID === 0 ? "" : (findIndexPath(response.dataResult.data.launcherConfigs.scfPathID) === -1 ? "" : pathList[findIndexPath(response.dataResult.data.launcherConfigs.scfPathID)].title),
          value: response.dataResult.data.launcherConfigs.scfPathID === 0 ? 0 : response.dataResult.data.launcherConfigs.scfPathID
        }
        
        setLauncherData(response.dataResult.data.launcherConfigs);
        setInitialLauncherData(response.dataResult.data.launcherConfigs);

        if(process.env.REACT_APP_IS_ONPREMISE !== "true") {
          setScriptText(`<!-- Please paste the script inside -->
<script 
  src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v1.0/main.js" 
  async 
  defer 
  onload="onLoadOneTalk()"
>
</script>
  
<script>
  function onLoadOneTalk() {
    TapTalkLive.init("${response.dataResult.data.secretKey.secretKey}");
  }
</script>`);
        }else {
          setScriptText(`<!-- Please paste the script inside -->
<script 
  src="${process.env.REACT_APP_MAIN_LAUNCHER_URL}" 
  async 
  defer 
  onload="onLoadOneTalk()"
  data-storage-baseURL="${process.env.REACT_APP_LAUNCHER_STORAGE_URL}"
>
</script>
  
<script>
  function onLoadOneTalk() {
    setTapliveApiBaseURL("${process.env.REACT_APP_LAUNCHER_VISITOR_API_URL}");
    TapTalkLive.init("${response.dataResult.data.secretKey.secretKey}");
  }
</script>`);
        }


      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingDetailAccount(false);
    });
  };

  let saveConfig = (pathID, isClear = false) => {
    if(isClear) {
      setIsLoadingClearFaq(true);
    }else {
      setIsLoadingSaveChanges(true);
    }

    let hexColor = `#${addLabelData.color === "" ? "ff7e00" : addLabelData.color}`

    IntegrationTaptalkServices.saveConfig(
      orgID,
      match.params.accountID,
      hexColor,
      pathID,
      (response) => {
        setIsLoadingSaveChanges(false);
        setIsLoadingClearFaq(false);
        setShowDeletePath(false);
        if (response.dataResult.status === 200) {
          doToast(response.dataResult.data.message);
          getDetailConfig(orgID, match.params.accountID);
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      }
    );
  };

  let deleteChannel = () => {
    setWaitForResponseDeleteChannel(true);
    let id = detailConfig.id;
    let createdTime = detailConfig.createdTime;
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    SecretKeyServices.delete({ id, createdTime }, orgID, (response) => {
      setWaitForResponseDeleteChannel(false);
      if (response.dataResult.status === 200) {
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Delete Channel",
            {
              channel: detailConfig.platform,
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : orgID.split("-")[0]
            }
          )
        }
        history.push(`/o/${orgID}/integration/launcher`);
        doToast('Secret key removed');
      } else {
        doToast('Failed to delete secret key');
      }
    });
  };

  let back = () => history.push(`/o/${orgID}/integration/launcher`);

  let handleWelcomeTab = () => {
    setIsWelcomeTabActive(true);
    setIsFormTabActive(false);
    setIsChatroomTabActive(false);
  };

  let handleFormTab = () => {
    setIsWelcomeTabActive(false);
    setIsFormTabActive(true);
    setIsChatroomTabActive(false);
  };

  let handleChatroomTab = () => {
    setIsWelcomeTabActive(false);
    setIsFormTabActive(false);
    setIsChatroomTabActive(true);
  };

  let hexToRgb = (hex) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  let changeColorPicker = (color) => {
    let _addLabelData = { ...addLabelData };
    _addLabelData.color = color.hex.substring(1, 7);
    _addLabelData.rgb = hexToRgb(color.hex);
    setAddLabelData(_addLabelData);
  };

  let handleChangeColor = (event) => {
    let val = /^[0-9A-F]{6}$/i.test(event.target.value);
    let _addLabelData = { ...addLabelData };
    _addLabelData.color = event.target.value;
    _addLabelData.rgb = val ? hexToRgb(`#${event.target.value}`) : '';
    setAddLabelData(_addLabelData);
  }

  let toggleColorPicker = () => {
    setIsShowLabelColorPicker(!isShowLabelColorPicker);
  };

  let platformDetail = {
    android: {
      img: iconAndroid,
      text: 'Android'
    },
    ios: {
      img: iconIos,
      text: 'iOS'
    },
    web: {
      img: iconWeb,
      text: 'Web'
    }
  };

  let copyToClipboard = (e) => {
    e.preventDefault();
    secretKeyRef.current.select();
    document.execCommand('copy');
    doToast('Secret key copied!');
  };

  let toggleModalDeleteChannel = () => {
    setIsShowModalDeleteChannel(!isShowModalDeleteChannel);
  };

  // let isDisabledSave = () => {
  //   let dis = true;
  //   let val = /^[0-9A-F]{6}$/i.test(addLabelData.color);
    
  //   if(initialColor.color !== addLabelData.color && val) {
  //     dis = false;
  //   }
    
  //   if(launcherData.pathVal.value !== initialLauncherData.pathVal.value) {
  //     dis = false;
  //   }

  //   return dis;
  // };

  let runGetWelcomeMessage = () => {
    setLoadingGetWelcomeMessage(true);

    IntegrationTaptalkServices.getWelcomeMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setWelcomeMessageData(response.dataResult.data.welcomeMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorWelcome({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetWelcomeMessage(false);
    });
  }

  let runGetAwayMessage = () => {
    setLoadingGetAwayMessage(true);

    IntegrationTaptalkServices.getAwayMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setAwayMessageData(response.dataResult.data.awayMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorAway({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetAwayMessage(false);
    });
  }

  let runGetClosingMessage = () => {
    setLoadingGetClosingMessage(true);

    IntegrationTaptalkServices.getClosingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setClosingMessageData(response.dataResult.data.closingMessage);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorClosing({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetClosingMessage(false);
    });
  }

  let runGetRatingMessage = () => {
    setLoadingGetRatingMessage(true);

    IntegrationTaptalkServices.getRatingMessage(orgID, match.params.accountID, (response) => {
      // let _retriveDataError = {...retriveDataError};
      
      if (response.dataResult.error.message === "") {
        setRatingMessageData(response.dataResult.data.userRating);
      } else {
        // _retriveDataError.message = response.dataResult.error.message;
        // _retriveDataError.code = response.dataResult.error.code;
        // setRetrieveDataError(_retriveDataError);
        setErrorRating({
          errorCode: response.dataResult.error.code,
          errorMessage: response.dataResult.error.message
        });
        // doToast(response.dataResult.error.message, 'fail');
      }

      setLoadingGetRatingMessage(false);
    });
  }
  
  return (
    <div className="integration-outer-wrapper">
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {/* <Integrations activeIntegrationChannel="telegramwhatsapp" parentProps={props} orgIDProps={orgID} /> */}

        <div className="sectionWrap integration-main-container integration-livechat-customize text-left">
          {isLoadingDetailAccount || retriveDataError.code ? (
            <div className="integration-list-loading-wrapper">
              <RetrieveDataLoading 
                isLoading={isLoadingDetailAccount}
                errorMessage={retriveDataError.message} 
              />
            </div>
          ) : (
              <>
                <BackButton text="Back to Live Chat" onClick={back} />

                <div className="integration-livechat-customize-box">
                  <div className="integration-livechat-customize-box-top">
                    <div className="title-wrapper">
                      <img src={platformDetail[detailConfig.platform].img} alt="" />
                      <div className="title-description">
                        <p className="title-customize">
                          <b>{platformDetail[detailConfig.platform].text}</b>
                        </p>
                        <p className="description-customize">
                          {detailConfig.identifier}
                        </p>
                      </div>
                    </div>
                    
                    <ButtonWithLoadingOrIcon 
                        onClickAction={() => {
                          let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                          
                          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                            mixpanel.track(
                              "[Button] Delete Channel",
                              {
                                channel: detailConfig.platform,
                                userFullName : myAgentData.account.fullName,
                                userID : myAgentData.account.id,
                                organizationID : orgID.split("-")[0]
                              }
                            )
                          }
                          toggleModalDeleteChannel()}
                        }
                        className="red-button main-button-40"
                        text="Delete"
                        position="left"
                        icon={{
                          type: "svg",
                          src: FiTrash2
                        }}
                    />
                  </div>

                  <div className="integration-livechat-customize-box-form">
                    <div className="form-secret-key">
                      <label>
                        <b>Secret Key</b>
                      </label>
                      <form>
                        <input
                          type="text"
                          value={detailConfig.secretKey}
                          ref={secretKeyRef}
                          readOnly
                        />
                        <button onClick={copyToClipboard}>
                          <img src={iconCopy} alt="" />
                        </button>
                      </form>
                    </div>
                  </div>
                </div>

                {detailConfig.platform === 'web' ? (
                  <div className="integration-livechat-customize-box">
                    <div className="integration-livechat-customize-box-top">
                      <div className="title-wrapper">
                        <img src={iconScript} alt="" />
                        <div className="title-description">
                          <p className="title-customize">
                            <b>Script</b>
                          </p>
                          <p className="description-customize">
                            For more details,{' '}
                            <a
                              href="https://docs.taptalk.io/onetalk-omnichannel-documentation/onetalk-web/get-started"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              please read the documentation
                          </a>
                          </p>
                        </div>
                      </div>
                      <CopyToClipboard
                        text={scriptText}
                        onCopy={() => doToast('Script copied!')}
                      >
                        <button className="main-reuseable-button no-fill-button main-button-40 copy-script-button">
                          <img src={iconCopyOrange} alt="" />
                          <b className="button-text">Copy Script</b>
                        </button>
                      </CopyToClipboard>
                    </div>

                    <div className="integration-livechat-customize-box-form">
                      <div className="alert-component alert-blue alert-edit-token">
                        <FiInfo />
                        <b className="text-alert">
                          Please add this snippet code on your web page inside
                          &lt;head&gt; tag to enable Live Chat
                      </b>
                      </div>
                      <div className="taptalk-snippet">
                        <SyntaxHighlighter
                          language="javascript"
                          style={monokai}
                          showLineNumbers
                        >
                          {scriptText}
                        </SyntaxHighlighter>
                      </div>
                    </div>
                  </div>
                ) : 
                  ''
                }

                {detailConfig.platform === 'web' ? (
                  <div className="integration-livechat-customize-box">
                    <div className="integration-livechat-customize-box-top">
                      <div className="title-wrapper">
                        <img src={iconCustomize} alt="" />
                        <div className="title-description">
                          <p className="title-customize">
                            <b>Customize your Launcher</b>
                          </p>
                          <p className="description-customize">
                            Incorporate your own branding, change the way your
                            launcher look and feel.
                          </p>
                        </div>
                      </div>
                      {!isLoadingSaveChanges ? (
                        <ButtonWithLoadingOrIcon 
                            onClickAction={() => saveConfig(launcherData.pathVal.value)}
                            // isDisabled={isDisabledSave()}
                            className="orange-button main-button-40"
                            text="Save Changes"
                            position="left"
                        />
                      ) : (
                          <ButtonWithLoadingOrIcon 
                              isLoading
                              loadingColor="gray"
                              isDisabled
                              className="orange-button main-button-40"
                              text="Save Changes"
                              position="left"
                          />
                        )}
                    </div>
                    <div className="integration-livechat-customize-box-form">
                      <div className="form-customize">
                        <div className="input-customize">
                          <div className="input-color">
                            <p>
                              <b>Brand Color</b>
                            </p>
                            <p>
                              Used for launcher button, background, action buttons
                              and chat bubbles.
                            </p>
                            <div className={`color-input-wrapper ${isLoadingSaveChanges ? "grey-box" : ""}`}>
                              <div
                                className="color-input-container"
                                onClick={() => {
                                  if(!isLoadingSaveChanges) {
                                    toggleColorPicker();
                                  }
                                }}
                              >
                                <>
                                  {addLabelData.color === '' ?
                                    <img src={iconBrushBlack} alt="" className="brush-icon" />
                                    :
                                    <div className="color-value" style={{ background: `#${addLabelData.color}` }} />
                                  }
                                  <label className="color-tag">#</label>
                                  <input disabled={isLoadingSaveChanges} className="color-input" type="text" onChange={handleChangeColor} value={addLabelData.color === "" ? "ff7e00" : addLabelData.color} placeholder="Select Color" maxLength="6" />
                                </>
                              </div>

                              {isShowLabelColorPicker && (
                                <div className="color-picker-container" ref={colorPickerRef}>
                                  <FiX
                                    onClick={toggleColorPicker}
                                    className="close-color-picker"
                                  />
                                  <SketchPicker
                                    color={`#${addLabelData.color}`}
                                    onChange={changeColorPicker}
                                  />
                                </div>
                              )}
                            </div>

                            <div className="form-content-wrapper select-faq-wrapper">
                              <label>
                                <b>QnA Path</b>
                                <FiInfo href="#" id="tooltip-scf" />
                                <Tooltip placement="right" isOpen={tooltipOpen} target="tooltip-scf" toggle={toggleTooltip}>
                                  QnA Path can be used to show FAQ (Frequently Asked Questions) in live chat or as static conversation bot in other channels.
                                </Tooltip>

                                {(initialLauncherData && initialLauncherData.scfPathID !== 0 &&
                                  <b className="button-clear-scf-path" onClick={toggleDeletePath}>Clear Selected Path</b>
                                )}
                              </label>
                              <div>
                                {pathList.length !== 0 ?
                                  <CustomSelectOption 
                                    // isDisabled={isLoadingFilterAgent}
                                    optionListProps={pathList}
                                    valueDropdownProps={launcherData.pathVal}
                                    placeholderProps={"Select Path"}
                                    onClickDropDownListOptionProps={(e) => {
                                      let _launcherData = {...launcherData};
                                      _launcherData.pathVal = e;
                                      setLauncherData(_launcherData);
                                    }}
                                    specificClassNameProps="custom-select-path-option"
                                    isDisabled={isLoadingSaveChanges}
                                  />
                                  :
                                  <a href={`/o/${orgID}/setup/qna`} target="_blank" rel="noopener noreferrer" className="create-faq-button">
                                    <FiPlus /><b>Create QnA Path</b>
                                  </a>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="preview-customize">
                          <div className="open-case-switch-wrapper">
                            <div className="open-case-switch-content">
                              <button
                                className={`open-case-switch-panel ${isWelcomeTabActive ? 'active-case-panel' : ''
                                  }`}
                                value="welcome"
                                onClick={handleWelcomeTab}
                              >
                                Welcome
                          </button>
                              <button
                                className={`open-case-switch-panel ${isFormTabActive ? 'active-case-panel' : ''
                                  }`}
                                value="form"
                                onClick={handleFormTab}
                              >
                                Form
                          </button>
                              <button
                                className={`open-case-switch-panel ${isChatroomTabActive ? 'active-case-panel' : ''
                                  }`}
                                value="chatroom"
                                onClick={handleChatroomTab}
                              >
                                Chatroom
                          </button>
                            </div>
                          </div>
                          <LauncherBox
                            welcomeTab={isWelcomeTabActive}
                            formTab={isFormTabActive}
                            chatroomTab={isChatroomTabActive}
                            brandColorHex={`#${addLabelData.color}`}
                            rgbColorBrand={addLabelData.rgb}
                          />
                          <div
                            className="taplive2-chat-widget-opener-button"
                            style={{ background: `#${addLabelData.color}` }}
                          >
                            <img
                              src="https://storage.googleapis.com/f8a350-taplive-prd-public/static/launcher/web/v0.0/image/icon-close.svg"
                              alt=""
                              id="taplive2-chat-opener-icon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>)
                  : ''
                }

                {detailConfig.platform !== 'web' ? 
                  <div className="integration-livechat-customize-box">
                    <div className="integration-livechat-customize-box-top">
                      <div className="title-wrapper">
                        <div className="title-description">
                          <p className="title-customize">
                            <b>FAQ</b>
                          </p>

                          <p className="description-customize">
                            Setup your FAQ
                          </p>
                        </div>
                      </div>
                      
                      {!isLoadingSaveChanges ?
                        <ButtonWithLoadingOrIcon 
                            onClickAction={() => saveConfig(launcherData.pathVal.value)}
                            // isDisabled={isDisabledSave()}
                            className="orange-button main-button-40"
                            text="Save Changes"
                            position="left"
                        />
                        :
                        <ButtonWithLoadingOrIcon 
                            isLoading
                            loadingColor="gray"
                            isDisabled
                            className="orange-button main-button-40"
                            text="Save Changes"
                            position="left"
                        />
                      }
                    </div>
                    
                    <div className="form-content-wrapper select-faq-wrapper">
                      <label>
                        <b>QnA Path</b>
                        <FiInfo href="#" id="tooltip-scf" />
                        <Tooltip placement="right" isOpen={tooltipOpen} target="tooltip-scf" toggle={toggleTooltip}>
                          QnA Path can be used to show FAQ (Frequently Asked Questions) in live chat or as static conversation bot in other channels.
                        </Tooltip>

                        {(initialLauncherData && initialLauncherData.scfPathID !== 0 &&
                          <b className="button-clear-scf-path" onClick={toggleDeletePath}>Clear Selected Path</b>
                        )}
                      </label>
                      <div>
                        {pathList.length !== 0 ?
                          <CustomSelectOption 
                            // isDisabled={isLoadingFilterAgent}
                            optionListProps={pathList}
                            valueDropdownProps={launcherData.pathVal}
                            placeholderProps={"Select Path"}
                            onClickDropDownListOptionProps={(e) => {
                              let _launcherData = {...launcherData};
                              _launcherData.pathVal = e;
                              setLauncherData(_launcherData);
                            }}
                            specificClassNameProps="custom-select-path-option"
                            isDisabled={isLoadingSaveChanges}
                          />
                          :
                          <a href={`/o/${orgID}/setup/qna`} target="_blank" rel="noopener noreferrer" className="create-faq-button">
                            <FiPlus /><b>Create QnA Path</b>
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                  : 
                  ''
                }

                <IntegrationAutoMessageDetail 
                    withMarginTop
                    onClickEdit={(type) => {
                        props.history.push({
                            pathname: `/o/${orgID}/integration/launcher/auto-message/setup/${match.params.accountID}`,
                            state: {
                                type: type
                            }
                        })
                    }}
                    data={welcomeMessageData}
                    isLoading={loadingGetWelcomeMessage}
                    type="welcome"
                    error={errorWelcome}
                />

                <IntegrationAutoMessageDetail 
                    withMarginTop
                    onClickEdit={(type) => {
                        props.history.push({
                            pathname: `/o/${orgID}/integration/launcher/auto-message/setup/${match.params.accountID}`,
                            state: {
                                type: type
                            }
                        })
                    }}
                    data={awayMessageData}
                    isLoading={loadingGetAwayMessage}
                    type="away"
                    error={errorAway}
                />

                <IntegrationAutoMessageDetail 
                    withMarginTop
                    onClickEdit={(type) => {
                        props.history.push({
                            pathname: `/o/${orgID}/integration/launcher/auto-message/setup/${match.params.accountID}`,
                            state: {
                                type: type
                            }
                        })
                    }}
                    data={closingMessageData}
                    isLoading={loadingGetClosingMessage}
                    type="closing"
                    error={errorClosing}
                />

                <IntegrationAutoMessageDetail 
                    withMarginTop
                    onClickEdit={(type) => {
                        props.history.push({
                            pathname: `/o/${orgID}/integration/launcher/auto-message/setup/${match.params.accountID}`,
                            state: {
                                type: type
                            }
                        })
                    }}
                    data={ratingMessageData}
                    isLoading={loadingGetRatingMessage}
                    type="rating"
                    error={errorRating}
                />
              </>
            )}
        </div>

        <PopupRemove
          removeTitle={`Delete Secret Key?`}
          removeDescription={`Are you sure you want to delete this secret key?`}
          onClickCancel={toggleModalDeleteChannel}
          onClickRemove={deleteChannel}
          waitForResponse={waitForResponseDeleteChannel}
          showModalProps={isShowModalDeleteChannel}
          submitText="Delete"
          colorButton="red-button"
        />

        <PopupRemove
          removeTitle={`Clear Selected Path?`}
          removeDescription={`Are you sure you want to clear selected path?`}
          onClickCancel={toggleDeletePath}
          onClickRemove={() => saveConfig(0, true)}
          waitForResponse={isLoadingClearFaq}
          showModalProps={showDeletePath}
          submitText="Clear"
          colorButton="red-button"
        />
      </SectionWrap>
    </div>
  );
};

export default IntegrationCustomizeLauncher;
