import React from "react";
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { FiArrowLeft } from "react-icons/fi";
// import { Scrollbars } from 'react-custom-scrollbars'; 

let SubMenuIntegration = (props) => {
    // const style = {
    //     scrollStyle: {
    //       position: "relative",
    //       backgroundColor: "rgb(199,98,0)",
    //       right: "-5px",
    //       borderRadius: "8px",
    //       width: "4px"
    //     }
    // };

    return (
        // Object.props.keys(props.sideMenu.submenuList).map((props.value, props.key) => {
        //     return (
        <>
            {(props._isActiveChannels && props._key === 1) &&
                <>
                    <p className="back-to-main-menu-sidemenu" onClick={props.onClickBackToMainMenu}>
                        <FiArrowLeft />
                        <b>Back to Main Menu</b>
                    </p>

                    <p className="channels-text-side-menu">
                        <b>CHANNELS</b>
                    </p>
                </>
            }

            <NavLink to={props.sideMenu.submenuList[props.value].pathName} key={`sub-menu-${props.key}`} onClick={() => props.onClickSubMenu(props.sideMenu.submenuList[props.value].activeValue)}>
                <div className={`sub-menu-content integration-submenu 
                            ${props.checkActiveSubmenu(props.sideMenu.submenuList[props.value].subPath) ? "active-sub-menu" : ""}
                            ${props.findMediumCounter(props.value) !== 0 ? "sub-menu-overflow" : ""}
                        `}
                >
                    <div className={`sub-menu-icon  ${props.value}`}></div>
                    <b>{props.sideMenu.submenuList[props.value].name}</b>

                    {/* medium count */}
                    {(
                        props.sideMenu.activeMenuBlock === "integration" &&
                        props.findMediumCounter(props.value) !== 0
                    ) &&
                        <div className="medium-counter">
                            {props.findMediumCounter(props.value)}
                        </div>
                    }
                    {/* medium count */}
                </div>
            </NavLink>
        </>
        // })
    )
}

const mapStateToProps = state => ({
    sideMenu: state.sideMenu,
});
  
const mapDispatchToProps = {

};

export default connect(mapStateToProps, mapDispatchToProps)(SubMenuIntegration);