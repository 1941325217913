import React, { Component } from 'react';
import { Scrollbars } from 'react-custom-scrollbars'; 
import { NavLink } from 'react-router-dom';
import "./Sidemenu.scss";
import Helper from '../../../helper/HelperChat';

import { setParentMenuBlockActive } from '../../../redux/actions/reduxActionSideMenu';
import { setBalanceData } from "../../../redux/actions/reduxActionBalance";
import { setLogout } from "../../../redux/actions/reduxLogout";
import { connect } from 'react-redux';
// import ServiceOrganization from "../../../services/sorganization/ServiceOrganization";
import ServiceAuthAccessToken from "../../../services/auth/ServiceAuthAccessToken";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { taptalk } from '@taptalk.io/web-sdk';
import { IoIosCheckmark, IoIosAdd } from 'react-icons/io'; //IoIosArrowForward
import iconHomeNew from '../../../assets/img/sidemenu-icon/new/home.svg';
import iconOrganizationNew from '../../../assets/img/sidemenu-icon/new/organization.svg';
// import iconTechnicalNew from '../../../assets/img/sidemenu-icon/new/technical.svg';
import iconIntegrationNew from '../../../assets/img/sidemenu-icon/new/integration.svg';
import iconInboxNew from '../../../assets/img/sidemenu-icon/new/inbox.svg';
// import iconQuickReplyNew from '../../../assets/img/sidemenu-icon/new/quick-reply.svg';
import iconOperational from '../../../assets/img/sidemenu-icon/new/operational.svg';
import iconSwitchOrganization from '../../../assets/img/sidemenu-icon/new/switch-organization.svg';
import iconSetup from '../../../assets/img/sidemenu-icon/new/setup.svg';
import iconReport from '../../../assets/img/sidemenu-icon/new/report.svg';
import mixpanel from "mixpanel-browser";
import SubMenuIntegration from './SubmenuIntegration';
// import { numberWithCommas, rupiahFormat } from '../../../helper/HelperGeneral';

const ROLE_CODE = {
  owner: 'owner',
  member: 'member',
  admin: 'admin',
  all: 'all'
}

const style = {
  menuArrow: {
    position: "absolute",
    right: "16px",
    top: "50%",
    transform: "translate(0, -50%)"
  },
  borderBottom: {
    borderBottom: "solid 1px #e87200"
  },
  scrollStyle: {
    position: "relative",
    backgroundColor: "rgb(199,98,0)",
    right: "-5px",
    borderRadius: "8px",
    width: "4px"
  }
};

class Sidemenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isWaitForResponseLogout: false,
      showPopupOrganization: false,
      userData: null,
      submenuList: null,
      isActiveChannels: false
    }
  }

  componentDidMount() {
    // let initTaplive = () => {  
    //   var elTapliveScript = document.createElement('script')
    //   elTapliveScript.id="script-taplive"
    //   elTapliveScript.type = "text/javascript"
    //   elTapliveScript.onload = () => {
    //       window.TapTalkLive.init(process.env.REACT_APP_TAPLIVE_KEY)
    //   }
    //   elTapliveScript.src= process.env.REACT_APP_TAPLIVE_URL
    //   elTapliveScript.async = true
    //   elTapliveScript.defer = true
    //   var elTapliveScriptPosition = document.getElementsByTagName('script')[0]
    //   elTapliveScriptPosition.parentNode.insertBefore(elTapliveScript, elTapliveScriptPosition)
    // }

    // initTaplive();

    let bodyClick = document.querySelector('body');

    let _this = this;

    bodyClick.addEventListener('click', function () {
      _this.setState({
        showPopupOrganization: false
      })
    });

    this.setState({
      userData: { ...HelperCustomEncryptor.doDecrypt(this.props.user.data) }
    })
  }

  componentDidUpdate ({ location: prevLocation }) {
    const { location: { pathname } } = this.props

    if(pathname.includes("/integration")) {
      if(
          !pathname.includes("/integration/channels") && 
          !pathname.includes("/integration/api-keys") && 
          !pathname.includes("/integration/salestalk") && 
          !pathname.includes("/integration/chatbots") && 
          !this.state.isActiveChannels
        ) {
        this.setState({
          isActiveChannels: true
        })
      }

      if(pathname.includes("/integration/channels") && this.state.isActiveChannels) {
        this.setState({
          isActiveChannels: false
        })
      }
    }
  }

  isActiveOrganizationCheck = (orgID) => {
    if (this.props.activeOrgID) {
      let matchParam = this.props.activeOrgID;

      return matchParam.split("-")[0] === orgID.toString();
    }
  }

  initiateAccordionMenu(menu) {
    this.props.setParentMenuBlockActive({
      activeMenuBlock: this.props.sideMenu.activeMenuBlock === menu ? "" : menu
    });
  }
  
  runLogoutAction = () => {
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
      mixpanel.reset();
    }
    
    this.setState({
      isWaitForResponseLogout: true
    });
    
    let userData = HelperGetLocalStorage.getLocalStorageData("user");
    
    if (userData) {
      if (taptalk.isAuthenticated()) {
        taptalk.logoutAndClearAllTapTalkData({
          onSuccess: (response) => {
            console.log(response)
          }
        });
      }
      
      let accessToken = HelperCustomEncryptor.doDecrypt(userData.data).accessToken;
      ServiceAuthAccessToken.postLogout(accessToken).then(response => {
        if (response.dataResult.error.code !== "") {
          this.setState({
            isWaitForResponseLogout: false
          });
        } else {
          console.log(response.dataResult.error.message);
        }
      });
      
      this.props.setLogout();
      
      setTimeout(() => {
        window.location.href = window.location.origin + "/";
      }, 250);
    } else {
      window.location.href = window.location.origin + "/";
    }
  }
  
  togglePopupOrganization = () => {
    this.setState({
      showPopupOrganization: this.state.showPopupOrganization ? false : true
    })
  }
  
  onClickOrganizationList = (organization) => {
    setTimeout(() => {
      window.location.href = `/o/${organization.id}-${organization.createdTime}/home`;
    }, 100);
  }
  
  findMemberRole = () => {
    let user = this.state.userData;
    if (this.props.activeOrgID) {
      let organizationID = this.props.activeOrgID.split("-")[0];
      
      let findOrganization = () => {
        let _findIndex = user.memberships.findIndex(value => value.organization.id === parseInt(organizationID));
        
        return _findIndex;
      }
      
      if (user.memberships.length === 0) {
        return ROLE_CODE.all;
      } else {
        return findOrganization() !== -1 ? user.memberships[findOrganization()].roleCode : ROLE_CODE.all
      }
    }
  }
  
  runGetBalance = () => {
    // ServiceOrganization.postGetBalance(this.props.activeOrgID, (response) => {
    //   if(response.dataResult.error.message === "") {
    //     let responseData = response.dataResult.data.balances;
    //     this.props.setBalanceData(responseData)
        
    //   }else {
    //     console.log(response.dataResult.error.message);
    //   }
    // })
    return null;
  }

  runBackToMainMenuIntegration = () => {
    this.props.history.push(`/o/${this.props.activeOrgID}/integration/channels`);
  }

  checkActiveSubmenu = (subPath) => {
    let _active = false;

    _active = window.location.pathname.includes(subPath);

    return _active;
  }
  
  render() {
    let sideMenuList = {
      home: {
        label: "Home",
        icon: iconHomeNew,
        newTab: false,
        pathName: `/o/${this.props.activeOrgID}/home`,
        activeValue: "home",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        dontPrintOnLoop: false,
        isWaitTierDetail: false
      },
      integration: {
        label: "Integration",
        icon: iconIntegrationNew,
        newTab: false,
        activeValue: "integration",
        role: [ROLE_CODE.admin, ROLE_CODE.owner],
        // pathName: `/o/${this.props.activeOrgID}/integration/launcher`,
        subMenu: {
          channels: {
            name: "Channels",
            activeValue: "channels",
            pathName: `/o/${this.props.activeOrgID}/integration/channels`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/channels",
            isChannel: false
          },
          launcher: {
            name: "Live Chat",
            activeValue: "launcher",
            pathName: `/o/${this.props.activeOrgID}/integration/launcher`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/launcher",
            isChannel: true
          },
          whatsapp: {
            name: "WhatsApp for SME",
            activeValue: "whatsapp",
            pathName: `/o/${this.props.activeOrgID}/integration/whatsapp-sme`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/whatsapp-sme",
            isChannel: true
          },
          whatsappba: {
            name: "WhatsApp Business API",
            activeValue: "whatsappba",
            pathName: `/o/${this.props.activeOrgID}/integration/whatsapp-business-api`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/whatsapp-business-api",
            isChannel: true
          },
          facebook: {
            name: "Facebook Messenger",
            activeValue: "facebook",
            pathName: `/o/${this.props.activeOrgID}/integration/messenger`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/messenger",
            isChannel: true
          },
          instagram: {
            name: "Instagram DM",
            activeValue: "instagram",
            pathName: `/o/${this.props.activeOrgID}/integration/instagram`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/instagram",
            isChannel: true
          },
          telegram: {
            name: "Telegram",
            activeValue: "telegram",
            pathName: `/o/${this.props.activeOrgID}/integration/telegram`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/telegram",
            isChannel: true
          },
          google_business: {
            name: "Google Business Messages",
            activeValue: "google_business",
            pathName: `/o/${this.props.activeOrgID}/integration/google-business-messages`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/google-business-messages",
            isChannel: true
          },
          gb_profile: {
            name: "Google Business Profile",
            activeValue: "gb_profile",
            pathName: `/o/${this.props.activeOrgID}/integration/google-business-profile`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/google-business-profile",
            isChannel: true
          },
          tokopedia: {
            name: "Tokopedia",
            activeValue: "tokopedia",
            pathName: `/o/${this.props.activeOrgID}/integration/tokopedia`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/tokopedia",
            isChannel: true
          },
          // twitter: {
          //   name: "Twitter DM",
          //   activeValue: "twitter",
          //   pathName: `/o/${this.props.activeOrgID}/integration/twitter`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner],
          //   subPath: "/integration/twitter",
          //   isChannel: true
          // },
          // line: {
          //   name: "LINE",
          //   activeValue: "line",
          //   pathName: `/o/${this.props.activeOrgID}/integration/line`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner],
          //   subPath: "/integration/line",
          //   isChannel: true
          // },
          apiKeys: {
            name: "API Keys",
            activeValue: "apiKeys",
            pathName: `/o/${this.props.activeOrgID}/integration/api-keys`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/api-keys",
            isChannel: false
          },
          salestalk: {
            name: "SalesTalk",
            activeValue: "salestalk",
            pathName: `/o/${this.props.activeOrgID}/integration/salestalk`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/salestalk",
            isChannel: false
          },
          chatbots: {
            name: "Chatbots",
            activeValue: "chatbots",
            pathName: `/o/${this.props.activeOrgID}/integration/chatbots`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/integration/chatbots",
            isChannel: false
          },
          // webhook: {
          //   name: "Webhook",
          //   activeValue: "webhook",
          //   pathName: `/o/${this.props.activeOrgID}/integration/webhook`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner],
          //   subPath: "/integration/webhook"
          // }
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      // quickReply: {
      //   label: "Quick Reply",
      //   newTab: false,
      //   activeValue: "quick-reply",
      //   role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
      //   icon: iconQuickReplyNew,
      //   subMenu: {
      //     generalQuickReply: {
      //       name: "General Quick Replies",
      //       activeValue: "general",
      //       pathName: `/o/${this.props.activeOrgID}/quick-reply/general`,
      //       role: [ROLE_CODE.admin, ROLE_CODE.owner],
      //     },
      //     personalQuickReply: {
      //       name: "Personal Quick Replies",
      //       activeValue: "personal",
      //       pathName: `/o/${this.props.activeOrgID}/quick-reply/personal`,
      //       role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
      //     }
      //   },
      //   dontPrintOnLoop: false,
      //   isWaitTierDetail: true
      // },
      // technical: {
      //   label: "Technical",
      //   icon: iconTechnicalNew,
      //   newTab: false,
      //   activeValue: "technical",
      //   role: [ROLE_CODE.admin, ROLE_CODE.owner],
      //   subMenu: {
      //       secretKeys: {
      //         name: "Secret Keys",
      //         activeValue: "secret-keys",
      //         pathName: `/o/${this.props.activeOrgID}/technical/secret-keys`,
      //         role: [ROLE_CODE.admin, ROLE_CODE.owner],
      //       },
      //       // pushNotification: {
      //       //   name: "Push Notifications",
      //       //   activeValue: "push-notifications",
      //       //   pathName: `/o/${this.props.activeOrgID}/technical/secret-keys`
      //       // }
      //   },
      //   dontPrintOnLoop: false,
      //   isWaitTierDetail: true
      // },
      operational: {
        label: 'Operational',
        newTab: false,
        activeValue: 'operational',
        role: [ROLE_CODE.admin, ROLE_CODE.owner],
        icon: iconOperational,
        subMenu: {
          broadcastMessage: {
            name: 'Broadcast Message',
            activeValue: 'broadcast-message',
            pathName: `/o/${this.props.activeOrgID}/operational/broadcast-message`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/operational/broadcast-message"
          },
          userContact: {
            name: 'Contacts',
            activeValue: 'user-contacts',
            pathName: `/o/${this.props.activeOrgID}/operational/user-contact`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/operational/user-contact"
          },
          userSegment: {
            name: 'Contact Segments',
            activeValue: 'user-segments',
            pathName: `/o/${this.props.activeOrgID}/operational/user-segment`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/operational/user-segment"
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      organization: {
        label: "Organization",
        icon: iconOrganizationNew,
        newTab: false,
        activeValue: "organization",
        role: [ROLE_CODE.admin, ROLE_CODE.owner],
        subMenu: {
          // profile: {
          //   name: "Profile",
          //   activeValue: "profile",
          //   pathName: `/o/${this.props.activeOrgID}/organization/profile`
          // },
          members: {
            name: "Members",
            activeValue: "members",
            pathName: `/o/${this.props.activeOrgID}/organization/members`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/organization/members"
          },
          // setup: {
          //   name: "Setup",
          //   activeValue: "setup",
          //   pathName: `/o/${this.props.activeOrgID}/organization/setup`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner]
          // },
          topics: {
            name: "Topics",
            activeValue: "topics",
            pathName: `/o/${this.props.activeOrgID}/organization/topics`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/organization/topics"
          },
          labels: {
            name: "Case Labels",
            activeValue: "labels",
            pathName: `/o/${this.props.activeOrgID}/organization/labels`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/organization/labels"
          },
          billing: {
            name: "Billing",
            activeValue: "billing",
            pathName: `/o/${this.props.activeOrgID}/organization/billing`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            ignoreTier: true,
            subPath: "/organization/billing"
          },
          organizationDetails: {
            name: "Organization Details",
            activeValue: "organizationDetails",
            pathName: `/o/${this.props.activeOrgID}/organization/details`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            ignoreTier: true,
            subPath: "/organization/details"
          },
          // topup: {
          //   name: "Balance & Top Up",
          //   activeValue: "topup",
          //   pathName: `/o/${this.props.activeOrgID}/organization/top-up`,
          //   role: [ROLE_CODE.admin, ROLE_CODE.owner],
          //   ignoreTier: true
          // }
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      setup: {
        label: "Setup",
        icon: iconSetup,
        newTab: false,
        activeValue: "setup",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        subMenu: {
          quickReply: {
            name: "Quick Reply",
            activeValue: "quick-reply",
            pathName: `/o/${this.props.activeOrgID}/setup/quick-reply`,
            role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
            subPath: "/setup/quick-reply"
          },
          officeHour: {
            name: "Office Hours",
            activeValue: "office-hour",
            pathName: `/o/${this.props.activeOrgID}/setup/office-hour`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/setup/office-hour"
          },
          welcomeMessage: {
            name: "Welcome Message",
            activeValue: "welcome-message",
            pathName: `/o/${this.props.activeOrgID}/setup/welcome-message`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/setup/welcome-message"
          },
          awayMessage: {
            name: "Away Message",
            activeValue: "away-message",
            pathName: `/o/${this.props.activeOrgID}/setup/away-message`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/setup/away-message"
          },
          closingMessage: {
            name: "Closing Message",
            activeValue: "closing-message",
            pathName: `/o/${this.props.activeOrgID}/setup/closing-message`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/setup/closing-message"
          },
          userRating: {
            name: "User Rating",
            activeValue: "user-rating",
            pathName: `/o/${this.props.activeOrgID}/setup/user-rating`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            ignoreTier: true,
            subPath: "/setup/user-rating"
          },
          assignmentRules: {
            name: "Assignment Rules",
            activeValue: "assignment-rules",
            pathName: `/o/${this.props.activeOrgID}/setup/assignment-rules`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            ignoreTier: true,
            subPath: "/setup/assignment-rules"
          },
          inboxSetting: {
            name: "Inbox Settings",
            activeValue: "inbox-settings",
            pathName: `/o/${this.props.activeOrgID}/setup/inbox-settings`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            ignoreTier: true,
            subPath: "/setup/inbox-settings"
          },
          faq: {
            name: "QnA Path",
            activeValue: "faq",
            pathName: `/o/${this.props.activeOrgID}/setup/qna`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            ignoreTier: true,
            subPath: "/setup/qna"
          },
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      report: {
        label: "Report",
        icon: iconReport,
        newTab: false,
        activeValue: "report",
        role: [ROLE_CODE.admin, ROLE_CODE.owner],
        subMenu: {
          overview: {
            name: "Overview",
            activeValue: "overview",
            pathName: `/o/${this.props.activeOrgID}/report/overview`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/report/overview"
          },
          caseReport: {
            name: "Case Report",
            activeValue: "case-report",
            pathName: `/o/${this.props.activeOrgID}/report/case-report`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/report/case-report"
          },
          caseRatings: {
            name: "Case Ratings",
            activeValue: "case-ratings",
            pathName: `/o/${this.props.activeOrgID}/report/case-ratings`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/report/case-ratings"
          },
          agentReport: {
            name: "Agent Report",
            activeValue: "agent-report",
            pathName: `/o/${this.props.activeOrgID}/report/agent-report`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/report/agent-report"
          },
          topicReport: {
            name: "Topic Report",
            activeValue: "topic-report",
            pathName: `/o/${this.props.activeOrgID}/report/topic-report`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/report/topic-report"
          },
          messageApiHistory: {
            name: "Message API History",
            activeValue: "message-api-history",
            pathName: `/o/${this.props.activeOrgID}/report/message-api-history`,
            role: [ROLE_CODE.admin, ROLE_CODE.owner],
            subPath: "/report/message-api-history"
          }
        },
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      inbox: {
        label: "Inbox",
        pathName: `/o/${this.props.activeOrgID}/inbox`,
        newTab: process.env.REACT_APP_ENV === "prod",
        // newTab: false,
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        icon: iconInboxNew,
        dontPrintOnLoop: false,
        isWaitTierDetail: true
      },
      account: {
        label: "Account",
        newTab: false,
        activeValue: "account",
        role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
        icon: "",
        subMenu: {
          profile: {
            name: "Profile",
            activeValue: "profile",
            pathName: `/o/${this.props.activeOrgID}/account/profile`,
            role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
            subPath: "/account/profile"
          },
          security: {
            name: "Security",
            activeValue: "security",
            pathName: `/o/${this.props.activeOrgID}/account/security`,
            role: [ROLE_CODE.member, ROLE_CODE.admin, ROLE_CODE.all, ROLE_CODE.owner],
            subPath: "/account/security"
          },
        },
        dontPrintOnLoop: true,
        isWaitTierDetail: true
      }
    };

    // let activeMenuCheck = (pathString) => {
    //   console.log(pathString)
    //   return window.location.href.includes(pathString);
    // }

    let isSubmenuExist = () => {
      return typeof this.props.sideMenu === "object" && this.props.sideMenu.submenuList;
    }

    let resetSubMenu = () => {
      this.props.setParentMenuBlockActive({
        activeMenuBlock: "",
        submenuList: null,
        activeSubMenu: false
      })
    }

    let mainMenuClick = (activeParent, submenuList) => {
      let _submenuList = submenuList ? submenuList : [];
      let activeSubMenu = "";

      let isRoleMatch = (role) => {
        return role.findIndex(val => val === this.findMemberRole()) !== -1;
      }

      let i = 0;
      while (i < Object.keys(_submenuList).length) {
        if (isRoleMatch(_submenuList[Object.keys(_submenuList)[i]].role)) {
          this.props.history.push(submenuList[Object.keys(_submenuList)[i]].pathName);
          activeSubMenu = _submenuList[Object.keys(_submenuList)[i]].activeValue;
          break;
        } else {
          i++;
        }
      }

      this.props.setParentMenuBlockActive({
        activeMenuBlock: activeParent,
        submenuList: _submenuList,
        activeSubMenu: activeSubMenu
      })
    }

    let findMediumCounter = (medium) => {
      let mediumCounter = 0;
      
      if(medium === "channels") {
        if(this.props.mediumData) {
          this.props.mediumData.mediums.map(v => {
            mediumCounter = mediumCounter + v.count;

            return null;
          })
        }
      }else {
        if (this.props.mediumData) {
          let findIndex = this.props.mediumData.mediums.findIndex(val => val.code === medium);
  
          if (findIndex !== -1) {
            mediumCounter = this.props.mediumData.mediums[findIndex].count;
          }
        }
      }

      return mediumCounter;
    }

    let onClickSubMenu = (activeValue) => {
      let _sideMenu = { ...this.props.sideMenu };
      _sideMenu.activeSubMenu = activeValue;

      this.props.setParentMenuBlockActive(_sideMenu);
    }

    // let formatMoney = (currency, amount) => {
    //   if(currency === "IDR") return rupiahFormat(amount)
    //   return `${currency} ${numberWithCommas(amount)}`
    // }

    // let getBalance = () => {
    //   let balance = "-"
    //   if (this.props.allOrganizationDetail) {
    //     let prefCurrency = this.props.allOrganizationDetail.organization.prefCurrency
        
    //     if(this.props.balanceData.length > 0) {
    //       let currBalance = this.props.balanceData.find(elem => elem.currency === prefCurrency);
    //       if(currBalance) {
    //         balance = formatMoney(prefCurrency, currBalance.amount)
    //       } else {
    //         balance = formatMoney(this.props.balanceData[0].currency, this.props.balanceData[0].amount)
    //       }
    //     } else {
    //       balance = formatMoney(prefCurrency, 0)
    //     }
    //   } 
    //   return balance
    // }

    return (
      <React.Fragment>
        {/* new side menu */}
        <div className={`new-side-menu-wrapper ${(process.env.REACT_APP_HIDE_BILLING !== "true" && this.props.billingWarning) ? "side-menu-active-billing-warning" : ""}`}>
          <div className="scrolling-top-menu">
            <Scrollbars 
              renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
            > 
              {this.state.userData !== null &&
                Object.keys(sideMenuList).map((value, key) => {
                  if (sideMenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 && !sideMenuList[value].dontPrintOnLoop) {
                    if (sideMenuList[value].pathName) {
                      if (!sideMenuList[value].isWaitTierDetail) {
                        return (
                          <NavLink to={sideMenuList[value].pathName} target={sideMenuList[value].newTab ? '_blank' : '_self'}
                            key={`main-menu-${key}`}
                            onClick={() => {
                              this.props.setParentMenuBlockActive({
                                activeMenuBlock: sideMenuList[value].activeValue,
                                submenuList: null
                              })
                            }}
                          >
                            <div className={`new-main-side-menu-wrapper
                                              ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}`}
                              onClick={() => {
                                mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                if(sideMenuList[value].activeValue === "organization") {
                                  this.runGetBalance()
                                }
                              }}
                            >
                              <div className="active-white-line" />
                              <img src={sideMenuList[value].icon} alt="" />
                              <br />
                              <b>{sideMenuList[value].label}</b>
                            </div>
                          </NavLink>
                        )
                      } else { // if(!sideMenuList[value].isWaitTierDetail) {
                        if (this.props.currentTierDetailProps !== null) {
                          if (this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                            return (
                              // <NavLink to={sideMenuList[value].pathName} target={sideMenuList[value].newTab ? '_blank' : '_self'}
                              <NavLink to={sideMenuList[value].pathName} target={'_blank'}
                                key={`main-menu-${key}`}
                                onClick={() => {
                                  this.props.setParentMenuBlockActive({
                                    activeMenuBlock: sideMenuList[value].activeValue,
                                    submenuList: null
                                  })
                                }}
                              >
                                <div className={`new-main-side-menu-wrapper
                                                  ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}`}
                                  onClick={() => {
                                    mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                    if(sideMenuList[value].activeValue === "organization") {
                                      this.runGetBalance()
                                    }
                                  }}
                                >
                                  <div className="active-white-line" />
                                  <img src={sideMenuList[value].icon} alt="" />
                                  <br />
                                  <b>{sideMenuList[value].label}</b>
                                </div>
                              </NavLink>
                            )
                          }
                        }
                      }
                    } else { // if(sideMenuList[value].pathName) { -> with sub 
                      if (!sideMenuList[value].isWaitTierDetail) {
                        return (
                          <div className={`new-main-side-menu-wrapper
                                            ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}`}
                            key={`main-menu-${key}`}
                            onClick={() => {
                              mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                              if(sideMenuList[value].activeValue === "organization") {
                                this.runGetBalance()
                              }
                            }}
                          >
                            <div className="active-white-line" />
                            <img src={sideMenuList[value].icon} alt="" />
                            <br />
                            <b>{sideMenuList[value].label}</b>
                          </div>
                        )
                      } else { // if(!sideMenuList[value].isWaitTierDetail) {
                        if (this.props.currentTierDetailProps !== null) {
                          if (sideMenuList[value].label === "Organization" && this.props.allOrganizationDetail) {
                            if (this.props.allOrganizationDetail.paymentSettings.isSet || this.props.allOrganizationDetail.selectedTier.id !== 0) {
                              // if(this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                              return (
                                <div className={`new-main-side-menu-wrapper
                                                    ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}`}
                                  key={`main-menu-${key}`}
                                  onClick={() => {
                                    mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                    if(sideMenuList[value].activeValue === "organization") {
                                      this.runGetBalance()
                                    }
                                  }}
                                >
                                  <div className="active-white-line" />
                                  <img src={sideMenuList[value].icon} alt="" />
                                  <br />
                                  <b>{sideMenuList[value].label}</b>
                                </div>
                              )
                              // }
                            }
                          } else {
                            if (this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) {
                              return (
                                <div className={`new-main-side-menu-wrapper
                                                  ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList[value].activeValue ? 'active-side-menu' : ''}`}
                                  key={`main-menu-${key}`}
                                  onClick={() => {
                                    mainMenuClick(sideMenuList[value].activeValue, sideMenuList[value].subMenu);

                                    if(sideMenuList[value].activeValue === "organization") {
                                      this.runGetBalance()
                                    }
                                  }}
                                >
                                  <div className="active-white-line" />
                                  <img src={sideMenuList[value].icon} alt="" />
                                  <br />
                                  <b>{sideMenuList[value].label}</b>
                                </div>
                              )
                            }
                          }
                        }
                      }
                    }
                  } else {
                    return false;
                  }

                  return null;
                })
              }
            </Scrollbars>
          </div>


          <div className="side-menu-main-bottom-wrapper">
            {this.state.userData !== null &&
              <div className={`new-main-side-menu-wrapper
                                ${this.props.sideMenu.activeMenuBlock && this.props.sideMenu.activeMenuBlock === sideMenuList.account.activeValue ? 'active-side-menu' : ''}`}
                onClick={() => {
                  mainMenuClick(sideMenuList.account.activeValue, sideMenuList.account.subMenu);
                }}
              >
                <div className="active-white-line" />
                {this.state.userData.account.imageURL.thumbnail !== "" ?
                  <React.Fragment>
                    <img src={this.state.userData.account.imageURL.thumbnail} alt="" className="my-account-avatar image" />
                    <br />
                    <b>My Account</b>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <div className="my-account-avatar avatar-name">
                      <b>{Helper.renderUserAvatarWord(this.state.userData.account.fullName)}</b>
                    </div>
                    <br />
                    <b>My Account</b>
                  </React.Fragment>
                }
              </div>
            }

            <div className="new-main-side-menu-wrapper" onClick={() => this.togglePopupOrganization()}>
              <img src={iconSwitchOrganization} alt="" />
              <br />
              <b>Switch Organization</b>
            </div>
          </div>
        </div>

        {this.state.userData !== null &&
          <div className={`new-sub-side-menu-wrapper 
                          ${!isSubmenuExist() ? "deactive-submenu-wrapper" : ""}
                          ${(process.env.REACT_APP_HIDE_BILLING !== "true" && this.props.billingWarning) ? "new-sub-side-menu-active-billing-warning" : ""}
          `}>
            {/* <Scrollbars renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
            > */}

            {isSubmenuExist() &&
              (
                (
                  this.props.sideMenu.activeMenuBlock === "integration" ||
                  this.props.sideMenu.activeMenuBlock === "account"
                ) ? 
                    
                      <div className='sub-menu-scroll'>
                        <Scrollbars 
                          renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
                          renderTrackHorizontal={props => <div {...props} style={{display: 'none'}} className="track-horizontal"/>}
                        >
                          <div className='sub-menu-scroll-inner'>
                            {Object.keys(this.props.sideMenu.submenuList).map((value, key) => {
                              return (
                                this.state.isActiveChannels ?
                                  (
                                    this.props.sideMenu.submenuList[value].isChannel ?
                                      <SubMenuIntegration 
                                        onClickSubMenu={onClickSubMenu}
                                        findMediumCounter={findMediumCounter}
                                        checkActiveSubmenu={this.checkActiveSubmenu}
                                        value={value}
                                        _key={key}
                                        onClickBackToMainMenu={this.runBackToMainMenuIntegration}
                                        _isActiveChannels={this.state.isActiveChannels}
                                      />
                                      :
                                      ""
                                  ) 
                                  :
                                  (
                                    !this.props.sideMenu.submenuList[value].isChannel ?
                                      <SubMenuIntegration 
                                        onClickSubMenu={onClickSubMenu}
                                        findMediumCounter={findMediumCounter}
                                        checkActiveSubmenu={this.checkActiveSubmenu}
                                        value={value}
                                        _key={key}
                                      />
                                      :
                                      ""
                                  )
                              )
                            })}
                          </div>
                        </Scrollbars>
                      </div>
                    :
                    Object.keys(this.props.sideMenu.submenuList).map((value, key) => {
                      return (
                        // ${activeMenuCheck(this.props.sideMenu.submenuList[value].activeValue) ? "active-sub-menu" : ""}`}
                        this.props.sideMenu.submenuList[value].role ?
                          this.props.sideMenu.submenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 ?
                            //check tier
                            this.props.currentTierDetailProps !== null ?
                              this.props.sideMenu.submenuList[value].ignoreTier ?
                                (this.props.sideMenu.submenuList[value].name === "Billing" ?
                                  (process.env.REACT_APP_HIDE_BILLING !== "true" ?
                                    <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue)}>
                                      <div className={`sub-menu-content ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}`}>
                                        <div className={`sub-menu-icon  ${value}`}></div>
                                        <b>{this.props.sideMenu.submenuList[value].name}</b>
                                      </div>
                                    </NavLink>
                                    :
                                    ""
                                  )
                                  :
                                  <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue)}>
                                    <div className={`sub-menu-content ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}`}>
                                      <div className={`sub-menu-icon  ${value}`}></div>
                                      <b>{this.props.sideMenu.submenuList[value].name}</b>
                                    </div>
                                  </NavLink>
                                )
                                :
                                (!this.props.sideMenu.submenuList[value].ignoreTier && this.props.currentTierDetailProps.id !== 0 && this.props.currentTierDetailProps.isActive) ?
                                  <NavLink to={this.props.sideMenu.submenuList[value].pathName} key={`sub-menu-${key}`} onClick={() => onClickSubMenu(this.props.sideMenu.submenuList[value].activeValue)}>
                                    <div className={`sub-menu-content ${this.checkActiveSubmenu(this.props.sideMenu.submenuList[value].subPath) ? "active-sub-menu" : ""}`}>
                                      <div className={`sub-menu-icon  ${value}`}></div>
                                      <b>{this.props.sideMenu.submenuList[value].name}</b>
                                    </div>
                                  </NavLink>
                                  :
                                  null
                              : // this.props.currentTierDetailProps !== null ?
                              null
                            : // this.props.sideMenu.submenuList[value].role.findIndex(val => val === this.findMemberRole()) !== -1 ?
                            null
                          : // this.props.sideMenu.submenuList[value].role ?
                          resetSubMenu()
                      )
                    })
                  )
                }
            {/* </Scrollbars> */}
{/* 
            {(this.props.sideMenu.activeMenuBlock === "organization" && this.props.balanceData) &&
              <div className="your-channel-limit">
                <p>
                  <b>Balance</b>
                </p>

                <b className="channel-limit">{getBalance()}</b>
              </div>
            } */}

            {(this.props.sideMenu.activeMenuBlock === "integration" && this.props.mediumData && this.state.isActiveChannels) &&
              <div className="your-channel-limit">
                <p>
                  <b>Your Channels</b>
                </p>

                <b className="channel-limit">{this.props.mediumData.numberOfItems.current}/{this.props.mediumData.numberOfItems.maximum !== -1 ? this.props.mediumData.numberOfItems.maximum : "Unlimited"} channels</b>
              </div>
            }
          </div>
        }

        {this.state.userData !== null &&
          <div className={`organization-popup-wrapper ${this.state.showPopupOrganization ? '' : 'deactive-organization-popup-wrapper'}`}>
            <div className="popup-organization-content-info popup-top-content">
              <p className="popup-organization-content-info-name">
                <b>{this.state.userData !== null && this.state.userData.account.fullName}</b>
              </p>

              <p className="popup-organization-content-info-email">
                {this.state.userData !== null && this.state.userData.account.email}
              </p>

              {/* <Link to={`/o/${this.props.activeOrgID}/account/security`}>
                <FiSettings />
              </Link> */}
            </div>

            <div className={`popup-oganization-list`}>
              <ul>
                {this.state.userData.memberships.map((value, index) => {
                  return (
                    // <li onClick={() => this.onClickOrganizationList(value.organization)}
                    <li key={`organization-list-${index}`}
                      className={this.isActiveOrganizationCheck(value.organization.id) ? 'active-organization' : ''}
                    >
                      <a href={`/o/${value.organization.id}-${value.organization.createdTime}/home`} onClick={() => {
                        this.props.setParentMenuBlockActive({
                          activeMenuBlock: "home",
                          submenuList: null
                        })
                      }}>
                        <div className="organization-link-wrapper">
                          <IoIosCheckmark />
                          {value.organization.name}
                        </div>
                      </a>
                    </li>
                  )
                })}
              </ul>
              {/* : */}
            </div>

            {(this.state.userData.canCreateOrganization) &&
              <NavLink to="/organization/new">
                <div className="popup-organization-content-wrapper new-organization-wrapper">
                  <b>New Organization</b>
                  <IoIosAdd />
                </div>
              </NavLink>
            }

            <div onClick={() => this.runLogoutAction()} className="popup-organization-content-wrapper logout-wrapper">
              <b>Logout</b>
            </div>
          </div>
        }
        {/* new side menu */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  sideMenu: state.sideMenu,
  user: state.user,
  activeOrgID: state.activeOrgID,
  billingWarning: state.billingWarning,
  allOrganizationDetail: state.allOrganizationDetail,
  mediumData: state.mediumData,
  balanceData: state.balanceData
});

const mapDispatchToProps = {
  setParentMenuBlockActive,
  setLogout,
  setBalanceData
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidemenu)