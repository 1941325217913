import React from "react";
import "./BackButton.css";
import { FaChevronLeft } from "react-icons/fa";

const BackButton = ({ text, onClick }) => (
  <button className="comp-back-button" onClick={onClick}>
    <FaChevronLeft />
    &nbsp; &nbsp;
    {text}
  </button>
);

export default BackButton;
