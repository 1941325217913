import React, { useState, useEffect, useRef } from 'react';
import './ChatRoomCaseHistoryModalMainChat.scss';
import { taptalk, tapCoreRoomListManager, tapCoreMessageManager } from '@taptalk.io/web-sdk';
import { doToast } from "../../../../helper/HelperGeneral";
import { connect } from 'react-redux';
import ChatRoomChatInfo from '../../chatRoomMain/chatRoomChatInfo/ChatRoomChatInfo';
import ChatRoomMessageIn from '../../chatRoomMain/chatRoomMessageIn/ChatRoomMessageIn';
import ChatRoomMessageInFile from '../../chatRoomMain/chatRoomMessageInFile/ChatRoomMessageInFile';
import ChatRoomMessageInImage from '../../chatRoomMain/chatRoomMessageInImage/ChatRoomMessageInImage';
import ChatRoomMessageInVideo from '../../chatRoomMain/chatRoomMessageInVideo/ChatRoomMessageInVideo';
import ChatRoomMessageInTokopedia from '../../chatRoomMain/chatRoomMessageInTokopedia/ChatRoomMessageInTokopedia';
import ChatRoomMessageOut from '../../chatRoomMain/chatRoomMessageOut/ChatRoomMessageOut';
import ChatRoomMessageOutFile from '../../chatRoomMain/chatRoomMessageOutFile/ChatRoomMessageOutFile';
import ChatRoomMessageOutImage from '../../chatRoomMain/chatRoomMessageOutImage/ChatRoomMessageOutImage';
import ChatRoomMessageOutVideo from '../../chatRoomMain/chatRoomMessageOutVideo/ChatRoomMessageOutVideo';
import ChatRoomMessageOutWabaTemplate from '../../chatRoomMain/chatRoomMessageOutWabaTemplate/ChatRoomMessageOutWabaTemplate';
import ChatRoomMessageOutWabaTemplateImage from '../../chatRoomMain/chatRoomMessageOutWabaTemplateImage/ChatRoomMessageOutWabaTemplateImage';
import ChatRoomMessageOutBroadcast from '../../chatRoomMain/chatRoomMessageOutBroadcast/ChatRoomMessageOutBroadcast';
import ChatRoomMessageOutBroadcastImage from '../../chatRoomMain/chatRoomMessageOutBroadcastImage/ChatRoomMessageOutBroadcastImage';

const CHAT_TYPE = {
  TAPChatMessageTypeText : 1001,
  TAPChatMessageTypeImage : 1002,
  TAPChatMessageTypeVideo : 1003,
  TAPChatMessageTypeFile : 1004,
  TAPChatMessageTypeLocation : 1005,
  TAPChatMessageTypeContact : 1006,
  TAPChatMessageTypeSticker : 1007,
  TAPChatMessageTypeLink : 1010,
  TAPChatMessageTypeSystemMessage : 9001,
  TAPChatMessageTypeUnreadMessageIdentifier : 9002,
  TAPChatMessageTypeCaseClosed : 3001,
  TAPChatMessageTypeLeaveReview: 3003,
  TAPChatMessageTypeLeaveReviewDisabled: 3004,
  MessageTypeBroadcastText: 3011,
  MessageTypeBroadcastImage: 3012,
  MessageTypeWhatsAppBATemplateText: 3021,
  MessageTypeWhatsAppBATemplateImage: 3022,
  MessageTypeTokopedia: 3031
}

// const MESSAGE_TYPE = {
//   CASE_CREATED: 3005,
//   CASE_CLOSED: 3001,
//   CASE_REOPEN: 3002,
//   CASE_RATING_BUTTON: 3003,
//   CASE_RATING_BUTTON_DISABLED: 3004,
//   CASE_UPDATE_AGENT_CHANGE: 3006
// }

var ChatRoomCaseHistoryModalMainChat = (props) => {
  let [roomData, setRoomData] = useState(null);
  let [isLoadingChatModalHistory, setIsLoadingChatModalHistory] = useState(true);
  let [chatRoomPopupData, setChatRoomPopupData] = useState(null);

  let chatRoomPopupListRef = useRef("messageLists");

  useEffect(() => {
    setChatRoomPopupData(null);

    if(props.caseHistoryPopupDataProps !== null) {
      tapCoreRoomListManager.getRoomByXcID(props.caseHistoryPopupDataProps.case.tapTalkXCRoomID, {
        onSuccess: (response) => {
          setRoomData(response)
        },
        onError: (errorCode, errorMessage) => {
          doToast(errorMessage, 'fail');
          console.log(errorCode, errorMessage);
        }
      })
    }
  }, [props.caseHistoryPopupDataProps])

  useEffect(() => {
    if(roomData !== null) {
      getMessageAfter(roomData.room.roomID);
    }
  }, [roomData])

  useEffect(() => {
    let listenerNewMessage = props.messageListenerNewMessageProps === null ? null : props.messageListenerNewMessageProps;
    let cloneChatRoomPopupData = {...chatRoomPopupData};

    if(props.isShowModalCaseHistoryProps && roomData !== null) {

      if(listenerNewMessage !== null) {

        if(roomData.room.roomID === listenerNewMessage.room.roomID) {

          if(listenerNewMessage.user.userID === taptalk.getTaptalkActiveUser().userID) {
            // if message was from me            
            cloneChatRoomPopupData[listenerNewMessage.localID] = listenerNewMessage;
            
            //imediately scrolling to bottom
            scrollChatViewToBottom();
          }else {
            //if chat was from another device
            cloneChatRoomPopupData[listenerNewMessage.localID] = listenerNewMessage;
          }
          
          setChatRoomPopupData(cloneChatRoomPopupData);

          if((chatRoomPopupListRef.current.scrollHeight - chatRoomPopupListRef.current.scrollTop) === chatRoomPopupListRef.current.clientHeight) {
            scrollChatViewToBottom();
          }
        }
        
      }
      
    }
  }, [props.messageListenerNewMessageProps]);

  useEffect(() => {
    let listenerUpdateMessage = props.messageListenerUpdateMessageProps === null ? null : props.messageListenerUpdateMessageProps;
    
    if(props.isShowModalCaseHistoryProps && roomData !== null) {

      if(listenerUpdateMessage !== null) { 

        if(roomData.room.roomID === listenerUpdateMessage.room.roomID) {

          if(listenerUpdateMessage.isDelivered && !listenerUpdateMessage.isRead) {
            let cloneChatDataUndelivered = {...chatRoomPopupData};

            if(cloneChatDataUndelivered[listenerUpdateMessage.localID]) {
              cloneChatDataUndelivered[listenerUpdateMessage.localID].isDelivered = true;
              setChatRoomPopupData(cloneChatDataUndelivered);
            }
          }

          //set all chat data isRead to true 
          if(listenerUpdateMessage.isRead) {
            let cloneChatDataUnRead = {...chatRoomPopupData};

            Object.keys(cloneChatDataUnRead).map(function(i) {
              if(!cloneChatDataUnRead[i].isRead) {
                cloneChatDataUnRead[i].isRead = true;
                setChatRoomPopupData(cloneChatDataUnRead);
              }
              return null;
            });
          }
          
        }

      }

    }
  }, [props.messageListenerUpdateMessageProps]);

  let reverseMessagesObject = (object) => {
    var newObject = {};
    var keys = [];

    for (var key in object) {
        keys.push(key);
    }

    for (var i = keys.length - 1; i >= 0; i--) {
      var value = object[keys[i]];
      newObject[keys[i]]= value;
    }       

    return newObject;
  }

  let scrollChatViewToBottom = () => {
    setTimeout(function() {
      if(chatRoomPopupListRef.current !== null) {
        chatRoomPopupListRef.current.scrollTop = chatRoomPopupListRef.current.scrollHeight;
      }
    }, 0);
  }

  let onScrollListener = () => {
    let currentViewHeight = chatRoomPopupListRef.current.scrollHeight;

    if(chatRoomPopupListRef.current.scrollTop === 0) {
      getMessageBefore(roomData.room.roomID, false, function() {
        chatRoomPopupListRef.current.scrollTop = chatRoomPopupListRef.current.scrollHeight - currentViewHeight;
      });
    }
  }
  
  let getMessageAfter = (roomID) => {
    setTimeout(function() {
      setIsLoadingChatModalHistory(true);

      tapCoreMessageManager.getNewerMessagesAfterTimestamp(roomID, {
        onSuccess: (messages) => {
              setIsLoadingChatModalHistory(false);

              if(messages !== null) {
                setChatRoomPopupData(reverseMessagesObject(messages));
        
                Object.keys(messages).length < 50 && getMessageBefore(roomData.room.roomID, true);
                
                scrollChatViewToBottom();
              }
          },
          onError: (errorCode, errorMessage) => {
              setIsLoadingChatModalHistory(false);
              console.log(errorCode, errorMessage);
          }
      });
    }, 100);
  }

  let getMessageBefore = (roomID, isFirstUpload = false, scrollingBack) => {
    setIsLoadingChatModalHistory(true);
    let numberOfItems = 50;
    
    tapCoreMessageManager.getOlderMessagesBeforeTimestamp(roomID, numberOfItems, {
      onSuccess: (messages) => {
          setIsLoadingChatModalHistory(false);

          setChatRoomPopupData(reverseMessagesObject(messages));

          if(isFirstUpload) {
            scrollChatViewToBottom();
          }else {
            scrollingBack();
          }
      },
      onError: (errorCode, errorMessage) => {
          setIsLoadingChatModalHistory(false);
          console.log(errorCode, errorMessage);
      }
    });
  }

  let generateMessageBuble = (messageData, index) => {
    let activeUser = taptalk.getTaptalkActiveUser().userID;
    switch(messageData.type) {
      case CHAT_TYPE.TAPChatMessageTypeText:
        return (
          messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
            />
            :
            <ChatRoomMessageOut 
              key={index} 
              singleChatDataProps={messageData} 
            />
        )
      case CHAT_TYPE.TAPChatMessageTypeLink:
          return (
            messageData.user.userID !== activeUser ?
              <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData} 
              />
              :
              <ChatRoomMessageOut 
                key={index} 
                singleChatDataProps={messageData} 
              />
          )
      case CHAT_TYPE.TAPChatMessageTypeLeaveReview:
        return (
          messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
            />
            :
            <ChatRoomMessageOut 
              key={index} 
              singleChatDataProps={messageData} 
            />
        )
      case CHAT_TYPE.TAPChatMessageTypeLeaveReviewDisabled:
        return (
          messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
            />
            :
            <ChatRoomMessageOut 
              key={index} 
              singleChatDataProps={messageData} 
            />
        )
      case CHAT_TYPE.TAPChatMessageTypeImage:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
                :
                <ChatRoomMessageInImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
                :
                <ChatRoomMessageOutImage 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeVideo:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
                :
                <ChatRoomMessageInVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
                :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
                :
                <ChatRoomMessageOutVideo 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeSystemMessage:
          return (
            <ChatRoomChatInfo 
                key={index} 
                singleChatDataProps={messageData} 
            />
          )
      case CHAT_TYPE.TAPChatMessageTypeCaseClosed:
        return (
          <ChatRoomChatInfo 
              key={index} 
              singleChatDataProps={messageData} 
          />
        )
      case CHAT_TYPE.TAPChatMessageTypeLocation:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted &&
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
              :
              messageData.isDeleted &&
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
          )
      case CHAT_TYPE.TAPChatMessageTypeFile:
          return (
            messageData.user.userID !== activeUser ?
              messageData.isDeleted ?
                <ChatRoomMessageIn 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
                :
                <ChatRoomMessageInFile 
                    key={index}
                    singleChatDataProps={messageData} 
                />
              :
              messageData.isDeleted ?
                <ChatRoomMessageOut 
                    key={index} 
                    singleChatDataProps={messageData} 
                />
                :
                <ChatRoomMessageOutFile 
                    key={index} 
                    singleChatDataProps={messageData}
                    isOnUpload={messageData.isOnUpload}
                    uploadFile={messageData.uploadFile}
                />
          )
    case CHAT_TYPE.MessageTypeWhatsAppBATemplateText:
      return (
        messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn
                key={index}
                singleChatDataProps={messageData} 
            />
            // messageData.isDeleted ?
            //   <ChatRoomMessageIn 
            //       key={index} 
            //       singleChatDataProps={messageData} 
            //   />
            //   :
            //   <ChatRoomMessageInWabaTemplate
            //       key={index}
            //       singleChatDataProps={messageData} 
            //   />
            :
            messageData.isDeleted ?
              <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
              />
              :
              <ChatRoomMessageOutWabaTemplate
                  key={index} 
                  singleChatDataProps={messageData}
              />
      )
    case CHAT_TYPE.MessageTypeBroadcastText:
      return (
        messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn
                key={index}
                singleChatDataProps={messageData} 
            />
            :
            messageData.isDeleted ?
              <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
              />
              :
              <ChatRoomMessageOutBroadcast
                  key={index} 
                  singleChatDataProps={messageData}
              />
      )
    case CHAT_TYPE.MessageTypeBroadcastImage:
      return (
        messageData.user.userID !== activeUser ?
          messageData.isDeleted ?
            <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
            />
            :
            <ChatRoomMessageInImage 
                key={index} 
                singleChatDataProps={messageData} 
            />
          :
          messageData.isDeleted ?
            <ChatRoomMessageOut 
                key={index} 
                singleChatDataProps={messageData} 
            />
            :
            <ChatRoomMessageOutBroadcastImage 
                key={index} 
                singleChatDataProps={messageData} 
            />
        )
      case CHAT_TYPE.MessageTypeWhatsAppBATemplateImage:
        return (
          messageData.user.userID !== activeUser ?
            messageData.isDeleted ?
              <ChatRoomMessageIn 
                  key={index} 
                  singleChatDataProps={messageData} 
              />
              :
              <ChatRoomMessageInImage 
                  key={index} 
                  singleChatDataProps={messageData}
              />
            :
            messageData.isDeleted ?
              <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
              />
              :
              <ChatRoomMessageOutWabaTemplateImage 
                  key={index} 
                  singleChatDataProps={messageData} 
              />
          )
          case CHAT_TYPE.MessageTypeTokopedia:
            return (
              messageData.user.userID !== activeUser ?
                <ChatRoomMessageInTokopedia
                    key={index} 
                    singleChatDataProps={messageData} 
                />
                :
                <ChatRoomMessageOut 
                  key={index} 
                  singleChatDataProps={messageData} 
                />
            )
      default:
        return (
          messageData.user.userID !== activeUser ?
            <ChatRoomMessageIn 
                key={index} 
                singleChatDataProps={messageData} 
            />
            :
            <ChatRoomMessageOut 
              key={index} 
              singleChatDataProps={messageData} 
            />
        )
    }
  }
  
  return (
    props.caseHistoryPopupDataProps &&
      <div className={`chat-room-case-history-modal-main-chat-wrapper ${props.isUserDetailShowProps ? 'active-chat-room-case-history-modal-detail' : ''}`}>
          <div className="chat-room-case-history-modal-main-chat-container">
            <div className="chat-room-case-history-modal-main-chat-content"  onScroll={() => onScrollListener()} ref={chatRoomPopupListRef}>
              {isLoadingChatModalHistory &&
                <div className="loading-message-wrapper">
                  <div className="lds-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                </div>
              }

              {chatRoomPopupData !== null &&
                  Object.keys(chatRoomPopupData).map(function(key) {
                    if(!chatRoomPopupData[key].isHidden) {
                      return (
                        generateMessageBuble(chatRoomPopupData[key], chatRoomPopupData[key].localID)
                      )
                    }
                    return null;
                  })
              }
            </div>
          </div>

          {/* <div className="chat-room-case-history-modal-go-to-container">
              <p>
                  <FiClock /> <b>Case History</b>
              </p>

              <p>
                  You are currently viewing a case history
              </p>

              <button className="orange-button">
                  <FiArrowRight /><b>Go To Case</b>
              </button>
          </div> */}
      </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomCaseHistoryModalMainChat);
