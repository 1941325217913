import React, { useState } from "react";
import NewButton from "../reuseableComponent/NewButton/NewButton";
import { FaRegTrashAlt, FaRegEdit } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import Loading from "../reuseableComponent/loading/Loading";
import TopicDelete from "./TopicsDelete";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import { NavLink } from "react-router-dom";

const getBtnAct = (type, text, action) => (
  <button
    className={`action-button ${type || ""}`}
    type="button"
    onClick={action}
  >
    {type === "del" ? <FaRegTrashAlt /> : <FaRegEdit />}
    {text}
  </button>
);

const TopicsList = props => {
  const [popupDel, setPopupDel] = useState(false);
  const [popData, setPopData] = useState(false);
  const { orgID, listTopic, reloadTopic } = props;

  const LoadingList = () => (
    <div className="topic__tcRow plain">
      <div className="topic__tcCol">
        <br />
        <Loading />
      </div>
    </div>
  );

  const Row = ({ data }) => {
    const { id, name, totalAgents } = data;

    const clickDel = async () => {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
        mixpanel.track(
          "[Button] Delete Topic",
          {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.orgID.split("-")[0]
          }
        );
      }
      
      const adds = { orgID, setPopupDel, reloadTopic, setPopData };
      setPopData({ ...data, ...adds });
      setPopupDel(true);
    };

    const clickToDetails = () => {
      // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      // if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
      //   mixpanel.track(
      //     "[Button] Topic Details",
      //     {
      //       userFullName : myAgentData.account.fullName,
      //       userID : myAgentData.account.id,
      //       organizationID : props.orgID.split("-")[0]
      //     }
      //   );
      // }

      // const fullID = `${orgID}`;
      // const fullUrl = `/o/${fullID}/organization/topics/details/${id}`;
      
      // history.push(fullUrl);
    };

    const btnDel = getBtnAct("del", "Delete", clickDel);
    const btnEdit = getBtnAct("det", "Details", clickToDetails);

    return (
      <React.Fragment>
        <div className="tcRow" key={id}>
          <div className="tcCol">{id}</div>
          <div className="tcCol">{name}</div>
          <div className="tcCol">
            <div className="tcRow inside">
              <div className="tcCol">{totalAgents}</div>
              <div className="tcCol flex">
                <NavLink to={`/o/${orgID}/organization/topics/details/${id}`}>
                  <div>{btnEdit}</div>
                </NavLink>

                <div>{btnDel}</div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  };

  const NoData = () => (
    <div className="tcRow plain">
      <div className="tcCol info">
        No topics found, start with ‘+ Add topic’
      </div>
    </div>
  );

  const Content = ({ data }) => {
    if (data && data.length === 0) return <NoData />;
    else if (data && data.length > 0) {
      const view = data.map(item => <Row data={item} key={item.id} />);
      return <div className="tcBody">{view}</div>;
    }
    return <LoadingList />;
  };

  const TableContent = props => {
    const { data } = props;
    const { data: dataList } = data;

    return (
      <div className="tableContent">
        <div className="tcHeader">
          <div className="tcCol">Topic ID</div>
          <div className="tcCol">Topic Name</div>
          <div className="tcCol">Number of Agents</div>
        </div>
        <Content data={dataList} />
        <TopicDelete isOpen={popupDel} data={popData} />
      </div>
    );
  };

  return orgID ? <TableContent data={listTopic} /> : <Loading />;
};

const TopicsHead = ({ setPopupAdd, mainProps }) => {
  const defAction = () => console.log("NO ACTION PASSED. PLEASE PROVIDE!");
  const clickAdd = () => {
    setPopupAdd(true);
  }
  const click = setPopupAdd ? clickAdd : defAction;
  return (
    <div className="topContent">
      <div className="title">Topic List</div>
      <div className="btn">
        <NewButton 
          text="Add Topic" 
          icon={FiPlus} 
          onClick={() => {
            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
            
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
              mixpanel.track(
                "[Button] Add Topics",
                {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : mainProps.match.params.orgID.split("-")[0]
                }
              );
            }

            click();
          }} />
      </div>
    </div>
  );
};

export { TopicsList, TopicsHead };
