import React, { Component } from 'react';
import './VerifyEmail.css';
import Logo from "../../../assets/img/logo-taptalk-white.svg";
import PaperPlaneLeft from "../../../assets/img/auth/img-paper-plane-left.svg";
import PaperPlaneRight from "../../../assets/img/auth/img-paper-plane-right.svg";
import IconFacebookMessenger from "../../../assets/img/auth/icon-fb-messenger-circle.svg";
import IconGoogle from "../../../assets/img/auth/icon-google-circle.svg";
import IconGoogleBusinessProfile from "../../../assets/img/auth/icon-gb-profile-circle.svg";
import IconInstagram from "../../../assets/img/auth/icon-instagram-circle.svg";
import IconMail from "../../../assets/img/auth/icon-mail-circle.svg";
import IconTelegram from "../../../assets/img/auth/icon-telegram-circle.svg";
import IconTokopedia from "../../../assets/img/auth/icon-tokopedia-circle.svg";
import IconWhatsAppSME from "../../../assets/img/auth/icon-whatsapp-circle.svg";
import IconWhatsAppBA from "../../../assets/img/auth/icon-whatsapp-ba-circle.svg";
import { Link } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import ServiceAuthRegister from '../../../services/auth/ServiceAuthRegister';
import HelperQueryString from "../../../helper/HelperQueryString";
import HelperGTag from "../../../helper/HelperGTag";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiArrowLeft, FiCheck, FiX } from 'react-icons/fi';

class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verifyEmailStatus: "process",
      verificationErrorMessage: "",
      emailIsVerified: false
    }; 
  }

  componentDidMount() {
    let qs = HelperQueryString.parseQuerystring();

    let valToken = qs["token"];

    if (valToken) {
      try {
        let accountVerificationData = JSON.parse(
          atob(valToken)
        );

        this.actionEmailVertificaton(accountVerificationData);
      }
      catch (e) {
        this.setState({
          verifyEmailStatus: "failed",
          verificationErrorMessage: "Wrong Token Format"
        })
      }
    }
    else {
      window.location.href = "/login";
    }
  }

  actionEmailVertificaton = (emailPayload) => {
    ServiceAuthRegister.postEmailVerification(emailPayload).then(response => {
      if (response.dataResult.data.success) {
        this.setState({
          verifyEmailStatus: "success"
        })

        window.dataLayer.push(
          Object.assign({...HelperGTag.basicParameter("verify-email")},
            {
              event: "action-email-verification-success",
              source: "verify-email"
            }
          )
        )
      }
      else {
        this.setState({
          emailIsVerified: true,
          verifyEmailStatus: "failed",
          verificationErrorMessage: response.dataResult.data.message
        })
      }

      if (response.dataResult.error.code !== "") {
        this.setState({
          verifyEmailStatus: "failed",
          verificationErrorMessage: response.dataResult.error.message
        })
      }
    })
  }

  render() {
    return (
      <div id="outer-container">

        <img src={Logo} alt="TapTalk Logo" className="logo-image" />
        <p className="subtitle">Omnichannel Chat Platform</p>

        <img alt="" src={PaperPlaneLeft} className="bg-img-paper-plane-left" />
        <img alt="" src={PaperPlaneRight} className="bg-img-paper-plane-right" />
        <img alt="" src={IconWhatsAppSME} className="bg-img-whatsapp-sme" />
        <img alt="" src={IconTelegram} className="bg-img-telegram" />
        <img alt="" src={IconGoogleBusinessProfile} className="bg-img-gb-profile" />
        <img alt="" src={IconGoogle} className="bg-img-google" />
        <img alt="" src={IconInstagram} className="bg-img-instagram" />
        <img alt="" src={IconFacebookMessenger} className="bg-img-fb-messenger" />
        <img alt="" src={IconMail} className="bg-img-mail" />
        <img alt="" src={IconTokopedia} className="bg-img-tokopedia" />
        <img alt="" src={IconWhatsAppBA} className="bg-img-whatsapp-ba" />

        <div id="verify-email-wrapper">
          {
            this.state.verifyEmailStatus === "process" &&
            <Fade>
              <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
              <div className="verification-status-title">
                <b>Verifying Email</b>
              </div>
              <p>
                Please give it a few moment for us to verify your email address.
              </p>
            </Fade>
          }

          {
            this.state.verifyEmailStatus === "failed" &&
            <Fade>
              <div className="verification-status-failed">
                  <FiX />
              </div>

              <div className="verification-status-title">
                <b>Verification Failed</b>
              </div>

              {
                !this.state.emailIsVerified ?
                <p>
                  {
                    this.state.verificationErrorMessage &&
                    <>
                      {this.state.verificationErrorMessage}
                      <br />
                    </>
                  }
                  Unfortunately we cannot verify your email right now.
                  <br />
                  Please contact us at <a href="help@taptalk.io" target="_blank" rel="noopener noreferrer">help@taptalk.io</a>
                </p>
                :
                <p>
                  {this.state.verificationErrorMessage}
                  {
                    this.state.emailIsVerified &&
                    <div style={{marginTop: "32px"}}>
                      <Link to="/">
                        <ButtonWithLoadingOrIcon
                          className="orange-button main-button-48 width-100"
                          text="Back to Log In"
                          position="left"
                          icon={{
                            type: "svg",
                            src: FiArrowLeft
                          }}
                        />
                      </Link>
                    </div>
                  }
                </p>
              }
            </Fade>
          }

          {
            this.state.verifyEmailStatus === "success" &&
            <Fade>
              <div className="verification-status-success">
                  <FiCheck />
              </div>
              <div className="verification-status-title">
                <b> Verification Success</b>
              </div>

              <p>
                Congratulations! Your email has been verified. Sign in now with your verified email address.
              </p>
              
              <div style={{marginTop: "32px"}}>
                <Link to="/">
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-48 width-100"
                    text="Back to Log In"
                    position="left"
                    icon={{
                      type: "svg",
                      src: FiArrowLeft
                    }}
                  />
                </Link>
              </div>
            </Fade>
          }
        </div>
      </div>
    );
  }
}

export default VerifyEmail;
