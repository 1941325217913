import React, { useState } from "react";
import "./BroadcastMessage.scss";
import { TabContent, Nav, NavItem } from 'reactstrap';
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import { checkID } from "../../helper/HelperGeneral";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import BroadcastMessageImmediate from "./broadcastMessage/broadcastMessageImmediate/BroadcastMessageImmediate";
import BroadcastMessageScheduled from "./broadcastMessage/broadcastMessageScheduled/BroadcastMessageScheduled";
// import { connect } from 'react-redux';

const BroadcastMessage = (props) => {
    const BROADCAST = {
        immediate: {
            id: "immediate",
            tab: "Immediate Broadcast",
            component: <BroadcastMessageImmediate {...props} tab={"immediate"} />,
            isHidden: props.memberRole === "member"
        },
        scheduled: {
            id: "scheduled",
            tab: "Scheduled Broadcast",
            component: <BroadcastMessageScheduled {...props} tab={"scheduled"} />
        },
    }

    let [activeTab, setActiveTab] = useState(props.memberRole === "member" ? BROADCAST.scheduled.id : BROADCAST.immediate.id);
    let { match, history, location } = props;
    let [orgID, setOrgID] = React.useState(false);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    const doMount = async () => {
        const paramID = checkID(match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/organization/members`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    React.useEffect(() => {
        doMount();
    }, [location.pathname]);

    React.useEffect(() => {
        if(props.history.location.state) {
          if(props.history.location.state.tab) {
            setActiveTab(props.history.location.state.tab);
          }
        }
    }, [props.history])

    return (
        <React.Fragment>
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                <div className="broadcast-page-wrapper">
                    <Nav tabs>
                        {Object.keys(BROADCAST).map((value, index) => {
                            return (
                                !BROADCAST[value].isHidden &&
                                    <NavItem key={`tab-setup-${index}`} className={`${activeTab === BROADCAST[value].id ? 'active-setup-tab' : ''}`}>
                                        <div className="setup-tab-wrapper"
                                            onClick={() => { toggle(BROADCAST[value].id); }}
                                        >
                                            <b>{BROADCAST[value].tab}</b>
                                        </div>
                                    </NavItem>
                            )
                        })}
                    </Nav>

                    <TabContent>
                        {BROADCAST[activeTab].component}
                    </TabContent>
                </div>
            </SectionWrap>
        </React.Fragment>
    )
}

export default BroadcastMessage;