import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; 
import "../auth/Auth.css";
import "./Members.scss";
import { checkID, doToast } from "../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import CustomSearchBox from "../reuseableComponent/customSearchBox/CustomSearchBox";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
// import Pagination from "../reuseableComponent/pagination/Pagination";
import { FiPlus } from "react-icons/fi";
// import arrowTopRight from "../../assets/img/arrow-top-right-orange.svg";
import iconEyeOrange from "../../assets/img/icon-eye-orange.svg";
import IconRemoveRed from "../../assets/img/icon-remove-red.svg";
import iconPaperplaneOrange from "../../assets/img/icon-paperplane-orange.svg";
// import { IoIosRefresh } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";
import PopupRemove from "../reuseableComponent/popupRemove/PopupRemove";
import MemberServices from "../../services/newServices/MemberServices";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";

// const style = {
//   fiPlus: {
//     position: "relative",
//     top: "-2px",
//     marginLeft: "5px"
//   }
// };

const Members = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  let [showPopupRemove, setShowPopupRemove] = useState(false);
  // let [membersOriginal, setMemberOriginals] = useState([]);
  let [members, setMembers] = useState([]);
  let [waitForResponseGetList, setWaitForResponseGetList] = useState(true);
  let [isSelect, setIsSelect] = useState(false);
  let [countRemoveMember, setCountRemoveMember] = useState(0);
  let [dataToBeRemoved, setDataToBeRemoved] = useState([]);
  let [singleRemoveMember, setSingleRemoveMember] = useState({
    email: "",
    dataToBeRemoved: []
  });
  let [waitForResponseSingleDelete, setWaitForResponseSingleDelete] = useState(false);
  let [showPopupSingleRemove, setShowPopupSingleRemove] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
      code: false,
      message: false
  })
  
  const doMount = async () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(orgID) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
            "[Page] Member", 
            {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
            }
        );
      }
    }
  }, [orgID])

  useEffect(() => {
    if(props.history.location.state) {
        if(props.history.location.state.success) {
          doToast(props.history.location.state.inviteMessage);
        }else {
          doToast(props.history.location.state.inviteMessage, 'fail');
        }

        window.history.pushState(null, '');
    }
  }, [props.history])

  let onChangeSearch = (data) => {
    setMembers(data);
  }

  // let onChangePage = () => {
  //   console.log('change page')
  // }

  let onClickResendInvitation = (email, rowID) => {
    // let targetResendButton = document.querySelector(`resend-invitation-${rowID}`);
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    let body = {
      email: email
    };

    MemberServices.resendInvitation(orgID, body, (response) => {
      if (response.dataResult.status === 200) {
        if(response.dataResult.data.success) {
          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Reinvite Member",
              {
                userFullName : myAgentData.account.fullName,
                userID : myAgentData.account.id,
                organizationID : orgID.split("-")[0]
              }
            );
          }
          doToast(response.dataResult.data.message);
        }else {
          doToast(response.dataResult.data.message, 'fail');
        }
      }else {
        doToast(response.dataResult.error.message);
      }
    });
  }

  let toggleModalRemoveMember = (single) => {
    if(!single) {
      setShowPopupRemove(!showPopupRemove);
    }else {
      setShowPopupSingleRemove(!showPopupSingleRemove);
    }
  }

  let onCancelRemove = () => {
    setIsSelect(false);
    setDataToBeRemoved([]);
    setCountRemoveMember(0);

    let allCheckBox = document.querySelectorAll('.custom-table-content input[type="checkbox"]');

    for(let i = 0; i < allCheckBox.length; i++) {
        allCheckBox[i].checked = false;
    }
  }

  let initialState = () => {
    setIsSelect(false);
    setCountRemoveMember(0);
    setDataToBeRemoved([]);
    setShowPopupRemove(false);
    setWaitForResponseGetList(true);
    setWaitForResponseDelete(false);
    setWaitForResponseSingleDelete(false);
    setSingleRemoveMember({
      email: "",
      dataToBeRemoved: []
    })
    setShowPopupSingleRemove(false);
  }

  let deleteMember = (single = false) => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    let _body = null;

    if(!single) {  
      setWaitForResponseDelete(true);

      _body = {
        ids: dataToBeRemoved
      }
    }else {
      setWaitForResponseSingleDelete(true);

      _body = {
        ids: singleRemoveMember.dataToBeRemoved
      }
    }

    MemberServices.deleteMember(orgID, _body, (response) => {
      if (response.dataResult.status === 200) {
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Remove Member",
            {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : orgID.split("-")[0]
            }
          );
        }

        doToast(response.dataResult.data.message);
        initialState();
        getMemberList();
      }else {
        doToast(response.dataResult.error.message);
        setWaitForResponseSingleDelete(false);
        setWaitForResponseDelete(false);
      }
    })
  }

  let getMemberList = () => {
    let _retriveDataError = {...retriveDataError};
    // const { data } = await MemberServices.getList(orgID);

    // if (data.status === 200) {
    //   setMemberOriginals(data.data.members);
    //   setMembers(data.data.members);
    //   setWaitForResponseGetList(false);
    // }else {
    //   doToast(data.error.message);
    //   setWaitForResponseGetList(false);
    // }
    
    MemberServices.getList(orgID, (response) => {
      let dataResult = response.dataResult;

      if (response.dataResult.status === 200) {
        // setMemberOriginals(response.dataResult.data.members);
        setMembers(response.dataResult.data.members);
      }else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setWaitForResponseGetList(false);
    })
  }

  useEffect(() => {
    if (orgID) getMemberList(orgID);
  }, [orgID]);

  let onClickCheckAll = (e) => {
    let targetCheckBox = document.querySelectorAll('.custom-table-content tbody input[type="checkbox"]');

    for(let i = 0; i < targetCheckBox.length; i++) {
        targetCheckBox[i].checked = e.target.checked ? true : false
    }

    let countChecked = document.querySelectorAll('.custom-table-content tbody input[type="checkbox"]:checked').length;

    let _setDataToBeRemoved = members;

    let dataToBeRemoved = _setDataToBeRemoved.map(data => data.rowID);

    setIsSelect(countChecked < 1 ? false : true)
    setCountRemoveMember(countChecked);
    setDataToBeRemoved(e.target.checked ? dataToBeRemoved : []);
  }

  let onClickSingleCheck = (e, id) => {
    let targetCheckBoxAll = document.querySelectorAll('#select-all');
    targetCheckBoxAll[0].checked = false;
    
    targetCheckBoxAll.checked = false;
    
    let countChecked = document.querySelectorAll('.custom-table-content tbody input[type="checkbox"]:checked').length;
    let _dataToBeRemoved = dataToBeRemoved;

    if(e.target.checked) {
      _dataToBeRemoved.push(id);
    }else {
      _dataToBeRemoved.splice(_dataToBeRemoved.indexOf(id), 1);
    }

    setIsSelect(countChecked < 1 ? false : true);
    setCountRemoveMember(countChecked);
    setDataToBeRemoved(_dataToBeRemoved);
  }

  let onClickSingleDelete = (data) => {
    let _data = {
      email: data.accountEmail,
      dataToBeRemoved: [data.rowID]
    }

    setSingleRemoveMember(_data);
    setShowPopupSingleRemove(true);
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <div className="sectionWrap">
            <div className="member-list-title">
              <b>Members</b>
            </div>

            {waitForResponseGetList || retriveDataError.code ? 
              <RetrieveDataLoading 
                isLoading={waitForResponseGetList}
                errorMessage={retriveDataError.message} 
              />
              :
              <React.Fragment>
                <div className="member-list-tool-wrapper">
                  <CustomSearchBox placeholder={"Search for member"}
                                  onChangeSearch={onChangeSearch}
                                  style={{width: '350px'}}
                                  dataToSearch={members} 
                  />

                  {!isSelect ?
                    <Link 
                      to={`/o/${orgID}/organization/members/invite`}
                      onClick={() => {
                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                        
                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                          mixpanel.track(
                            "[Button] Invite Member",
                            {
                              userFullName : myAgentData.account.fullName,
                              userID : myAgentData.account.id,
                              organizationID : orgID.split("-")[0]
                            }
                          );
                        }
                      }}
                    >
                      <ButtonWithLoadingOrIcon 
                          className="orange-button main-button-48 button-invite-member"
                          text="Invite Member"
                          icon={{
                            type: "svg",
                            src: FiPlus
                          }}
                          position="left"
                      />
                    </Link>
                    :
                    <div className="manage-project-tool-wrapper member-tool-wrapper">
                      <div id="button-box-wrapper">
                          <ButtonWithLoadingOrIcon 
                              onClickAction={() => toggleModalRemoveMember(false)}
                              className="red-button main-button-48"
                              text={`Remove ${countRemoveMember} Member${countRemoveMember > 1 ? "s" : ""}`}
                              icon={{
                                type: "svg",
                                src: FiTrash2
                              }}
                              position="right"
                          />

                          <ButtonWithLoadingOrIcon 
                              className="no-fill-button main-button-48"
                              text={`Cancel`}
                              onClickAction={() => onCancelRemove()}
                          />
                      </div>
                    </div>
                  }          
                </div>

                <div className="member-list-table-wrapper">
                  <div className="custom-table">
                      <table className="custom-table-content table-member-list">
                          <thead>
                              <tr>
                                <td className="col_full_name">
                                  <div className="custom-checkbox">
                                      <input type="checkbox" 
                                              id="select-all"
                                              onChange={(e) => onClickCheckAll(e)}
                                      />
                                      <label htmlFor="select-all"></label>
                                  </div>
                                  Full Name
                                </td>
                                <td className="col_email">Email</td>
                                <td className="col_role">Role &amp; Access</td>
                                {/* <td className="col_action"></td> */}
                              </tr>
                          </thead>
                          
                          {members.length < 1 ?
                            <tbody>
                              <tr>
                                <td colSpan="3" className="no-data-column"><b className="no-result-found">No results found</b></td>
                              </tr>
                            </tbody>
                            :
                            <tbody>
                              {members.map((value, index) => {
                                return (
                                  <tr key={`member-${index}`}>
                                      <td className="col_full_name">
                                        <div className="custom-checkbox">
                                          <input type="checkbox" 
                                                id={`select-member-${value.rowID}`}
                                                onChange={(e) => onClickSingleCheck(e, value.rowID)}
                                          />
                                          <label htmlFor={`select-member-${value.rowID}`}></label>
                                        </div>
                                        {value.accountName}
                                      </td>
                                      <td className="col_email">{value.accountEmail}</td>
                                      <td className={`col_role agent-${value.rowID}`}>
                                          <div className="col-action-button-wrapper">
                                            {value.accountID === 0 &&
                                              // <div className={`no-background-and-border-button resend-inviation-${value.rowID} resend-inviation-button`}>
                                                  <button className={`resent-invitation`} 
                                                      onClick={() => {
                                                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                                        
                                                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                          mixpanel.track(
                                                            "[Button] Reinvite Member",
                                                            {
                                                              userFullName : myAgentData.account.fullName,
                                                              userID : myAgentData.account.id,
                                                              organizationID : orgID.split("-")[0]
                                                            }
                                                          );
                                                        }
                                                        onClickResendInvitation(value.accountEmail, value.rowID)
                                                      }}
                                                  >
                                                      {/* <IoIosRefresh size={18} /> */}
                                                      <img src={iconPaperplaneOrange} alt="" />
                                                      <b>Re-invite</b>
                                                  </button>

                                                  // <button className={`no-fill-button resend-invitation-loading`}>
                                                  //     <div className="lds-ring">
                                                  //       <div /><div /><div /><div />
                                                  //     </div>
                                                  //     <b>Sending Invitation</b>
                                                  // </button>
                                              // </div>
                                            }

                                            {value.accountID !== 0 &&
                                              <Link to={{
                                                  pathname: `/o/${orgID}/organization/members/detail/${value.rowID}`,
                                                  state: {
                                                    data: null
                                                  }
                                                }}
                                                onClick={() => {
                                                  let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                                  
                                                  if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                    mixpanel.track(
                                                      "[Button] Member Details",
                                                      {
                                                        userFullName : myAgentData.account.fullName,
                                                        userID : myAgentData.account.id,
                                                        organizationID : orgID.split("-")[0]
                                                      }
                                                    );
                                                  }
                                                }}
                                              >
                                                <img className="view-agent" src={iconEyeOrange} alt="" />
                                                <b className="view-agent">View</b>
                                              </Link>
                                            }

                                            <button className="single-remove-button no-background-and-border-button" onClick={() => {
                                              let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                              
                                              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                mixpanel.track(
                                                  "[Button] Remove Member",
                                                  {
                                                    userFullName : myAgentData.account.fullName,
                                                    userID : myAgentData.account.id,
                                                    organizationID : orgID.split("-")[0]
                                                  }
                                                );
                                              }
                                              onClickSingleDelete(value);
                                            }}>
                                              <img className="view-agent" src={IconRemoveRed} alt="" />
                                              <b>Delete</b>
                                            </button>
                                          </div>
                                          {value.roleName}
                                      </td>
                                  </tr>
                                )
                              })}
                            </tbody>
                        }
                      </table>
                  </div>
                    
                  {/* <Pagination total={100} 
                              page={1} 
                              listPerPage={8} 
                              step={3} 
                              onChangePage={onChangePage} 
                  /> */}

                  {/* {showPopupRemove && */}
                    <PopupRemove removeTitle={`Remove ${countRemoveMember} member${countRemoveMember > 1 ? "s" : ""}?`}
                                removeDescription={`${countRemoveMember} member${countRemoveMember > 1 ? "s" : ""} will be removed from this organization`}
                                onClickCancel={() => toggleModalRemoveMember(false)}
                                onClickRemove={() => deleteMember(false)}
                                waitForResponse={waitForResponseDelete}
                                showModalProps={showPopupRemove}
                    />
                  {/* } */}

                  {/* {showPopupSingleRemove && */}
                    <PopupRemove removeTitle={`Remove ${singleRemoveMember.email}?`}
                                removeDescription={`this account will be removed from this organization`}
                                onClickCancel={() => toggleModalRemoveMember(true)}
                                onClickRemove={() => deleteMember(true)}
                                waitForResponse={waitForResponseSingleDelete}
                                showModalProps={showPopupSingleRemove}
                    />
                  {/* } */}
                </div>
              </React.Fragment>
            }
        </div>
      </SectionWrap>
    </React.Fragment>
  );
};

export default Members;
