// import axios from "axios";
// import getHeaderServices from "./headerServices";
import BaseService from "../BaseService";
import { API_KEY } from "../../config";

// const setUrl = `${process.env.REACT_APP_ENVIRONMENT_API}/organization/topic/`;
// const getHeader = getHeaderServices;

const topicServices = {
  addTopic(params, idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_TOPIC, params, headers, callback);
  },

  getTopic(idOrTime, type, id, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    if(type === "details") {
      BaseService.doPostAccessToken(API.GET_TOPIC_DETAIL, { id }, headers, callback);
    }else {
      BaseService.doPostAccessToken(API.GET_TOPIC_LIST, {}, headers, callback);
    }
  },

  delTopic(id, createdTime, idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_TOPIC, { id, createdTime }, headers, callback);
  },

  editTopic(idOrTime, id, name, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.EDIT_TOPIC, { id, name }, headers, callback);
  },

  addAgent(body, idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.ADD_AGENT, body, headers, callback);
  },

  delAgent(body, idOrTime, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.DELETE_AGENT, body, headers, callback);
  }
};


const API = {
  ADD_TOPIC: "/organization/topic/create",
  GET_TOPIC_DETAIL: "/organization/topic/get_details",
  GET_TOPIC_LIST: "/organization/topic/get_list",
  DELETE_TOPIC: "/organization/topic/delete",
  EDIT_TOPIC: "/organization/topic/edit",
  ADD_AGENT: "/organization/topic/agent/add",
  DELETE_AGENT: "/organization/topic/agent/remove",
}

export default topicServices;
