import React from "react";
import "./SelectTopic.scss";
import { FiSearch, FiX } from "react-icons/fi";
import iconList from '../../../assets/img/icon-list.png'

const SelectTopic = ({
    onChangeSearchTopic,
    onCheckAll,
    onClearAll,
    topicList,
    onChangeSingleSelect,
    isChecked,
    searchTopic,
    isCheckAll,
    title,
    isTitleOptional,
    selectedTopic,
    removeSingleTopic,
    _className,
    ...props
}) => {
    return (
        <div className={`option-select-topic-wrapper ${_className}`}>
            <div className="option-select-topic-wrapper-50">
                <label>
                    <b>{title}</b>{isTitleOptional ? <span> (optional)</span> : ""}
                </label>
        
                <div className="selection-topic-wrapper custom-checkbox">
                    <div className="search-box-wrapper">
                        <input type="text"
                            placeholder={"Search Topic"}
                            onChange={(e) => onChangeSearchTopic(e)}
                            value={searchTopic}
                        />

                        <div id="search-icon">
                            <FiSearch size="20" color="#191919" />
                        </div>
                    </div>

                    <div className="overflow-select-topic">
                        {searchTopic === "" &&
                            <div className="select-content custom-checkbox custom-checkbox-min checkbox-all">
                                <input type="checkbox" id="select-all-topic" onChange={(e) => onCheckAll(e)} checked={isCheckAll} />
                                <label htmlFor="select-all-topic">Select All</label>
                            </div>
                        }
                        {topicList.length > 0 ?
                            topicList.map((value, index) => {
                                return (
                                    <div className="select-content" key={`topic-${index}`}>
                                        <input 
                                            type="checkbox" 
                                            id={value.id} 
                                            onChange={(e) => {onChangeSingleSelect(e, value)}} 
                                            checked={isChecked(value.id)} 
                                        />
                                        <label htmlFor={value.id}>{value.name}</label>
                                    </div>
                                )
                        })
                        :
                        <div className="select-content not-found">
                            No Topic Found
                        </div>
                        }
                    </div>
                </div>
            </div>

            <div className="option-select-topic-wrapper-50">
                <label>
                    <b>Selected</b> <span>({selectedTopic.length})</span>

                    {selectedTopic.length > 0 &&
                        <b className="clear-topic" onClick={onClearAll}>
                            <FiX />
                            Clear All
                        </b>
                    }
                </label>

                <div className="select-topic-wrapper">
                    <div className="selected-topic-container">
                        {
                        selectedTopic.length > 0 ?
                            selectedTopic.map((value, index) => {
                                return (
                                    <div className="topic-list-content" key={`selected-topic-${index}`}>
                                        <b>{value.name}</b>

                                        <div className="remove-topic" onClick={() => removeSingleTopic(value)}>
                                            <FiX />
                                        </div>
                                    </div>
                                )
                            })
                            :
                            <div className="no-selected-topic">
                                <img src={iconList} alt="" />
                                <p className="font-root-medium">Assigned topics will be displayed here.</p>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SelectTopic;