import React, { useState, useEffect } from "react";
import "../../auth/Auth.css";
import "./MemberDetail.scss";
import { FiList, FiSearch, FiX } from "react-icons/fi";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast } from "../../../helper/HelperGeneral";
import iconName from "../../../assets/img/icon-name.svg";
import iconEmail from "../../../assets/img/icon-email.svg";
import iconRole from "../../../assets/img/icon-role.svg";
import iconEdit from "../../../assets/img/icon-edit.svg";
import iconTopicList from "../../../assets/img/icon-topic-list.svg";
// import dummyUserAvatar from "../../../assets/img/dummy-user-avatar.svg";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import MemberServices from "../../../services/newServices/MemberServices";
import TopicService from "../../../services/newServices/TopicServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const MEMBER_ROLE = {
  admin: {
    code: "admin",
    name: "Admin"
  },
  member: {
    code: "member",
    name: "Agent"
  }
}

const MemberDetail = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [memberID, setMemberID] = useState(null);
  let [waitForResponseDetailMember, setWaitForResponseDetailMember] = useState(true);
  let [memberDetail, setMemberDetail] = useState(null);
  let [memberDetailOrigin, setMemberDetailOrigin] = useState(null);
  let [showModalChangeRole, setShowModalChangeRole] = useState(false);
  let [showModalChangeTopic, setShowModalChangeTopic] = useState(false);
  let [memberRole, setMemberRole] = useState("");
  let [memberRoleOrigin, setMemberRoleOrigin] = useState("");
  let [waitForResponseChangeRole, setWaitForResponseChangeRole] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [selectedTopic, setSelectedTopic] = useState([]);
  let [isCheckAll, setIsCheckAll] = useState(false);
  let [topicListOrigin, setTopicListOrigin] = useState([]);
  let [topicList, setTopicList] = useState([]);
  let [searchTopic, setSearchTopic] = useState("");
  let [memberTopics, setMemberTopics] = useState([])


  const back = () => history.push(`/o/${orgID}/organization/members`);

  const doMount = async () => {
    const paramID = checkID(match);
    const _memberID = props.match.params.memberID;
    setMemberID(_memberID)
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/members`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  useEffect(() => {
    if (orgID) {
      getTopic();
    }
  }, [orgID])

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if (orgID) {
      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
        mixpanel.track(
          "[Page] Member Details",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: orgID.split("-")[0]
          }
        );
      }

      getTopic();
    }
  }, [orgID])

  let getTopic = () => {
    setWaitForResponseDetailMember(true);

    TopicService.getTopic(orgID, "list", null, (response) => {
      if (response.dataResult.error.message === "") {
        setTopicListOrigin(response.dataResult.data.topics);
        setTopicList(response.dataResult.data.topics);
        getMemberDetail(orgID);
      } else {
        let _retriveDataError = { ...retriveDataError };
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }
    });
  }

  let getMemberDetail = () => {
    setWaitForResponseDetailMember(true);

    let dataMemberID = {
      id: parseInt(memberID)
    };

    MemberServices.getDetail(orgID, dataMemberID, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        setMemberTopics(response.dataResult.data.topics)
        setMemberDetail(response.dataResult.data.member);
        setMemberDetailOrigin(response.dataResult.data.member);
        setMemberRole(response.dataResult.data.member.roleCode);
        setMemberRoleOrigin(response.dataResult.data.member.roleCode);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        // doToast(response.dataResult.error.message, "fail");
      }

      setWaitForResponseDetailMember(false);
    })
  }

  let changeMemberRole = () => {
    setWaitForResponseChangeRole(true);
    let dataMember = {
      id: parseInt(memberID),
      roleCode: memberRole
    };

    MemberServices.changeRoleMember(orgID, dataMember, (response) => {
      if (response.dataResult.status === 200) {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
          mixpanel.track(
            "[Action] Change Member Role",
            {
              userFullName: myAgentData.account.fullName,
              userID: myAgentData.account.id,
              organizationID: orgID.split("-")[0]
            }
          );
        }

        let _memberDetail = memberDetail;
        _memberDetail.roleCode = MEMBER_ROLE[memberRole].code;

        _memberDetail.roleName = MEMBER_ROLE[memberRole].name;
        setMemberDetail(_memberDetail);

        // setShowModalChangeRole(false);
        doToast(response.dataResult.data.message);

        setTimeout(() => {
          back();
        }, 3000);
      } else {
        doToast(response.dataResult.error.message, "fail");
        setWaitForResponseChangeRole(false);
        // setWaitForResponseDetailMember(false);
      }
    })
  }

  let toggleModalChangeRole = () => {
    setShowModalChangeRole(!showModalChangeRole);
    setMemberDetail(memberDetailOrigin);
    setMemberRole(memberRoleOrigin);
  }

  let toggleModalChangeTopic = () => {
    setShowModalChangeTopic(!showModalChangeTopic);
  }

  let renderPopupChangeRole = () => {
    let onChangeRoleMember = (e) => {
      setMemberRole(e.target.value);
    };

    // let isDisableChangeRoleSaveChange = () => {
    //   return memberDetail !== null ? memberRole === memberDetail.roleCode : false;
    // }

    return (
      <div>
        <Modal isOpen={showModalChangeRole}
          toggle={toggleModalChangeRole}
          className="modal-member-change-role"
        >
          <ModalBody>
            <p className="modal-member-change-role-title">
              <b>Edit Role &amp; Access</b>
            </p>

            <div className="modal-member-role-wrapper">
              <label><b>Organization Role</b></label>
              <div className="custom-radio">
                <input type="radio"
                  id="role-admin"
                  name="role-radio"
                  value={MEMBER_ROLE.admin.code}
                  onChange={(e) => onChangeRoleMember(e)}
                  checked={memberRole === MEMBER_ROLE.admin.code}
                />
                <label htmlFor="role-admin">
                  <div className="role-radio-label">
                    <b>Admin</b>
                    <span>Access to organization including billing, technical, integrations, inbox and quick reply</span>
                  </div>
                </label>

                <div className="change-role-gap" />

                <input type="radio"
                  id="role-agent"
                  name="role-radio"
                  value={MEMBER_ROLE.member.code}
                  onChange={(e) => onChangeRoleMember(e)}
                  checked={memberRole === MEMBER_ROLE.member.code}
                />
                <label htmlFor="role-agent">
                  <div className="role-radio-label">
                    <b>Agent</b>
                    <span>Access to inbox and personal quick reply</span>
                  </div>
                </label>
              </div>
            </div>
          </ModalBody>

          <ModalFooter>
            <ButtonWithLoadingOrIcon
              className="no-fill-button main-button-48"
              text="Cancel"
              onClickAction={toggleModalChangeRole}
            />

            {waitForResponseChangeRole ?
              <ButtonWithLoadingOrIcon
                isLoading
                loadingColor="gray"
                isDisabled
                position="left"
                className="orange-button main-button-48"
                text="Save Changes"
              />
              :
              <ButtonWithLoadingOrIcon
                className="orange-button main-button-48"
                onClickAction={changeMemberRole}
                text="Save Changes"
                // isDisabled={isDisableChangeRoleSaveChange()}
              />
            }
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  const changeTopicMember = () => {
    console.log('api change topic member')
  }

  let renderModalTopic = () => {
    let onCheckAll = (e) => {
      if (e.target.checked) {
        setSelectedTopic(topicList);
      } else {
        setSelectedTopic([]);
      }

      setIsCheckAll(e.target.checked);
    }

    let isChecked = (id) => {
      let _findIndex = selectedTopic.findIndex(_val => _val.id === id);

      return _findIndex !== -1;
    }

    let onChangeSingleSelect = (e, val) => {
      let _findIndex = topicList.findIndex(_val => _val.id === val.id);
      let _selectedTopic = selectedTopic.slice();

      if (e.target.checked) {
        _selectedTopic.push(val);
      } else {
        setIsCheckAll(false);

        _selectedTopic.splice(_findIndex, 1);
      }

      setSelectedTopic(_selectedTopic);
    }

    let removeSingleTopic = (val) => {
      let _findIndex = topicList.findIndex(_val => _val.id === val.id);
      let _selectedTopic = selectedTopic.slice();
      setIsCheckAll(false);
      _selectedTopic.splice(_findIndex, 1);
      setSelectedTopic(_selectedTopic);
    }

    let onChangeSearchTopic = (e) => {
      let _topicOrigin = topicListOrigin.slice();
      let result = [];
      setSearchTopic(e.target.value);

      if (_topicOrigin.length > 0) {
        for (let i in _topicOrigin) {
          let objectKey = Object.keys(_topicOrigin[i]);

          for (let j in objectKey) {
            let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
            if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
              result.push(_topicOrigin[i])
              break;
            }
          }
        }
      }

      setTopicList(result);
    }

    return (
      <Modal isOpen={showModalChangeTopic} className="modal-edit-topic" toggle={toggleModalChangeTopic}>
        <ModalBody>
          <p className="modal-member-change-role-title">
            <FiList />
            <b>Edit Assigned Topics</b>
          </p>

          <div className="invite-member-select-topic-wrapper">
            <div className="invite-member-select-topic-wrapper-50">
              <label><b>Assign Topics</b> <span>(optional)</span></label>

              <div className="selection-topic-wrapper custom-checkbox">
                <div className="search-box-wrapper">
                  <input type="text"
                    placeholder={"Search Topic"}
                    onChange={(e) => onChangeSearchTopic(e)}
                    value={searchTopic}
                  />

                  <div id="search-icon">
                    <FiSearch size="20" color="#191919" />
                  </div>
                </div>

                <div className="overflow-select-topic">
                  {searchTopic === "" &&
                    <div className="select-content custom-checkbox custom-checkbox-min checkbox-all">
                      <input type="checkbox" id="select-all-topic" onChange={(e) => onCheckAll(e)} checked={isCheckAll} />
                      <label htmlFor="select-all-topic">Select All</label>
                    </div>
                  }
                  {topicList.length > 0 ?
                    topicList.map((value, index) => {
                      return (
                        <div className="select-content" key={`topic-${index}`}>
                          <input type="checkbox" id={value.id} onChange={(e) => onChangeSingleSelect(e, value)} checked={isChecked(value.id)} />
                          <label htmlFor={value.id}>{value.name}</label>
                        </div>
                      )
                    })
                    :
                    <div className="select-content not-found">
                      No Topic Found
                        </div>
                  }
                </div>
              </div>
            </div>

            <div className="invite-member-select-topic-wrapper-50">
              <label><b>Selected</b> <span>({selectedTopic.length})</span></label>

              <div className="select-topic-wrapper">
                <div className="selected-topic-container">
                  {selectedTopic.map((value, index) => {
                    return (
                      <div className="topic-list-content" key={`selected-topic-${index}`}>
                        <b>{value.name}</b>

                        <div className="remove-topic" onClick={() => removeSingleTopic(value)}>
                          <FiX />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>

        <ModalFooter>
          <ButtonWithLoadingOrIcon
            className="no-fill-button main-button-48"
            text="Cancel"
            onClickAction={toggleModalChangeTopic}
          />

          {waitForResponseChangeRole ?
            <ButtonWithLoadingOrIcon
              isLoading
              loadingColor="gray"
              isDisabled
              position="left"
              className="orange-button main-button-48"
              text="Save Changes"
            />
            :
            <ButtonWithLoadingOrIcon
              onClickAction={changeTopicMember}
              className="orange-button main-button-48"
              text="Save Changes"
            />
          }
        </ModalFooter>
      </Modal>
    )
  }

  return (
    <React.Fragment>
      <SectionWrap
        {...props}
        orgID={orgID}
        withHeader
        withSideMenu
      >
        <BackButton text="Back to Members" onClick={back} />

        <div className="sectionWrap">
          <div id="member-detail-wrapper">
            {waitForResponseDetailMember || retriveDataError.code ?
              <RetrieveDataLoading
                noPaddingTop={true}
                isLoading={waitForResponseDetailMember}
                errorMessage={retriveDataError.message}
              />
              :
              <React.Fragment>
                <p className="member-detail-title">
                  <b>Edit Member</b>
                </p>

                {/* <img src={dummyUserAvatar} alt="" className="member-detail-avatar" /> */}

                <div id="member-value-wrapper">
                  <div className="member-value-content">
                    <div className="icon-wrapper icon-name">
                      <img src={iconName} alt="" />
                    </div>

                    <div className="member-info-wrapper">
                      <label><b>Full Name</b></label>
                      <b className="member-detail-value">{memberDetail.accountName}</b>
                    </div>
                  </div>

                  <div className="member-value-content">
                    <div className="icon-wrapper icon-email">
                      <img src={iconEmail} alt="" />
                    </div>

                    <div className="member-info-wrapper">
                      <label><b>Email</b></label>
                      <b className="member-detail-value">{memberDetail.accountEmail}</b>
                    </div>
                  </div>

                  <div className="member-value-content">
                    <div className="icon-wrapper icon-role">
                      <img src={iconRole} alt="" />
                    </div>

                    <div className="member-info-wrapper">
                      <label><b>Role &amp; Access</b></label>
                      <b className="member-detail-value">{memberDetail.roleName}</b>
                      <p className="access-to-inbox-label">
                        {memberDetail.roleCode === "member" ?
                          "Access to inbox and Quick Reply Personal"
                          :
                          "Access to organization including billing, technical, integrations, inbox and quick reply"
                        }
                      </p>

                      {memberDetail.roleCode !== "owner" &&
                        <button className="no-fill-button member-detail-edit-role"
                          onClick={() => {
                            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                              mixpanel.track(
                                "[Button] Change Member Role",
                                {
                                  userFullName: myAgentData.account.fullName,
                                  userID: myAgentData.account.id,
                                  organizationID: orgID.split("-")[0]
                                }
                              );
                            }

                            toggleModalChangeRole()
                          }}
                        >
                          <img src={iconEdit} alt="" />
                          Edit Role &amp; Access
                        </button>
                      }
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>

        {!waitForResponseDetailMember && !retriveDataError.code &&
          <div className="sectionWrap">
            <div className="form-title-wrapper assigned-topic-member">
              <b>Assigned Topics</b>

              {/* <ButtonWithLoadingOrIcon
                className="no-fill-button main-button-40"
                icon={{
                  type: 'img',
                  src: iconEdit
                }}
                position='left'
                text='Edit Assigned Topics'
                onClickAction={toggleModalChangeTopic}
              /> */}
            </div>
            {
              memberTopics.length > 0 ?
                <div className="topic-list-wrapper">
                  {
                    memberTopics.map(topic => {
                      return (
                        <div className="topic-list-content" key={`topic-${topic.id}`}>
                          <b>{topic.name}</b>
                        </div>
                      )
                    })
                  }
                </div>
                :
                <div className="topic-no-list">
                  <div className="topic-no-list-icon-wrapper">
                    <img src={iconTopicList} alt="" />
                  </div>
                  <br />
                  <b>
                    Assigned topics will be displayed here.
                </b>
                </div>
            }
          </div>
        }
      </SectionWrap>
      {renderPopupChangeRole()}
      {renderModalTopic()}
    </React.Fragment>
  );
};

export default MemberDetail;