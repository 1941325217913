import React, { useState, useEffect } from 'react';
import './AddContactSegment.scss'
import SectionWrap from '../../reuseableComponent/sectionWrap/SectionWrap'
import BackButton from '../../reuseableComponent/BackButton/BackButton'
import { FiSearch, FiX } from 'react-icons/fi'
import CustomSelectOption from '../../reuseableComponent/customSelectOption/CustomSelectOption'
import { IoMdCloseCircle } from 'react-icons/io'
import iconNoList from '../../../assets/img/user-segment/icon-no-list.svg'
import ServiceOrganization from '../../../services/organization/ServiceOrganization'
import UserContactServices from '../../../services/newServices/UserContactServices'
import UserSegmentServices from '../../../services/newServices/UserSegmentServices'
import RetrieveDataLoading from '../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading'
import { checkID, doToast } from '../../../helper/HelperGeneral';
import HelperInput from '../../../helper/HelperInput';
import Pagination from '../../reuseableComponent/pagination/Pagination'
import iconSelectedContact from '../../../assets/img/user-segment/icon-selected-contact.svg'
// import LoadingButton from '../../reuseableComponent/loadingButton/LoadingButton'
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'

const AddContactSegment = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isSearchFocus, setIsSearchFocus] = useState(false)
  const [query, setQuery] = useState("")
  const [submitQuery, setSubmitQuery] = useState("")
  const [pageDisplay, setPageDisplay] = useState(1)
  const [listPageVal, setListPageVal] = useState({
    label: '20',
    value: 20,
  })
  // let [isSelect, setIsSelect] = useState(false);
  let [countAddContact, setCountAddContact] = useState(0);
  let [dataToBeAdded, setDataToBeAdded] = useState([]);
  let [dataToBeAddedOrigin, setDataToBeAddedOrigin] = useState([]);
  const [dataUserContact, setDataUserContact] = useState([])
  const [isLoadingUserContact, setIsLoadingUserContact] = useState(false)
  const [isLoadingTableUser, setIsLoadingTableUser] = useState(false)
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [listPage, setListPage] = useState(20)
  const [totalUsers, setTotalUsers] = useState(0)
  const [isLoadingAddContact, setIsLoadingAddContact] = useState(false)

  const listPageOption = [
    {
      label: '20',
      value: 20,
    },
    {
      label: '50',
      value: 50,
    },
    {
      label: '100',
      value: 100,
    },
  ]

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount()
  }, [])

  const back = () => history.push(`/o/${orgID}/operational/user-segment/${match.params.id}`)

  const onChangeSearch = (e) => {
    setQuery(e.target.value)
  }

  const onSubmitSearch = (e) => {
    e.preventDefault()
    const data = {
      pageDisplay: 1,
      listPage: listPage,
      submitQuery: query
    }
    const paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setPageDisplay(1)
    setSubmitQuery(query)
  }

  const onClickCheckAll = (e) => {
    if(!isLoadingAddContact) {
      let targetCheckBox = document.querySelectorAll('.custom-table-content tbody input[type="checkbox"]');
  
      targetCheckBox.forEach(item => {
        item.checked = e.target.checked ? true : false
      })
  
      let _dataToBeAdded = [...dataUserContact];
  
      if (e.target.checked) {
        // filter _dataToBeAdedd with dataToBeAdded
        const _dataToSelect = _dataToBeAdded.filter((el) => {
          return !dataToBeAdded.find(user => user.userID === el.userID)
        });
  
        const _dataSelect = [...dataToBeAdded, ..._dataToSelect]
        setDataToBeAdded(_dataSelect);
        setCountAddContact(_dataSelect.length);
      } else {
        const _dataUnSelect = dataToBeAdded.filter((el) => {
          return !_dataToBeAdded.find(user => user.userID === el.userID)
        });
        setDataToBeAdded([..._dataUnSelect]);
        setCountAddContact(_dataUnSelect.length);
      }
    }
  }

  let onClickSingleCheck = (e, item) => {
    if(!isLoadingAddContact) {
      let targetCheckBoxAll = document.querySelectorAll('#select-all');
      targetCheckBoxAll[0].checked = false;
  
      targetCheckBoxAll.checked = false;
  
      let _dataToBeAdded = [...dataToBeAdded];
  
      if (e.target.checked) {
        _dataToBeAdded = [..._dataToBeAdded, item]
      } else {
        const _dataRemove = [..._dataToBeAdded]
        _dataToBeAdded = _dataRemove.filter(el => el.userID !== item.userID)
      }
  
      setCountAddContact(_dataToBeAdded.length);
      setDataToBeAdded(_dataToBeAdded);
    }
  }

  const onCheckedInputAll = () => {
    let isChecked = true;
    if (!isLoadingTableUser) {
      if (dataUserContact.length === 0) {
        isChecked = false;
        return;
      }

      const _data = [...dataToBeAdded]
      const _dataSegment = [...dataUserContact]

      _dataSegment.forEach(item => {
        if (_data.findIndex(val => val.userID === item.userID) === -1) {
          isChecked = false
        }
      })

      return isChecked
    }
  }

  const onChecked = (id) => {
    const _data = [...dataToBeAdded]
    return _data.findIndex(val => val.userID === id) !== -1;
  }

  const removeSelectedContact = (item) => {
    let _dataToBeAdded = [...dataToBeAdded];
    const _dataRemove = [..._dataToBeAdded]

    _dataToBeAdded = _dataRemove.filter(el => el.userID !== item.userID)

    setCountAddContact(_dataToBeAdded.length);
    setDataToBeAdded(_dataToBeAdded);
  }

  const clearAllSelected = () => {
    setDataToBeAdded([]);
    setCountAddContact(0);

    let allCheckBox = document.querySelectorAll('.custom-table-content input[type="checkbox"]');

    for (let i = 0; i < allCheckBox.length; i++) {
      allCheckBox[i].checked = false;
    }
  }


  const onSelectListPage = (val) => {
    const data = {
      pageDisplay: 1,
      listPage: val.value,
      submitQuery: submitQuery
    }
    const paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setListPageVal(val)
    setListPage(val.value)
    setPageDisplay(1)
  }

  const getUserContactList = (orgID, onload, dataContact = {}) => {
    let _retriveDataError = { ...retriveDataError };
    if (onload) {
      setIsLoadingUserContact(true);
    } else {
      setIsLoadingTableUser(true);
    }

    let data = {}
    if (Object.keys(dataContact).length === 0) {
      data.pageDisplay = pageDisplay
      data.listPage = listPage
      data.submitQuery = submitQuery
    } else {
      data.pageDisplay = dataContact.pageDisplay
      data.listPage = dataContact.listPage
      data.submitQuery = dataContact.submitQuery
    }

    UserContactServices.getContactList(orgID, data.pageDisplay, data.listPage, data.submitQuery, (response) => {
      if (response.dataResult.status === 200) {
        setDataUserContact(response.dataResult.data.users)
        setTotalUsers(response.dataResult.data.totalUsers)
        setIsLoadingUserContact(false);
        setIsLoadingTableUser(false);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  };

  useEffect(() => {
    const paramID = checkID(match);
    getUserContactList(paramID, true)
  }, [])

  const handleChangePage = (page) => {
    const data = {
      pageDisplay: page,
      listPage: listPage,
      submitQuery: submitQuery
    }
    const paramID = checkID(match);
    getUserContactList(paramID, false, data);
    setPageDisplay(page)
  }

  const addContactIntoSegment = () => {
    setIsLoadingAddContact(true)

    const contactSelected = dataToBeAdded.map(item => item.userID)
    const segmentID = match.params.id

    UserSegmentServices.addUsersIntoSegment(orgID, segmentID, contactSelected, (response) => {
      if (response.dataResult.status === 200) {
        setIsLoadingAddContact(false)
        if (!response.dataResult.data.success) {
          doToast(response.dataResult.data.message);
        } else {
          setDataToBeAddedOrigin(dataToBeAdded);
          setTimeout(() => {
            history.push(
              `/o/${orgID}/operational/user-segment/${segmentID}`
            );
          }, 0);
          doToast(response.dataResult.data.message);
        }
      } else {
        setIsLoadingAddContact(false);
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  }

  return (
    <SectionWrap 
      {...props} 
      orgID={orgID} 
      withHeader 
      withSideMenu
      dataOrigin={{data: dataToBeAddedOrigin}}
      dataAfterChange={{data: dataToBeAdded}}
    >
      {
        (isLoadingUserContact || retriveDataError.code) ?
          <RetrieveDataLoading
            isLoading={isLoadingUserContact}
            errorMessage={retriveDataError.message}
          />
          :
          <>
            <div className="header-add-contact-container">
              <BackButton text="Back to Contact Segments" onClick={back} />
              {
                isLoadingAddContact ?
                  <ButtonWithLoadingOrIcon
                    isLoading
                    loadingColor="gray"
                    isDisabled
                    className="orange-button main-button-40"
                    text="Add Contacts"
                    position="left"
                  />
                  :
                  <ButtonWithLoadingOrIcon
                    className="orange-button main-button-40"
                    text="Add Contacts"
                    onClickAction={addContactIntoSegment}
                    // isDisabled={dataToBeAdded.length < 1}
                  />
              }
            </div>
            <div className="add-contact-main-container">
              <div className="sectionWrap add-contact-segment-main-wrapper">
                <div className="topContent add-contact-segment-header">
                  <div className="title">Add Contacts Into Segment</div>
                </div>
                <form
                  className={`add-contact-segment-search ${isSearchFocus ? 'focus-search' : ''}`}
                  onFocus={() => setIsSearchFocus(true)}
                  onBlur={() => setIsSearchFocus(false)}
                  onSubmit={onSubmitSearch}
                >
                  <input type="text" placeholder="Search for a contact" value={query} onChange={onChangeSearch} />
                  <span onClick={onSubmitSearch}>
                    <FiSearch />
                  </span>
                </form>
                {
                  (isLoadingTableUser || retriveDataError.code) ?
                    <RetrieveDataLoading
                      isLoading={isLoadingTableUser}
                      errorMessage={retriveDataError.message}
                    />
                    :
                    // data on table
                    <div className="user-contact-segment-list-table-wrapper">
                      <div className="custom-table">
                        <table className="custom-table-content table-user-contact-segment-list">
                          <thead>
                            <tr>
                              <td className="col_full_name">
                                <div className="custom-checkbox">
                                  <input type="checkbox"
                                    id="select-all"
                                    onChange={(e) => onClickCheckAll(e)}
                                    checked={onCheckedInputAll()}
                                  />
                                  <label htmlFor="select-all"></label>
                                </div>
                                Full Name
                              </td>
                              <td className="col_phone_number">Phone Number</td>
                              <td className="col_select_list">
                                <CustomSelectOption
                                  optionListProps={listPageOption}
                                  valueDropdownProps={listPageVal}
                                  onClickDropDownListOptionProps={onSelectListPage}
                                />
                              </td>
                            </tr>
                          </thead>

                          <tbody>
                            {
                              dataUserContact.length > 0 ?
                                dataUserContact.map(item => {
                                  return (
                                    <tr
                                      key={`user-contact-${item.userID}`}
                                    >
                                      <td className="col_full_name">
                                        <div className="custom-checkbox">
                                          <input type="checkbox"
                                            id={`select-member-${item.userID}`}
                                            onChange={(e) => onClickSingleCheck(e, item)}
                                            checked={onChecked(item.userID)}
                                          />
                                          <label htmlFor={`select-member-${item.userID}`}></label>
                                        </div>
                                        <p>{item.fullName}</p>
                                      </td>
                                      <td className="col_phone">
                                        {item.phone ? "+"+HelperInput.phoneBeautify(item.phone) : "-"}
                                      </td>
                                      <td className="col-action">
                                      </td>
                                    </tr>
                                  )
                                })
                                :
                                <tr>
                                  <td colSpan="6" className="no-data-column">
                                    <div className="no-contact-wrapper">
                                      <img src={iconNoList} alt="" className='no-contact-found-image' />
                                      <p>No contacts found.</p>
                                    </div>
                                  </td>
                                </tr>
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                }

                {
                  dataUserContact.length > 0 &&
                  <div className="pagination-wrapper">
                    <Pagination
                      total={totalUsers}
                      page={pageDisplay}
                      listPerPage={listPage}
                      step={1}
                      onChangePage={handleChangePage}
                    ></Pagination>
                  </div>
                }
              </div>
              <div className="sectionWrap selected-contact-segment-main-wrapper">
                <div className="topContent selected-contact-segment-header">
                  <div className="title">Selected ({countAddContact})</div>
                  {
                    dataToBeAdded.length > 0 &&
                    <p className="clear-all" onClick={clearAllSelected}>
                      <b>
                        <FiX />
                      Clear All
                    </b>
                    </p>
                  }
                </div>
                <div className="contact-selected-wrapper">
                  {
                    dataToBeAdded.length > 0 ?
                      dataToBeAdded.map((item, id) => {
                        return (
                          <div className="contact-item-selected" key={`contact-selected-${id}`}>
                            <IoMdCloseCircle
                              onClick={() => removeSelectedContact(item)}
                            />
                            <b>{item.fullName}</b>
                          </div>
                        )
                      })
                      :
                      <div className="no-selected-contact-wrapper">
                        <img src={iconSelectedContact} alt="" />
                        <p>Selected contacts will be displayed here.</p>
                      </div>
                  }
                </div>
              </div>
            </div>
          </>
      }
    </SectionWrap>
  );
};

export default AddContactSegment;