import React from "react";
import AscendingDescendingTable from "../AscendingDescendingTable/AscendingDescendingTable";
import "./AscendingDescendingTableHeader.scss";

const AscendingDescendingTableHeader = ({ 
    className,
    fieldName,
    title,
    onClick,
    sortByVal
}) => {
  return (
  <td className={`sort-table-header ${className}`} onClick={onClick}>
    <b>{title}</b>
    <AscendingDescendingTable 
      onDescending={() => onClick({by: fieldName, order: "DESC"})}
      onAscending={() => onClick({by: fieldName, order: "ASC"})}
      fieldName={fieldName}
      sortVal={sortByVal}
    />
  </td>
 ) 
};

export default AscendingDescendingTableHeader;
