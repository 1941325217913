import React, { useState } from "react";
import { withFormik } from "formik";
import { FiInfo } from "react-icons/fi";
// import NewButton from "../reuseableComponent/NewButton/NewButton";
import ErrorOrInfoComp from "../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
// import FormInput from "../reuseableComponent/FormInput/FormInput";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import topicServices from "../../services/newServices/TopicServices";
import { doToast } from "../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const TDTopicsView = props => {
  const { handleChange, values, errors, handleSubmit } = props;
  // const { name, copyName, loading } = values;
  const { name, loading } = values;
  // const isDisabled = name === copyName || loading || !name || name.length < 3;
  const [errorMessageData, setErrorMessageData] = useState({
    topicName: ""
  })
  // const textBtn = loading ? <Loading /> : "Save Changes";

  let handleErrorTopicName = (e) => {
    e.preventDefault();
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.topicName ="This field is required";
    setErrorMessageData(_errorMessageData);
  }

  let handleErrorTopicNameMaxLength = (e) => {
    e.preventDefault();
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.topicName ="Characters exceed limit";
    setErrorMessageData(_errorMessageData);
  }

  return (
    <div className="topic__bodyContent form form-topic-detail">
      <form className="formContent" onSubmit={name === "" ? handleErrorTopicName : (name.length > 50 ? handleErrorTopicNameMaxLength : handleSubmit)}>
        <div className="input-wrapper margin-top-0">
          <label className="pos-relative">
            <b>Topic Name</b>
            <span className={errorMessageData.topicName === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{name.length}/50</span>
          </label>
          {/* <FormInput
            id="inputTopicName"
            name="name"
            type="text"
            placeholder="e.g. Technical"
            value={name}
            onChange={handleChange}
            error={errors.name}
            maxLength={80}
            className={`add-topic-input-2 ${errorMessageData.topicName !== "" ? "border-red" : ""}`}
            isDisabled={loading}
          /> */}
          <input
            id="inputTopicName"
            name="name"
            type="text"
            placeholder="e.g. Technical"
            value={name}
            onChange={handleChange}
            onKeyDown={() => {
              let _errorMessageData = {...errorMessageData};
              _errorMessageData.topicName = "";
              setErrorMessageData(_errorMessageData);
            }}
            error={errors.name}
            className={`margin-top-0 add-topic-input-2 ${errorMessageData.topicName !== "" ? "border-red" : ""}`}
            isDisabled={loading}
          />
        </div>
        {errorMessageData.topicName !== "" &&
          <ErrorOrInfoComp
            text={errorMessageData.topicName}
            _className={"font-red"}
            icon={<FiInfo />}
          />
        }
        {/* <NewButton type="submit" disabled={isDisabled} text={textBtn} /> */}

        {loading ?
          <ButtonWithLoadingOrIcon
            isLoading
            loadingColor="gray"
            isDisabled
            className="orange-button main-button-40"
            text="Save Changes"
            position="left"
          />
          :
          <ButtonWithLoadingOrIcon
            className="orange-button main-button-40"
            text="Save Changes"
            // isDisabled={isDisabled}
          />
        }
      </form>
    </div>
  );
};

const TDTopicForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => {
    const topic = props.detailsData && props.detailsData.topic;
    const id = (topic && topic.id) || "";
    const name = (topic && topic.name) || "";
    const createdTime = (topic && topic.createdTime) || "";
    return { id, name, copyName: name, createdTime, loading: false };
  },
  validate: values => {
    let errors = {};
    const { name } = values;
    const firstNumber = !name[0].match(/^([^0-9]*)$/);
    if (!name) errors.name = "Please fill topic's name";
    if (name.length < 3) errors.name = "Fill complete name";
    else if (firstNumber) errors.name = "No first number allowed";
  },
  handleSubmit: async (values, { setFieldValue, props }) => {
    document.getElementById("inputTopicName").blur();
    const { orgID, reloadAPI } = props;
    const { id, name } = values;
    setFieldValue("loading", true);

    const waitAPI = setTimeout(() => {
      setFieldValue("loading", false);
      doToast("Failed to update topic name");
    }, 5500);

    topicServices.editTopic(orgID, id, name, (response) => {
      if (response.dataResult.status === 200) {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
          mixpanel.track(
            "[Action] Edit Topic",
            {
              userFullName: myAgentData.account.fullName,
              userID: myAgentData.account.id,
              organizationID: orgID.split("-")[0]
            }
          );
        }

        reloadAPI("Topic name updated");
      } else {
        doToast(response.dataResult.error.message);
      }

      clearTimeout(waitAPI);
      setFieldValue("loading", false);
    });
  }
})(TDTopicsView);

export default TDTopicForm;
