import BaseService from "../BaseService";
import { API_KEY } from "../../config";

const UserSegmentServices = {
  getSegmentList(idOrTime, pageNumber, listPerPage, query, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      search: query,
      pageNumber: pageNumber,
      pageSize: Number(listPerPage)
    };

    BaseService.doPostAccessToken(API.GET_SEGMENT_LIST, data, headers, callback);
  },
  createSegment(idOrTime, segmentName, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      name: segmentName,
    };

    BaseService.doPostAccessToken(API.CREATE_SEGMENT, data, headers, callback);
  },
  deleteSegment(idOrTime, segmentIDs, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      segmentIDs
    };

    BaseService.doPostAccessToken(API.DELETE_SEGMENT, data, headers, callback);
  },
  detailSegment(idOrTime, id, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      id: Number(id)
    };

    BaseService.doPostAccessToken(API.DETAIL_SEGMENT, data, headers, callback);
  },
  getUserList(idOrTime, id, pageNumber, listPerPage, query, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      id: Number(id),
      search: query,
      pageNumber: pageNumber,
      pageSize: Number(listPerPage)
    };

    BaseService.doPostAccessToken(API.GET_USER_LIST, data, headers, callback);
  },
  addUsersIntoSegment(idOrTime, id, userIDs, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      id: Number(id),
      userIDs
    };

    BaseService.doPostAccessToken(API.ADD_USERS, data, headers, callback);
  },
  removeUsersFromSegment(idOrTime, id, userIDs, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      id: Number(id),
      userIDs
    };

    BaseService.doPostAccessToken(API.REMOVE_USERS, data, headers, callback);
  },
  editSegment(idOrTime, id, segmentName, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    const data = {
      id: Number(id),
      name: segmentName
    };

    BaseService.doPostAccessToken(API.EDIT_SEGMENT, data, headers, callback);
  },
  setIntoSegment(idOrTime, data, callback) {
    let headers = {
      "API-Key": API_KEY(),
      "Device-Platform": "web",
      "Organization-ID": idOrTime
    };

    BaseService.doPostAccessToken(API.SET_SEGMENT, data, headers, callback);
  }
};

const API = {
  GET_SEGMENT_LIST: "/organization/user_segment/get_list",
  CREATE_SEGMENT: "/organization/user_segment/create",
  DELETE_SEGMENT: "/organization/user_segment/delete",
  DETAIL_SEGMENT: "/organization/user_segment/get_details",
  GET_USER_LIST: "/organization/user_segment/get_user_list",
  ADD_USERS: "/organization/user_segment/add_users",
  REMOVE_USERS: "/organization/user_segment/remove_users",
  EDIT_SEGMENT: "/organization/user_segment/edit",
  SET_SEGMENT: "/organization/user/set_user_segments"
}

export default UserSegmentServices;
