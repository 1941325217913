import React, { useEffect } from 'react';
import './CheckoutSuccess.scss';
import NewOrganizationHeader from "../../newOrganizationHeader/NewOrganizationHeader";
import iconSuccess from '../../../../assets/img/select-tier/icon-success.svg';
import { clearSelectedTier } from "../../../../redux/actions/reduxActionSelectedTier";
import { connect } from 'react-redux';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

const CheckoutSuccess = (props) => {
  useEffect(() => {
    if (props.history.location.state) {
      if (!props.history.location.state.redirectTo) {
        props.history.push(`/o/${props.match.params.orgID}/home`); 
      }else {
        setTimeout(() => {
         props.clearSelectedTier();
         window.location.href = props.history.location.state.redirectTo;
        }, 3000) 
      }
      
      window.history.pushState(null, '');
    }
  }, [props.history]);

  useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
          "[Action] Checkout",
          {
              userFullName : myAgentData.account.fullName,
              userID : myAgentData.account.id,
              organizationID : props.match.params.orgID.split("-")[0]
          }
      );
    }
  }, [])

  return (
    <>
      <NewOrganizationHeader
        titleText="Start Subscription"
        hideLogout
      // topRightContent={renderStep}
      />

      <div className="checkout-success-main-wrapper">

        <div className="checkout-success-content">

          <div className="checkout-success-container">
            <img src={iconSuccess} alt="" />
            <h1>Checkout Success!</h1>
            {props.history.location.state.redirectTo &&
              <p>You will be redirected shortly. <a href={props.history.location.state.redirectTo}>Click here</a> if nothing happens.</p>
            }
          </div>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = {
  clearSelectedTier
};

export default connect(null, mapDispatchToProps)(CheckoutSuccess);