import React, { useState } from 'react';
// import { Link } from "react-router-dom";
// import "../auth/Auth.css";
import './Home.scss';
import 'react-toastify/dist/ReactToastify.css';
import SectionWrap from '../reuseableComponent/sectionWrap/SectionWrap';
// import { rupiahFormat } from '../../helper/HelperGeneral';
// import ButtonWithLoadingOrIcon from '../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon';
// import CustomSelectOption from '../reuseableComponent/customSelectOption/CustomSelectOption';
import OnBoarding from "./onBoarding/OnBoarding";
import ServiceProfile from '../../services/profile/ServiceProfile';
import ServiceOrganization from '../../services/organization/ServiceOrganization';
// import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import { checkID, doToast } from '../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import { setBalanceData } from "../../redux/actions/reduxActionBalance";
import { setAuthActive } from '../../redux/actions/reduxActionAuth';
import iconGlobe from '../../assets/img/icon-globe-home.svg';
import iconAt from '../../assets/img/icon-at.svg';
import iconTier from '../../assets/img/icon-tier-home.svg';
// import iconWallet from '../../assets/img/icon-wallet-home.svg';
// import iconMau from '../../assets/img/usage/icon-mau.svg';
// import iconMonthly from '../../assets/img/usage/icon-monthly-cases.svg';
// import iconAgents from '../../assets/img/usage/icon-agents.svg';
// import iconChannels from '../../assets/img/usage/icon-channels.svg';
// import ContentLoader from 'react-content-loader';
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";
// import { FiPlus } from 'react-icons/fi';

// const topicList = [
//   {
//     label: '29 January - 1 February',
//     value: 1,
//   },
//   {
//     label: '29 December - 1 January 2019',
//     value: 2,
//   },
//   {
//     label: '29 November - 1 December 2019',
//     value: 3,
//   },
// ];

const ROLE_CODE = {
  owner: 'owner',
  member: 'member',
  admin: 'admin',
  all: 'all'
}

const Home = (props) => {
  const { match, history, location } = props;
  const [orgID, setOrgID] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isShowOnboarding, setIsShowOnboarding] = useState(true);
  // const [datePeriod, setDatePeriod] = useState(topicList[1]);
  // const [isLoadingUsage, setIsLoadingUsage] = useState(true);
  // let [isWaitingProfileData, setIsWaitingProfileData] = React.useState(true);

  const doMount = async () => {
    // setUserData(HelperCustomEncryptor.doDecrypt(props.user.data));

    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      runGetProfile();
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) {
            window.location.href = '/organization/new';
          }
          
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/home`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  React.useEffect(() => {
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
      mixpanel.track(
        "[Page] Home",
        {
          userFullName: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: props.match.params.orgID.split("-")[0],
        }
      );
    }

    doMount();
  }, [location.pathname]);

  React.useEffect(() => {
    if (orgID) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      let memberShips = myAgentData.memberships;
      let role = memberShips[memberShips.findIndex(val => val.organization.id === parseInt(orgID.split("-")[0]))].roleCode;
      
      if(role === ROLE_CODE.member) {
        setIsShowOnboarding(false);
      }

      runGetBalance();
    }
  }, [orgID]);

  // let setUserDataWithCallback = (data) => {
  //   setUserData(data);
  // }

  
  let runGetBalance = () => {
    ServiceOrganization.postGetBalance(orgID, (response) => {
      if(response.dataResult.error.message === "") {
        let responseData = response.dataResult.data.balances;
        props.setBalanceData(responseData)
        
      }else {
        console.log(response.dataResult.error.message);
      }
    })
  }

  let runGetProfile = () => {
    // setIsWaitingProfileData(true);
    // let getUrlOrgID = () => {
    //   let matchParam = props.match.params.orgID;

    //   return matchParam.split("-")[0];
    // }

    ServiceProfile.postGetProfile((response) => {
      // setIsWaitingProfileData(false);

      if (response.dataResult.error.message === '') {
        let resultData = response.dataResult.data;

        // let currentUserData = HelperCustomEncryptor.doDecrypt(props.user.data);

        // let indexActiveOrganization = resultData.memberships.findIndex(value => value.organization.id === parseInt(getUrlOrgID()));

        // currentUserData.account = resultData.account;
        // currentUserData.memberships = resultData.memberships;
        // currentUserData.canCreateOrganization = resultData.canCreateOrganization;
        // currentUserData.activeOrganization = resultData.memberships[indexActiveOrganization].organization;

        // setUserDataWithCallback(currentUserData);

        setUserData(resultData);
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    });
  };

  let getActiveOrganization = () => {
    let getUrlOrgID = () => {
      let matchParam = props.match.params.orgID;

      return matchParam.split('-')[0];
    };

    let indexActiveOrganization = userData.memberships.findIndex(
      (value) => value.organization.id === parseInt(getUrlOrgID())
    );

    if (indexActiveOrganization === -1) {
      window.location.href = '/home';
    } else {
      return userData.memberships[indexActiveOrganization];
    }
  };

  // const dataUsage = [
  //   {
  //     img: iconMau,
  //     title: 'MAU',
  //     desc: '(Monthly Active Users)',
  //     value: '9.234',
  //     per: '/ unlimited',
  //   },
  //   {
  //     img: iconMonthly,
  //     title: 'Monthly Cases',
  //     value: '900',
  //     per: '/ unlimited',
  //   },
  //   {
  //     img: iconAgents,
  //     title: 'Agents',
  //     value: '2',
  //     per: '/ 2',
  //   },
  //   {  
  //     img: iconChannels,
  //     title: 'Channels',
  //     value: '3',
  //     per: '/ 3',
  //   },
  // ];

  // const onSelectTopic = (value) => {
  //   setDatePeriod(value);
  // };

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoadingUsage(false);
  //   }, 3000);
  // }, [isLoadingUsage]);

  return (
    <React.Fragment>
      <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
        {isShowOnboarding &&
          <OnBoarding {...props} userData={userData} />
        }

        <div className="sectionWrap">
          {/* {isWaitingProfileData ? */}
          <React.Fragment>
            <p className="home-welcome-message">
              <b>
                Hello {userData !== null && userData.account.fullName}, ready to
                serve your customers?
              </b>
            </p>

            <div className="home-info-wrapper">
              <div className="home-info-content">
                <div className="home-info-image">
                  <img src={iconGlobe} alt="" />
                </div>

                <div className="home-info-detail">
                  <b className="home-info-detail-title">ORGANIZATION</b>
                  <br />
                  <p className="home-info-detail-value">
                    <b>{userData !== null && getActiveOrganization().organization.name}</b>
                  </p>
                </div>
              </div>

              <div className="home-info-content">
                <div className="home-info-image">
                  <img src={iconAt} alt="" />
                </div>

                <div className="home-info-detail">
                  <b className="home-info-detail-title">ACCOUNT</b>
                  <br />
                  <p className="home-info-detail-value">
                    <b>{userData !== null && userData.account.email}</b>
                  </p>
                </div>
              </div>

              {userData !== null &&
                ((getActiveOrganization().roleCode === "admin" || getActiveOrganization().roleCode === "owner") &&
                  <>
                    <div className="home-info-content">
                      <div className="home-info-image">
                        <img src={iconTier} alt="" />
                      </div>

                      <div className="home-info-detail">
                        <b className="home-info-detail-title">TIER</b>
                        <br />
                        <p className="home-info-detail-value">
                          <b>{userData !== null && props.currentTierDetailProps.name}</b>
                        </p>
                      </div>
                    </div>

                    {/* <div className="home-info-content">
                      <div className="home-info-image">
                        <img src={iconWallet} alt="" />
                      </div>

                      <div className="home-info-detail">
                        <b className="home-info-detail-title">CURRENT BALANCE</b>
                        <br />
                        <p className="home-info-detail-value">
                          <b>{userData !== null && (props.balanceData ? (props.balanceData.length === 0 ? "Rp 0" : (props.balanceData[0].amount ? rupiahFormat(props.balanceData[0].amount) : "Rp 0")) : "Rp 0")}</b>
                        </p>

                        <Link to={`/o/${orgID}/organization/top-up`}>
                          <ButtonWithLoadingOrIcon 
                            text="Top Up"
                            icon={{
                              type: "svg",
                              src: FiPlus
                            }}
                            position="left"
                            className="no-fill-button main-button-40"
                          />
                        </Link>
                      </div>
                    </div> */}
                  </>
                )
              }
            </div>
          </React.Fragment>
        </div>
        {/* <div className="sectionWrap">
          <div className="usage-header">
            <p className="usage-title">
              <b>Usage</b>
            </p>
            <CustomSelectOption
              optionListProps={topicList}
              valueDropdownProps={datePeriod}
              placeholderProps={'Select date periode'}
              onClickDropDownListOptionProps={onSelectTopic}
              specificClassNameProps="custom-select-telegram-topic"
            />
          </div>
          <div className="usage-info-wrapper">
            {dataUsage.map((item, id) => {
              return (
                <div className="home-usage-wrapper" key={id}>
                  <img className="usage-icon" src={item.img} alt="" />
                  <p className="usage-title">
                    <b>
                      {item.title} <span>{id === 0 ? item.desc : ''}</span>
                    </b>
                  </p>
                  <p className="usage-value">
                    {isLoadingUsage ? (
                      <ContentLoader viewBox="0 0 200 30">
                        <rect
                          x="0"
                          y="0"
                          rx="4"
                          ry="4"
                          width="172"
                          height="40"
                        />
                      </ContentLoader>
                    ) : (
                      <b>{item.value}</b>
                    )}
                  </p>
                  <p className="usage-per">
                    {isLoadingUsage ? (
                      <ContentLoader viewBox="0 0 200 30">
                        <rect
                          x="0"
                          y="0"
                          rx="4"
                          ry="4"
                          width="86"
                          height="24"
                        />
                      </ContentLoader>
                    ) : (
                      <b>{item.per}</b>
                    )}
                  </p>
                </div>
              );
            })}
          </div>
        </div> */}
      </SectionWrap>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  balanceData: state.balanceData
});

const mapDispatchToProps = {
  setAuthActive,
  setBalanceData
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
