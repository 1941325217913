import React, { useEffect, useState } from "react";
import { FiInfo } from "react-icons/fi";
import "./IntegrationTokopediaEdit.scss";
import "react-toastify/dist/ReactToastify.css";
// import Integrations from "../../Integrations";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { checkID, doToast, scrollToClass } from "../../../../helper/HelperGeneral";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import topicServices from "../../../../services/newServices/TopicServices";
import IntegrationTokopediaServices from "../../../../services/integration/IntegrationTokopediaServices";

const IntegrationTokopediaEdit = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [topicList, setTopicList] = useState([]);
    let [accountTopicVal, setAccountTopicVal] = useState({
        label: "",
        value: ""
    });
    let [accountTopicValOrigin, setAccountTopicValOrigin] = useState({
        label: "",
        value: ""
    });
    let [errorMessageData, setErrorMessageData] = useState({
        topic: ""
    })
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const back = () => history.push(`/o/${props.match.params.orgID}/integration/tokopedia/detail/${parseInt(props.match.params.accountID)}`);

    let onSubmitClick = () => {
       let _errorMessageData = {...errorMessageData};
       let errorCount = 0;
       let errorClass = "";

       if(accountTopicVal.value === "") {
        errorCount = errorCount + 1;
        _errorMessageData.topic = "Please select topic";

        errorClass = "input-topic";
       }

       if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
       }else {
        setIsLoadingSubmit(true);

        IntegrationTokopediaServices.editTopic(orgID, {topicID: accountTopicVal.value, id: parseInt(props.match.params.accountID)}, (res) => {
            let _res = res.dataResult;

            if(_res.error.code === "") {
                if(_res.data.success) {
                    doToast(_res.data.message);
                    setAccountTopicValOrigin(accountTopicVal);
                    
                    setTimeout(() => {
                        back();
                    }, 3000)
                }else {
                    doToast(_res.data.message, "fail");
                    setIsLoadingSubmit(false);
                }
            }else {
                doToast(_res.error.message, "fail");
                setIsLoadingSubmit(false);
            }
        })
       }
    };

    let doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getTopics = async (orgID) => {
        topicServices.getTopic(orgID, "list", null, (response) => {
            const data = response.dataResult.data.topics;
            const createList = () => {
                let newData = [];
                data.map(val => {
                    let _data = {};
                    _data.value = val.id;
                    _data.label = val.name;
                    newData.push(_data);
                    return null;
                });
                setTopicList(newData);
            };

            createList();
        });
    }

    useEffect(() => {
        if(props.location.state) {
            setAccountTopicVal(props.location.state.topic);
            setAccountTopicValOrigin(props.location.state.topic);
        }else {
            back();
        }
        doMount();
    }, []);

    useEffect(() => {
        if(orgID) {
            getTopics(orgID);
        }
    }, [orgID])

    let onSelectTopic = (value) => {
        let _errorMessageData = {...errorMessageData};
        _errorMessageData.topic = "";
        setErrorMessageData(_errorMessageData);
        setAccountTopicVal(value)
    }

    // let isFormValid = () => {
    //     let _shopUrlVal = shopUrlVal;
    //     let _accountTopicVal = accountTopicVal.value;
    //     return (_shopUrlVal !== "" && _accountTopicVal !== "");
    // }

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
                dataOrigin={{
                    topic: accountTopicValOrigin
                }}
                dataAfterChange={{
                    topic: accountTopicVal
                }}
            >
                <div className="sectionWrap integration-main-container integration-add-account text-left">
                    <BackButton text="Back to Tokopedia Account Detail" onClick={back} />

                    <div className="integration-add-account-box">
                        <div className="integration-add-account-box-top">
                            <b>Edit Channel Details</b>
                            
                            {isLoadingSubmit ?
                                <ButtonWithLoadingOrIcon 
                                    // isDisabled={!isFormValid()} 
                                    isLoading
                                    position="left"
                                    loadingColor="gray"
                                    className="orange-button main-button-40"
                                    text="Save Changes"
                                    isDisabled
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    // isDisabled={!isFormValid()} 
                                    onClickAction={onSubmitClick}
                                    className="orange-button main-button-40"
                                    text="Save Changes"
                                    position="left"
                                />
                            }
                        </div>

                        <div className="integration-add-account-box-form">
                            <div className="width-320">
                                <label><b>Topic</b></label>
                                <CustomSelectOption
                                    optionListProps={topicList}
                                    valueDropdownProps={accountTopicVal}
                                    placeholderProps={"Select account topic"}
                                    onClickDropDownListOptionProps={onSelectTopic}
                                    specificClassNameProps="custom-select-whatsapp-topic"
                                    _className={`${errorMessageData.topic !== "" ? "border-red input-topic" : "input-topic"}`}
                                    isDisabled={isLoadingSubmit}
                                />
                                <ErrorOrInfoComp 
                                    _className={`${errorMessageData.topic !== "" ? "red-text" : ""} margin-top-0`}
                                    icon={<FiInfo />}
                                    text={`${errorMessageData.topic !== "" ? errorMessageData.topic : "Incoming messages on this channel will be assigned to this topic."}`}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </SectionWrap>
        </div>
    )
}

export default IntegrationTokopediaEdit;