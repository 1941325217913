import React, { useEffect, useState } from 'react';
import './OverviewReport.scss';
import ReportService from '../../../services/newServices/ReportService';
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import { doToast, checkID } from '../../../helper/HelperGeneral';
import HelperDate from '../../../helper/HelperDate';
import { FiUser } from 'react-icons/fi';
import { FaStar } from 'react-icons/fa';
import NoReportFound from "../../../assets/img/no-report-found.svg";
import AdvancedDateFilter from '../../reuseableComponent/advancedDateFilter/AdvancedDateFilter';
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import OverviewTopicReport from "./overviewTopicReport/OverviewTopicReport";
import OverviewTopicReportNoData from './overviewTopicReportNoData/OverviewTopicReportNoData';
import mixpanel from "mixpanel-browser";
import CaseReport1 from "../../../assets/img/report/overview/case-report-1.svg";
import CaseReport2 from "../../../assets/img/report/overview/case-report-2.svg";
import CaseReport3 from "../../../assets/img/report/overview/case-report-3.svg";
import CaseReport4 from "../../../assets/img/report/overview/case-report-4.svg";
import CaseReport5 from "../../../assets/img/report/overview/case-report-5.svg";
import CaseReport6 from "../../../assets/img/report/overview/case-report-6.svg";
import CaseReport7 from "../../../assets/img/report/overview/case-report-7.svg";
import { PieChart } from 'react-minimal-pie-chart';

const OverviewReport = (props) => {
  let [orgID, setOrgID] = React.useState(false);
  let [isWaitingFetchOverview, setIsWaitingFetchOverview] = useState(false);
  let [printedOverviewData, setPrintedOverviewData] = useState([]);
  let [retriveDataError, setRetrieveDataError] = React.useState({
      code: false,
      message: false
  });
  let [dateVal, setDateVal] = useState({
    from:  new Date(new Date().setDate(new Date().getDate() - 30)),
    to: new Date()
  });
  let [filterDateForCalender, setFilterDateForCalender] = useState({
    from: undefined,
    to: undefined
  });
  let [filterBy, setFilterBy] = useState({
    text: "Last 30 Days",
    val: "last_30"
  });

  let [isShowDropdownDate, setIsShowDropdownDate] = useState(false);

  useEffect(() => {
    if(orgID) {
      getOverview();
    }
  }, [dateVal])

  useEffect(() => {
    if(orgID) {
        getOverview();
    }
  }, [orgID])

  useEffect(() => {
    doMount();
    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
      mixpanel.track(
        "[Page] Overview Report", 
        {
            userFullName : myAgentData.account.fullName,
            userID : myAgentData.account.id,
            organizationID : props.match.params.orgID.split("-")[0]
        }
      );
    }
  }, []);

  const doMount = async () => {
    const paramID = checkID(props.match);

    if (paramID) {
        setOrgID(paramID);
    } else {
        ServiceOrganization.postGetOrganization((response) => {
            if (response.dataResult.error !== "") {
                let responseData = response.dataResult.data;
                if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                const { id, createdTime } = responseData.memberships[0].organization;
                const urlOrgID = `${id}-${createdTime}`;
                props.history.push(`/o/${urlOrgID}/organization/members`);
            } else {
                console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
            }
        });
    }
  };

  let getOverview = () => {
    setIsWaitingFetchOverview(true);
    let _retriveDataError = {...retriveDataError};
    let data = {
      startDate: dateVal.from ? HelperDate.formatToString(new Date(dateVal.from), "yyyy-MM-dd") : "",
      endDate: dateVal.to ?  HelperDate.formatToString(new Date(dateVal.to), "yyyy-MM-dd") : ""
    }

    ReportService.getOverview(props.match.params.orgID, data, (response) => {
      let dataResult = response.dataResult;
      
      if (dataResult.error.message === '') {        
        setPrintedOverviewData(dataResult.data);
      } else {
        _retriveDataError.message = dataResult.error.message;
        _retriveDataError.code = dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
      }

      setIsWaitingFetchOverview(false);
    });
  };

  let countTotalRating = (ratings) => {
    let total = 0;

    Object.keys(ratings).map((key, index) => {
      total = total + ratings[key];

      return null;
    })

    return total;
  }

  let printTime = (val) => {
    let time = "";
    let split = val.split(":");
    let h = split[0];
    let m = split[1];
    let s = split[2];
    
    if(m === "00") {
      time = s+"s";
    }else if(h === "00") {
      time = m+"m "+s+"s";
    }else {
      time = h+"h "+m+"m "+s+"s";
    }

    return time;
  }

  useEffect(() => {
    if (props.history.location.state) {
      if (props.history.location.state.success) {
        doToast(props.history.location.state.message);
      } else {
        doToast(props.history.location.state.message, 'fail');
      }

      window.history.pushState(null, '');
    }
  }, [props.history]);

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
    >
      <div className="overview-report-wrapper sectionWrap">
        {/* tier blocking */}
        {retriveDataError.code  === "49901" &&
          <RetrieveDataLoading 
            isLoading={isWaitingFetchOverview}
            errorMessage={retriveDataError.message}
            errorCode={retriveDataError.code}
          />
        }
        {/* tier blocking */}

        <React.Fragment>
          <p className="overview-report-title">
              <b>Overview</b>
              
              <AdvancedDateFilter 
                  toggleDropdownDate={() => setIsShowDropdownDate(!isShowDropdownDate)}
                  isOpenDropdown={isShowDropdownDate}
                  dateVal={dateVal}
                  filterDateForCalender={filterDateForCalender}
                  filterBy={filterBy}
                  onClickDate={(date) => setDateVal(date)}
                  onClickFilterBy={(filter) => setFilterBy(filter)}
                  onClickCalendar={(date) => {
                    setFilterDateForCalender(date)
                  }}
                  ignoreAllTime
                  isDisabled={isWaitingFetchOverview}
                  disabledDays={{
                    after: new Date()
                  }}
                  maxRange={365}
              />
          </p>

          {(isWaitingFetchOverview || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
            <RetrieveDataLoading 
              isLoading={isWaitingFetchOverview}
              errorMessage={retriveDataError.message}
              errorCode={retriveDataError.code}
            />
            :
            <div className="overview-report-data-outer-wrapper">
              <div className="overview-report-data-wrapper">

                {printedOverviewData.length === 0 ? 
                  <div className="no-overview-report">
                    <img src={NoReportFound} alt="No Report Found" />

                    <p>
                      <b>No report found. </b>
                    </p>
                  </div>
                  :
                  <>
                    <div className="overview-box-wrapper">
                        <p className="overview-box-title">
                            <b>Case Report</b>
                        </p>

                        <div>
                          <div className="case-report-box">
                            <img src={CaseReport1} alt="" />
                            <p className="case-report-value"><b>{printedOverviewData.caseReport.totalCasesCreated}</b></p>
                            <p className="case-report-title"><b>Cases Created</b></p>
                          </div>

                          <div className="case-report-box">
                            <img src={CaseReport2} alt="" />
                            <p className="case-report-value"><b>{printedOverviewData.caseReport.totalCasesClosed}</b></p>
                            <p className="case-report-title"><b>Cases Being Resolved</b></p>
                          </div>

                          <div className="case-report-box">
                            <img src={CaseReport3} alt="" />
                            <p className="case-report-value"><b>{printedOverviewData.caseReport.totalCasesMarkedAsJunk}</b></p>
                            <p className="case-report-title"><b>Cases Marked as Junk</b></p>
                          </div>

                          <div className="case-report-box">
                            <img src={CaseReport4} alt="" />
                            <p className="case-report-value"><b>{printedOverviewData.caseReport.totalCasesTakenOver}</b></p>
                            <p className="case-report-title"><b>Cases Taken Over from Other Agent</b></p>
                          </div>

                          <div className="case-report-box">
                            <img src={CaseReport5} alt="" />
                            <p className="case-report-value"><b>{printedOverviewData.caseReport.totalCasesHandedOver}</b></p>
                            <p className="case-report-title"><b>Cases Handed Over</b></p>
                          </div>

                          <div className="case-report-box">
                            <img src={CaseReport6} alt="" />
                            <p className="case-report-value"><b>{printTime(printedOverviewData.caseReport.averageFirstResponseWaitDuration)}</b></p>
                            <p className="case-report-title"><b>Average First Response Wait Duration</b></p>
                          </div>

                          <div className="case-report-box">
                            <img src={CaseReport7} alt="" />
                            <p className="case-report-value"><b>{printTime(printedOverviewData.caseReport.averageCaseDuration)}</b></p>
                            <p className="case-report-title"><b>Average Case Duration</b></p>
                          </div>
                        </div> 
                    </div>

                    <div className="overview-box-wrapper">
                        <p className="overview-box-title">
                            <b>Case Rating</b>
                        </p>

                        <div className="overview-case-rating-left">
                          <PieChart
                            data={[
                              { title: '5 Stars', value: printedOverviewData.caseRating.totalCasesPerRating["5"], color: '#51E683' },
                              { title: '4 Stars', value: printedOverviewData.caseRating.totalCasesPerRating["4"], color: '#3BAEff' },
                              { title: '3 Stars', value: printedOverviewData.caseRating.totalCasesPerRating["3"], color: '#9C2EEF' },
                              { title: '2 Stars', value: printedOverviewData.caseRating.totalCasesPerRating["2"], color: '#F4C12A' },
                              { title: '1 Star', value: printedOverviewData.caseRating.totalCasesPerRating["1"], color: '#f99080' },
                            ]}
                          />

                          <div className="total-rating-is">
                            <div className="total-rating-is-text">
                              <b>
                                {countTotalRating(printedOverviewData.caseRating.totalCasesPerRating)}
                              </b>

                              <p>
                                Total Rating
                              </p>
                            </div>

                          </div>
                        </div>

                        <div className="overview-case-rating-right">
                          <table className="table-overview">
                            <thead>
                              <tr>
                                <td><b>Rating</b></td>
                                <td><b># of Cases</b></td>                                
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td className="stars-5"><div /><b>5 Stars</b></td>
                                <td><b>{printedOverviewData.caseRating.totalCasesPerRating[5]}</b></td>                                
                              </tr>
                              <tr>
                                <td className="stars-4"><div /><b>4 Stars</b></td>
                                <td><b>{printedOverviewData.caseRating.totalCasesPerRating[4]}</b></td>                                
                              </tr>
                              <tr>
                                <td className="stars-3"><div /><b>3 Stars</b></td>
                                <td><b>{printedOverviewData.caseRating.totalCasesPerRating[3]}</b></td>                                
                              </tr>
                              <tr>
                                <td className="stars-2"><div /><b>2 Stars</b></td>
                                <td><b>{printedOverviewData.caseRating.totalCasesPerRating[2]}</b></td>                                
                              </tr>
                              <tr>
                                <td className="stars-1"><div /><b>1 Star</b></td>
                                <td><b>{printedOverviewData.caseRating.totalCasesPerRating[1]}</b></td>                                
                              </tr>
                            </tbody>
                          </table>

                          <div className="overview-case-rating-average">
                            <FaStar /> <b className="rating-value">{printedOverviewData.caseRating.averageRating}</b>

                            <p>
                              <b>Average User Rating</b>
                            </p>
                          </div>
                        </div>
                    </div>

                    <div className="overview-box-wrapper">
                        <p className="overview-box-title">
                            <b>Agent Report</b>
                        </p>

                        <div className="overview-agent-report-wrapper">
                          <div className="overview-agent-report-box">
                            <div className="agent-report-icon-wrapper">
                              <FiUser />
                            </div>

                            <p className="agent-report-text-1"><b>{printedOverviewData.agentReport.mostCaseFirstResponse.value}</b></p>
                            <p className="agent-report-text-2"><b>{printedOverviewData.agentReport.mostCaseFirstResponse.accountName}</b></p>
                            <p className="agent-report-text-3">Agent with The Most First Responded Case</p>
                          </div>

                          <div className="overview-agent-report-box">
                            <div className="agent-report-icon-wrapper">
                              <FiUser />
                            </div>
                            
                            <p className="agent-report-text-1"><b>{printedOverviewData.agentReport.mostCaseClose.value}</b></p>
                            <p className="agent-report-text-2"><b>{printedOverviewData.agentReport.mostCaseClose.accountName}</b></p>
                            <p className="agent-report-text-3">Agent with The Most Case Resolved</p>
                          </div>

                          <div className="overview-agent-report-box">
                            <div className="agent-report-icon-wrapper">
                              <FiUser />
                            </div>
                            
                            <p className="agent-report-text-1"><b className="rating-text"><FaStar />{printedOverviewData.agentReport.lowestAverageCaseRating.value}</b></p>
                            <p className="agent-report-text-2"><b>{printedOverviewData.agentReport.lowestAverageCaseRating.accountName}</b></p>
                            <p className="agent-report-text-3">Agent with The Lowest Average Case Rating</p>
                          </div>

                          <div className="overview-agent-report-box">
                            <div className="agent-report-icon-wrapper">
                              <FiUser />
                            </div>
                            
                            <p className="agent-report-text-1"><b className="rating-text"><FaStar />{printedOverviewData.agentReport.highestAverageCaseRating.value}</b></p>
                            <p className="agent-report-text-2"><b>{printedOverviewData.agentReport.highestAverageCaseRating.accountName}</b></p>
                            <p className="agent-report-text-3">Agent with The Highest Average Case Rating</p>
                          </div>
                        </div>
                    </div>
                    
                    {printedOverviewData.topicReport.topics.length > 0 ?
                      <OverviewTopicReport 
                        {...props} 
                        printedOverviewData={printedOverviewData.topicReport}
                      />
                      :
                      <OverviewTopicReportNoData />
                    }
                  </>
                }
              </div>
            </div>
          }
        </React.Fragment>
      </div>
    </SectionWrap>
  );
};

export default OverviewReport;
