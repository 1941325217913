import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import "./ChatRoomMessageOutBroadcastImage.scss";
import { IoIosClose } from "react-icons/io";
import CheckMarkDoubleWhite from "../../../../assets/img/chatroom/icon-double-check-white.svg";
import CheckMarkDoubleDark from "../../../../assets/img/chatroom/icon-double-check-dark.svg";
import CheckMarkDark from "../../../../assets/img/chatroom/icon-check-dark.svg";
import AirplaneDark from "../../../../assets/img/chatroom/icon-airplane-dark.svg";
import BroadcastGrey from "../../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg";
import CheckMarkDoubleLight from "../../../../assets/img/chatroom/icon-double-check-light.svg";
import CheckMarkLight from "../../../../assets/img/chatroom/icon-check-light.svg";
import AirplaneLight from "../../../../assets/img/chatroom/icon-airplane-light.svg";
import { FiDownload, FiUpload } from "react-icons/fi";
import { FaReply } from 'react-icons/fa';
import Helper from "../../../../helper/HelperChat";
import { tapCoreChatRoomManager } from "@taptalk.io/web-sdk";
import { CircularProgressbar } from "react-circular-progressbar";
import MessageDeleteWhite from "../../../../assets/img/icon-notallowed-white.svg";

var ChatRoomMessageOut = (props) => {
  let [percentageDownload, setPercentageDownload] = useState(0);
  let [imageSrc, setImageSrc] = useState("");
  let [isImageExistInDB, setIsImageExistInDB] = useState(false);
  let [onDownloadImageProgress, setOnImageDownloadProgress] = useState(false);
  let [imageFromUrl, setImageFromUrl] = useState(false);
  let [isShowModalImage, setIsShowModalImage] = useState(false);

  useEffect(() => {
    let isUnmounted = false;
    let fetchFromDB = () => {
      if(props.singleChatDataProps.data.fileID) {
        tapCoreChatRoomManager.getFileFromDB(
          props.singleChatDataProps.data.fileID,
          function(data) {
            if (data) {
              if (!isUnmounted) {
                setImageSrc(data.file);
                setIsImageExistInDB(true);
              }
            } else {
              if (!isUnmounted) {
                setImageSrc(props.singleChatDataProps.data.thumbnail);
                setIsImageExistInDB(false);
              }
            }
          }
        );
      }
    };

    if (props.singleChatDataProps.data.url) {
      setImageFromUrl(true);
      setImageSrc(props.singleChatDataProps.data.url);
    } else if (props.singleChatDataProps.data.fileURL) {
      if (props.singleChatDataProps.data.fileURL !== "") {
        setImageFromUrl(true);
        setImageSrc(props.singleChatDataProps.data.fileURL);
      } else {
        setImageFromUrl(false);
        fetchFromDB();
      }
    } else {
      fetchFromDB();
    }

    return () => {
      isUnmounted = true;
    };
  }, [props.singleChatDataProps]);

  let downloadFile = () => {
    setOnImageDownloadProgress(true);

    tapCoreChatRoomManager.downloadMessageFile(props.singleChatDataProps, {
      onSuccess: (data) => {
        setOnImageDownloadProgress(false);
        setImageSrc(data.base64);
        setIsImageExistInDB(true);
      },

      onProgress: (message, percentage, bytes) => {
        setPercentageDownload(percentage);
      },

      onError: (errorCode, errorMessage) => {
        setOnImageDownloadProgress(false);
        console.log(errorCode, errorMessage);
      },
    });
  };

  let toggleModalImage = () => {
    if (isImageExistInDB || imageFromUrl) {
      setIsShowModalImage(!isShowModalImage);
    }
  };

  let generateModalImage = () => {
    let zoomImage = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = "200%";
      var offsetX = e.nativeEvent.offsetX;
      var offsetY = e.nativeEvent.offsetY;

      var x = (offsetX / zoomer.offsetWidth) * 100;
      var y = (offsetY / zoomer.offsetHeight) * 100;
      zoomer.style.backgroundPosition = x + "% " + y + "%";
    };

    let zoomImageOut = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = "0%";
    };

    return (
      <div>
        <Modal isOpen={isShowModalImage} className={"modal-image"} toggle={toggleModalImage}>
          <ModalBody onClick={() => toggleModalImage()}>
            <IoIosClose
              className="close-modal-image"
              onClick={() => toggleModalImage()}
            />
            <div className="image-wrapper">
              <figure
                className="zoom"
                style={{ backgroundImage: `url(${imageSrc})` }}
                onMouseMove={(e) => zoomImage(e, imageSrc)}
                onMouseLeave={(e) => zoomImageOut(e)}
              >
                <img src={imageSrc} alt="" className="image-preview-val" />
              </figure>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  let messageActionView = (message) => {
    let onClickReply = () => {
      props.onReplyMessage(message)
    }

    return (
        <div 
          className={`message-action-wrapper`}
        >  
            <div className="message-action-button-wrapper reply-button" title="Reply"  onClick={() => onClickReply()}>
                <FaReply />
            </div>
         </div>
    )
  }

  return (
    <div className="chat-room-message-out-broadcast-image-wrapper">
      {props.singleChatDataProps.isDeleted ? (
        <div className="message-out-bubble deleted-bubble">
          <React.Fragment>
            <img src={MessageDeleteWhite} alt="" className="deleted-icon" />
            This message was deleted.
          </React.Fragment>
        </div>
      ) : (
        <div
          className={`message-out-bubble ${
            props.status !== "uploaded" ? "not-sent-message-bubble" : ""
          }`}
        >
          <div className="message-title">
            <img src={BroadcastGrey} alt="" />
            <b>Broadcasted Message</b>
          </div>
          <div
            className={`bubble-image-wrapper ${
              props.singleChatDataProps.data.caption !== ""
                ? "bubble-wrapper-with-caption"
                : ""
            } ${
              isImageExistInDB || imageFromUrl ? "bubble-wrapper-clickable" : ""
            }`}
          >
            {imageFromUrl ? (
              <React.Fragment>
                <img
                  src={imageSrc}
                  className={`image-from-url`}
                  alt=""
                  onClick={() => {
                    toggleModalImage();
                  }}
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                {isImageExistInDB ? (
                  <React.Fragment>
                    <img
                      src={"data:image/png;base64, " + imageSrc}
                      alt=""
                      className="main-image-message"
                      onClick={() => {
                        toggleModalImage();
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <img
                      src={"data:image/png;base64, " + imageSrc}
                      alt=""
                      className="thumbnail-image-message"
                    />
                  </React.Fragment>
                )}

                {!isImageExistInDB &&
                  !onDownloadImageProgress &&
                  props.singleChatDataProps.percentageUpload === undefined &&
                  !imageFromUrl && (
                    <div className="icon-status-wrapper">
                      <FiDownload onClick={() => downloadFile()} />
                    </div>
                  )}

                {onDownloadImageProgress && (
                  <div className="icon-status-wrapper">
                    <div className="onprocess-wrapper">
                      <CircularProgressbar value={percentageDownload} />
                      {/* <MdClose onClick={() => console.log('cancel download')} /> */}
                      <FiDownload />
                    </div>
                  </div>
                )}

                {props.singleChatDataProps.bytesUpload !== undefined && (
                  <div className="icon-status-wrapper">
                    <div className="onprocess-wrapper">
                      <CircularProgressbar
                        value={props.singleChatDataProps.percentageUpload}
                      />
                      {/* <MdClose onClick={() => console.log('cancel upload')} /> */}
                      <FiUpload />
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </div>

          {props.singleChatDataProps.data.caption !== "" && (
            <p className="caption-text" dangerouslySetInnerHTML={ {__html: Helper.lineBreakChatRoom(props.singleChatDataProps.data.caption)} } />
          )}

          {/* <div className="dark-cover">
                {(!isImageExistInDB && !onDownloadImageProgress) &&
                    <div className="icon-status-wrapper">
                        <MdFileDownload onClick={() => downloadFile()} />
                    </div>
                }
                    
                {onDownloadImageProgress && 
                    <div className="icon-status-wrapper">
                        <div className="onprocess-wrapper">
                            <CircularProgressbar value={percentageDownload} />
                            <MdClose onClick={() => console.log('cancel')} />
                        </div>
                    </div>
                }
            </div> */}

          {props.singleChatDataProps.data.caption === "" ? (
            <p className={`message-info message-info-dark`}>
              {Helper.getDateMonthYear(props.singleChatDataProps.created)}{" "}
              <span className="centered-dot" />{" "}
              {Helper.getHourMinute(props.singleChatDataProps.created)}
              {props.singleChatDataProps.isSending &&
                !props.singleChatDataProps.isRead && (
                  <img src={AirplaneLight} alt="" />
                )}
              {!props.singleChatDataProps.isSending &&
                !props.singleChatDataProps.isDelivered &&
                !props.singleChatDataProps.isRead &&
                props.singleChatDataProps.data.caption === "" && (
                  <img src={CheckMarkLight} alt="" />
                )}
              {!props.singleChatDataProps.isSending &&
                props.singleChatDataProps.isDelivered &&
                !props.singleChatDataProps.isRead &&
                props.singleChatDataProps.data.caption === "" && (
                  <img src={CheckMarkDoubleLight} alt="" />
                )}
              {props.singleChatDataProps.isRead && (
                <img src={CheckMarkDoubleWhite} alt="" />
              )}
            </p>
          ) : (
            <p className={`message-info`}>
              {Helper.getDateMonthYear(props.singleChatDataProps.created)}{" "}
              <span className="centered-dot" />{" "}
              {Helper.getHourMinute(props.singleChatDataProps.created)}
              {props.singleChatDataProps.isSending &&
                !props.singleChatDataProps.isRead && (
                  <img src={AirplaneDark} alt="" />
                )}
              {!props.singleChatDataProps.isSending &&
                !props.singleChatDataProps.isDelivered &&
                !props.singleChatDataProps.isRead && (
                  <img src={CheckMarkDark} alt="" />
                )}
              {!props.singleChatDataProps.isSending &&
                props.singleChatDataProps.isDelivered &&
                !props.singleChatDataProps.isRead && (
                  <img src={CheckMarkDoubleDark} alt="" />
                )}
              {props.singleChatDataProps.isRead && (
                <img src={CheckMarkDoubleWhite} alt="" />
              )}
            </p>
          )}
          {props.status === "fail" && (
            <React.Fragment>
              <br />
              <b className="failed-sending-file-warning">
                Failed to send tap to retry
              </b>
            </React.Fragment>
          )}

          {(isImageExistInDB || imageFromUrl) && generateModalImage()}
          {messageActionView(props.singleChatDataProps)}
        </div>
      )}
    </div>
  );
};

export default ChatRoomMessageOut;
