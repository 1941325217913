import React, { useState, useEffect } from "react";
import { checkID, doToast, scrollToClass } from "../../../../../../helper/HelperGeneral";
import HelperInput from "../../../../../../helper/HelperInput";
import { FiInfo, FiTrash2, FiPlus } from "react-icons/fi";
import IntegrationGoogleServices from "../../../../../../services/integration/IntegrationGoogleServices";
import ServiceOrganization from "../../../../../../services/organization/ServiceOrganization";
import BackButton from "../../../../../reuseableComponent/BackButton/BackButton";
import ButtonWithLoadingOrIcon from "../../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import RetrieveDataLoading from "../../../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SectionWrap from "../../../../../reuseableComponent/sectionWrap/SectionWrap";
import ErrorOrInfoComp from '../../../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp';
import SelectBulk from "../../../../../reuseableComponent/selectBulk/SelectBulk";
// import Loading from "../../../../../reuseableComponent/loading/Loading";
import IconRegion from "../../../../../../assets/img/icon-region.png";
import "./EditEntryPointNonLocal.scss";

let IntegrationGoogleEditEntryPointNonLocal = (props) => {
  let { match, history } = props;
  let [searchBulk, setSearchBulk] = useState("");
  let [bulkListOrigin, setBulkListOrigin] = useState(false);
  let [bulkList, setBulkList] = useState(false);
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingEdit, setIsLoadingEdit] = useState(false);
  let [data, setData] = useState({
    isEnabled: false,
    domain: [
      {
        value: "",
        error: ""
      },
    ],
    region: [],
    phoneNumber: "",
    phoneNumberError: "",
    contactURL: "",
    contactURLError: "",
    contactOptions: [],
    contactOptionsError: "",
    isAllRegions: false,
    regionError: "" 
  });
  let [dataOrigin, setDataOrigin] = useState({
    isEnabled: false,
    domain: [
      {
        value: "",
        error: ""
      },
    ],
    region: [],
    phoneNumber: "",
    phoneNumberError: "",
    contactURL: "",
    contactURLError: "",
    contactOptions: [],
    contactOptionsError: "",
    isAllRegions: false,
    regionError: ""
  });

  let doMount = () => {
    setIsLoadingEdit(false);
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getRegionList();
    }
  }, [orgID]);

  useEffect(() => {
    if(bulkListOrigin) {
      getAccountDetail();
    }
  }, [bulkListOrigin])

  let checkBoxList = [
    {
        label: "Email",
        value: "EMAIL",
        onClick: (e) => onClickCheckBoxSource(e) 
    },
    {
        label: "Phone",
        value: "PHONE",
        onClick: (e) => onClickCheckBoxSource(e) 
    },
    {
        label: "FAQs",
        value: "FAQS",
        onClick: (e) => onClickCheckBoxSource(e) 
    },
    {
        label: "WhatsApp",
        value: "WHATSAPP",
        onClick: (e) => onClickCheckBoxSource(e) 
    },
    {
        label: "Twitter",
        value: "TWITTER",
        onClick: (e) => onClickCheckBoxSource(e) 
    },
    {
        label: "Web Chat",
        value: "WEB_CHAT",
        onClick: (e) => onClickCheckBoxSource(e) 
    }
  ];

  let getRegionList = () => {
    setIsLoadingGetDetailData(true);
    IntegrationGoogleServices.getRegionList(orgID, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        let arr = [];
        response.dataResult.data.regions.map(v => {
          arr.push({
            name: v.name,
            id: v.code
          })

          return null;
        })

        setBulkList(arr);
        setBulkListOrigin(arr);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        setIsLoadingGetDetailData(false);
      }
    });
  }

  let getAccountDetail = () => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: Number(match.params.id),
    };

    IntegrationGoogleServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = { ...retriveDataError };

      if (response.dataResult.error.message === "") {
        let dom = [];
        let region = [];
        let contactUS = [];

        if(response.dataResult.data.profile.entryPointConfigs.nonLocal) {
          response.dataResult.data.profile.entryPointConfigs.nonLocal.sitelinkDomains.map((v) => {
            return (
              dom.push({
                value: v,
                error: ""
              })
            )
          }) 

          response.dataResult.data.profile.entryPointConfigs.nonLocal.regionCodes.map((_v) => {
            return (
              bulkList.findIndex(v => v.id === _v) !== -1 &&
                region.push({
                  id: _v,
                  name: bulkList[bulkList.findIndex(v => v.id === _v)].name
                })
            )
          })

          response.dataResult.data.profile.entryPointConfigs.nonLocal.contactOptions.map((_v) => {
            return (
              checkBoxList.findIndex(v => v.value.toLowerCase() === _v.toLowerCase()) !== -1 &&
                contactUS.push(checkBoxList[checkBoxList.findIndex(v => v.value.toLowerCase() === _v.toLowerCase())].value)
            )
          })
        }else {
          dom.push({
            value: "",
            error: ""
          })
        }

        let _data = {
          isEnabled: response.dataResult.data.profile.entryPointConfigs.enabledEntryPoints.findIndex(v => v === "NON_LOCAL") !== -1,
          domain:  dom,
          region: region,
          phoneNumber: response.dataResult.data.profile.entryPointConfigs.nonLocal ? response.dataResult.data.profile.entryPointConfigs.nonLocal.phoneNumber.replace("+", "") : "",
          phoneNumberError: "",
          contactURL: response.dataResult.data.profile.entryPointConfigs.nonLocal ? response.dataResult.data.profile.entryPointConfigs.nonLocal.contactURL : "",
          contactURLError: "",
          // contactOptions: response.dataResult.data.profile.entryPointConfigs.nonLocal ? response.dataResult.data.profile.entryPointConfigs.nonLocal.contactOptions : [],
          contactOptions: contactUS,
          contactOptionsError: "",
          isAllRegions: response.dataResult.data.profile.entryPointConfigs.nonLocal ? (bulkList.length === response.dataResult.data.profile.entryPointConfigs.nonLocal.regionCodes) : "",
          regionError: ""
        };

        setData(_data);
        setDataOrigin(_data);
        setIsLoadingGetDetailData(false);
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        setIsLoadingGetDetailData(false);
      }

    });
  };

  let submitEdit = () => {
    let _editGoogle = JSON.stringify(data);
    let _parse = JSON.parse(_editGoogle);
    let errorCount = 0;
    let scrollToError = "";

    if(_parse.isEnabled) {
        //domain
        _parse.domain.map((v, i) => {
            if(!HelperInput.validURL(v.value)) {
                v.error = "Invalid URL format";
                
                errorCount = errorCount + 1;

                if(scrollToError === "") { scrollToError = `domain-${i}`}
            }

            if(i > 0) {
              _parse.domain.map((_v, _i) => {
                  if(_v.value !== "" && _v.value === v.value && _i !== i) {
                      v.error = "Domain is duplicate";

                      errorCount = errorCount + 1;

                      if(scrollToError === "") { scrollToError = `domain-${i}`}
                  }

                  return null;
              })
            }

            if(v.value === "") {
              v.error = "This field is required";

              errorCount = errorCount + 1;

              if(scrollToError === "") { scrollToError = `domain-${i}`}
            }

            return null;
        })

        if(_parse.region.length === 0) {
            _parse.regionError = "Please select at least one region";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `custom-switch-region`}
        }

        //region
        if(_parse.region.length === 0) {
            _parse.regionError = "Please select at least one region";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `custom-switch-region`}
        }

        //phone
        if(_parse.phoneNumber === "") {
            _parse.phoneNumberError = "This field is required";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `input-phone`}
        }

        //contact
        if(!HelperInput.validURL(_parse.contactURL)) {
            _parse.contactURLError = "Invalid URL format";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `input-contact-url`}
        }

        if(_parse.contactURL    === "") {
          _parse.contactURLError = "This field is required";
          errorCount = errorCount + 1;

          if(scrollToError === "") { scrollToError = `input-contact-url`}
        }

        //source
        if(_parse.contactOptions.length === 0) {
            _parse.contactOptionsError = "Please select at least one contact type";
            errorCount = errorCount + 1;

            if(scrollToError === "") { scrollToError = `checkbox-source`}
        }
    }

    if(errorCount === 0) {
      setIsLoadingEdit(true);

      let reg = [];
      let link = [];

      data.region.map((v) => {
        reg.push(v.id)
        return null;
      })

      data.domain.map((v) => {
        link.push(v.value)
        return null;
      })

      let _data = {
        channelID: Number(match.params.id),
        isEnabled: data.isEnabled,
        nonLocal: {
            contactURL: data.contactURL,
            contactOptions: data.contactOptions,
            phoneNumber: `+${data.phoneNumber}`,
            sitelinkDomains: link,
            regionCodes: reg,
            isAllRegions: data.isAllRegions
        }
      };

      if(!_data.isEnabled) {
        delete _data.nonLocal;
      }

      IntegrationGoogleServices.editNonLocal(orgID, _data, (response) => {
        if (response.dataResult.error.message === "") {
          if(response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
            setDataOrigin(data);
            setTimeout(() => {
              history.push({
                pathname: `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
                state: {
                  whichTab: "entryPoints",
                },
              })
            }, 3000);
          }else {
            doToast(response.dataResult.data.message, "fail");
            setIsLoadingEdit(false);
          }
        } else {
          doToast(response.dataResult.error.message, "fail");
          setIsLoadingEdit(false);
        }
      }); 
    }else {
      scrollToClass(`.${scrollToError}`);
      setData(_parse);
    }
  };

  let onChangeSwitch = (e) => {
    let _data = {...data};
    _data.isEnabled = e.target.checked;
    setData(_data);
  }

  let addMoreDomain = () => {
    let _editGoogle = JSON.stringify(data);
    let _parse = JSON.parse(_editGoogle);
    _parse.domain.push({
        value: "",
        error: ""
    })
    setData(_parse);
  }

  let onChangeDomain = (e, i) => {
      let _editGoogle = JSON.stringify(data);
      let _parse = JSON.parse(_editGoogle);
      _parse.domain[i].value = e.target.value;
      _parse.domain[i].error = "";
      setData(_parse);
  }

  let onClickRemoveDomain = (i) => {
      let _editGoogle = JSON.stringify(data);
      let _parse = JSON.parse(_editGoogle);
      _parse.domain.splice(i, 1);
      setData(_parse);
  }

  let printDomain = (v, i) => {
    return (
      <>
        <label className={`label-domain domain-${i}`}>
            <b>Domain{i > 0 ? `#${i+1}` : ""}</b>
        </label>

        <div className="domain-input-wrapper">
            <input 
                type="text" 
                onChange={(e) => onChangeDomain(e, i)} 
                value={v.value} 
                placeholder="Type your domain here"
                className={v.error === "" ? "" : "border-red"}
                disabled={isLoadingEdit}
            />

            {v.error !== "" &&
                <ErrorOrInfoComp
                    text={v.error}
                    _className={"font-red"}
                    icon={<FiInfo />}
                />
            }

            {i > 0 &&
                <FiTrash2 
                    className="remove-domain-icon"
                    onClick={() => onClickRemoveDomain(i)}
                />
            }
        </div>
      </>
    )
  }

  let onChangePhone = (e) => {
    let _editGoogle = JSON.stringify(data);
    let _parse = JSON.parse(_editGoogle);

    if(HelperInput.numberOnly(e.target.value)) {
      _parse.phoneNumber = e.target.value;
      _parse.phoneNumberError = "";
    }
    
    setData(_parse);
  }

  let onChangeContactURL = (e) => {
    let _editGoogle = JSON.stringify(data);
    let _parse = JSON.parse(_editGoogle);

    _parse.contactURL = e.target.value;
    _parse.contactURLError = "";

    setData(_parse);
  }

  let onClickCheckBoxSource = (e) => {
    let _editGoogle = JSON.stringify(data);
    let _parse = JSON.parse(_editGoogle);
    setData(_parse);
  }

  let onChangeSearchBulk = (e) => {
      let _bulkOrigin = bulkListOrigin.slice();
      let result = [];
      setSearchBulk(e.target.value);

      if (_bulkOrigin.length > 0) {
        for (let i in _bulkOrigin) {
          let objectKey = Object.keys(_bulkOrigin[i]);

          for (let j in objectKey) {
            let isString = typeof _bulkOrigin[i][objectKey[j]] === "string";
            if (_bulkOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value) && isString) {
              result.push(_bulkOrigin[i])
              break;
            }
          }
        }
      }

      setBulkList(result);
  }

  let onChangeSingleSelect = (e, val) => {
    if(!isLoadingEdit) {
      let _editGoogle = JSON.stringify(data);
      let _parse = JSON.parse(_editGoogle);

      let _selected = _parse.region;

      if (e.target.checked) {
          _selected = [..._selected, val];
      } else {
          let _dataSelected = [..._selected];
          _selected = _dataSelected.filter(el => el.id !== val.id)
      }

      _parse.region = _selected;
      _parse.regionError = "";
      setData(_parse);
    }
  }

  let removeSingleBulk = (val) => {
    if(!isLoadingEdit) {
      let _editGoogle = JSON.stringify(data);
      let _parse = JSON.parse(_editGoogle);
      let _selected = _parse.region;
      let _dataSelected = [..._selected]

      _selected = _dataSelected.filter(el => el.id !== val.id)
      _parse.region = _selected;
      setData(_parse);
    }
  }

  let isChecked = (id) => {
      let _data = [...data.region];
      return _data.findIndex(val => val.id === id) !== -1;
  }

  let onClearAll = () => {
    if(!isLoadingEdit) {
      let _editGoogle = JSON.stringify(data);
      let _parse = JSON.parse(_editGoogle);
      _parse.region = [];
      setData(_parse);
    }
  }

  let onChangeSwitchAllRegion = (e) => {
      let _editGoogle = JSON.stringify(data);
      let _parse = JSON.parse(_editGoogle);
      _parse.isAllRegions = e.target.checked;
      _parse.region = bulkList;
      _parse.regionError = "";
      setData(_parse);
  }

  return (
    <div className="edit-entry-point-outer-wrapper main-integration-google-create-entry">
      <SectionWrap 
        {...props} 
        orgID={orgID}
        withHeader 
        withSideMenu
        dataOrigin={JSON.stringify(dataOrigin)}
        dataAfterChange={JSON.stringify(data)}
      >
        <BackButton
          text="Back to Entry Points"
          onClick={() =>
            history.push({
              pathname: `/o/${orgID}/integration/google-business-messages/detail/${props.match.params.id}`,
              state: {
                whichTab: "entryPoints",
              },
            })
          }
        />

        <div className="edit-entry-point-google sectionWrap">
          {isLoadingGetDetailData || retriveDataError.code ?
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message}
              />
            </div>
            :
            <>
              <div className="edit-entry-point-header">
                <div className="title-wrapper">
                  <b>Edit Non-local</b>
                </div>
                <div className="button-wrapper">
                  {!isLoadingEdit ? 
                    <ButtonWithLoadingOrIcon
                      className="orange-button  main-button-40"
                      text="Save Changes"
                      // isDisabled={JSON.stringify(dataOrigin) === JSON.stringify(data)}
                      onClickAction={submitEdit}
                    />
                    : 
                    <ButtonWithLoadingOrIcon
                      isLoading
                      loadingColor="gray"
                      isDisabled
                      className="orange-button main-button-40"
                      text="Save Changes"
                      position="left"
                    />
                  }
                </div>
              </div>

              <div className="edit-entry-point-body">
                  <p className="form-title">
                    <b>
                      Edit Non-local
                    </b>

                    <label className="custom-switch-wrapper">
                      <input disabled={isLoadingEdit} type="checkbox" onChange={(e) => onChangeSwitch(e)} checked={data.isEnabled} />
                      <span className="custom-switch round"></span>
                    </label>
                  </p>

                  <p className="form-description">
                    Non-local entry point group includes all entry points that don't rely on locations
                  </p>
              </div>

              {/* non local */}
              {data.isEnabled &&
                  <div className="non-local-form-wrapper">
                      {data.domain.map((v, i) => {
                          return (
                              printDomain(v, i)
                          )
                      })}

                      <p className="add-more-domain" onClick={addMoreDomain}>
                          <b>
                              <FiPlus />
                              Add More Domain
                          </b>
                      </p>

                      <label className="custom-switch-wrapper  custom-switch-region">
                          <input disabled={isLoadingEdit} type="checkbox" onChange={(e) => onChangeSwitchAllRegion(e)} checked={data.isAllRegions} />
                          <span className="custom-switch round"></span>
                          <b>All Region</b>
                      </label>

                      {!data.isAllRegions &&
                          <SelectBulk 
                              onChangeSearchBulk={onChangeSearchBulk}
                              onClearAll={onClearAll}
                              bulkList={bulkList}
                              onChangeSingleSelect={onChangeSingleSelect}
                              isChecked={isChecked}
                              isCheckAll={data.isAllRegions}
                              searchBulk={searchBulk}
                              title={"Select Region"}
                              selectedBulk={data.region}
                              removeSingleBulk={removeSingleBulk}
                              withOutSelectAll
                              selectedTitle={"Selected region will be displayed here."}
                              icon={IconRegion}
                              searchTitle={"Search for region"}
                              errorText={data.regionError}
                          />
                      }
                      
                      <label className="margin-top-24 input-phone">
                          <b>Phone Number</b>
                      </label>

                      <div className="input-phone-wrapper width-400">
                          <input 
                              type="text" 
                              onChange={(e) => onChangePhone(e)} 
                              value={data.phoneNumber} 
                              placeholder="812xxxxxxxx"
                              className={data.phoneNumberError === "" ? "" : "border-red"}
                              disabled={isLoadingEdit}
                          />

                          <FiPlus className="icon-plus" />

                          <ErrorOrInfoComp
                            text={
                                data.phoneNumberError === "" ? 
                                    "Always start the phone number with the country code without the “+” sign e.g. “628XXXXXXXXX”."
                                    :
                                    data.phoneNumberError
                            }
                            _className={data.phoneNumberError === "" ? "" : "red-text"}
                            icon={<FiInfo />}
                        />
                      </div>

                      <label className="margin-top-24 input-contact-url">
                          <b>Contact Us URL</b>
                      </label>
                      <div className="width-400">
                          <input 
                              type="text" 
                              onChange={(e) => onChangeContactURL(e)} 
                              value={data.contactURL} 
                              placeholder="Type your URL here"
                              className={data.contactURLError === "" ? "" : "border-red"}
                              disabled={isLoadingEdit}
                          />

                          {data.contactURLError !== "" &&
                            <ErrorOrInfoComp
                                text={data.contactURLError}
                                _className={"font-red"}
                                icon={<FiInfo />}
                            />
                          }
                      </div>

                      <div className="checkbox-wrapper checkbox-source">
                        {checkBoxList.map((v, i) => {
                            return (
                                <div className="custom-checkbox" key={`checkbox-source-${i}`}>
                                    <input 
                                        type="checkbox" 
                                        id={`checkbox-source-${i}`}
                                        onChange={(e) => {
                                            let _editGoogle = JSON.stringify(data);
                                            let _parse = JSON.parse(_editGoogle);
                                            let _arr = _parse.contactOptions.slice();
                                            
                                            if(data.contactOptions.findIndex(_v => _v === v.value) === -1) {
                                                _arr.push(v.value);
                                            }else {
                                                _arr.splice(data.contactOptions.findIndex(_v => _v === v.value), 1);
                                            }
                                            
                                            _parse.contactOptionsError = "";
                                            _parse.contactOptions = _arr;
                                            
                                            setData(_parse);
                                        }}
                                        checked={data.contactOptions.findIndex(_v => _v === v.value) !== -1}
                                        disabled={isLoadingEdit}
                                    />
                                    <label htmlFor={`checkbox-source-${i}`}>{v.label}</label>
                                </div>
                            )
                        })}
                      </div>

                      {data.contactOptionsError !== "" &&
                        <ErrorOrInfoComp
                            text={data.contactOptionsError}
                            _className={"font-red no-margin"}
                            icon={<FiInfo />}
                        />
                    }
                  </div>
              }
              {/* non local */}
            </>
          }
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationGoogleEditEntryPointNonLocal;
