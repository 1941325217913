export class HelperInput {
    withEmoji(text) {
        let withEmojis = /\p{Extended_Pictographic}/ug;

        return withEmojis.test(text);
    }
    emailFormatValid(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email); 
    }

    hourMinuteFormat(string) {
        let re = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
        return re.test(string);
    }

    numberOnly(value) {
        let re = /^[0-9]*$/;
        return re.test(value);
    }

    validURL(value) {
        // let regHttp = /^http:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/;
        // let regHttps = /^https:\/\/\w+(\.\w+)*(:[0-9]+)?\/?(\/[.\w]*)*$/;
        let regExp = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
  
        // return (
        //   regHttp.test(value) || regHttps.test(value)
        // )
        return regExp.test(value);
    }

    alphanumericAndUnderscoreOnly(value) {
        let regExp = /^\w*$/;
        return regExp.test(value.toLowerCase());
    }

    alphanumericAndHypen(value) {
        let regExp = /^[a-zA-Z0-9-]+$/;
        return regExp.test(value.toLowerCase());
    }

    alphanumericOnly(value) {
        let regExp = /^[a-zA-Z0-9 ]*$/;
        return regExp.test(value.toLowerCase());
    }

    alphanumericAndSpaceOnly(value) {
        let regExp = /^[\w\-\s]+$/;
        return regExp.test(value);
    }

    noSpace(value) {
        var regexp = /^\S*$/;
        return regexp.test(value);
    }

    valid24HourFormat(value) {
        let regExp= /^([0-1]?[0-9]|[2][0-3]):([0-5][0-9])(:[0-5][0-9])?$/;
        return regExp.test(value);
    }

    isContainUppercase(text) {
        return /\p{Lu}/u.test(text);
    }

    phoneBeautify(phone, ignoreCountryCode = false) {
        let result = "";
        let countryCode = phone.substr(0, 3) === "+62" ? "62" : (phone.substr(0, 2) === "62" ? "62" : "");
        let _phone = phone.substr(0, 3) === "+62" ? phone.replace("+62", "") : (phone.substr(0, 2) === "62" ? phone.replace("62", "") : phone);
        let _phoneLength = _phone.length;
        
        if(_phoneLength > 5) {
            if(_phoneLength < 6) {
                result = _phone.substr(0);
            }
    
            if(_phoneLength < 8 && _phoneLength > 5) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4);
            }
    
            if(_phoneLength === 8) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4);
            }
        
            if(_phoneLength === 9) {
                result = _phone.substr(0, 3)+" "+_phone.substr(3, 3)+" "+_phone.substr(6, 3);
            }
        
            if(_phoneLength === 10) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 3)+" "+_phone.substr(7, 3);
            }
        
            if(_phoneLength === 11) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 3);
            }
        
            if(_phoneLength === 12) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 4);
            }
        
            if(_phoneLength === 13) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 3)+" "+_phone.substr(7, 3)+" "+_phone.substr(10, 3);
            }
        
            if(_phoneLength === 14) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 3)+" "+_phone.substr(11, 3);
            }

            if(_phoneLength === 15) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 4)+" "+_phone.substr(12, 3);
            }
        
            if(_phoneLength > 15) {
                result = _phone.substr(0, 4)+" "+_phone.substr(4, 4)+" "+_phone.substr(8, 3)+" "+_phone.substr(11, 3)+" "+_phone.substr(14);
            }
        
            return countryCode !== "" ? (ignoreCountryCode ? result: (countryCode+" "+result)) : result;
        }else {
            return phone;
        }
    }

    removeSpace(text) {
        let result = text.replaceAll(" ", "");
        return result;
    }
}

export default new HelperInput();

