import React, { Component } from "react";
import "./CustomSearchBox.css";
import { FiSearch } from "react-icons/fi";

class CustomSearchBox extends Component {
  constructor(props) {
      super(props);

      this.state = {
          dataToSearch: this.props.dataToSearch
      };
  }

  onChangeSearch = () => {
    let result = [];
    let data = this.state.dataToSearch;
    let valSearch = document.querySelector(`#search-box-input-${this.props.id ? this.props.id : "val"}`).value.toLowerCase();
    if(data.length > 0) {
    for(let i in data) {
        let objectKey = Object.keys(data[i]);

        for(let j in objectKey) {
            let isString = typeof data[i][objectKey[j]] === "string";
            if(data[i][objectKey[j]].toString().toLowerCase().includes(valSearch) && isString) {
                result.push(data[i])
                break;
            }
        }
    }
    }

    this.props.onChangeSearch(result);
  }

  render() {
    return (
        <div className="search-box-wrapper" style={this.props.style}>
            {this.state.valSearch}
            
            {this.props.onSubmitSearch ?
                <input type="text" 
                    placeholder={this.props.placeholder}
                    onChange={(e) => this.props.onChangeSearch(e)}
                    id={`search-box-input-${this.props.id ? this.props.id : "val"}`}
                    onKeyUp={(e) => {
                        if(e.which === 13) {
                            this.props.onSubmitSearch(e.target.value);
                        }
                    }}
                    value={this.props.search}
                />
                :
                <input type="text" 
                    placeholder={this.props.placeholder}
                    onChange={(e) => this.onChangeSearch(e)}
                    id={`search-box-input-${this.props.id ? this.props.id : "val"}`}
                />
            }
            
            <div id="search-icon">
                <FiSearch size="20" />
            </div>
        </div>
    );
  }
}
  
export default CustomSearchBox;