import React, { useState, useEffect } from 'react';
import './ChatRoomCaseHistoryCaseDetail.scss';
// import ChatRoomUserInfo from './chatRoomMain/chatRoomUserInfo/ChatRoomUserInfo';
import { MdFiberManualRecord } from 'react-icons/md';
import { FiMail, FiInfo, FiUser } from 'react-icons/fi';
import { connect } from 'react-redux';
import ChatRoomCaseDetailTab from './chatRoomCaseHistoryDetailTab/ChatRoomCaseHistoryDetailTab';
import ChatRoomUserInfoTab from './chatRoomCaseHistoryUserInfoTab/ChatRoomCaseHistoryUserInfoTab';
import { taptalk } from '@taptalk.io/web-sdk';
import Helper from '../../../../helper/HelperChat';
import { TAPLIVE_MEDIUM } from "../../../../constants/taplive";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import iconReplaceAvatar from "../../../../assets/img/replace-avatar.svg";

const USER_INFO_TAB = {
    caseDetail: 1,
    userInfo: 2,
}

var ChatRoomCaseHistoryCaseDetail = (props) => {
  let [activeUserInfoTab, setActiveUserInfoTab] = useState(USER_INFO_TAB.caseDetail);
  let [userAgentData, setUserAgentData] = useState(null);

  useEffect(() => {
    if(props.activeRoom !== null) {
        let _userAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).account;
        setUserAgentData(_userAgentData);
    }
  }, [props.activeRoom])

  let onClickUserInfoTab = (tab) => {
    setActiveUserInfoTab(tab)
  }

  return (
    props.caseHistoryPopupDataProps &&
        <div className={`chat-room-user-info-wrapper ${props.isUserDetailShowProps ? 'active-chat-room-user-info-wrapper' : ''}`}>
            <div className="user-info-name-avatar-wrapper">
                {props.caseHistoryPopupDataProps.user.photoURL === "" ?
                    <div 
                        className="user-info-avatar-wrapper user-avatar-name" 
                        style={{background: taptalk.getRandomColor(props.caseHistoryPopupDataProps.user.fullName)}}
                    >
                        <b>{Helper.renderUserAvatarWord(props.caseHistoryPopupDataProps.user.fullName)}</b>
                    </div>
                    :
                    <img 
                        className="user-avatar-name" 
                        src={props.caseHistoryPopupDataProps.user.photoURL} 
                        alt=""
                        onError={(e) => {e.target.onerror = null; e.target.src = iconReplaceAvatar;}} 
                    />
                }

                <div className="user-info-name-connectivity-modal">
                    <p>
                        <b>{props.caseHistoryPopupDataProps.user.alias ?
                                props.caseHistoryPopupDataProps.user.alias !== "" ?
                                    `${props.caseHistoryPopupDataProps.user.alias} (${props.caseHistoryPopupDataProps.user.fullName})`
                                    :
                                    props.caseHistoryPopupDataProps.user.fullName
                                :
                                props.caseHistoryPopupDataProps.user.fullName
                            }
                        </b>
                    </p>
                    
                    {props.caseHistoryPopupDataProps.case.medium === TAPLIVE_MEDIUM.launcher &&
                        ((!props.caseHistoryPopupDataProps.user.isOnline && userAgentData !== null) ?
                            !props.caseHistoryPopupDataProps.case.isClosed && props.caseHistoryPopupDataProps.case.agentAccountID === userAgentData.id && props.caseHistoryPopupDataProps.case.userEmail !== "" ?
                                <a href={`mailto:${props.caseHistoryPopupDataProps.case.userEmail}`}>
                                    <div className="user-info-offline"><FiMail />Send Email</div>
                                </a>
                                :
                                <span className="user-info-offline-dot"><MdFiberManualRecord />OFFLINE</span>
                            :
                            <span className="user-info-online"><MdFiberManualRecord />ONLINE</span>
                        )
                    }
                </div>
            </div>

            <div className="user-info-tab-wrapper">
                <div className={`user-info-tab-content ${activeUserInfoTab === USER_INFO_TAB.caseDetail ? 'active-room-list-tab' : ''}`} 
                        onClick={() => onClickUserInfoTab(USER_INFO_TAB.caseDetail)}
                >
                    <FiInfo />
                    <b>Case Details</b>
                </div>

                <div className={`user-info-tab-content ${activeUserInfoTab === USER_INFO_TAB.userInfo ? 'active-room-list-tab' : ''}`} 
                        onClick={() => onClickUserInfoTab(USER_INFO_TAB.userInfo)}
                >
                    <FiUser />
                    <b>Contact Info</b>
                </div>
            </div>

            <div className="user-info-tab user-info-tab-modal">
                {activeUserInfoTab === USER_INFO_TAB.caseDetail &&
                    <ChatRoomCaseDetailTab caseHistoryPopupDataProps={props.caseHistoryPopupDataProps}
                                        messageListenerNewMessageProps={props.messageListenerNewMessageProps}
                                        messageListenerUpdateMessageProps={props.messageListenerUpdateMessageProps}
                    />
                }

                {activeUserInfoTab === USER_INFO_TAB.userInfo &&
                    <ChatRoomUserInfoTab caseHistoryPopupDataProps={props.caseHistoryPopupDataProps} />
                }
            </div>
        </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom
});

export default connect(mapStateToProps, null)(ChatRoomCaseHistoryCaseDetail);
