import React from "react";
import { Modal } from "reactstrap";
import NewButton from "../reuseableComponent/NewButton/NewButton";
import NewSelect from "../reuseableComponent/NewSelect/NewSelect";
import ButtonWithLoadingOrIcon from "../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";

const doSetFilteredName = (list, nowList, setList) =>
  list.map(item => {
    const { accountName } = item;
    return setList([...nowList, accountName]);
  });

const AssignAgentView = props => {
  const { isOpen, setPopAssign, resetForm, agentMember, detailsData } = props;
  const { handleSubmit, setFieldValue, values } = props;
  const { loadingAPI, errorAPI } = values;
  const setPopup = setPopAssign;
  const [filteredName, setFilteredName] = React.useState(false);
  const { agents } = detailsData;

  React.useEffect(() => {
    const hasAgent = agents && agents.length > 0;
    if (hasAgent) doSetFilteredName(agents, filteredName, setFilteredName);
    else setFilteredName([]);
  }, [detailsData]);

  const cancel = () => {
    resetForm();
    setPopup(false);
  };

  const toggle = () => setPopup(!isOpen);

  const onChange = e => {
    let newAgentList = [];

    if (!e) return setFieldValue("agentList", []);
    return e.map(i => {
      newAgentList.push(i.value);
      setFieldValue("agentList", newAgentList);
      return null;
    });
  };

  const makeList = () => {
    let rets = [];
    if (agentMember)
      agentMember.map(item => {
        const { accountID: value, accountName: label } = item;
        const sameName = filteredName.indexOf(label) < 0;
        if (sameName) return rets.push({ value, label });
        return false;
      });
    return rets;
  };

  return (
    <Modal isOpen={isOpen} className="Modal" toggle={toggle}>
      {/* <form> */}
      <div className="popup-header">Assign Agent</div>
      <div className="popup-body">
        <NewSelect
          name="agentList"
          options={makeList()}
          onChange={onChange}
          type="textarea"
          search
          isMulti
          openSelect={isOpen}
          isDisabled={loadingAPI}
        />
        {errorAPI && (
          <div className="errorInfo">
            <i>{errorAPI}</i>
          </div>
        )}
        <div className="button-flex">
          <NewButton
            text="Cancel"
            onClick={cancel}
            className="no-fill-button"
          />
          {/* <NewButton text={textBtn} disabled={loadingAPI} type="submit" /> */}
          &nbsp; &nbsp;
          {loadingAPI ?
            <ButtonWithLoadingOrIcon
              isLoading
              loadingColor="gray"
              isDisabled
              className="orange-button main-button-40"
              text="Assign Agents"
              position="left"
            />
            :
            <ButtonWithLoadingOrIcon
              className="orange-button main-button-40"
              text="Assign Agents"
              isDisabled={loadingAPI}
              onClickAction={handleSubmit}
            />
          }
        </div>
      </div>
      {/* </form> */}
    </Modal>
  );
};

export default AssignAgentView;
