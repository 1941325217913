import React, { useEffect, useState } from "react";
import "./BroadcastMessageModalSelectRecipient.scss";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { FiX, FiUsers, FiUserPlus, FiFilePlus, FiDownload } from "react-icons/fi";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperInput from "../../../../helper/HelperInput";
import UserContactServices from "../../../../services/newServices/UserContactServices";
import ListPerPage from "../../../reuseableComponent/listPerPage/ListPerPage";
import SearchBox from "../../../reuseableComponent/searchBox/SearchBox";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import Pagination from "../../../reuseableComponent/pagination/Pagination";
import { connect } from 'react-redux';
import { TAPLIVE_MEDIUM } from '../../../../constants/taplive';
import mixpanel from "mixpanel-browser";

const DEFAULT_PAGE_SIZE = 20;

const BroadcastMessageModalSelectRecipient = props => {
    let [isShowSelectFromContact, setIsShowSelectFromContact] = useState(false);
    let [isShowDropdownListPerPage, setIsShowDropdownListPerPage] = useState(false);
    let [currentListPerPageValue, setCurrentListPerPageValue] = useState(DEFAULT_PAGE_SIZE);
    let [pageDisplay, setPageDisplay] = useState(1);
    let [contactList, setContactList] = useState([]);
    let [totalUsers, setTotalUsers] = useState(0);
    let [selectedContactList, setSelectedContactList] = useState([]);
    let [isWaitResponseGetContact, setIsWaitResponseGetContact] = useState(false);
    let [contactSearch, setContactSearch] = useState("");

    useEffect(() => {
        runGetContactList();
    }, [pageDisplay])

    useEffect(() => {
        if (contactSearch === "") {
            runGetContactList();
        }
    }, [contactSearch])

    useEffect(() => {
        if (currentListPerPageValue !== "") {
            runGetContactList(true);
        }
    }, [currentListPerPageValue])

    let onSubmitAddSelectedContact = () => {
        selectedContactList.map((val) => {
            val.origin = "Contact List";
            return null;
        })

        setPageDisplay(1);
        props.setContacts(selectedContactList);
        props.toggleProps();
        toggleSelectFromContact();
        setSelectedContactList([]);
    }
      
    let findChecked = (userID, selectedList = false) => {
        let _findIndex = selectedList ? selectedList.findIndex(val => val.userID === userID) : selectedContactList.findIndex(val => val.userID === userID);
        
        return _findIndex;
    }

    let onClickSelectAll = (e) => {
        let _selected = selectedContactList.slice();
        let _checked = e.target.checked;

        let runCheck = (checked) => {
            contactList.map((val, idx) => {
                let _idx = findChecked(val.userID, _selected);

                if(checked) {
                    if(_idx === -1) {
                        _selected.push(contactList[idx]);
                    }
                }else {
                    if(_idx !== -1) {
                        _selected.splice(_idx, 1);
                    }
                }

                setSelectedContactList(_selected);

                return null;
            })   
        }
        
        runCheck(_checked);
    }

    let clearSelectedContact = () => {
        setSelectedContactList([]);
    }

    let toggleSelectFromContact = () => {
        setIsShowSelectFromContact(!isShowSelectFromContact);
    }

    let toggleDropdownListPerPage = () => {
        setIsShowDropdownListPerPage(!isShowDropdownListPerPage);
    }

    let onChangeListPerPage = (val) => {
        setPageDisplay(1);
        setCurrentListPerPageValue(val);
    }

    let onChangeSearchContact = (val) => {
        setContactSearch(val)
    }

    let handleChangePage = (page) => {
        setPageDisplay(page);
        // runGetContactList();
    }

    let modalSelectFromContact = () => {
        let isSelectedAllChecked = () => {
            let _checked = true;
            let _selected = selectedContactList.slice();

            if(_selected.length === 0) {
                _checked = false;
            }else {
                contactList.map((val) => {
                    if(findChecked(val.userID) === -1) {
                        _checked = false;
                    }

                    return null;
                })
            }

            return _checked;
        }

        let onChecked = (val) => {
            let _selected = selectedContactList.slice();
            let _findIndex = _selected.findIndex(_val => _val.userID === val.userID);

            if (_findIndex === -1) {
                _selected.push(val);
            } else {
                let elSelectAll = document.querySelector("#select-all-contact");
                elSelectAll.checked = false;

                _selected.splice(_findIndex, 1);
            }

            setSelectedContactList(_selected);
        }

        return (
            <Modal toggle={toggleSelectFromContact} isOpen={isShowSelectFromContact} className="modal-select-contact">
                <ModalHeader>
                    <p className="select-contact-title">
                        <FiUsers className="user-icon" />
                        <b>Add From Contact</b>
                    </p>

                    {/* <div className="modal-select-search-wrapper">
                        <input type="text" placeholder="Select for contacts" />
                        <FiSearch/>
                    </div> */}
                    <SearchBox
                        onSubmitSearch={() => runGetContactList(true)}
                        onChangeInputSearch={onChangeSearchContact}
                        searchValue={contactSearch}
                        isDisabled={true}
                        isWaitResponse={isWaitResponseGetContact}
                        placeholder="Search for a contact"
                    />
                </ModalHeader>

                <ModalBody>
                    <table className="table-contact-list">
                        <thead>
                            <tr>
                                <td className="with-checkbox">
                                    <div className="custom-checkbox">
                                        <input type="checkbox" id="select-all-contact" checked={isWaitResponseGetContact ? false : isSelectedAllChecked()} onClick={(e) => onClickSelectAll(e)} />
                                        <label htmlFor="select-all-contact" />
                                    </div>
                                    <b>Full Name</b>
                                </td>
                                <td><b>Alias</b></td>
                                <td><b>Email</b></td>
                                <td><b>Phone Number</b></td>
                                <td className="list-per-page">
                                    <ListPerPage
                                        isOpen={isShowDropdownListPerPage}
                                        toggle={toggleDropdownListPerPage}
                                        currentValue={currentListPerPageValue}
                                        onChangeDropdown={onChangeListPerPage}
                                    />
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            {(contactList.length > 0 && !isWaitResponseGetContact) &&
                                contactList.map((val, index) => {
                                    return (
                                        <tr key={`contact-list-${index}`}>
                                            <td className="with-checkbox">
                                                <div className="custom-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        id={`select-contact-${val.userID}`}
                                                        checked={findChecked(val.userID) !== -1}
                                                        onChange={() => onChecked(val)}
                                                    />
                                                    <label htmlFor={`select-contact-${val.userID}`} />
                                                </div>
                                                {val.fullName}
                                                <br />
                                                <span>{val.alias}</span>
                                            </td>
                                            <td>{val.alias}</td>
                                            <td>{val.email}</td>
                                            <td>+{HelperInput.phoneBeautify(val.phone)}</td>
                                            <td></td>
                                        </tr>
                                    )
                                })
                            }

                            <tr>
                                {(contactList.length === 0 && !isWaitResponseGetContact) &&
                                    <td colSpan={4} className="table-center">
                                        <span>
                                            No Contact Found
                                        </span>
                                    </td>
                                }

                                {isWaitResponseGetContact &&
                                    <td colSpan={4} className="table-center">
                                        <div className="lds-ring">
                                            <div /><div /><div /><div />
                                        </div>
                                    </td>
                                }
                            </tr>
                        </tbody>
                    </table>
                    
                    {totalUsers > 0 &&
                        <Pagination
                            total={totalUsers}
                            page={pageDisplay}
                            listPerPage={currentListPerPageValue}
                            step={1}
                            onChangePage={handleChangePage}
                        />
                    }
                </ModalBody>

                <ModalFooter>
                    <ButtonWithLoadingOrIcon
                        onClickAction={() => {
                            setPageDisplay(1);
                            clearSelectedContact();
                            toggleSelectFromContact();
                        }}
                        className="no-fill-button main-button-48 cancel-button"
                        text="Cancel"
                    />

                    {selectedContactList.length > 0 &&
                        <button className="button-clear-selection" onClick={() => {
                            clearSelectedContact()
                        }}>
                            <b>Clear Selection</b>
                        </button>
                    }

                    <ButtonWithLoadingOrIcon
                        isDisabled={selectedContactList.length === 0}
                        onClickAction={onSubmitAddSelectedContact}
                        className="orange-button main-button-48 button-submit"
                        text={`Add ${selectedContactList.length > 0 ? selectedContactList.length : ""} Contact${selectedContactList.length > 1 ? "s" : ""}`}
                    />
                </ModalFooter>
            </Modal>
        )
    }

    let runGetContactList = (onSearch = false) => {
        onSearch && setPageDisplay(1);
        // clearSelectedContact();
        setIsWaitResponseGetContact(true);


        let data = {
            search: contactSearch,
            pageNumber: !onSearch ? pageDisplay : 1,
            pageSize: currentListPerPageValue
        }

        UserContactServices.getContactWhatsapp(props.parentProps.match.params.orgID, data, (response) => {
            if (response.dataResult.status === 200) {
                let dataResult = response.dataResult;
                setTotalUsers(dataResult.data.totalUsers);
                setContactList(dataResult.data.users);
            }

            setIsWaitResponseGetContact(false);
        });
    }

    let modalSelectRecipient = () => {
        return (
            <Modal isOpen={props.isOpenProps} toggle={props.toggleProps} className="modal-select-how-to-add-recipient">
                <ModalHeader>
                    <p className="add-recipient-title">
                        <b>Add Recipient</b>
                        <br />
                        <span> Select how you like to add recipients.</span>
                        <FiX className="close-modal" onClick={() => props.toggleProps()} />
                    </p>
                </ModalHeader>

                <ModalBody>
                    <div className="select-recipient-by from-contact" onClick={() => {
                        toggleSelectFromContact();
                    }}>
                        <div className="select-recipient-by-icon">
                            <FiUsers />
                        </div>
                        <br />
                        <b>
                            Add From Contact
                        </b>

                        <p>
                            Add recipients from your contacts
                        </p>
                    </div>

                    {props.messageChannel.value === "whatsapp" &&
                        <div className="select-recipient-by import-csv">
                            <label
                                htmlFor="csv-file-select"
                                onClick={() => {
                                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                                        mixpanel.track(
                                            "[Button] Add Recipient",
                                            {
                                                source: "CSV",
                                                type: props.messageChannel,
                                                userFullName: myAgentData.account.fullName,
                                                userID: myAgentData.account.id,
                                                organizationID: props.parentProps.match.params.orgID.split("-")[0]
                                            }
                                        );
                                    }
                                }}
                            >
                                <div className="select-recipient-by-icon">
                                    <FiFilePlus />
                                </div>
                                <br />
                                <b>
                                    Import as CSV
                                </b>

                                <br />
                                
                                {props.messageChannel.value === TAPLIVE_MEDIUM.whatsappba ?
                                    <a
                                        href={require(`../../Phone_number_data_example_waba.csv`)}
                                        download="Phone-number-list-example_waba.csv"
                                        onClick={() => {
                                            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                                            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                                                mixpanel.track(
                                                    "[Action] Download CSV Template",
                                                    {
                                                        type: props.messageChannel,
                                                        userFullName: myAgentData.account.fullName,
                                                        userID: myAgentData.account.id,
                                                        organizationID: props.parentProps.match.params.orgID.split("-")[0]
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        <div className="button-download-csv no-fill-button">
                                            <FiDownload />
                                            <b>Download CSV Template</b>
                                        </div>
                                    </a>
                                    :
                                    <a
                                        href={require(`../../Phone_number_data_example.csv`)}
                                        download="Phone-number-list-example.csv"
                                        onClick={() => {
                                            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

                                            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                                                mixpanel.track(
                                                    "[Action] Download CSV Template",
                                                    {
                                                        type: props.messageChannel,
                                                        userFullName: myAgentData.account.fullName,
                                                        userID: myAgentData.account.id,
                                                        organizationID: props.parentProps.match.params.orgID.split("-")[0]
                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        <div className="button-download-csv no-fill-button">
                                            <FiDownload />
                                            <b>Download CSV Template</b>
                                        </div>
                                    </a>
                                }

                                <p>
                                    Import existing recipients by importing CSV
                                </p>
                            </label>
                        </div>
                    }


                    <div className="select-recipient-by add-manually" onClick={() => {
                        props.toggleProps();
                        props.showModalAddRecipientProps();
                    }}>
                        <div className="select-recipient-by-icon">
                            <FiUserPlus />
                        </div>
                        <br />
                        <b>
                            Add manually
                        </b>

                        <p>
                            Send messages to people as plain text
                        </p>
                    </div>
                </ModalBody>
            </Modal>
        )
    }

    return (
        <div className="">
            {modalSelectRecipient()}
            {modalSelectFromContact()}
        </div>
    );
};

const mapStateToProps = state => ({
    countryList: state.countryList,
});

export default connect(mapStateToProps, null)(BroadcastMessageModalSelectRecipient)