import BaseService from "../BaseService";
import { API_KEY } from "../../config";

class ServiceOrganization {
    postGetOrganization(callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
        };
        
        BaseService.doPostAccessToken(API.GET_ORGANIZATION, {}, headers, callback);
    }

    postNewOrganization(param, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
        };
        
        BaseService.doPostAccessToken(API.NEW_ORGANIZATION, param, headers, callback);
    }

    postGetOrganizationDetail(orgID, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.GET_ORGANIZATION_DETAIL, {}, headers, callback);   
    }

    postEditOrganization(orgID, param, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.EDIT_ORGANIZATION, param, headers, callback);
    }

    postGetTierList(orgID, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.GET_PROJECT_TIER, {}, headers, callback);
    }

    postSetTierList(orgID, param, callback) {
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };

        BaseService.doPostAccessToken(API.SET_PROJECT_TIER, param, headers, callback);
    }
     
    postGetTimezone(callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
        };
        
        BaseService.doPostAccessToken(API.GET_TIMEZONE, {}, headers, callback);
    }

    postGetCountry(callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
        };
        
        BaseService.doPostWithoutAccessTokenWithCallback(API.GET_COUNTRY, {}, headers, callback);
    }

    postGetMedium(orgID, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };
        
        BaseService.doPostAccessToken(API.GET_MEDIUM, {}, headers, callback);
    }

    postGetBalance(orgID, callback) {        
        let headers = {
            "API-Key": API_KEY(),
            "Device-Platform": "web",
            "Organization-ID": orgID
        };
        
        BaseService.doPostAccessToken(API.GET_BALANCE, {}, headers, callback);
    }
}

const API = {
    GET_ORGANIZATION: "/account/organization/get",
    NEW_ORGANIZATION: "/account/organization/create/v1_7",
    GET_ORGANIZATION_DETAIL: "/organization/get_details",
    GET_PROJECT_TIER: "/organization/store/get_tier_list",
    SET_PROJECT_TIER: "/organization/store/create_tier_subscription",
    GET_TIMEZONE: "/account/time_zones",
    // GET_COUNTRY: "/account/countries",
    GET_COUNTRY: "/client/countries",
    GET_MEDIUM: "/organization/channel/get_mediums",
    GET_BALANCE: "/organization/billing/balance/get_current",
    EDIT_ORGANIZATION: "/organization/edit_details"
}

export default new ServiceOrganization()