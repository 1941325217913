import React, { useEffect, useState } from "react";
import "./AwayMessage.scss";
import { FiInfo } from "react-icons/fi";
import AwayWelcomeMessageServices from "../../../services/newServices/AwayWelcomeMessageServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import { doToast, checkID, scrollToClass } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import mixpanel from "mixpanel-browser"; 

const AwayMessage = (props) => {
    let [orgID, setOrgID] = React.useState(false);
    let [val, setVal] = useState({
        isEnabled: false,
        text: "",
        createdTime: 0,
        updatedTime: 0
    })
    let [valOrigin, setValOrigin] = useState({
        isEnabled: false,
        text: "",
        createdTime: 0,
        updatedTime: 0
    })
    let [isWaitingFetchMessage, setIsWaitingFetchMessage] = useState(false);
    let [isWaitingSetMessage, setIsWaitingSetMessage] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [errorMessageData, setErrorMessageData] = useState({
        message: ""
    });
    
    useEffect(() => {
        if(orgID) {
            getMessage();
        }
    }, [orgID])

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Away Message",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    const doMount = async () => {
        const paramID = checkID(props.match);
    
        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/away-message`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let toggleAwayMessage = () => {
        let _val = {...val};
        _val.isEnabled = !_val.isEnabled;
        setVal(_val);
    }

    let getMessage = () => {
        let _retriveDataError = {...retriveDataError};
        setIsWaitingFetchMessage(true);

        AwayWelcomeMessageServices.getAwayMessage(props.match.params.orgID, (response) => {
            setIsWaitingFetchMessage(false);
            let dataResult = response.dataResult;

            if(dataResult.error.message === "") {
                setVal(dataResult.data.awayMessage);
                setValOrigin(dataResult.data.awayMessage);
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }
        })
    }

    let setMessage = () => {
        if(retriveDataError.code !== "49901") {
            let errorCount = 0;
            let errorClass = "";
            let _errorMessageData = {...errorMessageData};

            if(val.text === "") {
                _errorMessageData.message = "This field is required";
                errorCount = errorCount + 1;
                
                if(errorClass === "") { errorClass = "input-message" }
            }

            if(val.text.length > 1000) {
                _errorMessageData.message = "Characters exceed limit";
                errorCount = errorCount + 1;
                
                if(errorClass === "") { errorClass = "input-message" }
            }

            if(errorCount > 0) {
                setErrorMessageData(_errorMessageData);
                scrollToClass(`.${errorClass}`);
            }else {
                setIsWaitingSetMessage(true);

                let data = {
                    isEnabled: val.isEnabled,
                    text: val.text
                }

                AwayWelcomeMessageServices.setAwayMessage(props.match.params.orgID, data, (response) => {
                    setIsWaitingSetMessage(false);
                    let dataResult = response.dataResult;

                    if(dataResult.error.message === "") {
                        setValOrigin(val);
                        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                        
                        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                            mixpanel.track(
                                "[Action] Set Away Message",
                                {
                                    status : val.isEnabled ? "On" : "Off",
                                    userFullName : myAgentData.account.fullName,
                                    userID : myAgentData.account.id,
                                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                                }
                            );
                        }
                        doToast(dataResult.data.message);
                    }else {
                        doToast(dataResult.error.message, "fail");
                    }
                })
            }
        }
    }

    let isDisabledTextareaMessage = () => {
        return val.isEnabled ? false : true;
    }

    // let isDisabledSubmitButton = () => {
    //     let dis = false;

    //     if(JSON.stringify(val) === JSON.stringify(valOrigin)) {
    //         dis = true;
    //     }

    //     if(val.isEnabled && val.text.length < 1) {
    //         dis = true;
    //     }

    //     return dis;
    // }

    let changeValueMessage = (e) => {
        let _val = {...val};
        let _errorMessageData = {...errorMessageData};

        _errorMessageData.message = "";
        _val.text = e.target.value;

        setErrorMessageData(_errorMessageData);
        setVal(_val);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={JSON.stringify(valOrigin)}
            dataAfterChange={JSON.stringify(val)}
        >
            <div className="away-welcome-message sectionWrap">
                {/* tier blocking */}
                {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                    isLoading={isWaitingFetchMessage}
                    errorMessage={retriveDataError.message}
                    errorCode={retriveDataError.code}
                    />
                }
                {/* tier blocking */}

                <React.Fragment>
                    <div className="away-welcome-message-header">
                        <b>Away Message</b>
                    </div>

                    {(isWaitingFetchMessage || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
                        <RetrieveDataLoading 
                            isLoading={isWaitingFetchMessage}
                            errorMessage={retriveDataError.message} 
                        />
                        :
                        <div className="away-welcome-message-form">
                            <div className="away-welcome-switch-wrapper">
                                <label className="custom-switch-wrapper">
                                    <input type="checkbox" checked={val.isEnabled} onChange={() => toggleAwayMessage()} />
                                    <span className="custom-switch round"></span>
                                </label>
                            </div>

                            <div className="away-welcome-message-form-content form-content-wrapper">
                                <b>Enable Away Message</b>
                                <p>
                                    Notify your visitors with a custom type message when you are away.
                                </p>

                                <label className={`${!val.isEnabled ? "label-disabled" :""} pos-relative`}>
                                    <b>Message</b>
                                    <span className={errorMessageData.message === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{val.text.length}/1000</span>
                                </label>
                                <textarea rows="5" 
                                    value={val.text} 
                                    placeholder="Your away message..." 
                                    disabled={isDisabledTextareaMessage() || isWaitingSetMessage} 
                                    onChange={(e) => changeValueMessage(e)}
                                    maxLength="1000"className={`input-message ${errorMessageData.message !== "" ? "border-red" : ""}`}
                                />
                                    
                                {errorMessageData.message !== "" &&
                                    <ErrorOrInfoComp
                                        text={errorMessageData.message}
                                        _className={"font-red"}
                                        icon={<FiInfo />}
                                    />
                                }
                            </div>

                            <br />

                            {!isWaitingSetMessage ?
                                <ButtonWithLoadingOrIcon 
                                    // isDisabled={isDisabledSubmitButton()} 
                                    onClickAction={setMessage}
                                    className="orange-button main-button-40 button-save"
                                    text="Save Changes"
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    isLoading
                                    loadingColor="gray"
                                    isDisabled
                                    className="orange-button main-button-40 button-save"
                                    text="Save Changes"
                                    position="left"
                                />
                            }
                        </div>
                    }      
                </React.Fragment>
            </div>
        </SectionWrap>
    )
}

export default AwayMessage;