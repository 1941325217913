import BaseService from '../BaseService';
import { API_KEY } from '../../config';

const IntegrationWhatsappBusinessServices = {
  getList(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_LIST, {}, headers, callback);
  },
  getDetail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_DETAIL, data, headers, callback);
  },
  editChannel(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_CHANNEL, data, headers, callback);
  },
  editAbout(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_ABOUT, data, headers, callback);
  },
  categoryOption(idOrTime, data = false, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_OPTION, data ? data : {}, headers, callback);
  },
  getTemplateList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_TEMPLATE_LIST, data, headers, callback);
  },
  getTemplateDetail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_TEMPLATE_DETAIL, data, headers, callback);
  },
  getRequestList(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_REQUEST_LIST, data, headers, callback);
  },
  getRequestDetail(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_REQUEST_DETAIL, data, headers, callback);
  },
  createTemplate(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.CREATE_TEMPLATE, data, headers, callback);
  },
  createTemplateAuth(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.CREATE_TEMPLATE_AUTH, data, headers, callback);
  },
  editTemplateStatus(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_TEMPLATE_STATUS, data, headers, callback);
  },
  getVerticalOptions(idOrTime, data = false, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_VERTICAL_OPTIONS, data ? data : {}, headers, callback);
  },
  editProfile(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.EDIT_PROFILE, data, headers, callback);
  },

  addChannel(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.ADD_CHANNEL, data, headers, callback);
  },

  getRequestStatus(idOrTime, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_REQUEST_STATUS, {}, headers, callback);
  },

  uploadPhoto(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
      'Content-Type': 'multipart/form-data',
    };
    
    BaseService.doPostAccessTokenNoWebWorker(API.UPLOAD_PHOTO, data, headers, callback);
  },

  deletePhoto(idOrTime, data, callback) {
    let headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };
    BaseService.doPostAccessToken(API.DELETE_PHOTO, data, headers, callback);
  },

  getChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHANNEL_LINK, data, headers, callback);
  },
  setChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHANNEL_LINK, data, headers, callback);
  },
  deleteChannelLink(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.DELETE_CHANNEL_LINK, data, headers, callback);
  },
  getChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_CHAT_BOT, data, headers, callback);
  },
  setChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CHAT_BOT, data, headers, callback);
  },
  removeChatBot(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.REMOVE_CHAT_BOT, data, headers, callback);
  },
  getPhoneNumber(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_PHONE_NUMBER, data, headers, callback);
  },
  getBusinessProfile(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.GET_BUSINESS_PROFILE, data, headers, callback);
  },
  getWelcomeMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_WELCOME, data, headers, callback);
  },
  getAwayMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_AWAY, data, headers, callback);
  },
  getClosingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_CLOSING, data, headers, callback);
  },
  getRatingMessage(idOrTime, id, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    const data = {
      id: parseInt(id)
    };

    BaseService.doPostAccessToken(API.GET_USER_RATING, data, headers, callback);
  },
  setWelcomeMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_WELCOME, data, headers, callback);
  },
  setAwayMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_AWAY, data, headers, callback);
  },
  setClosingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_CLOSING, data, headers, callback);
  },
  setRatingMessage(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
    };

    BaseService.doPostAccessToken(API.SET_USER_RATING, data, headers, callback);
  },
  uploadFile(idOrTime, data, callback) {
    const headers = {
      'API-Key': API_KEY(),
      'Device-Platform': 'web',
      'Organization-ID': idOrTime,
      'Content-Type': 'multipart/form-data',
    };

    BaseService.doPostAccessTokenNoWebWorker(API.UPLOAD_FILE, data, headers, callback);
  }
}

const API = {
  GET_LIST: '/organization/channel/whatsappba/get_list',
  GET_DETAIL: '/organization/channel/whatsappba/get_details',
  EDIT_ABOUT: '/organization/channel/whatsappba/edit_about',
  EDIT_CHANNEL: '/organization/channel/whatsappba/edit_channel',
  EDIT_PROFILE: '/organization/channel/whatsappba/edit_business_profile',
  GET_OPTION: '/organization/channel/whatsappba/template/get_options',
  GET_VERTICAL_OPTIONS: '/organization/channel/whatsappba/get_vertical_options',
  GET_TEMPLATE_LIST: '/organization/channel/whatsappba/template/get_list',
  GET_TEMPLATE_DETAIL: '/organization/channel/whatsappba/template/get_details',
  GET_REQUEST_LIST: '/organization/channel/whatsappba/template/get_request_list',
  GET_REQUEST_DETAIL: '/organization/channel/whatsappba/template/get_request_details',
  CREATE_TEMPLATE: '/organization/channel/whatsappba/template/create_request',
  CREATE_TEMPLATE_AUTH: '/organization/channel/whatsappba/template/create_request_authentication',
  EDIT_TEMPLATE_STATUS: '/organization/channel/whatsappba/template/set_enabled',
  ADD_CHANNEL: '/organization/channel/whatsappba/submit_request',
  GET_REQUEST_STATUS: '/organization/channel/whatsappba/get_request_status',
  UPLOAD_PHOTO: '/organization/channel/whatsappba/upload_photo',
  DELETE_PHOTO: '/organization/channel/whatsappba/delete_photo',
  GET_CHANNEL_LINK: '/organization/channel/whatsappba/get_channel_link',
  SET_CHANNEL_LINK: '/organization/channel/whatsappba/set_channel_link',
  DELETE_CHANNEL_LINK: '/organization/channel/whatsappba/delete_channel_link',
  GET_CHAT_BOT: '/organization/channel/whatsappba/get_chatbot',
  SET_CHAT_BOT: '/organization/channel/whatsappba/set_chatbot',
  REMOVE_CHAT_BOT: '/organization/channel/whatsappba/remove_chatbot',
  GET_PHONE_NUMBER: '/organization/channel/whatsappba/get_phone_numbers',
  GET_BUSINESS_PROFILE: '/organization/channel/whatsappba/get_business_profile',
  GET_WELCOME: "/organization/channel/whatsappba/get_welcome_message",
  SET_WELCOME: "/organization/channel/whatsappba/set_welcome_message",
  GET_AWAY: "/organization/channel/whatsappba/get_away_message",
  SET_AWAY: "/organization/channel/whatsappba/set_away_message",
  GET_CLOSING: "/organization/channel/whatsappba/get_closing_message",
  SET_CLOSING: "/organization/channel/whatsappba/set_closing_message",
  GET_USER_RATING: "/organization/channel/whatsappba/get_user_rating",
  SET_USER_RATING: "/organization/channel/whatsappba/set_user_rating",
  UPLOAD_FILE: "/organization/channel/whatsappba/template/upload_media"
};

export default IntegrationWhatsappBusinessServices;