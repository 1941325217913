import React from 'react';
import './ChatRoomMessageOutBroadcast.scss';
import CheckMarkDoubleWhite from '../../../../assets/img/chatroom/icon-double-check-white.svg';
import CheckMarkDoubleDark from '../../../../assets/img/chatroom/icon-double-check-dark.svg';
import CheckMarkDark from '../../../../assets/img/chatroom/icon-check-dark.svg';
import AirplaneDark from '../../../../assets/img/chatroom/icon-airplane-dark.svg';
import BroadcastGrey from '../../../../assets/img/sidemenu-icon/new/sub/broadcast-message-grey.svg';
// import { FaReply } from 'react-icons/fa';
// import { FiMoreHorizontal } from 'react-icons/fi';
import { FiCopy } from 'react-icons/fi';
import { FaReply } from 'react-icons/fa';
// import { FiTrash2 } from 'react-icons/fi';
import Helper from '../../../../helper/HelperChat';
// import MessageDelete from "../../../../assets/img/icon-notallowed.svg";
import MessageDeleteWhite from "../../../../assets/img/icon-notallowed-white.svg";
// import { taptalk } from '@taptalk.io/web-sdk';
// import { MdInsertDriveFile } from 'react-icons/md';

var ChatRoomMessageOut = (props) => {
  let messageActionView = (message) => {
    let onClickReply = () => {
        props.onReplyMessage(message)
    }

    return (
        <div 
            className={`message-action-wrapper`}
        >  
              <div className="message-action-button-wrapper" title="Copy to clipboard" onClick={() => Helper.copyToClipBoard(message.body)}>
                  <FiCopy />
              </div>

              <div className="message-action-button-wrapper reply-button" title="Reply"  onClick={() => onClickReply()}>
                  <FaReply />
              </div>
         </div>
    )
  }

  return (
    <div className="chat-room-message-out-broadcast-wrapper">
        {props.singleChatDataProps.isDeleted ?
            <div className="message-out-bubble-broadcast deleted-bubble">
                <React.Fragment>
                    <img src={MessageDeleteWhite} alt="" className="deleted-icon" />
                    This message was deleted.
                </React.Fragment>
            </div>
            :

            <div className="message-out-bubble-broadcast">
                {/* {props.singleChatDataProps.forwardFrom.localID !== "" &&
                    <div className="forwarded-message">
                        <b>Forwarded</b>
                        <br />
                        From: <b>{props.singleChatDataProps.forwardFrom.fullname}</b>
                    </div>
                } */}
                <div className="message-title">
                    <img src={BroadcastGrey} alt="" />
                    <b>Broadcasted Message</b>
                </div>

                <span className="message-body" dangerouslySetInnerHTML={ {__html: Helper.lineBreakChatRoom(props.singleChatDataProps.body)} }></span>
                <p className="message-info">
                    {Helper.getDateMonthYear(props.singleChatDataProps.created)} <span className="centered-dot" /> {Helper.getHourMinute(props.singleChatDataProps.created)}
                
                    {(props.singleChatDataProps.isSending && !props.singleChatDataProps.isRead) &&
                        <img src={AirplaneDark} alt="" />
                    }

                    {(!props.singleChatDataProps.isSending && !props.singleChatDataProps.isDelivered && !props.singleChatDataProps.isRead) &&
                        <img src={CheckMarkDark} alt="" />
                    }

                    {(!props.singleChatDataProps.isSending && props.singleChatDataProps.isDelivered && !props.singleChatDataProps.isRead) &&
                        <img src={CheckMarkDoubleDark} alt="" />
                    }

                    {props.singleChatDataProps.isRead &&
                        <img src={CheckMarkDoubleWhite} alt="" />
                    }

                    {/* {!props.singleChatDataProps.isDelivered &&
                        <img src={CheckMarkDark} alt="" />
                    } */}
    
                </p>
                {messageActionView(props.singleChatDataProps)}
            </div>
        }
    </div>
  );
}

export default ChatRoomMessageOut;
