import React, { useState, useEffect } from 'react';
import './IntegrationWhatsappBusinessEditChannelButton.scss';
import { FiInfo } from 'react-icons/fi';
import IntegrationWhatsappBusinessServices from '../../../../services/integration/IntegrationWhatsappBusinessServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import { checkID, doToast, excludeEmojiInput, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperInput from '../../../../helper/HelperInput';
import mixpanel from "mixpanel-browser";

const REG_NUMERIC = /^[0-9]*$/;

const IntegrationWhatsappBusinessEditChannelButton = (props) => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  const [isLoadingEditChannelLink, setIsLoadingEditChannelLink] = useState(false);
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  const [data, setData] = useState({
    targetID: "",
    message: "",
    sendPageURL: false,
    isEnabled: false,
    title: ""
  })
  const [dataOrigin, setDataOrigin] = useState({
    targetID: "",
    message: "",
    sendPageURL: false,
    isEnabled: false,
    title: ""
  })
  const [errorMessageData, setErrorMessageData] = useState({
    title: "",
    targetID: "",
    message: ""
  })

  useEffect(() => {
    doMount();
  }, []);

  const doMount = () => {
    const paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getWhatsappChannelLink(paramID, match.params.id);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  const getWhatsappChannelLink = (orgID, id) => {
    setIsLoadingGetDetailData(true);

    IntegrationWhatsappBusinessServices.getChannelLink(orgID, {id: parseInt(id)}, (response) => {
      let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        setData(response.dataResult.data.channelLink);
        setDataOrigin(response.dataResult.data.channelLink);
      } else {
        if(response.dataResult.error.code !== "40401") {
          _retriveDataError.message = response.dataResult.error.message;
          _retriveDataError.code = response.dataResult.error.code;
          setRetrieveDataError(_retriveDataError);
        }
      }

      setIsLoadingGetDetailData(false);
    });
  };

  const editWhatsappChannelLink = () => {
    let __data = {...data};
    let _errorMessageData = {...errorMessageData};
    let errorCount = 0;
    let errorClass = "";

    if(__data.title === "") {
      _errorMessageData.title = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    if(__data.title.length > 25) {
      _errorMessageData.title = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-title" }
    }

    if(__data.targetID === "") {
      _errorMessageData.targetID = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-phone" }
    }

    if(__data.targetID.replaceAll(" ", "").length > 15) {
      _errorMessageData.targetID = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-phone" }
    }

    if(__data.message === "") {
      _errorMessageData.message = "This field is required";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-message" }
    }

    if(__data.message.length > 1000) {
      _errorMessageData.message = "Characters exceed limit";
      errorCount = errorCount + 1;
      if(errorClass === "") { errorClass = "input-content" }
    }

    if(errorCount > 0) {
      setErrorMessageData(_errorMessageData);
      scrollToClass(`.${errorClass}`);
    }else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      setIsLoadingEditChannelLink(true);

      let _data = {
        channelID: parseInt(match.params.accountID),
        isEnabled: data.isEnabled,
        targetID: data.targetID.replaceAll(" ", ""),
        message: data.message,
        sendPageURL: data.sendPageURL,
        title: data.title
      }

      IntegrationWhatsappBusinessServices.setChannelLink(orgID, _data, (response) => {
          if (response.dataResult.status === 200) {
            if (!response.dataResult.data.success) {
              doToast(response.dataResult.data.message);
              setIsLoadingEditChannelLink(false);
            } else {
              doToast(response.dataResult.data.message);

              setTimeout(() => {
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                  mixpanel.track(
                    "[Action] Set Channel Link",
                    {
                      channel : "Whatsapp Business API",
                      userFullName : myAgentData.account.fullName,
                      userID : myAgentData.account.id,
                      organizationID : orgID.split("-")[0]
                    }
                  );
                }
                
                setDataOrigin(data);
                setTimeout(() => {
                  history.push(
                    `/o/${orgID}/integration/whatsapp-business-api/detail/${match.params.id}`,
                    {
                      state: 'edit-channel-link',
                      message: response.dataResult.data.message,
                    }
                  );
                }, 0);

                setIsLoadingEditChannelLink(false);
              }, 200)
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setIsLoadingEditChannelLink(false);
          }
        }
      );
    }
  };

  // const isFormValid = () => {
  //   let isDisabled = false;

  //   if(data.targetID === "") {
  //     isDisabled = true;
  //   }

  //   if(JSON.stringify(data) === JSON.stringify(dataOrigin)) {
  //     isDisabled = true;
  //   }

  //   return isDisabled;
  // };

  let back = (id) => history.push(`/o/${orgID}/integration/whatsapp-business-api/detail/${id}`);

  let onChangeToggle = (e) => {
    let _data = {...data};
    _data.isEnabled = e.target.checked;
    setData(_data)
  }

  let onChange = (e) => {
    let _data = {...data};
    let _errorMessageData = {...errorMessageData};

    if(e.target.id === "message" && e.target.value === "") {
      _data.sendPageURL = false;
    }
    
    if(e.target.id === "targetID") {
      if(REG_NUMERIC.test(e.target.value.replaceAll(" ", ""))) {
        _data[e.target.id] = e.target.value;
        _errorMessageData[e.target.id] = "";
        setData(_data);
      }
    }else {
      _data[e.target.id] = excludeEmojiInput(e.target.value);
      _errorMessageData[e.target.id] = "";
      setData(_data);
    }

    setErrorMessageData(_errorMessageData)
  }

  let onChangeCheck = (e) => {
    let _data = {...data};
    _data.sendPageURL = e.target.checked;
    setData(_data)
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID} 
        withHeader 
        withSideMenu
        dataOrigin={dataOrigin}
        dataAfterChange={data}
      >
        <div className="sectionWrap integration-main-container integration-whatsapp-ba-edit-topic-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading 
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message} 
              />
            </div>
          ) : (
            <>
              <BackButton
                text={`Back to Whatsapp Account Detail`}
                onClick={() => back(match.params.id)}
              />

              <div className="integration-whatsapp-edit-topic-account-box">
                <div className="integration-whatsapp-edit-topic-account-box-top">
                  <b>Edit Channel Button for Live Chat</b>

                  {!isLoadingEditChannelLink ? (
                    <ButtonWithLoadingOrIcon 
                        // isDisabled={isFormValid()}
                        onClickAction={editWhatsappChannelLink}
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon 
                        isLoading
                        loadingColor="gray"
                        isDisabled
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                    />
                  )}
                </div>
                <div className="integration-whatsapp-edit-topic-account-box-form">
                  <div className="form-topic">  
                    <div className="switch-channel-button">
                      <label>
                        <b>Enable Channel Button for Live Chat</b>
                      </label>
                      <span className="integration-info-form">
                        Your social media channel will be shown in live chat widget.
                      </span>

                      <label className="custom-switch-wrapper">
                          <input disabled={isLoadingEditChannelLink} type="checkbox" checked={data.isEnabled} onChange={(e) => onChangeToggle(e)} />
                          <span className="custom-switch round"></span>
                      </label>
                    </div>

                    <div className="form-content-wrapper">
                      <label>
                        <b>Title</b>

                        <span className={`grey-font ${errorMessageData.title === "Characters exceed limit" ? "red-text" : ""}`}>{data.title.length}/25</span>
                      </label>
                      <input className={errorMessageData.title !== "" ? "border-red input-title" : "input-title"} disabled={isLoadingEditChannelLink} type="text" placeholder="Enter your channel button title" id="title" value={data.title} onChange={(e) => onChange(e)}/>
                      {errorMessageData.title !== "" &&
                        <ErrorOrInfoComp
                          text={errorMessageData.title}
                          _className={"font-red"}
                          icon={<FiInfo />}
                        />
                      }

                      <label>
                        <b>Phone Number</b>

                        <span className={`grey-font ${errorMessageData.targetID === "Characters exceed limit" ? "red-text" : ""}`}>{data.targetID.replaceAll(" ", "").length}/15</span>
                      </label>
                      <div className="phone-input">
                        <span>+</span>
                        <input className={`input-phone ${errorMessageData.targetID === "" ? "" : "border-red"}`} disabled={isLoadingEditChannelLink} type="text" placeholder="62 XXXX XXX XXX" id="targetID" value={HelperInput.phoneBeautify(data.targetID.replaceAll(" ", ""))} onChange={(e) => onChange(e)} />
                        {/* <div className="alert-wrapper">
                          <FiInfo />
                          <p>Always start the phone number with the country code without the “+” sign e.g. “628XXXXXXXXX”.</p>
                        </div> */}

                        <ErrorOrInfoComp
                          text={errorMessageData.targetID !== "" ? errorMessageData.targetID : "Always start the phone number with the country code without the “+” sign e.g. “628XXXXXXXXX”."}
                          _className={errorMessageData.targetID !== "" ? "font-red" : ""}
                          icon={<FiInfo />}
                        />
                      </div>

                      <label>
                        <b>First Message</b>

                        <span className={`grey-font ${errorMessageData.message === "Characters exceed limit" ? "red-text" : ""}`}>{data.message.length}/1000</span>
                      </label>
                      <textarea placeholder="Enter your first message" rows="6" id="message" disabled={isLoadingEditChannelLink} value={data.message} className={errorMessageData.message !== "" ? "border-red input-message" : "input-message"} onChange={(e) => onChange(e)} />
                      {errorMessageData.message !== "" &&
                        <ErrorOrInfoComp
                          text={errorMessageData.message}
                          _className={"font-red margin-top-0 margin-bottom-8"}
                          icon={<FiInfo />}
                        />
                      }

                      <div className="custom-checkbox">
                          <input type="checkbox" id="include-current" checked={data.sendPageURL} disabled={data.message === "" || isLoadingEditChannelLink} onChange={(e) => onChangeCheck(e)} />
                          <label htmlFor="include-current">
                            Include current page URL in message <a href="https://docs.taptalk.io/onetalk-omnichannel-documentation/social-channel-button" className="link-orange" target="_blank" rel="noopener noreferrer">(learn more)</a>
                          </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationWhatsappBusinessEditChannelButton;
