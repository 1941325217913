import React, { useEffect, useState, useRef } from "react";
import "./NeedReply.scss";
import { FiX } from 'react-icons/fi';
import { doToast, checkID } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import { NEED_REPLY_OPT } from "../../../../helper/HelperConst";
import HelperInput from "../../../../helper/HelperInput";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import CustomSelectOption from "../../../reuseableComponent/customSelectOption/CustomSelectOption";
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupConfirmation from "../../../reuseableComponent/popupConfirmation/PopupConfirmation";
import InboxConfigServices from "../../../../services/newServices/InboxConfigServices";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import mixpanel from "mixpanel-browser";
import { SketchPicker } from 'react-color';

const NeedReply = (props) => {
    let [val, setVal] = useState(false);
    let [valOrigin, setValOrigin] = useState(false);
    let [isWaitingFetch, setIsWaitingFetch] = useState(true);
    let [isWaitingSet, setIsWaitingSet] = useState(false);
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });
    let [orgID, setOrgID] = React.useState(false);

    let [showColorPicker1, setShowColorPicker1] = useState(false);
    let [showColorPicker2, setShowColorPicker2] = useState(false);
    let [showColorPicker3, setShowColorPicker3] = useState(false);
    let colorPickerRef1 = useRef(null);
    let colorPickerRef2 = useRef(null);
    let colorPickerRef3 = useRef(null);
    let [showPopupConfirmation, setShowPopupConfirmation] = useState(false);

    let back = () => {
        props.history.push(`/o/${props.match.params.orgID}/setup/inbox-settings`);
    }

    const TIME_OPT = [
        {
            label: "minutes",
            value: "minutes"
        },
        {
            label: "hours",
            value: "hours"
        }
    ];

    useEffect(() => {
        const onBodyClick = (event) => {
          if (showColorPicker1) {
            if (
                !colorPickerRef1.current.contains(event.target) && 
                !colorPickerRef1.current.previousSibling.contains(event.target)
            ) {
              setShowColorPicker1(false);
            }
          }

          if (showColorPicker2) {
            if (
                !colorPickerRef2.current.contains(event.target) && 
                !colorPickerRef2.current.previousSibling.contains(event.target)
            ) {
              setShowColorPicker2(false);
            }
          }

          if (showColorPicker3) {
            if (
                !colorPickerRef3.current.contains(event.target) && 
                !colorPickerRef3.current.previousSibling.contains(event.target)
            ) {
              setShowColorPicker3(false);
            }
          }
        };
    
        document.body.addEventListener('click', onBodyClick);
    
        return () => {
          document.body.removeEventListener('click', onBodyClick);
        };
      }, [showColorPicker1, showColorPicker2, showColorPicker3]);

    useEffect(() => {
        doMount();
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Inbox Setting",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    useStateorganizationID : props.match.params.orgID.split("-")[0]
                }
            );
        }
    }, [])

    useEffect(() => {
        if(orgID) {
            getData();
        }
    }, [orgID])

    const doMount = async () => {
        const paramID = checkID(props.match);

        if (paramID) {
            setOrgID(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if (response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    props.history.push(`/o/${urlOrgID}/setup/inbox-settings`);
                } else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    let getData = () => {
        let _retriveDataError = {...retriveDataError};
        setIsWaitingFetch(true);

        InboxConfigServices.getNeedReplyConfig(orgID, (response) => {
            let dataResult = response.dataResult;

            if(dataResult.error.message === "") {
                let _data = {...dataResult.data};

                if(_data.needReplyIndicator.firstHexColor === "") {
                    _data.needReplyIndicator.firstHexColor = "ff3f57";
                }

                _data.needReplyIndicator.firstHexColor = _data.needReplyIndicator.firstHexColor.replace("#", "");
                _data.needReplyIndicator.dropdownVal = NEED_REPLY_OPT[_data.needReplyIndicator.isCustom ? 1 : 0];
                
                if(_data.needReplyIndicator.levels) {
                    _data.needReplyIndicator.levels[0].hexColor = _data.needReplyIndicator.levels[0].hexColor.replace("#", "");
                    _data.needReplyIndicator.levels[1].hexColor = _data.needReplyIndicator.levels[1].hexColor.replace("#", "");
                }else {
                    _data.needReplyIndicator.levels = [
                        {
                            afterMinutes: 0,
                            afterSeconds: 0,
                            afterType: "minutes",
                            afterValue: "",
                            hexColor: "ff3f57",
                            sequence: 1
                        },
                        {
                            afterMinutes: 0,
                            afterSeconds: 0,
                            afterType: "minutes",
                            afterValue: "",
                            hexColor: "ff3f57",
                            sequence: 2
                        }
                    ];
                }

                if(_data.needReplyIndicator.levels[0].afterType === "minutes") {
                    _data.needReplyIndicator.levels[0].dropdownVal = TIME_OPT[0];
                }

                if(_data.needReplyIndicator.levels[1].afterType === "minutes") {
                    _data.needReplyIndicator.levels[1].dropdownVal = TIME_OPT[0];
                }

                if(_data.needReplyIndicator.levels[0].afterType === "hours") {
                    _data.needReplyIndicator.levels[0].dropdownVal = TIME_OPT[1];
                }

                if(_data.needReplyIndicator.levels[1].afterType === "hours") {
                    _data.needReplyIndicator.levels[1].dropdownVal = TIME_OPT[1];
                }

                setValOrigin(JSON.parse(JSON.stringify(dataResult.data)));
                setVal(JSON.parse(JSON.stringify(dataResult.data)));
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitingFetch(false);
        })
    }

    let setSetting = () => {
        if(retriveDataError.code !== "49901") {
            setIsWaitingSet(true);
            
            let data = JSON.parse(JSON.stringify(val));

            data.needReplyIndicator.firstHexColor = "#"+data.needReplyIndicator.firstHexColor;
            
            if(data.needReplyIndicator.levels) {
                data.needReplyIndicator.levels[0].hexColor = "#"+data.needReplyIndicator.levels[0].hexColor;
                data.needReplyIndicator.levels[1].hexColor = "#"+data.needReplyIndicator.levels[1].hexColor;
            }

            InboxConfigServices.setNeedReplyConfig(orgID, data.needReplyIndicator, (response) => {
                let dataResult = response.dataResult;

                if(dataResult.error.message === "") {
                    setValOrigin(val);

                    doToast("Need Reply Indicator updated");
                    setTimeout(() => {
                        back();
                    }, 2000)
                }else {
                    doToast(dataResult.error.message, "fail");
                    setIsWaitingSet(false);
                }
            })
        }
    }

    let onSelectConfig = (_val) => {
        let _inboxConfig = {...val};
        _inboxConfig.needReplyIndicator.dropdownVal = _val;
        _inboxConfig.needReplyIndicator.isCustom = _val.value === NEED_REPLY_OPT[1].value;
        setVal(_inboxConfig);
    }

    // let isDisabled = () => {
    //     let dis = false;
    //     let _val = val.needReplyIndicator;

    //     if(JSON.stringify(valOrigin) === JSON.stringify(val)) {
    //         dis = true;
    //     }

    //     if(_val && _val.isCustom) {
    //         if(_val.firstHexColor === "") {
    //             dis = true;
    //         }
    
    //         if(_val.levels[0].hexColor === "" || _val.levels[1].hexColor === "") {
    //             dis = true;
    //         }

    //         if(_val.levels[0].afterValue === "" || _val.levels[0].afterValue === "") {
    //             dis = true;
    //         }
    //     }

    //     return dis;
    // }

    // let changeColorPicker = (color) => {
        // let data = { ...autoMessageVal };
        // data.themeColor = color.hex.substring(1, 7);
        // // _addData.rgb = hexToRgb(color.hex);
        // // toggleColorPicker();
        // setAutoMessageVal(data);
    // };

    let toggleSketchPicker = (i) => {
        if(i === 1) {
            setShowColorPicker1(!showColorPicker1);
        }

        if(i === 2) {
            setShowColorPicker2(!showColorPicker2);
        }

        if(i === 3) {
            setShowColorPicker3(!showColorPicker3);
        }
    }
    
    let onSelectTime = (i, e) => {
        let _val = {...val};

        _val.needReplyIndicator.levels[i].dropdownVal = e;
        _val.needReplyIndicator.levels[i].afterType = e.value;
        setVal(_val);
    }

    let onChangeAfterValue = (i, e) => {
        let _val = {...val};

        if(HelperInput.numberOnly(e.target.value)) {
            _val.needReplyIndicator.levels[i].afterValue = e.target.value === "" ? "" : parseInt(e.target.value);
            setVal(_val);
        }
    }

    let changeColorPickerFirst = (e) => {
        let _val = {...val};

        _val.needReplyIndicator.firstHexColor = e.hex.replace("#", "");
        setVal(_val);
    }

    let changeColorPickerLevel = (i, e) => {
        let _val = {...val};

        _val.needReplyIndicator.levels[i].hexColor = e.hex.replace("#", "");
        setVal(_val);
    }

    let onChangeFirstColor = (e) => {
        let _val = {...val};

        if(HelperInput.alphanumericOnly(e.target.value)) {
            _val.needReplyIndicator.firstHexColor = e.target.value;
        }

        setVal(_val);
    }

    let onChangeLevelsColor = (i, e) => {
        let _val = {...val};

        if(HelperInput.alphanumericOnly(e.target.value)) {
            _val.needReplyIndicator.levels[i].hexColor = e.target.value;
        }

        setVal(_val);
    }

    let togglePopupConfirmation = () => {
        setShowPopupConfirmation(!showPopupConfirmation);
    }

    return (
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={valOrigin}
            dataAfterChange={val}
            discardDesc="You have unsaved changes on Need Reply Indicator setup. Are you sure you want to discard your progress?"
        >
            <BackButton 
                onClick={back}
                text="Back To Inbox Settings"
            />

            <PopupConfirmation
                isOpen={showPopupConfirmation}
                toggle={togglePopupConfirmation}
                title="Save Changes?"
                description="You have changes in your Need Reply Indicator setup, are you sure you want to continue?"
                className="modal-confirmation-need-reply"
                >
                {!isWaitingSet ?
                    <ButtonWithLoadingOrIcon
                        text="Save Changes"
                        className="main-button-48 orange-button"
                        onClickAction={setSetting}
                    />
                    :
                    <ButtonWithLoadingOrIcon
                        text="Save Changes"
                        className="main-button-48 orange-button"
                        isLoading
                        isDisabled
                        loadingColor="gray"
                        position="left"
                    />
                }
            </PopupConfirmation>

            <div className="inbox-settings sectionWrap">
                {/* tier blocking */}
                {/* {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                        isLoading={isWaitingFetch}
                        errorMessage={retriveDataError.message}
                        errorCode={retriveDataError.code}
                    />
                } */}
                {/* tier blocking */}

                <React.Fragment>
                    <div className="needreply-settings-header">
                        <b>Need Reply Indicator</b>

                        {/* {isWaitingSet ? */}
                            {/* <ButtonWithLoadingOrIcon 
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                isDisabled={true}
                                isLoading
                                loadingColor={"gray"}
                                position="left"
                            /> */}
                            {/* : */}
                            <ButtonWithLoadingOrIcon 
                                text="Save Changes"
                                className="main-button-40 orange-button"
                                onClickAction={togglePopupConfirmation}
                                // isDisabled={isDisabled()}
                            />
                        {/* } */}
                    </div>
                    
                    {(isWaitingFetch || (retriveDataError.code && retriveDataError.code !== "49901")) ? 
                        <RetrieveDataLoading 
                            isLoading={isWaitingFetch}
                            errorMessage={retriveDataError.message} 
                        />
                        :
                        <>
                            <div className="needreply-settings-form">
                                <div className="needreply-settings-form-content form-content-wrapper">
                                    <b>Need Reply Indicator</b>
                                    <p>
                                        Change the color of Need Reply Indicator based on customer’s waiting for reply duration.
                                    </p>
                                    
                                    <CustomSelectOption 
                                        optionListProps={NEED_REPLY_OPT}
                                        valueDropdownProps={val.needReplyIndicator.dropdownVal}
                                        placeholderProps={'Select Config'}
                                        onClickDropDownListOptionProps={(e) => onSelectConfig(e)}
                                        specificClassNameProps="custom-select-need-reply-config"
                                        isBold
                                    />
                                </div>
                            </div>
                            
                            {val.needReplyIndicator.isCustom &&
                                <div className="need-reply-color-config-wrapper">
                                    <label className="need-reply-color-config-label">
                                        <b>First Color</b>
                                    </label>

                                    <br />

                                    <div className="need-reply-color-picker-wrapper" onClick={() => toggleSketchPicker(1)}>
                                        #
                                        <input 
                                            className="input-color" 
                                            type="text" 
                                            value={val.needReplyIndicator.firstHexColor} 
                                            maxLength={6}
                                            onChange={(e) => onChangeFirstColor(e)}
                                        />
                                        
                                        <div 
                                            className="need-reply-badge" 
                                            style={{background: "#"+val.needReplyIndicator.firstHexColor}}
                                        >
                                            Need Reply
                                        </div>

                                        {showColorPicker1 &&
                                            <div className="color-picker-container" ref={colorPickerRef1}>
                                                <FiX
                                                    onClick={() => toggleSketchPicker(1)}
                                                    className="close-color-picker"
                                                />

                                                <SketchPicker
                                                    color={val.needReplyIndicator.firstHexColor}
                                                    onChange={(e) => changeColorPickerFirst(e)}
                                                />
                                            </div>
                                        }
                                    </div>


                                    <br />

                                    <label className="need-reply-color-config-label">
                                        <b>Change Color</b>
                                    </label>

                                    <p className="need-reply-color-picker-desc"> 
                                        Need Reply Indicator will change color to
                                    </p>
                                    
                                    <div className="change-color-wrapper">
                                        <div className="need-reply-color-picker-wrapper" onClick={() => toggleSketchPicker(2)}>
                                            #
                                            <input 
                                                className="input-color" 
                                                type="text" 
                                                value={val.needReplyIndicator.levels[0].hexColor} 
                                                maxLength={6}
                                                onChange={(e) => onChangeLevelsColor(0, e)}
                                            />

                                            <div 
                                                className="need-reply-badge" 
                                                style={{background: "#"+val.needReplyIndicator.levels[0].hexColor}}
                                            >
                                                Need Reply
                                            </div>

                                            {showColorPicker2 &&
                                                <div className="color-picker-container" ref={colorPickerRef2}>
                                                    <FiX
                                                        onClick={() => toggleSketchPicker(2)}
                                                        className="close-color-picker"
                                                    />

                                                    <SketchPicker
                                                        color={val.needReplyIndicator.levels[0].hexColor}
                                                        onChange={(e) => changeColorPickerLevel(0, e)}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <p className="title-after">
                                            after
                                        </p>
                                        
                                        <input 
                                            type="text" 
                                            maxLength={3} 
                                            value={val.needReplyIndicator.levels[0].afterValue}
                                            onChange={(e) => onChangeAfterValue(0, e)} 
                                        />

                                        <CustomSelectOption 
                                            optionListProps={TIME_OPT}
                                            valueDropdownProps={val.needReplyIndicator.levels[0].dropdownVal}
                                            placeholderProps={'Select Time'}
                                            onClickDropDownListOptionProps={(e) => onSelectTime(0, e)}
                                            specificClassNameProps="custom-select-time-config"
                                        />
                                    </div>

                                    <div className="change-color-wrapper">
                                        <div className="need-reply-color-picker-wrapper" onClick={() => toggleSketchPicker(3)}>
                                            #
                                            <input 
                                                className="input-color" 
                                                type="text" 
                                                value={val.needReplyIndicator.levels[1].hexColor} 
                                                maxLength={6}
                                                onChange={(e) => onChangeLevelsColor(1, e)}
                                            />

                                            <div 
                                                className="need-reply-badge" 
                                                style={{background: "#"+val.needReplyIndicator.levels[1].hexColor}}
                                            >
                                                Need Reply
                                            </div>


                                            {showColorPicker3 &&
                                                <div className="color-picker-container" ref={colorPickerRef3}>
                                                    <FiX
                                                        onClick={() => toggleSketchPicker(3)}
                                                        className="close-color-picker"
                                                    />

                                                    <SketchPicker
                                                        color={val.needReplyIndicator.levels[1].hexColor}
                                                        onChange={(e) => changeColorPickerLevel(1, e)}
                                                    />
                                                </div>
                                            }
                                        </div>

                                        <p className="title-after">
                                            after
                                        </p>

                                        <input 
                                            type="text" 
                                            maxLength={3} 
                                            value={val.needReplyIndicator.levels[1].afterValue}
                                            onChange={(e) => onChangeAfterValue(1, e)} 
                                        />

                                        <CustomSelectOption 
                                            optionListProps={TIME_OPT}
                                            valueDropdownProps={val.needReplyIndicator.levels[1].dropdownVal}
                                            placeholderProps={'Select Time'}
                                            onClickDropDownListOptionProps={(e) => onSelectTime(1, e)}
                                            specificClassNameProps="custom-select-time-config"
                                        />
                                    </div>

                                    <br />
                                </div>
                            }
                        </>

                    }
                </React.Fragment>
            </div>
        </SectionWrap>
    )
}

export default NeedReply;