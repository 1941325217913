import React, { useState, useEffect } from 'react';
import './EditContact.scss'
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from '../../reuseableComponent/BackButton/BackButton';
import { FiEdit, FiInfo, FiAlertCircle } from 'react-icons/fi'
import { checkID, doToast, scrollToClass } from '../../../helper/HelperGeneral';
import HelperInput from '../../../helper/HelperInput';
import UserContactServices from '../../../services/newServices/UserContactServices'
import ServiceOrganization from '../../../services/organization/ServiceOrganization'
import ButtonWithLoadingOrIcon from '../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon'
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp"

let EditContact = (props) => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [phoneFocus, setPhoneFocus] = useState(false);
  let [errorEmail, setErrorEmail] = useState(false);
  let [errorPhoneNumber, setErrorPhoneNumber] = useState(false)
  let [errorEmailPhone, setErrorEmailPhone] = useState(false)
  let [isLoadingEditContact, setIsLoadingEditContact] = useState(false)
  // let [isButtonDisabled, setIsButtonDisabled] = useState(true);
  let [fullname, setFullname] = useState('')
  let [alias, setAlias] = useState('')
  let [email, setEmail] = useState('')
  let [company, setCompany] = useState('')
  let [jobTitle, setJobTitle] = useState('')
  let [phoneNumber, setPhoneNumber] = useState('')
  let [fullnameOrigin, setFullnameOrigin] = useState('')
  let [phoneNumberOrigin, setPhoneNumberOrigin] = useState('')
  let [aliasOrigin, setAliasOrigin] = useState('')
  let [emailOrigin, setEmailOrigin] = useState('')
  let [companyOrigin, setCompanyOrigin] = useState('')
  let [jobTitleOrigin, setJobTitleOrigin] = useState('')
  let [dataUserContact, setDataUserContact] = useState(null)
  let [disablePhoneNumber, setDisablePhoneNumber] = useState(false);
  let [errorMessageData, setErrorMessageData] = useState({
    fullname: "",
    alias: "",
    company: "",
    job: "",
    email: "",
    phone: ""
  }) 

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getUserContactDetail(paramID, match.params.id);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  useEffect(() => {
    doMount();
  }, []);

  let getUserContactDetail = (orgID, userID) => {

    UserContactServices.getContactDetail(orgID, userID, (response) => {
      if (response.dataResult.status === 200) {
        setDataUserContact(response.dataResult.data.user);
        setFullname(response.dataResult.data.user.fullName)
        setAlias(response.dataResult.data.user.alias)
        setEmail(response.dataResult.data.user.email)
        setCompany(response.dataResult.data.user.companyName)
        setJobTitle(response.dataResult.data.user.jobTitle)
        setPhoneNumber(response.dataResult.data.user.phone)
        setFullnameOrigin(response.dataResult.data.user.fullName)
        setAliasOrigin(response.dataResult.data.user.alias)
        setEmailOrigin(response.dataResult.data.user.email)
        setPhoneNumberOrigin(response.dataResult.data.user.phone)
        setCompanyOrigin(response.dataResult.data.user.companyName)
        setJobTitleOrigin(response.dataResult.data.user.jobTitle)
        // console.log(response.dataResult.data.user.phone)
        if (response.dataResult.data.user.phone === '') {
          setDisablePhoneNumber(false)
        } else {
          setDisablePhoneNumber(true)
        }
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }


    });
  };

  let back = () =>
    history.push({
      pathname: `/o/${orgID}/operational/user-contact/${match.params.id}`,
      state: {
        pageDisplay: location.state ? location.state.pageDisplay : 1,
        listPage: location.state ? location.state.listPage : 20,
        submitQuery: location.state ? location.state.submitQuery : ""
      }
    });

  let handlePhoneFocus = () => {
    setPhoneFocus(true);
  }

  let handleBlurPhone = () => {
    setPhoneFocus(false);
  }

  let [borderPhone, setBorderPhone] = useState('1px solid #dcdcdc')

  useEffect(() => {
    if (phoneFocus) {
      if (errorPhoneNumber || errorEmailPhone) {
        setBorderPhone('1px solid #FF3F57')
      } else {
        setBorderPhone('1px solid #ff7e00')
      }
    } else {
      setBorderPhone('1px solid #dcdcdc')
    }
  }, [phoneFocus, errorPhoneNumber, errorEmailPhone])

  let validateEmail = (e) => {
    setEmail(e.target.value)
    let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (e.target.value === '') {
      if (phoneNumber === '') {
        setErrorEmailPhone(true)
        setErrorEmail(false)
      } else { // phoneNumber !== ''
        setErrorEmailPhone(false);
        setErrorEmail(false)
      }
    } else { // e.target.value !== ''
      setErrorEmailPhone(false);
      if (re.test(String(e.target.value).toLowerCase())) {
        setErrorEmail(false)
      } else {
        setErrorEmail(true)
      }
    }
    
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.email = "";
    setErrorMessageData(_errorMessageData);
  }

  let validatePhoneNumber = (e) => {
    setPhoneNumber(e.target.value)
    let val = /^[0-9]*$/

    if (e.target.value === '' && email === '') {
      setErrorEmailPhone(true)
      setErrorPhoneNumber(false)
    } else {
      setErrorEmailPhone(false)
      if (val.test(e.target.value)) {
        setErrorPhoneNumber(false)
      } else {
        setErrorPhoneNumber(true)
      }
    }

    let _errorMessageData = {...errorMessageData};
    _errorMessageData.phone = "";
    setErrorMessageData(_errorMessageData);
  }

  let handleFullname = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.fullname = "";
    setErrorMessageData(_errorMessageData);
    setFullname(e.target.value);
  }

  let saveContact = () => {
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(fullname === "") {
        _errorMessageData.fullname = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-fullname" }
    }

    if(fullname === "") {
      _errorMessageData.fullname = "This field is required";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-fullname" }
    }

    if(fullname.length > 200) {
      _errorMessageData.fullname = "This field is required";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-fullname" }
    }

    if(alias.length > 100) {
      _errorMessageData.alias = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-alias" }
    }

    if(company.length > 200) {
      _errorMessageData.company = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-company" }
    }

    if(jobTitle.length > 50) {
      _errorMessageData.job = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-job" }
    }

    if(email.length > 250) {
      _errorMessageData.email = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-email" }
    }

    if(phoneNumber.replaceAll(" ", "").length > 15) {
      _errorMessageData.phone = "Characters exceed limit";
      errorCount = errorCount + 1;
        
      if(errorClass === "") { errorClass = "input-phone" }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      setIsLoadingEditContact(true);
      setDisablePhoneNumber(true);
      let dataUser = {
        userID: match.params.id,
        fullName: fullname,
        alias: alias,
        email: email,
        phone: phoneNumber,
        companyName: company,
        jobTitle: jobTitle
      }

      UserContactServices.editContact(orgID, dataUser, (response) => {
        setIsLoadingEditContact(false);
        if (phoneNumber === '') {
          setDisablePhoneNumber(false);
        }

        if (response.dataResult.status === 200) {
          if (!response.dataResult.data.success) {
            doToast(response.dataResult.data.message);
          } else {
            setFullnameOrigin(fullname);
            setEmailOrigin(email);
            setPhoneNumberOrigin(phoneNumber); 
            setAliasOrigin(alias);
            setJobTitleOrigin(jobTitle);
            setCompanyOrigin(company);
            setTimeout(() => {
              history.push(
                `/o/${orgID}/operational/user-contact/${response.dataResult.data.user.userID}`,
                {
                  state: 'create',
                  message: response.dataResult.data.message,
                }
              );
            }, 0);
            doToast(response.dataResult.data.message);
          }
        } else {
          doToast(response.dataResult.error.message, 'fail');
        }
      });
    }
  }

  // useEffect(() => {
  //   if (fullname !== '' && !errorEmail && !errorPhoneNumber) {
  //     setIsButtonDisabled(false)
  //   } else {
  //     setIsButtonDisabled(true)
  //   }

  // }, [fullname, errorEmail, errorPhoneNumber])

  let handleAlias = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.alias = "";
    setErrorMessageData(_errorMessageData);
    setAlias(e.target.value)
  }

  let handleCompany = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.company = "";
    setErrorMessageData(_errorMessageData);
    setCompany(e.target.value);
  }

  let handleJobTitle = (e) => {
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.job = "";
    setErrorMessageData(_errorMessageData);
    setJobTitle(e.target.value);
  }

  return (
    <SectionWrap
      {...props}
      orgID={orgID}
      withHeader
      withSideMenu
      dataOrigin={{name: fullnameOrigin, email: emailOrigin, phone: phoneNumberOrigin, alias: aliasOrigin, jobTitle: jobTitleOrigin, company: companyOrigin}}
      dataAfterChange={{name: fullname, email: email, phone: phoneNumber, alias: alias, jobTitle: jobTitle, company: company}}
    >
      <div className="sectionWrap integration-main-container add-user-contact-container text-left">
        {
          !dataUserContact ?
            <div className="integration-list-loading-wrapper">
              <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
              </div>
            </div>
            :
            <>
              <BackButton
                text={`Back to ${dataUserContact.fullName}`}
                onClick={() => back()}
              />
              <div className="add-user-contact-box">
                <div className="add-user-contact-box-top">
                  <div className="title-wrapper">
                    <FiEdit />
                    <b>Edit User</b>
                  </div>
                  {/* <p>Create a new user by inputting name and phone number.</p> */}
                </div>
                <div className="add-user-contact-box-form">
                  {
                    errorEmailPhone &&
                    <div className="alert-component alert-email-phone">
                      <FiInfo />
                      <b>
                        Either email address or phone number must be filled
                  </b>
                    </div>
                  }
                  <div className="form-contact-fullname">
                    <label className='pos-relative'>
                      <b>Full Name</b>

                      <span className={errorMessageData.fullname === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{fullname.length}/200</span>
                    </label>
                    <input
                      type="text"
                      value={fullname}
                      placeholder="Type full name here"
                      onChange={handleFullname}
                      disabled={isLoadingEditContact}
                      className={`form-fullname input-fullname ${errorMessageData.fullname !== "" ? "border-red" : ""}`}
                    />
                  </div>
                  {errorMessageData.fullname !== "" &&
                    <ErrorOrInfoComp
                        text={errorMessageData.fullname}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                  }
                  <div className="form-contact-alias">
                    <label className='pos-relative'>
                      <b>Alias<span>(Optional)</span></b>

                      <span className={errorMessageData.alias === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{alias.length}/100</span>
                    </label>
                    <input
                      type="text"
                      value={alias}
                      onChange={handleAlias}
                      placeholder="Type alias name here"
                      className={`form-alias input-alias ${errorMessageData.alias !== "" ? "border-red" : ""}`}
                      disabled={isLoadingEditContact}
                    />
                  </div>
                  {errorMessageData.alias !== "" &&
                    <ErrorOrInfoComp
                        text={errorMessageData.alias}
                        _className={"font-red"}
                        icon={<FiInfo />}
                    />
                  }

                  <div className="form-contact-alias">
                    <label className='pos-relative'>
                      <b>Company<span>(Optional)</span></b>

                      <span className={errorMessageData.company === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{company.length}/200</span>
                    </label>
                    <input
                      type="text"
                      value={company}
                      onChange={handleCompany}
                      placeholder="Type alias name here"
                      className={`form-alias input-company ${errorMessageData.company !== "" ? "border-red" : ""}`}
                      disabled={isLoadingEditContact}
                    />
                  </div>
                  <div className="form-contact-alias">
                    <label className='pos-relative'>
                      <b>Job Title<span>(Optional)</span></b>

                      <span className={errorMessageData.job === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{jobTitle.length}/50</span>
                    </label>
                    <input
                      type="text"
                      value={jobTitle}
                      onChange={handleJobTitle}
                      placeholder="Type alias name here"
                      className={`form-alias input-alias ${errorMessageData.job !== "" ? "border-red" : ""}`}
                      disabled={isLoadingEditContact}
                    />
                  </div>
                  <div className="form-contact-email">
                    <label className='pos-relative'>
                      <b>Email</b>
                      <span className={errorMessageData.email === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{email.length}/250</span>
                    </label>
                    <input
                      type="text"
                      value={email}
                      placeholder="Type email address here"
                      className={`form-email ${errorEmail ? 'error-email' : ''} input-email ${errorMessageData.email !== "" ? "border-red" : ""}`}
                      onChange={validateEmail}
                      disabled={isLoadingEditContact}
                    />
                    {
                      errorEmailPhone &&
                      <div className="warning-error-red">
                        <FiAlertCircle />
                        <p>You cannot leave this field empty if “Phone Number” is also empty</p>
                      </div>
                    }
                    {
                      errorEmail &&
                      <div className="warning-error-red">
                        <FiAlertCircle />
                        <p>Invalid email address</p>
                      </div>
                    }

                  </div>
                  <div className="form-contact-phonenumber">
                    <label className='pos-relative'>
                      <b>Phone Number</b>

                      <span className={errorMessageData.phone === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{phoneNumber.replaceAll(" ", "").length}/15</span>
                    </label>
                    <div className={`input-phonenumber-container ${disablePhoneNumber ? "grey-box" : ""} ${errorMessageData.phone !== "" ? "border-red" : ""}`} style={{ border: borderPhone }}>
                      <label>+</label>
                      <input
                        type="text"
                        value={HelperInput.phoneBeautify(phoneNumber)}
                        placeholder="628XXXXXXXXX"
                        className="form-phonenumber"
                        onFocus={handlePhoneFocus}
                        onBlur={handleBlurPhone}
                        onChange={validatePhoneNumber}
                        disabled={disablePhoneNumber}
                      />
                    </div>
                    {
                      errorEmailPhone &&
                      <div className="warning-error-red">
                        <FiAlertCircle />
                        <p>You cannot leave this field empty if “Email” is also empty</p>
                      </div>
                    }
                    {
                      errorPhoneNumber &&
                      <div className="warning-error-red">
                        <FiAlertCircle />
                        <p>Invalid Number</p>
                      </div>
                    }
                    <div className="alert-wrapper">
                      <FiInfo />
                      <p>Always start the phone number with the country code without the “+” sign e.g. “628XXXXXXXXX”.</p>
                    </div>
                  </div>
                  {
                    isLoadingEditContact ?
                      <ButtonWithLoadingOrIcon
                        isLoading
                        loadingColor="gray"
                        isDisabled
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                      />
                      :
                      <ButtonWithLoadingOrIcon
                        className="orange-button main-button-40"
                        text="Save Changes"
                        onClickAction={saveContact}
                        // isDisabled={isButtonDisabled}
                      />
                  }
                </div>
              </div>
            </>
        }
      </div>
    </SectionWrap>
  );
};

export default EditContact;