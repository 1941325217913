import React from "react";
import FormInput from "../reuseableComponent/FormInput/FormInput";
import ActionAgent from "./TDAgentAction";
import TDATableAgent from "./TDATableAgent";
import Loading from "../reuseableComponent/loading/Loading";
import { FaSearch } from "react-icons/fa";
import TDATableHead from "./TDATableHead";
import RetrieveDataLoading from "../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";

const TDATopAction = props => {
  const { agentData, setCopyAgentData } = props;

  const searchAgent = e => {
    const data = agentData;
    const hasAgent = data && data.length > 0;
    if (e.target.value && hasAgent) {
      const { value: keyword } = e.target;
      const filtered = data.filter(item => {
        const { accountName, accountEmail } = item;
        const lowKey = keyword.toLowerCase();
        const lowName = accountName.toLowerCase();
        const lowEmail = accountEmail.toLowerCase();
        return lowName.includes(lowKey) || lowEmail.includes(lowKey);
      });
      setCopyAgentData(filtered);
    } else setCopyAgentData(false);
  };

  return (
    <div className="flexContent">
      <div className="agentSearch">
        <FormInput
          name="agentSearch"
          type="text"
          placeholder="Search for agent"
          onChange={searchAgent}
          icon={<FaSearch />}
        />
      </div>
      <div className="agentAction">
        <ActionAgent {...props} />
      </div>
    </div>
  );
};

const NoAgent = () => (
  <table width="100%" className="tableContent">
    <TDATableHead />
    <tbody>
      <tr>
        <td colSpan="3">
          <div className="textInfo center grey">
            <p style={{ marginTop: "32px" }}>No agent assigned</p>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
);

// const LoadingMember = () => (
//   <React.Fragment>
//     <br />
//     <br />
//     <Loading />
//   </React.Fragment>
// );

const Reload = () => (
  <div className="reload">
    <Loading />
  </div>
);

const MemberView = agentProps => {
  const { agentData, retriveDataError, isLoadingTopic } = agentProps;
  if (agentData.length > 0) {
    return (
      <>
        <TDATopAction {...agentProps} />
        <TDATableAgent {...agentProps} />
      </>
    );
  }else if(agentData.length < 1) {
      return (
        <>
          <TDATopAction {...agentProps} />
          <NoAgent />
        </>
      )
  }else {
    return <RetrieveDataLoading errorMessage={retriveDataError.message} isLoading={isLoadingTopic} />;
  }
};

const TDContent = props => {
  const { onReload } = props;
  return (
    <div className="bodyContent assigned-agent-content">
      <MemberView {...props} />
      {onReload && <Reload />}
    </div>
  );
};

export default TDContent;
