import React, { useState, useEffect } from 'react';
import './UserRating.scss';
import { ToastContainer } from "react-toastify";
import iconFailed from "../../assets/img/icon-failed-80.svg";
import iconCheck from "../../assets/img/icon-check-80.svg";
import star0 from "../../assets/img/review/stars-0.svg";
import star1 from "../../assets/img/review/stars-1.svg";
import star2 from "../../assets/img/review/stars-2.svg";
import star3 from "../../assets/img/review/stars-3.svg";
import star4 from "../../assets/img/review/stars-4.svg";
import star5 from "../../assets/img/review/stars-5.svg";
import { Helmet } from "react-helmet";
import UserRatingServices from "../../services/newServices/UserRatingServices";
import HelperQueryString from "../../helper/HelperQueryString";
import ButtonWithLoadingOrIcon from "../../components/reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiUser, FiHash, FiInfo } from "react-icons/fi";

let REVIEW = {
    "noRating": {
        text: "No Rating",
        img: star0,
        val: 0,
    },
    "horrible": {
        text: "Horrible",
        img: star1,
        val: 1,
    },
    "not_good": {
        text: "Not good",
        img: star2,
        val: 2
    },
    "okay": {
        text: "Okay",
        img: star3,
        val: 3
    },
    "good": {
        text: "Good",
        img: star4,
        val: 4
    },
    "excellent": {
        text: "Excellent",
        img: star5,
        val: 5
    }
};

var UserRating = (props) => {
    let [token, setToken] = useState(false);
    let [isLoadingToken, setIsLoadingToken] = useState(true);
    let [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    let [errorMessage, setErrorMessage] = useState("");
    let [isSuccess, setIsSuccess] = useState(null);
    let [reviewResult, setReviewResult] = useState({
        "themeColor": "",
        "isExpired": "",
        "isSubmitted": "",
        "stringID": "",
        "agentName": "",
        "rating": REVIEW.noRating,
        "note": ""
    });
    let [isExample, setIsExample] = useState(false);
    let [isErrorComment, setIsErrorComment] = useState(false);

    useEffect(() => {
        let _reviewResult = {...reviewResult};
        let qs = HelperQueryString.parseQuerystring();
        let valColor = qs["color"];
        let valToken = qs["token"];    
        
        if(valToken !== "example") {
            setToken(valToken);
            fetchToken(valToken);
        }else {
            setIsExample(true);
            setIsLoadingToken(false);
        }

        if(valColor) {
            _reviewResult.themeColor = "#"+valColor;
            setReviewResult(_reviewResult);
        } 
    }, [])

    let fetchToken = (token) => {
        setIsLoadingToken(true);

        UserRatingServices.getUserRatingToken({token: token}).then(response => {
            let dataRes = response.dataResult;

            if(dataRes.status === 200) {
                if(dataRes.data.isSubmitted) {
                    setReviewResult(dataRes.data);
                    setIsSuccess(true);
                }else {
                    dataRes.data.note = "";
                    dataRes.data.rating = REVIEW.noRating;
                    setReviewResult(dataRes.data);

                    if(dataRes.data.isExpired) {
                        setIsSuccess(false);
                    }
                }
            }else {
                setIsSuccess(false);
                setErrorMessage(dataRes.error.message);
            }

            setIsLoadingToken(false);
        })
    }

    let submitToken = () => {
        if(!isExample) {

            if(reviewResult.note === "") {
                setIsErrorComment(true);
            }else {
                setIsErrorComment(false);
    
                setIsLoadingSubmit(true);
        
                let data = {
                    token: token,
                    rating: reviewResult.rating.val,
                    note: reviewResult.note
                }
        
                UserRatingServices.setUserRatingForm(data).then(response => {
                    setIsLoadingSubmit(false);
        
                    let dataRes = response.dataResult;
        
                    if(dataRes.status === 200) {
                        if(dataRes.data.success) {
                            setIsSuccess(true);
                        }else {
                            setIsSuccess(false);
                            setErrorMessage(dataRes.data.message);
                        }
                    }else {
                        setIsSuccess(false);
                        setErrorMessage(dataRes.error.message);
                    }
                })
            }
        }
    }

    let ErrorContent = () => (
        <div className="result-wrapper">
            <img src={iconFailed} alt="" />

            <p>
                <b>{reviewResult.isExpired ? 'Unable to Rate' : 'Error Occured!'}</b>
            </p>
            
            <p>
               {reviewResult.isExpired ? 'You have exceeded the time limit to rate, your request cannot be processed.' : errorMessage}
            </p>
        </div>
    )

    let SuccessContent = () => (
        <div className="result-wrapper">
            <img src={iconCheck} alt="" />
            
            <p>
                <b>Thank You!</b>
            </p>
            
            <p>
                You have rated this conversation.
            </p>
        </div>
    )

    let LoadingContent = () => (
        <div className="result-wrapper loading-content">
            <div className="inner-content">
                <div className="lds-ring">
                    <div /><div /><div /><div />
                </div>
                
                <p>
                    Please Wait
                </p>
            </div>
        </div>
    )

    let printStar = () => {
        let indents = [];

        for(let i = 0; i <= 4; i++) {
            indents.push(
                <div 
                    className={`star-selection ${i}`} 
                    key={`star-${i}`} 
                    onClick={() => {
                        let _reviewResult = {...reviewResult};
                        _reviewResult.rating = REVIEW[Object.keys(REVIEW)[i + 1]];
                        setReviewResult(_reviewResult);
                    }} 
                />
            )
        }
        
        return indents;
    }

    let onChangeComment = (e) => {
        let _reviewResult = {...reviewResult};
        if(e.target.value.length <= 1000) {
            _reviewResult.note = e.target.value;
            setReviewResult(_reviewResult);
        }
    }

    let isDisabled = () => {
        let dis = false;

        if(reviewResult.rating.val === 0) {
            dis = true;
        }


        if (reviewResult.rating.val < 4) {
            if(reviewResult.note === "") {
                dis = true
            }
        }

        return dis;
    }

    let userRating = () => {
        return (
            <div className="main-rating-wrapper">
                <p className="main-rating-title">
                    <b>Rate your conversation</b>
                </p>

                <label><b>Case ID</b></label>
                <div className="value-wrapper">
                    <FiHash />
                    {isExample ? "XXXXXXXXXX" : reviewResult.stringID}
                </div>

                <label><b>Agent</b></label>
                <div className="value-wrapper">
                    <FiUser />
                    {isExample ? "Your Agent" : reviewResult.agentName}
                </div>
                
                <div className="rating-wrapper">
                    <div className="star-selection-wrapper">
                        <img src={reviewResult.rating.img} alt={reviewResult.text} />
                        
                        <div className="star-selection-content">
                            {printStar()}
                        </div>
                    </div>

                    <p>
                        <b>{reviewResult.rating.text}</b>
                    </p>
                </div>

                <label>
                    <b>Comment</b>{(reviewResult.rating.val > 3) && <span> (optional)</span>}

                    <span className="comment-length">{reviewResult.note.length}/1000</span>
                </label>
                
                <textarea 
                    placeholder="Write a review..." 
                    maxLength={1000} 
                    rows={3} 
                    value={reviewResult.note} 
                    onChange={(e) => onChangeComment(e)} 
                    className={isErrorComment ? "border-red" : ""}
                    disabled={isLoadingSubmit}
                />

                {isErrorComment &&
                    <p className='red-text'>
                        <FiInfo />
                        <b>This is a required field</b>
                    </p>
                }

                {!isLoadingSubmit ?
                    <ButtonWithLoadingOrIcon 
                        isDisabled={isDisabled()}
                        className="main-button-48 button-submit-rating width-100"
                        text="Submit"
                        onClickAction={submitToken}
                        style={{
                            background: reviewResult.themeColor,
                        }}
                    />
                    :
                    <ButtonWithLoadingOrIcon 
                        isLoadingSubmit
                        loadingColor="gray"
                        isDisabled
                        position="left"
                        className="main-button-48 button-submit-rating width-100"
                        text="Submit"
                        style={{
                            background: reviewResult.themeColor,
                        }}
                    />
                }
            </div>
        )
    }

    return (
        <div className="user-rating-wrapper" style={{background: reviewResult.themeColor === "" ? '#ff7d00' : reviewResult.themeColor}}>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1" />
            </Helmet>
            
            <ToastContainer className="ToastContainer" />
            
            {isLoadingToken ?
                <LoadingContent />
                :
                (isSuccess !== null ?
                    (isSuccess ?
                        <SuccessContent />
                        :
                        <ErrorContent />
                    )
                    :
                    userRating()
                )
            }
        </div>
    );
}

export default UserRating;
