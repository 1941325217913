import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./IntegrationGoogleProfile.scss";
import { connect } from 'react-redux';
import "react-toastify/dist/ReactToastify.css";
import { FiArrowUpRight, FiPlus } from "react-icons/fi";
// import IconAdd from "../../../assets/img/icon-add.svg";
import GoogleIcon from "../../../assets/img/integration/logo-google-profile-side-menu.svg";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupWarning from "../../reuseableComponent/popupWarning/PopupWarning";
import { checkID } from "../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../helper/HelperGetLocalStorage";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import IntegrationGoogleBusinessProfileServices from "../../../services/integration/integrationGoogleBusinessProfile";
// import Integrations from "../Integrations";
import mixpanel from "mixpanel-browser";

const IntegrationGoogleProfile = props => {
    let { match, history } = props;
    let [orgID, setOrgID] = useState(false);
    let [isLoadingGoogleProfileAccount, setIsLoadingGoogleProfileAccount] = useState(true);
    let [googleProfileAccountData, setGoogleProfileAccountData] = useState([]);
    let [showPopupWarning, setShowPopupWarning] = useState(false);
    let [retriveDataError, setRetrieveDataError] = useState({
        code: false,
        message: false
    });

    let toggleShowWarning = () => {
        setShowPopupWarning(!showPopupWarning);
    }

    let getGoogleProfileAccount = (orgID) => {
        let _retriveDataError = {...retriveDataError};
        setIsLoadingGoogleProfileAccount(true);

        IntegrationGoogleBusinessProfileServices.getList(orgID, (response) => {
            let dataResult = response.dataResult;

            if(response.dataResult.status === 200) {
                setGoogleProfileAccountData(response.dataResult.data.channels);
            }else {
                setGoogleProfileAccountData([]);
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsLoadingGoogleProfileAccount(false);
        })
    }

    const doMount = () => {
        const paramID = checkID(match);
        if (paramID) {
            setOrgID(paramID);
            getGoogleProfileAccount(paramID);
        } else {
            ServiceOrganization.postGetOrganization((response) => {
                if(response.dataResult.error !== "") {
                    let responseData = response.dataResult.data;
                    if (responseData.memberships.length < 1) window.location.href = "/organization/new";
                    const { id, createdTime } = responseData.memberships[0].organization;
                    const urlOrgID = `${id}-${createdTime}`;
                    history.push(`/o/${urlOrgID}/integration`);
                }else {
                    console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
                }
            });
        }
    };

    useEffect(() => {
        doMount();
    }, []);

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(orgID) {
            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                    "[Page] Google Business Profile",
                    {
                        userFullName : myAgentData.account.fullName,
                        userID : myAgentData.account.id,
                        organizationID : orgID.split("-")[0]
                    }
                );
            }
        }
    }, [orgID])

    let goToAdd = () => {
        // let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
        // mixpanel.track(
        //     "[Button] Add Channel",
        //     {
        //         channel: "Google Business Profile",
        //         userFullName : myAgentData.account.fullName,
        //         userID : myAgentData.account.id,
        //         organizationID : orgID.split("-")[0]
        //     }
        // );
    
        if(!props.mediumData) {
            toggleShowWarning();
        }else {
            if(props.mediumData.numberOfItems.maximum !== -1 && props.mediumData.numberOfItems.current >= props.mediumData.numberOfItems.maximum) {
                toggleShowWarning();
            }else {
                history.push(`/o/${orgID}/integration/google-business-profile/add`);
            }
        }
    };
    // let goToDetail = (account) => history.push(`/o/${orgID}/integration/facebook/detail/${account.id}`);

    return (
        <div className="integration-outer-wrapper">
            <SectionWrap
                {...props}
                orgID={orgID}
                withHeader
                withSideMenu
            >
                {/* <Integrations activeIntegrationChannel="facebook" parentProps={props} orgIDProps={orgID} /> */}
                {/* tier blocking */}
                {retriveDataError.code  === "49901" &&
                    <RetrieveDataLoading 
                    isLoading={isLoadingGoogleProfileAccount}
                    errorMessage={retriveDataError.message}
                    errorCode={retriveDataError.code}
                    />
                }
                {/* tier blocking */}

                <div className="sectionWrap integration-main-container integration-google-profile-list text-left">
                    <React.Fragment>
                        <div className="integration-list-header">
                            <img src={GoogleIcon} alt="" className="integration-list-header-icon" />

                            <div className="integration-list-header-text-wrapper">
                                <b>Google Business Profile</b>
                            </div>

                            {!isLoadingGoogleProfileAccount &&
                                <ButtonWithLoadingOrIcon 
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    className="no-fill-button integration-list-header-button main-button-40"
                                    text="Add Channel"
                                    position="left"
                                    onClickAction={goToAdd}
                                />
                            }
                        </div>
                        
                        {isLoadingGoogleProfileAccount || retriveDataError.code ?
                            <RetrieveDataLoading
                                isLoading={isLoadingGoogleProfileAccount}
                                errorMessage={retriveDataError.message}
                            />
                            :
                            googleProfileAccountData.length > 0 ?
                                <>
                                    <div className="integration-list-outer-wrapper">
                                        {googleProfileAccountData.map((value, index) => {
                                            return (
                                                <Link key={`google-account-${index}`}
                                                    to={`/o/${orgID}/integration/google-business-profile/detail/${value.id}`}
                                                    
                                                    className="link-google-account-list"
                                                >
                                                    <div className="integration-list-inner-wrapper">
                                                        <FiArrowUpRight />
                                                        <p>
                                                            <b>{value.channelName}</b>
                                                        </p>

                                                        <span>{value.stringID}</span>

                                                        <p 
                                                            className={`
                                                                ${value.status === "active" ? 
                                                                    "text-green"
                                                                    :
                                                                    (value.status === "suspended" ?
                                                                        "yellow-text"
                                                                        :
                                                                        (value.status === "disconnected" ? 
                                                                            "font-grey"
                                                                            :
                                                                            "red-text"
                                                                        )
                                                                    )
                                                                }
                                                            `}
                                                        >
                                                            {value.statusText}
                                                        </p>
                                                    </div>
                                                </Link>
                                            )
                                        })}
                                    </div>
                                </>
                                :
                                <div className="integration-list-no-data">
                                    <b>No Channel</b>
                                    <p>
                                        To add a channel, simply click the "+ Add Channel" button on the top right.
                                    </p>
                                </div>
                        }
                    </React.Fragment>
                </div>
            </SectionWrap>
        </div>
    )
}

const mapStateToProps = state => ({
    mediumData: state.mediumData
});

export default connect(mapStateToProps, null)(IntegrationGoogleProfile);
