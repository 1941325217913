import React, { useState, useEffect } from "react";
import { Modal, ModalBody } from 'reactstrap';
import { connect } from "react-redux";
import "./BroadcastMessageDetailEdit.scss";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
import HelperChat from "../../../helper/HelperChat";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { FiActivity, FiClock, FiUser, FiUsers, FiEdit, FiInfo, FiCalendar } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import IconMessage from "../../../assets/img/sidemenu-icon/icon-messages-black.svg";
import BroadcastBlack from "../../../assets/img/sidemenu-icon/new/sub/broadcast-message-black.svg";
import IconFile from "../../../assets/img/file.svg";
import BroadcastMessageServices from "../../../services/newServices/BroadcastMessageServices";
import { TAPLIVE_MEDIUM_LOGO, TAPLIVE_STATUS, RECIPIENT_TYPE } from "../../../constants/taplive";
import { MESSAGE_TYPE } from "../../../constants/message";
import HelperDate from "../../../helper/HelperDate";
import HelperInput from "../../../helper/HelperInput";
import ColoredInfoBox from "../../reuseableComponent/coloredInfoBox/ColoredInfoBox";
import { setPreviewImageOrVideo } from "../../../redux/actions/reduxActionPreviewImageOrVideo";
import IcGroup from "../../../assets/img/ic-group.svg";
import ImageUpload from "../../reuseableComponent/imageUpload/ImageUpload";
import CalendarPickerSingle from "../../reuseableComponent/calendarPickerSingle/CalendarPickerSingle";
import { doToast } from "../../../helper/HelperGeneral";

const BroadcastMessageDetailEdit = (props) => {
  let { match, history, location } = props;
  const [orgID, setOrgID] = useState(false);
  const [broadcastDetail, setBroadcastDetail] = useState();
  const [broadcastDetailEdit, setBroadcastDetailEdit] = useState();
  const [broadcastDetailEditOrigin, setBroadcastDetailEditOrigin] = useState();
  let [imageSrc, setImageSrc] = useState('');
  let [isShowModalImage, setIsShowModalImage] = useState(false);
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  const [isLoadingGetBroadcastData, setIsLoadingGetBroadcastData] = useState(
    true
  );
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const toggleDatePicker = (reset = true) => {
    if(reset) {
      let _broadCastMessageData = {...broadcastDetail};
      _broadCastMessageData.broadcast.scheduleDateSelect = _broadCastMessageData.broadcast.scheduledInputDate;
      setBroadcastDetail(_broadCastMessageData);
    }

    setShowDatePicker(!showDatePicker);
  }

  let convertBody = (text) => {    
    return HelperChat.convertFormatTextWhatsapp(text);
  };

  let toCapitalize = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  const checkOrgID = (match) => {
    const { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  const checkID = (match) => {
    const { id: param } = match.params;
    if (!param) return false;
    else return Number(param);
  };

  const back = () =>
    history.push({
      pathname: `/o/${orgID}/operational/broadcast-message/detail/${props.match.params.id}`,
      state: {
        pageNumber: location.pageNumber ? location.pageNumber : 1,
        pageSize: location.pageSize ? location.pageSize : 20,
        tab: props.history.location.state.tab
      },
    });

  const doMount = async () => {
    const paramID = checkOrgID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  const fetchBroadcastMessageDetailEdit = (data, loadAll = true) => {
    if (loadAll) setIsLoadingGetBroadcastData(true);
    BroadcastMessageServices.getBroadcastDetails(orgID, data, (response) => {
      let _retrieveDataError = { ...retrieveDataError };

      if (response.dataResult.error.message === "") {
        let scheDuleDate = new Date(response.dataResult.data.broadcast.scheduledInputDate);
        let data = {
          scheduledInputDate: scheDuleDate,
          scheduleDateSelect: scheDuleDate,
          scheduledInputTime: response.dataResult.data.broadcast.scheduledInputTime
        };

        setBroadcastDetailEditOrigin(data);
        setBroadcastDetailEdit(data);
        setBroadcastDetail(response.dataResult.data);
        setImageSrc(response.dataResult.data.broadcast.contentBody);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
      }
      setIsLoadingGetBroadcastData(false);
    });
  };

  const runEdit = () => {
    let data = {
      "id": parseInt(props.match.params.id),
      "scheduledDate": HelperDate.formatToString(new Date(broadcastDetailEdit.scheduledInputDate), "yyyy-MM-dd"),
      "scheduledTime": broadcastDetailEdit.scheduledInputTime
    }

    setIsLoadingEdit(true);
    BroadcastMessageServices.edittBroadcastScheduled(orgID, data, (response) => {
      let _retrieveDataError = { ...retrieveDataError };

      if (response.dataResult.error.message === "") {
        if(response.dataResult.data.success) {
          setBroadcastDetailEditOrigin(broadcastDetailEdit);
          setTimeout(() => {
            props.history.push({
              pathname: `/o/${orgID}/operational/broadcast-message`,
              state: {
                tab: "scheduled"
              }
            })
          }, 2000);
        }else {
          setIsLoadingEdit(false);
        }

        doToast(response.dataResult.data.message);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
        setIsLoadingEdit(false);
      }
    });
  }

  let openURL = (url) => {
    var win = window.open(url, "_blank");
    if (win) {
      //Browser has allowed it to be opened
      win.focus();
    } else {
      //Browser has blocked it
      alert("Please allow popups for this website");
    }
  };

  const renderMessageContent = (body, type, caption = "", filename = "", idx) => {
    if (type === MESSAGE_TYPE.file) {
      return (
        <div className="message-content file-url" onClick={() => openURL(body)}>
          <div className="flexing">
            <img src={IconFile} alt="" />
            <p>{filename}</p>
          </div>
          <p
            className="content-caption"
            dangerouslySetInnerHTML={{
              __html: convertBody(HelperChat.lineBreakWithoutTrim(caption)),
            }}
          ></p>
        </div>
      );
    } else if (type === MESSAGE_TYPE.image) {
      // setImageSrc(body);
      return (
        <div className="message-content">
          <ImageUpload
            imageSrc={body}
            // onClick={() => clickMediaPreview("image", body)}
            onClick={() => {
              toggleModalImage();
            }}
            withoutRemove
          />
          {caption ? (
            <>
              <b>Caption</b>
              <p
                className="content-caption"
                dangerouslySetInnerHTML={{
                  __html: convertBody(HelperChat.lineBreakWithoutTrim(caption)),
                }}
              />
            </>
          ) : (
            ""
          )}
        </div>
      );
    } else
      return (
        <p
          dangerouslySetInnerHTML={{
            __html: convertBody(HelperChat.lineBreakWithoutTrim(body)),
          }}
        ></p>
      );
  };

  let toggleModalImage = () => {
    setIsShowModalImage(!isShowModalImage);
  };

  let generateModalImage = () => {
    let zoomImage = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = "200%";
      var offsetX = e.nativeEvent.offsetX;
      var offsetY = e.nativeEvent.offsetY;

      var x = (offsetX / zoomer.offsetWidth) * 100;
      var y = (offsetY / zoomer.offsetHeight) * 100;
      zoomer.style.backgroundPosition = x + "% " + y + "%";
    };

    let zoomImageOut = (e) => {
      var zoomer = e.currentTarget;
      zoomer.style.backgroundSize = "0%";
    };

    return (
      <div>
        <Modal isOpen={isShowModalImage} className={"modal-image"} toggle={toggleModalImage}>
          <ModalBody onClick={() => toggleModalImage()}>
            <IoIosClose
              className="close-modal-image"
              onClick={() => toggleModalImage()}
            />
            <div className="image-wrapper">
              <figure
                className="zoom"
                style={{ backgroundImage: `url(${imageSrc})` }}
                onMouseMove={(e) => zoomImage(e, imageSrc)}
                onMouseLeave={(e) => zoomImageOut(e)}
              >
                <img src={imageSrc} alt="" className="image-preview-val" />
              </figure>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  };

  let isDisabled = () => {
    let dis = false;
    let _bcDataOri = {...broadcastDetailEditOrigin};
    let _bcData = {...broadcastDetailEdit};

    if(JSON.stringify(_bcDataOri) === JSON.stringify(_bcData)) {
      dis = true;
    }
    
    if(_bcData.scheduledInputDate) {
      if(_bcData.scheduledInputTime === "" || !HelperInput.valid24HourFormat(_bcData.scheduledInputTime)) {
        dis = true;
      }
    }

    if(_bcData.scheduledInputTime !== "") {
      if(!_bcData.scheduledInputDate) {
        dis = true;
      }
    }

    return dis;
  }
  let submmitDateFilter = () => {
    let _broadCastMessageData = {...broadcastDetailEdit};
    _broadCastMessageData.scheduledInputDate = _broadCastMessageData.scheduleDateSelect;
    setBroadcastDetailEdit(_broadCastMessageData);
    toggleDatePicker(false);
  }

  let handleDateFilterClick = (d, {disabled}) => {
    if(!disabled) {
      let _broadCastMessageData = {...broadcastDetailEdit};
      _broadCastMessageData.scheduleDateSelect = d;
      setBroadcastDetailEdit(_broadCastMessageData);
    }
  }
  
  let clearDatePicker = () => {
    let _broadCastMessageData = {...broadcastDetailEdit};
    _broadCastMessageData.scheduleDateSelect = undefined;
    _broadCastMessageData.scheduledInputDate = undefined;
    setBroadcastDetailEdit(_broadCastMessageData);
    setShowDatePicker(false);
  }

  let onChangeTime = (e) => {
    let data = {...broadcastDetailEdit};
    let value = e.target.value;
    
    if(value.length === 2 && !value.includes(":")) {
        value = value + ":";
    }
    
    if(value.length === 5 && !HelperInput.valid24HourFormat(value)) {
      value = "00:00";
    }

    data.scheduledInputTime = value;        
    setBroadcastDetailEdit(data);
  }

  let onKeyPressChangeTime = (e) => {
      let data = {...broadcastDetailEdit};
      let value = e.target.value;
      
      if(e.keyCode === 8 && (data.scheduledInputTime.includes(":") && data.scheduledInputTime.length === 3)) {
          data.scheduledInputTime = value[0];    
          setBroadcastDetailEdit(data);
          e.preventDefault();
      }
  }

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    let paramID = checkID(match);
    if (orgID && paramID) {
      let data = {
        id: paramID,
        pageNumber: 1,
        pageSize: 20,
      };
      fetchBroadcastMessageDetailEdit(data);
    } else if (orgID) back();
  }, [orgID]);

  return (
    <SectionWrap 
      {...props} 
      orgID={orgID} 
      withHeader 
      withSideMenu
      dataOrigin={broadcastDetailEditOrigin}
      dataAfterChange={broadcastDetailEdit}
    >
      {/* <ModalPreviewImageOrVideo /> */}
      {imageSrc && generateModalImage()}

      <BackButton text="Back to Broadcast Message Detail" onClick={back} />

      {/* BROADCAST */}
      <div className="view-broadcast-message-detail sectionWrap">
        <div className="view-broadcast-message-detail-top-edit">
          <b><FiEdit /> Edit Scheduled Broadcast</b>

          <p>
            Send a new message to multiple recipients at once through one of your selected active channels.
          </p>
        </div>
        {isLoadingGetBroadcastData || retrieveDataError.code ? (
          <div className="broadcast-message-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetBroadcastData}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : broadcastDetail ? (
          <React.Fragment>
            <CalendarPickerSingle
              isOpen={showDatePicker}
              toggle={toggleDatePicker}
              date={broadcastDetailEdit.scheduleDateSelect}
              handleDateFilterClick={handleDateFilterClick}
              submmitDateFilter={submmitDateFilter}
              clearDatePicker={clearDatePicker}
              disabledDays={{
                before: new Date(),
                after: new Date(new Date().setMonth(new Date().getMonth()+6))
              }}
              {...props}
            />

            <div className="view-broadcast-message-detail-middle-edit">
              <div className="broadcast-message-schedule">
                <p className="broadcast-schedule-title">
                  <FiCalendar />
                  <b>Schedule Broadcast</b>
                </p>

                <div className="broadcast-schedule-time-wrapper">
                  <div className="broadcast-date-select-wrapper">
                    <label><b>Date</b></label>
                    <div className="broadcast-date-value-wrapper" onClick={toggleDatePicker}>
                      <FiCalendar />
                      {broadcastDetailEdit.scheduledInputDate ?
                        HelperDate.formatToString(new Date(broadcastDetailEdit.scheduledInputDate), "dd MMM yyyy")
                        :  
                        <span className="grey-font">
                          Select Date
                        </span>
                      }
                    </div>
                  </div>

                  <div className="broadcast-time-input-wrapper">
                    <label><b>Time</b></label>
                    <div className="broadcast-time-value-wrapper">
                      <FiClock />

                      <input 
                        id="scheduleTime"
                        type="text" 
                        value={broadcastDetailEdit.scheduledInputTime} 
                        placeholder="00:00"
                        maxLength={5}
                        onKeyDown={(e) => onKeyPressChangeTime(e)}
                        onChange={(e) => onChangeTime(e)}
                        disabled={isLoadingEdit}
                      />
                    </div>
                  </div>
                  <p className="time-select-info">
                    <FiInfo />
                    <b>Jakarta (GMT+07:00) Western Indonesia Time</b>
                  </p>
                </div>
              </div>


              <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-name">
                <img src={BroadcastBlack} alt="" className="label-icon" />
                <b>Broadcast Name</b>
                <p>{broadcastDetail.broadcast.name}</p>
              </div>
              <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-channel">
                <img src={IcGroup} alt="" className="label-icon" />
                <b>Channel</b>
                <div className="flexing">
                  <img
                    src={TAPLIVE_MEDIUM_LOGO[broadcastDetail.broadcast.medium]}
                    alt=""
                    className="channel-icon"
                  />
                  <p>{broadcastDetail.channel.accountName}</p>
                </div>
              </div>
              <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-sender">
                <FiUser />
                <b>Sender</b>
                <p>{broadcastDetail.broadcast.senderAccountName}</p>
              </div>
              <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-sender">
                <FiUser />
                <b>Recipient Type</b>
                <p>{RECIPIENT_TYPE[broadcastDetail.broadcast.recipientType].text}</p>
              </div>
              {broadcastDetail.broadcast.recipientType === RECIPIENT_TYPE.user_segment.val &&
                <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-sender">
                  <FiUsers />
                  <b>Segment Name</b>
                  <p>{broadcastDetail.broadcast.userSegmentName}</p>
                </div>
              }
              <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-created-time">
                <FiClock />
                <b>Started Time</b>
                {broadcastDetail.broadcast.startTime === 0 ?
                  <p>-</p>
                  :
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.startTime),
                      "dd MMM yyyy HH:mm"
                    )}
                  </p>
                }
              </div>
              <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-created-time">
                <FiClock />
                <b>Finished Time</b>
                {broadcastDetail.broadcast.endTime === 0 ?
                  <p>-</p>
                  :
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.endTime),
                      "dd MMM yyyy HH:mm"
                    )}
                  </p>
                }
              </div>

              
              {broadcastDetail.broadcast.scheduledInputDate !== "" &&
                <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-created-time">
                  <FiClock />
                  <b>Scheduled Time</b>
                  <p>
                    {HelperDate.formatToString(new Date(broadcastDetail.broadcast.scheduledInputDate),"dd MMM yyyy")} {broadcastDetail.broadcast.scheduledInputTime}
                  </p>
                </div>
              }

              {broadcastDetail.broadcast.scheduledInputDate === "" &&
                <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-status">
                  <FiActivity />
                  <b>Status</b>
                  {TAPLIVE_STATUS[broadcastDetail.broadcast.status] ? (
                    <ColoredInfoBox
                      text={toCapitalize(
                        TAPLIVE_STATUS[broadcastDetail.broadcast.status].value
                      )}
                      color={
                        TAPLIVE_STATUS[broadcastDetail.broadcast.status].color
                      }
                    />
                  ) : (
                    broadcastDetail.broadcast.status
                  )}
                </div>
              }

              <React.Fragment>
                {broadcastDetail.messages.map((val, idx) => {
                  return (
                    <div className="view-broadcast-message-detail-middle-edit message-number-wrapper" key={`message-detail-middle-edit-${idx}`}>
                      <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-content-body">
                        <img src={IconMessage} alt="" />
                        <b>Message {idx > 0 ? `#${idx + 1}` : ""}</b>
                      </div>
                      <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-message-type">
                        <b>Message Type</b>
                        <p>{toCapitalize(val.messageType)}</p>
                      </div>
                      <div className="view-broadcast-message-detail-middle-edit view-broadcast-message-detail-middle-edit-content-body">
                        <b>Content</b>
                        {renderMessageContent(
                          val.contentBody,
                          val.messageType,
                          val.contentCaption,
                          val.contentFileName,
                          idx
                        )}
                      </div>
                    </div>
                  )
                })}
              </React.Fragment>
              
              {isLoadingEdit ?
                <ButtonWithLoadingOrIcon
                  text="Save Changes"
                  className="main-button-48 orange-button button-save-changes"
                  isLoading
                  isDisabled
                  loadingColor="gray"
                  position="left"
              />
              :
              <ButtonWithLoadingOrIcon
                  text="Save Changes"
                  className="main-button-48 orange-button button-save-changes"
                  isDisabled={isDisabled()}
                  onClickAction={runEdit}
              />
              }
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    </SectionWrap>
  );
};

const mapStateToProps = (state) => ({
  previewImageOrVideo: state.previewImageOrVideo,
});

const mapDispatchToProps = {
  setPreviewImageOrVideo,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BroadcastMessageDetailEdit);
