import React, { useEffect, useState } from "react";
import "./CaseListAction.scss";
import { FiCheckCircle, FiAlertTriangle, FiChevronDown } from "react-icons/fi";
import { doToast } from "../../../../helper/HelperGeneral";
import CaseService from '../../../../services/chat/caseServices';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import { setCaseListChecked } from "../../../../redux/actions/reduxActionCaseListChecked";
import { connect } from 'react-redux';

let CaseListAction = (props) => {
    let { data, type, caseListChecked, 
          setCaseListChecked, orgID, _setLastBulkJunk,
          _setLastBulkSolve, toogleCaseListAction, caseLength
    } = props;
    
    let [showPopupJunk, setShowPopupJunk] = useState(false);
    let [showPopupSolved, setShowPopupSolved] = useState(false);
    let [isSetMarkAsResolved, setIsSetMarkAsResolved] = useState(false);
    let [isLoadingMarkCase, setIsLoadingMarkCase] = useState(false);
    let [dropdownCaseActionOpen, setDropdownCaseActionOpen] = useState(false);
    let [isSendClosingMessage, setIsSendClosingMessage] = useState(false);

    useEffect(() => {
        setIsSendClosingMessage(false);
    }, [showPopupSolved])

    let toggleMarkAsResolved = () => {
        setIsSetMarkAsResolved(!isSetMarkAsResolved);
    }

    let toggleJunk = () => {
        if(!isLoadingMarkCase) {
            setShowPopupJunk(!showPopupJunk);
            setIsSetMarkAsResolved(false);
        }
    }

    let toggleSolved = () => {
        if(!isLoadingMarkCase) {
            setShowPopupSolved(!showPopupSolved);
        }
    }

    let toggleDropdownCaseAction = () => {
        if(Object.keys(caseListChecked[type].data).length > 0) {
            setDropdownCaseActionOpen(!dropdownCaseActionOpen);
        }
    }

    let markCase = (_type) => {
        setIsLoadingMarkCase(true);
        let _caseListChecked = {...caseListChecked};
        
        if(_type === "close") {
            let ids = [];
            let roomIDs = [];

            Object.keys(_caseListChecked[type].data).map(v => {
                ids.push(_caseListChecked[type].data[v].id);
                roomIDs.push(_caseListChecked[type].data[v].tapTalkRoom.lastMessage.room.roomID);
                return null;
            })

            let data = {
                "ids": ids,
                "sendClosingMessage": isSendClosingMessage,
            };

            // if(type === "mine") {

            // }else if(type === "unassigned") {

            // }else {
                _setLastBulkSolve({
                    time: new Date().valueOf(),
                    arrayOfRoomIDs: roomIDs
                })
            // }
            
            setTimeout(() => {
                CaseService.postCloseCaseMulti(orgID, data, (response) => {
                    if(response.dataResult.error.code === "") {
                        _caseListChecked[type].data = {};
                        _caseListChecked[type].checkedAll = false;
                        setCaseListChecked(_caseListChecked)
                        doToast(response.dataResult.data.message);
                        toogleCaseListAction();
                    }else {
                        doToast(response.dataResult.error.message, "fail");
                    }
    
                    toggleSolved();
                    setIsLoadingMarkCase(false);
                });
            }, 1000)
        }else {
            let cases = [];
            let roomIDs = [];

            Object.keys(_caseListChecked[type].data).map(v => {
                cases.push({
                    id: _caseListChecked[type].data[v].id,
                    createdTime: _caseListChecked[type].data[v].createdTime
                });
                roomIDs.push(_caseListChecked[type].data[v].tapTalkRoom.lastMessage.room.roomID);
                return null;
            })
            
            let data = {
                "cases": cases,
                "isJunk": true,
                "closeSelectedCases": isSetMarkAsResolved,
            };

            // if(type === "mine") {

            // }else if(type === "unassigned") {

            // }else {
                if(isSetMarkAsResolved) {
                    _setLastBulkSolve({
                        time: new Date().valueOf(),
                        arrayOfRoomIDs: roomIDs
                    })
                }else {
                    _setLastBulkJunk({
                        time: new Date().valueOf(),
                        arrayOfRoomIDs: roomIDs
                    })
                }
            // }

            setTimeout(() => {
                CaseService.updateJunkStatusMulti(orgID, data, (response) => {
                    if(response.dataResult.error.code === "") {
                        _caseListChecked[type].data = {};
                        _caseListChecked[type].checkedAll = false;
                        setCaseListChecked(_caseListChecked)
                        doToast(response.dataResult.data.message);
                        toogleCaseListAction();
                    }else {
                        doToast(response.dataResult.error.message, "fail");
                    }
    
                    toggleJunk();
                    setIsLoadingMarkCase(false);
                });
            }, 1000)
        }
    }

    let clickCheckedAllCase = (e) => {
        let _checked = e.target.checked;
        let _caseListChecked = {...caseListChecked};

        _caseListChecked[type].data = {};
        
        if(_checked) {
            _caseListChecked[type].data = {...data};
        }

        _caseListChecked[type].checkedAll = _checked;
        setCaseListChecked(_caseListChecked);
    }

    return (
        <>
            <PopupRemove 
                removeTitle={`Mark as Resolved?`}
                removeDescription={`These ${Object.keys(caseListChecked[type].data).length} selected cases will be marked as Resolved. Are you sure you want to continue?`}
                onClickCancel={toggleSolved}
                onClickRemove={() => markCase("close")}
                additionalContent={
                   <div 
                        className="set-send-closing-message-wrapper"
                        style={{
                        display: "flex",
                        fontStyle: "normal",
                        fontWeight: "normal",
                        fontSize: 14,
                        lineHeight: "21px",
                        letterSpacing: "-0.2px",
                        color: "rgba(25, 25, 25, 0.6)",
                        padding: "0 8px 8px",
                        background: "rgba(25, 25, 25, 0.05)",
                        borderRadius: 8,
                        marginTop: 8,
                        }}
                    >
                        <div className="custom-checkbox">
                            <input 
                            type="checkbox"
                            id="set-send-closing-message"
                            onClick={() => setIsSendClosingMessage(!isSendClosingMessage)}
                            checked={isSendClosingMessage}
                            disabled={isLoadingMarkCase}
                            readOnly
                            />
                            <label htmlFor="set-send-closing-message" />
                        </div>
                        <p style={{marginTop: 7}}>Send closing message</p>
                    </div>
                }
                withoutBorder
                loadingButtonGray
                waitForResponse={isLoadingMarkCase}
                showModalProps={showPopupSolved}
                submitText={'Mark as Resolved'}
            />

            <PopupRemove 
                removeTitle={`Mark as Junk?`}
                removeDescription={`These ${Object.keys(caseListChecked[type].data).length} selected cases will be marked as junk. Are you sure you want to continue`}
                onClickCancel={toggleJunk}
                onClickRemove={() => markCase("junk")}
                additionalContent={ 
                    <div 
                        className="set-send-closing-message-wrapper"
                        style={{
                            display: "flex",
                            fontStyle: "normal",
                            fontWeight: "normal",
                            fontSize: 14,
                            lineHeight: "21px",
                            letterSpacing: "-0.2px",
                            color: "rgba(25, 25, 25, 0.6)",
                            padding: "0px 8px 8px",
                            background: "rgba(25, 25, 25, 0.05)",
                            borderRadius: 8,
                            marginTop: 8,
                        }}
                    >
                        <div className="custom-checkbox">
                            <input 
                                type="checkbox"
                                id="set-send-closing-message"
                                onClick={toggleMarkAsResolved}
                                checked={isSetMarkAsResolved}
                                disabled={isLoadingMarkCase}
                                readOnly
                            />
                            <label htmlFor="set-send-closing-message" />
                        </div>

                        <p style={{marginTop: 7}}>Mark as Resolved</p>
                    </div>
                }
                withoutBorder
                loadingButtonGray
                waitForResponse={isLoadingMarkCase}
                showModalProps={showPopupJunk}
                submitText={'Mark as Junk'}
            />

            <div className="case-list-action-wrapper">
                <div className="case-list-action-left">
                    <Dropdown 
                        isOpen={dropdownCaseActionOpen} 
                        toggle={toggleDropdownCaseAction} 
                        direction={"down"}
                        className={`${Object.keys(caseListChecked[type].data).length === 0 ? "dropdown-disabled" : ""}`}
                    >
                        <DropdownToggle>
                            <b>Mark Selected As</b> 
                            <FiChevronDown className={`${dropdownCaseActionOpen ? "dropdown-case-action-active" : ""}`} />
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem onClick={toggleSolved}>
                                <FiCheckCircle />
                                Mark as Resolved
                            </DropdownItem>

                            <DropdownItem onClick={toggleJunk}>
                                <FiAlertTriangle />
                                Mark as Junk
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>

                <div className="case-list-action-right">
                    <b>
                        {Object.keys(caseListChecked[type].data).length} Selected
                    </b>

                    {caseLength === Object.keys(caseListChecked[type].data).length &&
                        <div className={"custom-checkbox"}>
                            <input 
                                type="checkbox" 
                                id="check-all-case"
                                onChange={(e) => clickCheckedAllCase(e)}
                                checked={true}
                            />
                            <label htmlFor="check-all-case"></label>
                        </div>
                    }

                    {(Object.keys(caseListChecked[type].data).length > 0 && caseLength !== Object.keys(caseListChecked[type].data).length) &&
                        <div className={"custom-checkbox-stripe a"}>
                            <input 
                                type="checkbox" 
                                id="check-all-case"
                                onChange={(e) => clickCheckedAllCase(e)}
                                checked={true}
                            />
                            <label htmlFor="check-all-case"></label>
                        </div>
                    }

                    {Object.keys(caseListChecked[type].data).length === 0 &&
                        <div className={"custom-checkbox"}>
                            <input 
                                type="checkbox" 
                                id="check-all-case"
                                onChange={(e) => clickCheckedAllCase(e, true)}
                                checked={caseListChecked[type].checkedAll}
                            />
                            <label htmlFor="check-all-case"></label>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = state => ({
    activeRoom: state.activeRoom,
    caseListChecked: state.caseListChecked
});

const mapDispatchToProps = {
    setCaseListChecked
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseListAction);