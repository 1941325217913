import React, { useEffect, useState } from "react";
import "./BroadcastMessageForm.scss";
import BroadcastMessageFormCsvPart from "./broadcastMessageFormCsvPart/BroadcastMessageFormCsvPart";
import BroadcastMessageFormTextMessage from "./broadcastMessageFormTextMessage/BroadcastMessageFormTextMessage";
import BroadcastMessageFormImageMessageNew from "./broadcastMessageFormImageMessageNew/BroadcastMessageFormImageMessageNew";
import BroadcastMessageFormFileMessageNew from "./broadcastMessageFormFileMessageNew/BroadcastMessageFormFileMessageNew";
import BroadcastMessageFormAdditionalMessage from "./broadcastMessageFormAdditionalMessage/BroadcastMessageFormAdditionalMessage";
// import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
import SectionWrap from "../../reuseableComponent/sectionWrap/SectionWrap";
import BackButton from "../../reuseableComponent/BackButton/BackButton";
import ErrorOrInfoComp from "../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import CalendarPickerSingle from "../../reuseableComponent/calendarPickerSingle/CalendarPickerSingle";
import ButtonWithLoadingOrIcon from "../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
// import CustomSelectOption from "../../reuseableComponent/customSelectOption/CustomSelectOption";
import { checkID, doToast, isEmptyObject, scrollToClass } from "../../../helper/HelperGeneral"; //doToast
import HelperDate from "../../../helper/HelperDate";
import HelperInput from "../../../helper/HelperInput";
import IntegrationWhatsappBusinessServices from "../../../services/integration/IntegrationWhatsappBusinessServices";
import ServiceOrganization from "../../../services/organization/ServiceOrganization";
// import BroadcastMessageServices from "../../../services/newServices/BroadcastMessageServices";
import { FiEdit3, FiEye, FiInfo, FiPlus, FiCalendar, FiClock } from "react-icons/fi";
import iconImage from "../../../assets/img/broadcast-message/icon_image.svg";
import iconFile from "../../../assets/img/broadcast-message/icon_file.svg";
import iconText from "../../../assets/img/broadcast-message/icon_text.svg";
import IconMessage from "../../../assets/img/sidemenu-icon/icon-messages-black.svg";
import Select from "react-select";

// import ServiceQuickReply from "../../../services/newServices/QuickReplyService";

let iconDropOrange = "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
let iconDropGrey = "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

let MAX_MESSAGE = 5;

// let MESSAGE_TYPE = {
//   text: "text",
//   image: "image",
//   file: "file",
//   video: "video"
// }

let MESSAGE_TYPE = [
  {
    type: "text",
    img: iconText,
    text: "Text"
  },
  {
    type: "image",
    img: iconImage,
    text: "Image"
  },
  {
    type: "file",
    img: iconFile,
    text: "File"
  }
]

let CONTACT_TYPE = [
  {
      value: "contact",
      title: "Contact"
  },
  {
      value: "segment",
      title: "Contact Segment"
  }
]

let DEFAULT_BC_DATA = {
  selectedChannel: {
    img: "",
    channel: "",
    text: ""
  },
  name: "",
  channel: {
    label: "",
    value: "",
    channel: ""
  },
  contactType: CONTACT_TYPE[0].value,
  contacts: [],
  customContacts: {},
  segment: "",
  messages: [
    {
      body: "",
      type: "",
      filename: "",
      caption: ""
    }
  ],
  // fileAsset: {
  //   fileURL: "",
  //   fileName: "",
  //   fileData: false,
  //   caption: ""
  // },
  // caption: "",
  // messageType: "",
  messageTemplate: {
    value: "",
    label: "",
    channel: ""
  },
  language: {
    value: "",
    label: "",
    language: ""
  },
  withCase: false,
  scheduleDateSelect: undefined,
  scheduleDate: undefined,
  scheduleTime: "",
  topic: {
    value: "",
    label: ""
  },
  phoneVal: {
    value: "",
    label: ""
  }
};

let BroadcastMessageForm = props => {
  let { match, history, location } = props;
  let [orgID, setOrgID] = useState(false);
  let [messageTemplateList, setMessageTemplateList] = useState([]);
  let [broadcastMessageTemplateLang, setBroadcastMessageTemplateLang] = useState([]);
  let [broadCastMessageData, setBroadcastMessageData] = useState({...DEFAULT_BC_DATA});
  let [broadCastMessageDataOrigin, setBroadcastMessageDataOrigin] = useState({...DEFAULT_BC_DATA});
  let [topicListAgent, setTopicListAgent] = useState({});
  let [isLoadingMessageTemplate, setIsLoadingMessageTemplate] = useState(false);
  let [isLoadingMessageTemplateLang, setIsLoadingMessageTemplateLang] = useState(false);
  let [showDatePicker, setShowDatePicker] = useState();
  let [phoneNumberList, setPhoneNumberList] = useState(false);
  let [errorMessageData, setErrorMessageData] = useState({
    scheduled: "",
    name: "",
    type: "",
    recipient: "",
    messageType: ["", "", "", "", ""],
    messageValue: ["", "", "", "", ""],
    messageTemplate: "",
    messageLanguage: ""
  })
  // let [temporaryFileValue, setTemporaryFileValue] = useState({
  //   fileAsset: {},
  //   time: false
  // })

  let getPhoneNumber = (channelID) => {
    IntegrationWhatsappBusinessServices.getPhoneNumber(match.params.orgID, {channelID: Number(channelID)}, (res) => {
      let _phone = [];
      
      if(res.dataResult.data.items) {
        res.dataResult.data.items.map(v => {
            v.label = v.phoneNumber.split("")[0] === "+" ? "+"+HelperInput.phoneBeautify(v.phoneNumber) : "+"+HelperInput.phoneBeautify(v.phoneNumber);
            v.value = v.id;
            _phone.push(v);
  
            return null;
        })
      }

      if(_phone.length === 1) {
        let _broadCastMessageData = {...broadCastMessageData};
        _broadCastMessageData.phoneVal = _phone[0];

        setBroadcastMessageData(_broadCastMessageData);
      }

      setPhoneNumberList(_phone);
    });
  }

  let onSelectPhone = (val) => {
    let _broadCastMessageData = {...broadCastMessageData};
    _broadCastMessageData.phoneVal = val;

    setBroadcastMessageData(_broadCastMessageData);
}

  let toggleDatePicker = (reset = true) => {
    if(reset) {
      let _broadCastMessageData = {...broadCastMessageData};
      _broadCastMessageData.scheduleDateSelect = _broadCastMessageData.scheduleDate;
      setBroadcastMessageData(_broadCastMessageData);
    }
    setShowDatePicker(!showDatePicker);
  }

  let back = () => {
    // setBroadcastMessageData({...DEFAULT_BC_DATA});
    // props.history.replace('', null);
    history.push({
      pathname: `/o/${orgID}/operational/broadcast-message`,
      state: {
          tab: props.history.location.state.tab
      }
    })
  }

  let goToPreview = (data) => {
    console.log(broadCastMessageData)
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};

    if(props.history.location.state.tab === "scheduled") {
      if(broadCastMessageData.scheduleTime === "" || typeof broadCastMessageData.scheduleDateSelect === "undefined") {
        _errorMessageData.scheduled = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-schedule" }
      } 
    }

    if(broadCastMessageData.name === "") {
        _errorMessageData.name = "This field is required";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-name" }
    }

    if(broadCastMessageData.name.length > 100) {
      _errorMessageData.name = "Characters exceed limit";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-name" }
    }

    if(broadCastMessageData.contacts.length === 0 && broadCastMessageData.segment === "" && JSON.stringify(broadCastMessageData.customContacts) === "{}") {
      _errorMessageData.recipient = "Please select recipient";
      errorCount = errorCount + 1;
      
      if(errorClass === "") { errorClass = "input-recipient" }
    }

    if(broadCastMessageData.contactType === "contact") {
      _errorMessageData.recipient = "Please select recipient type";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-recipient" }
    }

    if(broadCastMessageData.selectedChannel.value === "whatsapp") {
      broadCastMessageData.messages.map((v, i) => {
        if(v.type === "") {
          _errorMessageData.messageType[i] = "Please select message type";
          errorCount = errorCount + 1;
            
          if(errorClass === "") { errorClass = `message-type-${i}` }
        }
  
        if(v.body === "") {
          _errorMessageData.messageValue[i] = "Please input message content";
          errorCount = errorCount + 1;
            
          if(errorClass === "") { errorClass = `message-content-${i}` }
        }
  
        return null;
      })
    }else {
      if(broadCastMessageData.messageTemplate.value === "") {
        _errorMessageData.messageTemplate = "Please select message template";
        errorCount = errorCount + 1;
          
        if(errorClass === "") { errorClass = `message-template` }
      }

      if(broadCastMessageData.language.value === "") {
        _errorMessageData.messageLanguage = "Please select message language";
        errorCount = errorCount + 1;
          
        if(errorClass === "") { errorClass = `message-language` }
      }
    }

    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      setBroadcastMessageDataOrigin(broadCastMessageData);
      setTimeout(() => {
        history.push({
          pathname: `/o/${orgID}/operational/broadcast-message/preview`,
          state: {
            tab: props.history.location.state.tab,
            broadcastMessageData: data,
            topicListAgent: topicListAgent,
          }
        })
      }, 0);
    }
  }

  let doMount = async () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if(response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1) window.location.href = "/organization/new";
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/operational/broadcast-message`);
        }else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  useEffect(() => {
    if(broadCastMessageData.channel.label !== "") {
      if(broadCastMessageData.selectedChannel.value === "whatsappba" && !phoneNumberList) {
        getPhoneNumber(broadCastMessageData.channel.id);
      }
    }
  }, [broadCastMessageData])

  useEffect(() => {
    if(props.history.location.state) {
      // if(props.history.location.state.messageType) {
      //   let _broadCastMessageData = {...broadCastMessageData};
      //   _broadCastMessageData.messageType = props.history.location.state.messageType;
      //   setBroadcastMessageData(_broadCastMessageData); 
      // }

      // console.log(props.history.location.state)
      if(props.history.location.state.broadcastMessageData) {
        let _broadCastMessageData = props.history.location.state.broadcastMessageData;
        let _isNew = props.history.location.state.isNew;
        let _topicListAgent = props.history.location.state.topicListAgent;

        if(_broadCastMessageData.selectedChannel.value === "whatsappba") {
          getMessageTemplateList(_broadCastMessageData);

          if(!_broadCastMessageData.messageTemplate) {
            _broadCastMessageData.messageTemplate = {
              value: "",
              label: "",
              channel: ""
            } 
          }else {
            if(_broadCastMessageData.messageTemplate.value !== "") {
              runTemplateDetail(_broadCastMessageData.channel.id, _broadCastMessageData.messageTemplate.value);
            }
          }

          if(!_broadCastMessageData.language) {
            _broadCastMessageData.language = {
              value: "",
              label: "",
              language: ""
            } 
          }
        }

        if(_isNew) {
          _broadCastMessageData.messages = [
            {
              body: "",
              type: _broadCastMessageData.messages[0].type,
              filename: "",
              caption: ""
            }
          ];
        }

        setBroadcastMessageData(_broadCastMessageData); 
        setBroadcastMessageDataOrigin(_broadCastMessageData); 
        setTopicListAgent(_topicListAgent);

        // if(_broadCastMessageData.selectedChannel.value === "whatsappba") {
        //   getPhoneNumber(_broadCastMessageData.channel.id);
        // }
      }
    }else {
      back();
    }
  }, [props.history])

  useEffect(() => {
    doMount();
  }, [location.pathname]);

  // useEffect(() => {
  //   if(temporaryFileValue.time) {
  //     let _temporaryFileValue = {...temporaryFileValue};
  //     let _broadCastMessageData = {...broadCastMessageData};

  //     _broadCastMessageData.fileAsset.fileName = _temporaryFileValue.fileAsset.fileName;
  //     _broadCastMessageData.fileAsset.fileURL = _temporaryFileValue.fileAsset.fileURL;
  //     _broadCastMessageData.fileAsset.fileData = _temporaryFileValue.fileAsset.fileData;
  //     setBroadcastMessageData(_broadCastMessageData); 
  //   }
  // }, [temporaryFileValue])

  let getMessageTemplateList = (_broadcastData) => {
    setIsLoadingMessageTemplate(true);
    let data = {
      channelID: _broadcastData.channel.id
    }

    IntegrationWhatsappBusinessServices.getTemplateList(props.match.params.orgID, data, (response) => {
      let dataResult = response.dataResult;

      if(dataResult.status !== 200) {
        doToast(dataResult.error.message, "fail");
      }else {
        if(dataResult.data.templates.length !== 0) {
          let _arr = [];
          dataResult.data.templates.map((val) => {
            if(val.isEnabled) {
              val.value = val.id;
              val.label = val.name; 
              val.category = val.categoryCode;
              _arr.push(val);
            }
            
            return null;
          })
          
          setMessageTemplateList(_arr);
        }
      }

      setIsLoadingMessageTemplate(false);
    })
  }

  let onChangeBroadcastMessage = (e, index) => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};
    
    if(e.target.id === "caption") {
      _broadCastMessageData.messages[index]['caption'] = e.target.value;
    }else if(e.target.id === "body") {
      _broadCastMessageData.messages[index]["body"] = e.target.value;
      _errorMessageData.messageValue[index] = "";
      
      setErrorMessageData(_errorMessageData);
    }else {
      _broadCastMessageData[e.target.id] = e.target.value;
      _errorMessageData[e.target.id] = "";
    }

    setErrorMessageData(_errorMessageData);
    setBroadcastMessageData(_broadCastMessageData); 
  };

  let onChangeVariableMessage = (message, target, index) => {
    let _broadCastMessageData = {...broadCastMessageData};

    if(target === "caption") {
      _broadCastMessageData.messages[index]['caption'] = message;
    }else {
      _broadCastMessageData.messages[index]["body"] = message;
    }

    setBroadcastMessageData(_broadCastMessageData); 
  }

  let onChangeAssetFile = (data, index, body = false) => {
    // let _temporaryFileValue = {...temporaryFileValue};
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.messageValue[index] = "";

    // _temporaryFileValue.fileAsset.fileURL = fileData.fileURL;
    // _temporaryFileValue.fileAsset.fileName = fileData.fileName;
    // _temporaryFileValue.fileAsset.fileData = fileData.fileData;
    // _temporaryFileValue.time = new Date().valueOf();
    
    // setTemporaryFileValue(_temporaryFileValue);
    let _broadcastData = {...broadCastMessageData};

    if(_broadcastData.messages[index].type !== "text") {
      if(body) {
        _broadcastData.messages[index].body = body;
      }else {
        _broadcastData.messages[index] = data;
      }
    }
    
    setErrorMessageData(_errorMessageData);
    setBroadcastMessageData(_broadcastData);
  }

  let onRemoveFile = (index) => {
    let _broadCastMessageData = {...broadCastMessageData};

    // let newFileImage = {
    //   fileURL: "",
    //   fileName: "",
    //   fileData: false,
    //   caption: ""
    // };

    _broadCastMessageData.messages[index].filename = "";
    _broadCastMessageData.messages[index].fileData = "";
    _broadCastMessageData.messages[index].body = "";
    _broadCastMessageData.messages[index].caption = "";

    setBroadcastMessageData(_broadCastMessageData); 
  }
  
  // let isButtonSubmitDisabled = () => {
  //   let _broadCastMessageData = {...broadCastMessageData};
  //   let isDisabled = false;
    
  //   if(broadCastMessageData.selectedChannel.value === "whatsappba") {
  //       if(_broadCastMessageData.messageTemplate.value === "") {
  //         isDisabled = true;
  //       }

  //       if(_broadCastMessageData.language.value === "") {
  //         isDisabled = true;
  //       }

  //       if(_broadCastMessageData.phoneVal.value === "") {
  //         isDisabled = true;
  //       }

  //       if(_broadCastMessageData.contactType === "users") {
  //         if(_broadCastMessageData.contacts.length === 0) {
  //           isDisabled = true;
  //         }
  //       }

  //       if(_broadCastMessageData.contactType === "user_segment") {
  //         if(_broadCastMessageData.segment === "") {
  //           isDisabled = true;
  //         }
  //       }

  //       if(_broadCastMessageData.contactType === "contacts") {
  //         if(isEmptyObject(_broadCastMessageData.customContacts)) {
  //           isDisabled = true;
  //         }
  //       }
  //   }else {
  //     _broadCastMessageData.messages.map((val) => {
  //       // if((val.type === MESSAGE_TYPE[0].type && val.body.length === 0) ||
  //       //    (val.type === MESSAGE_TYPE[1].type && val.body === "")
  //       // ) {
  //       if(val.body === "") {
  //         isDisabled = true;
  //       }
        
  //       return null;
  //     })
  //   }

  //   if(_broadCastMessageData.name === "" ||
  //      _broadCastMessageData.channel.value === "" ||
  //      _broadCastMessageData.contactType === ""
  //   ) {
  //     isDisabled = true;
  //   }

  //   if(_broadCastMessageData.contactType === CONTACT_TYPE[0].value && _broadCastMessageData.contacts.length === 0) {
  //     isDisabled = true;
  //   }

  //   if(_broadCastMessageData.contactType === CONTACT_TYPE[1].value && _broadCastMessageData.segment === "") {
  //     isDisabled = true;
  //   }

  //   if(props.history.location.state.tab === "scheduled") {
  //     if(_broadCastMessageData.scheduleDateSelect) {
  //       if(_broadCastMessageData.scheduleTime === "" || !HelperInput.valid24HourFormat(_broadCastMessageData.scheduleTime)) {
  //         isDisabled = true;
  //       }
  //     }
  
  //     if(_broadCastMessageData.scheduleTime !== "") {
  //       if(!_broadCastMessageData.scheduleDateSelect) {
  //         isDisabled = true;
  //       }
  //     }
  //   }

  //   if(_broadCastMessageData.withCase && _broadCastMessageData.topic.value === "") {
  //     isDisabled = true;
  //   }

  //   return isDisabled;
  // }

  // let onSelectChannel = (val) => {
  //   let _broadCastMessageData = {...broadCastMessageData};
  //   _broadCastMessageData.channel = val;
  //   setBroadcastMessageData(_broadCastMessageData); 
  // }
  let onChangeCsvCustomContacts = (csvData) => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};

    _errorMessageData.recipient = "";
    _broadCastMessageData.customContacts = csvData;

    setErrorMessageData(_errorMessageData);
    setBroadcastMessageData(_broadCastMessageData); 
  }

  let onChangeCsvDataProps = (csvData) => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};

    _errorMessageData.recipient = "";
    _broadCastMessageData.contacts = csvData;

    setErrorMessageData(_errorMessageData);
    setBroadcastMessageData(_broadCastMessageData); 
  }

  let onChangeContactType = (val) => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};

    _errorMessageData.recipient = "";
    _broadCastMessageData.contactType = val;
    _broadCastMessageData.segment = "";
    _broadCastMessageData.contacts = [];
    _broadCastMessageData.customContacts = {};
    setErrorMessageData(_errorMessageData);
    setBroadcastMessageData(_broadCastMessageData); 
  }

  let onChangeMultipleMessageType = (val, idx) => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};

    _errorMessageData.messageType[idx] = "";
    _errorMessageData.messageValue[idx] = "";
    setErrorMessageData(_errorMessageData);
    
    _broadCastMessageData.messages[idx] = {
      type: val,
      body: "",
      filename: "",
      caption: ""
    }
    
    setBroadcastMessageData(_broadCastMessageData); 
  }

  let onDeleteMultipleMessage = (idx) => {
    let _broadCastMessageData = {...broadCastMessageData};
    _broadCastMessageData.messages.splice(idx, 1);
    let _errorMessageData = {...errorMessageData};

    _errorMessageData.messageType = ["", "", "", "", ""];
    _errorMessageData.messageValue = ["", "", "", "", ""];
    setErrorMessageData(_errorMessageData);
    setBroadcastMessageData(_broadCastMessageData); 
  }

  let onChangeSegment = (val) => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};

    _errorMessageData.recipient = "";
    _broadCastMessageData.segment = val;

    setErrorMessageData(_errorMessageData);
    setBroadcastMessageData(_broadCastMessageData); 
  }

  let onChangeSwitchCase = (e) => {
    let _broadCastMessageData = {...broadCastMessageData};
    _broadCastMessageData.withCase = e.target.checked;
    setBroadcastMessageData(_broadCastMessageData)
  }

  let onCLickMessageTemplate = (template) => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};

    _errorMessageData.messageTemplate = "";

    setErrorMessageData(_errorMessageData);
    _broadCastMessageData.messageTemplate.value = template.value;
    _broadCastMessageData.messageTemplate.label = template.label;
    _broadCastMessageData.messageTemplate.channel = template;

    _broadCastMessageData.language = {
      value: "",
      label: "",
      language: ""
    };
    
    setBroadcastMessageData(_broadCastMessageData); 

    runTemplateDetail();
  }

  let onClickTemplateLang = (lang) => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};

    _errorMessageData.messageLanguage = "";

    setErrorMessageData(_errorMessageData);
    _broadCastMessageData.language = {
      value: lang.value,
      label: lang.label,
      language: lang
    };
    
    setBroadcastMessageData(_broadCastMessageData)
  }

  let runTemplateDetail = (channelID = false, templateID = false) => {
    setIsLoadingMessageTemplateLang(true);
    // let _retriveDataError = {...retriveDataError};
    let data = {
        channelID: channelID ? channelID : broadCastMessageData.channel.id,
        templateID: templateID ? templateID : broadCastMessageData.messageTemplate.value
    }

    IntegrationWhatsappBusinessServices.getTemplateDetail(props.match.params.orgID, data, (response) => {
      let dataResult = response.dataResult;
      if (dataResult.status === 200) {
          let _languageList = [];
          let languages = dataResult.data.languages;
          
          if (languages.length > 0) {
            languages.map((lang, idx) => {
              lang.value = idx;
              lang.label = lang.languageName;
              _languageList.push(lang);

              return null;
            });
            
            if(_languageList.length === 1) {
              let _broadCastMessageData = {...broadCastMessageData};
              _broadCastMessageData.language = {
                value: _languageList[0].value,
                label: _languageList[0].label,
                language: _languageList[0]
              };
              
              setBroadcastMessageData(_broadCastMessageData)
            }

            setBroadcastMessageTemplateLang(_languageList);
          }
      } else {
          doToast(dataResult.error.message, "fail");
      }

      setIsLoadingMessageTemplateLang(false);
    })
  }

  let styleSelect = () => ({
    indicatorsContainer: provide => ({
        ...provide,
        height: "48px",
    }),
    valueContainer: provide => ({
        ...provide,
        height: "100%"
    }),
    clearIndicator: () => ({
        // display: "none"
    }),
    dropdownIndicator: (provided, { isFocused }) => ({
        ...provided,
        background: isFocused ? iconDropOrange : iconDropGrey,
        transition: "0.25s ease-in-out",
        color: "transparent !important",
        width: "24px",
        height: "24px",
        // transform: isFocused ? "rotate(-180deg)" : ""
    }),
    container: (provided) => ({
        ...provided,
        width: "300px"
    }),
    control: (provide, { isFocused }) => ({
        ...provide,
        padding: "0 5px",
        // height: "48px",
        borderRadius: "8px",
        border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
        boxShadow: "none !important",
        marginBottom: "24px",
        cursor: "pointer"
    }),
    indicatorSeparator: provide => ({ ...provide, display: "none" }),
        menu: provide => ({
        ...provide,
        borderRadius: "8px",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
        overflow: "hidden"
    }),
    option: (provide, state) => {
        let { isSelected } = state;
        let checked = {
            color: "#ff7e00",
            content: '"✔"',
            position: "absolute",
            left: "0",
            top: "50%",
            transform: "translate(100%, -50%)"
        };
        return {
            ...provide,
            position: "relative",
            background: "#fff",
            padding: "12px 15px 12px 35px",
            cursor: "pointer",
            color: isSelected ? "#ff7e00" : "#121212",
            ":hover": { background: "#ffe7d0" },
            ":before": isSelected ? checked : null
        };
    }
  })

  let submmitDateFilter = () => {
    let _broadCastMessageData = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.scheduled = "";

    setErrorMessageData(_errorMessageData);
    _broadCastMessageData.scheduleDate = _broadCastMessageData.scheduleDateSelect;
    setBroadcastMessageData(_broadCastMessageData);
    toggleDatePicker(false);
  }

  let handleDateFilterClick = (d, {disabled}) => {
    if(!disabled) {
      let _broadCastMessageData = {...broadCastMessageData};
      _broadCastMessageData.scheduleDateSelect = d;
      setBroadcastMessageData(_broadCastMessageData);
    }
  }
  
  let clearDatePicker = () => {
    let _broadCastMessageData = {...broadCastMessageData};
    _broadCastMessageData.scheduleDateSelect = undefined;
    _broadCastMessageData.scheduleDate = undefined;
    setBroadcastMessageData(_broadCastMessageData);
    setShowDatePicker(false);
  }

  let onChangeTime = (e) => {
    let data = {...broadCastMessageData};
    let value = e.target.value;
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.scheduled = "";
    
    if(value.length === 2 && !value.includes(":")) {
        value = value + ":";
    }
    
    if(value.length === 5 && !HelperInput.valid24HourFormat(value)) {
      value = "00:00";
    }

    data[e.target.id] = value; 
    setErrorMessageData(_errorMessageData);       
    setBroadcastMessageData(data);
}

let onKeyPressChangeTime = (e) => {
    let data = {...broadCastMessageData};
    let _errorMessageData = {...errorMessageData};
    _errorMessageData.scheduled = "";

    setErrorMessageData(_errorMessageData);
    let value = e.target.value;
    if(e.keyCode === 8 && (data[e.target.id].includes(":") && data[e.target.id].length === 3)) {
        data[e.target.id] = value[0];    
        setBroadcastMessageData(data);
        e.preventDefault();
    }
}

let onSelectTopic = (val) => {
  let _broadCastMessageData = {...broadCastMessageData};
  _broadCastMessageData.topic = val;
  setBroadcastMessageData(_broadCastMessageData);
}

let getListTopic = () => {
  let list = [];
  let _broadCastMessageData = {...broadCastMessageData};
  if(_broadCastMessageData.channel.topics) {
      Object.keys(topicListAgent).map(v => {
          if(_broadCastMessageData.channel.topics.findIndex(e => e.id === parseInt(v)) !== -1) {
              list.push(topicListAgent[v]);
          }

          return null;
      })
  }

  return list;
}

return (
    <React.Fragment>
        <SectionWrap
            {...props}
            orgID={orgID}
            withHeader
            withSideMenu
            dataOrigin={broadCastMessageDataOrigin}
            dataAfterChange={broadCastMessageData}
        >   
            <BackButton text="Back to Broadcast Message" onClick={back} />
            
            <CalendarPickerSingle
              isOpen={showDatePicker}
              toggle={toggleDatePicker}
              date={broadCastMessageData.scheduleDateSelect}
              handleDateFilterClick={handleDateFilterClick}
              submmitDateFilter={submmitDateFilter}
              clearDatePicker={clearDatePicker}
              disabledDays={{
                before: new Date(),
                after: new Date(new Date().setMonth(new Date().getMonth()+6))
              }}
              {...props}
            />

            <div className="sectionWrap form-broadcast-message">
                <div className="form-title-wrapper">
                    <FiEdit3 />
                    <b>New Message</b>
                    <p>
                      Send a new message to multiple recipients at once through one of your selected active channels.
                    </p>
                </div>

                {props.history.location.state.tab === "scheduled" &&
                  <div className="broadcast-message-schedule">
                    <p className={`broadcast-schedule-title input-schedule`}>
                      <FiCalendar />
                      <b>Schedule Broadcast</b>
                    </p>

                    <div className="broadcast-schedule-time-wrapper">
                      <div className="broadcast-date-select-wrapper">
                        <label><b>Date</b></label>
                        <div className={`broadcast-date-value-wrapper ${errorMessageData.scheduled !== "" ? "border-red" : ""}`} onClick={toggleDatePicker}>
                          <FiCalendar />
                          {broadCastMessageData.scheduleDate ?
                            HelperDate.formatToString(new Date(broadCastMessageData.scheduleDate), "dd MMM yyyy")
                            :  
                            <span className="grey-font">
                              Select Date
                            </span>
                          }
                        </div>
                      </div>

                      <div className="broadcast-time-input-wrapper">
                        <label><b>Time</b></label>
                        <div className="broadcast-time-value-wrapper">
                          <FiClock />

                          <input 
                            id="scheduleTime"
                            type="text" 
                            value={broadCastMessageData.scheduleTime} 
                            placeholder="00:00"
                            maxLength={5}
                            onKeyDown={(e) => onKeyPressChangeTime(e)}
                            onChange={(e) => onChangeTime(e)}
                            className={errorMessageData.scheduled !== "" ? "border-red" : ""}
                          />
                        </div>
                      </div>
                      {/* <p className="time-select-info">
                        <FiInfo />
                        <b>Jakarta (GMT+07:00) Western Indonesia Time</b>
                      </p> */}

                      <ErrorOrInfoComp 
                        _className={errorMessageData.scheduled !== "" ? "font-red" : ""}
                        icon={<FiInfo />}
                        text={errorMessageData.scheduled !== "" ? errorMessageData.scheduled : `Jakarta (GMT+07:00) Western Indonesia Time`}
                      />
                    </div>
                  </div>
                }
            
                <div className="form-content-wrapper">
                    <p className="broadcast-info-separator">
                      <FiInfo />
                      <b>Broadcast Info &amp; Recipients</b>
                    </p>

                    <label><b>Channel Account</b></label>
                    <div className="message-channel-field">
                      <img src={broadCastMessageData.selectedChannel.img} alt="" />
                      {broadCastMessageData.channel.label}
                    </div>

                    {broadCastMessageData.selectedChannel.value === "whatsappba" &&
                      <>
                        <label>
                            <b>Phone Number</b>
                        </label>

                        <CustomSelectOption
                            isDisabled={false}
                            optionListProps={phoneNumberList}
                            valueDropdownProps={broadCastMessageData.phoneVal}
                            placeholderProps={"Select Phone Number"}
                            onClickDropDownListOptionProps={onSelectPhone}
                            specificClassNameProps="custom-select-phonenumber"
                        />
                      </>
                    }

                    <label className="pos-relative">
                      <b>Broadcast Name</b>
                      <span className={errorMessageData.name === "Characters exceed limit" ? "red-text absolute-right-0" : "grey-font absolute-right-0"}>{broadCastMessageData.name.length}/50</span>
                    </label>
                    <input type="text" 
                      placeholder="Type broadcast name" 
                      value={broadCastMessageData.name}
                      id="name" 
                      onChange={(e) => {
                        onChangeBroadcastMessage(e);
                      }}
                      className={`input-name ${errorMessageData.name !== "" ? "border-red" : ""}`}
                    />
                    {errorMessageData.name !== "" &&
                      <ErrorOrInfoComp 
                        _className={errorMessageData.name !== "" ? "font-red" : ""}
                        icon={<FiInfo />}
                        text={errorMessageData.name}
                      />
                    }

                    <BroadcastMessageFormCsvPart
                      csvDataProps={broadCastMessageData.contacts}
                      onChangeCsvCustomContacts={onChangeCsvCustomContacts}
                      onChangeCsvDataProps={onChangeCsvDataProps}
                      onChangeContactType={onChangeContactType}
                      onChangeSegment={onChangeSegment}
                      parentProps={props}
                      broadCastMessageDataProps={broadCastMessageData}
                      _className={'input-recipient'}
                    />
                    {errorMessageData.recipient !== "" &&
                      <ErrorOrInfoComp 
                        _className={errorMessageData.recipient !== "" ? "font-red" : ""}
                        icon={<FiInfo />}
                        text={errorMessageData.recipient}
                      />
                    }

                    <div className="message-part">
                      <p className="message-content-separator">
                        <img src={IconMessage} alt="" />
                        <b>Message Content</b>
                      </p>
                      
                      {broadCastMessageData.selectedChannel.value === "whatsapp" &&
                        <>
                          <label className={`message-type-0`}><b>Message Type</b></label>
                          {/* <div className="message-type-field">
                            <img src={broadCastMessageData.messages[0].type === MESSAGE_TYPE[0].type ? iconText : (broadCastMessageData.messages[0].type === MESSAGE_TYPE[2].type ? iconFile : iconImage)} alt="" />
                            {broadCastMessageData.messages[0].type === MESSAGE_TYPE[0].type ? "Text" : (broadCastMessageData.messages[0].type === MESSAGE_TYPE[2].type ? "File" : "Image")}
                          </div> */}

                          <div className="more-message-type-selection-wrapper">
                            {MESSAGE_TYPE.map((_val, _idx) => {
                              return (
                                  <div 
                                      className={`message-type-selection-selection ${broadCastMessageData.messages[0].type === _val.type ? "active-message-type-selection-selection" : ""}`} 
                                      key={`message-type-selection-${_idx}`} 
                                      onClick={() => onChangeMultipleMessageType(_val.type, 0)}
                                  >
                                      <div className={`message-type-selection-icon-wrapper message-type-selection-${_val.type}`}>
                                          <img src={_val.img} alt="" />
                                      </div>

                                      {_val.text}
                                  </div>
                              )
                            })}
                          </div>

                          {errorMessageData.messageType[0] !== "" &&
                            <ErrorOrInfoComp 
                              _className={errorMessageData.messageType[0] !== "" ? "font-red" : ""}
                              icon={<FiInfo />}
                              text={errorMessageData.messageType[0]}
                            />
                          }
                        </>
                      }
                      
                      {broadCastMessageData.selectedChannel.value === "whatsapp" &&
                        <>
                          <div className={`message-content-0`} />
                          {broadCastMessageData.messages[0].type === MESSAGE_TYPE[0].type &&
                            <BroadcastMessageFormTextMessage 
                              broadCastMessageDataProps={broadCastMessageData}
                              onChangeBroadcastMessageProps={onChangeBroadcastMessage}
                              onChangeVariableMessageProps={onChangeVariableMessage}
                              _index={0}
                            />
                          }

                          {broadCastMessageData.messages[0].type === MESSAGE_TYPE[1].type &&
                            <BroadcastMessageFormImageMessageNew 
                              orgIDProps={orgID}
                              onRemoveFileAssetProps={onRemoveFile}
                              broadCastMessageDataProps={broadCastMessageData}
                              onChangeAssetFileProps={onChangeAssetFile}
                              onChangeBroadcastMessageProps={onChangeBroadcastMessage}
                              onChangeVariableMessageProps={onChangeVariableMessage}
                              _index={0}
                            />
                          }

                          {broadCastMessageData.messages[0].type === MESSAGE_TYPE[2].type &&
                            <BroadcastMessageFormFileMessageNew 
                              orgIDProps={orgID}
                              onRemoveFileAssetProps={onRemoveFile}
                              broadCastMessageDataProps={broadCastMessageData}
                              onChangeAssetFileProps={onChangeAssetFile}
                              onChangeBroadcastMessageProps={onChangeBroadcastMessage}
                              _index={0}
                            />
                          }

                          {(errorMessageData.messageValue[0] !== "" && errorMessageData.messageType[0] === "") &&
                            <ErrorOrInfoComp 
                              _className={errorMessageData.messageValue[0] !== "" ? "font-red" : ""}
                              icon={<FiInfo />}
                              text={errorMessageData.messageValue[0]}
                            />
                          } 
                        </>
                      } 
                      
                      {broadCastMessageData.selectedChannel.value === "whatsappba" &&
                        <div className="no-height">
                          <label className="message-template"><b>Message Template</b></label>
                          {/* <CustomSelectOption
                              isLoading={isLoadingMessageTemplate}
                              isDisabled={isLoadingMessageTemplate}
                              optionListProps={messageTemplateList}
                              valueDropdownProps={broadCastMessageData.messageTemplate}
                              placeholderProps={"Please select"}
                              onClickDropDownListOptionProps={onCLickMessageTemplate}
                              specificClassNameProps="custom-select-message-template"
                          /> */}
                          <div className="message-template-wrapper">
                            <Select 
                                options={messageTemplateList} 
                                placeholder="Please select" 
                                onChange={(e) => onCLickMessageTemplate(e)}
                                styles={styleSelect()}
                                classNamePrefix="react-select-broadcast"
                                isDisabled={isLoadingMessageTemplate}
                                value={messageTemplateList.filter(
                                  (obj) => obj.value === broadCastMessageData.messageTemplate.value
                                )}
                                blurInputOnSelect={true}
                                _className={`input-path ${errorMessageData.messageTemplate !== "" ? "border-red" : ""}`}
                            />

                            <ErrorOrInfoComp
                              text={errorMessageData.messageTemplate !== "" ? errorMessageData.messageTemplate : "Message templates that has been approved will be displayed."}
                              _className={errorMessageData.messageTemplate !== "" ? "font-red" : ""}
                              icon={<FiInfo />}
                            />
                          </div>
                          
                          <label className="message-language"><b>Language</b></label>
                          {/* <CustomSelectOption
                              isLoading={isLoadingMessageTemplateLang}
                              isDisabled={
                                broadCastMessageData.messageTemplate.value === "" ||
                                isLoadingMessageTemplate ||
                                isLoadingMessageTemplateLang
                              }
                              optionListProps={broadcastMessageTemplateLang}
                              valueDropdownProps={broadCastMessageData.language}
                              placeholderProps={"Please select"}
                              onClickDropDownListOptionProps={onClickTemplateLang}
                              specificClassNameProps="custom-select-message-template"
                            /> */}
                          <Select 
                              options={broadcastMessageTemplateLang} 
                              placeholder={`${broadCastMessageData.messageTemplate.value === "" ? 'Select message template first' : 'Please select'}`} 
                              onChange={(e) => onClickTemplateLang(e)}
                              styles={styleSelect()}
                              classNamePrefix="react-select-broadcast"
                              isDisabled={isLoadingMessageTemplateLang || broadCastMessageData.messageTemplate.value === ""}
                              value={broadcastMessageTemplateLang.filter(
                                (obj) => obj.value === broadCastMessageData.language.value
                              )}
                              blurInputOnSelect={true}
                          />
                          {errorMessageData.messageLanguage !== "" &&
                            <ErrorOrInfoComp
                              text={errorMessageData.messageLanguage !== "" ? errorMessageData.messageLanguage : "Message templates that has been approved will be displayed."}
                              _className={errorMessageData.messageLanguage !== "" ? "font-red" : ""}
                              icon={<FiInfo />}
                            />
                          }
                        </div>
                      }

                      {broadCastMessageData.selectedChannel.value === "whatsapp" &&
                        <>
                          {broadCastMessageData.messages.map((val, idx) => {
                            return (
                              idx > 0 &&
                                <BroadcastMessageFormAdditionalMessage 
                                  onChangeMultipleMessageType={onChangeMultipleMessageType}
                                  broadCastMessageData={broadCastMessageData}
                                  onChangeBroadcastMessage={onChangeBroadcastMessage}
                                  onChangeVariableMessage={onChangeVariableMessage}
                                  onDeleteMultipleMessage={onDeleteMultipleMessage}
                                  orgIDProps={orgID}
                                  onChangeAssetFile={onChangeAssetFile}
                                  onRemoveFile={onRemoveFile}
                                  index={idx}
                                  _errorMessageData={errorMessageData}
                                />
                            )
                          })}

                          {MAX_MESSAGE !== broadCastMessageData.messages.length &&
                            <button 
                              className="add-more-message-button"
                              onClick={() => {
                                let _broadcastData = {...broadCastMessageData};
                                _broadcastData.messages.push({
                                  type: "text",
                                  body: "",
                                  filename: "",
                                  caption: ""
                                })
                                setBroadcastMessageData(_broadcastData);
                              }}
                            >
                              <b><FiPlus /> Add More Message</b>
                            </button>
                          }
                        </>
                      }
                    </div>

                    {/* {broadCastMessageData.selectedChannel.value !== "whatsappba" && */}
                      <div className="with-case-wrapper">
                        <label><b>Create Message as Case</b> <span className="grey-font">(optional)</span></label>
                        <p>
                          Create new case if the recipient has no ongoing case yet, or send the message to the ongoing case.
                        </p>

                        <label className="custom-switch-wrapper">
                            <input type="checkbox" onChange={(e) => onChangeSwitchCase(e)} checked={broadCastMessageData.withCase} />
                            <span className="custom-switch round"></span>
                        </label>
                      </div>
                    {/* } */}
                      {broadCastMessageData.withCase &&
                        <>
                          <label>
                            <b>Topic</b>
                          </label>

                          <CustomSelectOption
                              optionListProps={getListTopic()}
                              valueDropdownProps={broadCastMessageData.topic}
                              placeholderProps={"Select Topic"}
                              onClickDropDownListOptionProps={onSelectTopic}
                              specificClassNameProps="custom-select-broadcastchannel"
                          />

                          <p className="input-info-text">
                            <FiInfo />
                            <b>Incoming messages on this channel will be assigned to this topic.</b>
                          </p>
                        </>
                      }
                </div>

                <ButtonWithLoadingOrIcon 
                    className="orange-button main-button-40"
                    text="Preview Message"
                    icon={{
                      type: "svg",
                      src: FiEye
                    }}
                    position="left"
                    // isDisabled={isButtonSubmitDisabled()}
                    onClickAction={() => goToPreview(broadCastMessageData)}
                />
            </div>
        </SectionWrap>
    </React.Fragment>
  );
};

export default BroadcastMessageForm;