import axios from 'axios';
import { CONFIG, ERROR_RESPONSE } from '../config';
import HelperGetLocalStorage from '../helper/HelperGetLocalStorage';
import HelperCustomEncryptor from '../helper/HelperCustomEncryptor';
import { taptalk } from '@taptalk.io/web-sdk';
import WebWorker from "../helper/HelperWebWorker";

let dashRefreshAccessTokenCallbackArray = [];
let isRunRefreshToken = false;
let hashMapOfCallbacks = {};

let userDataBaseService = HelperGetLocalStorage.getLocalStorageData('user') !== null ? 
            HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data)
            : 
            null;

//new webworker
let webWorkerBaseService =  new WebWorker(() => {
  // eslint-disable-next-line no-restricted-globals
  self.addEventListener('message', function(e) {
      let { url, param, header, callbackID, responseType } = e.data; 
      fetch(url, {
          method: 'POST',
          headers: header,
          body: param
      })
      .then(function(response) {
          return response.json(); // .text();
      })
      .then(function(myJson) {
          let responseStatus = myJson.status;
          
          if(responseStatus === 401) {
              if(myJson.error.code === "40104") {
                  //eslint-disable-next-line no-restricted-globals
                  self.postMessage({
                      refreshSession: true,
                      url: url, 
                      param: param, 
                      header: header, 
                      callbackID: callbackID,
                      responseType: responseType
                  })
              }else {
                  //run kick session
                  //eslint-disable-next-line no-restricted-globals
                  self.postMessage({
                      kickSession: true
                  })
              }
          }else {
              //eslint-disable-next-line no-restricted-globals
              self.postMessage({
                  response: myJson,
                  callbackID: callbackID
              })
          }
      });
  });
});

let generateRandomID = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
};

let pushToHashMapOfCallbacks = (callback) => {
  let uniqueID = generateRandomID(10) + generateRandomID(10);

  if(hashMapOfCallbacks[uniqueID]) {
    pushToHashMapOfCallbacks(callback);
  }else {
    hashMapOfCallbacks[uniqueID] = callback;

    return uniqueID;
  }
};

let runCallBackWithUniqueID = (id, response) => {
  hashMapOfCallbacks[id](response);
  delete hashMapOfCallbacks[id];
};

webWorkerBaseService.addEventListener('message', (e) => {
  let _baseService = new BaseService();
  let { response, callbackID, kickSession, refreshSession, url, param, header, responseType } = e.data;
  
  if(kickSession || refreshSession) {
      if(kickSession) {
          if(taptalk.isAuthenticated()) {
            taptalk.logoutAndClearAllTapTalkData({
              onSuccess: (response) => {
                console.log(response)
              }
            });
          }
  
          localStorage.removeItem("persist:root");
          window.location.href = "/login";
      }

      if(refreshSession) {
          _baseService.doRefreshAccessToken(header, () => {
              _baseService.doPostAccessToken(url, (typeof param !== "object" ? JSON.parse(param) : param), header, null, responseType, callbackID);
          });
      }
  }else {
      if(callbackID !== null) {
          runCallBackWithUniqueID(callbackID, {dataResult: response});
      }
  }
});
//new webworker

class BaseService {
  // constructor() {
    // this.userDataBaseService =
    //   HelperGetLocalStorage.getLocalStorageData('user') !== null? 
    //     HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data)
    //     : 
    //     null;
  // }

  generateErrorMessage(error, url = "") {
    let errorResponse;

    let setErrorResult = (message, code) => {
      errorResponse = {
        data: {},
        error: {
          code: code,
          message: message,
        },
      };
    };

    if (error.code === 'ECONNABORTED') {
      setErrorResult(ERROR_RESPONSE.TIMEOUT, 408);
    } else if (error.response) {
      console.log(`${error.response.status} ${error.response.statusText}`);
      setErrorResult(ERROR_RESPONSE.ERROR_WITH_CODE, 0);
    } else {
      setErrorResult(ERROR_RESPONSE.NO_INTERNET_CONNECTION, 502);
    }

    console.log("url", url)
    console.log("error", error)

    return {
      dataResult: errorResponse,
    };
  }

  doPost(apiURL, param, header = null) {
    return axios.post(CONFIG.requestURL + apiURL, param, {
      headers: header === null ? CONFIG.headers : header,
      timeout: 1800000,
    });
  }

  doGet(apiURL) {
    return axios.get(CONFIG.requestURL + apiURL, {
      headers: CONFIG.headers,
      timeout: 1800000,
    });
  }

  doGetCustomHeader(apiURL, headers, callback) {
    return axios.get(apiURL, {
      headers: headers,
      timeout: 1800000,
    }).then((response) => {
      callback(response);
    });
  }

  doPostCustomHeader(apiURL, data, headers, callback) {
    return axios.post(apiURL, data, {
      headers: headers,
      timeout: 1800000,
    }).then((response) => {
      callback(response);
    });
  }

  doPostAccessToken(apiURL, param, header, callback = null, responseType = null, callbackID = null) {
    let callbackUniqueID = "";

    if(userDataBaseService !== null) {
        header.Authorization = "Bearer " +userDataBaseService.accessToken;
    }

    callbackUniqueID = callbackID !== null ? callbackID : (callback !== null ? pushToHashMapOfCallbacks(callback) : null);
    
    webWorkerBaseService.postMessage({
        url: apiURL.includes(CONFIG.requestURL) ? apiURL : CONFIG.requestURL+apiURL,
        param: JSON.stringify(param),
        header: header,
        callbackID: callbackUniqueID,
        responseType: responseType
    })
  }

  doPostAccessTokenNoWebWorker(apiURL, param, header, callback = null, responseType = null) {
    if (userDataBaseService !== null) {
      header.Authorization = 'Bearer ' + userDataBaseService.accessToken;
    }

    return axios
      .post(CONFIG.requestURL + apiURL, param, {
        headers: header,
        timeout: 1800000,
        responseType: responseType !== null ? responseType : 'json',
      })
      .then((response) => {
        let convertToBase64 = (_data) => {
          let uInt8Array = new Uint8Array(_data);
          let i = uInt8Array.length;
          let binaryString = new Array(i);

          while (i--) {
            binaryString[i] = String.fromCharCode(uInt8Array[i]);
          }

          let data = binaryString.join('');

          let base64 = window.btoa(data);

          return base64;
        };

        let responseData = response.data;
        let responseStatus = responseData.status;

        let result = {
          dataResult:
            responseType === 'arraybuffer'
              ? {
                  status: response.status,
                  value: convertToBase64(response.data),
                }
              : responseData,
        };

        if (responseStatus === 401) {
          if (responseData.error.code === '40104') {
            this.doRefreshAccessToken(header, () => {
              this.doPostAccessToken(
                apiURL,
                param,
                header,
                callback,
                responseType
              );
            });
          } else {
            //run kick session
            if (taptalk.isAuthenticated()) {
              taptalk.logoutAndClearAllTapTalkData({
                onSuccess: (response) => {
                  console.log(response);
                },
              });
            }

            localStorage.removeItem('persist:root');
            window.location.href = '/login';
          }
        } else {
          if (callback !== null) {
            callback(result);
          }
        }
      })
      .catch((error) => {
        callback(this.generateErrorMessage(error, apiURL))
      });
  }

  doPostWithoutAccessTokenWithCallback(apiURL, param, header, callback) {
    return axios
      .post(CONFIG.requestURL + apiURL, param, {
        headers: header,
        timeout: 1800000,
        responseType: 'json',
      })
      .then((response) => {
        let responseData = response.data;
        let result = {
          dataResult: responseData,
        };

        callback(result);
      })
  }

  doPostWithoutAccessToken(apiURL, param, header) {
    return axios.post(apiURL, param, {
      headers: header,
      timeout: 1800000,
    });
  }

  doGetWithoutAccessToken(apiURL, param, header) {
    return axios.get(apiURL, param, {
      headers: header,
      timeout: 1800000,
    });
  }

  //refresh token
  doRefreshAccessToken(header, callback, attempt = false) {
    let runApiRefreshAccessToken = () => {
      header.Authorization = 'Bearer ' + userDataBaseService.refreshToken;

      setTimeout(() => {
        axios
          .post(
            CONFIG.requestURL + URL_REFRESH_TOKEN,
            {},
            {
              headers: header,
              timeout: 1800000,
            }
          )
          .then((response) => {
            let responseData = response.data;

            if (responseData.error.code === '') {
              let persistRoot = JSON.parse(localStorage.getItem('persist:root'));

              // responseData.data.activeOrganization = USER_DATA.activeOrganization;
              persistRoot.user = JSON.stringify({
                data: HelperCustomEncryptor.doEncrypt(responseData.data),
              });

              localStorage.setItem('persist:root', JSON.stringify(persistRoot));
              userDataBaseService = responseData.data;

              runCallbackRefreshToken();
            } else {
              //run kick session
              let runKick = () => {
                if (taptalk.isAuthenticated()) {
                  taptalk.logoutAndClearAllTapTalkData({
                    onSuccess: (response) => {
                      console.log(response);
                    },
                  });
                }
  
                localStorage.removeItem('persist:root');
                window.location.href = '/login';
              }
              
              if(!attempt) {
                runKick();
              }else {
                if(attempt < 6) {
                  let _attempt = attempt + 1;

                  this.doRefreshAccessToken(header, callback, _attempt);
                }else {
                  runKick();
                }
              }
            }
          });
      }, 100);
    };

    if (!isRunRefreshToken) {
      isRunRefreshToken = true;
      runApiRefreshAccessToken();
    }

    let runCallbackRefreshToken = () => {
      if (dashRefreshAccessTokenCallbackArray.length > 0) {
        dashRefreshAccessTokenCallbackArray[0]();
        dashRefreshAccessTokenCallbackArray.shift();
        runCallbackRefreshToken();
      } else {
        isRunRefreshToken = false;
        return;
      }
    };

    if (callback !== null) {
      dashRefreshAccessTokenCallbackArray.push(callback);
    }
  }
}

const URL_REFRESH_TOKEN = '/auth/access_token/refresh';

export default new BaseService();
