import { FiAlertCircle, FiCheck } from "react-icons/fi";
import React from "react";
import { toast } from "react-toastify";
import ServiceOrganization from "../services/organization/ServiceOrganization";
import { md5 } from "./hashing";
import HelperCustomEncryptor from "./HelperCustomEncryptor";
import HelperGetLocalStorage from "./HelperGetLocalStorage";
import HelperInput from "./HelperInput";

let checkClickOutside = (elPath, exlude) => {
  let isOutside = true;

  elPath.map((val, index) => {
    if(val instanceof Element) {
      if(val.className && typeof val.className !== "object") {
        if(val.className.includes(exlude)) {
          isOutside = false;
        }
      }
    }

    return null;
  })

  return isOutside;
}

let checkID = match => {
  let { orgID: param } = match.params;
  // if (!param) getOrganization();
  if (!param) return false;
  else return param;
};

let numberOnly = (val) => {
  let REG_NUMERIC = /^[0-9]*$/;

  return REG_NUMERIC.test(val);
}

let getOrganization = async () => {
  let { dataResult } = await ServiceOrganization.postGetOrganization();
  let { status, data } = dataResult;
  let error = status !== 200;
  if (data.memberships.length < 1) window.location.href = "/organization/new";
  if (error) return false;
  let { id, createdTime } = data.memberships[0].organization;
  let urlOrgID = `${id}-${createdTime}`;
  return urlOrgID;
  // history.push(`topics/${urlOrgID}`);
};

let getSplitID = (type, id) => {
  let idSplit = id.split("-");
  if (type === "id") return idSplit[0];
  return idSplit[1];
};

let doToast = (text = "YOUR TOAST", _className = null) => {
  let config = {
    autoClose: 3000,
    position: "bottom-left",
    className: _className === null ? "ToastContent" : "ToastContent-"+_className,
    hideProgressBar: true
  };

  if(_className === "no-icon") {
    toast(text, config);
  } else if(_className === "fail") {
    toast(
      <span>
        <FiAlertCircle />
        {" " + text}
      </span>
    , config);
  }else {
    toast(
    <span>
      <FiCheck />
      {" " + text}
    </span>, 
    config);
  }
};

let CSVToArray = (strData,strDelimiter ) => {
  // Check to see if the delimiter is defined. If not,
  // then default to comma.
  var strMatchedValue;
  strDelimiter = (strDelimiter || ",");

  // Create a regular expression to parse the CSV values.
  var objPattern = new RegExp(
    (
      // Delimiters.
      "(\\" + strDelimiter + "|\\r?\\n|\\r|^)" +

      // Quoted fields.
      "(?:\"([^\"]*(?:\"\"[^\"]*)*)\"|" +

      // Standard fields.
      "([^\"\\" + strDelimiter + "\\r\\n]*))"
    ),
    "gi"
    );


  // Create an array to hold our data. Give the array
  // a default empty first row.
  var arrData = [[]];

  // Create an array to hold our individual pattern
  // matching groups.
  var arrMatches = null;


  // Keep looping over the regular expression matches
  // until we can no longer find a match.
  while (arrMatches = objPattern.exec( strData )){

    // Get the delimiter that was found.
    var strMatchedDelimiter = arrMatches[ 1 ];

    // Check to see if the given delimiter has a length
    // (is not the start of string) and if it matches
    // field delimiter. If id does not, then we know
    // that this delimiter is a row delimiter.
    if (
      strMatchedDelimiter.length &&
      (strMatchedDelimiter !== strDelimiter)
    ){

      // Since we have reached a new row of data,
      // add an empty row to our data array.
      arrData.push( [] );

    }


    // Now that we have our delimiter out of the way,
    // let's check to see which kind of value we
    // captured (quoted or unquoted).
    if (arrMatches[ 2 ]){

      // We found a quoted value. When we capture
      // this value, unescape any double quotes.
      strMatchedValue = arrMatches[ 2 ].replace(
        new RegExp( "\"\"", "g" ),
        "\""
        );

    } else {

      // We found a non-quoted value.
      strMatchedValue = arrMatches[ 3 ];

    }


    // Now that we have our value string, let's add
    // it to the data array.
    arrData[ arrData.length - 1 ].push( strMatchedValue );
  }

  // Return the parsed data.
  return( arrData );
}

let convertFileToCsv = (fileData, callback) => {
  let fileNameSplit = fileData.name.split(".");
  // var regex = /^([a-zA-Z0-9\s_\\.\-:])+(.csv|.txt)$/;
  
  // if(regex.test(fileData.name.toLowerCase())) {
    if(fileNameSplit[fileNameSplit.length-1] === "csv") {
      if(typeof (FileReader) !== "undefined") {
          var reader = new FileReader();
          reader.onload = function (e) {
            let result = e.target.result;
            let newCSVArray = CSVToArray(result, ",");
            
            let latestCSVArray = [];

            if(newCSVArray[0][0].includes(";")) {
              latestCSVArray = CSVToArray(result, ";");
            }else {
              latestCSVArray = newCSVArray;
            }

            latestCSVArray.map((v, i) => {
              if(v.length === 1 && v[0] === "") {
                latestCSVArray.splice(i, 1);
              }

              return null;
            })
            
            callback.onSuccessConvertCsv(latestCSVArray);
          }

          reader.readAsText(fileData);
      }else {
        doToast("This browser does not support HTML5.", "fail");
      }
  }else {
      doToast("Invalid CSV file", "fail");
  }
};

let validEmail = (text) => {
  let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(text)
}

let rupiahFormat = (amount) => {
  return new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    minimumFractionDigits: 0,
  }).format(amount);
};

let numberWithCommas = (amount) => {
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

let urlToFile = (url, filename, mimeType) => {
	return (
		fetch(url)
			.then(function (res) { return res.arrayBuffer(); })
			.then(function (buf) { return new File([buf], filename, { type: mimeType }); })
	);
};

let compressImageFile = (file, widthVal, heightVal) => {
    return new Promise(function (resolve, reject) {
        let fileName = file.name;
        let reader = new FileReader();
        let readerCanvasImage = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = event => {
            let img = new Image();
            img.src = event.target.result;

            img.onload = () => {
                let elem = document.createElement('canvas');
                elem.width = widthVal;
                elem.height = heightVal;
                let ctx = elem.getContext('2d');

                ctx.drawImage(img, 0, 0, widthVal, heightVal);

                ctx.canvas.toBlob((blob) => {
                    let newFile = new File([blob], fileName, {
                        type: file.type,
                        lastModified: Date.now()
                    });
                    readerCanvasImage.readAsDataURL(newFile);
                }, file.type, 0.6);                
            }
            
            reader.onerror = error => console.log(error);
        };

        readerCanvasImage.onload = event => {
          urlToFile(event.target.result, file.name, file.type)
    			.then((file) => { 
            resolve({
              file: file,
              src: event.target.result
            })
				  });
        }
    })
}

let runImageCompress = (file, callback) => {
  let MAX_IMAGE_HEIGHT = 2000;
  let MAX_IMAGE_WIDTH = 2000;

  let imageWidth = "";
  let imageHeight = "";
  let aspectRatio = "";
  let _URL = window.URL || window.webkitURL;
  let img = new Image();

  img.onload = function () {
    aspectRatio = this.width / this.height;
    imageHeight = this.height;
    imageWidth = this.width;
    //check image width and height
    if(imageWidth > MAX_IMAGE_WIDTH) {
      imageWidth = 2000;
      imageHeight = Math.floor(imageWidth / aspectRatio);
    }
    
    if(imageHeight > MAX_IMAGE_HEIGHT) {
      imageHeight = 2000;
      imageWidth = Math.floor(imageHeight * aspectRatio);
    }
    //check image width and height
    
    compressImageFile(file, imageWidth, imageHeight).then(function(res) {
      callback(res)
    });
  };
        
  img.src = _URL.createObjectURL(file);
}

let excludeEmojiInput = (val) => {
  val = val.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '');

  return val;
}

let checkDevice = () => {
  if(Array.isArray(navigator.userAgent.split('Windows NT')) && navigator.userAgent.split('Windows NT').length > 1) {
      return 'Windows PC'
  } else if (Array.isArray(navigator.userAgent.split('iPhone')) && navigator.userAgent.split('iPhone').length > 1) {
      return 'iPhone'
  } else if (Array.isArray(navigator.userAgent.split('Windows Phone')) && navigator.userAgent.split('Windows Phone').length > 1) {
      return 'Windows Phone'
  } else if (Array.isArray(navigator.userAgent.split('Macintosh')) && navigator.userAgent.split('Macintosh').length > 1) {
      return 'Macbook'
  } else if (Array.isArray(navigator.userAgent.split('X11')) && navigator.userAgent.split('X11').length > 1) {
      return 'Linux PC'
  } else if (Array.isArray(navigator.userAgent.split('Pixel')) && navigator.userAgent.split('Pixel').length > 1) {
      return 'Google Pixel'
  } else if (Array.isArray(navigator.userAgent.split('Android')) && navigator.userAgent.split('Android').length > 1) {
      return 'Android Device'
  } else {
      return 'Unknown Device'
  }
}

let checkOS = () => {
  switch (checkDevice()) {
      case 'Windows PC' :
          return Number(navigator.userAgent.split('Windows NT ')[1].split(';')[0]) < 6 ? 'Windows XP' 
          : Number(navigator.userAgent.split('Windows NT ')[1].split(';')[0]) < 6.1 ? 'Windows Vista' 
          : Number(navigator.userAgent.split('Windows NT ')[1].split(';')[0]) < 6.2 ? 'Windows 7'
          : Number(navigator.userAgent.split('Windows NT ')[1].split(';')[0]) < 6.3 ? 'Windows 8'
          : Number(navigator.userAgent.split('Windows NT ')[1].split(';')[0]) < 10 ? 'Windows 8.1' : 'Windows 10'

      case 'iPhone':
          return navigator.userAgent.split('iPhone OS ')[1].split(' like Mac OS X')[0].split('_').join('.')
      case 'Windows Phone':
          return 'Windows Phone ' + Number(navigator.userAgent.split('Windows Phone ')[1].split(';')[0])
      case 'Macbook':
          return 'Mac OS X ' + navigator.userAgent.split('Mac OS X ')[1].split(')')[0].split('_').join('.')
      case 'Linux PC':
          return 'Linux'
      case 'Google Pixel':
          return 'Android ' + Number(navigator.userAgent.split('Android ')[1].split(';')[0])
      case 'Android Device':
          return 'Android ' + Number(navigator.userAgent.split('Android ')[1].split(';')[0])
      default:
          return 'Unknown OS'
  }
}

let getDeviceID = () => {
  var DATE_NOW = new Date().valueOf();

  var localDeviceID = localStorage.getItem('tapTalk.DeviceID');

  var md5DeviceID = md5(navigator.userAgent + "@" + DATE_NOW);

  var generateDeviceID = md5DeviceID.substring(0, 16) + "-" + guid();

  if(localDeviceID !== null) {
    return localDeviceID;
  }

  localStorage.setItem('tapTalk.DeviceID', generateDeviceID);

  return generateDeviceID;
}

let guid = () => {
  function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

let guidChat = () => {
  let guidChar = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_";
    let result = "";
    let guidCharLength = guidChar.length;
    
    for (var i = 0; i < 32; i++) {
      result += guidChar.charAt(Math.floor(Math.random() * guidCharLength));
    }

    return result;
}

let uuidv4 = () => {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

let isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0;
}

let isObjectTwin = (obj1, obj2) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}

let fetchMyAgentData = () => {
  return HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).account;
}

let trimTextTo = (text, length) => {
  if(text.length > length) {
    text = text.substring(0, length - 3) + "...";
  }

  return text;
}

let scrollToClass = (selector) => {
  let _sel = document.querySelectorAll(selector);

  if(_sel.length > 0) {
    _sel[0].scrollIntoView();
  }
}

let getImageDimention = (file, callback) => {
  let dimention = {
    height: 0,
    width: 0
  }

  let _URL = window.URL || window.webkitURL;
  let img = new Image();

  img.onload = function () {
    let imageHeight = this.height;
    let imageWidth = this.width;
   
    callback(imageHeight, imageWidth, file);
  };
        
  img.src = _URL.createObjectURL(file);

  return dimention;
} 

let HelperCopy = (value, toastText) => {
  var tempInput = document.createElement("input");
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  document.execCommand("copy");
  document.body.removeChild(tempInput);
  doToast(toastText);
}

let passInsertCase = (filter, room, caseData) => {
  let pass = true;

  if(typeof filter[room] !== "undefined") {
    if(
      filter[room].active &&
      (filter[room].topic !== 0 && filter[room].topic !== caseData.topicID)
    ) {
      pass = false;
    }
  
    if(
      filter[room] &&
      filter[room].active &&
      (filter[room].agent !== 0 && filter[room].agent !== caseData.agentAccountID)
    ) {
      pass = false;
    }
  
    if(
      filter[room] &&
      filter[room].active &&
      !isEmptyObject(filter[room].medium) &&
      !filter[room].medium[caseData.medium]
    ) {
      pass = false;
    }
  }


  return pass;
}

let autoFocusField = (target) => {
  let _target = document.querySelectorAll(`.${target}`);

  if(_target.length > 0) {
    _target[0].focus();
  }
}

let getWhatsappParam = (str) => {
  var re = /\{\{([^{}]*)\}\}/g;
  var m;
  var match = [];

  do {
      m = re.exec(str);
      if (m) {
          if(m[1] !== "") {
              match.push(m[1]);
          }
      }
  } while (m);

  return match;
}

let getItemFromArray = (arr, key, val) => {
  let _find = arr.findIndex((v) => v[key] === val);

  if(_find === -1) {
    return null;
  }else {
    return arr[_find];
  }
}

let generateElipsis = (text, maxLength) => {
  if(text.length + 3 > maxLength) {
    text = text.substr(0, maxLength - 1) + "...";
  }

  return text;
} 

let printLastMessageName = (message, caseDetail, forRoomList = false, forAvatar = false) => {
  let text = "";

  if(caseDetail) {
    if(caseDetail.userID === message.user.xcUserID) {
      if(forAvatar) {
        text = caseDetail.userFullName;
      }else {
        if(forRoomList) {
          if(caseDetail.userAlias !== "") {
            text = caseDetail.userAlias;
          }else if(caseDetail.userFullName !== "") {
            text = caseDetail.userFullName;
          }else {
            text = "+"+HelperInput.phoneBeautify(caseDetail.userPhone);
          }
        }else {
          if(caseDetail.userAlias === "" && caseDetail.userfullName === "") {
            text = "+"+HelperInput.phoneBeautify(caseDetail.userPhone);
          }else if(caseDetail.userAlias !== "") {
            text = caseDetail.userAlias+`(${caseDetail.userFullName})`;
          }else {
            text = caseDetail.userFullName;
          }
        }
      }
    }else {
      text = message.user.fullname;
    }
  } else {
    text = message.user.fullname;
  }

  return text;
}

let getMyAccountID = () => {
  let id = "";
  let _userAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData("user").data).account;
  
  if(_userAgentData.account) {
    id = _userAgentData.account.id;
  }

  return id;
}

export { 
  checkID, 
  getOrganization, 
  getSplitID, 
  doToast, 
  convertFileToCsv,
  rupiahFormat, 
  compressImageFile, 
  runImageCompress, 
  numberWithCommas, 
  validEmail, 
  checkClickOutside,
  excludeEmojiInput,
  numberOnly,
  checkDevice,
  checkOS,
  getDeviceID,
  uuidv4,
  isEmptyObject,
  isObjectTwin,
  fetchMyAgentData,
  trimTextTo,
  scrollToClass,
  getImageDimention,
  HelperCopy,
  passInsertCase,
  autoFocusField,
  getWhatsappParam,
  getItemFromArray,
  generateElipsis,
  printLastMessageName,
  guidChat,
  getMyAccountID
};