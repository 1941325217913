import React, { useState, useEffect } from "react";
import './WhatsappPreview.scss';
import checkMarkBlue from "../../../assets/img/icon-checkmark-blue-2.svg";
import whatsappPreviewPdfIcon from "../../../assets/img/whatsapp-preview-pdf-icon.svg";
import { FiAlertCircle, FiImage, FiVideo, FiCopy, FiExternalLink } from "react-icons/fi";
import { FaPhoneAlt } from "react-icons/fa";
import HelperDate from "../../../helper/HelperDate";
import HelperChat from "../../../helper/HelperChat";

let WhatsappPreview = ({header, body, footer, template = false, language = false, footerLink, data = false, index = false, custom = false, categoryCode = false}) => {
    let [printHeader, setPrintHeader] = useState("");
    let [printBody, setPrintBody] = useState("");

    useEffect(() => {
        let headerText = header;
        let bodyText = body;

        if(data && index !== false) {
            let _data = {...data};
            _data.template[index].sampleParameters["header"].map((v) => {
                if(v.value !== "") {
                    headerText = headerText.replace(`{{${v.name}}}`, v.value);
                }

                return null;
            })

            _data.template[index].sampleParameters["body"].map((v) => {
                if(v.value !== "") {
                    bodyText = bodyText.replace(`{{${v.name}}}`, v.value);
                }

                return null;
            })
        }

        setPrintHeader(headerText);
        setPrintBody(bodyText);
    }, [header, body, data]);

    // useEffect(() => {
    //     if(data && index) {

    //     }else {
    //         setPrintHeader(body);
    //     }
    // }, [body]);
    
    let printButtons = (v, i) => {
        return (
            <div className="whatsapp-chat-bubble no-corner" keys={`call-to-action-button-${i}`}>
                {v.subType === "otp" ?
                    <FiCopy />
                    :
                    ""
                }
                
                <b>{v.buttonText ? v.buttonText : v.text}</b>
            </div>
        )
    }

    let actionPrintCallToAction = () => {
        return (
            language.buttons.map((v, i) => {
                return (
                    <div className="call-to-action-button-wrap" key={`call-to-action-${i}`}>
                        {v.subType === "url" &&
                            <FiExternalLink />
                        }

                        {v.subType === "phone_number" &&
                            <FaPhoneAlt />
                        }
                        {v.text}
                    </div>
                )
            })
        )
    }

    return (
        <div className="whatsapp-preview">
            <div className="whatsapp-info-wrapper">
                <div className="whatsapp-info-content" />
            </div>

            {(custom && custom.category === "AUTHENTICATION") ?
                <>
                    <div className="whatsapp-chat-bubble">
                        <p 
                            className="whatsapp-chat-bubble-title" 
                            dangerouslySetInnerHTML={{
                                __html:(`<b>548903</b> is your verification code.${custom.body}`)
                            }}
                        />

                        {custom.footer !== "" &&
                            <p className="whatsapp-chat-bubble-description color-707070">
                                This code expires in {custom.footerTime === "" ? "<NUM_MINUTES>" : custom.footerTime} minutes
                            </p>
                        }

                        
                        <p className="whatsapp-chat-bubble-status">
                            {HelperDate.formatToString(new Date(), "HH:mm")}
                            <img src={checkMarkBlue} alt="" />
                        </p>
                    </div>
                    
                    <div className="whatsapp-chat-bubble no-corner">
                        <FiCopy />
                        <b>{custom.buttonText}</b>
                    </div>
                </>
                :
                ((
                  (header === "" && body === "" && footer === "") && 
                  template === "text" &&
                  (categoryCode && categoryCode !== "AUTHENTICATION")
                ) ?
                    <div className="whatsapp-warning">
                        <div className="whatsapp-warning-content">
                            <FiAlertCircle />
                            <b>A preview will be displayed here once you fill the message box</b>
                        </div>
                    </div>
                    :
                    <>
                        <div className="whatsapp-chat-bubble">
                            {(template && template !== "text") &&
                                // (template === "static-media" ?
                                    <div className="static-media">
                                        {language.headerFormat === "document" &&
                                            (
                                                (language.headerTextNamed ?
                                                    (language.headerTextNamed === "" ?
                                                        <div className="static-media-document">
                                                            <img src={whatsappPreviewPdfIcon} alt="" />
        
                                                            Your document goes here
                                                        </div>
                                                        :
                                                        <div className="static-media-document">
                                                            <img src={whatsappPreviewPdfIcon} alt="" />
        
                                                            Your document goes here
                                                        </div>
                                                    )
                                                    :
                                                    (!language.file ?
                                                        <div className="static-media-document">
                                                            <img src={whatsappPreviewPdfIcon} alt="" />
        
                                                            Your document goes here
                                                        </div>
                                                        :
                                                        <div className="static-media-document">
                                                            <img src={whatsappPreviewPdfIcon} alt="" />
        
                                                            {language.file.name}
                                                        </div>
                                                    )
                                                )
                                            )
                                        }
        
                                        {language.headerFormat === "image" &&
                                            (
                                                (language.headerTextNamed ?
                                                    (language.headerTextNamed === "" ?
                                                        <div className="static-media-video">
                                                            <FiImage />
                                                            <br />
                                                            Your image goes here
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="static-media-video-file-wrapper">
                                                                <FiImage />
                                                            </div>
        
                                                            <img className="static-media-image-asset" src={language.headerTextNamed} alt="" />
                                                        </>
                                                    )
                                                    :
                                                    (!language.file ?
                                                        <div className="static-media-video">
                                                            <FiImage />
                                                            <br />
                                                            Your image goes here
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="static-media-video-file-wrapper">
                                                                <FiImage />
                                                            </div>
        
                                                            <img className="static-media-image-asset" src={language.file.fileSrc} alt="" />
                                                        </>
                                                    )
                                                )
                                            )
                                        }
        
                                        {language.headerFormat === "video" &&
                                            (
                                                (language.headerTextNamed ?
                                                    (language.headerTextNamed === "" ?
                                                        <div className="static-media-video">
                                                            <FiVideo />
                                                            <br />
                                                            Your video goes here
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="static-media-video-file-wrapper">
                                                                <FiVideo />
                                                            </div>
                                                        
                                                            <video className="static-media-video-asset" src={language.headerTextNamed} />
                                                        </>
                                                    )
                                                    :
                                                    (!language.file ?
                                                        <div className="static-media-video">
                                                            <FiVideo />
                                                            <br />
                                                            Your video goes here
                                                        </div>
                                                        :
                                                        <>
                                                            <div className="static-media-video-file-wrapper">
                                                                <FiVideo />
                                                            </div>
                                                            
                                                            <video className="static-media-video-asset" src={language.file.fileSrc} />
                                                        </>
                                                    )
                                                )
                                            )
                                        }
                                    </div>
                                    // :
                                    //dynamic
                                    // <div className="dynamic-media">
                                    //     {language.headerFormat === "document" &&
                                    //         <div className="dynamic-media-document">
                                    //             <img src={whatsappPreviewPdfIcon} alt="" />
        
                                    //             Your document goes here
                                    //         </div>
                                    //     }
                                        
                                    //     {language.headerFormat === "image" &&
                                    //         <div className="dynamic-media-image">
                                    //             <FiImage />
                                    //             <br />
                                    //             Your image goes here
                                    //         </div>
                                    //     }
        
                                    //     {language.headerFormat === "video" &&
                                    //         <div className="dynamic-media-video">
                                    //             <FiVideo />
                                    //             <br />
                                    //             Your video goes here
                                    //         </div>
                                    //     }
                                    // </div>
                                    //dynamic
                                // )
                            }
        
                            {template === "text" &&
                                <p 
                                    className="whatsapp-chat-bubble-title" 
                                    dangerouslySetInnerHTML={{
                                        __html:(`<b>${HelperChat.convertFormatTextWhatsapp(HelperChat.lineBreakWithoutTrim(printHeader))}</b>`)
                                    }}
                                />
                            }
        
                            <p 
                                className="whatsapp-chat-bubble-description" 
                                dangerouslySetInnerHTML={{
                                    __html:(
                                        (
                                            (categoryCode && categoryCode === "AUTHENTICATION") &&
                                            printBody === ""
                                        ) ?
                                            "<b>12345</b> is your verification code."
                                            :
                                            HelperChat.convertFormatTextWhatsapp(HelperChat.lineBreakWithoutTrim(printBody)))
                                }}
                            />
        
                            <p className="whatsapp-chat-bubble-footer">
                                {footerLink ?
                                    <>
                                        {footer}
                                        <br />
                                        <span className="whatsapp-link">
                                            {footerLink}
                                        </span>
                                    </>
                                    :
                                    footer
                                }
                            </p>
        
                            <p className="whatsapp-chat-bubble-status">
                                {HelperDate.formatToString(new Date(), "HH:mm")}
                                <img src={checkMarkBlue} alt="" />
                            </p>
                            
                            {(language && language.buttonType === "call_to_action" && categoryCode !== "AUTHENTICATION") &&
                                <div className="wrapper-call-to-action">
                                    {actionPrintCallToAction()}
                                </div>
                            }
                        </div>
                        
                        {(language.buttons && language.buttons.length > 0) && 
                            (
                                (categoryCode === "AUTHENTICATION" ? 
                                    language.buttons.map((v, i) => {
                                        return (
                                            printButtons(v, i)
                                        )
                                    })
                                    :
                                    language.buttonType === "quick_reply" ?
                                        <div className={`wrapper-quick-reply ${language.buttons.length > 2 ? "wrapper-quick-reply-more" : ""}`}>
                                            {language.buttons.map((v, i) => {
                                                return (
                                                    printButtons(v, i)
                                                )
                                            })}
                                        </div>
                                        :
                                        ""
                                )
                            )                            
                        }
                    </>
                )
            }

        </div>
    )
}

export default WhatsappPreview;