import React, { useState } from "react";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import "./CustomSelectOption.scss";
import { FiChevronDown } from "react-icons/fi";
import Loading from "../loading/Loading";

const CustomSelectOption = props => {
    let [isDropdownActive, setIsDropdownActive] = useState(false);

    let toggleDropdownOption = () => {
        setIsDropdownActive(!isDropdownActive);
    }

    let onClickOptionList = (value) => {
        toggleDropdownOption();
        props.onClickDropDownListOptionProps(value);
    }
    
    return (
        <div 
            className={`
                ${props.specificClassNameProps} 
                main-custom-select-option-wrapper 
                ${isDropdownActive ? "active-dropdown" : ""}
                ${props._className}
            `}
        >
            <Dropdown 
                isOpen={isDropdownActive} 
                toggle={() => {
                    !props.isLoading && toggleDropdownOption();
                }} 
            >
                <DropdownToggle className={`${props.dropdownError && props.dropdownError !== "" ? "border-red" : ""} custom-select-option-value-wrapper`} disabled={props.isDisabled || props.isLoading}>
                    {props.valueDropdownProps.label === "" ?
                        <p className="custom-select-option-placeholder">
                            {props.placeholderProps}
                        </p>
                        :
                        <p className="custom-select-option-value">
                            {props.isBold ?
                                <b>{props.valueDropdownProps.label}</b>
                                :
                                props.valueDropdownProps.label
                            }
                        </p>
                    }
                    
                    {props.isLoading ?
                        <Loading className="loading-custom-select" />
                        :
                        <FiChevronDown className={`icon-arrow`} />
                    }
                </DropdownToggle>

                <DropdownMenu className="dropdown-option-wrapper">
                    {props.optionListProps && props.optionListProps.length > 0 ?
                        props.optionListProps.map((val, key) => {
                            if(!props.condition) {
                                return (
                                    <DropdownItem className={`${props.valueDropdownProps.value === val.value ? "active-value" : ""}`}
                                        onClick={() => onClickOptionList(val)}
                                        key={`option-list-${props.placeholderProps}-${key}`}
                                    >
                                            {val.label}
                                    </DropdownItem>
                                )
                            }else {
                                if(props.condition(val.id)) {
                                    return (
                                        <DropdownItem className={`${props.valueDropdownProps.value === val.value ? "active-value" : ""}`}
                                            onClick={() => onClickOptionList(val)}
                                            key={`option-list-${props.placeholderProps}-${key}`}
                                        >
                                            {val.label}
                                        </DropdownItem>
                                    )
                                }
                            }

                            return null;
                        })
                        :
                        <DropdownItem className="no-data-option">
                            No Options
                        </DropdownItem>
                    }
                </DropdownMenu>
            </Dropdown>
        </div>
    );
};

export default CustomSelectOption;
