import HelperCustomEncryptor from "./HelperCustomEncryptor";

const APP_STORAGE = localStorage.getItem("persist:root");

const USER_STORAGE = APP_STORAGE === null ? 
                        null
                        :
                        JSON.parse(
                            localStorage.getItem("persist:root")
                        ).user;

const USER_DATA = USER_STORAGE === null ? 
                    null
                    :   
                    Object.keys(JSON.parse(USER_STORAGE)).length === 0 ? 
                        null 
                        : 
                        HelperCustomEncryptor.doDecrypt(JSON.parse(USER_STORAGE).data);

export class HelperAuth {
    userWasLoggedIn() {
        let wasLoggedIn = false;

        if(USER_DATA !== null) {
            if(USER_DATA.accessToken) {
                wasLoggedIn = true
            }
        }

        return wasLoggedIn;
    }
}

export default new HelperAuth();

