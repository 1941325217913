import React, { useState, useEffect } from 'react';
import './ChatRoomCaseActionHistory.scss';
import { connect } from 'react-redux';
import { FiX, FiClock, FiRefreshCw } from 'react-icons/fi';
import { doToast } from '../../../../../helper/HelperGeneral';
import CaseService from "../../../../../services/chat/caseServices";
import { setActiveCaseActionHistory } from "../../../../../redux/actions/reduxActionActiveCaseActionHistory";
import HelperDate from "../../../../../helper/HelperDate";
// import HelperGetLocalStorage from "../../../../../helper/HelperGetLocalStorage";
// import ButtonWithLoadingOrIcon from "../../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import { Scrollbars } from 'react-custom-scrollbars';

var style = {
  scrollStyle: {
    position: "relative",
    backgroundColor: "#ff7d00",
    right: "-5px",
    width: "3px",
    borderRadius: "8px"
  }
};


var ChatRoomCaseActionHistory = (props) => {
    let [caseHistoryData, setCaseHistoryData] = useState([]);
    let [caseHistoryDataTemp, setCaseHistoryDataTemp] = useState({
      time: false,
      data: [],
      room: false
    });
    let [isLoading, setIsLoading] = useState(false);
    let [isError, setIsError] = useState(false);

    let resetCaseActionHistory = () => {
      setIsError(false);
      setIsLoading(false);
      setCaseHistoryData([]);
    }

    useEffect(() => {
      if(props.activeRoom !== null) {
        if(props.activeRoom.id === caseHistoryDataTemp.room.id && caseHistoryDataTemp.time) {
          setCaseHistoryData(caseHistoryDataTemp.data);
        }
      }
    }, [caseHistoryDataTemp])

    useEffect(() => {
        if(props.activeCaseActionHistory) {
            getCaseHistory()
        }else {
          resetCaseActionHistory();
        }
    }, [props.activeCaseActionHistory])

    var getCaseHistory = () => {
      setIsLoading(true);

      CaseService.getCaseActionHistory(props.parentProps.match.params.orgID, {id: props.caseDetailData.case.id}, (res) => {
        let dataRes = res.dataResult;

        if(dataRes.status === 200) {
          setCaseHistoryDataTemp({
            time: new Date().valueOf(),
            data: dataRes.data.items,
            room: props.activeRoom
          });
        }else {
          doToast(dataRes.error.message, "fail");
          setIsError(true);
        }

        setIsLoading(false);
      })
    }

    return (
    <div className={`case-action-history-wrapper ${props.activeCaseActionHistory ? 'active-case-action-history' : ''}`}>
        <div className={`case-action-history-main-wrapper ${props.activeCaseActionHistory ? "active-case-action-history-main-wrapper" : ""}`}>
          <div className="case-action-history-close" onClick={() => props.setActiveCaseActionHistory(false)}>
            <FiX />
          </div>

          <p className={`title-case-action-history`}>
            <b>Case Action History</b>
          </p>

          <div className="case-action-history-list-wrapper">
            <Scrollbars autoHideTimeout={500}
                        renderThumbVertical={props => <div {...props} style={style.scrollStyle} />}
            >
              {isLoading ?
                <div className="loading-case-action">
                  <div className="lds-ring">
                    <div /><div /><div /><div />
                  </div>
                  <b>Loading Data</b>
                </div>
                :
                (isError ?
                  <div className="on-error">
                    <p onClick={getCaseHistory}>
                      <FiRefreshCw />
                      <b>Retry</b>
                    </p>
                  </div>
                  :
                  caseHistoryData.map((val, idx) => {
                    return (
                      <div className="case-action-history-card" key={`list-${idx}`}>
                        <p className="action-time">
                          <FiClock />
                          {HelperDate.formatToString(new Date(val.createdTime), "dd/MM/yyyy HH:mm")}
                        </p>
      
                        <p className="action-text">
                          <b>{val.text}</b>
                        </p>
                      </div>
                    )
                  })
                )
              }
            </Scrollbars>
          </div>
        </div>
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  activeCaseActionHistory: state.activeCaseActionHistory,
  caseDetailData: state.caseDetailData
});

const mapDispatchToProps = {
  setActiveCaseActionHistory
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatRoomCaseActionHistory);
