import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./PaymentSettings.scss";
// import Loading from "../../../reuseableComponent/loading/Loading";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import iconVisa from "../../../../assets/img/payment-card/visa.png";
import iconJsb from "../../../../assets/img/payment-card/jcb.png";
import iconMastercard from "../../../../assets/img/payment-card/mastercard.png";
import iconAmex from "../../../../assets/img/payment-card/amex.png";
import { doToast } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import { FiPlus, FiChevronDown, FiTrash2 } from "react-icons/fi";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import BillingServices from "../../../../services/newServices/BillingServices";
import { setAllOrganizationDetail } from "../../../../redux/actions/reduxActionAllOrganizationDetail";
import { connect } from 'react-redux';
import mixpanel from "mixpanel-browser";

const CARD_BRAND = {
    visa: {
        label: "Visa",
        img: iconVisa
    },
    mastercard: {
        label: "Mastercard",
        img: iconMastercard
    },
    jcb: {
        label: "JCB",
        img: iconJsb
    },
    american_express: {
        label: "American Express",
        img: iconAmex
    },
}

let PaymentSettings = (props) => {
    let { match } = props;
    let [isShowDropdownSelectCard, setIsShowDropdownSelectCard] = useState(false);
    let [isShowModalDeleteCard, setIsShowModalDeleteCard] = useState(false);
    let [isWaitDeleteCard, setIsWaitDeleteCard] = useState(false);
    let [isWaitResponseCardList, setIsWaitResponseCardList] = useState(true);
    let [cardList, setCardList] = useState(false);
    let [activeCard, setActiveCard] = useState({
        cardID: ""
    });
    let [singleDeleteCard, setSingleDeleteCard] = useState({});
    let [isWaitReponseSetPaymentSetting, setIsWaitReponseSetPaymentSetting] = useState(false);
    let [isAutomaticPayment, setIsAutomaticPayment] = useState(false);
    let [originSettingPayment, setOriginSettingPayment] = useState({
        isAutomaticPayment: false,
        primaryCardID: ""
    });
    let [retriveDataError, setRetrieveDataError] = React.useState({
        code: false,
        message: false
    });

    let toggleDropdownSelectCard =() => {
        setIsShowDropdownSelectCard(!isShowDropdownSelectCard);
    }

    let toggleModalDeleteCard =(data = {}) => {
        setSingleDeleteCard(data);
        setIsShowModalDeleteCard(!isShowModalDeleteCard);
    }

    let getPaymentSetting = () => {
        let _retriveDataError = {...retriveDataError};

        BillingServices.getPaymentSetting(match.params.orgID, (response) => {
            let dataResult = response.dataResult;
            
            if(dataResult.error.message === "") {
                let _allOrganizationDetail = {...props.allOrganizationDetail};
                let _originSettingPayment = {...originSettingPayment};
                _originSettingPayment.isAutomaticPayment = dataResult.data.isAutomaticPayment;
                _originSettingPayment.primaryCardID = dataResult.data.primaryCardID;

                _allOrganizationDetail.paymentSettings = dataResult.data;
                props.setAllOrganizationDetail(_allOrganizationDetail);
                setOriginSettingPayment(_originSettingPayment);
                setIsAutomaticPayment(dataResult.data.isAutomaticPayment);
                
                if(cardList.length > 0) {
                    let findIndex = cardList.findIndex((value) => value.cardID === dataResult.data.primaryCardID);
                    findIndex !== -1 && setActiveCard(cardList[findIndex]);
                }
            }else {
                _retriveDataError.message = dataResult.error.message;
                _retriveDataError.code = dataResult.error.code;
                setRetrieveDataError(_retriveDataError);
            }

            setIsWaitResponseCardList(false);
        })
    }        
    
    let getCardList = () => {
        let _retriveDataError = {...retriveDataError};

        setIsWaitResponseCardList(true);
        setCardList(false);
        setActiveCard({
            cardID: ""
        })

        BillingServices.getCardList(match.params.orgID, {
            onFailCredential: (errorMessage, errorCode) => {
                console.log(errorMessage)
                _retriveDataError.message = errorMessage;
                _retriveDataError.code = errorCode;
                setRetrieveDataError(_retriveDataError);
                setIsWaitResponseCardList(false);
            },
            onSuccessCredential: (response) => {
                let dataResult = response.dataResult;

                if(response.error.message === "") {
                    setCardList(response.data.cards);
                }else {
                    _retriveDataError.message = dataResult.error.message;
                    _retriveDataError.code = dataResult.error.code;
                    setRetrieveDataError(_retriveDataError);
                    setCardList([]);
                    setIsWaitResponseCardList(false);
                }
            }
        })
    }

    let submitPaymentSetting = () => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        
        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Button] Change Payment Method",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : match.params.orgID.split("-")[0]
                }
            );
        }

        let data = {
            isAutomaticPayment: isAutomaticPayment,
            primaryCardID: activeCard.cardID 
        }

        setIsWaitReponseSetPaymentSetting(true);

        BillingServices.setPaymentSetting(match.params.orgID, data, (response) => {
            let dataResult = response.dataResult;

            setIsWaitReponseSetPaymentSetting(false);
            
            if(dataResult.error.message === "") {
                let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                    mixpanel.track(
                        "[Action] Change Payment Method",
                        {
                            method : isAutomaticPayment ? "Automatic" : "Manual",
                            userFullName : myAgentData.account.fullName,
                            userID : myAgentData.account.id,
                            organizationID : match.params.orgID.split("-")[0]
                        }
                    )
                }

                setRetrieveDataError({
                    code: false,
                    message: false
                });
                
                doToast(dataResult.data.message);
                getCardList();
            }else {
                doToast(dataResult.error.message, "fail");
            }
        })
    }

    let deleteCard = () => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Button] Delete Card",
                {
                    method : isAutomaticPayment ? "Automatic" : "Manual",
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : match.params.orgID.split("-")[0]
                }
            )
        }

        if(originSettingPayment.primaryCardID === singleDeleteCard.cardID) {
            doToast("This card is selected for automatic payment")
        }else {
            setIsWaitDeleteCard(true);
            
            let data = {
                cardID: singleDeleteCard.cardID,
                createdTime: singleDeleteCard.createdTime
            }
    
            BillingServices.deleteCard(match.params.orgID, data, {
                onFailCredential: (errorMessage, errorCode) => {
                    doToast(errorMessage, "fail")
                },
                onSuccessCredential: (response) => {
                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                        mixpanel.track(
                            "[Action] Delete Card",
                            {
                                method : isAutomaticPayment ? "Automatic" : "Manual",
                                userFullName : myAgentData.account.fullName,
                                userID : myAgentData.account.id,
                                organizationID : match.params.orgID.split("-")[0]
                            }
                        )
                    }
                    setIsWaitDeleteCard(false);
                    toggleModalDeleteCard();
                    doToast(response.data.message)
    
                    if(response.data.success) {
                        getCardList();
                    }
                }
            })
        }
    }

    let onChangeAutomaticPayment = (e) => {
        setIsAutomaticPayment(e.target.value === "true" ? true : false);
    }

    let convertCardMasking = (cardNumber) => {
        return cardNumber.replace(/X/g, "<div class='masking-dot'></div>");
    }

    // let disabledSaveChange = () => {
    //     let isDisabled = false;

    //     if((originSettingPayment.isAutomaticPayment === isAutomaticPayment) && (originSettingPayment.primaryCardID === activeCard.cardID)) {
    //         isDisabled = true;
    //     }

    //     if(activeCard.cardID === "" && isAutomaticPayment) {
    //         isDisabled = true;   
    //     }

    //     if(originSettingPayment.primaryCardID === activeCard.cardID && isAutomaticPayment) {
    //         isDisabled = true;
    //     }     

    //     return isDisabled;
    // }

    useEffect(() => {
        let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
        getCardList();

        if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
                "[Page] Payment Settings",
                {
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : props.match.params.orgID.split("-")[0]   
                }
            );
        }
    }, [])

    useEffect(() => {
        if(cardList) {
            getPaymentSetting();
        }
    }, [cardList])

    return (
        <div className="payment-setting-outer-wrapper">
            <div className="payment-setting sectionWrap">
                {isWaitResponseCardList || retriveDataError.code ? 
                    <RetrieveDataLoading 
                        isLoading={isWaitResponseCardList}
                        errorMessage={retriveDataError.message} 
                        noPaddingTop={true}
                    />
                    :
                    <React.Fragment>
                        <div className="payment-setting-header">
                            <b>Payment Settings</b>

                            {!isWaitReponseSetPaymentSetting ?
                                <ButtonWithLoadingOrIcon 
                                    className="orange-button main-button-40"
                                    text="Save Changes"
                                    // isDisabled={disabledSaveChange()} 
                                    onClickAction={submitPaymentSetting}
                                />
                                :
                                <ButtonWithLoadingOrIcon 
                                    isLoading
                                    loadingColor="gray"
                                    isDisabled
                                    className="orange-button main-button-40"
                                    text="Save Changes"
                                    position="left"
                                />
                            }
                        </div>

                        <div className="how-to-pay-wrapper">
                            <p className="how-to-pay-title">
                                <b>Select How You Pay</b>
                            </p>

                            <div className="custom-radio">
                                <input 
                                    type="radio" 
                                    id="payment-auto" 
                                    name="payment-setting" 
                                    value={true} 
                                    onChange={(e) => onChangeAutomaticPayment(e)}
                                    checked={isAutomaticPayment} 
                                    disabled={isWaitReponseSetPaymentSetting}
                                />
                                <label htmlFor="payment-auto">
                                    <div className="role-radio-label">
                                        <b>Automatic Payments</b>
                                        <span>Automatically charge the selected primary payment method.</span>
                                    </div>
                                </label>

                                {isAutomaticPayment &&
                                    <div className="auto-payment-card-select-wrapper">
                                        {cardList.length === 0 ?
                                            <NavLink 
                                                to={`/o/${props.match.params.orgID}/organization/billing/add-card`}
                                                onClick={() => {
                                                    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                                                    
                                                    if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                                                        mixpanel.track(
                                                            "[Button] Add Card",
                                                            {
                                                                userFullName : myAgentData.account.fullName,
                                                                userID : myAgentData.account.id,
                                                                organizationID : props.match.params.orgID.split("-")[0]
                                                            }
                                                        )
                                                    }
                                                }}
                                            >
                                                <p className="auto-payment-add-card">
                                                    <FiPlus />
                                                    <b>Add Card</b>
                                                </p>
                                            </NavLink>
                                            :
                                            <div className="selected-card">
                                                <label><b>Primary Card</b></label>
                                                <Dropdown isOpen={isShowDropdownSelectCard} toggle={toggleDropdownSelectCard}>
                                                    {activeCard.cardID === "" ?
                                                        <DropdownToggle className="please-select-card">
                                                            <b>Please select your card</b>
                                                            <FiChevronDown className={isShowDropdownSelectCard ? "active-dropdown-card" : ""} />
                                                        </DropdownToggle>
                                                        :
                                                        <DropdownToggle>
                                                            <img src={CARD_BRAND[activeCard.cardBrand].img} alt="" className="card-icon" />
                                                            <p className="card-number">
                                                                <b dangerouslySetInnerHTML={{__html: convertCardMasking(activeCard.cardNumberMasked)}} />
                                                            </p>

                                                            <p className="card-expiry-date">
                                                                <b>Expires {activeCard.cardExpiryMonth}/{activeCard.cardExpiryYear}</b>
                                                            </p>
                                                            <FiChevronDown className={isShowDropdownSelectCard ? "active-dropdown-card" : ""} />
                                                        </DropdownToggle>
                                                    }

                                                    <DropdownMenu>
                                                        {cardList.map((value, index) => {
                                                            return (
                                                                <DropdownItem  key={`card-list-${index}`} onClick={() => {
                                                                    setActiveCard(value);
                                                                }}>
                                                                    <div className="card-list-content">
                                                                        <img src={CARD_BRAND[value.cardBrand].img} alt="" className="card-icon" />
                                                                        <p className="card-number">
                                                                            <b dangerouslySetInnerHTML={{__html: convertCardMasking(value.cardNumberMasked)}} />
                                                                        </p>

                                                                        <p className="card-expiry-date">
                                                                            <b>Expires {value.cardExpiryMonth}/{value.cardExpiryYear}</b>
                                                                        </p>
                                                                    </div>
                                                                </DropdownItem>
                                                            )
                                                        })}
                                                    </DropdownMenu>
                                                        
                                                </Dropdown>
                                            </div>
                                        }
                                    </div>
                                }

                                <input 
                                    type="radio" 
                                    id="payment-manual" 
                                    name="payment-setting" 
                                    value={false} 
                                    onChange={(e) => onChangeAutomaticPayment(e)}
                                    checked={!isAutomaticPayment} 
                                    disabled={isWaitReponseSetPaymentSetting}
                                />
                                <label htmlFor="payment-manual">
                                    <div className="role-radio-label">
                                        <b>Manual Payments</b>
                                        <span>Make payments manually via bank transfers.</span>
                                    </div>
                                </label>
                            </div>
                        </div>
                    </React.Fragment>
                } 
            </div>
            
            {(!isWaitResponseCardList && !retriveDataError.code) &&
                <div className="payment-setting sectionWrap">
                    <React.Fragment>
                        <div className="payment-setting-header">
                            <b>Saved Cards</b>

                            <p className="payment-setting-title-description">
                                Have multiple debit or credit cards and save one as your preferred payment method.
                            </p>

                            <NavLink to={`/o/${props.match.params.orgID}/organization/billing/add-card`}>
                                <ButtonWithLoadingOrIcon 
                                    className="no-fill-button main-button-40"
                                    text="Add Card"
                                    icon={{
                                        type: "svg",
                                        src: FiPlus
                                    }}
                                    position="left"
                                />
                            </NavLink>
                        </div>

                        <div className="card-list-wrapper">
                            {cardList.length === 0 ?
                                 <div className="card-list-no-data">
                                    <p>
                                        No card saved, Add by clicking “+ Add Card button on the top right.
                                    </p>
                                </div>
                                :
                                cardList.map((value, index) => {
                                    return (
                                        <div className="card-list-content" key={`card-list-${index}`}>
                                            <img src={CARD_BRAND[value.cardBrand].img} alt="" className="card-icon" />
                                            <p className="card-number">
                                                <b dangerouslySetInnerHTML={{__html: convertCardMasking(value.cardNumberMasked)}} />
                                            </p>

                                            <p className="card-expiry-date">
                                                <b>Expires {value.cardExpiryMonth}/{value.cardExpiryYear}</b>
                                            </p>

                                            <FiTrash2 onClick={() => toggleModalDeleteCard(value)}/>
                                        </div>
                                    )
                                })
                                
                            }
                        </div>
                    </React.Fragment>
                </div>
            }

            <PopupRemove removeTitle={`Remove this card ${singleDeleteCard.cardNumberMasked}?`}
                        removeDescription={`This card will be removed from this payment setting`}
                        onClickCancel={toggleModalDeleteCard}
                        onClickRemove={deleteCard}
                        waitForResponse={isWaitDeleteCard}
                        showModalProps={isShowModalDeleteCard}
            />
        </div>
    )
}

const mapStateToProps = state => ({
    allOrganizationDetail: state.allOrganizationDetail
});

const mapDispatchToProps = {
    setAllOrganizationDetail
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PaymentSettings);