import React, { useState, useEffect } from 'react';
import './IntegrationInstagramEditTopicAccount.scss';
import IntegrationInstagramServices from '../../../../services/integration/IntegrationInstagramServices';
import topicServices from '../../../../services/newServices/TopicServices';
import SectionWrap from '../../../reuseableComponent/sectionWrap/SectionWrap';
import ErrorOrInfoComp from "../../../reuseableComponent/errorOrInfoComp/ErrorOrInfoComp";
import { FiInfo } from 'react-icons/fi';
// import CustomSelectOption from '../../../reuseableComponent/customSelectOption/CustomSelectOption';
import BackButton from '../../../reuseableComponent/BackButton/BackButton';
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import SelectTopic from "../../../reuseableComponent/selectTopic/SelectTopic";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import ServiceOrganization from '../../../../services/organization/ServiceOrganization';
import { checkID, doToast, scrollToClass } from '../../../../helper/HelperGeneral';
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";

let IntegrationInstagramEditTopicAccount = (props) => {
  let { match, history } = props;
  let [orgID, setOrgID] = useState(false);
  let [isLoadingGetDetailData, setIsLoadingGetDetailData] = useState(true);
  let [accountDetail, setAccountDetail] = useState(null);
  let [isLoadingEditTopicAccount, setIsLoadingEditTopicAccount] = useState(false);
  // let [accountTopicInitial, setAccountTopicInitial] = useState({
  //   label: '',
  //   value: '',
  // });
  // let [accountTopicVal, setAccountTopicVal] = useState({
  //   label: '',
  //   value: '',
  // });
  let [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  });
  let [topicList, setTopicList] = useState([]);
  let [topicListOrigin, setTopicListOrigin] = useState([]);
  let [isCheckAll, setIsCheckAll] = useState(false);
  let [selectedTopic, setSelectedTopic] = useState([]);
  let [selectedTopicOrigin, setSelectedTopicOrigin] = useState([]);
  let [searchTopic, setSearchTopic] = useState("");
  let [errorMessageData, setErrorMessageData] = useState({
    topic: ""
  }) 

  useEffect(() => {
    doMount();
  }, []);

  useEffect(() => {
    if (orgID) {
      getTopics(orgID);
    }
  }, [orgID]);

  let doMount = () => {
    let paramID = checkID(match);
    if (paramID) {
      setOrgID(paramID);
      getInstagramAccountDetail(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== '') {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = '/organization/new';
          let { id, createdTime } = responseData.memberships[0].organization;
          let urlOrgID = `${id}-${createdTime}`;
          history.push(`/o/${urlOrgID}/integration`);
        } else {
          console.log('GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED');
        }
      });
    }
  };

  let getInstagramAccountDetail = (orgID) => {
    setIsLoadingGetDetailData(true);

    let data = {
      id: parseInt(props.match.params.accountID)
    }

    IntegrationInstagramServices.getDetail(orgID, data, (response) => {
      let _retriveDataError = {...retriveDataError};

      if (response.dataResult.error.message === "") {
        // setAccountTopicInitial({
        //   label: response.dataResult.data.channel.topicName,
        //   value: response.dataResult.data.channel.topicID,
        // });
        // setAccountTopicVal({
        //   label: response.dataResult.data.channel.topicName,
        //   value: response.dataResult.data.channel.topicID,
        // });
        setAccountDetail(response.dataResult.data.channel);

        if(response.dataResult.data.channel.topics.length > 0) {
          response.dataResult.data.channel.topics.map((v) => {
              v.value = v.id;
              return null;
          });

          setSelectedTopic(response.dataResult.data.channel.topics);
          setSelectedTopicOrigin(response.dataResult.data.channel.topics);
        }
      } else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        
        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetailData(false);
    });
  };

  let getTopics = async (orgID) => {
    topicServices.getTopic(orgID, 'list', null, (response) => {
      let data = response.dataResult.data.topics;
      let createList = () => {
        let newData = [];
        data.map((val) => {
          let _data = {};
          _data.id = val.id;
          _data.name = val.name;
          newData.push(_data);
          return null;
        });
        setTopicList(newData);
        setTopicListOrigin(newData);
      };

      createList();
    });
  };

  let editTopicInstagramAccount = () => { 
    let errorCount = 0;
    let errorClass = "";
    let _errorMessageData = {...errorMessageData};
    
    if(selectedTopic.length === 0) {
        _errorMessageData.topic = "Please select topic";
        errorCount = errorCount + 1;
        
        if(errorClass === "") { errorClass = "input-topic" }
    }
    
    if(errorCount > 0) {
        setErrorMessageData(_errorMessageData);
        scrollToClass(`.${errorClass}`);
    }else {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      setIsLoadingEditTopicAccount(true);

      let topics = [];
      
      selectedTopic.map((v) => {
        topics.push(v.id);
        return null;
      })

      IntegrationInstagramServices.editTopic(
        orgID,
        match.params.accountID,
        topics,
        (response) => {
          if (response.dataResult.status === 200) {
            if (!response.dataResult.data.success) {
              doToast(response.dataResult.data.message);
              setIsLoadingEditTopicAccount(false);
            } else {
              if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
                mixpanel.track(
                  "[Action] Edit Topic",
                  {
                    channel : "Instagram",
                    userFullName : myAgentData.account.fullName,
                    userID : myAgentData.account.id,
                    organizationID : orgID.split("-")[0]
                  }
                );
              }
              
              // setAccountTopicInitial(accountTopicVal);
              setSelectedTopicOrigin(selectedTopic);
              
              setTimeout(() => {
                history.push(
                  `/o/${orgID}/integration/instagram/detail/${match.params.accountID}`,
                  {
                    state: 'edit-topic',
                    message: response.dataResult.data.message,
                  }
                );
              }, 3000)
              doToast(response.dataResult.data.message);
            }
          } else {
            doToast(response.dataResult.error.message, 'fail');
            setIsLoadingEditTopicAccount(false);
          }
        }
      );
    }
  };

  // let onSelectTopic = (value) => {
  //   setAccountTopicVal(value);
  // };

  // let isFormValid = () => {
  //   // let _accountTopicVal = accountTopicVal.value;
  //   return (
  //     selectedTopic.length !== 0 && (JSON.stringify(selectedTopic) !== JSON.stringify(selectedTopicOrigin))
  //   );
  // };

  let back = (id) =>
    history.push(`/o/${orgID}/integration/instagram/detail/${id}`);

  let onChangeSingleSelect = (e, val) => {
    if(!isLoadingEditTopicAccount) {
      let _selectedTopic = [...selectedTopic];
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);
  
      if (e.target.checked) {
          _selectedTopic = [..._selectedTopic, val]
      } else {
          let _dataSelectedTopic = [..._selectedTopic]
          _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)
          setIsCheckAll(false);
      }
  
      setSelectedTopic(_selectedTopic);
    }
  }

  let onClearAll = () => {
    if(!isLoadingEditTopicAccount) {
      setIsCheckAll(false);
      setSelectedTopic([]);
    }
  }

  let onCheckAll = (e) => {
    if(!isLoadingEditTopicAccount) {
      let _errorMessageData = {...errorMessageData};
      _errorMessageData.topic = "";
      setErrorMessageData(_errorMessageData);

      if (e.target.checked) {
          setSelectedTopic(topicList);
      } else {
          setSelectedTopic([]);
      }
  
      setIsCheckAll(e.target.checked);
    }
  }

  let isChecked = (id) => {
    let _data = [...selectedTopic]
    return _data.findIndex(val => val.id === id) !== -1;
  }

  let removeSingleTopic = (val) => {
    setIsCheckAll(false);
    let _selectedTopic = [...selectedTopic];
    let _dataSelectedTopic = [..._selectedTopic]

    _selectedTopic = _dataSelectedTopic.filter(el => el.id !== val.id)

    setSelectedTopic(_selectedTopic);
  }

  let onChangeSearchTopic = (e) => {
    let _topicOrigin = topicListOrigin.slice();
    let result = [];
    setSearchTopic(e.target.value);

    if (_topicOrigin.length > 0) {
        for (let i in _topicOrigin) {
        let objectKey = Object.keys(_topicOrigin[i]);

        for (let j in objectKey) {
            let isString = typeof _topicOrigin[i][objectKey[j]] === "string";
            if (_topicOrigin[i][objectKey[j]].toString().toLowerCase().includes(e.target.value.toLowerCase()) && isString) {
            result.push(_topicOrigin[i])
            break;
            }
        }
        }
    }

    setTopicList(result);
  }

  return (
    <div className="integration-outer-wrapper">
      <SectionWrap 
        {...props} 
        orgID={orgID}
        withHeader 
        withSideMenu
        dataOrigin={JSON.stringify(selectedTopicOrigin)}
        dataAfterChange={JSON.stringify(selectedTopic)}
      >
        <div className="sectionWrap integration-main-container integration-instagram-edit-topic-account text-left">
          {isLoadingGetDetailData || retriveDataError.code ? (
            <div className="integration-detail-loading-wrapper">
              <RetrieveDataLoading 
                isLoading={isLoadingGetDetailData}
                errorMessage={retriveDataError.message} 
              />
            </div>
          ) : (
            <>
              <BackButton
                text={`Back to ${accountDetail.igName}`}
                onClick={() => back(accountDetail.id)}
              />

              <div className="integration-instagram-edit-topic-account-box">
                <div className="integration-instagram-edit-topic-account-box-top">
                  <b>Edit Topic</b>

                  {!isLoadingEditTopicAccount ? (
                    <ButtonWithLoadingOrIcon 
                        // isDisabled={!isFormValid()}
                        onClickAction={editTopicInstagramAccount}
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                    />
                  ) : (
                    <ButtonWithLoadingOrIcon 
                        isLoading
                        loadingColor="gray"
                        isDisabled
                        className="orange-button main-button-40"
                        text="Save Changes"
                        position="left"
                    />
                  )}
                </div>
                <div className="integration-instagram-edit-topic-account-box-form">
                  <div className="form-topic">
                    {/* <label>
                      <b>Topic</b>
                    </label>
                    <CustomSelectOption
                      optionListProps={topicList}
                      valueDropdownProps={accountTopicVal}
                      placeholderProps={'Select account topic'}
                      onClickDropDownListOptionProps={onSelectTopic}
                      specificClassNameProps="custom-select-instagram-topic"
                    />
                    <span className="integration-info-form">
                      Incoming messages on this channel will be assigned to this
                      topic.
                    </span> */}
                    <SelectTopic 
                        onChangeSearchTopic={onChangeSearchTopic}
                        onCheckAll={onCheckAll}
                        onClearAll={onClearAll}
                        topicList={topicList}
                        onChangeSingleSelect={onChangeSingleSelect}
                        isChecked={isChecked}
                        isCheckAll={isCheckAll}
                        searchTopic={searchTopic}
                        title={"Topic"}
                        selectedTopic={selectedTopic}
                        removeSingleTopic={removeSingleTopic}
                    />
                      
                    <ErrorOrInfoComp
                        text={errorMessageData.topic === "" ? "Customer will be able to choose if you select more than one topics." : errorMessageData.topic}
                        _className={errorMessageData.topic !== "" ? "font-red" : ""}
                        icon={<FiInfo />}
                    />

                    {/* <p className="input-info-text no-margin-bottom width-100">
                      <FiInfo />
                      <b>Customer will be able to choose if you select more than one topics.</b>
                    </p> */}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </SectionWrap>
    </div>
  );
};

export default IntegrationInstagramEditTopicAccount;
