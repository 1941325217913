import React, { useState, useEffect } from 'react';
import './ChatView.scss';
// import CasePanel from '../../components/casePanel/CasePanel';
import { taptalk, tapCoreChatRoomManager, tapCoreRoomListManager } from '@taptalk.io/web-sdk';
import { connect } from 'react-redux';
import { ToastContainer } from "react-toastify";
import { FiRefreshCw } from "react-icons/fi";
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import RoomList from '../../components/roomList/RoomList';
// import AccountPanel from '../../components/chatRoom/accountPanel/accountPanel';
import RoomListSetupModal from '../../components/roomList/roomListSetupModal/RoomListSetupModal';
import ChatRoom from '../../components/chatRoom/ChatRoom';
// import ClockHeader from "../../components/clockHeader/ClockHeader";
import ClockTutorial from "../../components/chatRoom/clockTutorial/ClockTutorial";
import TierBlockingScreen from '../../components/reuseableComponent/tierBlockingScreen/TierBlockingScreen';
import ModalPreviewImageOrVideo from '../../components/reuseableComponent/modalPreviewImageOrVideo/ModalPreviewImageOrVideo';
import { setActiveRoom, clearActiveRoom } from '../../redux/actions/reduxActionActiveRoom';
import { setActiveCasePanel } from '../../redux/actions/reduxActionActiveCasePanel';
import { setLogout } from '../../redux/actions/reduxLogout';
import { setQuickReplyList } from "../../redux/actions/reduxActionQuickReplyList";
import { setCaseLabelList } from '../../redux/actions/reduxActionCaseLabelList';
import { setClockState } from "../../redux/actions/reduxActionClockState";
import { clearReplyMessage } from '../../redux/actions/reduxActionReplyMessage';
import { setCaseDetailData } from "../../redux/actions/reduxActionCaseDetailData";
import { setShowOtherCase } from "../../redux/actions/reduxActionShowOtherCase";
import { setAllowReplyOnAway } from '../../redux/actions/reduxActionAllowReplyOnAway';
import { setAllTopicList } from "../../redux/actions/reduxActionAllTopicList";
import { setDraftMessage } from "../../redux/actions/reduxActionDraftMessage";
import { setMyTopics } from "../../redux/actions/reduxActionMyTopics";
import { setNeedReplyColorIndicator } from "../../redux/actions/reduxActionNeedReplyColorIndicator";
import { setReconnectRoomList } from "../../redux/actions/reduxActionReconnectRoomList";
import { setReconnectChatRoom } from "../../redux/actions/reduxActionReconnectChatRoom";
import { setAllowHandover } from "../../redux/actions/reduxActionAllowHandover";
import HelperAuth from "../../helper/HelperAuth";
import HelperChat from '../../helper/HelperChat';
import { doToast } from '../../helper/HelperGeneral';
import { OTHER_TAB_OPT } from '../../helper/HelperConst';
import HelperCustomEncryptor from "../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../helper/HelperGetLocalStorage";
import ClockService from "../../services/chat/clockServices";
import ChatService from '../../services/chat/caseServices';
import ServiceQuickReply from "../../services/newServices/QuickReplyService";
import ServiceOrganization from "../../services/organization/ServiceOrganization";
import topicServices from "../../services/newServices/TopicServices";
import InboxConfigServices from "../../services/newServices/InboxConfigServices";
// import WebWorker from "../../helper/HelperWebWorker";
import CaseLabelService from '../../services/newServices/CaseLabelService';
import ModalStartConversationBalance from '../modalStartConversationBalance/ModalStartConversationBalance';
import mixpanel from "mixpanel-browser";

const CONNECTING_STATUS = {
  disconnected: 1,
  loading: 2,
  connected: 3
};

const SETUP_ROOM_MODAL_STATUS = {
  loading: 1,
  success: 3,
  fail: 4
}

const CLOCK_STATE = {
  isOff: "off",
  isStarted: "started",
  isPaused: "paused"
}

// const ROLE_CODE = {
//   owner: 'owner',
//   member: 'member',
//   admin: 'admin',
//   all: 'all'
// }

var ChatView = (props) => {
  let [isSetupModalViewVisible, setIsSetupModalViewVisible] = useState(true);
  let [setupModalView, setSetupModalView] = useState(SETUP_ROOM_MODAL_STATUS.loading);
  let [setupModalText, setSetupModalText] = useState('Starting Your Inbox')

  let [messageListenerNewMessage, setMessageListenerNewMessage] = useState(null);
  let [messageListenerUpdateMessage, setMessageListenerUpdateMessage] = useState(null);

  let [listenerStartTyping, setListenerStartTyping] = useState(null);
  let [listenerStopTyping, setListenerStopTyping] = useState(null);
  let [listenerUserOnline, setListenerUserOnline] = useState(null);

  let [forceRefreshRoom, setForceRefreshRoom] = useState(false);

  let [markAsSolvedPanelShow, setMarkAsSolvedPanelShow] = useState(false);

  let [caseDetailVal, setCaseDetailVal] = useState(null);

  let [connectingStatus, setConnectingStatus] = useState(CONNECTING_STATUS.loading);

  let [newEmitMessage, setNewEmitMessage] = useState(null);

  // let [clockInDuration, setClockInDuration] = useState(0);
  // let [clockInTime, setClockInTime] = useState(null);
  let [agentDetail, setAgentDetail] = useState(null);

  let [topicList, setTopicList] = useState(null);

  let [isModalRefreshReconnectShow, setIsModalRefreshReconnectShow] = useState(false);

  let [isFinishFethcingCurrentTierDetail, setIsFinishFethcingCurrentTierDetail] = useState(false);
  let [currentTierDetail, setCurrentTierDetail] = useState({
    code: "",
    id: 0,
    isActive: false,
    isFreeTrial: false,
    name: "",
    time: false,
    statusText: ""
  });
  let [currentMembership, setCurrentMembership] = useState(null);
  let [currentOrganization, setCurrentOrganization] = useState(null);
  let [organizationError, setOrganizationError] = useState({
    code: 0,
    message: ""
  });
  let [lastConnect, setLastConnect] = useState(false);

  //reconnect
  useEffect(() => {
    if(lastConnect) {
      let _reconChatRoom = props.reconnectChatRoom;
      let _reconRoomList = props.reconnectRoomList;
  
      if(_reconRoomList === false) {
        props.setReconnectRoomList(0);
      }else {
        props.setReconnectRoomList(new Date().valueOf());
      }
  
      if(_reconChatRoom === false) {
        props.setReconnectChatRoom(0);
      }else {
        props.setReconnectChatRoom(new Date().valueOf());
      }
    }
  }, [lastConnect])
  //reconnect

  // let findMemberRole = () => {
  //     let organizationID =  props.match.params.orgID.split("-")[0];
  //     let userData = {...props.userData};
  //     userData = HelperCustomEncryptor.doDecrypt(userData.data);

  //     let findOrganization = () => {
  //         let _findIndex = userData.memberships.findIndex(value => value.organization.id === parseInt(organizationID));

  //         return _findIndex;
  //     }

  //     if(userData.memberships.length === 0) {
  //         return ROLE_CODE.all;
  //     }else {
  //         return findOrganization() !== -1 ? userData.memberships[findOrganization()].roleCode : ROLE_CODE.all
  //     }
  // }

  let runGetOrganizationDetail = () => {
    ServiceOrganization.postGetOrganizationDetail(props.match.params.orgID, (response) => {
      if (response.dataResult.status === 200) {
        setTimeout(() => {
          setCurrentMembership(response.dataResult.data.membership);
          setCurrentOrganization(response.dataResult.data.organization);

          response.dataResult.data.selectedTier.time = new Date().valueOf();
          setCurrentTierDetail(response.dataResult.data.selectedTier);
        }, 2000)
      } else {
        let data = {
          code: response.dataResult.error.code,
          message: response.dataResult.error.message
        }

        setTimeout(() => {
          setOrganizationError(data);
        }, 2000);
      }
    })
  }

  let runGetOganization = () => {
    ServiceOrganization.postGetOrganization((response) => {
      if (response.dataResult.error.message === "") {
        let responseData = response.dataResult.data;

        if (responseData.memberships.length < 1) {
          window.location.href = "/organization/new";
        } else {
          props.history.push(`/o/${responseData.memberships[0].organization.id}-${responseData.memberships[0].organization.createdTime}/home`);
        }

      } else {
        doToast(response.dataResult.error.message, "fail");
        setSetupModalView(SETUP_ROOM_MODAL_STATUS.fail);
      }
    })
  }

  useEffect(() => {
    if (organizationError.code !== 0) {
      setIsFinishFethcingCurrentTierDetail(true);
      setIsSetupModalViewVisible(false);
    }
  }, [organizationError])

  useEffect(() => {
    if (currentMembership !== null) {
      setIsFinishFethcingCurrentTierDetail(true);
    }
  }, [currentMembership])

  useEffect(() => {
    if (currentTierDetail.time) {
      if (currentTierDetail.id === 0) {
        setIsSetupModalViewVisible(false);
      } else {
        getAgentDetailAndStatusAction();
      }
    }
  }, [currentTierDetail])

  let toggleMarkAsSolvedPanel = (shouldChangeChatRoomHeight = true) => {
    if (shouldChangeChatRoomHeight) {
      let elChatRoomMain = document.getElementsByClassName("chat-room-main-wrapper")[0];
      let elChatRoomSolve = document.getElementsByClassName("taplive-main-chat-room-solve-wrapper")[0];

      if (elChatRoomMain && elChatRoomSolve) {
        let chatRoomContainerHeight = elChatRoomMain.style.maxHeight === "" ? 158 : parseInt(elChatRoomMain.style.maxHeight.split("-")[1].replace("px", ""));
        if (markAsSolvedPanelShow) {
          //remove mark as resolve
          if (elChatRoomMain.style.maxHeight !== "") {
            let heightNew = chatRoomContainerHeight - 40;
            elChatRoomMain.style.setProperty("max-height", "calc(100vh - " + heightNew + "px)", "important");
          }
        } else {
          //show mark as resolve
          let heightNew = chatRoomContainerHeight + 40;
          elChatRoomMain.style.setProperty("max-height", "calc(100vh - " + heightNew + "px)", "important");
        }
      }
    }

    setMarkAsSolvedPanelShow(!markAsSolvedPanelShow);
  }

  useEffect(() => {
    window.addEventListener('offline', function (event) {
      setConnectingStatus(CONNECTING_STATUS.disconnected);
      taptalk.disconnect();
    });

    window.addEventListener('online', function (event) {
      connect(true);
    });

    document.addEventListener('visibilitychange', function() {
      if(document.visibilityState === "visible" && navigator.onLine) {
        getQuickReplyList();
      }
    })

    // getQuickReplyList();
    getInboxSetting();
  }, [])

  useEffect(() => {
    if (props.activeCasePanel === null) {
      props.setActiveCasePanel('open');
    }

    if (props.match.params.orgID) {
      runGetOrganizationDetail();
    } else {
      runGetOganization();
    }

    // getAgentDetailAndStatusAction();

    // ChatService.postProjectConfig(props.match.params.orgID).then(response => {
    //   let data = response.dataResult.data.tapTalk;

    //   if(response.dataResult.error.code === "") {
    //     taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL);
    //     taptalkAuthTicket();
    //   }else {
    //     setSetupModalView(SETUP_ROOM_MODAL_STATUS.fail);
    //     doToast(response.dataResult.error.message);
    //   }

    // });

    // onExpiredTokenListener();
  }, [props.match.params.orgID])

  // useEffect(() => {
  //   if(props.clockState === CLOCK_STATE.isOff) {
  //     setClockInDuration(0);
  //   }

  //   let intervalClockInDuration = setInterval(function() {
  //       setClockInDuration(clockInDuration => clockInDuration + 1)
  //   }, 1000);

  //   if((props.clockState === CLOCK_STATE.isOff) || (props.clockState === CLOCK_STATE.isPaused)) {
  //     clearInterval(intervalClockInDuration);
  //   }

  //   return () => clearInterval(intervalClockInDuration);
  // }, [props.clockState])

  let getInboxSetting = () => {
    InboxConfigServices.getConfig(props.match.params.orgID, (response) => {
      let dataResult = response.dataResult;
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
      
      if (dataResult.error.message === "") {
        let _showOtherTab = true;
        let _showOtherCase = {...props.showOtherCase};
        let _indexAgent = dataResult.data.members.findIndex(v => v.accountID === myAgentData.account.id);

        
        if(_indexAgent !== -1) {
          if(dataResult.data.members[_indexAgent].showTabOthers === OTHER_TAB_OPT[0].value) {
            _showOtherTab = dataResult.data.inboxConfigs.showTabOthers;
          }

          if(dataResult.data.members[_indexAgent].showTabOthers === OTHER_TAB_OPT[2].value) {
            _showOtherTab = false;
          }
        }

        _showOtherCase.configShow = _showOtherTab;
        props.setShowOtherCase(_showOtherCase);

        props.setAllowReplyOnAway(dataResult.data.inboxConfigs.allowReplyOnAway);
        props.setAllowHandover(dataResult.data.inboxConfigs.allowHandoverCase);
      }else {
        doToast(dataResult.error.message, 'fail');
      }
    })
  }

  let getQuickReplyList = () => {
    ServiceQuickReply.getQuickReplyList(props.match.params.orgID, (response) => {
      let dataResult = response.dataResult;
      let quickReplyList = [];

      if (dataResult.error.message === "") {
        quickReplyList = quickReplyList.concat(dataResult.data.general, dataResult.data.personal);
        props.setQuickReplyList(quickReplyList);
      }else {
        doToast(dataResult.error.message, 'fail');
      }
    })
  }

  let getAgentDetailAndStatusAction = () => {
    let orgID = props.match.params.orgID;

    ClockService.postGetAgentDetail(orgID, (response) => {
      let dataResult = response.dataResult;
      let topicsHashMap = {};

      if (dataResult.error.code === "") {
        if ((dataResult.data.clock.clockState === CLOCK_STATE.isStarted) || (dataResult.data.clock.clockState === CLOCK_STATE.isPaused)) {
          setTimeout(() => {
            runChatview();
          }, 100);
        } else {
          setTimeout(() => {
            setIsSetupModalViewVisible(false);
            setSetupModalView(SETUP_ROOM_MODAL_STATUS.success);
          }, 100);
        }

        props.setClockState(dataResult.data.clock.clockState)
        // setClockInDuration(dataResult.data.clock.clockInDuration);
        setAgentDetail(dataResult.data.agent);

        for (let i in dataResult.data.topics) {
          dataResult.data.topics[i].value = dataResult.data.topics[i].id;
          dataResult.data.topics[i].label = dataResult.data.topics[i].name;

          topicsHashMap[dataResult.data.topics[i].id] = dataResult.data.topics[i];
        }

        setTopicList(topicsHashMap);
        props.setMyTopics(dataResult.data.topics);
      } else {
        setSetupModalView(SETUP_ROOM_MODAL_STATUS.fail);
        doToast(dataResult.error.message, 'fail');
      }
    })
  }

  let startClockInAction = (isAway) => {
    let orgID = props.match.params.orgID;

    initilizeSetupModalViewWebsocket();

    ClockService.postAgentClockIn(orgID, isAway, (response) => {
      let dataResult = response.dataResult;

      if (dataResult.error.code === "") {
        props.setClockState(dataResult.data.clockState);
        runChatview();
      } else {
        doToast(dataResult.error.message, 'fail');
      }
    });
  }

  let initilizeSetupModalViewWebsocket = () => {
    setSetupModalText('Preparing Your Inbox');
    setSetupModalView(SETUP_ROOM_MODAL_STATUS.loading);
    setIsSetupModalViewVisible(true);
  }

  let getCaseLabelList = () => {
    CaseLabelService.getCaseLabelList(props.match.params.orgID, "", (response) => {
      if (response.dataResult.status === 200) {
        const caseLabel = response.dataResult.data.caseLabels
        props.setCaseLabelList(caseLabel);
        taptalkAuthTicket()
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let getTopicList = () => {
    topicServices.getTopic(props.match.params.orgID, "list", null, (response) => {
      if (response.dataResult.status === 200) {
        const topiclist = response.dataResult.data.topics;
        topiclist.map((val) => {
          val.value = val.id;
          val.label = val.name;
          return null;
        })
        props.setAllTopicList(topiclist);
      } else {
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let getDataNeedReplyColor = () => {
    InboxConfigServices.getNeedReplyConfig(props.match.params.orgID, (response) => {
        let dataResult = response.dataResult;

        if(dataResult.error.message === "") {
            let _data = {...dataResult.data};
            
            props.setNeedReplyColorIndicator(_data.needReplyIndicator);
        }else {
            
        }
    })
}

  let runChatview = () => {
    initilizeSetupModalViewWebsocket();

    ChatService.postProjectConfig(props.match.params.orgID, (response) => {
      let data = response.dataResult.data.tapTalk;

      if (response.dataResult.error.code === "") {
        taptalk.init(data.appKeyID, data.appKeySecret, data.apiURL, true);
        getCaseLabelList();
        getTopicList();
        getDataNeedReplyColor();
        // taptalkAuthTicket();
      } else {
        setSetupModalView(SETUP_ROOM_MODAL_STATUS.fail);
        doToast(response.dataResult.error.message);
      }

    });

    onExpiredTokenListener();
  }

  let taptalkAuthTicket = () => {
    ChatService.postRequestAuthTicket(props.match.params.orgID, (response) => {
      if (response.dataResult.error.code === "") {
        taptalk.authenticateWithAuthTicket(response.dataResult.data.ticket, false, {
          onSuccess: () => {
            connect();
          },
          onError: (errorCode, errorMessage) => {
            setSetupModalView(SETUP_ROOM_MODAL_STATUS.fail);
            doToast(errorMessage, 'fail')
            console.log(errorCode, errorMessage);
          }
        })
      } else {
        setSetupModalView(SETUP_ROOM_MODAL_STATUS.fail);
        doToast(response.dataResult.error.message, 'fail');
      }
    })
  }

  let connect = (isInternetBackOnline = false) => {
    setConnectingStatus(CONNECTING_STATUS.loading);

    taptalk.connect({
      onSuccess: (successMessage) => {
        setTimeout(() => {
          setIsSetupModalViewVisible(false);
          setSetupModalView(SETUP_ROOM_MODAL_STATUS.success);
        }, 1000);
        setConnectingStatus(CONNECTING_STATUS.connected);
        console.log(successMessage)
        setLastConnect(new Date().valueOf())
      },
      onError: (errorMessage) => {
        setTimeout(() => {
          setSetupModalView(SETUP_ROOM_MODAL_STATUS.fail);
          doToast(errorMessage, 'fail');
        }, 1000);

        setConnectingStatus(CONNECTING_STATUS.disconnected);
        console.log(errorMessage);
      },
      onClose: (message) => {
        console.log(message);
        setConnectingStatus(CONNECTING_STATUS.disconnected);

        if (navigator.onLine && !isInternetBackOnline) {
          connect();
        }
      }
    });
  }

  let onExpiredTokenListener = () => {
    taptalk.addTapListener({
      onTapTalkRefreshTokenExpired: () => {
        setIsModalRefreshReconnectShow(true);
      }
    })
  }

  useEffect(() => {
    if (HelperAuth.userWasLoggedIn()) {
      let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);

      if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
        mixpanel.init(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY);
        mixpanel.identify(myAgentData.account.id);
  
        mixpanel.people.set({
          email: myAgentData.account.email,
          name: myAgentData.account.fullName,
          userID: myAgentData.account.id,
          organizationID: props.match.params.orgID ? props.match.params.orgID.split("-")[0] : ""
        });
        mixpanel.track(
          "[Page] Inbox",
          {
            userFullName: myAgentData.account.fullName,
            userID: myAgentData.account.id,
            organizationID: props.match.params.orgID ? props.match.params.orgID.split("-")[0] : ""
          }
        );
      }

    }

    setTimeout(function () {
      messageListenerChatRoom();
      roomStatusListener();
    }, 0);
  }, []);

  const isFirefox = typeof InstallTrigger !== 'undefined';

  useEffect(() => {
    if ('Notification' in window) {
      if (!isFirefox && Notification.permission !== 'denied') {
        Notification.requestPermission()
      }

      const onBodyClick = () => {
        if (isFirefox && Notification.permission !== 'denied') {
          Notification.requestPermission()
        }
      }

      document.body.addEventListener('click', onBodyClick);

      if (Notification.permission === 'granted' || Notification.permission === 'denied') {
        document.body.removeEventListener('click', onBodyClick);
      };
    }
  }, [])

  let messageListenerChatRoom = () => {
    tapCoreChatRoomManager.addMessageListener(
      {
        onReceiveNewMessage: (messageModel) => {
          // console.log("on receive new message 1", messageModel);
          setMessageListenerNewMessage(messageModel);
        },

        onReceiveUpdateMessage: (messageModel) => {
          // console.log("on receive update message 1", messageModel);
          setMessageListenerUpdateMessage(messageModel);
        },
      }
    )
  }

  let roomStatusListener = () => {
    tapCoreChatRoomManager.addRoomStatusListener(
      {
        onReceiveStartTyping: (roomID, user) => {
          // console.log("on receive onReceiveStartTyping2", roomID, user);
          setListenerStartTyping({ roomID: roomID, user: user });
        },

        onReceiveStopTyping: (roomID, user) => {
          // console.log("on receive onReceiveStopTyping", roomID, user);
          setListenerStopTyping({ roomID: roomID, user: user });
        },

        onReceiveOnlineStatus: (user, isOnline, lastActive) => {
          // console.log("on receiveonReceiveOnlineStatus", user, isOnline, lastActive);
          setListenerUserOnline({ user: user, isOnline: isOnline, lastActive: lastActive });
        }
      }
    )
  };

  let onClickRoomList = async (room, caseDetail, runReset = true) => {
    if(JSON.stringify(room) !== JSON.stringify(props.activeRoom)) {
      let runDraftMessage = async () => {
        let elInput = document.querySelectorAll(".main-textarea-input");
        let _activeRoom = {...props.activeRoom};
        let _draftMessage = {...props.draftMessage};
        
        if(elInput.length > 0 && JSON.stringify(_activeRoom) !== "{}") {
          let val = elInput[0].value;
          _draftMessage[_activeRoom.roomID] = val === "" ? false : val;
          props.setDraftMessage(_draftMessage);
        }
  
        if(runReset) {
          HelperChat.resetChatRoomHeightAndInputText();
        }
  
        if(_draftMessage[room.roomID] && elInput.length > 0) {
          elInput[0].value = _draftMessage[room.roomID];
          elInput[0].dispatchEvent(new Event('input', { bubbles: true }));
        }
  
      }
  
      runDraftMessage();
    
      setCaseDetailVal(caseDetail);
      setMarkAsSolvedPanelShow(false);
      props.setCaseDetailData(null);
  
      if (props.activeRoom !== null) {
        tapCoreChatRoomManager.sendStopTypingEmit(props.activeRoom.roomID);
  
        // if(props.activeRoom.roomID !== room.roomID) {
        // }
      }
  
      // HelperChat.resetHeightClearReply(props.replyMessage);
      // HelperChat.resetChatRoomHeightAndInputText();
      props.clearReplyMessage();
  
      props.setActiveRoom(room);
      // webWorkerChangeRoom.postMessage({valChatData: valChatData, caseDetail: caseDetail});
    }
  }

  let undoForceRefreshRoom = () => {
    setForceRefreshRoom(false);
  }

  let deleteLocalChatRoom = () => {
    tapCoreRoomListManager.removeChatRoomByRoomID(props.activeRoom.roomID);
    props.setActiveRoom(null);
    setForceRefreshRoom(true);
  }

  // let changeActiveCasePanel = (panel) => {
  //   setActivePanelCase(panel);
  //   props.clearActiveRoom();
  // }

  let setNewEmitMessageAction = (message) => {
    //update room list last message before getting new message emit
    setNewEmitMessage(message);
  }

  let toggleModalRefreshReconnectShow = () => {
    setIsModalRefreshReconnectShow(!isModalRefreshReconnectShow);
  }

  let modalConfirmationClock = () => {
    return (
      <div>
        <Modal isOpen={isModalRefreshReconnectShow} toggle={toggleModalRefreshReconnectShow} className="modal-refresh-on-reconnect">
          <ModalBody>
            <p>
              <b>Your Chat Session Has Expired</b>
            </p>

            <p>
              Please click the button below to refresh the page so you can continue where you left off.
                </p>
          </ModalBody>

          <ModalFooter>
            <button className="orange-button" onClick={() => {
              window.location.reload();
            }}>
              <FiRefreshCw />
                Refresh Page
              </button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  return (
    <div className="chat-view-container">
      <ToastContainer className="ToastContainer" />
      <ModalPreviewImageOrVideo />

      {isFinishFethcingCurrentTierDetail &&
        (organizationError.code !== 0 ?
          <TierBlockingScreen
            {...props}
            currentTierDetailProps={currentTierDetail}
            currentMembershipProps={currentMembership}
            organizationErrorProps={organizationError}
            goHomeButton
          />
          :
          currentTierDetail.id === 0 &&
          <TierBlockingScreen
            {...props}
            currentTierDetailProps={currentTierDetail}
            currentMembershipProps={currentMembership}
            organizationErrorProps={organizationError}
            goHomeButton
          />
        )
      }

      {isSetupModalViewVisible &&
        <RoomListSetupModal setupModalProps={setupModalView} setupModalTextProps={setupModalText} />
      }

      {setupModalView === SETUP_ROOM_MODAL_STATUS.success &&
        <React.Fragment>
          {/* <ClockHeader
            clockInDurationProps={clockInDuration}
            agentDetailProps={agentDetail}
            startClockInActionProps={startClockInAction}
            parentProps={props}
          /> */}

          {props.clockState === CLOCK_STATE.isOff ?
            <ClockTutorial  
              {...props}
              agentDetailProps={agentDetail}
              currentOrganizationProps={currentOrganization}
              startClockInActionProps={startClockInAction}
            />
            :
            <>
              {/* <ClockHeader
                clockInDurationProps={clockInDuration}
                agentDetailProps={agentDetail}
                startClockInActionProps={startClockInAction}
                parentProps={props}
              /> */}
              
              <div className="main-chat-container">
                {/* <CasePanel changeActiveCasePanelProps={changeActiveCasePanel} /> */}
                {/* <AccountPanel 
                  {...props}
                  agentDetailProps={agentDetail}
                  currentOrganizationProps={currentOrganization}
                  // startClockInActionProps={startClockInAction}
                /> */}

                <ModalStartConversationBalance 
                  currentMembership={currentMembership} 
                  {...props}
                />

                <RoomList
                  {...props}
                  onClickRoomListProps={onClickRoomList}
                  messageListenerNewMessageProps={messageListenerNewMessage}
                  messageListenerUpdateMessageProps={messageListenerUpdateMessage}
                  forceRefreshRoomProps={forceRefreshRoom}
                  undoForceRefreshRoomProps={undoForceRefreshRoom}
                  parentProps={props}
                  connectingStatusProps={connectingStatus}
                  listenerStartTypingProps={listenerStartTyping}
                  listenerStopTypingProps={listenerStopTyping}
                  newEmitMessageProps={newEmitMessage}
                  topicListProps={topicList}
                  agentDetailProps={agentDetail}
                  currentOrganizationProps={currentOrganization}
                  setNewEmitMessageProps={setNewEmitMessageAction}
                // setupModal={setupModalView}
                />

                <ChatRoom 
                  {...props}
                  messageListenerNewMessageProps={messageListenerNewMessage}
                  messageListenerUpdateMessageProps={messageListenerUpdateMessage}
                  listenerStartTypingProps={listenerStartTyping}
                  listenerStopTypingProps={listenerStopTyping}
                  listenerUserOnlineProps={listenerUserOnline}
                  deleteLocalChatRoomProps={deleteLocalChatRoom}
                  toggleMarkAsSolvedPanelProps={toggleMarkAsSolvedPanel}
                  markAsSolvedPanelShowProps={markAsSolvedPanelShow}
                  parentProps={props}
                  caseDetailValProps={caseDetailVal}
                  setNewEmitMessageProps={setNewEmitMessageAction}
                  onClickRoomListProps={onClickRoomList}
                  topicListProps={topicList}
                  lastConnectProps={lastConnect}
                  setMessageListenerNewMessage={setMessageListenerNewMessage}
                />
              </div>
            </>
          }

          {modalConfirmationClock()}
        </React.Fragment>
      }
    </div>
  );
}

const mapStateToProps = state => ({
  activeRoom: state.activeRoom,
  activeCasePanel: state.activeCasePanel,
  startConversation: state.startConversation,
  user: state.user,
  previewImageOrVideo: state.previewImageOrVideo,
  clockState: state.clockState,
  replyMessage: state.replyMessage,
  modalConfirmSendTemplateMessage: state.modalConfirmSendTemplateMessage,
  showOtherCase: state.showOtherCase,
  allowReplyOnAway: state.allowReplyOnAway,
  caseLabelList: state.caseLabelList,
  draftMessage: state.draftMessage,
  reconnectRoomList: state.reconnectRoomList,
  reconnectChatRoom: state.reconnectChatRoom,
  allowHandover: state.allowHandover
});

const mapDispatchToProps = {
  setActiveRoom,
  clearActiveRoom,
  setActiveCasePanel,
  setLogout,
  setQuickReplyList,
  setClockState,
  clearReplyMessage,
  setCaseLabelList,
  setCaseDetailData,
  setShowOtherCase,
  setAllowReplyOnAway,
  setAllTopicList,
  setDraftMessage,
  setMyTopics,
  setNeedReplyColorIndicator,
  setReconnectChatRoom,
  setReconnectRoomList,
  setAllowHandover
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatView);
