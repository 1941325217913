import React, { useState, useEffect } from "react";
// import Header from "../header/Header";
// import Sidemenu from "../sidemenu/Sidemenu";
import "./SectionWrap.scss";
import { connect } from 'react-redux';
import { setActiveOrgID } from "../../../redux/actions/reduxActionActiveOrgID";
import { ToastContainer } from "react-toastify";
import PopupDiscard from "../popupDiscard/PopupDiscard";
import BlockingDiscardChanges from "../blockingDiscardChanges/BlockingDiscardChanges";
import "react-toastify/dist/ReactToastify.css";

const SectionWrap = ({ 
  withHeader, 
  withSideMenu, 
  dataOrigin = false, 
  dataAfterChange = false, 
  ...props 
}) => {
  const { children: content } = props;
  const [isShowModalDiscard, setIsShowModalDiscard] = useState(false);
  const [condition, setCondition] = useState(false);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  
  useEffect(() => {
    if((dataOrigin && dataAfterChange)) {
      setCondition( JSON.stringify({...dataOrigin}) !== JSON.stringify({...dataAfterChange}) );
    } 
  }, [JSON.stringify(dataOrigin), JSON.stringify(dataAfterChange)])
  

  const toggleModalDiscard = () => {
    setIsShowModalDiscard(!isShowModalDiscard)
  }
  // const { children: content, orgID } = props;
  // const dataID = orgID || "";
  
  // const baseProps = {
  //   organizationID: dataID,
  //   projectEnvID: ""
  // };

  let isSubmenuExist = () => {
    return typeof props.sideMenu === "object" && props.sideMenu.submenuList;
  }

  // useEffect(() => {
  //   props.setActiveOrgID(dataID);
  // }, [orgID])
  const handleOpenModal = (isOpen) => {
    setIsShowModalDiscard(isOpen)
  }

  const discardProgress = () => {
    toggleModalDiscard()
    setConfirmedNavigation(true)
  }

  return (
    <React.Fragment>
      {/* {withHeader && <Header {...baseProps} />} */}
      {/* {withSideMenu && <Sidemenu {...props} {...baseProps} />} */}
      <div id={`sectionContent`} className={`sectionContent ${!isSubmenuExist() ? "deactive-sub-menu" : ""} ${(process.env.REACT_APP_HIDE_BILLING !== "true" && props.billingWarning) ? "section-wrap-active-billing-warning" : ""}`}>
        {/* <div className="sectionWrap">{content}</div> */}
        {content}
      </div>

      <BlockingDiscardChanges
        openModal={handleOpenModal}
        condition={condition}
        confirmedNavigation={confirmedNavigation}
      />

      <PopupDiscard 
        dataOrigin={dataOrigin}
        dataAfterChange={dataAfterChange}
        isOpenDiscard={isShowModalDiscard}
        toggleDiscard={toggleModalDiscard}
        discardProgress={discardProgress}
        discardDesc={props.discardDesc}
      />
      
      <ToastContainer className="ToastContainer" />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  sideMenu: state.sideMenu,
  billingWarning: state.billingWarning
});

const mapDispatchToProps = {
  setActiveOrgID
};

export default connect(mapStateToProps, mapDispatchToProps)(SectionWrap)

