import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import "./IntegrationChatbotDetail.scss";
import ServiceOrganization from "../../../../services/organization/ServiceOrganization";
import ChatbotService from '../../../../services/newServices/ChatbotService';
import BackButton from "../../../reuseableComponent/BackButton/BackButton";
import SectionWrap from "../../../reuseableComponent/sectionWrap/SectionWrap";
import RetrieveDataLoading from "../../../reuseableComponent/retrieveDataLoading/RetrieveDataLoading";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import PopupRemove from "../../../reuseableComponent/popupRemove/PopupRemove";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import mixpanel from "mixpanel-browser";
import { doToast } from "../../../../helper/HelperGeneral";
import { FiEdit, FiTrash2, FiCopy } from "react-icons/fi";
import { CHATBOT } from "../../../../constants/chatbot";
import { NavLink } from "react-router-dom";


const IntegrationChatbotDetail = (props) => {
  let { match } = props;
  let [orgID, setOrgID] = useState(false);
  let [chatbotDetail, setIntegrationChatbotDetail] = useState(false);
  let [retrieveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false,
  });
  let [isLoadingGetDetail, setIsLoadingGetDetail] = useState(true);
  let [showPopupSingleRemove, setShowPopupSingleRemove] = useState(false);
  let [waitForResponseDelete, setWaitForResponseDelete] = useState(false);
  
  useEffect(() => {
    if(orgID) {
      setRetrieveDataError({
        code: false,
        message: false,
      })
      getDetail();
    }
  }, [orgID, match])

  // const goToEditDetail = (id) => {
  //   props.history.push(`/o/${orgID}/integration/chatbots/edit-detail/${id}`);
  // }

  // const goToEditConfig = (id) => {
  //   props.history.push(`/o/${orgID}/integration/chatbots/edit-config/${id}`);
  // }

  const getDetail = () => {
    let _retrieveDataError = { ...retrieveDataError };
    setIsLoadingGetDetail(true);
    
    let data = {
      id: Number(match.params.id)
    }

    ChatbotService.detailChatbot(orgID, data, (response) => {
      if (response.dataResult.status === 200) {
        let item = response.dataResult.data.chatbot;
        setIntegrationChatbotDetail(item);
      } else {
        _retrieveDataError.message = response.dataResult.error.message;
        _retrieveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retrieveDataError);
        doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingGetDetail(false);
    });
  };

  const checkOrgID = (match) => {
    const { orgID: param } = match.params;
    // if (!param) getOrganization();
    if (!param) return false;
    else return param;
  };

  const back = () => {
    props.history.push(`/o/${orgID}/integration/chatbots`);
  };

  const doMount = async () => {
    const paramID = checkOrgID(match);
    if (paramID) {
      setOrgID(paramID);
    } else {
      ServiceOrganization.postGetOrganization((response) => {
        if (response.dataResult.error !== "") {
          let responseData = response.dataResult.data;
          if (responseData.memberships.length < 1)
            window.location.href = "/organization/new";
          const { id, createdTime } = responseData.memberships[0].organization;
          const urlOrgID = `${id}-${createdTime}`;
          props.history.push(`/o/${urlOrgID}/organization/billing`);
        } else {
          console.log("GET MEMBERSHIP ORGANIZATION HAS BEEN FAILED");
        }
      });
    }
  };

  const copyToClip = (selector, text) => {
    document.getElementById(selector).select();
    document.execCommand("copy");
    doToast(text);
  };

  useEffect(() => {
    doMount();
  }, []);

  let toggleModalRemoveChatbot = () => {
    setShowPopupSingleRemove(!showPopupSingleRemove);
  }

  let deleteChatbot = () => {
    setWaitForResponseDelete(true);
    let data = {
      id: chatbotDetail.id,
      createdTime: chatbotDetail.createdTime
    };

    let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
    
    ChatbotService.deleteChatbot(orgID, data, (response) => {
      let dataResult = response.dataResult;

      if(dataResult.error.message === "") {
        if(!response.dataResult.data.success) {
          doToast(response.dataResult.data.message, "fail");
          toggleModalRemoveChatbot();
          setWaitForResponseDelete(false);
        }else {
          if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") {
            mixpanel.track(
              "[Action] Delete Chatbot", 
              {
                  userFullName : myAgentData.account.fullName,
                  userID : myAgentData.account.id,
                  organizationID : orgID.split("-")[0]
              }
            );
          }

          doToast(response.dataResult.data.message);

          setTimeout(() => {
            back();
          }, 3000)
        }
      }else {
        doToast(response.dataResult.message, 'fail');
        toggleModalRemoveChatbot();
        setWaitForResponseDelete(false);
      }

    })
  }

  return (
    <SectionWrap {...props} orgID={orgID} withHeader withSideMenu>
      <BackButton text="Back" onClick={back} />

      <div className="view-chatbot-detail sectionWrap">
        <div className="view-chatbot-detail-top">
          <b>Chatbot Details</b>
          
          {!isLoadingGetDetail &&
            <>
              <NavLink to={`/o/${orgID}/integration/chatbots/edit-detail/${chatbotDetail.id}`}>
                <ButtonWithLoadingOrIcon 
                  text="Edit"
                  position="left"
                  icon={{
                    type: "svg",
                    src: FiEdit
                  }}
                  className="main-button-40 edit-top no-fill-button"
                />
              </NavLink>

              <ButtonWithLoadingOrIcon 
                text="Delete"
                position="left"
                icon={{
                  type: "svg",
                  src: FiTrash2
                }}
                onClickAction={toggleModalRemoveChatbot}
                className="main-button-40 red-button"
              />
            </>
          }
        </div>
        
        {isLoadingGetDetail || retrieveDataError.code ? (
          <div className="chatbot-detail-loading-wrapper">
            <RetrieveDataLoading
              isLoading={isLoadingGetDetail}
              errorMessage={retrieveDataError.message}
            />
          </div>
        ) : chatbotDetail ? 
          (
            <React.Fragment>
              <div className="view-chatbot-detail-middle view-chatbot-detail-middle-top">
                <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                  <b className="detail-label">Chatbot Name</b>
                  <p><b>{chatbotDetail.name}</b></p>
                </div>

                <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                  <b className="detail-label">Chatbot Type</b>
                  <p><b>{chatbotDetail.typeName}</b></p>
                </div>

                {chatbotDetail.type === CHATBOT["taptalk/scf"].value &&
                  <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                    <b className="detail-label">QnA Path</b>
                    <p><b>{chatbotDetail.scf.scfPathTitle}</b></p>
                  </div>
                }
              </div>
            </React.Fragment>
          ) 
          : 
          (
            ""
          )
        }

        <PopupRemove removeTitle={`Delete Chatbot?`}
          removeDescription={`This chatbot will be removed from the organization.`}
          onClickCancel={() => toggleModalRemoveChatbot()}
          onClickRemove={deleteChatbot}
          waitForResponse={waitForResponseDelete}
          showModalProps={showPopupSingleRemove}
          submitText="Delete Chatbot"
        />
      </div>

      {chatbotDetail.type === "custom" &&
        <div className="view-chatbot-detail sectionWrap">
          <div className="view-chatbot-detail-top">
            <b>Chatbot Configs</b>
            <p className="subtitle text-left">
              For more details, <a href="https://docs.taptalk.io/onetalk-omnichannel-documentation/chatbot-integration" target="_blank" rel="noopener noreferrer">please read the documentation.</a>
            </p>

            <NavLink to={`/o/${orgID}/integration/chatbots/edit-config/${chatbotDetail.id}`}> 
              <ButtonWithLoadingOrIcon 
                text="Edit"
                position="left"
                icon={{
                  type: "svg",
                  src: FiEdit
                }}
                className="main-button-40 no-fill-button edit-button"
              />
            </NavLink>
          </div>

          <React.Fragment>
            <div className="view-chatbot-detail-middle view-chatbot-detail-middle-top">
              <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                <b className="detail-label">Chatbot URL</b>
                <div className="detail-value-wrapper">
                  <input 
                    type="text"
                    value={chatbotDetail.custom.chatbotURL}
                    id="chatboturl"
                    readOnly
                  />
                  <FiCopy onClick={() => copyToClip("chatboturl", "Chatbot URL Copied")} />
                </div>
              </div>

              <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                <b className="detail-label">Webhook URL</b>
                <div className="detail-value-wrapper">
                  <input 
                    type="text"
                    value={chatbotDetail.custom.webhookURL}
                    id="webhookurl"
                    readOnly
                  />
                  <FiCopy onClick={() => copyToClip("webhookurl", "Webhook URL Copied")} />
                </div>
              </div>

              <div className="view-chatbot-detail-middle view-chatbot-detail-middle-name">
                <b className="detail-label">Secret Key</b>
                <div className="detail-value-wrapper">
                  <input 
                    type="text"
                    value={chatbotDetail.custom.secretKey}
                    id="secretkey"
                    readOnly
                  />
                  <FiCopy onClick={() => copyToClip("secretkey", "Secret Key Copied")} />
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      }
    </SectionWrap>
  );
};

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = {

};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IntegrationChatbotDetail);
