import React, { useEffect, useState } from "react";
import "./BroadcastMessageFormCsvPart.scss";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { FiCheck, FiSearch, FiX, FiPlus, FiTrash2, FiAlertCircle, FiChevronDown, FiUser, FiInfo } from "react-icons/fi";
import { IoIosCloseCircle } from "react-icons/io";
import { convertFileToCsv, doToast } from "../../../../helper/HelperGeneral";
import HelperCustomEncryptor from "../../../../helper/HelperCustomEncryptor";
import HelperGetLocalStorage from "../../../../helper/HelperGetLocalStorage";
import HelperInput from "../../../../helper/HelperInput";
import ButtonWithLoadingOrIcon from "../../../reuseableComponent/buttonWithLoadingOrIcon/ButtonWithLoadingOrIcon";
import BroadcastMessageModalContact from "../broadcastMessageModalContact/BroadcastMessageModalContact";
import { connect } from 'react-redux';
import flagGlobe from "../../../../assets/img/flag-globe.svg";
import iconContact from "../../../../assets/img/broadcast-message/contacts-red.svg";
import iconSegment from "../../../../assets/img/broadcast-message/segment-blue.svg";
import iconContactGreen from "../../../../assets/img/broadcast-message/contacts-green.svg";
import BroadcastMessageModalSelectRecipient from "../broadcastMessageModalSelectRecepient/BroadcastMessageModalSelectRecipient";
import BroadcastMessageFormContacts from "./broadcastMessageFormContacts/BroadcastMessageFormContacts";
import UserSegmentServices from '../../../../services/newServices/UserSegmentServices';
import mixpanel from "mixpanel-browser";
import Select from "react-select";

const REG_NUMERIC = /^[0-9]*$/;
const MAX_RECIPIENT = 5000;
const MAX_RECIPIENT_MESSAGE = `Maximum ${MAX_RECIPIENT} recipients allowed per broadcast`;

const ERROR_MESSAGE_NUMBER = {
    length: "The combined country code and phone number must be between 7-15 digits",
    isNumeric: "Only numerics are allowed",
    isStartZero: "Phone number cannot start from 0"
}

const CONTACT_TYPE = [
    {
        icon: iconContact,
        value: "users",
        title: "Contact"
    },
    {
        icon: iconSegment,
        value: "user_segment",
        title: "Contact Segment"
    },
    {
        icon: iconContactGreen,
        value: "contacts",
        title: "Contacts (Import from CSV)"
    }
]

const iconDropOrange = "url('/image/chevron-down-orange.svg') center / contain no-repeat !important";
const iconDropGrey = "url('/image/chevron-down-grey.svg') center / contain no-repeat !important";

const BroadcastMessageFormCsvPart = props => {
    let [csvList, setCsvList] = useState([]);
    let [tempCsvList, setTempCsvList] = useState({
        list: [],
        time: false
    });
    let [isShowModalAddRecipient, setIsShowModalAddRecipient] = useState(false);
    let [fieldAddRecipient, setFieldAddRecipient] = useState([
        {
            country: {
                id: "0",
                countryCodeNumber: "62",
                countryCode: "IDN",
                countryName: "Indonesia",
                countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
            },
            number: ""
        }
    ]);
    let [countryList, setCountryList] = useState(false);
    let [countryListSearch, setCountryListSearch] = useState([{
        keyword: "",
        countryList: []
    }]);
    let [isShowDropdownCountry, setIsShowDropdownCountry] = useState([false]);
    let [errorFieldNumber, setErrorFieldNumber] = useState([""]);

    let [showModalSelectRecipient, setShowModalSelectRecipient] = useState(false);
    let [searchContactVal, setSearchContactVal] = useState("");
    let [segments, setSegments] = useState([]);
    let [openModalContact, setOpenModalContact] = useState(false);
    let [modalContactData, setModalContactData] = useState(false);

    useEffect(() => {
        getUserSegment();
    }, [])
    
    useEffect(() => {
        let _tempCsvList = {...tempCsvList};
        let _csvList = csvList.slice();
        if(_tempCsvList.time) {
            _tempCsvList.list.map((val, index) => {
                if(_csvList.findIndex(_val => _val.phone === val.phone) !== -1) {
                    _tempCsvList.list.splice(index, 1);
                }

                return null;
            })

            _csvList = _csvList.concat(_tempCsvList.list);
            
            if(_csvList.length > MAX_RECIPIENT) {
                doToast(MAX_RECIPIENT_MESSAGE, "fail");
            }else {
                doToast(_tempCsvList.message);
                setCsvList(_csvList);
                props.onChangeCsvDataProps(_csvList);
            }
        }
    }, [tempCsvList])

    useEffect(() => {
        if(props.countryList) {
            let newCountryList = [];

            props.countryList.map((value) => {
                let country = {
                    id: value.id,
                    countryCodeNumber: value.callingCode,
                    countryCode: value.iso3Code,
                    countryName: value.commonName,
                    countryFlag: value.flagIconURL,
                    countryLabel: `${value.commonName} (+${value.callingCode})`
                };

                newCountryList.push(country);

                return null;
            })

            setCountryList(newCountryList)
        }
    }, [props.countryList])

    useEffect(() => {
        setCsvList(props.csvDataProps);
    }, [props.csvDataProps])

    let onClickSegment = (e) => {
        props.onChangeSegment(e);
    }

    let closeModalContactInfo = () => {
        setModalContactData(false);
        setOpenModalContact(false);
    }

    let getUserSegment = () => {
        UserSegmentServices.getSegmentList(props.parentProps.match.params.orgID, 1, 1000, "", (response) => {
            let dataResult = response.dataResult;

            if(dataResult.status === 200) {
                let _list = [];
                let _segment = dataResult.data.segments;
                
                if (_segment.length > 0) {
                    _segment.map((val) => {
                        if(val.totalUsers > 0) {
                            val.value = val.id;
                            val.label = val.name;
                            _list.push(val);
                        }

                        return null;
                    });
                    
                    setSegments(_list);
                }
            }else { 
                doToast(dataResult.error.message, "fail");
            }
        })
    }

    let styleSelect = () => ({
        indicatorsContainer: provide => ({
            ...provide,
            height: "48px",
        }),
        valueContainer: provide => ({
            ...provide,
            height: "100%"
        }),
        clearIndicator: () => ({
            // display: "none"
        }),
        dropdownIndicator: (provided, { isFocused }) => ({
            ...provided,
            background: isFocused ? iconDropOrange : iconDropGrey,
            transition: "0.25s ease-in-out",
            color: "transparent !important",
            width: "24px",
            height: "24px",
            // transform: isFocused ? "rotate(-180deg)" : ""
        }),
        container: (provided) => ({
            ...provided,
            width: "355px"
        }),
        control: (provide, { isFocused }) => ({
            ...provide,
            padding: "0 5px",
            // height: "48px",
            borderRadius: "8px",
            border: isFocused ? "1px solid #ff7e00 !important" : "1px solid rgba(25, 25, 25, 0.1) !important",
            boxShadow: "none !important",
            marginBottom: "0px",
            cursor: "pointer"
        }),
        indicatorSeparator: provide => ({ ...provide, display: "none" }),
            menu: provide => ({
            ...provide,
            borderRadius: "8px",
            boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
            overflow: "hidden"
        }),
        option: (provide, state) => {
            const { isSelected } = state;
            const checked = {
                color: "#ff7e00",
                content: '"✔"',
                position: "absolute",
                left: "0",
                top: "50%",
                transform: "translate(100%, -50%)"
            };
            return {
                ...provide,
                position: "relative",
                background: "#fff",
                padding: "12px 15px 12px 35px",
                cursor: "pointer",
                color: isSelected ? "#ff7e00" : "#121212",
                ":hover": { background: "#ffe7d0" },
                ":before": isSelected ? checked : null
            };
        }
      })

    let runSetContacts = (list) => {
        //ini
        setTempCsvList({
            list: list,
            time: new Date().valueOf(),
            message: `Added ${list.length} recipient${list.length > 1 ? "s" : ""}`
        })
        // setCsvList(list);
        // props.onChangeCsvDataProps(list);
    }

    let toggleModalSelectRecipient = () => {
        setShowModalSelectRecipient(!showModalSelectRecipient);
    }

    let closeModalAddRecipient = () => {
        resetFieldRecipient();
        setIsShowModalAddRecipient(false);
    }

    let showModalAddRecipient = () => {
        setIsShowModalAddRecipient(true);
    }

    let addMoreRecipient = () => {
        let _fieldAddRecipient = fieldAddRecipient.slice();
        let _isShowDropdownCountry = isShowDropdownCountry.slice();
        let _countryListSearch = countryListSearch.slice();
        let _errorFieldNumber = errorFieldNumber.slice();

        _errorFieldNumber.push("");
        setErrorFieldNumber(_errorFieldNumber);

        _countryListSearch.push({
            keyword: "",
            countryList: []
        })
        setCountryListSearch(_countryListSearch);

        _isShowDropdownCountry.push(false);
        setIsShowDropdownCountry(_isShowDropdownCountry);

        _fieldAddRecipient.push({ 
            country: {
                id: "0",
                countryCodeNumber: "62",
                countryCode: "IDN",
                countryName: "Indonesia",
                countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
            },
            number: ""
        });
        setFieldAddRecipient(_fieldAddRecipient)
    }

    let removeFieldRecipient = (index) => {
        let _fieldAddRecipient = fieldAddRecipient.slice();
        let _isShowDropdownCountry = isShowDropdownCountry.slice();
        let _countryListSearch = countryListSearch.slice();
        let _errorFieldNumber = errorFieldNumber.slice();

        _errorFieldNumber.splice(index, 1);
        setErrorFieldNumber(_errorFieldNumber);

        _countryListSearch.splice(index, 1);
        setCountryListSearch(_countryListSearch);

        _isShowDropdownCountry.splice(index, 1);
        setIsShowDropdownCountry(_isShowDropdownCountry);

        _fieldAddRecipient.splice(index, 1);
        setFieldAddRecipient(_fieldAddRecipient)
    }

    let resetFieldRecipient = () => {
        setErrorFieldNumber([""]);

        setCountryListSearch([{
            keyword: "",
            countryList: []
        }])

        setFieldAddRecipient([{
            country: {
                id: "0",
                countryCodeNumber: "62",
                countryCode: "IDN",
                countryName: "Indonesia",
                countryFlag: "https://storage.googleapis.com/f8a350-taplive-prd-public/static/flags/ID@2x.png"
            },
            number: ""
        }])

        setIsShowDropdownCountry([false]);
    }

    let isDisabledAddRecipient = () => {
        let isDisabled = false;
        
        for(let i = 0; i < fieldAddRecipient.length; i++) {
            let phoneLength = (fieldAddRecipient[i].country.countryCodeNumber + fieldAddRecipient[i].number).replaceAll(" ", "").length;

            if(fieldAddRecipient[i].number[0] === "0") {
                isDisabled = true;
            }

            if(phoneLength < 7 || phoneLength > 15) {
                isDisabled = true;
            }

            if(!REG_NUMERIC.test(fieldAddRecipient[i].number.replaceAll(" ", ""))) {
                isDisabled = true;
            }
        }
        
        return isDisabled;
    }

    let onBlurAction = (i) => {
        let _errorFieldNumber = errorFieldNumber.slice();
        let errorMessage = "";

        let phoneLength = (fieldAddRecipient[i].country.countryCodeNumber + fieldAddRecipient[i].number).replaceAll(" ", "").length;

        if(fieldAddRecipient[i].number[0] === "0") {
            errorMessage = ERROR_MESSAGE_NUMBER.isStartZero;
        }

        if(phoneLength < 7 || phoneLength > 15) {
            errorMessage = ERROR_MESSAGE_NUMBER.length;
        }

        if(!REG_NUMERIC.test(fieldAddRecipient[i].number.replaceAll(" ", ""))) {
            errorMessage = ERROR_MESSAGE_NUMBER.isNumeric;
        }

        _errorFieldNumber[i] = errorMessage;
        setErrorFieldNumber(_errorFieldNumber);
    }

    let onChangeCsvFile = (e) => {
        setShowModalSelectRecipient(false);
        
        convertFileToCsv(e.target.files[0], {
            onSuccessConvertCsv: (result) => {
                let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                
                if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                    mixpanel.track(
                        "[Action] Add Recipient",
                        {
                            source: "CSV",
                            type: props.broadCastMessageDataProps.messageType,
                            userFullName : myAgentData.account.fullName,
                            userID : myAgentData.account.id,
                            organizationID : props.parentProps.match.params.orgID.split("-")[0]
                        }
                    );
                }
               
                let errorIndex = [];
                    
                let findIndex = (array, key) => {
                    return array.findIndex(v => v === key);
                }

                if(findIndex(result[0], "phone_number") === -1) {
                    errorIndex.push("phone_number");
                }
                if(findIndex(result[0], "fullName") === -1) {
                    errorIndex.push("fullName");
                }
                
                if(findIndex(result[0], "alias") === -1) {
                    errorIndex.push("alias");
                }

                if(errorIndex.length > 0) {
                    doToast(`Failed to import CSV file (${errorIndex.join(", ")} not found)`, "fail");
                }else {
                    result.splice(0, 1);
                    let newResult = [];

                    result.map(rowVal => {
                        let newString = rowVal[0].replace(/(\r\n|\n|\r|-| |\s)/gm,"");
                        let resultString = rowVal[0].replace(/(\r\n|\n|\r|-)/gm,"")[0] === "+" ? newString.replace("+", "") : newString;
            
                        if(rowVal[0].length > 0) {
                            newResult.push({
                                phone: resultString,
                                fullName: rowVal[1],
                                alias: rowVal[2],
                                // email: rowVal[3],
                                origin: "Import CSV",
                                createdTime: new Date().valueOf()
                            });
                        }

                        return null;
                    })

                    setTempCsvList({
                        list: newResult,
                        time: new Date().valueOf(),
                        message: `Added ${newResult.length} recipient${newResult.length > 1 ? "s" : ""}`
                    })
                }
            }
        });

        e.target.value = null;
    }

    let onClickRemoveCsvItem = (index) => {
        let _csvList = csvList.slice();
        _csvList.splice(index, 1);
        props.onChangeCsvDataProps(_csvList);
    }

    let renderModalAddRecipient = () => {
        let onChangePhoneNumber = (index, e) => {
            let _errorFieldNumber = errorFieldNumber.slice();
            let _fieldAddRecipient = fieldAddRecipient.slice();

            if(HelperInput.numberOnly(e.target.value.replaceAll(" ", ""))) {
                _errorFieldNumber[index] = "";
                _fieldAddRecipient[index].number = HelperInput.phoneBeautify(e.target.value.replaceAll(" ", ""), true);
            }

            setErrorFieldNumber(_errorFieldNumber);
            setFieldAddRecipient(_fieldAddRecipient);
        }

        // let onKeyDownPhoneNumber = (e) => {
        //     let prevent = true;

        //     if(e.keyCode === 8 || e.ctrlKey) {
        //         prevent = false;
        //     }

        //     if(!e.ctrlKey && e.keyCode !== 8) { 
        //         if(e.keyCode > 47 && e.keyCode < 58) {
        //             prevent = false;
        //         }

        //         if(e.keyCode === 37 || e.keyCode === 38 || e.keyCode === 39 || e.keyCode === 40) {
        //             prevent = false;
        //         }
        //     }

        //     if(prevent) {
        //         e.preventDefault();
        //     }
        // }

        let onChangeSelectCountry = (data, index) => {
            let _fieldAddRecipient = fieldAddRecipient.slice();
            _fieldAddRecipient[index].country = data;
            setFieldAddRecipient(_fieldAddRecipient);
            toggleDropdownCountry(index);
        }

        let onClickSubmitAddRecipient = () => {
            let val = [];
            let newNumber = "";
            
            for(let i = 0; i < fieldAddRecipient.length; i++) {
                newNumber = fieldAddRecipient[i].country.countryCodeNumber + fieldAddRecipient[i].number;
                val.push({
                    phone: newNumber.replaceAll(" ", ""),
                    alias: "",
                    fullName: "",
                    email: "",
                    origin: "Manual Add Contact",
                    createdTime: new Date().valueOf()
                });
            }

            setTempCsvList({
                list: val,
                time: new Date().valueOf(),
                message: `Added ${val.length} recipient${val.length > 1 ? "s" : ""}`
            })
            
            closeModalAddRecipient();
        }

        let toggleDropdownCountry = (index) => {
            let _isShowDropdownCountry = isShowDropdownCountry.slice();
            let _countryListSearch = countryListSearch.slice();
            
            _countryListSearch[index] = {
                keyword: "",
                countryList: []
            }
            setCountryListSearch(_countryListSearch);

            _isShowDropdownCountry[index] = !_isShowDropdownCountry[index];
            setIsShowDropdownCountry(_isShowDropdownCountry);
        }

        let onChangeSearchCountry = (e, index) => {
            let _countryListSearch = countryListSearch.slice();
            let _countryList = countryList.slice();

            _countryListSearch[index].keyword = e.target.value;
            _countryListSearch[index].countryList = _countryList.filter(val => val.countryLabel.toLowerCase().includes(e.target.value.toLowerCase()));
            setCountryListSearch(_countryListSearch);

        }

        let renderPhoneNumberForm = (index) => {
            return (
                <div className="phone-number-form">
                    <div className="area-code-wrapper">
                        <Dropdown isOpen={isShowDropdownCountry[index]} toggle={() => toggleDropdownCountry(index)}>
                            <DropdownToggle className="value-country-code-wrapper">
                                <img src={fieldAddRecipient[index].country.countryFlag} alt="" className="country-flag" onError={(e) => {e.target.onerror = null; e.target.src = flagGlobe;}} />

                                +{fieldAddRecipient[index].country.countryCodeNumber}

                                <FiChevronDown className={isShowDropdownCountry[index] ? "chevron-up" : ""} />
                            </DropdownToggle>
                            <DropdownMenu>
                                <div className="country-search-wrapper">
                                    <FiSearch />
                                    <input type="text" onChange={(e) => onChangeSearchCountry(e, index)} value={countryListSearch[index].keyword} />
                                </div>

                                <div className="country-list-select">
                                    {countryList &&
                                        (countryListSearch[index].keyword !== "" ?
                                            countryListSearch[index].countryList.length > 0 ?
                                                <div className="country-list-select">
                                                    {countryListSearch[index].countryList.map((value, key) => {
                                                        return (
                                                            <div 
                                                                className={`custom-phone-code-picker ${fieldAddRecipient[index].country.countryCodeNumber === value.countryCodeNumber ? "active-code-number" : ""}`}
                                                                key={`country-list-${key}`}
                                                                onClick={() => onChangeSelectCountry(value, index)}
                                                            >
                                                                {fieldAddRecipient[index].country.countryCodeNumber === value.countryCodeNumber &&
                                                                    <FiCheck />
                                                                }
                                                                <img src={value.countryFlag} alt="" onError={(e) => {e.target.onerror = null; e.target.src = flagGlobe;}} />
                                                                <div>
                                                                    {value.countryLabel}
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            :
                                            <div className="country-list-select no-option">
                                                No options
                                            </div>
                                        :
                                        countryList.map((value, key) => {
                                            return (
                                                <div 
                                                    className={`custom-phone-code-picker ${fieldAddRecipient[index].country.countryCodeNumber === value.countryCodeNumber ? "active-code-number" : ""}`}
                                                    key={`country-list-${key}`}
                                                    onClick={() => onChangeSelectCountry(value, index)}
                                                >
                                                    {fieldAddRecipient[index].country.countryCodeNumber === value.countryCodeNumber &&
                                                        <FiCheck />
                                                    }
                                                    <img src={value.countryFlag} alt="" onError={(e) => {e.target.onerror = null; e.target.src = flagGlobe;}} />
                                                    <div>
                                                        {value.countryLabel}
                                                    </div>
                                                </div>
                                            )
                                        }))
                                    }
                                </div>
                            </DropdownMenu>
                        </Dropdown>
                    </div>

                    <div className="phone-wrapper">
                        <input 
                            type="text" 
                            placeholder="ex: 1234 567 890" 
                            maxLength="20"
                            value={fieldAddRecipient[index].number}
                            onChange={(e) => onChangePhoneNumber(index, e)}
                            onBlur={() => onBlurAction(index)}
                            className={`${errorFieldNumber[index] !== "" ? "error-input-message" : ""} input-phone-index-${index}`}
                            // onKeyDown={(e) => onKeyDownPhoneNumber(e)}
                        />

                        {errorFieldNumber[index] !== "" &&
                            <p>
                                <FiAlertCircle />
                                <b>{errorFieldNumber[index]}</b>
                            </p>
                        }

                        {index > 0 &&
                            <FiTrash2 onClick={(e) => removeFieldRecipient(index)} className="remove-number" />
                        }
                    </div>
                </div>
            )
        }

        return (
            <Modal toggle={closeModalAddRecipient} isOpen={isShowModalAddRecipient} className="modal-add-recipient">
                <ModalHeader>
                    <p className="add-recipient-title">
                        <b>Add Recipient</b>

                        <span> ({fieldAddRecipient.length})</span>
                    </p>
                </ModalHeader>

                <ModalBody>
                    <div className="add-recipient-form-wrapper">
                        <div className="form-label">
                            <p><b>Country</b></p>
                            <p><b>Phone Number</b></p>
                        </div>

                        {fieldAddRecipient.map((value, index) => {
                            return (
                                <React.Fragment key={`field-number-${index}`}>
                                    {renderPhoneNumberForm(index)}
                                </React.Fragment>
                            )
                        })}

                        <button className="add-recipient-button" onClick={addMoreRecipient}>
                            <FiPlus />
                            <b>Add More</b>
                        </button>

                        {/* <div className="phone-number-form phone-number-disabled">
                            <div className="area-code-wrapper">
                                <div className="country-flag-wrapper">
                                    <FiGlobe />
                                </div>
                                
                                <input type="text" placeholder="+0 000" readOnly />
                            </div>

                            <div className="phone-wrapper">
                                <input type="text" placeholder="123  456 7890" readOnly />
                            </div>
                        </div> */}
                        <br />
                    </div>
                </ModalBody>

                <ModalFooter>
                    <ButtonWithLoadingOrIcon 
                        className="no-fill-button main-button-40 button-submit"
                        text="Cancel"
                        onClickAction={() => {
                            resetFieldRecipient();
                            setIsShowModalAddRecipient(false);
                        }}
                    />

                    <ButtonWithLoadingOrIcon 
                        className="orange-button main-button-40 button-submit"
                        text="Submit"
                        onClickAction={() => {
                            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                            
                            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                                mixpanel.track(
                                    "[Action] Add Recipient",
                                    {
                                        source: "number",
                                        type: props.broadCastMessageDataProps.messageType,
                                        userFullName : myAgentData.account.fullName,
                                        userID : myAgentData.account.id,
                                        organizationID : props.parentProps.match.params.orgID.split("-")[0]
                                    }
                                );
                            }

                            onClickSubmitAddRecipient();
                        }}
                        isDisabled={isDisabledAddRecipient()}
                    />
                </ModalFooter>
            </Modal>
        )
    }

    let clearCsvList = () => {
        props.onChangeCsvDataProps([]);
        setCsvList([]);
    }

    let onChangeValSearchContact = (e) => {
        setSearchContactVal(e.target.value);
    }
  
    return (
        <div className={`broad-cast-form-csv-wrapper ${props._className}`}>
            {renderModalAddRecipient()}

            <BroadcastMessageModalContact 
                isOpen={openModalContact}
                toggle={closeModalContactInfo}
                contactData={modalContactData}
            />
            
            <BroadcastMessageModalSelectRecipient 
                isOpenProps={showModalSelectRecipient}
                toggleProps={toggleModalSelectRecipient}
                showModalAddRecipientProps={showModalAddRecipient}
                closeModalAddRecipientProps={closeModalAddRecipient}
                parentProps={props.parentProps}
                messageChannel={props.broadCastMessageDataProps.selectedChannel}
                setContacts={runSetContacts}
            />
            <input type="file" id="csv-file-select" onChange={(e) => onChangeCsvFile(e)} />
            
            {/* select contact */}
            <label><b>Recipient Type</b></label>
            <br />

            {CONTACT_TYPE.map((_val, _idx) => {
                return (
                    _idx === 2 ?
                        props.broadCastMessageDataProps.selectedChannel.value === "whatsappba" ?
                            <div 
                                className={`contact-selection-selection ${_val.value === props.broadCastMessageDataProps.contactType ? "active-contact-selection-selection" : ""}`} 
                                key={`contact-selection-${_idx}`} 
                                onClick={() => props.onChangeContactType(_val.value)}
                            >
                                <div className={`contact-selection-icon-wrapper contact-selection-${_val.value}`}>
                                    <img src={_val.icon} alt="" />
                                </div>

                                {_val.title}
                            </div>
                            :
                            ""
                        :
                        <div 
                            className={`contact-selection-selection ${_val.value === props.broadCastMessageDataProps.contactType ? "active-contact-selection-selection" : ""}`} 
                            key={`contact-selection-${_idx}`} 
                            onClick={() => props.onChangeContactType(_val.value)}
                        >
                            <div className={`contact-selection-icon-wrapper contact-selection-${_val.value}`}>
                                <img src={_val.icon} alt="" />
                            </div>

                            {_val.title}
                        </div>
                )
            })}

            <br />
            {/* select contact */}

            {props.broadCastMessageDataProps.contactType === CONTACT_TYPE[0].value &&
                <>
                    <label><b>Recipients</b></label>

                    {/* <label 
                        htmlFor="csv-file-select"
                        onClick={() => {
                            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
            
                            mixpanel.track(
                                "[Button] Add Recipient",
                                {
                                source: "CSV",
                                type: props.broadCastMessageDataProps.messageType,
                                userFullName : myAgentData.account.fullName,
                                userID : myAgentData.account.id,
                                organizationID : props.parentProps.match.params.orgID.split("-")[0]
                                }
                            );
                        }}
                    >
                        <div className="button-import-csv">
                            <FiFilePlus />
                            <b>Import CSV File</b>
                        </div>
                    </label> */}

                    {/* <a 
                        href={require(`../../Phone_number_data_example.csv`)} 
                        download="Phone-number-list-example.csv"
                        onClick={() => {
                            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
            
                            mixpanel.track(
                                "[Action] Download CSV Template",
                                {
                                type: props.broadCastMessageDataProps.messageType,
                                userFullName : myAgentData.account.fullName,
                                userID : myAgentData.account.id,
                                organizationID : props.parentProps.match.params.orgID.split("-")[0]
                                }
                            );
                        }}
                    >
                        <div className="button-download-csv">
                            <FiDownload />
                            <b>Download CSV Template</b>
                        </div>
                    </a> */}

                    {/* {props.csvDataProps.length > 0 && */}
                        <span className="phone-number-counter">
                            {csvList.length}/{MAX_RECIPIENT}
                        </span>
                    {/* } */}

                    {/* ${csvList.length < 1 ? "empty-contact-container" : ""}`}*/}
                    <div className={`phone-number-container`}>
                        <div className="recipient-search-box">
                            <input 
                                type="text" 
                                disabled={csvList.length === 0} 
                                placeholder="Search for recipient"
                                value={searchContactVal}
                                onChange={(e) => onChangeValSearchContact(e)}
                            />
                            <FiSearch />
                        </div>
                        {csvList.length < 1 ?
                            <p className="empty-contact">
                                No recipients found. Start by clicking “Add Recipients”.
                            </p>
                            :
                            <div className="selected-contact-list-wrapper">
                                {csvList.map((value, index) => {
                                    return (
                                        <div 
                                            className={`contact-item
                                                ${(searchContactVal !== "" && value.fullName !== "" && value.fullName.toLowerCase().includes(searchContactVal)) ? "active-contact-item" : ""}
                                            `} 
                                            key={`contact-${index}`}
                                        >
                                            <span
                                                onClick={() => {
                                                    setModalContactData(value);
                                                    setOpenModalContact(true);
                                                }}
                                            >
                                                <FiUser className="csv-icon" /> 
                                                <b>{value.fullName}</b> +<b>{HelperInput.phoneBeautify(value.phone)}</b>
                                            </span>
                                                
                                            <div className="contact-remove-button" onClick={() => onClickRemoveCsvItem(index)}>
                                                <IoIosCloseCircle />    
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        }   
                    </div>

                    <div 
                        className="no-fill-button button-add-recipient" 
                        onClick={() => {
                            // setIsShowModalAddRecipient(true);
                            toggleModalSelectRecipient();
                            let myAgentData = HelperCustomEncryptor.doDecrypt(HelperGetLocalStorage.getLocalStorageData('user').data);
                            
                            if(process.env.REACT_APP_TAPTALK_MIXPANEL_KEY !== "") { 
                                mixpanel.track(
                                    "[Button] Add Recipient",
                                    {
                                    source: "number",
                                    type: props.broadCastMessageDataProps.messageType,
                                    userFullName : myAgentData.account.fullName,
                                    userID : myAgentData.account.id,
                                    organizationID : props.parentProps.match.params.orgID.split("-")[0]
                                    }
                                );
                            }
                        }}
                    >
                        <FiPlus />
                        <b>Add Recipient</b>
                    </div>
                    
                    {csvList.length > 0 &&
                        <div 
                            className="red-button-no-fill button-clear-recipient" 
                            onClick={clearCsvList}
                        >
                            <FiX />
                            <b>Clear All Recipient{csvList.length > 1 ? "s" : ""}</b>
                        </div>
                    }
                </>
            }

            {props.broadCastMessageDataProps.contactType === CONTACT_TYPE[1].value &&
                <>
                    <label><b>Contact Segment</b></label>

                    <Select 
                        options={segments} 
                        placeholder="Select segment" 
                        onChange={(e) => onClickSegment(e)}
                        styles={styleSelect()}
                        classNamePrefix="react-select-broadcast"
                        // isDisabled={isLoadingMessageTemplate}
                        value={segments.filter(
                            (obj) => obj.value === props.broadCastMessageDataProps.segment.value
                        )}
                        blurInputOnSelect={true}
                    />

                    <p className="input-info-wrapper input-info-template-name">
                        <FiInfo />
                        <b>Only recipient with valid WhatsApp number will be send.</b>
                    </p>
                </>
            }

            {props.broadCastMessageDataProps.contactType === CONTACT_TYPE[2].value &&
                <BroadcastMessageFormContacts {...props} />
            }
        </div>
    );
};

const mapStateToProps = state => ({
    countryList: state.countryList,
});
  
export default connect(mapStateToProps, null)(BroadcastMessageFormCsvPart)