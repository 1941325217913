import React, { useEffect, useState } from "react";
import "./Topic.css";
import SectionWrap from "../reuseableComponent/sectionWrap/SectionWrap";
import topicServices from "../../services/newServices/TopicServices";
import { doToast } from "../../helper/HelperGeneral";
import TDTopic from "./TDTopic";
import TDAgent from "./TDAgent";
import BackButton from "../reuseableComponent/BackButton/BackButton";

const TopicsDetails = props => {
  const { match, history } = props;
  const [orgID, setOrgID] = useState(false);
  const [idTopic, setIdTopic] = useState(false);
  const [detailsData, setDataDetails] = useState(false);
  const [onReload, setOnReload] = useState(false);
  const [retriveDataError, setRetrieveDataError] = useState({
    code: false,
    message: false
  })
  const [isLoadingTopic, setIsLoadingTopic] = useState(false);

  const wrap = { ...props, orgID };

  const getTopicDetails = async (topicID, orgID) => {
    setIsLoadingTopic(true);
    
    if (!topicID || !orgID) {
      history.push("/organization/topics");
      return false;
    }
    const newTopID = parseInt(topicID, 10);

    topicServices.getTopic(orgID, "details", newTopID, (response) => {
      let _retriveDataError = {...retriveDataError};

      if(response.dataResult.error.message === "") {
        setDataDetails(response.dataResult.data);
      }else {
        _retriveDataError.message = response.dataResult.error.message;
        _retriveDataError.code = response.dataResult.error.code;
        setRetrieveDataError(_retriveDataError);
        // doToast(response.dataResult.error.message, 'fail');
      }

      setIsLoadingTopic(false);
    });
  };

  const checkTopicsID = () => {
    const { topicID, orgID: paramsID } = match.params;
    if (!topicID) return (window.location = "/organization/topics");
    return { topicID, paramsID };
  };

  const reloadAPI = async text => {
    setOnReload(true);
    await getTopicDetails(idTopic, orgID);
    doToast(text || "UPDATED");
    setOnReload(false);
  };

  useEffect(() => {
    const completeID = checkTopicsID();
    if (completeID) {
      const { topicID, paramsID } = completeID;
      setOrgID(paramsID);
      setIdTopic(topicID);
      getTopicDetails(topicID, paramsID);
    }
  }, []);

  const backToTopic = () => history.push(`/o/${orgID}/organization/topics`);

  const propsPassed = {
    detailsData,
    retriveDataError,
    isLoadingTopic,
    orgID,
    idTopic,
    reloadAPI,
    onReload
  };

  return (
    <SectionWrap {...wrap} withHeader withSideMenu>
      <BackButton text="Back to topics" onClick={backToTopic} />
      <TDTopic {...propsPassed} />
      <TDAgent {...propsPassed} />
    </SectionWrap>
  );
};

export default TopicsDetails;
